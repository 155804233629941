import * as React from "react";
// import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";

export default function BasicTooltip({ title, placement }) {
  return (
    <Tooltip title={title} placement={placement}>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </Box>
    </Tooltip>
  );
}
