import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function PlatformInfoDetailTable({ columnData }) {
  if (!columnData) return <p>데이터가 없습니다.</p>;

  const headerStyles = {
    fontWeight: "bold", // 헤더 텍스트를 진하게
    backgroundColor: "#EFF2F4", // 시도해 보는 배경색
    // zIndex: 1,
    // background: "green !important",
  };

  const columnStyles = {
    no: { width: "7%", ...headerStyles }, // 순번
    name: { width: "20%", ...headerStyles }, // 컬럼명
    description: { width: "56%", ...headerStyles }, // 컬럼 설명
    size: { width: "10%", ...headerStyles }, // 컬럼 사이즈
    key: { width: "7%", ...headerStyles }, // 컬럼 키
    noBody: { width: "7%" }, // 순번
    nameBody: { width: "20%" }, // 컬럼명
    descriptionBody: { width: "56%" }, // 컬럼 설명
    sizeBody: { width: "10%" }, // 컬럼 사이즈
    keyBody: { width: "7%" }, // 컬럼 키
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        // component={Paper}
        sx={{ maxHeight: 440 }}
      >
        <Table
          sx={{ minWidth: 650 }}
          stickyHeader
          aria-label="column details table"
        >
          <TableHead
          //  sx={{ backgroundColor: "#EFF2F4", zIndex: 1 }}
          >
            <TableRow>
              <TableCell sx={columnStyles.no}>순번</TableCell>
              <TableCell sx={columnStyles.name}>컬럼명</TableCell>
              <TableCell sx={columnStyles.description}>컬럼 설명</TableCell>
              <TableCell sx={columnStyles.size}>컬럼 사이즈</TableCell>
              <TableCell sx={columnStyles.key}>컬럼 키</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columnData.map((column, index) => (
              <TableRow key={index}>
                {/* <TableCell>{column.column_no}</TableCell>
              <TableCell>{column.column_name}</TableCell>
              <TableCell>{column.column_desc}</TableCell>
              <TableCell>{column.column_size}</TableCell>
              <TableCell>
                {column.column_pk_yn === "Y" ? "Yes" : "No"}
              </TableCell> */}
                <TableCell sx={columnStyles.noBody}>
                  {column.column_no}
                </TableCell>
                <TableCell sx={columnStyles.nameBody}>
                  {column.column_name}
                </TableCell>
                <TableCell sx={columnStyles.descriptionBody}>
                  {column.column_desc}
                </TableCell>
                <TableCell sx={columnStyles.sizeBody}>
                  {column.column_size}
                </TableCell>
                <TableCell sx={columnStyles.keyBody}>
                  {column.column_pk_yn === "Y" ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PlatformInfoDetailTable;
