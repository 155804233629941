import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate, redirect } from "react-router-dom";
import Typography from "@mui/material/Typography";
// import logo from "assets/images/logo.svg";
import preparing_image from "assets/images/tfImage/preparing_image.svg";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalContinue() {
  // 페이지 이동
  const navigate = useNavigate();
  const homePageMove = () => {
    navigate("/");
    console.log("homePageMove", homePageMove);
  };
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    setOpen(false);
  };

  // 스타일
  // Dialog 스타일
  const DialogSx = {
    // pl: "2",
    // pr: "2",
    "& .MuiPaper-root": { p: " 1% 6%" },
  };

  // 버튼 스타일
  const MoveBtnSx = {
    background: "#468ECD",
    cursor: "grab",
    "&:hover": {
      background: "#468ECD",
      // border: "1px solid #468ECD",
      // backgroundColor: "rgb(70 142 205 / 4%)",
    },
  };

  const CloseBtnSx = {
    border: "1px solid #468ECD",
    color: "#468ECD",
    cursor: "grab",
    "&:hover": {
      border: "1px solid #468ECD",
      backgroundColor: "rgb(70 142 205 / 4%)",
    },
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        sx={DialogSx}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title" sx={{ mt: 2, ml: 5, mr: 5 }}>
          {"Home으로 이동 하세요"}
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            component="span"
            sx={{ display: "flex", justifyContent: "center", mt: 2 }}
          >
            <img
              // src={logo}
              // alt="logo"
              src={preparing_image}
              alt="preparing_image"
              preparing_image
              sx={{ width: "150px", height: "auto" }}
              size="sm"
            />
          </Typography>

          <DialogContentText
            sx={{
              textAlign: "center",
              mt: 2.5,
              mb: 2,
              fontSize: "1.5rem",
              fontWeight: "500",
              color: "#005081",
            }}
          >
            NFT관리 준비중입니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            mb: 2,
            mr: 1,
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
          }}
        >
          <Button
            autoFocus
            onClick={homePageMove}
            sx={MoveBtnSx}
            variant="contained"
          >
            Home
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            sx={CloseBtnSx}
            variant="outlined"
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
