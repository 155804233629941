import React, { useEffect, useState } from "react";
import { useNavigate, redirect } from "react-router-dom";
import {
  Box,
  Typography,
  Dialog,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// 추가 S

export default function ModalCommonError({
  onClose,
  isOpen,
  handleClose,
  setIsSessionExpired,
}) {
  // const [isSessionExpired setIsSessionExpired] = useState()

  // 페이지 이동
  const navigate = useNavigate();
  const loginPageMove = () => {
    sessionStorage.clear();
    // setIsSessionExpired(false); // 모달 상태 초기화
    handleClose();
    navigate("/login", { replace: true });
    // console.log("## login", login);
  };

  const goBack = () => {
    sessionStorage.clear();
    handleClose();
    navigate(0);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"로그인 만료"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* 정말로 {selectedUser.userName} 님에게 결제를 요청하시겠습니까? */}
            로그인 시간이 만료되었습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={loginPageMove} color="primary" autoFocus>
            로그인 페이지 이동
          </Button>
          <Button onClick={goBack} color="primary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
