import React, { useEffect, useState } from "react";
import client from "assets/js/commonApi";
import {
  Box,
  Typography,
  Dialog,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Slide from "@mui/material/Slide";

// 추가 S
import { useNavigate } from "react-router-dom";

import SearchBarContractHandler from "components/searchs/SearchBarContractHandler";
import SearchBarPaymentRequest from "components/searchs/SearchBarPaymentRequest";

import DataGridModalA from "components/grid/DataGridModalA";
import BasicTooltip from "components/tooltips/BasicTooltip";
import { CircularIndeterminate } from "components/loaders/CircularIndeterminate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalPaymentRequestForm({
  isMessageLoading,
  onClose,
  isOpen,
  handleClose,
  contract_number,
  handleOnlyQrFileClose,
  contract_sub_id,
  onPaymentSuccess, // 부모 함수인 계약서 결제 성공 시 자동 닫힘 처리 하기 위해 생성
  // onCellClick,
  // finalClickInfo,
  // onFinalClickInfoChange,

  // onCellClick,
}) {
  // const autoCloseText = "* 담당자를 선택 후 결제요청 버튼을 누르세요.";

  const [grHandlerList, setGrHandlerList] = useState(); // 불러온 전체 데이터 > 그룹 사용자 리스트(결제요청할 대상자)

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  // const [schCriteria, setSchCriteria] = useState({ userId: "", userName: "" }); // 서치 검색기준
  // const [finalClickInfo, setFinalClickInfo] = useState(); // 최종 클릭한 정보를 부모에게 넘겨주는 useState

  // 추가하기 S

  // // 회사명 상태 관리 (기존 useState Name 참고하기 - 기존 계약서창 확인)
  // const [userId, setUserId] = useState(); // 회사명
  // const [userName, setUserName] = useState(); // 담당자(매니저)
  // // 담당자 상태 관리 (기존 useState Name 참고하기)

  // 추가하기 E

  // 회사 담장자 선택 데이터
  const COHANDLERLIST_API_ENDPOINT = "/pay/group";
  // const COHANDLERLIST_API_ENDPOINT = "/mdpt_platform/basic";

  const GetData = (searchParams) => {
    const { userId = "", userName = "" } = searchParams || {};
    client
      .post(COHANDLERLIST_API_ENDPOINT, {
        params: { userId, userName }, // API 호출 시 검색 파라미터로 사용
      })
      // .then((res) => {
      //   // console.log("## API 호출 결과:", res.data.data); // API 호출 결과 로깅
      //   console.log("## API 호출 결과:", res.data);

      //   const datas = res.data.data.flatMap((groupUser) =>
      //     groupUser.user_info
      //       .filter(
      //         (user) =>
      //           (!userId || groupUser.user_id?.includes(userId)) &&
      //           (!userName || user.user_name?.includes(userName)),
      //       )
      //       .map((user) => ({
      //         group_id: user.group_id, // 고유 ID 사용
      //         // sequence: groupUserIndex * 1000 + userIndex + 1, // 순번 생성 (1부터 시작)
      //         user_id: groupUser.user_id,
      //         user_name: user.user_name,
      //       })),
      //   );

      //   // 순번을 매기기
      //   const dataWithSequence = datas.map((data, index) => ({
      //     ...data,
      //     sequence: index + 1,
      //   }));

      //   console.log("## 필터링 후 데이터:", dataWithSequence);
      //   setGrHandlerList(dataWithSequence);

      //   // console.log("## 필터링 후 데이터:", datas); // 필터링 후 데이터 로깅
      //   // setCoHandlerList(datas);
      // })
      .then((res) => {
        console.log("## API 호출 결과:", res.data); // API 호출 결과 로깅
        if (res.data && res.data.data && res.data.data.user_info) {
          const groupUser = res.data.data; // Reference the groupUser object
          const datas = groupUser.user_info
            .filter(
              (user) =>
                (!userId || groupUser.user_id?.includes(userId)) &&
                (!userName || user.user_name?.includes(userName)),
            )
            .map((user, index) => ({
              id: user.user_id, // 고유 ID 사용
              sequence: index + 1, // 순번
              user_id: user.user_id,
              user_name: user.user_name,
              group_id: groupUser.group_id,
            }));

          console.log("## 필터링 후 데이터:", datas);
          setGrHandlerList(datas);
        }
      })
      .catch((err) => console.log("회사 담당자 검색 리스트", err));
  };

  // 검색 조건 업데이트 및 데이터 불러오기 함수 호출
  // const handleSearch = (userId, userName) => {
  //   // setSchCriteria({ userId, userName });
  //   // GetData(); // 검색 조건이 업데이트 된 후 데이터를 다시 불러옵니다.
  //   GetData({ userId, userName });
  // };
  // 검색 조건 업데이트 및 데이터 불러오기 함수를 호출합니다.
  const handleSearch = (userId, userName) => {
    // setSchCriteria({ userId, userName })
    console.log("## Search Parameters:", { userId, userName });
    // GetData();
    GetData({ userId, userName });
    // console.log("## GetData", GetData);
  };

  // 기존 코드
  // const handleOnCellClick = (params, recentData) => {
  //   const field = params.field; // 비교할 대상 변수로 만들고

  //   const userId = "user_id"; // 회사명
  //   const userName = "user_name"; // 담당자명

  //   if (field === userId) {
  //     const userIdValue = params.row.user_id; // 클릭된 셀의 user_id 값
  //     console.log("userId Value:", userIdValue);
  //     onFinalClickInfoChange(userIdValue);
  //   }
  // };

  // // 정상 두번째 S
  // const handleOnCellClick = (params) => {
  //   const userId = params.row.user_id; // 클릭된 셀의 ID 값
  //   const userName = params.row.user_name; // 클릭된 셀의 유저네임 값
  //   const groupId = params.row.group_id; // 클릭된 셀의 group_id 값

  //   console.log("Cell clicked!", params);
  //   console.log("## userId:", userId);
  //   console.log("## userName:", userName);
  //   console.log("## groupId:", groupId);

  //   // 선택된 사용자 ID 설정
  //   setSelectedUserId(userId);
  // };

  // const sendPaymentRequest = (userId) => {
  //   client
  //     .post("/pay/req", {
  //       pur_user: userId,
  //       contract_sub_id: contract_sub_id,
  //     })
  //     .then((response) => {
  //       console.log("결제 요청 성공:", response);
  //       alert("결제 요청이 성공적으로 완료되었습니다.");
  //       handleClose();
  //     })
  //     .catch((error) => {
  //       console.error("결제 요청 실패:", error);
  //       alert("결제 요청 실패: " + error.message);
  //     });
  // };
  // // 정상 두번째 E

  // // 정상 첫번째 S
  // const handleOnCellClick = (params) => {
  //   const userId = params.row.user_id; // 클릭된 셀의 ID 값
  //   const userName = params.row.user_name; // 클릭된 셀의 유저네임 값
  //   const groupId = params.row.group_id; // 클릭된 셀의 group_id 값

  //   console.log("Cell clicked!", params);
  //   console.log("## 1userId:", userId); // 클릭된 셀의 userId 값 출력
  //   console.log("## 2userName:", userName); // 클릭된 셀의 userName 값 출력
  //   console.log("## groupId:", groupId); // groupId 값 출력

  //   client
  //     .post("/pay/req", {
  //       pur_user: userId,
  //       contract_sub_id: contract_sub_id,
  //       // userName: userName,
  //       // groupId: groupId,
  //     })
  //     .then((response) => {
  //       console.log("결제 요청 성공:", response);
  //       alert("결제 요청이 성공적으로 완료되었습니다."); // 성공 메시지
  //       handleClose();
  //     })
  //     .catch((error) => {
  //       console.error("결제 요청 실패:", error);
  //       alert("결제 요청 실패: " + error.message); // 실패 메시지
  //     });
  //   // 여기서 onFinalClickInfoChange 함수를 호출하여 회사명과 담당자명을 전달합니다.
  //   // 이 예시에서는 onFinalClickInfoChange가 객체를 받아 처리할 수 있다고 가정합니다.

  //   // 기존 코드
  //   // onFinalClickInfoChange({
  //   //   userId: userId,
  //   //   userName: userName,
  //   //   groupId: groupId,
  //   // });
  //   handleClose();
  //     // 선택된 사용자 ID 설정
  // setSelectedUserId(userId);
  // };

  // // 정상 두번째 E

  // console.log("####@@finalClickInfo", finalClickInfo);

  const handleOnCellClick = (params) => {
    setSelectedUser({
      userId: params.row.user_id,
      userName: params.row.user_name,
      groupId: params.row.group_id,
    });
    setConfirmationOpen(true);
  };

  const handleConfirmPaymentRequest = () => {
    client
      .post("/pay/req", {
        pur_user: selectedUser.userId,
        contract_sub_id: contract_sub_id,
      })
      .then((response) => {
        console.log("결제 요청 성공:", response);
        alert("결제 요청이 성공적으로 완료되었습니다.");
        if (onPaymentSuccess) {
          onPaymentSuccess(); // 부모 컴포넌트에 성공을 알림
        }
        handleClose();
      })
      .catch((error) => {
        console.error("결제 요청 실패:", error);
        alert("결제 요청 실패: " + error.message);
      });
    setConfirmationOpen(false); // Close the confirmation dialog after handling
  };

  const handleCancelPaymentRequest = () => {
    setConfirmationOpen(false);
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "순번",
    //   flex: 0.5,
    // },
    {
      field: "sequence",
      headerName: "순번",
      flex: 0.5,
      valueGetter: (params) => params.row.sequence || "-", // 데이터가 없는 경우 "-" 표시
    },
    {
      field: "user_id",
      // headerName: "데이터",
      headerName: "ID",
      flex: 3.25,
      renderCell: (params) => (
        <BasicTooltip title={params.row.user_id} placement="right-end" />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "user_name",
      headerName: "담당자",
      flex: 1.25,
      renderCell: (params) => (
        <BasicTooltip title={params.row.user_name} placement="right-end" />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    // {
    //   field: "upd_dtm",
    //   headerName: "계약일",
    //   flex: 1.25,
    //   renderCell: (params) => <BasicTooltip title={params.row.upd_dtm} />,
    //   valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    // },
  ];

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        // onClose={onClose}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* Bar header S */}
        <AppBar
          sx={{
            position: "relative",
            boxShadow: 0,
            // boxShadow: "none",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Toolbar
            sx={{
              // background: "#468ECD"
              background: "#fff",
            }}
          >
            <IconButton
              edge="start"
              // color="inherit"
              color="#9e9e9e"
              // #005081
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "#000000de",
              }}
              variant="h6"
              component="div"
            >
              나가기
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        {/* Bar header E */}
        <Container sx={{ pt: 8, pb: 6 }} maxWidth="sm">
          <Typography
            sx={{
              flex: 1,
              mb: 1,
              color: "#000000de",
            }}
            variant="h6"
            component="div"
          >
            결제 요청 담당자 선택
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "2% 0 13%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* Search Area S */}

            <SearchBarPaymentRequest onSearch={handleSearch} />
            {/* </Toolbar> */}
            {/* </AppBar> */}
            {/* Search Area E */}
            {/* data Area S */}
            {grHandlerList && (
              <>
                <DataGridModalA
                  rows={grHandlerList}
                  columns={columns}
                  // onCellClick={onCellClick}
                  handleOnCellClick={handleOnCellClick}
                  // autoCloseText="* 담당자를 선택 후 결제요청 버튼을 누르세요."
                  autoCloseText="* 담당자를 선택 후 확인버튼을 누르면 결제요청이 진행 됩니다."
                  selectedUserId={selectedUserId}
                  // finalClickInfo={finalClickInfo}
                  // onFinalClickInfoChange={onFinalClickInfoChange}
                />
              </>
            )}
            {/* data Area E */}
          </Box>
        </Container>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationOpen}
        onClose={handleCancelPaymentRequest}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isMessageLoading && <CircularIndeterminate />}
        <DialogTitle id="alert-dialog-title">{"결제 요청 확인"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            정말로 {selectedUser.userName} 님에게 결제를 요청하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmPaymentRequest}
            color="primary"
            autoFocus
          >
            확인
          </Button>
          <Button onClick={handleCancelPaymentRequest} color="primary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
