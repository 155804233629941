// import * as React from "react";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import Card from "@mui/material/";
// import CardActions from "@mui/material/";
// import CardContent from "@mui/material/";
// import CardMedia from "@mui/material/";
// import Button from "@mui/material/";
// import Typography from "@mui/material/";
// import Chip from "@mui/material/";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Chip,
  Divider,
  ButtonBase,
  Tooltip,
  Link,
} from "@mui/material";
import {
  AccessTime,
  CloudDownload,
  Visibility,
  Favorite,
  FavoriteBorder,
  ShoppingCart,
  AddShoppingCart,
  EventAvailableOutlined,
  InsertDriveFileOutlined,
  Settings,
  SettingsOutlined,
  VisibilityOutlined,
  PlayArrow,
  Add,
  ArrowDropUp,
} from "@mui/icons-material";
import { SnackbarMessage } from "components/modal"; // 스낵바 팝업
import Stack from "@mui/material/Stack";

import { useUpdateLikeQuery } from "assets/hooks/useCategoryDetail/useUpdateLikeQuery"; // 좋아요
import { useAddToCartQuery } from "assets/hooks/useCategoryDetail/useAddToCartQuery"; // 장바구니

// "platform_id": "p_id_04",
// "data_title": "타이틀_4",
// "data_main_cat": "건강",
// "data_sub_cat": ["영양제", "영양제", "영양제"],
// "data_desc": "영양제 시장 분석 보고서.",
// "data_size": 300,
// "data_ext": "DOC",
// "data_price": 200000,
// "user_cart": "n",
// "view_cnt": 2000,
// "download_count": 90,
// "likes": "y",
// "like_cnt": 75,
// "reg_date": "2019-08-23"

// 데이터 이미지 data_image_url
// 카테고리 라벨 data_main_cat
// 타이틀 data_title
// 설명 data_desc
// 금액 data_price
// 태그 data_sub_cat
// 등록일자 reg_date
// 다운로드 수 download_count
// 조회수 view_cnt
// 좋아요 likes
// 좋아요 카운트 like_cnt
// 데이터 용량 data_size

// 이미지 파일 이름을 입력받아 배경색을 반환하는 함수
const getBackgroundColorByImageName = (imageName) => {
  // 파일 이름에서 확장자를 제거합니다 (옵션에 따라 필요한 경우에만 사용)
  const cleanImageName = imageName.replace(/\.\w+$/, "");
  // 배경색 객체에서 해당 이미지 이름에 해당하는 색상 코드를 찾습니다
  return backgroundColors[cleanImageName] || "#ffffff"; // 기본값으로 흰색을 반환
};

// const defaultImageUrl = "http://localhost:9001/static/images/logao.png";
// const imageUrl = dataImageUrl || defaultImageUrl;

// const backgroundColors = {
//   "category_img_01.png": "#7FC6E8", // 섬유제품 제조업: 하늘색
//   "category_img_02.png": "#F5A9B8", // 의복 및 모피제품 제조업: 분홍색
//   "category_img_03.png": "#A67C52", // 가죽, 가방 및 신발제품 제조업: 갈색
//   "category_img_04.png": "#5A3E36", // 목재 및 나무제품 제조업: 짙은 갈색
//   "category_img_05.png": "#F5F5DC", // 펄프, 종이 및 종이제품 제조업: 밝은 베이지색
//   "category_img_06.png": "#3D9A9B", // 인쇄 및 기록매제 복제업: 청록색
//   "category_img_07.png": "#7B7B7B", // 코크스, 연탄 및 석유정제품 제조업: 진회색
//   "category_img_08.png": "#FFEC47", // 화학물질 및 화학제품 제조업: 밝은 노란색
//   "category_img_09.png": "#89CFF0", // 의료용 물질 및 의약품 제조업: 밝은 파란색
//   "category_img_10.png": "#F5A455", // 고무제품 및 플라스틱 제조업: 오렌지색
//   "category_img_11.png": "#BEBEBE", // 비금속 광물제품 제조업: 회색
//   "category_img_12.png": "#B7AFA3", // 1차금속 제조업: 철갈색
//   "category_img_13.png": "#C0C0C0", // 금속가공제품 제조업: 실버색
//   "category_img_14.png": "#4A708B", // 전자부품, 컴퓨터, 영상 및 통신장비 제조업: 메탈릭 블루
//   "category_img_15.png": "#98FF98", // 의료, 정밀, 광학기기 및 시계 제조업: 밝은 민트색
//   "category_img_16.png": "#7AD3E6", // 전기장비 제조업: 밝은 청록색
//   "category_img_17.png": "#6B8E23", // 그 밖의 기계 및 장비 제조업: 녹색
//   "category_img_18.png": "#C41E3A", // 자동차 및 트레일러 제조업: 진한 빨간색
//   "category_img_19.png": "#008080", // 그 밖의 운송장비 제조업: 진한 청록색
//   "category_img_20.png": "#DEB887", // 가구 제조업: 따뜻한 목재색
//   "category_img_21.png": "#D3D3D3", // 그 밖의 제품 제조업: 연한 회색
//   "category_img_22.png": "#F4C2C2", // 산업용 기계 및 장비 수리업: 투스카니색
//   "category_img_23.png": "#f0f0f0", // 예시로 추가된 배경색, 실제 적용 시 적절한 산업을 매핑해주세요.
// };

const backgroundColors = {
  category_img_01: "#7FC6E8", // 섬유제품 제조업: 하늘색
  category_img_02: "#F5A9B8", // 의복 및 모피제품 제조업: 분홍색
  category_img_03: "#A67C52", // 가죽, 가방 및 신발제품 제조업: 갈색
  category_img_04: "#5A3E36", // 목재 및 나무제품 제조업: 짙은 갈색
  category_img_05: "#F5F5DC", // 펄프, 종이 및 종이제품 제조업: 밝은 베이지색
  category_img_06: "#3D9A9B", // 인쇄 및 기록매제 복제업: 청록색
  category_img_07: "#7B7B7B", // 코크스, 연탄 및 석유정제품 제조업: 진회색
  category_img_08: "#FFEC47", // 화학물질 및 화학제품 제조업: 밝은 노란색
  category_img_09: "#89CFF0", // 의료용 물질 및 의약품 제조업: 밝은 파란색
  category_img_10: "#F5A455", // 고무제품 및 플라스틱 제조업: 오렌지색
  category_img_11: "#BEBEBE", // 비금속 광물제품 제조업: 회색
  category_img_12: "#B7AFA3", // 1차금속 제조업: 철갈색
  category_img_13: "#C0C0C0", // 금속가공제품 제조업: 실버색
  category_img_14: "#4A708B", // 전자부품, 컴퓨터, 영상 및 통신장비 제조업: 메탈릭 블루
  category_img_15: "#98FF98", // 의료, 정밀, 광학기기 및 시계 제조업: 밝은 민트색
  category_img_16: "#7AD3E6", // 전기장비 제조업: 밝은 청록색
  category_img_17: "#6B8E23", // 그 밖의 기계 및 장비 제조업: 녹색
  category_img_18: "#C41E3A", // 자동차 및 트레일러 제조업: 진한 빨간색
  category_img_19: "#008080", // 그 밖의 운송장비 제조업: 진한 청록색
  category_img_20: "#DEB887", // 가구 제조업: 따뜻한 목재색
  category_img_21: "#D3D3D3", // 그 밖의 제품 제조업: 연한 회색
  category_img_22: "#F4C2C2", // 산업용 기계 및 장비 수리업: 투스카니색
  category_img_23: "#f0f0f0", // 예시로 추가된 배경색, 실제 적용 시 적절한 산업을 매핑해주세요.
};
const StackSX = {
  display: "flex",
  flexDirection: "row",
  // mb: 2,
};
export default function CategoryInfoCard({
  dataImageUrl, // 이미지
  alt,
  dataTitle, // 제목
  dataDesc, // 설명
  dataPrice, // 금액
  dataMainCat, // 메인 카테고리
  // data_sub_cat,
  dataDate, // 등록일자 ** 1번
  downloadCount, // 다운로드수 ** 2번
  viewCnt, // 조회수 ** 4번
  // likeCount, // 좋아요 수 **3번
  initialLikeCount,

  dataExt, // 파일확장자 명
  userLike, // 좋아요 Y N
  userCart, // 장바구니 Y N
  userBuy, // 구매여부 Y N
  // platform_main_id,
  dataTitleOnClick, // 타이틀 클릭시 상세 페이지
  dataImageOnClick, // 이미지 클릭시 상세 페이지
  platform_main_id,
  // platform_main_id,

  dataSubCat, // 해시태그
  dataSize, // 용량

  dataCompanyName, // 회사이름 > 회사이름으로 검색해야되서
  // onClick,
  detailContentClick, // hoverBox
}) {
  // let access = sessionStorage.getItem("access");
  // let access = sessionStorage.getItem("access");
  // let refresh = sessionStorage.getItem("refresh");
  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("refresh");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("##pathname", pathname);
  // const loader = () => {
  //   navigate("/login");
  //   console.log("loader", loader);
  // };

  // const { platform_main_id } = useParams();
  const { mutate: updateLike } = useUpdateLikeQuery(); // 좋아요
  // const { mutate: addToCartMutation } = useAddToCartQuery(); // 장바구니
  // const { mutate: buyReqMutation } = usePlatformBuyReqQuery([]); // 구매 요청
  const addToCartMutation = useAddToCartQuery(); // 장바구니

  // const [likeStatus, setLikeStatus] = useState(details?.user_like || "N"); //
  const [likeStatus, setLikeStatus] = useState(userLike === "Y"); // 좋아요
  const [likeCount, setLikeCount] = useState(initialLikeCount);
  const [isInCart, setInCart] = useState(userCart); // 장바구니

  console.log("##user_like", userLike);
  const [isPopupOpen, setPopupOpen] = useState(false); // 장바구니, 구매요청
  const [popupMessage, setPopupMessage] = useState(""); // 장바구니, 구매요청

  const [tooltipShowMore, setTooltipShowMore] = useState(false);
  const [open, setOpen] = useState(true);

  const handleTooltipShowMore = () => {
    setTooltipShowMore(true);
  };

  // const defaultImageUrl = "/path/to/default-image.jpg";
  //   // 이미지 URL에서 파일 이름만 추출
  //   const imageName = dataImageUrl.split("/").pop();
  // 이미지 URL에서 파일 이름만 추출하는 부분을 수정
  //   const imageName = dataImageUrl
  //     ? dataImageUrl.split("/").pop()
  //     : "defaultImage.png";
  //   // const imageName = dataImageUrl.split("/").pop();
  //   const backgroundColor = getBackgroundColorByImageName(imageName);
  // 이미지 URL에서 파일 이름만 추출하는 부분을 수정
  const imageName = dataImageUrl
    ? dataImageUrl.split("/").pop()
    : "defaultImage.png";
  // 배경색을 동적으로 설정
  const backgroundColor = getBackgroundColorByImageName(imageName);
  const cleanImageName = imageName.replace(/\.\w+$/, "");
  console.log("Original image name:", imageName);
  console.log("Clean image name:", cleanImageName);
  console.log("Resolved color:", backgroundColors[cleanImageName]);

  // 좋아요
  console.log("???likeStatus", likeStatus);
  const handleLikeClick = () => {
    if (!access || access === "null") {
      navigate("/login", { replace: true });
      console.log("####@@@@loader", navigate);
      return; // 추가 실행 중지
    }
    // console.log("");
    // const newLikeStatus = likeStatus === "Y" ? "N" : "Y";
    // console.log("#@$likeStatus", likeStatus);
    // updateLike(
    //   { platform_main_id, userLike: newLikeStatus },
    //   {
    //     onSuccess: () => {
    //       setLikeStatus(newLikeStatus); // 성공적으로 업데이트 되면 상태 업데이트
    //     },
    //   },
    // );
    const newLikeStatus = !likeStatus;
    const newLikeCount = likeStatus ? likeCount - 1 : likeCount + 1; // 좋아요 상태에 따라 카운트 조정
    updateLike(
      { platform_main_id, userLike: newLikeStatus ? "Y" : "N" },
      {
        onSuccess: () => {
          setLikeStatus(newLikeStatus);
          setLikeCount(newLikeCount);
        },
        onError: () => {
          // 에러 처리: 사용자에게 실패 메시지 표시 등
          alert("좋아요 업데이트에 실패했습니다.");
        },
      },
    );
  };
  console.log("##accessToken", access);
  console.log("##문자열accessToken", typeof access);
  // 장바구니
  // function redirectToLogin(navigate) {
  //   console.log("Redirecting to login due to missing access token");
  //   navigate("/login", { replace: true });
  // }

  const handleAddToCart = () => {
    console.log("Current cart status before processing:", isInCart);
    // 토큰 존재 여부 확인
    // alert(typeof access);
    // if (!access) {
    //   alert(access);
    // }
    // console.log("##access", access);
    if (
      !access ||
      // access === null ||
      // access === false ||
      // access === "" ||
      access === "null"
    ) {
      // if (access === null) {
      // navigate("/login"); // 토큰이 없으면 로그인 페이지로 리다이렉트
      navigate("/login", { replace: true });
      console.log("####@@@@loader", navigate);
      return; // 추가 실행 중지
    }

    if (userCart === "Y") {
      setPopupMessage("이미 장바구니에 담겨져 있습니다.");
      setPopupOpen(true);
      // setInCart(true);
    } else if (userBuy === "Y") {
      setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
      setPopupOpen(true);
    } else {
      addToCartMutation.mutate(platform_main_id, {
        onSuccess: (data) => {
          setPopupMessage("장바구니에 담겼습니다.");
          setPopupOpen(true);
          setInCart(true);
          setInCart("Y"); // 장바구니 상태 업데이트
        },
        onError: (error) => {
          // 400번대 오류 처리

          if (error.response && error.response.status === 406) {
            setPopupMessage("같은 회사 소속입니다.");
            setPopupOpen(true);
          } else if (error.response.status === 409) {
            setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
            setPopupOpen(true);
          } else if (
            // userCart === "Y" &&
            error.response &&
            error.response.status === 419
          ) {
            setPopupMessage("이미 장바구니에 담겨져 있습니다.");
            setPopupOpen(true);
          } else {
            setPopupMessage("오류가 발생했습니다. 다시 시도해주세요.");
            setPopupOpen(true);
          }
          console.log("Failed to add to cart, status remains:", isInCart);
        },
      });
    }
    console.log("loader", handleAddToCart);
  };
  // const fullTagsString = dataSubCat.join(", ");
  const fullTagsString = dataSubCat.join(", ");

  return (
    <Grid item xs={12} md={3}>
      <Card
        sx={{
          maxWidth: 345,
          minWidth: "auto",
          position: "relative",
          "&:hover .hoverBox": {
            opacity: 1,
            visibility: "visible",
          },
          //   backgroundColor: getBackgroundColorByImageName(imageName),
        }}
      >
        {/* <Box> */}
        <Box
          sx={{
            backgroundColor,
            // height: 140,
            width: "100%",
            height: "auto",
            display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            p: 0,
            position: "relative",
            // background: "linear-gradient(to top, rgba(0,0,0,0.5), transparent)",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              // height: "50%", // 그라데이션 높이 조절
              height: "25%", // 그라데이션 높이 조절
              background:
                // "linear-gradient(to top, rgba(0,0,0,0.39), transparent)",
                "linear-gradient(to top, rgba(0,0,0,0.79), transparent)",
              zIndex: 1,
            },
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: "100%",
              width: "auto",
              maxWidth: "100%",
              cursor: "pointer",

              // p: 0,
            }}
            // sx={{
            //   height: 140,
            //   width: "auto",
            //   margin: "0 auto",
            //   // p: 1,
            //   // backgroundColor: "#f0f0f0",
            //   // backgroundColor: getBackgroundColorByImageName(imageName), // 배경색 동적으로 설정
            //   // backgroundSize: "cover",
            // //   backgroundRepeat: "no-repeat",
            // //   backgroundPosition: "center",
            // //   backgroundSize: "contain",
            // }}
            //   component="img"
            image={dataImageUrl}
            // image={dataImageUrl || defaultImageUrl} // 이미지 URL이 없으면 기본 이미지 사용
            // alt={dataTitle}
            // alt={dataTitle || "Default Title"}
            alt={dataTitle || "Default Image"}
            onClick={dataImageOnClick}
            // alt={alt}
            // onError={(e) => (e.target.src = "/path/to/default-image.jpg")} // 로드 실패 시 대체 이미지 경로
          />
          <Chip
            label={dataMainCat}
            // variant="outlined"
            sx={{
              zIndex: 1,
              mt: 1,
              backgroundColor: "#29314A",
              // borderColor: "#468dcc",
              // color: "#468dcc",
              color: "#fff",
              p: 0,
              height: 24,
              position: "absolute",
              borderRadius: "0 16px 16px 0",
              fontWeight: "600",
            }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              zIndex: 2,
              mt: 1,
              position: "absolute",
              color: "#fff",
              bottom: "0",
              right: 0,
              minHeight: "31px",
            }}
          >
            {/* <Button
              size="small"
              onClick={handleLikeClick}
              sx={{ color: "#fff" }}
              startIcon={
                likeStatus ? <Favorite color="error" /> : <FavoriteBorder />
              }
              // color={userCart === "y" ? "primary" : "default"}
            >
              {likeCount}
            </Button> */}
            <Stack direction="row" spacing={1} sx={{ pr: 1.5 }}>
              <CloudDownload fontSize="small" />
              <Typography variant="body2">{downloadCount}</Typography>
            </Stack>
            {/* <Typography variant="body2">{likeCount} Likes </Typography> */}
          </Stack>
        </Box>
        {/* </Box> */}

        <CardContent sx={{ p: 1.5, pt: 0, pb: "14px !important" }}>
          {/* <Box sx={{ display: "flex", flexDirection: "row" }}> */}
          <Grid container spacing={1}>
            <Grid item xs={10.5}>
              {/* <Tooltip title={fullTagsString} placement="bottom-start"> */}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  // mt: 1,
                  mt: 0.5,

                  flexWrap: "nowrap", // 한 줄로 처리하기 위해 flex-wrap을 nowrap으로 설정
                  overflow: "hidden", // 넘치는 텍스트를 숨김
                  // textOverflow: "ellipsis", // 넘치는 텍스트 생략 처리
                  whiteSpace: "nowrap",
                  wordWrap: "break-word",
                }}
              >
                {/* <div
                    style={{
                      display: "flex",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  > */}
                {Array.isArray(dataSubCat) && (
                  <Chip
                    // key={index}
                    // label={tag}
                    // label={dataSubCat.map((tag, index) => `${tag}`).join(" ")}
                    label={dataSubCat
                      .map((tag) => `${tag}`)
                      .join("\u00A0\u00A0")}
                    sx={{
                      // mt: 1,
                      // mr: 1,

                      background: "0",

                      //
                      fontSize: "0.875rem",
                      maxWidth: "100%",

                      // display: "block",
                      // whiteSpace: "nowrap",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // padding: "0 8px 0 0 ", // 텍스트와 Chip 가장자리 사이에 여백 추가

                      "& .MuiChip-label": {
                        p: 0,
                      },
                    }}
                  />
                )}
                {/* </div> */}
              </Stack>
              {/* {!tooltipShowMore && (
                  <Button
                    onClick={handleTooltipShowMore}
                    sx={{ position: "absolute", right: 0, bottom: 0 }}
                  >
                    더 보기
                  </Button>
                )} */}
              {/* </Tooltip> */}
            </Grid>
            <Grid item xs={1.5} sx={{ zIndex: 2 }}>
              <Button
                // size="small"
                onClick={handleAddToCart}
                // access={access}
                sx={{ p: 0, mt: 1.25, minWidth: "0", ml: 1 }}
                startIcon={
                  isInCart === "Y" ? (
                    <ShoppingCart
                      sx={{
                        color: "#468ECD",
                        fontSize: "24px !important",
                        // mt: 1.25,
                      }}
                    />
                  ) : (
                    <AddShoppingCart
                      sx={{
                        color: "#468ECD",
                        fontSize: "24px !important",
                        // mt: 1.25,
                      }}
                    />
                  )
                }
                // color={userCart === "y" ? "primary" : "default"}
              >
                {/* {userCart === "Y" ? "In Cart" : "Add to Cart"} */}
                {/* {userCart === "Y" ? "장바구니 담김" : "장바구니 추가"} */}
              </Button>
            </Grid>
          </Grid>
          {/* </Box> */}
          <Typography
            onClick={dataTitleOnClick}
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              mt: 1,
              minHeight: "64px",
              maxHeight: "64px",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              wordWrap: "break-word",
              display: "-webkit-box",
              overflow: "hidden",
            }}
          >
            {dataTitle}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.8125rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            gutterBottom
            component="div"
          >
            {dataCompanyName}
          </Typography>
          {/* 회사명 */}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              // minHeight: "40px",
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              wordWrap: "break-word",
              WebkitLineClamp: 2,
            }}
          >
            {dataDesc}
            {/* {dataDesc || "No description available."} */}
          </Typography>
          <Typography
            variant="h5"
            color="text.primary"
            sx={{
              mt: 2,
              color: "#29314A",
              // fontWeight: "600",
              fontFamily: "sans-serif",
              fontWeight: "900",
              textAlign: "right",
            }}
          >
            {dataPrice}
            <Typography
              variant="h6"
              component="span"
              sx={{ fontFamily: "sans-serif" }}
            >
              원
            </Typography>
          </Typography>
          {/* 금액 */}
          {/* <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "8px 0 16px 0",
            }}
          > */}
          {/* <Divider
            sx={{ mb: 1, "& .MuiDivider-wrapper": { pl: 0.5, pr: 0.5 } }}
          >
            <Button
              sx={{
                fontSize: "0.75rem",
                p: 0,
                // p: 2,
                textTransform: "none",
                fontWeight: "400",
                color: "#00000099",
                // position: "absolute",
                "&:hover .hoverBox": {
                  opacity: 1,
                  visibility: "visible",
                },
              }}
              startIcon={<Add sx={{ color: "#00000099" }} />}
            >
              더 보기
            </Button>
          </Divider> */}
          {/* </Grid> */}
          <Divider sx={{ mt: 1, mb: 1.5 }} />
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{}}>
              <Stack direction="row" gap={0.5}>
                <VisibilityOutlined
                  sx={{ color: "#B0B0B0", fontSize: "14px", mt: 0.25 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    // color: "#c0c0c0",
                    color: "#B0B0B0",
                    fontSize: "12px",
                  }}
                >
                  {viewCnt}
                </Typography>
              </Stack>
              {/* Views */}
            </Grid>
            <Grid item xs={6} sx={{}}>
              <Stack direction="row" gap={0.5}>
                <SettingsOutlined
                  sx={{
                    // color: "#c0c0c0",
                    color: "#B0B0B0",
                    fontSize: "14px",
                    mt: 0.25,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    // color: "#0000003b",
                    color: "#B0B0B0",
                    fontSize: "12px",
                  }}
                >
                  {dataSize}KB
                </Typography>
              </Stack>
              {/* Size  */}
            </Grid>
            <Grid item xs={6} sx={{}}>
              <Stack
                direction="row"
                // spacing={1}
                // alignItems="center"
                gap={0.5}
                // sx={StackSX}
                // sx={{ fontSize: "20px" }}
              >
                <EventAvailableOutlined
                  sx={{
                    // color: "#0000003b",
                    color: "#B0B0B0",
                    fontSize: "14px",
                    mt: 0.25,
                  }}
                  // sx={{ fontSize: "0.8125rem" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "12px",
                  }}
                >
                  {dataDate}
                </Typography>
              </Stack>
              {/* Date 날짜 */}
            </Grid>

            <Grid item xs={3.5} sx={{}}>
              <Stack direction="row" gap={0.5}>
                <InsertDriveFileOutlined
                  sx={{ color: "#B0B0B0", fontSize: "14px", mt: 0.25 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "12px",
                  }}
                >
                  {dataExt}
                </Typography>
              </Stack>
              {/* File Type */}
            </Grid>
            <Grid
              item
              xs={2.5}
              sx={{
                paddingTop: "0 !important",
                mt: 0,
                position: "relative",
                zIndex: 2,
              }}
            >
              <Button
                size="small"
                onClick={handleLikeClick}
                sx={{
                  color: "#DA1E28",
                  position: "absolute",
                  pr: 0,
                  bottom: "-4px",
                  bottom: "-10px",
                }}
                startIcon={
                  likeStatus ? (
                    <Favorite
                      color="error"
                      sx={{ fontSize: "24px !important" }}
                    />
                  ) : (
                    <FavoriteBorder
                      color="error"
                      sx={{ fontSize: "24px !important" }}
                    />
                  )
                }
                // color={userCart === "y" ? "primary" : "default"}
              />
              {/* {likeCount}
                    </Button> */}
              {/* Views */}
            </Grid>
          </Grid>
          {/* <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "8px 0 16px 0",
            }}
          >
            <Button
              sx={{
                fontSize: "0.75rem",
                p: 0,
                textTransform: "none",
                fontWeight: "400",
                color: "#00000099",
                position: "absolute",
              }}
              startIcon={<Add sx={{ fontSize: "24px !important" }} />}
            >
              Add More
            </Button>
          </Grid> */}
        </CardContent>

        {/* <CardActions>
          <Button
            size="small"
            onClick={handleAddToCart}
            // access={access}
            startIcon={
              isInCart === "Y" ? <ShoppingCart /> : <AddShoppingCart />
            }
            // color={userCart === "y" ? "primary" : "default"}
          >
            {userCart === "Y" ? "In Cart" : "Add to Cart"}
            {userCart === "Y" ? "장바구니 담김" : "장바구니 추가"}
          </Button>
        </CardActions> */}
        {/* <Tooltip
          // placement="top-start"
          open={open}
          // sx={{position: "absolute"}}
          // variant="outlined"
          // variant="solid"
          // variant="soft"
          // variant="plain"
          arrow
          // color="success"
          // 아래 위치 주정하는 옵션
          // PopperProps={{
          //   modifiers: [
          //     {
          //       name: "offset",
          //       options: {
          //         offset: [50, 50], // 툴팁의 x, y 위치를 조정
          //       },
          //     },
          //   ],
          // }}
          // sx={{ position: "relative" }}
          componentsProps={{
            tooltip: {
              sx: {
                // position: "absolute",
                // left
                position: "absolute",
                backgroundColor: "#fbfcfe",
                border: "1px solid #cdd7e1",
                boxShadow: `0 0 #000, 0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08)`,

                // width: "auto",
                // position: "unset",
                // height: "100%",
                // top: "50%", // 원하는 위치로 조정
                // left: "50%", // 원하는 위치로 조정
                // margin: "0 auto",
                //     boxShadow: `
                //   var(--joy-shadowRing, 0 0 #000),
                //   0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21, 21, 21) / var(--joy-shadowOpacity, 0.08)),
                //   0px 2px 4px 0px rgba(var(--joy-shadowChannel, 21, 21, 21) / var(--joy-shadowOpacity, 0.08))
                // `,
              },
            },
          }}
          // styles={{
          //   '.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip':
          //     {
          //       transformOrigin: "center bottom",
          //       marginBottom: "14px",
          //       backgroundColor: "green",
          //     },
          // }}
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // maxWidth: 280,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Typography sx={{ color: "#636b74", fontSize: "0.8125rem" }}>
                  {dataCompanyName}
                </Typography>
                <Typography sx={{ color: "#636b74", fontSize: "0.8125rem" }}>
                  {dataDate}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={0.1}
                gap={0.5}
                alignItems="center"
                sx={{
                  // mt: 1,

                  flexWrap: "wrap",
                }}
              >
                {Array.isArray(dataSubCat) &&
                  dataSubCat.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      // variant="outlined"
                      sx={{
                        // mt: 1,
                        // mr: 1,
                        fontSize: "0.75rem",
                        // height: "auto",
                        height: "24px",
                        // p: 0,
                        "& .MuiChip-label": { pl: 1, pr: 1 },
                      }}
                    />
                  ))}
              </Stack>
              <Box sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
                <PlayArrow
                  // color="success"
                  fontSize="small"
                  sx={{ color: "#468ECD" }}
                />
                <Box sx={{ color: "#000000de", marginTop: "-2px" }}>
                  <Typography
                    onClick={dataTitleOnClick}
                    // gutterBottom
                    // variant="h6"
                    // component="div"
                    sx={{
                      fontWeight: "600",
                      fontSize: "0.875rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 236,
                      // whiteSpace: "nowrap",
                    }}
                  >
                    {dataTitle}
                  </Typography>
                  <Typography
                    // color="text.secondary"
                    // fontSize="sm"
                    sx={{
                      fontSize: "0.875rem",
                      display: "-webkit-box",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitBoxOrient: "vertical",
                      wordWrap: "break-word",
                      WebkitLineClamp: 5,
                      // maxWidth: 236,
                      // whiteSpace: "nowrap",
                    }}
                  >
                    {dataDesc}
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
        /> */}
        <Box
          className="hoverBox"
          onClick={detailContentClick}
          sx={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            // maxWidth: 280,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            p: 1,
            position: "absolute",
            top: 0,
            left: 0,
            // backgroundColor: "rgba(0, 0, 0, 0.8)",
            // border: "1px solid #cdd7e1",
            backgroundColor: "#fbfcfe",
            // backgroundColor: "rgba(251, 252, 254, 0.9)",
            // boxShadow: `0 0 #000, 0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08)`,
            backgroundColor: "rgba(255, 255, 255, 0.9)", // 흰색 배경에 약간의 불투명도 적용
            color: "#fff",
            opacity: 1,
            visibility: "hidden",
            // transition: "opacity 0.3s, visibility 0.3s",
            transition: "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out",
            zIndex: 1,
          }}
        >
          <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
            <Typography sx={{ color: "#636b74", fontSize: "0.8125rem" }}>
              {dataCompanyName}
            </Typography>
            <Typography sx={{ color: "#636b74", fontSize: "0.8125rem" }}>
              {dataDate}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={0.1}
            gap={0.5}
            alignItems="center"
            sx={{
              // mt: 1,

              flexWrap: "wrap",
            }}
          >
            {Array.isArray(dataSubCat) &&
              dataSubCat.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  // variant="outlined"
                  sx={{
                    // mt: 1,
                    // mr: 1,
                    fontSize: "0.75rem",
                    // height: "auto",
                    height: "24px",
                    // p: 0,
                    "& .MuiChip-label": { pl: 1, pr: 1 },
                  }}
                />
              ))}
          </Stack>
          <Box sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
            <PlayArrow
              // color="success"
              fontSize="small"
              sx={{ color: "#468ECD" }}
            />
            <Box sx={{ color: "#000000de", marginTop: "-2px" }}>
              <Typography
                onClick={dataTitleOnClick}
                // gutterBottom
                // variant="h6"
                // component="div"
                sx={{
                  fontWeight: "600",
                  fontSize: "0.875rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 236,
                  // whiteSpace: "nowrap",
                }}
              >
                {dataTitle}
              </Typography>
              <Typography
                // color="text.secondary"
                // fontSize="sm"
                sx={{
                  fontSize: "0.875rem",
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitBoxOrient: "vertical",
                  wordWrap: "break-word",
                  WebkitLineClamp: 5,
                  // maxWidth: 236,
                  // whiteSpace: "nowrap",
                }}
              >
                {dataDesc}
              </Typography>
              {/* <Chip size="sm" color="danger" sx={{ fontWeight: "lg" }}>
                    bug
                  </Chip>
                  <Chip
                    size="sm"
                    color="primary"
                    sx={{ ml: 1, fontWeight: "lg" }}
                  >
                    package: system
                  </Chip> */}
            </Box>
          </Box>
        </Box>
        {/* hoverBox */}
      </Card>

      <SnackbarMessage
        open={isPopupOpen}
        // onClose={() => setPopupOpen(false)}
        handleClose={() => setPopupOpen(false)}
        message={popupMessage}
        autoHideDuration={10000}
      />
    </Grid>
  );
}
