// import React from 'react'
import { useQuery } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 카테고리 페이지 메인카테고리
const fetchMainCategory = () => {
  return client.get(`/platformitem/category`);
};
export const useMainCatQuery = () => {
  return useQuery({
    queryKey: ["main-category"],
    queryFn: fetchMainCategory,
    select: (result) => result.data.data,
    // staleTime: 300000, // 5분
  });
};

// 모달계약서 그룹 선택 목록 데이터
const fetchSelectList = () => {
  //   return client.post(`/group/list`);
  return client.post(`/group/contract_list`, {});
};
export const useGroupSelectQuery = () => {
  return useQuery({
    queryKey: ["group-select-list"],
    queryFn: fetchSelectList,
    select: (result) => result.data.data,
    onSuccess: (data) => {
      console.log("Fetched Group List Data:", data); // 데이터 출력
    },
    onError: (error) => {
      console.error("그룹 목록 가져오기 오류:", error); // 에러 출력
    },
    // staleTime: 300000, // 5분
  });
};

// export const useGroupSelectQuery = () => {
//   return useQuery({
//     queryKey: ["group-select-list"],
//     queryFn: fetchSelectList,
//     select: (result) => result.data.data,
//     //   staleTime: 300000, // 5분
//   });
// };
