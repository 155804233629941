import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { Container, Typography, Box, Button } from "@mui/material";

const TestSignUpVerificationQR = ({ handleCloseQR }) => {
  //   const navigate = useNavigate();
  //   const [signupUrl, setSignupUrl] = useState(true);
  const [tokenValue, setTokenValue] = useState("");

  useEffect(() => {
    // // 여기서는 백엔드 통신 대신 가상의 주소를 사용합니다.
    // // 실제 구현에서는 이 부분을 백엔드 통신 로직으로 대체합니다.
    // const fakeRedirectUrl = "https://example.com/signup?token=123456";
    // setSignupUrl(fakeRedirectUrl);

    // // 이 예제에서는 제한 시간 로직을 사용하지 않습니다.
    // // 회원가입 인증 QR 코드는 제한 시간 없이 유효합니다.
    const fakeRedirectUrl = "https://example.com/signup?token=123456";
    const urlParams = new URLSearchParams(new URL(fakeRedirectUrl).search);
    const token = urlParams.get("token");
    setTokenValue(token);
    console.log("## QRCodeToken:", token);
  }, []);

  return (
    // <Container maxWidth="sm" sx={{ mt: 4 }}>
    //   <Typography variant="h4" component="h1" gutterBottom>
    //     회원가입
    //   </Typography>
    //   <Box
    //     sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    //   >
    //     {signupUrl && (
    //       <QRCode
    //         size={256}
    //         value={signupUrl}
    //         style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    //       />
    //     )}
    //     <Typography variant="body1" sx={{ mt: 2 }}>
    //       위 QR 코드를 스캔하여 회원가입을 계속하세요.
    //     </Typography>
    //   </Box>
    // </Container>
    <Container maxWidth="sm" sx={{ mt: 4, position: "relative" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        회원가입
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {tokenValue && (
          <QRCode
            size={256}
            value={tokenValue} // 'token' 값만을 QR 코드로 변환합니다.
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          />
        )}
        <Typography variant="body1" sx={{ mt: 2 }}>
          위 QR 코드를 스캔하여 회원가입을 계속하세요.
        </Typography>
        <Button
          onClick={handleCloseQR}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          닫기
        </Button>{" "}
        {/* 닫기 버튼 추가 */}
      </Box>
    </Container>
  );
};

export default TestSignUpVerificationQR;

// import qrcode

// # 주소(URL) 추출 과정은 클라이언트 측에서 처리되어야 하므로 여기서는 예제 URL을 사용합니다.
// url = "https://example.com/your-unique-url-for-registration"

// # QR 코드 생성
// qr = qrcode.QRCode(
//     version=1,
//     error_correction=qrcode.constants.ERROR_CORRECT_L,
//     box_size=10,
//     border=4,
// )
// qr.add_data(url)
// qr.make(fit=True)

// # QR 코드 이미지 생성 및 저장
// img = qr.make_image(fill_color="black", back_color="white")
// img.save("registration_qr.png")

// # 위 코드를 실행하면 현재 디렉터리에 'registration_qr.png' 파일이 생성됩니다.
