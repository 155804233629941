import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Container,
  IconButton,
  Box,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function CategorySearchAddOptions({
  fromValue,
  toValue,
  fromOnChange,
  toOnChange,
  //
  minPrice,
  maxPrice,
  minOnChange,
  maxOnChange,
  minName,
  maxName,

  //
  // onClick,
  onSearch,
  //
  fromDtm,
  toDtm,
  resetForm,
  //
  // setFromDtm,
  // setToDtm,
  // setMinPrice,
  // setMaxPrice,
}) {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [amount, setAmount] = useState("");
  //날짜

  //
  // const [provider, setProvider] = useState("");
  const [expanded, setExpanded] = useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  // 확장 상태를 토글하는 함수
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // 240524 Add S
  const handleSearch = () => {
    console.log("검색 시작!!!!:", fromDtm, toDtm, minPrice, maxPrice);
    if (onSearch) {
      onSearch(fromDtm, toDtm, minPrice, maxPrice);
      // onSearch(fromValue, toValue, minPrice, maxPrice);
    }
  };
  // const handleSearch = () => {
  //   console.log("Search button clicked"); // 함수 호출 확인 로그
  //   console.log(
  //     "From:",
  //     fromDtm,
  //     "To:",
  //     toDtm,
  //     "Min Price:",
  //     minPrice,
  //     "Max Price:",
  //     maxPrice,
  //   ); // 파라미터 로그

  //   if (onSearch) {
  //     console.log("Calling onSearch function"); // onSearch 호출 전 로그
  //     onSearch(fromDtm, toDtm, minPrice, maxPrice);
  //   } else {
  //     console.error("onSearch function is not provided"); // onSearch 함수가 제공되지 않았을 경우 오류 로그
  //   }
  // };

  // const handleSearch = () => {
  //   // 이 함수 내에서 직접 날짜 형식 지정
  //   const formattedFrom = fromDtm ? dayjs(fromDtm).format("YYYY-MM-DD") : "";
  //   const formattedTo = toDtm ? dayjs(toDtm).format("YYYY-MM-DD") : "";

  //   console.log("#### 카테고리Formatted fromDate:", formattedFrom);
  //   console.log("#### 카테고리Formatted toDate:", formattedTo);

  //   // 포맷된 날짜를 사용하여 검색 파라미터 업데이트
  //   if (onSearch) {
  //     onSearch(formattedFrom, formattedTo, minPrice, maxPrice);
  //   }
  // };

  // // 클릭 조회 버튼
  // function searchButton(e) {
  //   if (onClick) {
  //     onClick(e);
  //     return;
  //   }
  //   console.log("###카테고리검색버튼", searchButton);
  //   // call your method here
  //   e.preventDefault();
  //   alert("###카테고리 searchButton", searchButton);
  //   setFromDtm(e.target.value);
  //   setToDtm(e.target.value);
  // }

  // 240524 Add E

  //

  //

  //

  // // 초기화 함수
  // const resetForm = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  //   setAmount("");
  //   setProvider("");
  // };

  // const resetForm = () => {
  //   setFromDtm(null);
  //   setToDtm(null);
  //   setMinPrice("");
  //   setMaxPrice("");
  //   // 필요하다면 다른 상태들을 초기화하거나 새 검색을 수행
  // };

  //

  //
  //

  // const TrapezoidButton = styled(IconButton)(({ theme }) => ({
  //   position: "relative",
  //   // display: "inline-block",
  //   padding: "4px 12px",
  //   // padding: theme.spacing(0.5, 2), // 낮은 높이 설정
  //   cursor: "pointer",
  //   // backgroundColor: "#fff",
  //   borderRadius: "0",
  //   // backgroundColor: "pink",

  //   "&::before": {
  //     content: '""',
  //     position: "absolute",
  //     top: "-1px",
  //     left: 0,
  //     right: 0,
  //     bottom: 0,
  //     zIndex: 0,
  //     border: "1px solid #e0e0e0",
  //     // background: "#fff",e0e0e0
  //     background: "#e0e0e0",
  //     // clipPath: "polygon(10% 0, 90% 0, 100% 100%, 0 100%)",
  //     clipPath: "polygon(0 0, 100% 0, 90% 100%, 10% 100%)",
  //     // clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)", // 사다리꼴 클립 패스
  //     borderRadius: "0 0 10px 10px", // 하단 테두리 둥글기
  //   },
  //   // "&::before": {
  //   //   content: '""',
  //   //   position: "absolute",
  //   //   top: 0,
  //   //   left: "10%", // 사다리꼴 왼쪽 각도
  //   //   right: "10%", // 사다리꼴 오른쪽 각도
  //   //   bottom: 0,
  //   //   background: "#fff", // 내부 색상
  //   //   border: "1px solid #e0e0e0", // 테두리 색상
  //   //   borderRadius: "0 0 10px 10px", // 하단 테두리 둥글기
  //   //   clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)", // 사다리꼴 클립 패스
  //   // },
  //   "& > .MuiIconButton-root": {
  //     position: "relative",
  //     zIndex: 1,
  //     "&:hover": {
  //       // backgroundColor: "transparent",
  //       // backgroundColor: "red",
  //     },
  //   },
  // }));

  const inputFormat = "YYYY-MM-DD"; // 두 선택기 모두에 대한 일관된 형식
  const mask = "____-__-__"; // 공통 마스크
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <div style={{ borderBottom: "1px solid #e0e0e0" }}></div> */}
      <Box sx={{ borderBottom: "1px solid #e0e0e0", textAlign: "center" }}>
        <Accordion
          expanded={expanded}
          onChange={handleToggle}
          sx={{
            boxShadow: "none",
            "&:before": { display: "none" },
            background: "none",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
          }}
        >
          {/* AccordionSummary의 역할을 수행하도록 설정, "검색 옵션" 텍스트는 제거 */}
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ visibility: "hidden" }}
            // sx={{
            //   display: "flex",
            //   background: "0",
            //   position: "relative",
            //   // position: "absolute",
            //   // bottom: "80px",
            //   zIndex: 1000,
            //   "& .MuiAccordionSummary-content": {
            //     justifyContent: "center",
            //   },
            // }}
          >
            <Typography>검색 옵션</Typography>
            {/* <Box sx={{ position: "absolute", bottom: "80px", zIndex: 1000 }}> */}
            {/* <Box>
              <Stack gap={1} sx={{ display: "flex", flexDirection: "row" }}>
                <IconButton sx={{ p: 0 }}>
                  <ExpandMoreIcon />
                </IconButton>
                <Typography
                // sx={{ flexGrow: 1, textAlign: "right", marginRight: 2 }}
                >
                  검색 옵션 Test
                </Typography>
              </Stack>
            </Box> */}
          </AccordionSummary>
          <Container maxWidth="lg">
            <AccordionDetails
              sx={{
                pt: 0,
                pb: 2,
                position: "relative",
                // borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Stack spacing={3} sx={{ mb: 2 }}>
                <Stack direction="row" spacing={2}>
                  <DatePicker
                    label="시작일"
                    inputFormat={inputFormat}
                    mask={mask}
                    value={fromValue}
                    onChange={fromOnChange}
                    renderInput={(params) => <TextField {...params} />}
                    // value={startDate}
                    // onChange={(newValue) => setStartDate(newValue)}
                    // renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="종료일"
                    inputFormat={inputFormat}
                    mask={mask}
                    value={toValue}
                    onChange={toOnChange}
                    renderInput={(params) => <TextField {...params} />}
                    // value={endDate}
                    // onChange={(newValue) => setEndDate(newValue)}
                    // renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
                {/* <TextField
                  label="금액"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                <Stack direction="row" spacing={2}>
                  <TextField
                    name={minName}
                    label="최소 금액"
                    type="number"
                    value={minPrice}
                    onChange={minOnChange}
                    // onChange={(e) => setMinPrice(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name={maxName}
                    label="최대 금액"
                    type="number"
                    value={maxPrice}
                    onChange={maxOnChange}
                    // onChange={(e) => setMaxPrice(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </Stack>
              {/* 데이터 제공 기관 S */}
              {/* <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <InputLabel id="provider-label">데이터 제공기관</InputLabel>
                <Select
                  labelId="provider-label"
                  id="provider-select"
                  value={provider}
                  label="데이터 제공기관"
                  onChange={(e) => setProvider(e.target.value)}
                >
                  <MenuItem value="">
                    <em>전체</em>
                  </MenuItem>
                  <MenuItem value="관공서">관공서</MenuItem>
                  <MenuItem value="경찰서">경찰서</MenuItem>
                  <MenuItem value="우체국">우체국</MenuItem>
                  <MenuItem value="도서관">도서관</MenuItem>
                </Select>
              </FormControl> */}
              {/* 데이터 제공 기관 E */}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="outlined" onClick={resetForm}>
                  초기화
                </Button>
                {/* 검색요청 기능 정상적으로 작동되고 있으며, 현재 변경할때마다도 요청되고 있어서, 추후 onChange기능만 수정해서 처리하면 될듯 */}
                {/* <Button
                  onClick={handleSearch}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  검색
                </Button> */}
              </Stack>
            </AccordionDetails>
          </Container>
        </Accordion>
      </Box>
      <Container maxWidth="lg" sx={{ textAlign: "center" }}>
        {/* <Stack gap={1} sx={{ display: "flex", flexDirection: "row" }}>
      </Stack> */}
        <IconButton
          onClick={handleToggle}
          color="primary"
          aria-label="More"
          // aria-label={expanded ? "닫기" : "더 보기"}
          sx={{
            backgroundColor: "#e0e0e0",
            border: "1px solid #e0e0e0",
            borderTop: "none",
            borderRadius: "0 0 10px 10px ",
            margin: "-1px auto 0",
            color: "#000000de",
            boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.06)",
            padding: "4px",
            // 내부 엠
            // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // 입체감을 주는 그림자 효과
            // "&:hover": {
            // backgroundColor: "#f5f5f5", // 호버 효과
            // },
            // backgroundColor: "pink",
            // clipPath: "polygon(0 0, 100% 0, 85% 100%, 15% 100%)",
            // transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            // transition: "transform 0.3s",
          }}
          // sx={{
          //   margin: "0 auto", // 가운데 정렬
          //   transform: expanded ? "rotate(180deg)" : "none", // 확장될 때 아이콘 회전
          //   transition: "transform 0.3s",
          // }}
        >
          <ExpandMoreIcon
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
              color: "#0000008a",
            }}
          />
          {/* <ControlPointIcon />  */}
          {/* {expanded ? <ExpandMoreIcon /> : <ExpandMoreIcon />} */}
          <Typography
            sx={{
              zIndex: 1,
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "0.1rem",
              fontFamily: "Roboto",
              color: "#0000008a",
              fontSize: "14px",
            }}
          >
            More
            {/* {expanded ? "닫기" : "더보기"} */}
          </Typography>
        </IconButton>
      </Container>
    </LocalizationProvider>
  );
}
