import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 단일 스텝 데이터 가져오기
const fetchStepData = async (stepId, params) => {
  console.log(`##Fetching data for stepId: ${stepId} with params:`, params);
  const response = await client.get(`/user/mypage/${stepId}`, { params });
  console.log(`@@Data received for stepId: ${stepId}:`, response.data);
  return response.data.data;
};

// 두 개의 스텝 데이터를 병합하여 가져오는 함수
const fetchCombinedStepData = async (stepIds, params) => {
  const responses = await Promise.all(
    stepIds.map((stepId) => fetchStepData(stepId, params)),
  );
  // return responses.flat(); // 모든 응답 데이터를 하나의 배열로 병합

  const combinedData = responses.flat(); // 모든 응답 데이터를 하나의 배열로 병합

  // 'ins_dtm'을 기준으로 데이터 내림차순 정렬
  combinedData.sort((a, b) => new Date(b.ins_dtm) - new Date(a.ins_dtm));
  console.log("##@@ combinedData", combinedData);
  return combinedData;
};

// 단일 또는 병합된 스텝 데이터를 가져오는 훅
export const useDirectSalesStepQuery = (stepId, params, isEnabled = false) => {
  const queryClient = useQueryClient(); // queryClient 인스턴스 접근
  let stepIds;

  // 105 또는 106일 경우 두 개의 스텝 데이터를 병합
  if (stepId === "105" || stepId === "106") {
    stepIds = ["105", "106"];
  } else if (stepId === "109" || stepId === "110") {
    stepIds = ["109", "110"];
  } else {
    stepIds = [stepId]; // 다른 경우 단일 스텝 데이터만 처리
  }

  //   const fetchData = stepIds.length > 1 ? fetchCombinedStepData : fetchStepData;
  const fetchData =
    stepIds.length > 1
      ? () => fetchCombinedStepData(stepIds, params)
      : () => fetchStepData(stepId, params);

  return useQuery({
    queryKey: ["direct_sales_step_data", stepIds.join(","), params],
    queryFn: () => fetchData(stepIds, params),
    enabled: isEnabled, // 쿼리를 자동 실행하지 않고, 필요할 때만 실행하도록 설정
    select: (result) => result,
    onSuccess: () => {
      console.log("데이터 성공적으로 가져옴");
      // 조건에 따라 다른 쿼리들을 무효화
      // queryClient.invalidateQueries(["some_other_query_key"]);
      queryClient.invalidateQueries(["direct_sales_step_data"]); // 사용안하고 있음
    },
    staleTime: 300000, // 데이터를 5분간 fresh 상태로 유지
  });
};
