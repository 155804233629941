import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonBase,
  Typography,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";


const FileItemUnMenu = ({
  select,
  file,
  onFileClick,
}) => {

  return (
    <ListItem>
      <ButtonBase
        sx={{
          width: '100%',
          backgroundColor: select ? "#007BFF" : "#ccc",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
        onClick={(event) => {
          onFileClick(file)();
          event.stopPropagation();
        }}
      >
        <ListItemIcon sx={{ marginLeft: '15px' }}>
          <InsertDriveFileIcon />
        </ListItemIcon>
        <ListItemText sx={{ marginRight: '15px' }}>
          <Typography noWrap>{`${file.file_name}.${file.file_ext}`}</Typography>
        </ListItemText>
      </ButtonBase>
    </ListItem>
  );
};

export default FileItemUnMenu;
