import axios from "axios";

export const setupInterceptors = (client, handleOpenModal) => {
  let last401 = null; // 시간 추적

  client.interceptors.request.use(async (config) => {
    const access = sessionStorage.getItem("access");

    if (!config.headers.refresh) {
      config.headers.access = access;
    }

    for (const property in config.data) {
      const isDeleteAttr =
        Number.isNaN(config.data[property]) &&
        (!config.data[property] || config.data[property].length === 0);
      if (isDeleteAttr) {
        delete config.data[property];
      }
    }

    return config;
  });

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (window.location.pathname === "/login") {
        return Promise.reject(error);
      }
      const retryCount = error.config.retryCount || 0;
      if (error.request.status === 401 && retryCount < 25) {
        error.config.retryCount = retryCount + 1;
        console.log("##@@ Interceptor detected 401 error, retrying...");

        const currentTime = new Date().getTime();
        if (last401 && currentTime - last401 < 10000) {
          return Promise.reject(error);
        }
        // 마지막 401 오류 시간 업데이트
        last401 = currentTime;

        const refresh = sessionStorage.getItem("refresh");
        if (!refresh) {
          handleOpenModal();
          return Promise.reject(error);
        } else {
          delete error.response.config.headers.access;
          error.response.config.headers.refresh = refresh;
          try {
            const response = await axios(error.response.config);
            sessionStorage.setItem("access", response.headers.access);
            sessionStorage.setItem("refresh", refresh);

            delete response.config.headers.refresh;
            response.config.headers.access = response.headers.access;
            return axios(response.config);
          } catch (refreshError) {
            handleOpenModal();
            return Promise.reject(refreshError);
          }
        }
      }
      return Promise.reject(error);
    },
  );
};
