import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useErrorHandling from "assets/hooks/useError/useErrorQrHandling";
import SnackbarMessage from "./SnackbarMessage";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

// 추가 S
import client from "assets/js/commonApi";
import { Box } from "@mui/material";
import QRCode from "react-qr-code";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalContractQrFilev2({
  isOpen,
  onClose,
  handleClose,
  handleOnlyQrFileClose,
  qrcode,
}) {
  const [code, setCode] = useState({});
  const [limit, setLimit] = useState(180);
  const { handleError, snackbarOpen, snackbarMessage, handleCloseSnackbar } =
    useErrorHandling();

  useEffect(() => {
    // const c = {
    //   type: "storage/key",
    //   data: qrcode.data
    // };
    let interval;
    let limitInterval;

    setCode(qrcode);

    if (isOpen) {
      // Interval 소스 수정 S
      // 0인지 아닌지 감지하기위해 (time > 0) 조건을 넣음, 모달 컨트렉트에 props로 넘겨주기 위해 소스 새로 작성 위에
      limitInterval = setInterval(() => {
        setLimit((time) => {
          if (time > 0) {
            return time - 1;
          } else {
            clearInterval(limitInterval); // 시간이 0이 되면 interval 중지
            console.log("## 시간이 종료되었습니다.");
            // navigate("/contractlist");
            // window.location.reload();
            // handleClose();
            handleOnlyQrFileClose();
            return 0;
          }
        });
      }, 1000);
      // 1초마다 checkTime 함수를 실행합니다.
      // Interval 소스 수정 E

      interval = setInterval(() => {
        client
          // .create({
          //   timeout: 4000,
          // })
          .get("/pulling/get_key", {
            headers: {
              // access: sessionStorage.getItem("access") ?? "",
              timeout: 4000,
              pulling_key: qrcode?.data.pulling_key,
            },
          })
          .then((response) => {
            clearInterval(interval);
            // 토큰 날라가서 데이터를 setItem 제외 시킴
            // sessionStorage.setItem("access", response.headers.access);
            // sessionStorage.setItem("refresh", response.headers.refresh);
            console.log("[QR SCAN]", response.data);
            if (handleClose) handleClose(qrcode, response.data?.data);

            // 파일화면 유지하기
            // navigate("/contractlist");

            // if (limit < 0) {
            //   navigate("/contractlist");
            //   // window.location.reload()
            // } else {
            //   window.location.reload();
            // }
          })
          .catch((reason) => {
            console.log(reason);
            const statuses = [
              {
                code: 403,
                message: "로그인 계정 APP으로 스캔하였는 지 확인하세요.",
                options: { continuePolling: true },
              },
            ];
            const continuePolling = handleError(reason, statuses); // 오류 처리
            if (!continuePolling) {
              clearInterval(interval); // 풀링 중단
            }
            // if (reason.response && reason.response.status !== 401) {
            //   clearInterval(interval); // 401이 아닌 다른 오류 발생 시 인터벌 종료
            //   handleError(reason); // 오류 처리
            // }
            // handleError(reason);
          });
      }, 3000);
    } else {
      setLimit(180);
    }

    return () => {
      if (limitInterval) {
        clearInterval(limitInterval);
      }
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOpen]);

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ background: "#468ECD" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              QR 인증
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            width: "100%",
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "20% 0 13%",
            // height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              // maxWidth: 64,
              maxWidth: 104,
              width: "100%",
            }}
          >
            <QRCode
              // size={256}
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={JSON.stringify(code)}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Box>
        <Typography sx={{ paddingBottom: 12, paddingLeft: 12 }}>
          인증시간 : {`${limit} 초`}
        </Typography>
        <SnackbarMessage
          open={snackbarOpen}
          handleClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={30000}
        />
      </Dialog>
    </div>
  );
}
