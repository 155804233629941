import * as React from "react";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // backgroundColor: "green",
            // background: "red",
            // backgroundColor: "red",
            // "& .MuiTextField-root.Mui-focused": {
            //   backgroundColor: "pink",
            // },
            // "&:focus": "backgroundColor: green",
            // background: "pink",
            // "&:focus": {
            //   backgroundColor: "pink",
            // }, // 포크스 다시적용
            // "& > div": {
            //   //   background: "#ebebebb3",
            //   borderRadius: "4px",
            //   "&:focus": {
            //     backgroundColor: "red",
            //   }, // 포크스 다시적용
            // },
            "& .MuiTextField-root": {
              backgroundColor: "green",
            },

            width: "100%",
            "&.Mui-focused": {
              backgroundColor: "red",
            },

            // "& .MuiFormControl-root-MuiTextField-root:focus": {
            //   backgroundColor: "yellow",
            // },
            // "& label": { color: "green" },
            "--TextField-brandJinGray": "#0000006b",
            "--TextField-brandDeepGray": "#00000099",
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            // "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
              //   backgroundColor: "#0000001f",
            },
            "& .Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
              //   backgroundColor: "#0000001f",
            },
            // add S
            "& label.Mui-disabled ": {
              color: "#00000099",
              //   backgroundColor: "#fff",

              //   backgroundColor: "green",
            },
            // "&.Mui-focused": {
            //   backgroundColor: "#0000001f",
            // },

            "& .Mui-disabled ": {
              backgroundColor: "#fff",
            },
            // E
          },
        },
      },
      //   MuiOutlinedInput: {
      //     styleOverrides: {
      //       notchedOutline: {
      //         borderColor: 'var(--TextField-brandBorderColor)',
      //       },
      //       root: {
      //         [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      //           borderColor: 'var(--TextField-brandBorderHoverColor)',
      //         },
      //         [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
      //           borderColor: 'var(--TextField-brandBorderFocusedColor)',
      //         },
      //       },
      //     },
      //   },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            //스타일 add S
            "&.Mui-disabled::before": {
              borderBottom: "1px solid var(--TextField-brandJinGray)",
            },
            //스타일 add E
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      // MuiInput: {
      //   styleOverrides: {
      //     root: {
      //       // backgroundColor: "green",
      //       // "& .MuiInputBase-input": {
      //       //   backgroundColor: "pink",
      //       // },
      //       // "&.Mui-focused": {
      //       //   backgroundColor: "red",
      //       // },
      //       "&::before": {
      //         borderBottom: "2px solid var(--TextField-brandBorderColor)",
      //         //   backgroundColor: "pink",
      //       },
      //       //스타일 add S
      //       "&.Mui-disabled::before": {
      //         borderBottom: "1px solid var(--TextField-brandJinGray)",
      //       },
      //       //스타일 add E
      //       "&:hover:not(.Mui-disabled, .Mui-error):before": {
      //         borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
      //       },
      //       "&.Mui-focused:after": {
      //         borderBottom: "2px solid var(--TextField-brandJinGray)",
      //         backgroundColor: "red",
      //       },

      //       "&.Mui-focused:": {
      //         backgroundColor: "red",
      //         color: "red",
      //       },
      //       // "&.MuiFilledInput-root.Mui-focused": {
      //       //   backgroundColor: "pink",
      //       //   borderBottom: "2px solid var(--TextField-brandJinGray)",
      //       //   color: "red",
      //       // },
      //       // 스타일 수정해야 함 포크스 되었을 때
      //     },
      //   },
      // },
    },
  });

// CustomizedInputsStyleOverrides
export default function InputDisable({
  label,
  value,
  onChange,
  inputProps,
  focused,
  InputLabelProps,
  disabled,
}) {
  const outerTheme = useTheme();

  return (
    // <Box
    //   sx={{
    //     display: "grid",
    //     gridTemplateColumns: { sm: "1fr 1fr 1fr" },
    //     gap: 2,
    //   }}
    // >
    <ThemeProvider theme={customTheme(outerTheme)}>
      {/* <TextField label="Outlined" />
        <TextField label="Filled" variant="filled" /> */}
      <TextField
        value={value}
        onChange={onChange}
        disabled={disabled}
        label={label}
        focused={focused}
        InputLabelProps={InputLabelProps}
        //
        inputProps={inputProps}
        //
        id="standard-disabled"
        size="small"
        variant="filled"
      />
    </ThemeProvider>
    // </Box>
  );
}
