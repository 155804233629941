import { Button, Box } from "@mui/material";

export default function SearchBarCart({ onAddClick, onRemoveClick }) {
  // setTerminateBtn
  return (
    <Box
      sx={{
        display: "flex ",
        alignItems: "center",
        flexDirection: "row-reverse",
        mb: 1,
      }}
    >
      <Button
        onClick={onAddClick}
        variant="contained"
        sx={{
          height: "41px",
          boxShadow: "none",
          backgroundColor: "#29304a",
          marginLeft: 1,
        }}
      >
        장바구니추가
      </Button>
      <Button
        onClick={onRemoveClick}
        variant="contained"
        sx={{
          height: "41px",
          boxShadow: "none",
          backgroundColor: "#29304a",
          marginLeft: 1,
        }}
      >
        찜제외
      </Button>
    </Box>
  );
}
