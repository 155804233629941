import React, { useEffect, useState } from "react";
import client from "assets/js/commonApi";
import {
  Box,
  Typography,
  Dialog,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Slide from "@mui/material/Slide";

// 추가 S
import { useNavigate } from "react-router-dom";

import SearchBarContractHandler from "components/searchs/SearchBarContractHandler";
import SearchBarPaymentRequest from "components/searchs/SearchBarPaymentRequest";

import DataGridModalA from "components/grid/DataGridModalA";
import BasicTooltip from "components/tooltips/BasicTooltip";

import { CircularIndeterminate } from "components/loaders/CircularIndeterminate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalReviewRequestForm({
  onClose,
  isOpen,
  handleClose,
  contract_number,
  handleOnlyQrFileClose,
  contract_sub_id,
  // onPaymentSuccess, // 부모 함수인 계약서 결제 성공 시 자동 닫힘 처리 하기 위해 생성
  onReviewSuccess,
  isMessageLoading,
}) {
  // const autoCloseText = "* 담당자를 선택 후 결제요청 버튼을 누르세요.";

  const [grHandlerList, setGrHandlerList] = useState(); // 불러온 전체 데이터 > 그룹 사용자 리스트(결제요청할 대상자)

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const handleReviewRequest = () => {
    client
      .post("/platformsell/review", {
        pur_user: selectedUser.userId,
        contract_sub_id: contract_sub_id,
      })
      .then((response) => {
        console.log("검토 요청 성공:", response);
        alert("검토 요청이 성공적으로 완료되었습니다.");
        if (onReviewSuccess) {
          onReviewSuccess(); // 부모 컴포넌트에 성공을 알림
        }
        handleClose();
      })
      .catch((error) => {
        console.error("검토 요청 실패:", error);
        alert("검토 요청 실패: " + error.message);
      });
    // setConfirmationOpen(false); // Close the confirmation dialog after handling
  };

  // const handleCancelPaymentRequest = () => {
  //   setConfirmationOpen(false);
  // };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isMessageLoading && <CircularIndeterminate />}
        <DialogTitle id="alert-dialog-title">{"검토 요청 확인"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* 정말로 {selectedUser.userName} 님에게 결제를 요청하시겠습니까? */}
            정말로 검토요청을 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReviewRequest} color="primary" autoFocus>
            확인
          </Button>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
