import * as React from "react";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { NumericFormat } from 'react-number-format';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          // root: {
          //   [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          //     borderColor: "var(--TextField-brandBorderHoverColor)",
          //   },
          //   [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          //     borderColor: "var(--TextField-brandBorderFocusedColor)",
          //   },
          // },
        },
      },
      // MuiFilledInput: {
      //   styleOverrides: {
      //     root: {
      //       "&:before, &:after": {
      //         borderBottom: "2px solid var(--TextField-brandBorderColor)",
      //       },
      //       "&:hover:not(.Mui-disabled, .Mui-error):before": {
      //         borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
      //       },
      //       "&.Mui-focused:after": {
      //         borderBottom:
      //           "2px solid var(--TextField-brandBorderFocusedColor)",
      //       },
      //     },
      //   },
      // },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

export default function InputBasic({
  id,
  label,
  type,
  defaultValue,
  value,
  onChange,
  disabled,
  helperText,
  // options,
}) {
  const outerTheme = useTheme();
  const InputBoxSx = {
    " .MuiFormControl-root": {
      // position: "relative",

      "& label": {
        // backgroundColor: "red",
      },
    },
    " .MuiInput-root": {
      fontSize: "14px",
      // backgroundColor: "red",
    },
    // " .MuiInput-root": {
    //   // position: "relative",
    //   // "& textarea": {
    //   //   backgroundColor: "#000",
    //   // },
    //   "& input": {
    //     backgroundColor: "red",
    //     fontSize: "12px",
    //   },
    // },
    "& .Mui-disabled": {
      color: "#000000DE !important",
      "-webkit-text-fill-color": "#000000DE !important",
      // borderColor: "red !important",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root.Mui-disabled": {
      // 여기는 디지블드 상태라 수정할 필요없지만 일단 네임값 넣어둠
    },
  };
  return (
    <Box
      sx={{
        // display: "grid",
        // gridTemplateColumns: { sm: "1fr 1fr 1fr" },
        // gap: 2,
        margin: "0 8px",
        // width: "auto",
        // height: "45px",
        display: "inline-flex",
        padding: 0,
        width: 120,
        // margin: "0 8px",
        // "& > :not(style)": { m: 1 },
      }}
    >
      <ThemeProvider theme={customTheme(outerTheme)}>
        {/* <TextField label="Outlined" /> */}
        {/* <TextField label="Filled" variant="filled" /> */}
        <TextField
          InputLabelProps={{ shrink: value ? true : false }}
          id={id}
          sx={InputBoxSx}
          // sx={{ textAlign: "center", fontSize: "14px" }}
          label={label}
          size="small"
          type={type}
          variant="standard"
          // 여기서부터 프룹스로 넘겨줌
          value={value}
          onChange={onChange}
          disabled={disabled}
          helperText={helperText}
          // options={options}
          // disableAnimation={false}
        />
      </ThemeProvider>
    </Box>
  );
}

// export default function CustomizedInputsStyleOverrides() {
//     const outerTheme = useTheme();

//     return (
//       <Box
//         sx={{
//           display: "grid",
//           gridTemplateColumns: { sm: "1fr 1fr 1fr" },
//           gap: 2,
//         }}
//       >
//         <ThemeProvider theme={customTheme(outerTheme)}>
//           {/* <TextField label="Outlined" />
//           <TextField label="Filled" variant="filled" /> */}
//           <TextField label="Standard" variant="standard" />
//         </ThemeProvider>
//       </Box>
//     );
//   }
