import React, { useEffect, useState, useContext, useCallback } from "react";
import { MessageContext } from "assets/js/syntax";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  // MuiGrid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  // Divider,
} from "@mui/material";
import Draggable from "react-draggable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import SelectCompany from "components/selectBox/SelectCompany";
import InputBasic from "components/inputBox/InputBasic";

import { AppContext } from "App";

import { GetContractValueObjectForText } from "assets/js/contractUtil"; // 240521 과장님 추가

// 텍스트 마디 간격 스타일
// const MuiGrid = styled(Grid)(({ theme }) => ({
//   width: "100%",
//   ...theme.typography.body2,
//   '& [role="separator"]': {
//     // margin: theme.spacing(0, 2),
//     marginTop: theme.spacing(8),
//   },
// }));

const GridSx = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "16px",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
    // marginTop: theme.spacing(8),
  },
}));

const BoxSx = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "16px",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
    // marginTop: theme.spacing(8),
  },
}));

// Item
const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  flexDirection: "row",
  // color: theme.palette.text.secondary,
}));

// 드래그 컴포넌트
function PaperComponent(props) {
  return (
    <Draggable
      handle="#MuiAccordionSummary-root"
      cancel={'[class*="MuiAccordionDetails-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ContractAccordion({
  // onVaildate = () => null,
  preamble,
  preambleRef,
  appendix,
  appendixRef,
  sellerCompany,
  buyerCompany,
  buyerLabel,
  disabled,
  label,
  onPreambleChange, // 전문 Change 이벤트
  onAppendixChange, // 부속 Change 이벤트
  onVaildate,
  histBody,
  histAppendix,
  // company,
  // allValue,
  // allOnChange,
  // onChange,
  // inputValue,
  // onInputChange,
  // options,
}) {
  // 스타일 디테일

  const accordionSx = {
    "& .MuiAccordionSummary-root": {
      //   boxShadow: 0,
      //   padding: "0",
      //   margin: "0",
    },

    "& .MuiButtonBase-root": {
      boxShadow: 0,
      //   padding: "0",
      margin: "0",
      minHeight: "0",
    },
    "& .MuiAccordionDetails-root": {
      minHeight: "280px",
      height: "280px",
      overflow: "auto",
    },
  };

  // 240116 S
  // const [initPreamble, setInitPreamble] = useState(null);
  // const [initAppendix, setInitAppendix] = useState(null);
  const [initPreamble, setInitPreamble] = useState({}); // 기존 전문 데이터(JSON 형태)
  const [initAppendix, setInitAppendix] = useState({}); // 기존 부속 데이터(JSON 형태)
  // 240116 E

  // 여기 부분은 변수를 꼭 수정해야함 warn
  const [sellCompany, setSellCompany] = useState();
  const [buyCompany, setBuyCompany] = useState();
  const [dataType, setDataType] = useState();
  const [day, setDay] = useState();
  const [dRange, setDRange] = useState();
  const [cRange, setCRange] = useState();
  const [sDay, setSDay] = useState();
  const [count, setCount] = useState();
  const [year, setYear] = useState();
  const [vDay, setVDay] = useState();
  const [rDay, setRDay] = useState();
  const [court, setCourt] = useState();

  const [process, setProcess] = useState();
  const [processDataType, setProcessDataType] = useState();
  const [processMonth, setProcessMonth] = useState();
  const [processDRate, setProcessDRate] = useState();
  const [processDCount, setProcessDCount] = useState();
  const [processCRate, setProcessCRate] = useState();
  const [processCCount, setProcessCCount] = useState();

  // const memoizedOnVaildate = useCallback(onVaildate, [onVaildate]);
  // const memoizedOnVaildate = useCallback(
  //   (data) => {
  //     // onVaildate(data);
  //   },
  //   [onVaildate],
  // );

  useEffect(() => {
    // 바인딩 할 때 구문
    if (preamble) {
      const _preamble = GetContractValueObjectForText(preamble);

      setSellCompany(_preamble.sellCompany);
      setBuyCompany(_preamble.buyCompany);
      setDataType(_preamble.dataType);
      setDay(_preamble.day);
      setDRange(_preamble.dRange);
      setCRange(_preamble.cRange);
      setSDay(_preamble.sDay);
      setCount(_preamble.count);
      setYear(_preamble.year);
      setVDay(_preamble.vDay);
      setRDay(_preamble.rDay);
      setCourt(_preamble.court);

      // 화면 렌더링 줄이기 위해 변수 추가
      setInitPreamble(GetContractValueObjectForText(preamble));

      console.log("[REF P sellCompany]", sellCompany);
      console.log("[REF P buyCompany]", buyCompany);
      console.log("[REF P dataType]", dataType);
      console.log("[REF P day]", day);
      console.log("[REF P dRange]", dRange);
      console.log("[REF P cRange]", cRange);
      console.log("[REF P sDay]", sDay);
      console.log("[REF P count]", count);
      console.log("[REF P year]", year);
      console.log("[REF P vDay]", vDay);
      console.log("[REF P rDay]", rDay);
      console.log("[REF P court]", court);
    }

    if (appendix) {
      const _appendix = GetContractValueObjectForText(appendix);
      setProcess(_appendix.process);
      setProcessDataType(_appendix.processDataType);
      setProcessMonth(_appendix.processMonth);
      setProcessDRate(_appendix.processDRate);
      setProcessDCount(_appendix.processDCount);
      setProcessCRate(_appendix.processCRate);
      setProcessCCount(_appendix.processCCount);

      setInitAppendix(_appendix);

      console.log("[REF X process]", process);
      console.log("[REF X processDataType]", processDataType);
      console.log("[REF X processMonth]", processMonth);
      console.log("[REF X processDRate]", processDRate);
      console.log("[REF X processDCount]", processDCount);
      console.log("[REF X processCRate]", processCRate);
      console.log("[REF X processCCount]", processCCount);
    }
    // }, [preamble, appendix, company]);
  }, [preamble, appendix]);
  // 계약서 라벨

  // const onVaildateCallback = useCallback(() => {
  //   onVaildate({
  //     dataType,
  //     day,
  //     dRange,
  //     cRange,
  //     sDay,
  //     count,
  //     year,
  //     vDay,
  //     rDay,
  //     court,
  //     process,
  //     processDataType,
  //     processMonth,
  //     processDRate,
  //     processDCount,
  //     processCRate,
  //     processCCount,
  //   });
  // }, []);

  useEffect(() => {
    if (initAppendix && initPreamble) {
      console.log("initRendering", initAppendix, initPreamble);
      onVaildate({
        ...initAppendix,
        ...initPreamble,
      });
    }
  }, [initAppendix, initPreamble]);
  // if (initAppendix && initPreamble) {
  //   console.log("initRendering", initAppendix, initPreamble);
  //   onVaildate({
  //     ...initAppendix,
  //     ...initPreamble,
  //   });
  // }

  // const {
  //   // label01,
  //   // label02,
  //   // label03,
  //   // label04,
  //   label05,
  //   label06,
  //   label07,
  //   label08,
  //   label09,
  //   label10,
  //   label11,
  //   label12,
  //   label13,
  //   label14,
  //   label15,
  //   label16,
  //   label17,
  //   label18,
  //   label19,
  //   label20,
  //   label21,
  // } = useContext(AppContext);
  // const {
  //   label05,
  //   label06,
  //   label07,
  //   label08,
  //   label09,
  //   label10,
  //   label11,
  //   label12,
  //   label13,
  //   label14,
  //   label15,
  //   label16,
  //   label17,
  //   label18,
  //   label19,
  //   label20,
  //   label21,
  // } = useContext(MessageContext).messageList;
  // const { messageList, platform_messageList } = useContext(MessageContext);
  const { messageList } = useContext(MessageContext);

  // 예시 추후 삭제 2번 - 여기서부터 표준계약서(부속)
  // 총 17개
  let preContractContent = [
    "기존 데이터 명 입력 00", // 00
    "기존 데이터 명 입력 01", // 01
    "기존 데이터 명 입력 02", // 02
    "기존 데이터 명 입력 03", // 03
    "기존 데이터 명 입력 04", // 04
    "기존 데이터 명 입력 05", // 05
    "기존 데이터 명 입력 06", // 06
    "기존 데이터 명 입력 07", // 07
    "기존 데이터 명 입력 08", // 08
    "기존 데이터 명 입력 09", // 09
    "기존 데이터 명 입력 10", // 10
    "기존 데이터 명 입력 11", // 11
    "기존 데이터 명 입력 12", // 12
    "기존 데이터 명 입력 13", // 13
    "기존 데이터 명 입력 14", // 14
    "기존 데이터 명 입력 15", // 15
    "기존 데이터 명 입력 16", // 16
  ];

  // ****** 추가 240116 S
  // Method
  // 변경된 데이터의 배열 가져오기
  // 사용자가 입력할 때 이벤트 호출
  // 전문 변경 이벤트
  const setOnPreambleChangeEvent = (event, newData) => {
    // 현재 입력된 데이터
    const currentData = {
      sellerCompany,
      buyerCompany,
      dataType,
      day,
      dRange,
      cRange,
      sDay,
      count,
      year,
      vDay,
      rDay,
      court,
    };
    // 새로 입력된 데이터
    newData = { ...currentData, ...newData };

    const preambleData = [
      newData.sellerCompany,
      newData.buyerCompany,
      newData.dataType,
      newData.day,
      newData.dRange,
      newData.cRange,
      newData.sDay,
      newData.count,
      newData.year,
      newData.vDay,
      newData.rDay,
      newData.court,
    ];

    // 1) 삭제하기
    // const formattedData = preambleData.map((value) => {
    //   // 따옴표로 감싸진 문자열은 그대로 두고, 숫자 등은 그대로 반환
    //   return typeof value === "string" ? `'${value}'` : value;
    // });
    // const formattedString = `[${formattedData.join(",")}]`;
    // onPreambleChange(event, formattedString);

    //

    // // 2) 데이터 불필요하게 변화요구되어 수정한것
    // const formattedData = preambleData.map((value) => {
    //   // 따옴표가 있는 경우 제거
    //   return typeof value === "string" ? value.replace(/'/g, "") : value;
    // });
    // const formattedDataEmpty = formattedData.map((value) =>
    //   !value ? "" : value,
    // );
    // // 백단에서 빈문자열이 들어갔을때 처리해줘야 한다. 왜냐면 넘겨주는 배열에서 쌍따옴표를 제외시켜 빈값을 주기가 어렵다.
    // // 그래서 데이터중에 하나라도 입력이 안되어 있으면 Null값으로 반환한다.
    // const formattedString = `[${formattedDataEmpty.join(",")}]`;
    // onPreambleChange(event, formattedString);

    // 3) 데이터 불필요하게 변화요구되어 수정한것

    // 코드 수정 240603 _ 과장님이 수정해줌
    // const formattedData = preambleData.map(
    //   (value) => `"${value ? value : ""}"`,
    // );
    // const formattedString = `[${formattedData.join(",")}]`;

    // onPreambleChange(event, formattedString);
    const formattedData = preambleData.map((value) => `${value ? value : ""}`);
    // const formattedString = `[${formattedData.join(",")}]`;

    onPreambleChange(event, formattedData);
  };

  // 부속 변경 이벤트
  const setOnAppendixChangeEvent = (event, newData) => {
    const currentData = {
      process,
      processDataType,
      processMonth,
      processDRate,
      processDCount,
      processCRate,
      processCCount,
    };

    newData = { ...currentData, ...newData };

    const appendixData = [
      newData.process,
      newData.processDataType,
      newData.processMonth,
      newData.processDRate,
      newData.processDCount,
      newData.processCRate,
      newData.processCCount,
    ];

    // // 2) 데이터 불필요하게 변화요구되어 수정한것
    // const formattedData = appendixData.map((value) => {
    //   // 따옴표가 있는 경우 제거
    //   return typeof value === "string" ? value.replace(/'/g, "") : value;
    // });
    // const formattedDataEmpty = formattedData.map((value) =>
    //   !value ? "" : value,
    // );
    // const formattedString = `[${formattedDataEmpty.join(",")}]`;
    // onAppendixChange(event, formattedString);
    // const formattedData = appendixData.map(
    //   (value) => `"${value ? value : ""}"`,
    // );
    // const formattedString = `[${formattedData.join(",")}]`;

    // onAppendixChange(event, formattedString);
    const formattedData = appendixData.map((value) => `${value ? value : ""}`);
    // const formattedString = `[${formattedData.join(",")}]`;

    onAppendixChange(event, formattedData);
  };
  // ****** 추가 240116 E
  const countValue = (event) => {
    return event.target.value === ""
      ? 0
      : Math.max(Number(event.target.value), 0);
  };

  // const countValue = (event) => {
  //   const value = event.target.value;
  //   if (value === "") {
  //     return null; // 빈 문자열인 경우 null 반환
  //   }
  //   const number = Number(value);
  //   if (number === 0) {
  //     return null; // 0 이하의 값은 null로 처리
  //   }

  //   return event.target.value === ""
  //     ? 0
  //     : Math.max(Number(event.target.value), 0);
  // };

  return (
    <>
      <div ref={preambleRef}>
        <Accordion
          // value={allValue}
          // onChange={allOnChange}
          sx={accordionSx}
          PaperComponent={PaperComponent}
          // sx={{ boxShadow: 0, padding: "0", margin: "0" }}
        >
          <AccordionSummary
            //   sx={accordionSx}
            //   sx={{ padding: "0", margin: "0", minHeight: "0" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography component="h2" variant="subtitle2">
                  표준계약서
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {/* <StyledPaper> */}
            <GridSx item sx={{ mt: 0 }}>
              <Typography component="p" variant="body2" gutterBottom>
                주식회사
                {/* <SelectCompanyA /> */}
                <InputBasic
                  id="sell_company"
                  label={"판매회사"}
                  value={sellerCompany}
                  // onChange={(event) => setSellCompany(event.target.value)}
                  // label={buyerLabel}
                  disabled={disabled}
                />
                (이하‘갑’이라함)와주식회사
                {/* <SelectCompanyB /> */}
                <InputBasic
                  id="buy_company"
                  // label={label}
                  // label={buyerLabel}
                  label={"구매회사"}
                  // 여기서부터 넘겨줌
                  value={buyerCompany}
                  disabled={disabled}
                  // onChange={onChange}
                  // inputValue={inputValue}
                  // onInputChange={onInputChange}
                  // options={options}
                />
                (이하‘을’이라함)은에관한데이터의 취급에 관하여 이하와 같이
                데이터거래계약(이하 “본 계약”이라 함)을 체결한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제1조(목적)
                <br />본 계약은 갑이 을에게
                <InputBasic
                  id="data_type"
                  // label={"데이터 명 입력"}
                  label={messageList.label05}
                  value={dataType}
                  // helperText="**이전 내용입니다."
                  // helperText={preContractContent[0]}
                  // helperText={histBody.dataType}
                  // helperText={histBody.helperText02}
                  helperText={histBody?.helperText02}
                  // helperText={helperText01}
                  onChange={(event) => {
                    // 전문 변경 이벤트 호출
                    setOnPreambleChangeEvent(event, {
                      dataType: event.target.value,
                    });
                    onVaildate({
                      dataType: event.target.value,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setDataType(event.target.value);
                  }}
                  disabled={disabled}
                />{" "}
                데이터를 제공하는 행위와 관련하여 발생하는 권리와 의무, 그 밖 에
                필요한 기본적인 사항을 규정하는 것을 목적으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제2조{"("}정의{")"}
                <br />본 계약에서 사용하는 용어의 뜻은 다음과 같다.
                <br />
                &#9312;“제공데이터”란 본 계약에 따라 갑이 을에게 제공하는
                데이터로서, 부속문서에 기재된 것을 말한다.
                <br />
                &#9313;“파생데이터”란 을이 제공데이터를 가공, 분석, 편집 등을
                통하여 새로 또는 2차적으로 생성 한 데이터 등을 말한다.
                <br />
                &#9314;“결합데이터”란 을이 제공데이터에 파생데이터 및
                기존데이터를 결합하여 새로 또는 2차 적으로 생성한 데이터 등을
                말한다.
                <br />
                &#9315;“이용권한”이란 제공데이터, 또는 파생데이터 또는
                결합데이터를 이용할 수 있는 권한을 말 한다.
                <br />
                &#9316;“비밀정보”란 계약당사자가 상대방에 공개하고 또 공개한
                때에 비밀이라는 취지를 명시한 기 술상 또는 경영상 정보, 본
                계약의 존재 · 내용 기타 일체의 정보를 말한다.
                <br />
                &#9317;“가명처리”란 특정 개인을 알아볼 수 없도록
                개인정보보호법령이 정하는 방법으로 데이터를 처리하는 것을
                말한다.
                <br />
                &#9318;“가명정보”란 전항의 가명처리에 의해 원상태로 복원하기
                위한 추가 정보의 사용·결합없이는 특정 개인을 알아볼 수 없는
                정보를 말한다.
                <br />
                &#9319;“민감정보”란 사상·신념, 노동조합·정당의 가입·탈퇴, 정치적
                견해, 건강, 성생활 등에 관한 정보, 그 밖에 개인정보보호법령으로
                정한 정보를 말한다.
                <br />
                &#9320;“지식재산권”이란 특허, 실용신안, 디자인, 상표, 컴퓨터
                프로그램을 포함한 저작권, 데 이터베이스권, 엉업비밀 등을 포함한
                법상 보호 가능한 일체의 무형재산에 대한 권리로서 출원, 신청 및
                등록을 모두 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제3조(제공데이터의 제공방법)
                <br />
                &#9312;갑은본계약기간중을에대하여제공데이터를부속문서 및
                스마트계약에정한제공방식 으로 제공한다. 그러나 갑은 데이터 제공
                <InputBasic
                  id="day"
                  type="number"
                  min={0}
                  // label={"기간 입력"}
                  label={messageList.label06}
                  value={day}
                  // value={day === 0 ? null : day}
                  // helperText={helperText[1]}
                  helperText={histBody?.helperText03}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      // day: event.target.value,
                      day: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day: newCountValue,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setDay(newCountValue);
                  }}
                  disabled={disabled}
                />
                일 전에 을에게 통지하는 것으로 데이터 제공 방식을 변경할 수
                있다.
                <br />
                &#9313;갑은 본 계약기간 중 을에게 제공데이터의 업데이트 정보를
                스마트계약에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제4조(제공데이터의 이용허락)
                <br />
                &#9312;갑은 을에 대하여, 제공데이터를 본 계약의 유효기간 내에서
                본 계약 목적의 범위 내에서만 이용하는 것을 허락한다.
                <br />
                &#9313;을은 본 계약에서 명시적으로 규정된 것을 제외하고는
                제공데이터에 대하여 내용의 정정, 추 가 또는 삭제를 행할 수 있는
                권한이 없다.
                <br />
                &#9314;을은 갑의 서면에 의하여 사전 승낙이 없는 한 본 계약 목적
                이외의 목적으로 제공데이터를가 공, 분석, 편집, 통합 기타의
                이용을 할 수 없으며, 제공데이터를 제3자(을이 법인인 경우 그계열
                사, 관련회사도 제3자에 포함)에게 공개, 제공, 누설하여서는
                아니된다.
                <br />
                &#9315;제공데이터에 관한 지식재산권(데이터베이스에 관한 권리를
                포함하고 이에 제한되지 않음)은 갑에게 귀속된다. 그러나
                제공데이터 중 제3자에게 지식재산권이 귀속되는 것은 그러하지 아니
                한다.
                <br />
                &#9316;본 조의 이용허락은 갑의 제공데이터의 이용을 방해하지
                아니한다.
                <br />
                &#9317;갑은 을 이외의 제3자에게도 본 조의 이용허락을 할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제5조(파생데이터)
                <br />
                &#9312;을은 파생데이터의 이용권한을 가지며, 을은 갑에 대하여
                <InputBasic
                  id="d_range"
                  // label={"범위 입력"}
                  label={messageList.label07}
                  value={dRange}
                  // helperText={preContractContent[2]}
                  helperText={histBody?.helperText04}
                  onChange={(event) => {
                    setOnPreambleChangeEvent(event, {
                      dRange: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange: event.target.value,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setDRange(event.target.value);
                  }}
                  disabled={disabled}
                />
                의 범위에서 [ 의 목적의 범위 에서] 파생데이터를 무상[유상]으로
                이용하는 것을 허락한다.
                <br />
                &#9313;을이 제공데이터를 이용함에 따라 발생하는 발명, 고안, 창작
                및 영업비밀 등에 대한 지식재 산권은 을에게 귀속한다. 단, 을은
                갑에 대하여 해당 지식재산권에 대한 무상[유상]의 실시 허락 을
                한다.
                <br />
                &#9314;제1항 및 제2항의 이용 허락의 조건에 대한 구체적 내용은
                별도로 협의하여 부속문서에 포함 한다.
                <br />
                &#9315;을이 파생데이터를 이용하여 실시한 사업 또는 서비스에 따라
                매출을 얻을 때는, 을이 얻은
                매출액의일부분을갑에게지불한다.지급조건 및
                비율에대해서는갑및을의사이의 별도의 협의하에 부속문서에
                포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제6조(결합데이터)
                <br />
                &#9312;을은 결합데이터의 이용권한을 가지며, 을은 갑에 대하여
                <InputBasic
                  id="c_range"
                  // label={"범위 입력"}
                  label={messageList.label08}
                  value={cRange}
                  // helperText={preContractContent[3]}
                  helperText={histBody?.helperText05}
                  onChange={(event) => {
                    setOnPreambleChangeEvent(event, {
                      cRange: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange: event.target.value,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setCRange(event.target.value);
                  }}
                  disabled={disabled}
                />
                의 범위에서 [ 의 목적의 범위 에서] 결합데이터를 무상[유상]으로
                이용하는 것을 허락한다.
                <br />
                &#9313;을이 제공데이터를 이용함에 따라 발생하는 발명, 고안, 창작
                및 영업비밀 등에 대한 지식재 산권은 을에게 귀속한다. 단, 을은
                갑에 대하여 해당 지식재산권에 대한 무상[유상]의 실시 허락 을
                한다. <br />
                &#9314;제1항 및 제2항의 이용 허락의 조건에 대한 구체적 내용은
                별도로 협의하여 부속문서에 포함 한다.
                <br />
                &#9315;을이 결합데이터를 이용하여 실시한 사업 또는 서비스에 따라
                매출이 얻을때는 매출액의 일부분을 갑에게 지급하여야 하며 지급
                조건 및 비율에 대해서는 별도로 협의하여 부속문 서에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제7조(대금지급)
                <br />
                &#9312;을은 갑에게 제공데이터의 이용허락에 대한 대가는
                스마트계약서에 포함한다.
                <br />
                &#9313;전항의 대가 지급방범 및 시기는 스마트계약서에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제8조(제공데이터 등에 대한 보증 등)
                <br />
                &#9312;갑은 제공데이터가 적법하고 적절한 방법에 의해
                취득되었음을 표명하고 보증한다.
                <br />
                &#9313;갑은 개인정보를 포함한 데이터를 을에게 제공하는 경우,
                해당 데이터의 생성, 수집, 제공 등 에 대해 개인정보보호 관계
                법률에서 정한 요건과 절차를 준수하였음을 보증한다.
                <br />
                &#9314;갑은 제공데이터의 정확성, 완전성(데이터에 하자 내지
                결함이 없음), 안전성(데이터에 바이 러스 등 악성코드가 없음),
                유효성(본 계약 목적에의 적합성)을 보증하여야 한다.
                <br />
                &#9315;갑은 제공데이터가 타인의 지식재산권 및 기타 권리를
                침해하지 않는다는 것을 보증 하여야 한다.
                <br />
                &#9316;갑은 제공데이터에 제3자의 지식재산권의 대상으로 되는
                데이터가 포함되었거나 기타 상대 방의 이용에 관하여 제한이 있을
                수 있는 것이 판명된 경우에는, 신속히 상대방 당사자와 협의하 여
                해당 제3자로부터의 이용허락의 취득 또는 해당 데이터를 제거하는
                조치 기타 상대방 당사 자가 이용권한을 행사할 수 있도록 조치를
                강구하여야 한다.
                <br />
                &#9317;갑은 제공데이터의 정확성, 완전성, 안전성, 유효성의 어느
                하나에 문제가 있거나 또는 제공 데이터가 제3자의 지식재산권 기타
                권리를 침해하는 것을 고의 또는 중대한 과실에 의해 알리지 않고
                상대방 당사자에게 이용 등 하게 하는 경우, 을에게 관련 손해를
                배상할 책임이 있다.
                <br />
                &#9318;본 조에 따른 손해배상책임은 갑이 을로부터 받았던 대가를
                상한으로 한다.
                <br />
                &#9319;본 조의 규정은 을이 갑에게 파생데이터를 제공하는 경우에도
                적용된다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제9조(가명정보, 민감정보의 처리)
                <br />
                &#9312;가명정보는 개인정보보호 관계 법령에서 정한 목적 범위와
                법적 요건에 따라 처리되어야 한 다.
                <br />
                &#9313;갑은 가명정보를 원상태로 복원할 수 있는 추가 정보를
                안전하게 보관하여야 하며 이를 을에 게 제공하여서는 아니된다.
                <br />
                &#9314;을은 특정 개인을 재식별하려는 목적으로 가명정보를
                처리하여서는 아니되며 특정 개인을 알 아 볼 수 있는 정보가 생성된
                경우에는 가명정보의 처리를 중단하고 해당 정보를 파기하여야 한다.{" "}
                <br />
                &#9315;갑은 민감정보 내지 특정 개인에게 신체적, 재산적으로
                중대한 영향을 미칠 수 있는 데이터를 을에게 제공하는 경우, 을의
                제공데이터 접근을 장소적, 물리적으로 제한하여 데이터를 제공할 수
                있으며 을에게 가명처리 등 필요한 안전성 확보 조치를 요구할 수
                있다. <br />
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제10조(제공데이터 관리의무)
                <br />
                &#9312;을은 제공데이터를 자신이 보관하고 있는 다른 정보와 명확히
                구별하고 선량한 관리자의 주 의를 가지고 관리·보관하여야 한다.
                <br />
                &#9313;을은 제공데이터를 관리하기 위한 절차를 제시하고 이를
                준수하여야 한다.
                <br />
                &#9314;을은 제공데이터에 대하여 자신의 영업비밀과 동등 이상의
                합리적인 관리 조치를 취하여야 한다.
                <br />
                &#9315;을은 상대방의 사전서면에 의한 승낙을 받지 아니하고
                제공데이터를 제3자 에게 공개, 제공, 누설해서는 아니된다.
                <br />
                &#9316;갑은 제공데이터의 관리상황에 대하여, 을에게 언제든지
                서면에 의한 보고를 요청할 수 있다. 이 경우 제공데이터의 유출
                또는 상실의 우려가 있다고 갑이 판단한 경우, 갑은 을에게 제공
                데이터의 관리 방법·보관 방법의 시정을 요구할 수 있다.
                <br />
                &#9317;전 항의 보고 또는 시정 요구가 있는 경우, 을은 즉시 이에
                응하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제11조(이용현황 보고 등)
                <br />
                &#9312;갑은 을에 대하여 을에 의한 제공데이터의 이용이 본 계약의
                조건에 적합한지를 확인하는데 필요한 이용현황의 보고를 요구할 수
                있다.
                <br />
                &#9313;갑은 합리적인 기준에 따라 전항에 따른 보고가 제공데이터
                이용현황을 검증하기에 충분하지 않다고 판단하는 경우,
                <InputBasic
                  id="s_day"
                  type="number"
                  min={0}
                  // label={"기간 입력"}
                  label={messageList.label09}
                  value={sDay}
                  // helperText={preContractContent[4]}
                  helperText={histBody?.helperText06}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      sDay: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay: newCountValue,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setSDay(newCountValue);
                  }}
                  disabled={disabled}
                />
                영업일 전에 서면에 의한 사전통지를 할 것을 조건으로, 1년에
                <InputBasic
                  id="count"
                  type="number"
                  min={0}
                  // label={"한도 횟수 입력"}
                  label={messageList.label10}
                  value={count}
                  // helperText={preContractContent[5]}
                  helperText={histBody?.helperText07}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      count: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count: newCountValue,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setCount(newCountValue);
                  }}
                  disabled={disabled}
                />{" "}
                회를 한도로써, 을의 영업소에서 제공데이터의 이용 상황의 감사를
                실시할 수 있다. 이 경우 갑은 을의 정보보안에 관한 규정 그 이외의
                을이 별도로 정하는 사내규정을 준수하여야 한다.
                <br />
                &#9314;을은 자신의 영업비밀 보호 등을 이유로 제3자에 의한 감사를
                요구할 수 있다.
                <br />
                &#9315;전항에 의한 감사의 결과, 을이 본 계약을 중대하게 위반하여
                제공데이터를 이용하였다는 것 이 확인되었을 경우, 을은 갑에
                대하여 감사에 소요된 비용 및 제공데이터의 이용에 관한 추가의
                대가를 지급해야 한다. 을이 갑에게 지급해야 하는 감사비용은
                협의에 따라 정한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제12조(제공데이터의 유출 시 조치)
                <br />
                &#9312;을은 제공데이터 유출, 제3자 제공, 목적 외 이용 등 본
                계약에 위반하는 제공데이터의 이용 (이하 [제공데이터 유출 등]이라
                한다)을 발견한 경우, 즉시 갑에게 그 취지를 통지하여야 한다.
                <br />
                &#9313;을의 고의 또는 과실에 의해 제공데이터 유출 등이 발생한
                경우, 을은 자기의 비용과 책임으 로 제공데이터 유출 등의 사실
                여부를 확인하고, 제공데이터 유출 등의 사실이 확인된 경우 그 원인
                및 재발방지책을 검토하고 이를 갑에게 보고하여야 한다.
                <br />
                &#9314;제공데이터에 개인정보가 포함되어 있는 경우, 을은
                개인정보보호법령에 따라 개인정보 유출 등의 사실을 감독당국에
                신고하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제13조(손해배상)
                <br />양 당사자는 자기의 본 계약의 위반에 기인하여 상대방
                당사자가 입은 일체의 손해, 손실, 비용 및 지출(합리적인 변호사
                비용을 포함함. 이하 “손해 등”이라 함)을 상대방 당사자에 대하여
                배 상할 책임이 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제14조(책임의 제한 등)
                <br />
                &#9312;갑은 을이 제공데이터를 이용하는 과정에서 발생한 타인의
                발명, 고안, 창작 및 영업비밀 등 지식재산권 및 기타 권리 침해에
                관한 모든 청구, 손실, 손해 또는 비용에 대하여 책임을 지지않
                는다.
                <br />
                &#9313;을은 제공데이터 이용에 관련되는 제3자와의 사이에 분쟁,
                클레임 또는 청구(이하 [분쟁 등] 이라 한다)가 발생한 경우에는
                즉시 갑에게 서면으로 통지하여야 하고 또한 자기의 책임과 비용
                부담으로 해당 분쟁 등을 해결한다. 갑은 해당 분쟁 등에 합리적인
                범위에서 협력한다.
                <br />
                &#9314;을은 전항에서 정하는 분쟁 등과 관련하여 갑이 손해, 손실
                또는 비용(합리적인 변호사 비용 을 포함, 이하 [손해 등]이라
                한다)을 피해 받은 경우(단, 분쟁 등이 갑의 귀책 사유에 따른 경우
                를 제외) 갑에 대하여 해당 손해 등을 전보한다. <br />
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제15조(비밀유지의무)
                <br />
                &#9312;갑 및 을은 상대방이 서면·구두 또는 기타 방법을 가리지
                않고 비밀정보임을 표명한 후 공개 한 비밀정보를 엄격하게 비밀로
                유지하고, 상대방의 서면에 의한 사전 동의 없이 제3자에게 공 개,
                제공, 누설하거나 비밀정보를 본 계약에 따른 권리의 행사 또는
                의무의 이행 이외의 목적으 로 이용해서는 아니된다. 다만, 법원의
                판결, 수사기관의 강제수사, 행정청의 처분, 명령 또는 관 련 법령의
                공개의무에 따라서 공개하는 행위는 비밀유지의무를 위반하지 않는
                것으로 본다.
                <br />
                &#9313;전항의 규정에도 불구하고 다음 각 호의 어느 하나에
                해당하는 정보는 비밀정보에 해당하지 않는 것으로 한다.
                <BoxSx>1. 공개 시점에서 이미 공개자가 보유하고 있던 정보</BoxSx>
                <BoxSx>
                  2. 비밀정보에 의하지 않고 공개자가 독자적으로 개발한 정보
                </BoxSx>
                <BoxSx>3. 공개 시점에 알려진 정보</BoxSx>
                <BoxSx>
                  4. 공개 후에 공개자의 귀책사유에 의하지 아니하고 공지된 정보
                </BoxSx>
                <BoxSx>
                  5. 정당한 권리를 가진 제3자로부터 비밀유지의무 없이 공개된
                  정보
                </BoxSx>
                &#9314;공개자는 본 계약의 이행을 위해 필요한 범위 내에서만 본조
                제1항에 근거하여 비밀유지의무 를 준수하는 것을 전제로, 자신의
                임직원 또는 법률상 준수의무가 있는 자신의 변호사, 회계사, 세무사
                등에 비밀정보를 공개할 수 있다. <br />
                &#9315;본조에 따른 의무는 본 계약이 종료 된 후에도
                <InputBasic
                  id="year"
                  type="number"
                  min={0}
                  // label={"존속 년수 입력"}
                  label={messageList.label11}
                  value={year}
                  // helperText={preContractContent[6]}
                  helperText={histBody?.helperText08}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      year: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year: newCountValue,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setYear(newCountValue);
                  }}
                  disabled={disabled}
                />
                년간 존속한다
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제16조(계약기간)
                <br />
                &#9312;계약의 유효기간 및 연장에 관련된 사항은 스마트계약에
                포함한다. <br />
                &#9313;당사자들이 본 계약의 내용 및 조건을 변경하고자 할 경우
                변경된 내용을 반영하여 계약서를 새로이 작성하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제17조(계약의 해제 또는 해지)
                <br />
                &#9312;일방 당사자는 타방 당사자가 본 계약을 위반한 경우
                <InputBasic
                  id="v_day"
                  type="number"
                  min={0}
                  // label={"위반 시정기간 일수 입력"}
                  label={messageList.label12}
                  value={vDay}
                  // helperText={preContractContent[7]}
                  helperText={histBody?.helperText09}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      vDay: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay: newCountValue,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setVDay(newCountValue);
                  }}
                  disabled={disabled}
                />
                일간의 기간을 정해 위반의 시정을 요구할 수 있고, 그 기간내에
                타방 당사자가 위반을 시정하지 않은 때에는 서면에 의한 통지로써
                본 계약을 해제/해지할 수 있다.
                <br />
                &#9313;다음 각 호에 해당하는 경우, 양 당사자는 최고 없이도 즉시
                본 계약을 해제/해지할 수 있다. <br />
                <BoxSx>1. 을이 대금 지급의무를 일 이상 지체하는 경우</BoxSx>
                <BoxSx>
                  2. 수표, 어음의 부도처리 등 지급정지상태에 빠진 경우
                </BoxSx>
                <BoxSx>3. 압류, 가압류, 가처분, 강제집행이 발생한 경우</BoxSx>
                <BoxSx>
                  4. 파산, 화의, 정리, 회생절차 개시신청을 받거나 또는 스스로
                  그러한 신청을 한 경우
                </BoxSx>
                <BoxSx>
                  5. 갑의 사전승낙 없이 본 계약상 권리의 전부 또는 일부 양도하는
                  경우
                </BoxSx>
                <BoxSx>6. 을의 회사가 타사와 합병, 인수되는 경우</BoxSx>
                <br />
                &#9314;본 조의 해제/해지는 손해배상청구권에 영향을 미치지
                않는다.
                <br />
                &#9315;양 당사자는 본 계약에서 별도로 정한 경우 이외에
                부속문서에 합의함으로써 본 계약을 해제 /해지할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제18조(본 계약 종료 후의 효력)
                <br />
                &#9312;본 계약이 유효기간의 만료 또는 해제에 따라 종료한
                경우이더라도, 제9조, 제11조, 제14조 의 규정은 유효하게 존속하는
                것으로 한다.
                <br />
                &#9313;을은 본 계약이 종료한 때 제공데이터 중에서 부속문서에서
                계약종료시에 폐기 또는 제거, 삭 제가 명기된 것에 관하여, 갑 및
                을이 별도로 협의하여 정한 절차에 따라서 신속히 폐기 또는 제 거,
                삭제하는 것으로 한다.
                <br />
                &#9314;을은 제2항에 따라 폐기 또는 제거, 삭제할 의무를 부담하는
                데이터 이외의 제공데이터 등에 대하여 갑의 이용허락을 받아 계속
                이용할 수 있다. 을이 계속 제공데이터 등을 이용하는 범위 및
                행위에 대해서는 본 계약의 효력이 유지된다.
                <br />
                &#9315;을은 계약 종료 후 갑으로부터 제공받은 자료를 즉시 갑에게
                반환한다. 물리적으로 반환할 수 없는 경우에는 갑의 지시에 따라
                폐기하고 폐기한 사실을 증명하는 서면을 제출하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제19조(비용)
                <br />본 계약에 별도의 정함이 있는 경우와 아울러 갑 및 을이 별도
                합의한 경우를 제외하고, 본 계약 의 체결 및 이행에 관한 비용에
                관해서는 각자의 부담으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제20조(불가항력)
                <br />
                &#9312;어떠한 당사자도 지진, 태풍, 수해, 화재, 노동쟁의, 전쟁
                기타 예측이 불가능하고 합리적인 통제를 벗어나는 원인으로 인해
                계약의 이행에 실패하거나 이행을 지체하여 손해 또는 손실을
                발생시켰더라도 이에 대해 책임을 지지 않는다. 단, 이행을 하지
                못하게 된 당사자는 그 원인을 제거하기 위해 상업적이고 합리적인
                노력을 다하여야 하고, 그 원인이 제거된 후에는 합리적인 조치로
                계약의 이행을 지속해야 한다.
                <br />
                &#9313;어느 일방 당사자가 불가항력의 사유로 이행의무의 전부 또는
                일부에 대한 불능의 상태에 이 르는 경우, 즉시 상대 당사자에게
                그러한 불가항력에 대한 합리적으로 충분한 내용을 통지하여 야
                한다.
                <br />
                &#9314;전항의 경우, 양 당사자들은 계약의 원활한 이행을 위해
                성실하게 선후책을 협의해야 한다.
                <br />
                &#9315;불가항력으로 본 계약의 이행불능 또는 이행지체가{" "}
                <InputBasic
                  id="r_day"
                  type="number"
                  min={0}
                  // label={"이행 지체 일수 입력"}
                  label={messageList.label13}
                  value={rDay}
                  // helperText={preContractContent[8]}
                  helperText={histBody?.helperText10}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      rDay: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay: newCountValue,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setRDay(newCountValue);
                  }}
                  disabled={disabled}
                />
                일 이상 지속되는 경우, 일방 당사자 는 상대방에 대한 서면
                통지로서 손해배상 없이도 본 계약을 해제/해지할 수 있다.
                <br />
                &#9316;불가항력을 이유로 본 계약이 해제/해지될 경우 그때까지
                데이터 제공자가 수령한 이용대가 는 반환되지 않는다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제21조(완전합의)
                <br />본 계약은 양 당사자의 모든 합의를 망라하며, 본건 주제에
                관한 양 당사자 간의 서면 또는 구두 에 의한 종전의 일체의 합의에
                우선한다. 본 계약의 변경은 양 당사자가 서명한 서면에 의하지 않는
                한 구속력을 가지지 않는 것으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제22조(일부 무효)
                <br />본 계약의 일부 조항이 무효 또는 이행불능으로 인정되더라도
                특별한 사정이 없는 한 이러한 사 실은 본 계약의 다른 조항의
                유효성에 영향을 미치지 아니한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제23조(양도금지)
                <br />갑 및 을은 상대방 당사자의 사전 서면에 의한 동의를 받지
                아니하고, 본 계약, 본 계약상의 지위 또는 이들에 기한 권리·의무를
                양도, 이전 기타 처분을 해서는 아니된다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제24조(준거법)
                <br />본 계약은 한국법에 준거하고, 한국법에 따라서 해석한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제25조(관할법원 등)
                <br />
                &#9312;본 계약에 기인하거나 관련하여 발생한 분쟁(본 계약의 각
                조항의 해석에 의문이 발생한 경우 를 포함함)에 관해서는 우선 갑
                및 을이 성실하게 협의함으로써 그 해결하기로 한다. <br />
                &#9313;위 &#9312;항의 협의가 이루어지지 않은 경우에는{" "}
                <InputBasic
                  id="court"
                  // label={"지방 법원 명 입력"}
                  label={messageList.label14}
                  value={court}
                  // helperText={preContractContent[9]}
                  helperText={histBody?.helperText11}
                  onChange={(event) => {
                    setOnPreambleChangeEvent(event, {
                      court: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court: event.target.value,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setCourt(event.target.value);
                  }}
                  disabled={disabled}
                />
                지방법원을 제1심의 전속적 합의관할 법원 으로 한다.
              </Typography>
            </GridSx>
          </AccordionDetails>
        </Accordion>
      </div>
      <div ref={appendixRef}>
        <Accordion sx={accordionSx} PaperComponent={PaperComponent}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography component="h2" variant="subtitle2">
                  표준계약서(부속)
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {/* <StyledPaper> */}
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                1. 제공데이터 범위
                <br />
                &#9312;제공데이터는{" "}
                <InputBasic
                  id="process"
                  // label={"공정 명 입력"}
                  label={messageList.label15}
                  value={process}
                  // helperText={preContractContent[10]}
                  // helperText={histBody.helperText12}
                  helperText={histAppendix?.helperText13}
                  onChange={(event) => {
                    // 부속 변경 이벤트 호출
                    setOnAppendixChangeEvent(event, {
                      process: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process: event.target.value,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcess(event.target.value);
                  }}
                  disabled={disabled}
                />
                공정의{" "}
                <InputBasic
                  id="process_data_type"
                  // label={"데이터 명 입력"}
                  label={messageList.label16}
                  value={processDataType}
                  // helperText={preContractContent[11]}
                  helperText={histAppendix?.helperText14}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processDataType: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType: event.target.value,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessDataType(event.target.value);
                  }}
                  disabled={disabled}
                />
                데이터의{" "}
                <InputBasic
                  id="process_month"
                  type="number"
                  min={0}
                  // label={"개월 수 입력"}
                  label={messageList.label17}
                  value={processMonth}
                  // helperText={preContractContent[12]}
                  helperText={histAppendix?.helperText15}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processMonth: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth: newCountValue,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessMonth(newCountValue);
                  }}
                  disabled={disabled}
                />
                개월치 데이터를 말한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                2. 파생데이터 이용허락 조건 및 이득발생시 지급조건
                <br />
                &#9312;을의 파생데이터를 이용한 경제적 비경제적 활동이 갑의
                사업에 불이익을 주지않아야 한다.
                <br />
                &#9313;계약종료시 파생데이터를 더 이상 사용하지 않을 시에는
                파생데이터를 파기하여야 하며 파생데이터를 계속이용할 경우에는
                별도의 계약을 통해 파생데이터를 이용하여야 한다.
                <br />
                &#9314;파생데이터를 이용하여 발생한 매출의{" "}
                <InputBasic
                  id="process_d_rate"
                  type="number"
                  min={0}
                  // label={"매출의 제공 % 입력"}
                  label={messageList.label18}
                  value={processDRate}
                  // helperText={preContractContent[13]}
                  helperText={histAppendix?.helperText16}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processDRate: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate: newCountValue,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessDRate(newCountValue);
                  }}
                  disabled={disabled}
                />
                %를 갑에게 제공하여야 하다. 파생데이터의 사용횟수 및 타
                기관으로의 제공건수{" "}
                <InputBasic
                  id="process_d_count"
                  // label={"제공 건수/ 년 입력"}
                  label={messageList.label19}
                  value={processDCount}
                  // helperText={preContractContent[14]}
                  helperText={histAppendix?.helperText17}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processDCount: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount: event.target.value,
                      processCRate,
                      processCCount,
                    });
                    setProcessDCount(event.target.value);
                  }}
                  disabled={disabled}
                />{" "}
                건/년 으로 제한한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                3. 결합데이터 이용허락 조건 및 이득발생시 지급조건
                <br />
                &#9312;을의 결합데이터를 이용한 경제적 비경제적 활동이 갑의
                사업에 불이익을 주지않아야 한다.
                <br />
                &#9313;계약종료시 결합데이터를 더 이상 사용하지 않을 시에는
                결합데이터를 파기하여야 하며 결합데이터를 계속이용할 경우에는
                별도의 계약을 통해 파생데이터를 이용하여야 한다.
                <br />
                &#9314;결합데이터를 이용하여 발생한 매출의{" "}
                <InputBasic
                  id="process_c_rate"
                  type="number"
                  min={0}
                  // label={"매출의 제공 % 입력"}
                  label={messageList.label20}
                  value={processCRate}
                  // helperText={preContractContent[15]}
                  helperText={histAppendix?.helperText18}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processCRate: newCountValue,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate: newCountValue,
                      processCCount,
                    });
                    setProcessCRate(newCountValue);
                  }}
                  disabled={disabled}
                />{" "}
                %를 갑에게 제공하여야 하다. 결합데이터의 사용횟수 및 타
                기관으로의 제공건수
                <InputBasic
                  id="process_c_count"
                  // label={"제공 건수/ 년 입력"}
                  label={messageList.label21}
                  value={processCCount}
                  // helperText={preContractContent[16]}
                  helperText={histAppendix?.helperText19}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processCCount: event.target.value,
                    });
                    onVaildate({
                      dataType,
                      day,
                      dRange,
                      cRange,
                      sDay,
                      count,
                      year,
                      vDay,
                      rDay,
                      court,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount: event.target.value,
                    });
                    setProcessCCount(event.target.value);
                  }}
                  disabled={disabled}
                />
                건/년 으로 제한한다.
              </Typography>
            </GridSx>
            {/* </StyledPaper> */}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
