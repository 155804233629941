import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicTooltip from "components/tooltips/BasicTooltip";
import { Box, Paper, Typography, Grid, Tooltip } from "@mui/material";

import { useSearchContractsQuery } from "assets/hooks/useCategory/useSearchContractsQuery";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const DataGridMain = ({ children, dataTitle, mainHomeSortOption }) => {
  const [popularityData, setPopularityData] = useState([]);
  const fromDtm = dayjs().subtract(70, "day");
  const toDtm = dayjs();
  console.log("####popularityData", popularityData);

  // 카테고리 메인페이지와 동일한 api 사용 (데이터 이름 동일하게 사용)
  // const { data: searchApiQuery } = useSearchContractsQuery;

  // 카테고리 메인페이지와 동일한 api 사용
  const { data: contractsData } = useSearchContractsQuery({
    // keyword: "", // 필요한 경우 키워드를 넘겨줄 수 있습니다.
    searchInitiated: true, // API 호출 활성화
    // sortOption: "reg_date",
    sortOption: mainHomeSortOption,
    additionalOptions: {
      // data_main_cat: "all",
      // from_dtm: "2024-05-28",
      // to_dtm: "2024-06-27",
      from_dtm: fromDtm.format("YYYY-MM-DD"),
      to_dtm: toDtm.format("YYYY-MM-DD"),
    },
  });

  useEffect(() => {
    if (contractsData) {
      const processedData = contractsData.map((item, index) => ({
        // console.log("#### insDate:", insDate.format("YYYY-MM-DD"));
        ...item,
        id: index + 1, // DataGrid에 필요한 고유 id
        // upd_dtm: item.upd_dtm.substring(0, 10), // 날짜 형식 조정

        ins_dtm: dayjs(item.ins_dtm).format("YYYY-MM-DD"),
      }));
      const topTenData = processedData.slice(0, 10);
      // setPopularityData(processedData);
      setPopularityData(topTenData);
    }
  }, [contractsData]);

  console.log("##@@contractsData", contractsData);

  // useEffect((params) => {
  //   client.get("/mdpt_platform/popularity").then((res) => {
  //     const datas = res.data.data;
  //     // if (data)
  //     const newDatas = datas?.map((data, index) => {
  //       for (let key in data) {
  //         const lowerKey = key.toLowerCase();
  //         if (lowerKey !== key) {
  //           data[lowerKey] = data[key];
  //           delete data[key]; // 오브젝트 키 A 값 12345 빈오브젝트 만드는기능
  //         }
  //       }

  //       const updDtm = data.upd_dtm;
  //       return {
  //         ...data,
  //         id: index + 1,
  //         upd_dtm: updDtm.substring(0, 10),
  //         // upd_dtm: `${data.upd_dtm.substring(0, 10)}
  //         // to_dtm: data.to_dtm.substring(0, 10),
  //       };
  //     });

  //     setPopularityData(newDatas);
  //     console.log("## 인기계약데이터", newDatas);
  //   });
  // }, []);

  const columns = [
    {
      field: "id",
      headerName: "순번",
      // type: "number",
      // width: 15
      flex: 0.5,
      // valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "data_title",
      // field: "data",
      headerName: "데이터",
      // type: "number",
      // width: 150,
      flex: 3.25,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.data_title} />,
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "company_name",
      // field: "company_name",
      headerName: "기업명",
      // width: 100,
      flex: 1.25,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.company_name} />,
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "ins_dtm",
      headerName: "계약일",
      // type: "date",
      // width: 150,
      flex: 1.25,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.ins_dtm} />,
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
  ];

  return (
    <Grid
      item
      // xs
      // xs={6}
      xs={12}
      sm={6}
      // md={6}
      // spacing={2}
    >
      {/* <Paper variant="outlined"> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          border: "1px solid #8192bc",
          padding: "24px",
          borderRadius: "4px",
          height: "100%",
          // height: 240, // 고정하게되면 이게 젤 예쁨
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          sx={{
            fontWeight: "400",
            color: "#29314a",

            paddingBottom: "16px",
            marginBottom: "16px",
            borderBottom: "3px solid #8192bc",
          }}
        >
          {dataTitle}
        </Typography>
        <DataGrid
          // onRowClick={handleRowClick}
          // {...data}
          children={children}
          sx={{
            boxShadow: 0,
            border: 0,
            // borderColor: "primary.light",
            "& .MuiDataGrid-cell:": {},
            "& .MuiDataGrid-withBorderColor": {
              border: 0,
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-cell:nth-child(1)": {
              color: "#468ECD",
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: 14,
              minHeight: "40px !important",
              maxHeight: "40px !important",
              lineHeight: "40px !important",
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          // getRowId={(row) => row.id}
          rows={popularityData}
          // key={id}
          columns={columns}
          disableRowSelectionOnClick
          columnHeight={25}
          rowHeight={25}
          // pageSize={5}
          // rowsPerPageOptions={[5, 10, 20]}
          // onCellClick={handleOnCellClick}
        />
        {/* </Paper> */}
      </Box>
    </Grid>
  );
};

export default DataGridMain;
