import * as React from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";

const DataGridLayout = ({ children, rows, columns, onCellClick, sx }) => {
  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const handleOnCellClick = (params) => {
    console.log('[UserDataGridLayout handleOnCellClick]', params)
    setFinalClickInfo(params);
    onCellClick(params);
  };

  console.log("finalClickInfo", finalClickInfo);

  // no data 이미지 S
  const getHeightSX = () => {
    // 데이터가 없을 때
    if (!rows || rows.length === 0) {
      return { height: 400 };
    }
    // 데이터가 있을 때
    const numRows = rows.length;
    const calculatedHeight = numRows * 50 + 100;
    return { height: calculatedHeight };
  };
  // no data 이미지 E
  return (
    <Stack sx={getHeightSX}>
      <DataGrid
        children={children}
        sx={sx}
        rows={rows}
        columns={columns}
        // baseTooltip
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        hideFooterSelectedRowCount={true}
        rowHeight={55}
        onCellClick={handleOnCellClick}
      />
    </Stack>
  );
};

export default DataGridLayout;
