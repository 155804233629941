// import React, { createContext } from "react";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { MessageProvider } from "assets/js/syntax";
import { setupInterceptors } from "assets/js/interceptors"; // 인터셉터 모듈 가져오기
import ModalCommonError from "components/modal/ModalCommonError";
import client from "assets/js/commonApi";

// 컴포넌트
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";
import FixedButton from "components/btns/FixedButton";
import { ContractTypeSelDialog } from "components/btns/ContractTypeSelDialog";

// page route
import AuthGuard from "pages/auth/AuthGuard";

// view
import Home from "./views/Home";
import Welcome from "./views/Welcome";
import Login from "./views/Login";
import Intro from "./views/Intro";
import Category from "./views/Category";
import PlatformDetail from "./views/details/PlatformDetail";
import Mypage from "./views/Mypage";
import Statistics from "./views/Statistics";
import ContractList from "./views/mypages/ContractList";
// import BuyList from "./views/BuyList";
import FileList from "./views/FileList";
import DID from "./views/DID";
import TF from "./views/TF";
// view - mypage
import ContractProgress from "views/mypages/ContractProgress";
import SalesList from "views/mypages/SalesList";
import BuyList from "views/mypages/BuyList";
import ConsignmentList from "views/mypages/ConsignmentList";
import FTManagement from "views/mypages/FTManagement";
// import FTManagement from "views/mypages/FTManagement";
import NFTManagement from "views/mypages/NFTManagement";
import CartLikes from "views/mypages/CartLikes";
import UserManagement from "views/mypages/UserManagement";
import GroupManagement from "views/mypages/GroupManagement";
import StorageManagement from "views/mypages/StorageManagement";

// error page
import PageError from "./views/errors/PageError";
// location
import LocationTop from "components/location/LocationTop";

// import Partner

// mui
import { Container, CssBaseline, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// import Example from "./views/Example";
// import Main from "./views/Main";
import theme from "styles/reset";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // useNavigate,
} from "react-router-dom";
// import { RecoilRoot } from "recoil";
// import {
//   RecoilRoot,
//   // atom,
//   // selector,
//   // useRecoilValue,
// } from "recoil";

// Axios 인스턴스 생성
// export const client = axios.create({
//   baseURL: "https://u-stc.net:8821",
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
// });

function App() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const handleOpenModal = () => {
    if (window.location.pathname !== "/login") {
      console.log("Opening modal due to session expiration");
      setIsSessionExpired(true);
    }
    // console.log("Opening modal due to session expiration");
    // // handleClose();
    // setIsSessionExpired(true);
  };
  const handleCloseModal = () => {
    setIsSessionExpired(false);
  };
  setupInterceptors(client, handleOpenModal);

  return (
    <React.StrictMode>
      {/* <RecoilRoot> */}
      <ThemeProvider className="App" theme={theme}>
        <MessageProvider>
          {/* <AppContext.Provider value={messageList}> */}
          <CssBaseline />
          <Router>
            <LocationTop />
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
              Home
            </Link>
          </nav> */}
            <div style={{ flex: "1" }}>
              <TopMenu />
              <Box style={{ height: "100%" }}>
                <AuthGuard>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/intro" element={<Intro />} />
                    {/* <Route path="/category" element={<Category />}>
                      <Route index element={<Category />} />
                      <Route
                        path=":platform_main_id"
                        element={<PlatformDetail />}
                      />
                    </Route> */}
                    <Route path="/category" element={<Category />} />
                    <Route
                      path="/category/:platform_main_id"
                      element={<PlatformDetail />}
                    />
                    {/* <Route path="/category" element={<Category />}>
                      <Route index element={<Category />} />
                      <Route path="/category/search" element={<Category />} />
                    </Route> */}
                    {/* <Route path="/category" element={<Category />}>
                      <Route
                        path=":platform_main_id"
                        element={<PlatformDetail />}
                      />
                    </Route> */}
                    {/* <Route path="/mypage" element={<Mypage />}> */}
                    <Route path="/mypage" element={<Mypage />}>
                      <Route index element={<ContractProgress />} />
                      <Route path="progress" element={<ContractProgress />} />
                      <Route path="sales" element={<SalesList />} />
                      <Route path="buy" element={<BuyList />} />
                      <Route
                        path="consignments"
                        element={<ConsignmentList />}
                      />
                      <Route path="ft/*" element={<FTManagement />} />
                      <Route path="nft" element={<NFTManagement />} />
                      <Route path="cart-likes" element={<CartLikes />} />
                      <Route path="users" element={<UserManagement />} />
                      <Route path="groups" element={<GroupManagement />} />
                      <Route path="storage/*" element={<StorageManagement />} />
                    </Route>
                    <Route path="/statistics" element={<Statistics />} />
                    {/* <Route path="/filelist" element={<FileList />} /> */}
                    <Route path="/contractlist" element={<ContractList />} />
                    {/* <Route path="/buylist" element={<BuyList />} /> */}
                    {/* <Route path="/did" element={<DID />} /> */}
                    <Route path="/tf" element={<TF />} />
                    <Route
                      path="/login"
                      element={
                        <Login
                        // setIsSessionExpired={setIsSessionExpired}
                        />
                      }
                    />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="*" element={<PageError />} />
                  </Routes>
                </AuthGuard>
              </Box>
              {/* <FixedButton /> */}
              <ContractTypeSelDialog />
              <ModalCommonError
                isOpen={isSessionExpired}
                handleClose={handleCloseModal}
                // setIsSessionExpired={setIsSessionExpired}
              />
            </div>
            <Footer />
          </Router>
          {/* </AppContext.Provider> */}
        </MessageProvider>
      </ThemeProvider>
      {/* </RecoilRoot> */}
    </React.StrictMode>
  );
}

export default App;
