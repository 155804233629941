import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";

function StatisticsCard({ card }) {
  // const { card } = props;
  // const [fontSize, setFontSize] = useState("60px");
  const [fontSize, setFontSize] = useState("3.75rem");

  // 숫자를 포맷팅하는 함수
  const formatNumber = (num) => {
    return new Intl.NumberFormat("ko-KR").format(num);
  };

  // useEffect(() => {
  //   const textLength = card.date.length;
  //   const newSize =
  //     textLength > 10 ? `calc(2rem - ${textLength * 0.1}vw)` : "2rem";
  //   setFontSize(newSize);
  // }, [card.date]);

  // useEffect(() => {
  //   // card.date가 숫자일 수도 있으므로 문자열로 변환 후 길이 측정
  //   const textLength = card.date.toString().length;
  //   const newSize =
  //     textLength < 6 ? `calc(60px - ${textLength * 0.3}px)` : "2rem";
  //   setFontSize(newSize);
  // }, [card.date]);
  useEffect(() => {
    // card.date가 숫자일 수도 있으므로 문자열로 변환 후 길이 측정
    const textLength = card.date?.toString().length || 0;

    // const textLength = card.date.toString().length;
    let newSize = "3.75rem"; // 초기 기본값

    if (textLength < 6) {
      // newSize = `calc(60px - ${textLength * 0.5}px)`;
      newSize = `calc(3.75rem - ${textLength * 0.1}rem)`;
    } else if (textLength < 8) {
      newSize = "2.77rem"; // 48px
    } else if (textLength < 9) {
      newSize = "2.55rem"; // 48px
    } else if (textLength < 10) {
      newSize = "2.36rem"; // 40px
    } else {
      newSize = "2rem"; // 32px
    }

    setFontSize(newSize);
  }, [card.date]);

  // useEffect(() => {
  //   // 숫자 길이에 따라 폰트 사이즈 조정
  //   const length = card.date.toString().length;
  //   const newSize = Math.max(16, 32 - length * 2); // 숫자 길이가 증가할수록 폰트 사이즈 감소
  //   setFontSize(`${newSize}px`);
  // }, [card.date]);

  return (
    <Grid
      item
      //   xs={12}
      //   md={12}
      //   md={2}
      sx={{
        height: "inherit",
        "& .MuiGrid-root": {
          //   height: "100%",
        },
        "& .MuiPaper-root": {
          background: "none",
          border: "0",
          boxShadow: "0",
          height: "inherit",

          //   height: "100% !important",
        },
        // "& .MuiGrid-item": { height: "100%" },
      }}
      // sx={{ width: "100%", flex: 1, display: "flex" }}
    >
      {/* <CardActionArea
      //   component="a"
      // href="#"
      > */}
      {/* <Card sx={{ display: "flex" }}> */}
      <Card
      // sx={{
      //   "& .MuiPaper-root": {
      //     background: "none",
      //   },
      // }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 3,
            // height: "100%",
            // height: "inherit",
            height: "inherit",
            // "& .MuiPaper-root": { height: "inherit" },

            //
            //   alignItems: "flex-start",
            //   m: 2,
            //   bgcolor: "background.paper",
            //   borderRadius: 1,
          }}
        >
          {/* <Box
            sx={
              {
                // display: "flex",
                // alignItems: "flex-start",
                // m: 2,
              }
            }
          > */}
          {/* <CardMedia
            component="img"
            sx={{
              width: 50,
              height: 50,
              // width: 100,
              display: { xs: "none", sm: "block" },
            }}
            image={card.image}
            // src={card.src}
            alt={card.imageLabel}
          /> */}
          {/* </Box> */}

          {/* <CardContent sx={{ flex: 1 }}> */}
          <CardContent
            // sx={MainTopCardSx}
            sx={{
              p: 0,
              pb: "0 !important",
              //   background: 0,
              //   background: "none",
              //   "& .MuiPaper-root": {
              //     background: "none",
              //   },
              //   "& .MuiCard-root": {
              //     background: "none",
              //   },
              height: "inherit",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "flex-end",
                mt: 1,
                p: 0,
                height: "inherit",
                // padding: "0 !impprtant",
                //   m: 2,
              }}
            >
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  textAlign: "left",
                  //   "& .MuiTypography-root": { textAlign: "left" },
                }}
              >
                {card.title}
              </Typography>
              <Box
                sx={{
                  textAlign: "right",
                  height: "inherit",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "baseline",
                  alignContent: "flex-end",
                }}
              >
                {card.date && (
                  <Typography
                    component="subtitle1"
                    variant="h2"
                    sx={{
                      color: "#468ECD",
                      // fontWeight: 500,
                      fontWeight: "600",
                      fontFamily: "sans-serif",
                      fontSize: {
                        // xs: "3vw",
                        // sm: "2vw",
                        // md: "1.5vw",
                        // lg: "1.25vw",
                      }, // 동적 폰트 사이즈
                      fontSize: fontSize, // 동적으로 조정된 폰트 사이즈
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // whiteSpace: "nowrap", // 바깥으로 흘러나가지 않도록 처리
                      // "& .MuiTypography-root": { textAlign: "left" },
                    }}
                    //   variant="subtitle1"
                    //   color="text.secondary"
                  >
                    {/* {card.date} */}
                    {formatNumber(card.date)} {/* 숫자를 포맷팅하여 표시 */}
                  </Typography>
                )}
                <Typography
                  component="subtitle1"
                  //   variant="h5"
                  sx={{
                    color: "#468ECD",
                    "& .MuiTypography-root": { textAlign: "right" },
                  }}
                  variant="h5"
                  type="number"
                  //   paragraph
                >
                  {card.description}
                </Typography>
                {/* <Typography variant="subtitle1" color="primary">
              Continue reading...
            </Typography> */}
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
      {/* </CardActionArea> */}
    </Grid>
  );
}

// StatisticsCard.propTypes = {
//   card: PropTypes.shape({
//     date: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     // image: PropTypes.string.isRequired,
//     // src: PropTypes.string.isRequired,
//     imageLabel: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//   }).isRequired,
// };

StatisticsCard.propTypes = {
  card: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // 숫자와 문자열 모두 허용
    description: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatisticsCard;
