import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 플랫폼 판매 데이터
const fetchPlatformSales = (CST02) => {
  return client.post(`/user/mypage/CST02`, { CST02 });
};

export const usePlatformSalesQuery = (CST02) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["platform-sales-progress", CST02],
    queryFn: () => fetchPlatformSales(CST02), // 함수를 반환하는 함수를 사용
    select: (result) => result.data.data,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["platform-sales-progress"]);
    // },
    staleTime: 300000, // 5분
    refetchOnWindowFocus: true,
  });
};
