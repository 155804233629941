import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 직접 판매 데이터
const fetchDirectSales = (CST01) => {
  return client.post(`/user/mypage/CST01`, { CST01 });
};
export const useDirectSalesQuery = (CST01) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["direct-sales-progress", CST01],
    // queryFn: fetchDirectSales(CST01),
    queryFn: () => fetchDirectSales(CST01), // 함수를 반환하는 함수를 사용
    select: (result) => result.data.data,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["direct-sales-progress"]);
    // },
    staleTime: 300000, // 5분
    refetchOnWindowFocus: true,
  });
};
