import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

export const SelectForm = ({
  onSelectChange,
  // selectOption,
  value,
  options,
  label,
  placeholder,
  disabled,
}) => {
  const handleSelectChange = (event) => {
    const newSelectOption = event.target.value;
    // setSelectOption(newSelectOption);
    onSelectChange(newSelectOption);
  };

  return (
    <FormControl
      fullWidth
      size="small"
      //   sx={{
      //     minWidth: 240,
      //     // width: "90%",
      //     borderColor: "white",
      //     color: "white",
      //     "& .MuiSvgIcon-root": { color: "white" },
      //     "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
      //   }}
    >
      <InputLabel id="select-form-label">{label}</InputLabel>
      <Select
        labelId="select-form-label"
        id="select-form"
        // value={selectOption}
        value={value}
        // value={selectOption?.value || ""}
        label={label}
        placeholder={placeholder}
        onChange={handleSelectChange}
        disabled={disabled}
        // variant="standard"
        // sx={{
        //   color: "white",
        //   borderColor: "white",
        //   "&:hover": {
        //     borderColor: "#fff !important",
        //     background: "#ccc",
        //     border: "1px solid #ccc",
        //     color: "#000",
        //   },
        //   "&:before": { borderColor: "white" },
        //   "&:after": { borderColor: "white" },
        //   "& .MuiSvgIcon-root": { color: "white" },
        //   "&:hover:not(.Mui-disabled):before": {
        //     borderColor: "white",
        //   },
        //   "& .MuiOutlinedInput-notchedOutline": {
        //     borderColor: "white",
        //   },
        //   "& .MuiInputBase-root": {
        //     borderColor: "white",
        //   },
        // }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {/* <MenuItem value={"recent"}>등록일자순</MenuItem>
    <MenuItem value={"toprated"}>추천순</MenuItem>
    <MenuItem value={"cheapest"}>인기순</MenuItem> */}
      </Select>
    </FormControl>
  );
};
