import React, { useEffect, useState } from "react";
import { client as axios } from "assets/js/axios";
import { DataGrid } from "@mui/x-data-grid";
// import { Stack } from "@mui/material";
// import { ThemeProvider } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Paper, Typography, Grid, Tooltip } from "@mui/material";
import BasicTooltip from "components/tooltips/BasicTooltip";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.selected-row": {
            backgroundColor: "lightblue",
          },
        },
      },
    },
  },
});

const DataGridModalA = ({
  onCellClick,
  // onFinalClickInfoChange,
  rows,
  columns,
  handleOnCellClick,
  autoCloseText,
  selectedUserId,
  placement,
}) => {
  // const [recentData, setRecentData] = useState([]);
  // console.log("####recentData", recentData);
  const [finalClickInfo, setFinalClickInfo] = useState();
  const [forceRender, setForceRender] = useState(false); // 강제 렌더링을 위한 상태 추가

  return (
    <ThemeProvider theme={theme}>
      <Grid
        item
        // xs
        // xs={6}
        xs={12}
        // sm={6}
        // md={6}
        // spacing={2}
        sx={{
          // flex: "unset",
          width: "100%",
          // width: "auto",
          // display: "flex",
          // "& > :not(style)": {
          //   m: 1,
          //   width: 128,
          //   height: 128,
          // },
        }}
      >
        {/* <Paper variant="outlined"> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // p: 2,
            // border: "1px solid #8192bc",
            // padding: "24px",
            borderRadius: "4px",
            // height: "100%",

            height: 360, // 고정하게되면 이게 젤 예쁨
          }}
        >
          {/* <Typography
          component="h3"
          // variant="h4"
          variant="h6"
          sx={{
            fontWeight: "400",
            // color: "#29314a",
            // coloc: "#000000de",
            // textAlign: "left",
            //
            // paddingBottom: "16px",
            // marginBottom: "16px",
            // borderBottom: "3px solid #8192bc",
          }}
          // align="center"
          // gutterBottom
        >
          회사 및 담당자 조회
        </Typography> */}
          <Typography
            component="h3"
            // variant="h4"
            // variant="h6"
            sx={{
              fontWeight: "400",
              // color: "#00000063",
              color: "#939393",
              // fontSize는 >> 16이나 14Px
              // fontSize: "0.875rem",
              // color: "#29314a",
              // coloc: "#000000de",
              // textAlign: "left",
              //
              // paddingBottom: "16px",
              // marginBottom: "16px",
              // borderBottom: "3px solid #8192bc",
            }}
            // align="center"
            // gutterBottom
          >
            {/* 검색결과 {recentData.length}개 */}
          </Typography>
          <Typography
            component="h3"
            // variant="h4"
            // variant="h6"
            sx={{
              fontWeight: "400",
              // color: "#00000063",
            }}
            // align="center"
            // gutterBottom
            fontSize="14px"
            color="red"
          >
            {autoCloseText}
          </Typography>
          {/* {finalClickInfo && (
          <Typography>선택된 셀 정보**: {finalClickInfo}</Typography>
        )} */}
          <DataGrid
            // onRowClick={handleRowClick}
            // {...data}
            // children={children}
            sx={{
              boxShadow: 0,
              border: 0,
              padding: 0,
              // borderColor: "primary.light",
              "& .MuiDataGrid-cell:": {},
              "& .MuiDataGrid-withBorderColor": {
                border: 0,
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-cell:nth-child(1)": {
                // color: "#468ECD",
                fontWeight: "500",
                padding: "0 !important",
              },
              "& .MuiDataGrid-columnHeader:nth-child(1)": {
                padding: "0 !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: 14,
                minHeight: "40px !important",
                maxHeight: "40px !important",
                lineHeight: "40px !important",
              },
              "& .MuiDataGrid-footerContainer": {
                display: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              // pointerEvents S
              // "& .MuiDataGrid-cell": {
              //   pointerEvents: "none",
              // },
              // pointerEvents E
            }}
            rows={rows}
            columns={columns}
            // disableRowSelectionOnClick
            columnHeight={25}
            rowHeight={25}
            onCellClick={handleOnCellClick}
            // 결제요청에만 해당 S
            getRowClassName={(params) =>
              params.id === selectedUserId ? "selected-row" : ""
            }
            // 결제요청에만 해당 E
            // finalClickInfo={finalClickInfo}
            // recentData={recentData}
            // key={forceRender} // 강제 렌더링을 위해 key prop 추가
            // onStateChange={forceRender}
            // onStateChange={() => setForceRender((prev) => !prev)}
          />

          {/* </Paper> */}
        </Box>
        {/* {finalClickInfo && <p>선택된 셀 정보: {finalClickInfo}</p>} */}
        {/* <Typography>선택된 셀 정보: {finalClickInfo.recital}</Typography> */}
        {/* {finalClickInfo && finalClickInfo.row.recital
        ? `Final clicked finalClickInfo.row.doc = ${finalClickInfo.row.recital}`
        : null} */}
      </Grid>
    </ThemeProvider>
  );
};

export default DataGridModalA;
