import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ModalDirectContractNew from "components/modal/ModalDirectContractNew"; // 일반계약(직접계약) 모달창
import ModalPlatformContractNew from "components/modal/ModalPlatformContractNew";

const actions = [
  {
    icon: <AddBusinessIcon />,
    name: "플랫폼계약",
    modal: "platform",
    // step_type: "200",
  },
  {
    icon: <PersonAddIcon />,
    name: "일반계약",
    modal: "direct",
    // step_type: "100",
  },
];

export const ContractTypeSelDialog = () => {
  const access = sessionStorage.getItem("access");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [stepType, setStepType] = useState(null); // stepType 상태 추가
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   const openContractModal = () => setIsModalOpen(true);

  // 이거는 그냥 첫번째 두번째 인수 인자로 사용해서 이름이 다랄도 되고 순서만 맞추면 된다.
  const openModal = (type, step_type) => {
    if (!access || access === "null") {
      navigate("/login", { replace: true });
      console.log("####@@@@loader", navigate);
      return; // 추가 실행 중지
    }
    setModalType(type); // 설정한 모달 타입을 저장
    // setStepType(step_type); // stepType 상태 설정
    handleClose(); // SpeedDial을 닫음
  };
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          height: 330,
          transform: "translateZ(0px)",
          flexGrow: 1,
          bottom: "5%", // 하단에서 24px 위에 위치
          right: "1.65%", // 오른쪽에서 24px 떨어진 위치
          background: "pink",
          zIndex: 50,
        }}
      >
        <Backdrop open={open} />
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            //   backgroundColor: "blue", // 이렇게 배경색을 추가할 수 있습니다.
            //   "&:hover": {
            //     backgroundColor: "darkblue", // 호버 시 더 어두운 파란색으로 변경
            //   },
            // 메안컬러 29314A
            // 303956
            "& > .MuiButtonBase-root": {
              backgroundColor: "#468ECD",
              "&:hover": {
                backgroundColor: "#468ECD",
              },
            },
          }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              //   onClick={handleClose}
              //   onClick={() => {
              //     handleClose(); // SpeedDial을 닫음
              //     openContractModal(); // 모달을 엶
              //   }}
              onClick={() =>
                openModal(
                  action.modal,
                  // action.step_type
                )
              }
            />
          ))}
        </SpeedDial>
      </Box>
      {/* {isModalOpen && (
        <ModalDirectContractNew onClose={() => setIsModalOpen(false)} />
      )} */}
      {modalType === "direct" && (
        <ModalDirectContractNew
          // step_type={stepType}
          onClose={() => setModalType(null)}
        />
      )}
      {modalType === "platform" && (
        <ModalPlatformContractNew
          // step_type={stepType}
          onClose={() => setModalType(null)}
        />
      )}
    </>
  );
};
