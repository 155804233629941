import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LockPersonIcon from "@mui/icons-material/LockPerson";

export default function SignedStatusView({ contractData, isSeller }) {
  const SignSxBefore = { color: "#717171" };
  const SignSxAfter = {
    color: "#2f3655",
    marginRight: "20px",
    position: "relative",
  };

  const isSigned = isSeller
    ? contractData.sign_seller_yn === "Y"
    : contractData.sign_buyer_yn === "Y";

  return (
    <Box>
      <Typography
        sx={isSigned ? SignSxAfter : SignSxBefore}
        component="body2"
        variant="subtitle2"
      >
        인증완료
        {isSigned && (
          <Typography
            component="span"
            sx={{ position: "absolute", right: "-20px", top: "-2px" }}
          >
            <LockPersonIcon fontSize="small" sx={{ color: "#468ECD" }} />
          </Typography>
        )}
      </Typography>
    </Box>
  );
}
