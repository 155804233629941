import React, { createContext, useState } from "react";

export const MessageContext = createContext();

const messageList = {
  label00: "제목 입력", // 00
  // 240514 추가 S
  label01: "계약 금액 입력", // 01
  label02: "데이터 총 제공 횟수 입력", // 02
  label03: "그룹 선택", // 03
  label04: "계약 담당자 선택", // 04
  // 240514 추가 E
  label05: "데이터 명 입력", // 05
  label06: "기간 입력", // 06
  label07: "범위 입력", // 07
  label08: "범위 입력", // 08

  // label09: "범위 입력", // 09

  label09: "기간 입력", // 09
  label10: "한도 횟수 입력", // 10
  label11: "존속 년수 입력", // 11
  label12: "위반 시정기간 일수 입력", // 12
  label13: "이행 지체 일수 입력", // 13
  label14: "지방 법원 명 입력", // 14
  label15: "공정 명 입력", // 15
  label16: "데이터 명 입력", // 16
  label17: "개월 수 입력", // 17
  label18: "매출의 제공 % 입력", // 18
  label19: "제공 건수/ 년 입력", // 19
  label20: "매출의 제공 % 입력", // 20
  label21: "제공 건수/ 년 입력", // 21
};

const platform_messageList = {
  label00: "제목 입력", // 00
  // 240514 추가 S
  label01: "계약 금액 입력", // 01
  label02: "데이터 총 제공 횟수 입력", // 02
  label03: "그룹 선택", // 03
  label04: "계약 담당자 선택", // 04
  // 240514 추가 E
  label05: "범위 입력", // 05
  label06: "범위 입력", // 06
  label07: "개월 수 입력", // 07
  label08: "영업 일수 입력", // 08

  // label09: "범위 입력", // 09

  label09: "정산 보류일수 입력", // 09
  label10: "위반 시정 기간일수 입력", // 10
  label11: "지급의무 지체일수 입력", // 11
  label12: "공정 명 입력", // 12 7
  label13: "데이터 명 입력", // 13 6
  label14: "개월 수 입력", // 14 5
  label15: "매출의 제공 % 입력", // 15 4
  label16: "제공 건수/ 년 입력", // 16 3
  label17: "매출의 제공 % 입력", // 17 2
  label18: "제공 건수/ 년 입력", // 18 1
};

const file_messageList = {
  label00: "파일 선택",
  label01: "Main Category 입력",
  label02: "Sub Category 입력",
  label03: "가격 입력",
  label04: "설명 입력",
  label05: "수집 시작일 입력",
  label06: "수집 종료일 입력",
  label07: "수집장비 입력",
  label08: "수집공정 입력",
  label09: "수집제품 입력",
};

export const MessageProvider = ({ children }) => {
  // const [refreshData, setRefreshData] = useState(false); // 추가: 데이터 갱신 상태
  const [refreshData, setRefreshData] = useState(0); // 추가: 데이터 갱신 상태

  const triggerRefresh = () => {
    // setRefreshData((prev) => !prev); // 추가: 데이터 갱신 트리거
    setRefreshData((prev) => prev + 1); // 카운터 증가
  };
  return (
    <MessageContext.Provider
      value={{
        messageList,
        platform_messageList,
        file_messageList, // 추가 : 스토리지 파일 입력창
        refreshData, // 추가: refreshData 상태 전달
        triggerRefresh, // 추가: refreshData 상태 변경 함수 전달
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
