import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 플랫폼 구매 데이터
const fetchPlatformBuying = (CST03) => {
  return client.post(`/user/mypage/CST03`, { CST03 });
};
export const usePlatformBuyingQuery = (CST03) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["platform-buying-progress", CST03],
    queryFn: () => fetchPlatformBuying(CST03),
    select: (result) => result.data.data,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["platform-buying-progress"]);
    // },
    staleTime: 300000, // 5분
    refetchOnWindowFocus: true,
  });
};
