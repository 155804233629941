import React, { useEffect, useState } from "react";
import client from "assets/js/commonApi";
import {
  Box,
  Typography,
  Dialog,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Slide from "@mui/material/Slide";

// 추가 S
import { useNavigate } from "react-router-dom";

import SearchBarContractHandler from "components/searchs/SearchBarContractHandler";

import DataGridModalA from "components/grid/DataGridModalA";
import BasicTooltip from "components/tooltips/BasicTooltip";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalContractHandlerSelect({
  onClose,
  isOpen,
  handleClose,
  contract_number,
  handleOnlyQrFileClose,
  // onCellClick,
  // finalClickInfo,
  onFinalClickInfoChange,

  // onCellClick,
}) {
  const [coHandlerList, setCoHandlerList] = useState(); // 불러온 전체 데이터
  const [schCriteria, setSchCriteria] = useState({ coName: "", mgrName: "" }); // 서치 검색기준
  const [finalClickInfo, setFinalClickInfo] = useState(); // 최종 클릭한 정보를 부모에게 넘겨주는 useState

  // 추가하기 S

  // // 회사명 상태 관리 (기존 useState Name 참고하기 - 기존 계약서창 확인)
  // const [coName, setCoName] = useState(); // 회사명
  // const [mgrName, setMgrName] = useState(); // 담당자(매니저)
  // // 담당자 상태 관리 (기존 useState Name 참고하기)

  // 추가하기 E

  // const handleClickOpen = (params) => {
  //   console.log("## params", params);
  //   console.log("## handleClickOpen", handleClickOpen);
  //   setIsOpenModal(true);
  //   // setSelectDid(params.row.did_buyer);
  //   // setIsOpenNewModal(true);
  //   setSelectContractNumber(params.row.contract_number);
  // };
  // const handleClose = () => {
  //   setIsOpenModal(false);
  //   // setIsOpenNewModal(false);
  //   GetData();
  // };

  // 회사 담장자 선택 데이터
  const COHANDLERLIST_API_ENDPOINT = "/company/list";
  // const COHANDLERLIST_API_ENDPOINT = "/mdpt_platform/basic";

  const GetData = (searchParams) => {
    const { coName = "", mgrName = "" } = searchParams || {};
    client
      .post(COHANDLERLIST_API_ENDPOINT, {
        params: { coName, mgrName }, // API 호출 시 검색 파라미터로 사용
      })
      .then((res) => {
        console.log("## API 호출 결과:", res.data.data); // API 호출 결과 로깅
        // 첫 번째 요소의 company_name 필드 로깅 (예시로 첫 번째 요소만 확인)
        // if (res.data.data.length > 0) {
        //   console.log(
        //     "## 첫 번째 요소의 company_name:",
        //     res.data.data[0].company_name,
        //   );
        // }

        const datas = res.data.data.flatMap((company) =>
          company.user_info
            .filter(
              (user) =>
                (!coName || company.company_name?.includes(coName)) &&
                (!mgrName || user.user_name?.includes(mgrName)),
            )
            .map((user) => ({
              id: user.user_id, // 고유 ID 사용
              // sequence: companyIndex * 1000 + userIndex + 1, // 순번 생성 (1부터 시작)
              company_name: company.company_name,
              user_name: user.user_name,
            })),
        );

        // 순번을 매기기
        const dataWithSequence = datas.map((data, index) => ({
          ...data,
          sequence: index + 1,
        }));

        console.log("## 필터링 후 데이터:", dataWithSequence);
        setCoHandlerList(dataWithSequence);

        // console.log("## 필터링 후 데이터:", datas); // 필터링 후 데이터 로깅
        // setCoHandlerList(datas);
      })
      //   const datas = res.data.data
      //     .filter((data) => {
      //       const companyName = data.company_name() || "";
      //       const sellCompanyName = data.user_name() || "";
      //       return (
      //         (!coName || companyName.includes(coName)) &&
      //         (!mgrName || sellCompanyName.includes(mgrName))
      //       );
      //     })
      //     .map((data, index) => ({
      //       ...data,
      //       id: index + 1,
      //     }));
      //   console.log("## 필터링 후 데이터:", datas); // 필터링 후 데이터 로깅
      //   setCoHandlerList(datas);
      // })
      .catch((err) => console.log("회사 담당자 검색 리스트", err));
  };

  // 검색 조건 업데이트 및 데이터 불러오기 함수 호출
  // const handleSearch = (coName, mgrName) => {
  //   // setSchCriteria({ coName, mgrName });
  //   // GetData(); // 검색 조건이 업데이트 된 후 데이터를 다시 불러옵니다.
  //   GetData({ coName, mgrName });
  // };
  // 검색 조건 업데이트 및 데이터 불러오기 함수를 호출합니다.
  const handleSearch = (coName, mgrName) => {
    // setSchCriteria({ coName, mgrName })
    console.log("## Search Parameters:", { coName, mgrName });
    // GetData();
    GetData({ coName, mgrName });
    // console.log("## GetData", GetData);
  };

  // useEffect(() => {
  //   GetData(); // 컴포넌트 마운트 시 데이터를 처음 불러옵니다.
  // }, []);
  // useEffect(() => {
  //   GetData(); // 컴포넌트 마운트 시 데이터를 처음 불러옵니다.
  // }, []); // 의존성 배열을 비워 초기 마운트 시에만 호출되도록 합니다.

  // 기존 코드
  // const handleOnCellClick = (params, recentData) => {
  //   const field = params.field; // 비교할 대상 변수로 만들고

  //   const companyName = "company_name"; // 회사명
  //   const userName = "user_name"; // 담당자명

  //   if (field === companyName) {
  //     const companyNameValue = params.row.company_name; // 클릭된 셀의 company_name 값
  //     console.log("companyName Value:", companyNameValue);
  //     onFinalClickInfoChange(companyNameValue);
  //   }
  // };
  const handleOnCellClick = (params) => {
    const companyName = params.row.company_name; // 클릭된 셀의 회사명 값
    const userName = params.row.user_name; // 클릭된 셀의 담당자명 값
    const userId = params.row.id; // 클릭된 셀의 user_id 값

    console.log("Cell clicked!", params);
    console.log("## 1companyName:", companyName); // 클릭된 셀의 회사명 값 출력
    console.log("## 2userName:", userName); // 클릭된 셀의 담당자명 값 출력
    console.log("## userId:", userId); // user_id 값 출력

    // 여기서 onFinalClickInfoChange 함수를 호출하여 회사명과 담당자명을 전달합니다.
    // 이 예시에서는 onFinalClickInfoChange가 객체를 받아 처리할 수 있다고 가정합니다.
    onFinalClickInfoChange({
      coName: companyName,
      mgrName: userName,
      userId: userId,
    });
    handleClose();
  };

  // console.log("####@@finalClickInfo", finalClickInfo);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "순번",
    //   flex: 0.5,
    // },
    {
      field: "sequence",
      headerName: "순번",
      flex: 0.5,
      valueGetter: (params) => params.row.sequence || "-", // 데이터가 없는 경우 "-" 표시
    },
    {
      field: "company_name",
      // headerName: "데이터",
      headerName: "회사명",
      flex: 3.25,
      renderCell: (params) => (
        <BasicTooltip title={params.row.company_name} placement="right-end" />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "user_name",
      headerName: "담당자",
      flex: 1.25,
      renderCell: (params) => (
        <BasicTooltip title={params.row.user_name} placement="right-end" />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    // {
    //   field: "upd_dtm",
    //   headerName: "계약일",
    //   flex: 1.25,
    //   renderCell: (params) => <BasicTooltip title={params.row.upd_dtm} />,
    //   valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    // },
  ];

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        // onClose={onClose}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* Bar header S */}
        <AppBar
          sx={{
            position: "relative",
            boxShadow: 0,
            // boxShadow: "none",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Toolbar
            sx={{
              // background: "#468ECD"
              background: "#fff",
            }}
          >
            <IconButton
              edge="start"
              // color="inherit"
              color="#9e9e9e"
              // #005081
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "#000000de",
              }}
              variant="h6"
              component="div"
            >
              계약서 계속 작성하기
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        {/* Bar header E */}
        <Container sx={{ pt: 8, pb: 6 }} maxWidth="sm">
          <Typography
            sx={{
              flex: 1,
              mb: 1,
              color: "#000000de",
            }}
            variant="h6"
            component="div"
          >
            담당자 선택
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "2% 0 13%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* Search Area S */}
            {/* <AppBar sx={{ position: "relative" }}> */}
            {/* <Toolbar sx={{ background: "#fff" }}> */}
            {/* <IconButton
                  edge="start"
                  // color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton> */}
            <SearchBarContractHandler
              // onClick={GetData}
              onSearch={handleSearch}
              // onSearchCoChanged
              // onSearchMgrChanged
              // onSearchNumerChanged={setContractNumber}
            />
            {/* </Toolbar> */}
            {/* </AppBar> */}
            {/* Search Area E */}
            {/* data Area S */}
            {coHandlerList && (
              <DataGridModalA
                rows={coHandlerList}
                columns={columns}
                // onCellClick={onCellClick}
                handleOnCellClick={handleOnCellClick}
                autoCloseText="* 담당자 선택 시 자동으로 창이 닫힘니다."

                // finalClickInfo={finalClickInfo}
                // onFinalClickInfoChange={onFinalClickInfoChange}
              />
            )}

            {/* data Area E */}
          </Box>
        </Container>
      </Dialog>
    </>
  );
}
