import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const CircularIndeterminate = () => {
  return (
    <Box
      //   color="tertiary"
      sx={{
        // color: "grey.500",
        // display: "flex",
        // justifyContent: "center",
        // width: "100%",
        // pt: 3,
        background: "rgb(255 255 255 / 50%)",
        position: "absolute",
        margin: "0 auto",
        width: "100%",
        height: "100%",
        // background: 0;
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "2",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
