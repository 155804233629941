// import { LinkProps, Link as MuiLink } from "@mui/material";
import * as React from "react";
import { Link as MuiLink } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";

// import React, { FC } from "react";

const Link = (props) => {
  return (
    <MuiLink {...props} component={ReactRouterLink} to={props.href ?? "#"} />
  );
};
export default Link;
