// 추후에 작업시 Tab 컴포넌트 분리해서 작업하기
// Tab 공통 컴포넌트 가능여부 확인 후 가능하면 공통 컴포넌트로 사용하기
// 내 계약 진행 Tab 커스텀아이징한거 가져오기

import React, { useEffect, useState } from "react";
import LabTabs from "components/Tabs";
import { styled } from "@mui/material/styles";
import {
  Button,
  Stack,
  Box,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import ModalContinue from "components/modal/ModalContinue";

const NFTManagement = () => {
  const UserName = [
    "비피앤",
    "솔루션",
    "비피앤솔루션",
    "가나다라마바사아자차카타파하",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ ㄹㅎㄹ햐;ㄹ",
  ];
  const TFTopCCardSx = styled(Card)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.h5,
    // padding: theme.spacing(1),
    // textAlign: "center",
    // display: "flex",
    // flexDirection: "column",
    // backgroundColor: "#EFF2F4",
    // position: "relative",
    // height: "100%",

    // color: theme.palette.text.secondary,
    background: "#29314a",
    color: "#fff",
  }));
  // 준비중 S
  // 오픈할때 열기할때 필요함
  // const [continueBtn, setContinueBtn] = useState();
  const [isOpenContinueModal, setIsOpenContinueModal] = useState(true);
  // const [selectContractNumber, setSelectContractNumber] = useState();

  // 오픈 버튼 있을 시 필요한
  // const handleClickContinueOpen = (e) => {
  //   // console.log("@@@params", params);
  //   // console.log("handleClickQrOpen", handleClickQrOpen);
  //   // alert("handleClickQrOpen");
  //   setIsOpenContinueModal(true);
  //   setContinueBtn(e.target.value);
  //   // setSelectContractNumber(params.data.contract_number);
  // };
  const handleContinueClose = () => {
    setIsOpenContinueModal(false);
  };
  // 준비중 E
  return (
    <>
      {/* <ModalContinue /> */}
      <Box sx={{ pb: 6 }}>
        <Container
          sx={{
            // backgroundColor: "green",
            // width: "100%",
            pt: 8,
            pb: 6,
          }}
          maxWidth="lg"
        >
          <Grid container spacing={2} sx={{ mb: 6 }}>
            <Grid item xs={5.5} sx={{ height: "100%", width: "auto" }}>
              <Box>
                <Typography component="h3" variant="h5" sx={{ flexGrow: 1 }}>
                  <Typography
                    component="span"
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#29314A",
                      display: "flex",
                    }}
                  >
                    DID
                  </Typography>
                  <Typography component="span" variant="h5">
                    {UserName[0]}
                  </Typography>{" "}
                  님
                </Typography>
              </Box>
            </Grid>
            {/* leftArea */}
            <Grid
              container
              item
              xs={6.5}
              spacing={2}
              sx={{
                "& .MuiGrid-item": {
                  paddingLeft: "0",
                  paddingTop: "0",
                },
                "& .MuiPaper-root": {
                  // padding: "0",
                  // margin: "0 8px",
                  // "&:nth-child(1)": { marginRight: "4px" },
                  // "&:nth-child(2)": { marginLeft: "4px" },
                },
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  padding: "0",
                  position: "relative",

                  // height: "100%",
                  // p: 0,
                  // "& .MuiGrid-root > .MuiGrid-item": {
                  //   padding: "0",
                  // },
                  // "& .MuiGrid-item": {
                  //   padding: "0 !important",
                  // },
                  // "& > .MuiGrid-item": {
                  //   padding: "0 !important",
                  // },
                  "& .MuiPaper-root": {
                    height: "100%",
                    padding: "16px",

                    marginRight: "2.5%",
                  },
                }}
              >
                <TFTopCCardSx>
                  <CardContent
                    sx={{
                      padding: "0px !important",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // p: 2,
                        height: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography component="h4" variant="h5">
                        <Typography
                          component="span"
                          variant="h5"
                          sx={{ display: "flex" }}
                        >
                          NFT
                        </Typography>
                        <Typography component="span" variant="h5">
                          10
                        </Typography>
                        개
                      </Typography>
                      <Typography
                        component="P"
                        variant="h5"
                        sx={{
                          color: "#468ecd",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignContent: "baseline",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography component="span" variant="h4">
                          12,321
                        </Typography>
                        원
                      </Typography>
                    </Box>
                  </CardContent>
                </TFTopCCardSx>
                <Stack
                  sx={{
                    marginTop: "4px",
                    position: "absolute",
                    width: "98%",
                  }}
                  direction="row"
                  spacing={1}
                  // justifyContent="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="text"
                    // sx={{
                    //   color: "#29314a",
                    //   position: "relative",
                    //   "&:after": {
                    //     content: '""',
                    //     display: "block",
                    //     position: "absolute",
                    //     top: "14px",
                    //     // right: "-3px",
                    //     right: "-41%",
                    //     width: "1px",
                    //     height: "10px",
                    //     background: "#80849e",
                    //   },
                    // }}
                  >
                    NFT내역
                  </Button>
                  <Button
                    variant="text"
                    // sx={{
                    //   color: "#29314a",
                    //   position: "relative",
                    //   "&:after": {
                    //     content: '""',
                    //     display: "block",
                    //     position: "absolute",
                    //     top: "14px",
                    //     right: "-5px",
                    //     width: "1px",
                    //     height: "10px",
                    //     background: "#80849e",
                    //   },
                    // }}
                  >
                    충전내역
                  </Button>
                  <Button variant="text" sx={{ color: "#29314a" }}>
                    계약대기
                  </Button>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  // height: "100%",
                  position: "relative",
                  "& .MuiPaper-root": {
                    height: "100%",
                    padding: "16px",
                    marginLeft: "2.5%",
                  },
                }}
              >
                <TFTopCCardSx>
                  <CardContent sx={{ padding: "0px !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // p: 2,
                      }}
                    >
                      <Typography component="h4" variant="h5">
                        FT
                      </Typography>
                      <Typography
                        component="P"
                        variant="h5"
                        sx={{
                          color: "#468ecd",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignContent: "baseline",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography component="span" variant="h4">
                          12,321
                        </Typography>
                        원
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{
                      p: 0,
                      mt: 2,
                    }}
                  >
                    {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "baseline",
                        alignItems: "flex-end",
                      }}
                    > */}
                    <Button // color="#468ECD"
                      sx={{
                        borderColor: "#fff",
                        color: "#fff",
                        width: "50%",
                      }}
                      target="_blank"
                      // href="/intro" // 이거 나중에, 프롭스로 넘겨주기 !!!!!!!!!!!!!!!!!!!!!!!!!!!
                      // onClick
                      size="small"
                      variant="outlined"
                    >
                      충전
                    </Button>
                    <Button // color="#468ECD"
                      sx={{
                        borderColor: "#fff",
                        color: "#fff",
                        width: "50%",
                      }}
                      target="_blank"
                      // href="/intro" // 이거 나중에, 프롭스로 넘겨주기 !!!!!!!!!!!!!!!!!!!!!!!!!!!
                      // onClick
                      size="small"
                      variant="outlined"
                    >
                      인출
                    </Button>
                    {/* </Box> */}
                  </CardActions>
                </TFTopCCardSx>
                <Stack
                  sx={{
                    marginTop: "4px",
                    position: "absolute",
                    width: "98%",
                    // color: "#29314a",
                    marginLeft: "2.5%",
                  }}
                  direction="row"
                  spacing={1}
                  // justifyContent="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="text"
                    // sx={{
                    //   color: "#29314a",
                    //   position: "relative",
                    //   "&:after": {
                    //     content: '""',
                    //     display: "block",
                    //     position: "absolute",
                    //     top: "14px",
                    //     // right: "-3px",
                    //     right: "-45%",
                    //     width: "1px",
                    //     height: "10px",
                    //     background: "#80849e",
                    //   },
                    // }}
                  >
                    FT내역
                  </Button>
                  <Button
                    variant="text"
                    // sx={{
                    //   color: "#29314a",
                    //   position: "relative",
                    //   "&:after": {
                    //     content: '""',
                    //     display: "block",
                    //     position: "absolute",
                    //     top: "14px",
                    //     right: "-5px",
                    //     width: "1px",
                    //     height: "10px",
                    //     background: "#80849e",
                    //   },
                    // }}
                  >
                    인출내역
                  </Button>
                  <Button variant="text" sx={{ color: "#29314a" }}>
                    결제대기
                  </Button>
                </Stack>
                {/* rightAred */}
              </Grid>
            </Grid>
          </Grid>

          <LabTabs />
        </Container>
        {/* <ModalContinue /> */}
        {isOpenContinueModal ? (
          <ModalContinue
            handleClose={handleContinueClose}
            open={isOpenContinueModal}
            // onClick={}
            onClose={handleContinueClose}
            isOnClick={handleContinueClose}
          />
        ) : null}
      </Box>
    </>
  );
};

export default NFTManagement;
