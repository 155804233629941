import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// 쿼리
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Reset } from "styled-reset";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Reset />
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
