// import * as React from "react";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
  Button,
  Stack,
  Box,
  Grid,
  Typography,
  Container,
  Toolbar,
  InputBase,
} from "@mui/material";
// import DidRadio from "./radio/DidRadio";
// import SelectStepType from "components/selectBox/SelectStepType";
import SearchType from "components/selectBox/SearchType";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  // "&:hover": {
  //   backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  // marginRight: theme.spacing(2),// 16px
  marginRight: theme.spacing(1), // 16px
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
  // background: "pink",
  // top: 0,
  // right: 0,
  //   backgroundColor: "pink",
  // cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   "& .MuiInputBase-root": {
  //     backgroundColor: "pink",
  //   },

  //   backgroundColor: "#fff",
  //   color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "1.125rem",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(0.5)})`,
    // paddingRight: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(0.5)})`,

    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.only("xs, xl")]: {
    //   // width: "20ch",
    //   width: "100%",
    // },
    // color: "pink",
    // borderRadius: "4px",
    // backgroundColor: "pink",
    // borderRadius: "20px",
    // "& input": {
    //   backgroundColor: "#fff",
    // },
  },
}));

const ToolbarSx = styled(Toolbar)(({ theme }) => ({
  // background: "#eef2f5",
  // borderRadius: "4px",
  // border: "1px solid #ebebebb3",
  // marginBottom: "16px",
  // display: "flex",
  // justifyContent: "flex-end",
  // minHeight: "72px",
  minHeight: "unset !important",
  // minHeight: "48px",
  // width: "100%", 230913 주석처리
  marginBottom: 8,
  marginLeft: 0,
  // marginRight: "32px",
  padding: "0px !important",
  "& .MuiInputBase-root :focus": {
    background: "#fff",
    marginLeft: 0,

    // zIndex:'0'
  },
  boxShadow: "none",
  "& > div": {
    background: "#ebebebb3",
    // marginRight: "0",
    marginLeft: "0",
    border: "1px solid #b7b6b6b3",

    borderRadius: "4px",
    // "&:focus": {
    //   backgroundColor: "blue",
    // }, // 포크스 다시적용
  },
  "& > div:focus": {
    // border: "1px solid #29304a ",
    // background: "red",
  },
  "& > div:hover": {
    border: "1px solid #29304a ",
  },

  "& .MuiInputBase-input": {
    // backgroundColor: "red",
    // padding: theme.spacing(1, 1, 1, 0),
    // // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    // width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
  "& .MuiInputBase-input:focus": {
    border: "1px solid #29304a ",
    // background: "red",
    // background: "#fff",
  },
  //   "& .MuiInputBase-root": {
  //     // backgroundColor: "red",
  //     // padding: theme.spacing(1, 1, 1, 0),
  //     // // vertical padding + font size from searchIcon
  //     // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     // transition: theme.transitions.create("width"),
  //     // width: "100%",
  //     // [theme.breakpoints.up("md")]: {
  //     //   width: "20ch",
  //     // },
  //   },
}));

export default function SearchBarContractHandler({
  onNew,
  onClick,
  onSearch,
  onChange,
  onSearchNumerChanged,
  onSearchCoChanged,
  onSearchMgrChanged,
  value,
  stepOnChange,
  inputValue,
  onInputChange,
  options,
  getOptionLabel,
  isOptionEqualToValue,
}) {
  // const [searchNum, setSearchNum] = useState("");
  // 회사명 상태 관리 (기존 useState Name 참고하기 - 기존 계약서창 확인)
  const [coName, setCoName] = useState(""); // 회사명
  const [mgrName, setMgrName] = useState(""); // 담당자(매니저)

  // 담당자 상태 관리 (기존 useState Name 참고하기)

  // const [newContractBtn, setNewContractBtn] = useState();
  // const [terminateBtn, setTerminateBtn] = useState();

  // console.log("searchNum", searchNum);
  console.log("## coName", coName);
  console.log("## mgrName", mgrName);

  // ** 이상없으면 삭제하기 S 230227
  // // onChange - 현재 페이지에서 타겟팅 해주고
  // const handleChange = (event) => {
  //   // console.log("setSearchNum", setSearchNum);
  //   // setSearchNum(event.target.value);
  //   console.log("## coName", coName);
  //   console.log("## mgrName", mgrName);
  //   setCoName(event.target.value);
  //   setMgrName(event.target.value);

  //   onSearchNumerChanged(event.target.value); // props로 부모 modald에게 전달
  // };
  // ** 이상없으면 삭제하기 E 230227

  // 회사명
  // onChange - 현재 페이지에서 타겟팅 해주고
  const handleCoNameChange = (e) => {
    console.log("## coName", coName);
    const value = e.target.value;
    setCoName(value);
    // onSearchCoChanged(value); // props로 부모 modald에게 전달
  };

  // 담당자명(매니저)
  // onChange - 현재 페이지에서 타겟팅 해주고
  const handleMgrNameChange = (e) => {
    console.log("## mgrName", mgrName);
    const value = e.target.value;
    setMgrName(value);
    // onSearchMgrChanged(value); // props로 부모 modald에게 전달
  };

  // Refresh 버튼에 대한 이벤트 핸들러 추가
  const handleReset = () => {
    // 입력한 내용만 삭제 되게
    setCoName("");
    setMgrName("");

    // 초기화 시 전체 목록을 다시 불러옵니다.
    // onSearch("", "");
  };
  // ** 이상없으면 삭제하기 S 230227
  // 버튼 - 클릭 시 onClick 이벤트 (검색 기능)
  function searchButton(e) {
    e.preventDefault();
    // if (onClick) {
    onSearch(coName, mgrName);
    // } else {
    //   console.log("검색 조건:", coName, mgrName);
    // }
    console.log("검색 조건 :", coName, mgrName);
    // console.log("###검색버튼", searchButton);
    // call your method here

    // onSearchCoChanged(coName);
    // onSearchMgrChanged(mgrName);
  }
  // ** 이상없으면 삭제하기 E 230227

  // 검색 엔터 키
  // 엔터 키 누르면 searchButton 이벤트가 실행 되는데, 타겟팅(setSearchNum)을 할필요가 있나? 라고 생각했는데, 앞번에 안했더니 타겟팅이 안됏나, 나중에 소스 완료하면 타겟팅 되는지 확인하고, 만약 실행되면 삭제하기
  function handleKeyPress(e) {
    // console.log("###handleKeyPress", handleKeyPress);
    if (e.code === "Enter") {
      // call your method here
      e.preventDefault();
      // alert("###handleKeyPress", handleKeyPress);
      console.log("Enter key pressed");
      onSearch(coName, mgrName); // 예시: 검색 실행
    }
    // setSearchNum(e.target.value); // 테스트 후 삭제해도 이상없으면 삭제 하기// 전체적으로 테스트 후 삭제하기 (검색 - 데이터그리드 관련)
    // setCoName(e.target.value);
    // setMgrName(e.target.value);

    // searchButton(e);
  }
  // console.log("handleKeyPress", handleKeyPress);

  // function deleteBtn(e) {
  //   if (onClick) {
  //     onClick(e);
  //   }
  //   console.log("해지버튼", deleteBtn);
  //   e.preventDefault();
  //   alert("###해지버튼", deleteBtn);
  //   setTerminateBtn(e.target.value);
  // }

  // setTerminateBtn
  const SearchBtn = styled(Button)({
    position: "absolute",
    right: "0",
    top: "0",
    height: "100%",
    boxShadow: "none",
    textTransform: "none",
    // fontSize: 16,
    // padding: "6px 12px",
    padding: 0,
    // border: "1px solid ",
    // lineHeight: 1.5,
    // backgroundColor: "green",
    // borderColor: "#b7b6b6b3",
    cursor: "pointer",
    color: "#999",
    zIndex: "1",
    "&:hover": {
      backgroundColor: "rgb(235,235,235,0.9)",
      // cursor: "pointer",
      boxShadow: "none",
    },
    // "& .MuiButton-startIcon": {
    //   fontSize: 50,
    // },
  });
  // const IconSx = {
  //   // color: "blue",
  //   // color: "#037cc0",
  //   // color: "#0370b0",
  //   color: "fff",
  //   // fontSize: "28px",
  //   // fontSize: 30,
  //   // hover 하려면 기본값 root에 넣어야 함
  //   "& .MuiButton-startIcon": {
  //     // fontSize: 50,
  //     // fontSize: "28px",
  //   },
  //   root: {
  //     // fontSize: "28px",
  //     // fontSize: 30,
  //     // color: "#000000de",
  //     // "&:hover": {
  //     //   color: "#fff",
  //     // },
  //   },
  //   // .MuiButton-startIcon>*:nth-of-type(1) {
  // };
  return (
    <Box sx={{ width: 1, mb: 2 }}>
      {/* <Box sx={{ width: 1, background: "red" }}> */}
      <ToolbarSx>
        <Search sx={{ flexGrow: 1 }}>
          <SearchIconWrapper>
            {/* <SearchIcon
                onClick={searchButton}
                sx={{
                  // color: "#468ECD",
                  color: "29314A",
                }}
              /> */}
            <BusinessIcon
              onClick={searchButton}
              sx={{
                // color: "#468ECD",
                // color: "#00000099",
                // color: "#0000008a",
                color: "#00000063",
                // color: "#c8c8c8",
              }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            // type="Number"
            type="search"
            id="search"
            placeholder="회사명을 입력하세요."
            inputProps={{ "aria-label": "검색하기" }}
            // value={searchNum}
            value={coName} //회사명
            onChange={handleCoNameChange}
            onKeyDown={handleKeyPress}
          />
        </Search>
        <Button
          onClick={searchButton}
          variant="contained"
          sx={{
            // height: "41px",
            height: "43.88px",
            boxShadow: "none",
            // backgroundColor: "#29304a",
            backgroundColor: "#468ECD",
            // borderRadius: "2px"
          }}
          startIcon={<SearchIcon sx={{ color: "#fff" }} />}
        >
          검색
        </Button>
      </ToolbarSx>
      <ToolbarSx>
        <Search sx={{ flexGrow: 1 }}>
          <SearchIconWrapper>
            <PersonIcon
              // onClick={searchButton}
              sx={{
                color: "#00000063",
              }}
            />
          </SearchIconWrapper>

          {/* </SearchIconWrapper> */}
          <StyledInputBase
            // type="Number"
            type="search"
            id="search"
            placeholder="담당자명을 입력하세요."
            inputProps={{ "aria-label": "검색하기" }}
            // value={searchNum}
            value={mgrName} // 담당자(매니저)
            onChange={handleMgrNameChange}
            onKeyDown={handleKeyPress}
          />
        </Search>
        <Button
          onClick={handleReset}
          variant="contained"
          sx={{
            // height: "41px",
            height: "43.88px",
            boxShadow: "none",
            // padding: "0",
            padding: "6px 9.57px",
            // borderRadius: "2px"
            color: "#000000de",
            backgroundColor: "#fff",
          }}
          startIcon={<RefreshRoundedIcon sx={{ color: "#000000de" }} />}
        >
          초기화
        </Button>

        {/* <Button
            onClick={searchButton}
            variant="contained"
            sx={{
              height: "41px",
              boxShadow: "none",
              backgroundColor: "#29304a",
              // borderRadius: "2px"
            }}
          >
            조회
          </Button> */}
      </ToolbarSx>

      {/* <Button
          onClick={searchButton}
          variant="contained"
          sx={{
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            // borderRadius: "2px"
          }}
        >
          조회
        </Button> */}
      {/* </Box> */}
    </Box>
  );
}
