import React, { useState } from "react";
import {
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";

import MyDialog from "components/modal/Dialog";

const FolderItem = ({
  storage,
  folder,
  onFolderClick,
  onFolderMoveClick,
  onDeleteStorageFolderClick,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [conformState, setConformState] = useState({
    open: false,
    callback: () => {},
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const deleteStorageFolderHandler = (event) => {
    event.stopPropagation();

    const callback = (result) => {
      if (result) {
        onDeleteStorageFolderClick({
          ...folder,
          storage_sub_id: storage.storage_sub_id,
        })();
      }
      setConformState((pre) => ({ ...pre, open: false }));
    };
    setConformState({ open: true, callback });
  };

  return (
    <>
      <ListItem>
        <ButtonBase
          sx={{
            // position: "relative",
            width: "100%",
            // height: "10%",
            // overflow: "hidden",
            backgroundColor: "#ccc",
            borderRadius: "10px",
            // float: "left",
            // justifyContent: "space-between",
          }}
          onClick={onFolderClick(folder)}
        >
          <ListItemIcon sx={{ marginLeft: "15px" }}>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography noWrap>{folder.folder_name}</Typography>
          </ListItemText>
          <IconButton
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* 추후 추가 예정 */}
            <MenuItem
              onClick={(event) => {
                onFolderMoveClick(folder);
                setAnchorEl(null);
                event.stopPropagation();
              }}
            >
              폴더 이동
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setAnchorEl(null);
                deleteStorageFolderHandler(event);
                event.stopPropagation();
              }}
            >
              폴더 삭제
            </MenuItem>
          </Menu>
        </ButtonBase>
      </ListItem>
      <MyDialog
        open={conformState.open}
        context={"정말로 삭제하시겠습니까?"}
        onClick={() => conformState.callback(true)}
        onClose={() => conformState.callback(false)}
      />
    </>
  );
};

export default FolderItem;
