// 계약 타입 라벨
export const PAYMENT_TYPE_LABELS = {
  500: "정액",
  501: "종량",
  502: "혼합",
};

// 종료 처리 라벨
export const PROCESSING_AT_END_LABELS = {
  600: "반환",
  601: "폐기",
  602: "삭제",
};

// 데이터 제공 방식 라벨
export const DATA_PROVIDED_LABELS = {
  701: "다운로드",
  702: "FTP",
  703: "DB connection",
};

// 파일 타입 라벨
export const FILE_TYPE_LABELS = {
  800: "json",
  801: "csv",
  802: "excel",
  803: "txt",
  804: "XML",
  805: "zip",
  806: "pdf",
};
