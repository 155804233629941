import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Grid,
  Typography,
  Link,
  GlobalStyles,
  Container,
  Box,
} from "@mui/material";
import flogo from "../assets/images/flogo.svg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      // color="text.secondary"
      // color="#fff"
      // sx={{ color: "#fff", mb: 1 }}
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://bpnsolution.com/">
        {/* Your Website  */}
        비피앤솔루션
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const footers = [
  {
    title: "이용약관",
    // description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "개인정보 처리방침",
    // description: [
    //   "Cool stuff",
    //   "Random feature",
    //   "Team feature",
    //   "Developer stuff",
    //   "Another one",
    // ],
  },
  {
    title: "회원가입 안내",
    // description: [
    //   "Resource",
    //   "Resource name",
    //   "Another resource",
    //   "Final resource",
    // ],
  },
  {
    title: "QNA",
    // description: ["Privacy policy", "Terms of use"],
  },
];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Footer() {
  return (
    // <ThemeProvider theme={defaultTheme} className="footerfix">
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Footer */}
      <div
        maxWidth="lg"
        component="footer"
        sx={
          {
            // flex: "1", // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            // mt: 8,
            // py: [3, 6],
          }
        }
      >
        <Box
          sx={{
            bgcolor: "#29314A",
            p: 3,
            // mt: 5,
          }}
        >
          <Typography
            component="div"
            // variant="h4"
            noWrap
            flexGrow="1"
            lineHeight={0}
            sx={{ textAlign: "center" }}
            // sx={{ flexGrow: 1 }}
            // sx={logoSx}
          >
            <Link href="/" underline="none">
              {/* USTC */}
              <img
                src={flogo}
                alt="flogo"
                sx={{ width: "150px", height: "auto" }}
              />
            </Link>
          </Typography>
        </Box>
        {/* <Container maxWidth="lg" sx={{ mt: 5, p: 0 }}>
          <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item}>
                      <Link href="#" variant="subtitle1" color="text.secondary">
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Container> */}
        <Copyright />
      </div>
      {/* End footer */}
    </ThemeProvider>
  );
}
