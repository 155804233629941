// import * as React from "react";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMainCatQuery } from "assets/hooks/useCategory/useSelectQuery";

// const categories = [
//   "식음료품 제조업",
//   "섬유제품 제조업",
//   "의복 및 모피제품 제조업",
//   "가죽, 가방 및 신발제품 제조업",
//   "목재 및 나무제품 제조업",
//   "펄프, 종이 및 종이제품 제조업",
//   "인쇄 및 기록매제 복제업",
//   "코크스, 연탄 및 석유정제품 제조업",
//   "화학물질 및 화학제품 제조업",
//   "의료용 물질 및 의약품 제조업",
//   "고무제품 및 플라스틱 제품 제조업",
//   "비금속 광물제품 제조업",
//   "1차금속 제조업",
//   "금속가공제품 제조업",
//   "전자부품, 컴퓨터, 영상 및 통신장비 제조업",
//   "의료, 정밀, 광학기기 및 시계 제조업",
//   "전기장비 제조업",
//   "그 밖의 기계 및 장비 제조업",
//   "자동차 및 트레일러 제조업",
//   "그 밖의 운송장비 제조업",
//   "가구 제조업",
//   "그 밖의 제품 제조업",
//   "산업용 기계 및 장비 수리업",
// ];

export default function CategoryButtons({
  onCategorySelect,
  selectedCategory,
}) {
  // const [selected, setSelected] = useState(0);
  // const [selected, setSelected] = useState("all");
  const { data: categories } = useMainCatQuery();

  //  // 카테고리 선택 핸들러
  //  const handleCategorySelect = (category) => {
  //   setSelected(category);
  //   onCategorySelect(category);
  // };

  // const mainCatQuery = useMainCatQuery(); // 메인쿼리 데이터
  // const { data: mainCat } = mainCatQuery;

  // const mainCats = [
  //   { value: "All mainCat", label: "전체" },
  //   ...(mainCat
  //     ? mainCat.map((Cat) => ({
  //         value: Cat.code_value,
  //         label: Cat.code_name,
  //       }))
  //     : []),
  // ];

  // const [selected, setSelected] = useState(0);
  // console.log("##mainCat", mainCat);

  const buttonStyle = (isSelected) => ({
    backgroundColor: isSelected ? "#468ECD" : "white",
    borderColor: isSelected ? "white" : "lightgray",
    // color: isSelected ? "white" : "#468ECD",
    color: isSelected ? "white" : "black",
    // borderRadius: 50,
    border: `1px solid ${isSelected ? "white" : "#468ECD"}`,
    // borderRadius: "8px",
    borderRadius: 50,
    "&:hover": {
      backgroundColor: "#468ECD",
      borderColor: "white",
      color: "white",
    },
  });

  // const buttonStyle = (isSelected) => ({
  //   backgroundColor: isSelected ? "#FD4926" : "white",
  //   borderColor: isSelected ? "white" : "lightgray",
  //   // color: isSelected ? "white" : "#FD4926",
  //   color: isSelected ? "white" : "black",
  //   // borderRadius: 50,
  //   border: `1px solid ${isSelected ? "white" : "#FD4926"}`,
  //   // borderRadius: "8px",
  //   borderRadius: 50,
  //   "&:hover": {
  //     backgroundColor: "#FD4926",
  //     borderColor: "white",
  //     color: "white",
  //   },
  // });

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        // "& > *": {
        //   margin: 0.6,
        // },
        // "& > *:not(:first-of-type)": {
        //   marginRight: 0.5, // 첫 번째 요소를 제외하고 왼쪽 마진 적용
        // },
      }}
    >
      <Button
        key="all"
        variant="outlined"
        onClick={() => {
          // setSelected("all");
          onCategorySelect("all");
          // onCategorySelect(null); //'전체' 선택시 null을 반환하여 모든 카테고리를 보여줍니다.
        }}
        sx={buttonStyle(selectedCategory === "all")} // 'all' 값 비교를 사용하여 스타일 적
      >
        전체
      </Button>
      {categories &&
        categories.map((categoryItem) => (
          <Button
            key={categoryItem.code_value}
            // key={index}
            variant="outlined"
            // onClick={() => {
            //   setSelected(index);
            //   onCategorySelect?.(categoryItem);
            // }}
            onClick={() => {
              // setSelected(categoryItem.code_value);
              onCategorySelect(categoryItem.code_value);
            }}
            // sx={buttonStyle(selected === index)}
            sx={buttonStyle(selectedCategory === categoryItem.code_value)}
          >
            {categoryItem.code_name}
          </Button>
        ))}
    </Box>
  );
}
