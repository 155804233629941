import React, { useEffect, useState, useContext } from "react";
// import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import client from "assets/js/commonApi";

// 추가 S
import { Button, Stack, Box, Grid, Typography, Container } from "@mui/material";
// 추가 E

import { v4 } from "uuid";

// 이미지
import qrimage from "../assets/images/qrimage.svg";

// JWT 디코드 함수 추가
const decodeJWT = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

const Login = () => {
  // const access = sessionStorage.getItem("access");
  // const refresh = sessionStorage.getItem("refresh");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [code, setCode] = useState({});
  const [limit, setLimit] = useState(180);

  useEffect(() => {
    console.log("pathname", pathname);
  }, []);

  useEffect(() => {
    if (limit === 0) {
      // navigate("/");
      navigate(0);

      return;
    }
  }, [limit, navigate]);

  useEffect(() => {
    const pulling_key = v4().toString();
    const c = {
      // type: "login",
      type: "user/login",
      data: {
        pulling_key: pulling_key,
        subscription_id: "",
      },
    };

    setCode(c);

    const limitInterval = setInterval(() => {
      setLimit((time) => time - 1);
    }, 1000);

    const interval = setInterval(() => {
      // let location = useLocation();

      console.log("pathname", pathname);
      // location.pathname: 현재 페이지의 경로 URL

      client
        .create({
          timeout: 3000,
        })
        .get(
          // "/login_request",
          "/pulling/login",
          // .post(
          //   // "/login_request",
          //   "/pulling/login",

          // get은 바디가 필요없다. Post만 필요하다
          {
            headers: {
              pulling_key: pulling_key,
              // access,
              // refresh,
            },
          },
        )
        .then((response) => {
          clearInterval(interval);
          // if (response.status === 200) {
          //   // 모든 헤더 이름은 소문자
          //   let accessToken = response.headers["authorization"]; // 응답헤더에서 토큰 받기
          //   let refreshToken = response.headers["refresh"]; // 응답헤더에서 토큰 받기
          //   console.log("access 토큰 :", accessToken);
          //   console.log("refresh 토큰 :", refreshToken);
          // }
          sessionStorage.setItem("access", response.headers.access);
          sessionStorage.setItem("refresh", response.headers.refresh);
          sessionStorage.setItem("user_type", response.headers.user_type);
          // sessionStorage.setItem("name", response.data.name);
          // sessionStorage.setItem("company", response.data.company);

          console.log("Access token:", response.headers.access);
          console.log("Refresh token:", response.headers.refresh);
          // console.log("DID:", response.headers.did);
          console.log("user_type:", response.headers.user_type);
          console.log("Name:", response.data.name);
          console.log("Company:", response.data.company_name);
          console.log("Full Response:", response);

          // JWT 페이로드 디코드 후 콘솔 출력
          const decodedPayload = decodeJWT(response.headers.access);
          sessionStorage.setItem("name", decodedPayload.name);
          sessionStorage.setItem("company", decodedPayload.company_name);
          sessionStorage.setItem("user_id", decodedPayload.user_id);

          console.log("Decoded JWT Payload:", decodedPayload);
          console.log("##Name:", decodedPayload.name);
          console.log("##Company:", decodedPayload.company_name);
          console.log("##user_id:", decodedPayload.user_id);

          // 메인화면으로 이동하기
          // navigate("/");
          // useLocation에 pathname 지정 // 내가 만든 변수를 인자로 전달 해줘라
          if (pathname === "/login") {
            navigate("/");
          } else {
            window.location.reload();
          }

          // 그렇다면 추가 조건문을 단다고 하면
          // If 만약에 (pathname === "/계약관리 리스트") 일때
          //  if 만약에 (온클릭을 하게 된다면)
          // return (navigate("계약관리리스트");

          // 변수 // 메소드 // 인자// 파라미터//

          // // pathname.filelist;
          // navigate("/intro");
          // navigate("/statistics");
          // // navigate("/filelist");
          // navigate("/contractlist");
          // navigate("/buylist");
          // navigate("/did");
        })
        .catch((reason) => {
          console.log(reason);
        });
    }, 3000);

    return () => {
      clearInterval(limitInterval);
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          pb: 6,
          // margin: "5% 0 1%",
          m: 0,
          p: 0,

          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            p: 0,

            // pt: 8,
            // pb: 6,
          }}
          maxWidth="lg"
        >
          <Box>
            <Typography
              component="h2"
              variant="h5"
              sx={{ fontWeight: "400", textAlign: "center" }}
              // align="center"
              gutterBottom
            >
              제조데이터 보호거래 플랫폼
              <Typography component="h2" variant="h4" sx={{ mt: 1 }}>
                로그인
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              // height: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "20px 0 10%",
            }}
          >
            {/* <img src={qrimage} alt="큐알 이미지" /> */}
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                // maxWidth: 64,
                maxWidth: 104,
                width: "100%",
              }}
            >
              <QRCode
                // size={256}
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={JSON.stringify(code)}
                viewBox={`0 0 256 256`}
              />
            </div>
          </Box>
          인증시간 : {`${limit} 초`}
        </Container>
      </Box>
      {/* <div>로그인페이지</div> */}
    </>
  );
};
export default Login;
