import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function PlatformInfoTable({ details }) {
  if (!details) return <p>데이터가 없습니다.</p>;

  const rows = [
    {
      label1: "플랫폼명",
      value1: details.data_title,
      label2: "회사명",
      value2: details.company_name,
    },
    {
      label1: "메인 카테고리",
      value1: details.data_main_cat_desc,
      label2: "서브 카테고리",
      value2: details.data_sub_cat.join("\u00A0\u00A0"),
      // label2: "등록일",
      // value2: details.ins_dtm,
    },
    // {
    //   label1: "수집 시작 일자",
    //   value1: details.collect_from_dtm,
    //   label2: "수집 종료 일자",
    //   value2: details.collect_to_dtm,
    // },
    {
      label1: "데이터 수집 시작일",
      value1: details.collect_from_dtm,
      label2: "데이터 수집 종료일",
      value2: details.collect_to_dtm,
    },
    {
      label1: "데이터 수집 가공 단계",
      value1: details.collect_process,
      label2: "데이터 수집 장비",
      value2: details.collect_machine,
    },
    {
      label1: "데이터 수집 제품",
      value1: details.collect_product,
      label2: "데이터 사용자 구매 여부",
      value2: details.user_buy,
    },
    {
      label1: "입력일",
      value1: details.ins_dtm,
      label2: "수정일",
      value2: details.upd_dtm,
    },
    // {
    //   label1: "서브 카테고리",
    //   value1: details.data_sub_cat.join(", "),
    //   label2: "가격",
    //   value2: `${details.data_price}원`,
    // },
    // {
    //   label1: "파일 확장자",
    //   value1: details.data_ext,
    //   label2: "파일 크기",
    //   value2: `${details.data_size}바이트`,
    // },
    // {
    //   label1: "다운로드 횟수",
    //   value1: details.download_cnt,
    //   label2: "조회수",
    //   value2: details.view_cnt,
    // },
    // {
    //   label1: "수집 시작 일자",
    //   value1: details.collect_from_dtm,
    //   label2: "수집 종료 일자",
    //   value2: details.collect_to_dtm,
    // },
    // {
    //   label1: "수집 과정",
    //   value1: details.collect_process,
    //   label2: "수집 장비",
    //   value2: details.collect_machine,
    // },
    // {
    //   label1: "수집 공정",
    //   value1: details.collect_product,
    //   label2: "이미지 URL",
    //   value2: details.data_img_url,
    // },
  ];

  // const rows = [
  //   {
  //     label1: "플랫폼명",
  //     value1: details.data_title,
  //     label2: "주 카테고리",
  //     value2: details.data_main_cat_desc,
  //   },
  //   {
  //     label1: "회사명",
  //     value1: details.company_name,
  //     label2: "등록일",
  //     value2: details.ins_dtm,
  //   },
  //   {
  //     label1: "서브 카테고리",
  //     value1: details.data_sub_cat.join(", "), // 배열을 문자열로 변환
  //     label2: "가격",
  //     value2: `${details.data_price}원`,
  //   },
  //   {
  //     label1: "파일 확장자",
  //     value1: details.data_ext,
  //     label2: "파일 크기",
  //     value2: details.data_size,
  //   },
  //   {
  //     label1: "다운로드 횟수",
  //     value1: details.download_cnt,
  //     label2: "조회수",
  //     value2: details.view_cnt,
  //   },
  // ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="custom layout table">
        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  backgroundColor: "#EFF2F4",
                  // width: '150px'
                  width: "15%",
                }}
                component="th"
                scope="row"
              >
                <span style={visuallyHidden}>필드: </span>
                {item.label1}
              </TableCell>
              <TableCell sx={{ width: "35%" }}>
                <span style={visuallyHidden}>정보: </span>
                {item.value1}
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#EFF2F4", width: "15%" }}
                component="th"
                scope="row"
              >
                <span style={visuallyHidden}>필드: </span>
                {item.label2}
              </TableCell>
              <TableCell sx={{ width: "35%" }}>
                <span style={visuallyHidden}>정보: </span>
                {item.value2}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PlatformInfoTable;
