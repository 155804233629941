import * as React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function FTBottomCard(props) {
  const navigate = useNavigate();
  const introPageMove = () => {
    navigate("/intro");
    console.log("introPageMove", introPageMove);
  };

  const { card } = props;

  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{ mb: 3, "& .MuiPaper-root": { background: "#EFF2F4" } }}
    >
      <Card>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}
        >
          <CardContent
            sx={{
              p: 0,
              pb: "0 !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 1,
                p: 0,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#468ECD", fontWeight: "500" }}
                >
                  {card.state}
                </Typography>
                <Typography sx={{ color: "#29314a" }}>{card.date}</Typography>
              </Box>

              <Typography
                sx={{
                  mt: 1,
                  color: "#29314a",
                }}
                component="p"
                variant="h6"
              >
                {card.title}
              </Typography>
              <CardActions
                sx={{ p: 0, mt: 1, justifyContent: "space-between" }}
              >
                <Button // color="#468ECD"
                  sx={{
                    fontSize: "1.25rem",
                    borderColor: "#468ECD",
                    color: "#29314a",
                    p: 0,
                    fontWeight: "400",
                    "& .MuiButton-endIcon": { marginLeft: "0 !important" },
                  }}
                  target="_blank"
                  onClick={card.quickbtnOnClick ?? introPageMove}
                  size="large"
                  endIcon={<KeyboardArrowRightIcon />}
                >
                  {card.quickbtn}
                </Button>
                <Box>
                  {card.cancelbtn && (
                    <Button
                      onClick={card.cancelbtn.onClick}
                      variant="contained"
                      sx={{
                        height: "41px",
                        boxShadow: "none",
                        backgroundColor: "#29304a",
                        marginLeft: 1,
                      }}
                    >
                      {card.cancelbtn.text}
                    </Button>
                  )}
                  {card.acceptbtn && (
                    <Button
                      onClick={card.acceptbtn.onClick}
                      variant="contained"
                      sx={{
                        height: "41px",
                        boxShadow: "none",
                        backgroundColor: "#29304a",
                        marginLeft: 1,
                      }}
                    >
                      {card.acceptbtn.text}
                    </Button>
                  )}
                </Box>
              </CardActions>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
}

FTBottomCard.propTypes = {
  card: PropTypes.shape({
    state: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    quickbtn: PropTypes.string.isRequired,
    quickbtnOnClick: PropTypes.func,
    cancelbtn: { text: PropTypes.string.isRequired, onClick: PropTypes.func },
    acceptbtn: { text: PropTypes.string.isRequired, onClick: PropTypes.func },
  }).isRequired,
};

export default FTBottomCard;
