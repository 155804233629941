import * as React from "react";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot } from "@mui/x-charts/LineChart";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";

export default function ChartBasic({ series, xAxis }) {
  //   const uData = [8, 5, 7, 10];
  //   const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  // const xLabels = [
  //   "6월",
  //   "7월",
  //   "8월",
  //   // "9월",
  //   // "Page E",
  //   // "Page F",
  //   // "Page G",
  // ];

  // function formatYAxis(value) {
  //   console.log("##@@Formatting value:", value); // 현재 처리되는 값 로깅
  //   if (value >= 1e9) {
  //     return (value / 1e9).toFixed(1) + "B"; // 십억 단위
  //   } else if (value >= 1e6) {
  //     return (value / 1e6).toFixed(1) + "M"; // 백만 단위
  //   } else if (value >= 1e3) {
  //     return (value / 1e3).toFixed(1) + "K"; // 천 단위
  //   }
  //   return value.toString(); // 1000 이하면 그대로 표시
  // }

  // 소숫점
  // const formatYAxis = (value) => {
  //   const suffixes = [
  //     { scale: 1e9, suffix: "B" },
  //     { scale: 1e6, suffix: "M" },
  //     { scale: 1e3, suffix: "K" },
  //   ];

  //   const { scale, suffix } =
  //     suffixes.find(({ scale }) => value >= scale) || {};
  //   if (scale) {
  //     return (value / scale).toFixed(1).replace(/\.0$/, "") + suffix;
  //   }

  //   // 1000 이하의 숫자는 그대로 문자열로 반환
  //   return value.toString();
  // };
  //
  const formatYAxis = (value) => {
    const suffixes = [
      { scale: 1e9, suffix: "B" },
      { scale: 1e6, suffix: "M" },
      { scale: 1e3, suffix: "K" },
    ];

    const { scale, suffix } =
      suffixes.find(({ scale }) => value >= scale) || {};
    if (scale) {
      // value를 scale로 나누고 정수로 변환
      return Math.floor(value / scale) + suffix;
    }

    // 1000 이하의 숫자는 그대로 문자열로 반환
    return value.toString();
  };

  return (
    <ChartContainer
      series={series}
      width={280}
      height={260}
      xAxis={xAxis}
      // xAxis={[
      //   {
      //     id: "years",
      //     data: xLabels,
      //     scaleType: "band",
      //     categoryGapRatio: 0.98,
      //     // categoryGapRatio: 1.5,
      //     valueFormatter: (value) => value.toString(),
      //   },
      // ]}
      //   yAxis={[{ scaleType: "log" }]}
      //   yAxis={[{ scaleType: "linear" }]}
      //   yAxis={[{ disabled: true }]}

      //   xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      //   series={[
      //     {
      //       data: [2, 5.5, 2, 8.5, 1.5, 5],
      //     },
      //   ]}
      //   curve="linear"
      //
      yAxis={[
        {
          id: "eco",
          scaleType: "linear",
        },
      ]}
      sx={{
        "& .MuiLineElement-root": {
          //   strokeDasharray: "10 5",
          strokeWidth: 5,
          // stroke: "#5196cf",
          stroke: "#2a2f4a",
          //   scaleType: "stepAfter",
        },
        "& .MuiMarkElement-root": {
          //   display: "none",
          //   strokeDasharray: "10 5",
          //   strokeSize: "4",
          //   stroke: "5",
          scale: "1.5",
          tickFontSize: 18,
          stroke: "#5196cf",
        },
        "& .MuiAreaElement-series-Germany": {
          fill: "url('#myGradient')",
        },
        "& .MuiChartsXAxis-root": {
          tickSize: 22,
          tickFontSize: 22,
          fontSize: "22px",
        },
        "& .MuiChartsAxis-tickLabel": {
          fill: "#5196cf",
          //   tickFontSize: 50,
          //   tickSize: 50,
          fontSize: "14px",
        },
        "& .MuiChartsAxis-line": {
          //   display: "none",
          stroke: "#5196cf",
          // stroke: "red !important",
        },
        "& .MuiChartsAxis-directionY": {
          //   display: "none",
        },

        "& .MuiChartsAxis-tick": {
          //   disabled: true,
          //   stroke: "#5196cf",
          stroke: "red",
          display: "none",
          //   scale: "8",
          //   strokeWidth: "1",
        },
        "& .MuiBarElement-root": {
          stroke: "#5196cf",
        },
      }}
    >
      <BarPlot />
      <LinePlot />
      <ChartsXAxis
        label=""
        position="bottom"
        axisId="years"
        // data={xAxis.data}
      />
      <ChartsYAxis
        label=""
        position="left"
        axisId="eco"
        //
        // tickLabel={formatYAxis}
        valueFormatter={formatYAxis} // 포맷 함수 적용
        // scaleType="linear"
        tickLabelStyle={{ fontSize: "14px", fill: "#5196cf" }}
        // sx={{
        //   "& .MuiChartsAxis-tickLabel": {
        //     fill: "#5196cf",
        //     fontSize: "14px",
        //   },
        //   "& .MuiChartsAxis-line": {
        //     stroke: "#5196cf",
        //   },
        // }}
      />
    </ChartContainer>
  );
}
