import * as React from "react";
import { useState } from "react";
import {
  styled,
  alpha,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledSearch = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "transparent", // 배경색을 투명하게 설정합니다.
  marginLeft: theme.spacing(1),
  width: "auto",
  // marginRight: theme.spacing(1), // 검색 폼과 오른쪽 요소 사이의 공간을 조정
  // flexGrow: 1, // 검색 폼이 차지하는 공간의 유연성을 증가시킴
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  left: 0, // 아이콘을 왼쪽으로 정렬
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   // color: "inherit",
//   color: "black", // 글씨 색상을 검정색으로 변경
//   width: "100%",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     // border: "none", // 입력 필드 보더 제거 (필요시 추가)
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     // width: "40ch", // 기본 너비
//     // "&:focus": {
//     //   width: "40ch", // 포커스 상태에서의 너비를 더 넓게 설정
//     // },
//     [theme.breakpoints.up("sm")]: {
//       width: "40ch",
//       "&:focus": {
//         width: "60ch",
//       },
//     },
//   },
// }));

// StyledInputBase 스타일을 기반으로 하는 StyledTextField 정의
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    // pl: "50px",
  },
  "& .MuiInputBase-root": {
    color: "black", // 글씨 색상을 검정색으로 변경
    // width: "100%",
    // width: width, // 동적으로 넓이 설정

    "& input": {
      backgroundColor: "transparent !important",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
      transition: theme.transitions.create("width"),

      //   input:-webkit-autofill {
      //     -webkit-box-shadow: 0 0 0 1000px #181924 inset; // 배경색 변경
      //     -webkit-text-fill-color: white // 글자색 변경
      //  }
      // width: "100%",
      // width: "20ch", // sm 브레이크포인트 이하에서의 기본 너비
      // [theme.breakpoints.up("sm")]: {
      //   width: "12ch", // sm 브레이크포인트 이상에서의 기본 너비
      //   "&:focus": {
      //     width: "20ch", // 포커스 상태에서 너비 확장
      //   },
      // },
      // width: "40ch", // sm 브레이크포인트 이하에서의 기본 너비
      // 기존에 아래 스타일 적용했지만, 조건에 따라 스타일링이 필요하기 때문에 아래것은 지워주겠습니다.
      [theme.breakpoints.up("sm")]: {
        width: "40ch", // sm 브레이크포인트 이상에서의 기본 너비
        "&:focus": {
          width: "60ch", // 포커스 상태에서 너비 확장
        },
      },
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #181924 inset;",
      WebkitTextFillColor: "white",
    },
  },
}));

export default function TopMenuSearch({ isOpen, handleClose }) {
  // Search S
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  // const [inputValue, setInputValue] = useState(""); // 입력값 카운트
  // Search E

  const handleChange = (e) => {
    console.log("현재 키워드:", keyword); // 변경 전 키워드
    setKeyword(e.target.value);
    console.log("업데이트 될 키워드:", e.target.value); // 변경 후 키워드, 실제 업데이트는 비동기적으로 이루어집니다.
  };

  const searchByKeyword = (e) => {
    e.preventDefault();
    console.log("@@@ 검색 키워드:", keyword);
    // navigate(`/category?q=${keyword}`);
    // navigate(`http://localhost:3000//category/search?query=${keyword}`);
    // navigate(`/category/search?q=${keyword}`);
    navigate(`/category/?q=${keyword}`);
    // setKeyword(""); // 검색 종료 후 셋키워드 비워주고 싶을 시 추가
    handleClose(); // 검색 후 폼 닫기
    // setKeyword(e.target.value);
  };

  // const [inputWidth, setInputWidth] = useState("");
  // const [isFocused, setIsFocused] = useState(false);

  // const getInputWidth = () => {
  //   if (keyword || isFocused) {
  //     return "60ch";
  //   } else {
  //     return "20ch";
  //   }
  // };

  // 이거보면은 지금 mui 속성에 바로 적용해줬거든, 근데 나중에, 검색기능 구현하려면, 여기다가 조건을 추가해야 될수도 있어
  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   setkeyword(value);
  //   // setInputWidth(value ? "60ch" : "40ch");
  // };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: "100",
          // position: "relative",
          // width: "fit-content",
        }}
        onClick={handleClose}
      >
        <AppBar
          onClick={(e) => e.stopPropagation()} // 이벤트 버블링 방지
          position="static"
          sx={{
            flexGrow: 1,
            position: "fixed",
            // position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            // backgroundColor: "rgb",
            // backgroundColor: "rgba(0, 0, 0, 0.5)",
            // height: "100%",
          }}
          open={isOpen}
          // onClick={handleClose}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "#fff",
              height: "90px",
              flexWrap: "wrap",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              flexGrow="1"
              lineHeight={0}
              // sx={{ flexGrow: 1 }}
              // sx={logoSx}
            >
              {/* <Link href="/" underline="none"> */}
              {/* USTC */}
              <img
                src={logo}
                alt="logo"
                sx={{ width: "150px", height: "auto" }}
                size="sm"
              />
              {/* </Link> */}
            </Typography>
            {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
            {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            MUI
          </Typography> */}
            <form
              // onClick={searchByKeyword}
              onSubmit={searchByKeyword}
              // style={{ width: "100%" }}
            >
              <StyledSearch>
                <SearchIconWrapper sx={{ p: 0 }}>
                  <SearchIcon
                    sx={{ color: "#000", fontWeight: "400" }}
                    fontSize="large"
                  />
                </SearchIconWrapper>
                <StyledTextField
                  id="standard-search"
                  label="플랫폼 이름을 입력하세요."
                  type="search"
                  variant="standard"
                  value={keyword}
                  // width={inputWidth}
                  // width={getInputWidth}
                  // onChange={handleChange} // 입력값이 변경될 때 상태 업데이트
                  onChange={(e) => setKeyword(e.target.value)}
                  // onFocus={() => setIsFocused(true)}
                  // onBlur={() => setIsFocused(false)}
                  inputProps={{
                    "aria-label": "search",
                    maxLength: 50,
                    // width: getInputWidth(),
                  }}
                  // placeholder="Search…"
                />
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    transform: "translateY(100%)", // TextField 아래에 위치하도록 조정
                    color: "rgba(0, 0, 0, 0.6)", // 스타일 조정
                  }}
                >
                  {`${keyword.length} / 50`} {/* 현재 길이 / 최대 길이 표시 */}
                </Typography>
              </StyledSearch>
            </form>
            <IconButton
              sx={{
                position: "fixed",
                right: 8,
                top: 8,
                // color: "white",
                // zIndex: (theme) => theme.zIndex.tooltip,
              }}
              onClick={handleClose} // 닫기 버튼
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
