import * as React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

export default function ContractRadioBtn({
  typeValue,
  onChange,
  disabled,
  sx,
  contractTypeLabels,
}) {
  const handleRadioChange = (event) => {
    onChange(event.target.value); // 부모 컴포넌트의 함수 호출
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="radio-buttons-group-label"
        // defaultValue="500"
        name="radio-buttons-group"
        // id={id}
        value={typeValue}
        onChange={handleRadioChange}
        sx={sx}
      >
        {Object.keys(contractTypeLabels).map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio size="small" />}
            label={contractTypeLabels[value]}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
