// import * as React from "react";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MoreIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Stack,
  Box,
  Grid,
  Typography,
  Container,
  Toolbar,
  InputBase,
  Select,
  MenuItem,
} from "@mui/material";
// import DidRadio from "./radio/DidRadio";
import SearchType from "components/selectBox/SearchType";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  // "&:hover": {
  //   backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  // marginRight: theme.spacing(2),// 16px
  marginRight: theme.spacing(1), // 16px
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
  //   background: "pink",
  //   backgroundColor: "pink",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   "& .MuiInputBase-root": {
  //     backgroundColor: "pink",
  //   },

  //   backgroundColor: "#fff",
  //   color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    // borderRadius: "4px",
    // backgroundColor: "pink",
    // borderRadius: "20px",
    // "& input": {
    //   backgroundColor: "#fff",
    // },
  },
}));

const ToolbarSx = styled(Toolbar)(({ theme }) => ({
  // background: "#eef2f5",
  // borderRadius: "4px",
  // border: "1px solid #ebebebb3",
  // marginBottom: "16px",
  // display: "flex",
  // justifyContent: "flex-end",
  minHeight: "72px",
  // minHeight: "48px",
  // width: "100%", 230913 주석처리
  marginBottom: "8px",
  marginLeft: 0,
  // marginRight: "32px",
  marginRight: "14px",
  padding: "0px !important",
  "& .MuiInputBase-root :focus": {
    background: "#fff",
    marginLeft: 0,

    // zIndex:'0'
  },
  "& > div": {
    background: "#ebebebb3",
    // marginRight: "0",
    marginLeft: "0",
    border: "1px solid #b7b6b6b3",

    borderRadius: "4px",
    // "&:focus": {
    //   backgroundColor: "blue",
    // }, // 포크스 다시적용
  },
  "& > div:focus": {
    // border: "1px solid #29304a ",
    // background: "red",
  },
  "& > div:hover": {
    border: "1px solid #29304a ",
  },

  "& .MuiInputBase-input": {
    // backgroundColor: "red",
    // padding: theme.spacing(1, 1, 1, 0),
    // // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    // width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
  "& .MuiInputBase-input:focus": {
    // border: "1px solid #29304a ",
    // background: "red",
    // background: "#fff",
  },
  //   "& .MuiInputBase-root": {
  //     // backgroundColor: "red",
  //     // padding: theme.spacing(1, 1, 1, 0),
  //     // // vertical padding + font size from searchIcon
  //     // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     // transition: theme.transitions.create("width"),
  //     // width: "100%",
  //     // [theme.breakpoints.up("md")]: {
  //     //   width: "20ch",
  //     // },
  //   },
}));

export default function SearchBarUser({
  onClick,
  onChange,
  didSearchOptionsValue,
  onSearchNames,
  value,
  searchType,
  searchNumValue,
  searchButton,
  handleTypeChange,
  handleChange,
  handleKeyPress,
}) {
  // const [searchNum, setSearchNum] = useState("");
  const [registerBtn, setRegisterBtn] = useState();
  const [terminateBtn, setTerminateBtn] = useState();
  // const [radioValue, setRadioValue] = useState();
  // const [onKeyPress, setOnKeyPress] = useState("");
  // console.log("searchNum", searchNum);

  // const handleChange = (event) => {
  //   console.log("setSearchNum", setSearchNum);
  //   setSearchNum(event.target.value);
  //   onSearchNames(event.target.value);
  // };

  // function searchButton(e) {
  //   if (onClick) {
  //     onClick(e);
  //     return;
  //   }

  //   console.log("###검색버튼", searchButton);
  //   // call your method here
  //   e.preventDefault();
  //   alert("###searchButton", searchButton);
  //   setSearchNum(e.target.value);
  // }
  // // 등록 해지에 대한 유즈 스테이트 기본값 등록 f

  // function handleKeyPress(e) {
  //   console.log("###handleKeyPress", handleKeyPress);
  //   if (e.code === "Enter") {
  //     // call your method here
  //     e.preventDefault();
  //     alert("###handleKeyPress", handleKeyPress);
  //     searchButton(e);
  //   }
  //   setSearchNum(e.target.value);
  // }
  // console.log("handleKeyPress", handleKeyPress);

  function saveBtn(e) {
    if (onClick) {
      onClick(e);
    }
    console.log("등록버튼", saveBtn);
    e.preventDefault();
    alert("###등록버튼", saveBtn);
    setRegisterBtn(e.target.value);
  }

  function deleteBtn(e) {
    if (onClick) {
      onClick(e);
    }
    console.log("해지버튼", deleteBtn);
    e.preventDefault();
    alert("###해지버튼", deleteBtn);
    setTerminateBtn(e.target.value);
  }

  // setTerminateBtn
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          // display: "flex"
          flexGrow: "1",
          display: "flex",
        }}
      >
        {/* <Box sx={{ flexGrow: 1 }}> */}
        <ToolbarSx>
          <Select
            value={searchType}
            onChange={handleTypeChange}
            sx={{
              maxHeight: "41px",
              marginRight: "10px",
            }}
          >
            <MenuItem value="user_name">이름</MenuItem>
            <MenuItem value="did">DID</MenuItem>
            <MenuItem value="email">이메일</MenuItem>
          </Select>
          <Search>
            <SearchIconWrapper>
              <SearchIcon
                sx={{
                  // color: "#468ECD",
                  color: "29314A",
                }}
              />
            </SearchIconWrapper>
            <StyledInputBase
              // type="Number"
              type="search"
              id="search"
              placeholder="내용을 입력하세요"
              inputProps={{ "aria-label": "내용을 입력하세요" }}
              value={searchNumValue}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              sx={{
                "& .MuiInputBase-input:focus": {
                  border: "1px solid #29304a ",
                },
              }}
            />
          </Search>
        </ToolbarSx>
        {/* <DidRadio
          onChange={onChange}
          value={value}
        /> */}
        {/* {renderMobileMenu} */}
        {/* {renderMenu} */}
        {/* </Box> */}
      </Box>

      <Box sx={{ display: "flex ", alignItems: "center", mb: 1 }}>
        <Button
          onClick={searchButton}
          variant="contained"
          sx={{
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            // borderRadius: "2px"
          }}
        >
          조회
        </Button>
        {/* <Button
          // onClick={searchButton}
          onClick={saveBtn}
          value={registerBtn}
          variant="contained"
          sx={{
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            mr: 1,
            // borderRadius: "2px"
          }}
        >
          등록
        </Button> */}
        {/* <Button
          onClick={deleteBtn}
          value={terminateBtn}
          variant="contained"
          sx={{
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            // borderRadius: "2px"
          }}
        >
          해지
        </Button> */}
      </Box>
    </Box>
  );
}
