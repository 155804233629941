import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import client from "assets/js/commonApi";
import {
  ContractHtmlToText,
  ContractElementToText,
  GetContractValueArrayForText,
} from "assets/js/contractUtil";
import { styled } from "@mui/material/styles";
import { v4 } from "uuid";
import CryptoJS, { AES } from "crypto-js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import IconTxtBtn from "components/btns/IconTxtBtn";
import PaperComponent from "components/paper/PaperComponent"; // paper
import InputContent from "components/inputBox/InputContent"; // 내용
import DatePic from "components/picker/DatePic"; // 날짜 선택
import ContractRadioBtn from "components/radio/ContractRadioBtn"; // 레디오 버튼
import {
  PAYMENT_TYPE_LABELS,
  PROCESSING_AT_END_LABELS,
  DATA_PROVIDED_LABELS,
  FILE_TYPE_LABELS,
} from "assets/hooks/constants/contractRadioButtonLabels"; // 레디오 버튼 라벨
import ContractAccordionPlatformBuy from "components/accordion/ContractAccordionPlatformBuy"; // 아코디언
// import SelectCompany from "components/selectBox/SelectCompany"; // 회사 및 담당자 선택
import ModalContractHandlerSelect from "./ModalContractHandlerSelect"; // 담당자 선택
import ModalPaymentRequestForm from "./ModalPaymentRequestForm"; // 결제 요청
import InputDisable from "components/inputBox/InputDisable"; // 계약 담당자 - 선택 후 값 자동 저장 240305
import BtnNDragUploadFile from "components/BtnStyle/BtnNDragUploadFile"; // 파일 업로드
import {
  ContractNeedAddInput, // 유효성 경고
  InputFormDlg, // 비고 폼
  // ModalContractPlatformQr, // 서명
  SnackbarMessage, // 파일 업 및 다운로드 알림
} from "."; // 스낵바는 특정 작업(성공,실패,알림,정보 등)에 대한 가벼운 피드백을 제공
import ModalContractPlatformQr from "./ModalContractPlatformQr";
import {
  // ModalContractPlatformQr, // 서명
  SignedStatusView, // 계약서 하단 서명뷰
  FileDownloadAreaView, // 다운로드 이동 영역 뷰
} from "../viewContents/modalContractView";

import { CircularIndeterminate } from "components/loaders/CircularIndeterminate";
import { MessageContext } from "assets/js/syntax";
import { SelectForm } from "components/selectBox/SelectForm";

// 쿼리
import { useGroupSelectQuery } from "assets/hooks/useCategory/useSelectQuery";

const FormData = require("form-data");

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: "8px auto",
  display: "flex",
  flex: 1,
  color: theme.palette.text.primary,
}));

const ModalPlatformBuyContract = ({
  onClose,
  did,
  contract_sub_id,
  company_info_type,
}) => {
  // 페이지 이동
  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("refresh");
  const navigate = useNavigate();
  const fileListPageMove = () => {
    navigate("/filelist");
    console.log("## fileListPageMove", fileListPageMove);
  };

  // 로그인 유저 정보
  // const company = sessionStorage.getItem("company");
  const userDID = sessionStorage.getItem("did");
  // const userName = sessionStorage.getItem("name");

  // 240513 로그인 정보 업데이트
  const company = sessionStorage.getItem("company");
  const userName = sessionStorage.getItem("name");
  // 240711 로그인 정보 업데이트
  const contractorID = sessionStorage.getItem("user_id");

  // 쿼리
  const selectedGroupQuery = useGroupSelectQuery();
  const { data: groups, isLoading, isError } = selectedGroupQuery;

  const [contractData, setContractData] = useState({}); // 계약서 데이터
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  console.log("## contractData", contractData);
  const [recital, setRecital] = useState(""); // 내용
  const [preamble, setPreamble] = useState(); // 표준 계약서
  const [appendix, setAppendix] = useState(); // 부속 계약서
  const [preambleData, setPreambleData] = useState([company, "", ""]); // 240416 추가
  const [appendixData, setAppendixData] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // 240416 추가

  const onPreambleChange = (event, newData) => {
    setPreambleData(newData);
  };

  const onAppendixChange = (event, newData) => {
    setAppendixData(newData);
  };

  const [from_dtm, setFrom_dtm] = useState(dayjs()); // 시작 날짜
  const [to_dtm, setTo_dtm] = useState(dayjs()); // 종료 날짜
  // const [amount, setAmount] = useState(null); // 계약 금액
  // const [finalCnt, setfinalCnt] = useState(null); // 데이터 총 제공 횟수
  const [paymentValue, setPaymentValue] = useState("500"); // 레디오 버튼(결재)
  const [processingAtEndValue, setProcessingAtEndValue] = useState("600"); // 레디오 버튼(종료시 처리)
  const [dataProvidedValue, setDataProvidedValue] = useState("701"); // 레디오 버튼(데이터 제공 방식)
  const [fileTypeValue, setFileTypeValue] = useState("800"); // 레디오 버튼(제공 형태)

  const [buyerId, setBuyerId] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(); // 그룹 선택창

  // ** 검색창 테스트 S
  const [selectedCompanyValue, setSelectedCompanyValue] = useState(); // 선택된 회사명
  const [selectedManagerValue, setSelectedManagerValue] = useState(); // 선택된 담당자
  const onSelectedCoNMgrChange = ({ coName, mgrName, userId }) => {
    setSelectedCompanyValue(coName);
    setSelectedManagerValue(mgrName);
    setBuyerId(userId);
  };
  // ** 검색창 테스트 E
  const [isOpenModalPayRequest, setIsOpenModalPayRequest] = useState(false); // 팝업 open

  // *** 계약담당자선택 팝업 open NEW S
  const [handleBtn, setHandleBtn] = useState(); // 버튼
  const [isOpenModalHandlerSelect, setIsOpenModalHandlerSelect] =
    useState(false); // 팝업 open

  // *** 계약담당자선택 팝업 open NEW E

  const [companyData, setCompanyData] = useState([]); // 회사명/담당자 기본 데이터
  // 회사명 선택 콤보박스 { value: '회사코드', label: '회사명' }
  const [companyValue, setCompanyValue] = useState({});

  console.log("##companyValue", companyValue);

  const [companyInputValue, setCompanyInputValue] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const onCompanyValueChange = (event, value) => setCompanyValue(value);
  const onCompanyInputChange = (event, value) => setCompanyInputValue(value);

  // 담당자 선택 콤보박스 { value: 'did', label: '이름' }
  const [userValue, setUserValue] = useState({});
  const [isEditable, setIsEditable] = useState(false); // 수정 가능 상태
  const [reloadTrigger, setReloadTrigger] = useState(false);

  const [isEditing, setIsEditing] = useState(false); // 버튼 상태 변화

  const preambleRef = useRef(); // 표준계약서
  const appendixRef = useRef(); // 부속계약서

  // 파일 기능 (다운로드)
  const key = v4().toString();
  const fileInputRef = useRef(null);

  // BtnNDragUploadFile.js 파일 제한 및 기타코드 수정 >>> 필요없는내용있늗듯, 코드분석해서 삭제할것 삭제 하기

  // 파일 기능(업로드) S **
  const [uploadFile, setUploadFile] = useState(null); // 파일 업로드
  const [open, setOpen] = useState(false); // 파일 업로드 확장자 예외처리
  const [needAddInputOpen, setNeedAddInputOpen] = useState(false); // 비고 입력창
  const [remark, setRemark] = useState(); // 비고 입력
  const [isOpenVigoDlg, setIsOpenVigoDlg] = useState(false); // 비고창
  // 미 입력
  const [fileInfo, setFileInfo] = useState([]); // 파일 리스트 State for file info

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNeedAddInputOpen(false);
    setOpen(false); // 파일 확장자 예외처리 팝업 닫힘 기능
    setIsOpenVigoDlg(false); // 비고 폼 닫기 버튼
    setIsOpenModalHandlerSelect(false); // 담당자 선택 닫기 버튼
    setIsOpenModalPayRequest(false); // 결제 요청 닫기 버튼
  };

  // 결제 요청 오픈
  const handleClickPayRequestOpen = (params) => {
    console.log("## handleClickPayRequestOpen", handleClickPayRequestOpen);
    // alert("setIsOpenVigoDlg");
    setIsOpenModalPayRequest(true); // 담당자 선택 - 팝업 open State
    // setHandlePayRequestBtn(params.target.value);
  };

  const handleOnlyQrFileClose = () => {
    setIsOpenSignQrModal(false);
  };
  // 파일명 조회(계약번호/ 판매회사명/ 파일명/ 계약기간/ 진행상황/ 기타) E

  // 계약관리 - 계약서 조회/ 저장/ 수정
  useEffect(() => {
    let url =
      company_info_type === "seller"
        ? "/platformsell/select"
        : "/platformbuy/select";
    client
      .post(
        url,
        { contract_sub_id: contract_sub_id },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // access: access,
            // refresh: refresh,
          },
        },
      )
      .then((res) => {
        // let data = res.data.data[0];
        let data = res.data.data;
        if (data) {
          setContractData(data);
          setRecital(data.contract_title);
          setFrom_dtm(dayjs(data.from_dtm));
          setTo_dtm(dayjs(data.to_dtm));
          // setAmount(data.contract_price);
          // setfinalCnt(data.final_cnt);
          // setSelectedGroup(data.seller_group_id);
          setSelectedCompanyValue(data.buyer_company_name);
          setSelectedManagerValue(data.buyer_name);
          setFileInfo(data.file_info);
          // setBuyerId(data.buyer_id); 이거는 상대방이 없다. 시스템(비피앤솔루션)

          if (groups) {
            setSelectedGroup({
              value: data.buyer_group_id,
              label: data.buyer_group_name,
            });
          }
        }
        console.log("## 표준계약서", data.preamble);
        console.log("## 부속계약서", data.appendix);
        console.log("## 계약서 모달창 데이터", data);
        console.log("## 테스트용contract_sub_id", contract_sub_id);
        console.log("## data_type_name", data.data_type_name);
      });
  }, [did, contract_sub_id, company_info_type, groups]);

  useEffect(() => {
    if (contractData) {
      console.log("## PAYMENT", contractData);

      if (contractData.step_type === "301") {
        const preambles = contractData.contract_value?.hist_body;
        const appendies = contractData.contract_value?.hist_appendix;
        let preamble = "";
        let appendix = "";

        if (Array.isArray(preambles) && preambles.length > 1) {
          preamble = `[[{"buyerCompany":"${company}"}]][[{"dRange":"${preambles[0]}"}]][[{"cRange":"${preambles[1]}"}]]`;
        }
        if (Array.isArray(appendies) && appendies.length > 6) {
          appendix = `[[{"process":"${appendies[0]}"}]][[{"processDataType":"${appendies[1]}"}]][[{"processMonth":"${appendies[2]}"}]][[{"processDRate":"${appendies[3]}"}]][[{"processDCount":"${appendies[4]}"}]][[{"processCRate":"${appendies[5]}"}]][[{"processCCount":"${appendies[6]}"}]]`;
        }

        setPreamble(preamble);
        setAppendix(appendix);
        setPreambleData(GetContractValueArrayForText(preamble));
        setAppendixData(GetContractValueArrayForText(appendix));
      } else {
        setPreamble(contractData.contract_body);
        setAppendix(contractData.contract_appendix);
        setPreambleData(
          GetContractValueArrayForText(contractData.contract_body),
        );
        setAppendixData(
          GetContractValueArrayForText(contractData.contract_appendix),
        );
      }

      setPaymentValue(contractData.payment_type);
      setProcessingAtEndValue(contractData.termination_type);
      setDataProvidedValue(contractData.response_type);
      setFileTypeValue(contractData.data_type);
    }
  }, [contractData]);

  console.log("## userDID", userDID);
  console.log("## contractData.STEP_TYPE", contractData.step_type);
  console.log("## contractData.SIGN_BUYER_YN", contractData.sign_buyer_yn);
  console.log("## contractData.SIGN_SELLER_YN", contractData.sign_seller_yn);

  // // 구매자 확인 버튼 S
  const check = () => {
    console.log("## Check function called");
    console.log("## Selected Group:", selectedGroup);

    if (!Validation()) {
      setNeedAddInputOpen(true);
      return;
    }
    setIsMessageLoading(true); // 요청 전 로딩 시작
    client
      .post("/platformbuy/confirm", {
        contract_sub_id: contract_sub_id,
        buyer_group_id: selectedGroup.value,
        contract_body: ContractElementToText(preambleRef.current),
        contract_appendix: ContractElementToText(appendixRef.current),
      })
      .then((response) => {
        console.log("## Server response:", response);
        setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
        if (response.status === 200) {
          alert("확인 완료 되었습니다.", contract_sub_id);
          console.log("## contract_sub_id", contract_sub_id);
          if (onClose) onClose();
        } else {
          alert("서버에서 문제가 발생했습니다. 다시 시도해주세요.");
        }
      })
      .catch((error) => {
        // Handle error.
        console.log("## An error occurred:", error.response);
        setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
      });
  };
  // ** 구매자 확인 버튼 E

  // 파일 확장자
  // 파일 확장자 예외처리 필요없을 시 추후 코드 삭제

  const getFileAccept = (dataTypeName) => {
    const acceptedTypes = ["json", "csv", "excel", "txt", "XML", "zip", "pdf"];
    return acceptedTypes.includes(dataTypeName) ? (
      `.${dataTypeName}`
    ) : (
      // <SnackbarMessage message="선택한 파일 유형은 현재 지원되지 않습니다. 다른 유형의 파일을 선택해주세요." />
      // 나중에
      <SnackbarMessage
        message="선택한 파일 유형만 지원됩니다."
        open={open}
        handleClose={handleClose}
      />
    );
  };

  // Disabled
  const DisabledTrue = contractData.step_type >= "301";
  const DisabledGroupTrue = contractData.step_type !== "301";

  // 계약서 버튼
  const HandleBtns = ({ did, contractData, contract_sub_id }) => {
    const isSeller = company_info_type === "seller";
    const isBuyer = company_info_type === "buyer";
    const is300 = contractData.step_type === "300";
    const is301 = contractData.step_type === "301";
    const is302 = contractData.step_type === "302";
    const is303 = contractData.step_type === "303";
    const is304 = contractData.step_type === "304";
    const is305 = contractData.step_type === "305";
    const is306 = contractData.step_type === "306";
    const isRelevantUser =
      contractorID === contractData.buyer_id ||
      contractorID === contractData.seller_id;
    console.log("company_info_type:", company_info_type);

    if (!isRelevantUser) return null;

    if (isBuyer && is303) {
      return (
        <Button value={QrBtn} onClick={handleClickQrOpen}>
          서명
        </Button>
      );
    }
    // 구매자 확인
    if (isBuyer && is301) {
      return (
        <Button autoFocus onClick={check}>
          확인
        </Button>
      );
    }
    // 구매자 결제 요청
    if (isBuyer && is304) {
      return <Button onClick={handleClickPayRequestOpen}>결제 요청</Button>;
    }

    return null;
  };
  // 반려 눌렀을때 //팝업 - 비고창 - 확인/취소//확인 눌렀을 때 수정내용 + 비고내용 같이 전송하기(백단으로)//
  console.log("## contract_sub_id", contract_sub_id);

  // **** 유효성 확인/ 팝업 기능 구현/ 요청함수 담기 S
  const [inputChange, setInputChange] = useState({});
  const [message, setMessage] = useState();

  // 데이터 유효성 체크
  const onVaildate = (data) => {
    setInputChange(data);
  };

  const { label03 } = useContext(MessageContext).platform_messageList;

  const validationRules = [
    // 240705 추가 S
    { field: "selectedGroup", label: label03 },
  ];
  console.log("## preamble ", preamble);
  console.log("## appendix ", appendix);

  // 유효성 에 대한 팝업 표시
  // const Validation = () => {
  //   for (const rule of validationRules) {
  //     const fieldValue = inputChange[rule.field];
  //     if (!fieldValue) {
  //       console.log(`## 유효성 에러 ${rule.field}`, rule.label);
  //       setMessage(rule.label);
  //       return false;
  //     }
  //   }
  //   return true;
  // };
  const Validation = () => {
    // selectedGroup의 value 속성을 검사하여 유효한지 확인
    if (!selectedGroup || !selectedGroup.value) {
      console.log("## 유효성 에러: 그룹 ID 선택 필요");
      setMessage("그룹 선택");
      return false;
    }
    return true; // 유효성 검사 통과
  };

  // **** 유효성 확인/ 팝업 기능 구현/ 요청함수 담기 S
  // 서명 판매자용 뷰
  const SellerSigned = ({ contractData }) => (
    <SignedStatusView contractData={contractData} isSeller={true} />
  );
  // 서명 구매자용 뷰
  const BuyerSigned = ({ contractData }) => (
    <SignedStatusView contractData={contractData} isSeller={false} />
  );
  // 서명 QR
  const [QrBtn, setQrBtn] = useState();
  const [isOpenSignQrModal, setIsOpenSignQrModal] = useState(false);

  const handleClickQrOpen = (params) => {
    setIsOpenSignQrModal(true);
    setQrBtn(params.target.value);
  };
  const handleQrClose = () => {
    setIsOpenSignQrModal(false);
    if (onClose) {
      onClose();
    }
  };

  // ****** 240116 코드추가 S

  // 기존 contractData와 비교하여서 같으면 null, 다르면 변경값을 지정하여 반환함
  const motificateContractData = () => {
    // 변경사항 확인하기
    const _data = {
      contract_sub_id:
        contractData.contract_sub_id === contract_sub_id
          ? null
          : contract_sub_id,
      payment_type:
        contractData.payment_type === paymentValue ? null : paymentValue,
      did_buyer:
        contractData.did_buyer === userValue.value ? null : userValue.value,
      termination_type:
        contractData.termination_type === processingAtEndValue
          ? null
          : processingAtEndValue,
      to_dtm:
        contractData.to_dtm === to_dtm.format("YYYY-MM-DD")
          ? null
          : to_dtm.format("YYYY-MM-DD"),
      from_dtm:
        contractData.from_dtm === from_dtm.format("YYYY-MM-DD")
          ? null
          : from_dtm.format("YYYY-MM-DD"),
      recital:
        contractData.recital === recital ? null : recital ? null : recital,
      response_type: contractData.response_type ? null : dataProvidedValue,
      data_type: contractData.data_type ? null : fileTypeValue,
    };
    for (const key in _data) {
      if (!_data[key]) delete _data[key];
    }

    return _data;
  };

  // ****** 240116 코드추가 E

  // 스타일
  const DisabledSX = {
    "& .Mui-disabled": {
      color: "#000000DE !important",
      "-webkit-text-fill-color": "#000000DE !important",
    },
    "& .MuiRadio-root": {
      p: 0,
    },
  };
  // 선택 - 타입별
  const TypeTypographySX = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    color: "red",
    fontSize: "0.75rem",
  }));

  const GroupOptions = groups
    ? groups.map((group) => ({
        value: group.group_id, // group_id 사용
        label: group.group_name, // group_name 사용
      }))
    : [];
  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Dialog
          open={true}
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          {isMessageLoading && <CircularIndeterminate />}
          <DialogTitle
            component="h1" // 헤딩태그 지정
            variant="h6" // 변형 사이즈
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            플랫폼 구매 계약서
          </DialogTitle>
          {/* // 타이틀 */}

          <DialogContent>
            {/* {contractData.contract_sub_id && ( */}
            <Grid
              item
              container
              wrap="nowrap"
              spacing={2}
              sx={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item>
                <Typography component="h2" variant="subtitle2">
                  계약번호
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  id="contract_sub_id"
                  component="body1"
                  variant="subtitle2"
                >
                  {contractData.contract_sub_id}
                </Typography>
              </Grid>
            </Grid>
            {/* )} */}
            {/* 계약번호 */}
            <Grid
              sx={{ flexGrow: 1 }}
              item
              xs={12}
              sm
              container
              direction="column"
            >
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography component="h2" variant="subtitle2">
                    제목
                  </Typography>
                  <StyledPaper variant="outlined">
                    <InputContent
                      type="string"
                      value={recital}
                      placeholder="제목 입력"
                      inputProps={{ "aria-label": "제목 입력" }}
                      onChange={(event) => {
                        setRecital(event.target.value);
                        console.log("## 제목 입력", event.target.value);
                      }}
                      disabled={DisabledTrue}
                      // disabled={DisabledFix}
                      // helperText={PreContent[0]}
                    />
                  </StyledPaper>
                </Grid>
              </Grid>
              {/* 내용 설명 */}
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography component="h2" variant="subtitle2">
                    선택
                  </Typography>
                  <StyledPaper variant="outlined" sx={{ paddingBottom: "0" }}>
                    <Box sx={{ p: 2 }}>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "8px", width: "calc(100% + 49px)" }}
                        >
                          <Grid item>
                            <Typography component="dt" variant="subtitle2">
                              계약기간
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <DatePic
                              component="dd"
                              fromValue={from_dtm}
                              toValue={to_dtm}
                              fromInputFormat={"yyyy-MM-dd"}
                              fromMask={"____-__-__"}
                              toInputFormat={"yyyy-MM-dd"}
                              toMask={"____-__-__"}
                              fromFormat="YYYY/MM/DD"
                              toFormat="YYYY/MM/DD"
                              fromOnChange={(newValue) => setFrom_dtm(newValue)}
                              toOnChange={(newValue) => setTo_dtm(newValue)}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {/* {contractData.from_dtm} */}
                            {/* <Typography
                              sx={{ fontSize: "0.75rem", color: "red" }}
                            >
                       
                              {PreContent[1]} ~ {PreContent[2]}
                            </Typography> */}
                            {/* 기존 선택 계약기간 01" */}
                          </Grid>
                        </Grid>
                        {/* 계약기간 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography component="dt" variant="subtitle2">
                              금액
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <TextField
                              // name="amount"
                              label="금액"
                              type="number"
                              size="small"
                              value={contractData?.contract_price}
                              // onChange={handleCountChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    원
                                  </InputAdornment>
                                ),
                              }}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 계약 금액 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              // gutterBottom
                            >
                              데이터 총
                              <Typography
                                component="span"
                                // variant="h5"
                                sx={{ display: "flex", fontSize: "inherit" }}
                              >
                                제공 횟수
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* <Stack
                              gap={0.5}
                              sx={{ display: "flex", flexDirection: "row" }}
                            > */}
                            <TextField
                              name="finalCnt"
                              label="데이터 총 제공 횟수"
                              type="number"
                              // sx={{ m: 1, width: "25ch" }}
                              size="small"
                              value={contractData?.final_cnt}
                              // onChange={(e) => setAmount(e.target.value)}
                              // onChange={handleCountChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    회
                                  </InputAdornment>
                                ),
                              }}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 데이터 총 제공 횟수 */}

                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              결제
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={paymentValue}
                              onChange={(value) => setPaymentValue(value)}
                              contractTypeLabels={PAYMENT_TYPE_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {/* 기존 선택 결제 03 */}
                          </Grid>
                        </Grid>
                        {/* 결제 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "1px", mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              종료시 처리
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={processingAtEndValue}
                              onChange={(value) =>
                                setProcessingAtEndValue(value)
                              }
                              contractTypeLabels={PROCESSING_AT_END_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 종료시 처리 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "1px", mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              데이터 제공 방식
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={dataProvidedValue}
                              onChange={(value) => setDataProvidedValue(value)}
                              contractTypeLabels={DATA_PROVIDED_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 데이터 제공 방식 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              제공 형태
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* ㄴㄴ */}
                            <ContractRadioBtn
                              typeValue={fileTypeValue}
                              onChange={(value) => setFileTypeValue(value)}
                              contractTypeLabels={FILE_TYPE_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                          </Grid>
                        </Grid>
                        {/*  제공 형태 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography component="dt" variant="subtitle2">
                              그룹 선택
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <SelectForm
                              value={selectedGroup?.value || ""}
                              // onSelectChange={setSelectedGroup}
                              onSelectChange={(value) => {
                                setSelectedGroup({
                                  value,
                                  label:
                                    GroupOptions.find(
                                      (option) => option.value === value,
                                    )?.label || "그룹 선택",
                                });
                              }}
                              options={GroupOptions}
                              label="그룹 선택"
                              placeholder="그룹 선택"
                              disabled={DisabledGroupTrue}
                              sx={DisabledSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 그룹 ID */}
                      </Grid>
                      {/* </Box> IN */}
                    </Box>
                    {/* </Box> */}
                  </StyledPaper>
                  {/* 선택 paper */}
                </Grid>
                {/* 선택 인 */}
              </Grid>
              {/* 선택 */}
              {/* <FileUploadArea
                did={userDID}
                encryptKey={key}
                contractData={contractData}
              /> */}
              <FileDownloadAreaView
                // did={userDID}
                file_info={fileInfo}
                company_info_type={company_info_type}
                encryptKey={key}
                contractData={contractData}
                contract_sub_id={contract_sub_id}
                // onClick={fileListPageMove}
              />
              {/* 파일 업로드 */}
              <ContractAccordionPlatformBuy
                onVaildate={onVaildate}
                preambleRef={preambleRef}
                appendixRef={appendixRef}
                sellerCompany={contractData.seller_company_name} // 셀러 회사를 잡아주고 았고
                // buyerCompany={selectedCompanyValue}
                buyerCompany={contractData.buyer_company_name} // 바이어 회사를 잡아주고 있다.
                // sellerCompany={company}
                // buyerCompany={companyValue.label}
                preamble={preamble}
                appendix={appendix}
                disabled={DisabledTrue}
                // 240116 S
                // 전문 변경 이벤트
                // onPreambleChange={(event, newData) => {
                //   setPreambleData(newData);
                // }}
                onPreambleChange={onPreambleChange}
                // 부속 변경 이벤트
                onAppendixChange={onAppendixChange}
                // onAppendixChange={(event, newData) => {
                //   setAppendixData(newData);
                // }}
                // 240116 E
              />
              {/* 표준 계약서(부록) */}
            </Grid>
            <Box sx={{ pt: 2, pb: 2 }}>
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ justifyContent: "right", marginBottom: "8px" }}
              >
                <Grid item sx={{ alignItems: "right" }}>
                  <Typography
                    sx={{ color: "#717171" }}
                    component="h2"
                    variant="subtitle2"
                  >
                    판매자
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography
                    sx={{
                      color: "#2f3655",
                      fontWeight: "900",
                    }}
                    BorderBottomOutlined
                    component="body1"
                    variant="subtitle2"
                  >
                    {/* {contractData.seller_name} */}
                    {/* {userName} */}
                    {contractData.seller_name}
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <SellerSigned contractData={contractData} />
                </Grid>
              </Grid>
              {/* 판매자 */}
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ justifyContent: "right", marginBottom: "8px" }}
              >
                <Grid item sx={{ alignItems: "right" }}>
                  <Typography
                    sx={{ color: "#717171" }}
                    component="h2"
                    variant="subtitle2"
                  >
                    구매자
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography
                    sx={{
                      color: "#2f3655",
                      fontWeight: "900",
                    }}
                    BorderBottomOutlined
                    component="body1"
                    variant="subtitle2"
                  >
                    {/* {userValue.label} */}
                    {/* {userValue.label} */}
                    {selectedManagerValue}
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <BuyerSigned contractData={contractData} />
                </Grid>
              </Grid>
              {/* 구매자 */}
            </Box>
            {/* </Grid> */}
            {/* 콘텐츠 in */}
          </DialogContent>

          {/* 콘텐츠 */}
          <DialogActions>
            <HandleBtns
              did={userDID}
              contractData={contractData}
              contract_sub_id={contract_sub_id}
            />
            {/* <Button onClick={onClose}>닫기</Button> */}
            <Button
              onClick={() => {
                const _data = motificateContractData();
                // 계약서 전체 데이터(JSON)
                console.log("[계약서 전체", contractData);
                // 계약서의 상단 입력부분 변경된 값을 배열로 보여줌
                console.log("[계약서 공통]", _data);
                console.log(
                  "[계약서 전문 - 텍스트]",
                  ContractHtmlToText(preambleRef.current.innerHTML),
                );
                console.log("[계약서 전문 - 값]", [preambleData]);
                console.log(
                  "[계약서 부속 - 텍스트]",
                  ContractHtmlToText(appendixRef.current.innerHTML),
                );
                console.log("[계약서 부속 - 값]", appendixData);
                onClose();
              }}
            >
              닫기
            </Button>
          </DialogActions>
          {needAddInputOpen && (
            <ContractNeedAddInput
              open={needAddInputOpen}
              handleClose={handleClose}
              message={message}
              // recital={recital}
            />
          )}
        </Dialog>
        {isOpenSignQrModal && (
          <ModalContractPlatformQr
            isOpen={isOpenSignQrModal}
            handleClose={handleQrClose}
            contract_sub_id={contract_sub_id}
            handleOnlyQrFileClose={handleOnlyQrFileClose}
            company_info_type={company_info_type}
          />
        )}
      </Box>
      {/* 파일 구문 */}
      <input
        type="file"
        style={{ display: "none" }}
        multiple={false}
        ref={fileInputRef}
        // onChange={onFileChange}
      />

      {/* 담당자 선택 창 */}
      {isOpenModalHandlerSelect && (
        <ModalContractHandlerSelect
          isOpen={isOpenModalHandlerSelect}
          handleClose={handleClose}
          onFinalClickInfoChange={onSelectedCoNMgrChange}
          // onClose={handleClose}
        />
      )}
      {/* 결제 요청 창 */}
      {isOpenModalPayRequest && (
        <ModalPaymentRequestForm
          isOpen={isOpenModalPayRequest}
          handleClose={handleClose}
          onPaymentSuccess={onClose}
          onFinalClickInfoChange={onSelectedCoNMgrChange}
          contract_sub_id={contract_sub_id}
          isMessageLoading={isMessageLoading}
          // onClose={handleClose}
        />
      )}
    </>
  );
};

export { ModalPlatformBuyContract, StyledPaper };
