// import { useQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 좋아요 상태 업데이트를 위한 API 호출 함수
const updateLikeStatus = async ({ platform_main_id, userLike }) => {
  const response = await client.post(`/user/likeupdate`, {
    like_yn: userLike, // 좋아요 상태를 동적으로 설정
    platform_main_id, // 사용자 ID를 매개변수로 전달받아 요청에 포함
  });
  return response.data;
};

// 좋아요 상태 업데이트를 위한 커스텀 훅
export const useUpdateLikeQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateLikeStatus, // 이곳에서 mutationFn을 명시적으로 지정
    onSuccess: () => {
      console.log("좋아요 상태가 성공적으로 업데이트되었습니다.");
      // queryClient.invalidateQueries(["platform-detail"]);
    },
    onError: (error) => {
      console.error("좋아요 업데이트 실패:", error);
    },
  });
};

// 지정안하니 에러 남

// // 좋아요 상태 업데이트를 위한 커스텀 훅
// export const useUpdateLikeQuery = () => {
//   return useMutation(updateLikeStatus, {
//     onSuccess: () => {
//       // 좋아요 업데이트 성공 시 수행할 작업, 예를 들어 쿼리 무효화
//       console.log("좋아요 상태가 성공적으로 업데이트되었습니다.");
//     },
//     onError: (error) => {
//       // 에러 처리 로직
//       console.error("좋아요 업데이트 실패:", error);
//     },
//   });
// };
