import { useState, useEffect } from "react";
import client from "assets/js/commonApi";
import Axios from "axios";

export default function useLike() {
  const [data, setData] = useState([]);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 10000,
    handleClose: () => setSnackBar((pre) => ({ ...pre, open: false })),
  });
  const showSnackBar = (message) =>
    setSnackBar((pre) => ({
      ...pre,
      open: true,
      message: message,
    }));

  const getData = async () => {
    client
      .post("/user/likelist")
      .then((res) => {
        console.log("[useLike]", "[data]", res.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.error("[useLike]", "[data err]", err);
      });
  };

  const onAddClick = (checkList) => {
    console.log("[add]", checkList);
    console.log("[add]", data);

    if (checkList.length === 0) {
      showSnackBar("장바구니에 추가할 플렛폼 데이터를 선택하세요");
      return;
    }

    if (
      data.find((item) => {
        return (
          new Set(checkList).has(item.platform_main_id) &&
          item.user_cart === "Y"
        );
      })
    ) {
      showSnackBar("이미 장바구니에 담겨져 있습니다.");
      return;
    }

    if (
      data.find((item) => {
        return new Set(checkList).has(item.user_buy) && item.user_cart === "Y";
      })
    ) {
      showSnackBar("이미 구매요청 및 계약완료된 상품입니다.");
      return;
    }

    Axios.all(
      checkList.map((platform_main_id) => {
        return client.post("/user/cartupdate", {
          platform_main_id,
        });
      }),
    )
      .then((response) => {
        showSnackBar("장바구니에 담겼습니다.");
        console.log("[cartLikes ask]", response);
        getData();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          showSnackBar("같은 회사 소속입니다.");
          return;
        }
        showSnackBar(error.response.data.msg ?? "");
        console.log("[cartLikes ask error]", error.response);
      });
  };

  const onRemoveClick = (checkList) => {
    if (checkList.length === 0) {
      showSnackBar("찜 제외할 플렛폼 데이터를 선택하세요");

      return;
    }

    Axios.all(
      checkList.map((platform_main_id) => {
        return client.post("/user/likeupdate", {
          platform_main_id,
          like_yn: "N",
        });
      }),
    )
      .then((response) => {
        showSnackBar("찜에서 제외하였습니다.");
        console.log("[cartLikes ask]", response);
        getData();
      })
      .catch((error) => {
        setSnackBar((pre) => ({
          ...pre,
          open: true,
          message: error.response.data.msg ?? "",
        }));
        console.log("[cartLikes ask error]", error.response);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return { data, snackBar, onAddClick, onRemoveClick };
}
