import * as React from "react";
import { Box, Tab, Grid } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import TFBottomCard from "./viewContents/FTManagementView/TFBottomCard";

import SaveAltIcon from "@mui/icons-material/SaveAlt"; // 저장
import TaskIcon from "@mui/icons-material/Task"; // 등록
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined"; // 구매자 확인
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"; // 서명 확인
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined"; // 데이터 등록
import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined"; //한달이내

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TFBottomCardContent = [
    {
      state: "결제완료",
      title: "비피앤솔루션 커피물류 데이터 계약",
      date: "2023.06.19 구매",
      // description: "데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
      quickbtn: "3,000FT 계약 바로가기",
      // image: mainbottomicon_01,
      // imageLabel: "총 계약 건수 아이콘",
    },
    {
      state: "결제완료",
      title: "2체인트리 용접 데이터 계약",
      date: "2023.06.20 구매",
      // description: "11데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
      quickbtn: "3,000FT 계약 바로가기",
      // image: mainbottomicon_01,
      // imageLabel: "총 계약 건수 아이콘",
    },
    {
      state: "결제완료",
      title: "3체인트리 용접 데이터 계약",
      date: "2023.06.30 구매",
      // description: "22데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
      quickbtn: "3,000FT 계약 바로가기",
      // image: mainbottomicon_01,
      // imageLabel: "총 계약 건수 아이콘",
    },
  ];

  const TabPanelSx = styled(TabPanel)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.h5,
    // padding: theme.spacing(1),
    // textAlign: "center",
    // display: "flex",
    // flexDirection: "column",
    // backgroundColor: "#EFF2F4",
    // position: "relative",
    // height: "100%",
    padding: "0",
    marginTop: "24px",
    // color: theme.palette.text.secondary,
    // background: "#29314a",
    // color: "#fff",
  }));

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="최근 FT 사용 내역" value="1" />
            <Tab label="최근 FT 수령 내역" value="2" />
            <Tab label="최근 NFT 수령 내역" value="3" />
          </TabList>
        </Box>
        <TabPanelSx value="1">
          {/* <Grid container spacing={4}> */}
          {/* <Box sx={{ mb: 2 }}> */}
          Item One
          {TFBottomCardContent.map((card) => (
            <TFBottomCard key={card.title} card={card} />
          ))}
          {/* </Box> */}
          {/* </Grid> */}
          {/* <TFBottomCard /> */}
        </TabPanelSx>
        <TabPanelSx value="2">
          Item Two
          {TFBottomCardContent.map((card) => (
            <TFBottomCard key={card.title} card={card} />
          ))}
        </TabPanelSx>
        <TabPanelSx value="3">
          Item Three
          {TFBottomCardContent.map((card) => (
            <TFBottomCard key={card.title} card={card} />
          ))}
        </TabPanelSx>
      </TabContext>
    </Box>
  );
}
