import { Button, Box } from "@mui/material";

export default function SearchBarCart({ onRemoveClick, onClick }) {
  // setTerminateBtn
  return (
    <Box
      sx={{
        display: "flex ",
        alignItems: "center",
        flexDirection: "row-reverse",
        mb: 1,
      }}
    >
      <Button
        onClick={onClick}
        variant="contained"
        sx={{
          height: "41px",
          boxShadow: "none",
          backgroundColor: "#29304a",
          marginLeft: 1,
        }}
      >
        선택구매요청
      </Button>
      <Button
        onClick={onRemoveClick}
        variant="contained"
        sx={{
          height: "41px",
          boxShadow: "none",
          backgroundColor: "#29304a",
          marginLeft: 1,
        }}
      >
        장바구니제외
      </Button>
    </Box>
  );
}
