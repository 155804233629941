import { useQuery } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 플랫폼 전체 데이터 조회
const fetchPlatformTotalData = () => {
  return client.get(`/platformitem/total`);
};

// 플랫폼 전체 데이터에 대한 쿼리 훅
export const useTotalQuery = () => {
  return useQuery({
    queryKey: ["platform-total-data"],
    queryFn: fetchPlatformTotalData,
    select: (result) => result.data.data,
    // {
    //   // 필요한 데이터만 선택 반환
    //   return {
    //     totalContracts: result.data.data.contract_cnt,
    //     totalDataSize: result.data.data.upload_data_size,
    //     totalFT: result.data.data.ft_amount,
    //     totalNFT: result.data.data.nft_amount,
    //   };
    // }
    staleTime: 300000, // 5분
  });
};
