import { Button, Box } from "@mui/material";
// import DidRadio from "./radio/DidRadio";

export default function BarStorage({
  onNewFolderClick,
  onNewDataClick,
  onMoveParentFolderClick,
}) {
  // setTerminateBtn
  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "row-reverse" }}>
      <Box sx={{ display: "flex ", alignItems: "center", mb: 1 }}>
        <Button
          onClick={onMoveParentFolderClick}
          variant="contained"
          sx={{
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            // borderRadius: "2px"
          }}
        >
          상위이동
        </Button>
        <Button
          onClick={onNewFolderClick}
          variant="contained"
          sx={{
            marginLeft: '15px',
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            // borderRadius: "2px"
          }}
        >
          새 폴더
        </Button>
        <Button
          onClick={onNewDataClick}
          variant="contained"
          sx={{
            marginLeft: '15px',
            height: "41px",
            boxShadow: "none",
            backgroundColor: "#29304a",
            // borderRadius: "2px"
          }}
        >
          업로드
        </Button>
      </Box>
    </Box>
  );
}
