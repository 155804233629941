import * as React from "react";
import PropTypes from "prop-types";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";

function MainTopCard(props) {
  const { card } = props;

  const MainTopCardSx = {
    // "& .MuiCardContent-root": {
    //     padding: "0 !important",
    //   },
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
    // "& .MuiCardContent-root": {
    //   padding: "0 !important",
    // },
    // "& .MuiCardContent-root:last-child": {
    //   paddingBottom: "0 !important",
    // },

    // "& .MuiDataGrid-cell:nth-child(7)": {
    //     "& .MuiDataGrid-cellContent": {
    //       // cursor: "pointer !important",
    //       // color: "red",
    //       // border-bottom: 1px solid #000,
    //       borderBottom: "1px solid #000",
    //     },
    //   },
    // "& .MuiToolbar-root": {
    //   // flexGrow: 1,
    //   "& .MuiTypography-root": {
    //     // display: "flex",
    //     // flex: "4",
    //     // height: "90px",
    //     // flexGrow: 1,
    //   },
    // },

    // "& .MuiTypography-root": {

    // },
  };

  return (
    <Grid item xs={12} md={2}>
      {/* <CardActionArea
      //   component="a"
      // href="#"
      > */}
      {/* <Card sx={{ display: "flex" }}> */}
      <Card>
        <Box
          sx={{
            display: "flex",
            //   alignItems: "flex-start",
            flexDirection: "column",
            p: 2,
            //   m: 2,
            //   bgcolor: "background.paper",
            //   borderRadius: 1,
          }}
        >
          {/* <Box
            sx={
              {
                // display: "flex",
                // alignItems: "flex-start",
                // m: 2,
              }
            }
          > */}
          <CardMedia
            component="img"
            sx={{
              width: 50,
              height: 50,
              // width: 100,
              display: { xs: "none", sm: "block" },
            }}
            image={card.image}
            // src={card.src}
            alt={card.imageLabel}
          />
          {/* </Box> */}

          {/* <CardContent sx={{ flex: 1 }}> */}
          <CardContent
            // sx={MainTopCardSx}
            sx={{
              p: 0,
              pb: "0 !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                mt: 1,
                p: 0,
                // padding: "0 !impprtant",
                //   m: 2,
              }}
            >
              <Typography sx={{ fontSize: "12px" }} component="p" variant="h5">
                {card.title}
              </Typography>
              <Typography
                sx={{
                  // fontSize: "22px",
                  // color: "#468ECD",
                  // fontWeight: 500,
                  fontSize: "23px",
                  color: "#468ECD",
                  fontWeight: 600,
                  fontFamily: "sans-serif",
                  mt: 0.5,
                }}
                variant="subtitle1"
                //   color="text.secondary"
              >
                {card.date}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#468ECD",
                  lineHeight: "inherit",
                  mb: 0.5,
                }}
                variant="subtitle1"
                //   paragraph
              >
                {card.description}
              </Typography>
              {/* <Typography variant="subtitle1" color="primary">
              Continue reading...
            </Typography> */}
            </Box>
          </CardContent>
        </Box>
      </Card>
      {/* </CardActionArea> */}
    </Grid>
  );
}

MainTopCard.propTypes = {
  card: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    // src: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainTopCard;
