import React, { useEffect, useState, useContext, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  // MuiGrid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  // Divider,
} from "@mui/material";
import Draggable from "react-draggable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import SelectCompany from "components/selectBox/SelectCompany";
import InputBasic from "components/inputBox/InputBasic";

// import { AppContext } from "App";
import { MessageContext } from "assets/js/syntax";

import { GetContractValueObjectForText } from "assets/js/contractUtil"; // 240521 과장님 추가

// 텍스트 마디 간격 스타일
// const MuiGrid = styled(Grid)(({ theme }) => ({
//   width: "100%",
//   ...theme.typography.body2,
//   '& [role="separator"]': {
//     // margin: theme.spacing(0, 2),
//     marginTop: theme.spacing(8),
//   },
// }));

const GridSx = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "16px",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
    // marginTop: theme.spacing(8),
  },
}));

const BoxSx = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "16px",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
    // marginTop: theme.spacing(8),
  },
}));

// Item
const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  flexDirection: "row",
  // color: theme.palette.text.secondary,
}));

// 드래그 컴포넌트
function PaperComponent(props) {
  return (
    <Draggable
      handle="#MuiAccordionSummary-root"
      cancel={'[class*="MuiAccordionDetails-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ContractAccordionPlatformSell({
  // onVaildate = () => null,
  preamble,
  preambleRef,
  appendix,
  appendixRef,
  sellerCompany,
  buyerCompany,
  buyerLabel,
  disabled,
  label,
  onPreambleChange, // 전문 Change 이벤트
  onAppendixChange, // 부속 Change 이벤트
  onVaildate,
  // company,
  // allValue,
  // allOnChange,
  // onChange,
  // inputValue,
  // onInputChange,
  // options,
}) {
  // 스타일 디테일

  const accordionSx = {
    "& .MuiAccordionSummary-root": {
      //   boxShadow: 0,
      //   padding: "0",
      //   margin: "0",
    },

    "& .MuiButtonBase-root": {
      boxShadow: 0,
      //   padding: "0",
      margin: "0",
      minHeight: "0",
    },
    "& .MuiAccordionDetails-root": {
      minHeight: "280px",
      height: "280px",
      overflow: "auto",
    },
  };

  // 240116 S
  // const [initPreamble, setInitPreamble] = useState(null);
  // const [initAppendix, setInitAppendix] = useState(null);
  const [initPreamble, setInitPreamble] = useState({}); // 기존 전문 데이터(JSON 형태)
  const [initAppendix, setInitAppendix] = useState({}); // 기존 부속 데이터(JSON 형태)
  // 240116 E

  // 여기 부분은 변수를 꼭 수정해야함 warn
  const [sellCompany, setSellCompany] = useState();
  const [dRange, setDRange] = useState();
  const [cRange, setCRange] = useState();
  const [month, setMonth] = useState();
  const [rDay, setRDay] = useState();
  const [vDay, setVDay] = useState();
  const [sDay, setSDay] = useState();
  const [s2Day, setS2Day] = useState();

  const [process, setProcess] = useState();
  const [processDataType, setProcessDataType] = useState();
  const [processMonth, setProcessMonth] = useState();
  const [processDRate, setProcessDRate] = useState();
  const [processDCount, setProcessDCount] = useState();
  const [processCRate, setProcessCRate] = useState();
  const [processCCount, setProcessCCount] = useState();

  // const memoizedOnVaildate = useCallback(onVaildate, [onVaildate]);
  // const memoizedOnVaildate = useCallback(
  //   (data) => {
  //     // onVaildate(data);
  //   },
  //   [onVaildate],
  // );

  useEffect(() => {
    // 바인딩 할 때 구문
    if (preamble) {
      const _preamble = GetContractValueObjectForText(preamble);
      console.log('[contractAccordionPlatformSell] preamble', preamble);

      setSellCompany(_preamble.sellCompany);
      setCRange(_preamble.cRange);
      setDRange(_preamble.dRange);
      setMonth(_preamble.month);
      setRDay(_preamble.rDay);
      setVDay(_preamble.vDay);
      setSDay(_preamble.sDay);
      setS2Day(_preamble.s2Day);

      // 화면 렌더링 줄이기 위해 변수 추가
      setInitPreamble(GetContractValueObjectForText(preamble));

      console.log("[REF P sellCompany]", sellCompany);
      console.log("[REF P dRange]", dRange);
      console.log("[REF P cRange]", cRange);
      console.log("[REF P sDay]", sDay);
      console.log("[REF P vDay]", vDay);
      console.log("[REF P rDay]", rDay);
    }

    if (appendix) {
      const _appendix = GetContractValueObjectForText(appendix);
      setProcess(_appendix.process);
      setProcessDataType(_appendix.processDataType);
      setProcessMonth(_appendix.processMonth);
      setProcessDRate(_appendix.processDRate);
      setProcessDCount(_appendix.processDCount);
      setProcessCRate(_appendix.processCRate);
      setProcessCCount(_appendix.processCCount);

      setInitAppendix(_appendix);

      console.log("[REF X process]", process);
      console.log("[REF X processDataType]", processDataType);
      console.log("[REF X processMonth]", processMonth);
      console.log("[REF X processDRate]", processDRate);
      console.log("[REF X processDCount]", processDCount);
      console.log("[REF X processCRate]", processCRate);
      console.log("[REF X processCCount]", processCCount);
    }
    // }, [preamble, appendix, company]);
  }, [preamble, appendix]);
  // 계약서 라벨

  // const onVaildateCallback = useCallback(() => {
  //   onVaildate({
  //     dataType,
  //     day,
  //     dRange,
  //     cRange,
  //     sDay,
  //     count,
  //     year,
  //     vDay,
  //     rDay,
  //     court,
  //     process,
  //     processDataType,
  //     processMonth,
  //     processDRate,
  //     processDCount,
  //     processCRate,
  //     processCCount,
  //   });
  // }, []);

  useEffect(() => {
    if (initAppendix && initPreamble) {
      console.log("initRendering", initAppendix, initPreamble);
      onVaildate({
        ...initAppendix,
        ...initPreamble,
      });
    }
  }, [initAppendix, initPreamble]);
  // if (initAppendix && initPreamble) {
  //   console.log("initRendering", initAppendix, initPreamble);
  //   onVaildate({
  //     ...initAppendix,
  //     ...initPreamble,
  //   });
  // }

  // const {
  //   // label01,
  //   // label02,
  //   // label03,
  //   // label04,
  //   label05,
  //   label06,
  //   label07,
  //   label08,
  //   label09,
  //   label10,
  //   label11,
  //   label12,
  //   label13,
  //   label14,
  //   label15,
  //   label16,
  //   label17,
  //   label18,
  //   label19,
  //   label20,
  //   label21,
  // } = useContext(AppContext);
  const { platform_messageList } = useContext(MessageContext);
  // 예시 추후 삭제 2번 - 여기서부터 표준계약서(부속)
  // 총 17개
  let preContractContent = [
    "기존 데이터 명 입력 00", // 00
    "기존 데이터 명 입력 01", // 01
    "기존 데이터 명 입력 02", // 02
    "기존 데이터 명 입력 03", // 03
    "기존 데이터 명 입력 04", // 04
    "기존 데이터 명 입력 05", // 05
    "기존 데이터 명 입력 06", // 06
    "기존 데이터 명 입력 07", // 07
    "기존 데이터 명 입력 08", // 08
    "기존 데이터 명 입력 09", // 09
    "기존 데이터 명 입력 10", // 10
    "기존 데이터 명 입력 11", // 11
    "기존 데이터 명 입력 12", // 12
    "기존 데이터 명 입력 13", // 13
    "기존 데이터 명 입력 14", // 14
    "기존 데이터 명 입력 15", // 15
    "기존 데이터 명 입력 16", // 16
  ];

  // ****** 추가 240116 S
  // Method
  // 변경된 데이터의 배열 가져오기
  // 사용자가 입력할 때 이벤트 호출
  // 전문 변경 이벤트
  const setOnPreambleChangeEvent = (event, newData) => {
    // 현재 입력된 데이터
    const currentData = {
      sellerCompany,
      cRange,
      dRange,
      month,
      rDay,
      vDay,
      sDay,
      s2Day,
    };
    // 새로 입력된 데이터
    newData = { ...currentData, ...newData };

    const preambleData = [
      newData.sellerCompany,
      newData.dRange,
      newData.cRange,
      newData.month,
      newData.rDay,
      newData.vDay,
      newData.sDay,
      newData.s2Day,
    ];

    // 1) 삭제하기
    // const formattedData = preambleData.map((value) => {
    //   // 따옴표로 감싸진 문자열은 그대로 두고, 숫자 등은 그대로 반환
    //   return typeof value === "string" ? `'${value}'` : value;
    // });
    // const formattedString = `[${formattedData.join(",")}]`;
    // onPreambleChange(event, formattedString);

    //

    // // 2) 데이터 불필요하게 변화요구되어 수정한것
    // const formattedData = preambleData.map((value) => {
    //   // 따옴표가 있는 경우 제거
    //   return typeof value === "string" ? value.replace(/'/g, "") : value;
    // });
    // const formattedDataEmpty = formattedData.map((value) =>
    //   !value ? "" : value,
    // );
    // // 백단에서 빈문자열이 들어갔을때 처리해줘야 한다. 왜냐면 넘겨주는 배열에서 쌍따옴표를 제외시켜 빈값을 주기가 어렵다.
    // // 그래서 데이터중에 하나라도 입력이 안되어 있으면 Null값으로 반환한다.
    // const formattedString = `[${formattedDataEmpty.join(",")}]`;
    // onPreambleChange(event, formattedString);

    // 3) 데이터 불필요하게 변화요구되어 수정한것

    // 코드 수정 240603 _ 과장님이 수정해줌
    // const formattedData = preambleData.map(
    //   (value) => `"${value ? value : ""}"`,
    // );
    // const formattedString = `[${formattedData.join(",")}]`;

    // onPreambleChange(event, formattedString);
    const formattedData = preambleData.map((value) => `${value ? value : ""}`);
    // const formattedString = `[${formattedData.join(",")}]`;

    onPreambleChange(event, formattedData);
  };

  // 부속 변경 이벤트
  const setOnAppendixChangeEvent = (event, newData) => {
    const currentData = {
      process,
      processDataType,
      processMonth,
      processDRate,
      processDCount,
      processCRate,
      processCCount,
    };

    newData = { ...currentData, ...newData };

    const appendixData = [
      newData.process,
      newData.processDataType,
      newData.processMonth,
      newData.processDRate,
      newData.processDCount,
      newData.processCRate,
      newData.processCCount,
    ];

    // // 2) 데이터 불필요하게 변화요구되어 수정한것
    // const formattedData = appendixData.map((value) => {
    //   // 따옴표가 있는 경우 제거
    //   return typeof value === "string" ? value.replace(/'/g, "") : value;
    // });
    // const formattedDataEmpty = formattedData.map((value) =>
    //   !value ? "" : value,
    // );
    // const formattedString = `[${formattedDataEmpty.join(",")}]`;
    // onAppendixChange(event, formattedString);
    // const formattedData = appendixData.map(
    //   (value) => `"${value ? value : ""}"`,
    // );
    // const formattedString = `[${formattedData.join(",")}]`;

    // onAppendixChange(event, formattedString);
    const formattedData = appendixData.map((value) => `${value ? value : ""}`);
    // const formattedString = `[${formattedData.join(",")}]`;

    onAppendixChange(event, formattedData);
  };
  // ****** 추가 240116 E
  const countValue = (event) => {
    return event.target.value === ""
      ? 0
      : Math.max(Number(event.target.value), 0);
  };

  return (
    <>
      <div ref={preambleRef}>
        <Accordion
          // value={allValue}
          // onChange={allOnChange}
          sx={accordionSx}
          PaperComponent={PaperComponent}
          // sx={{ boxShadow: 0, padding: "0", margin: "0" }}
        >
          <AccordionSummary
            //   sx={accordionSx}
            //   sx={{ padding: "0", margin: "0", minHeight: "0" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography component="h2" variant="subtitle2">
                  플랫폼운영자와 데이터제공자 간의 표준계약서
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {/* <StyledPaper> */}
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제1조(목적)
                <br />본 계약은 (주)비피앤솔루션 (이하 '플랫폼운영자'라고
                한다)와 플랫폼 운영자가 운영하는 플랫폼'U-STC' (이하
                '제조데이터보호거래플랫폼'라고 한다)에서 판매회원으로 가입하여
                데이터상품 등의 전자상거래 관려 판매 서비스 및 기타 서비스(이하
                '서비스')를 이용하는{" "}
                <InputBasic
                  id="seller_company"
                  // label={"데이터 명 입력"}
                  label={"판매회사"}
                  value={sellerCompany}
                  disabled={true}
                />{" "}
                (이하 '데이터제공자'라고 한다) 간의 권리와 의무, 데이터
                활용형태에 관한 정의, 그 밖에 필요한 사항을 규정하는 것을
                목적으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제2조{"("}정의{")"}
                <br />본 계약에서 사용하는 용어의 뜻은 다음과 같다.
                <br />
                &#9312;“데이터”란 데이터베이스, 전자화된 파일 등 광(光) 또는
                전자적 방식으로 처리된 자료 또는 정보를 말한다.
                <br />
                &#9313;“데이터상품”이란 데이터제공자와 데이터이용자 사이의
                거래에 제공되는 데이터, API, 이미지, 동영상 등 일체의 데이터를
                말한다.
                <br />
                &#9314;“데이터제공자”는 플랫폼운영자가 운영하는
                제조데이터보호거래플랫폼에 판매회원으로 가입하 여
                제조데이터보호거래플랫폼을 이용하여 데이터 및 관련 부가서비스를
                판매, 위탁 및 제공하는 사 업자를 말한다.
                <br />
                &#9315;“데이터이용자”는 플랫폼운영자가 운영하는
                제조데이터보호거래플랫폼에 구매회원으로 가입하 여
                데이터제공자로부터 데이터를 구매하고 제공받는 자를 말한다.
                <br />
                &#9316;"데이터중개"란 제조데이터보호거래플랫폼을 이용하여거래
                당사자 간의 데이터판매를 알선, 중 개하는 행위를 말한다.
                <br />
                &#9317;“제조데이터보호거래플랫폼”이란 컴퓨터 등과 정보통신설비를
                이용하여 데이터 및 재화 등을 거 래할 수 있도록 설정된 가상의
                영업장을 말한다.
                <br />
                &#9318;“제공데이터”란 본 계약에 따라 데이터제공자가
                플랫폼운영자에게 판매를 통한 활용을 위 해 제공하는 데이터로서,
                부속문서에 기재된 것을 말한다.
                <br />
                &#9319;“파생데이터”란 데이터이용자가 제공데이터를 가공, 분석,
                편집 등을 통하여 새로 또는 2차적으로 생성한 데이터 등을 말한다.
                <br />
                &#9320;“결합데이터”란 데이터이용자가 제공데이터에 파생데이터 및
                기존데이터를 결합하여 새로 또는 2차 적으로 생성한 데이터 등을
                말한다.
                <br />
                &#9321;“이용권한”이란 제공데이터, 또는 파생데이터 또는
                결합데이터를 이용할 수 있는 권한을 말 한다.
                <br />
                &#9322;“지식재산권”이란 특허, 실용신안, 디자인, 상표, 컴퓨터
                프로그램을 포함한 저작권, 데 이터베이스권, 엉업비밀 등을 포함한
                법상 보호 가능한 일체의 무형재산에 대한 권리로서 출원, 신청 및
                등록을 모두 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제3조(계약서의 명시)
                <br />
                &#9312;플랫폼운영자는 본 계약서의 내용을 데이터제공자가 알 수
                있도록 제조데이터보호거래플랫폼 홈 페이지에 게시하거나 그 밖에
                데이터제공자가 쉽게 확인할 수 있는 방법으로 알려야 한다.
                <br />
                &#9313;플랫폼운영자는 데이터제공자가 본 계약서의 내용에 관하여
                질의 및 응답할 수 있도록 적절한 절 차를 마련하여야 한다.
                <br />
                &#9314;제공데이터, 파생데이터 및 결합데이터의 이용은
                데이터이용자와 데이터제공자 간의 제약사 상을 따라야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제4조(계약의 해석)
                <br />본 계약서에서 정하지 아니한 사항과 계약서의 해석에
                관하여는 ‘약관의 규제에 관한 법률’, ‘전자상거래 등에서의
                소비자보호에 관한 법률’, ‘정보통신망 이용촉진 및 정보보호 등에
                관한 법률’, ‘개인정보보호법’, ‘신용정보의 이용 및 보호에 관한
                법률’, ‘저작권법’ 등 관계 법령 및 상관습에 따른다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제5조(제공데이터의 이용허락)
                <br />
                &#9312;데이터제공자는 데이터이용자에 대하여, 제공데이터를 본
                계약의 유효기간 내에서 본 계약 목적의 범위 내에서만 이용하는
                것을 허락한다.
                <br />
                &#9313;데이터이용자는 본 계약에서 명시적으로 규정된 것을
                제외하고는 제공데이터에 대하여 내용 의 정정, 추가 또는 삭제를
                행할 수 있는 권한이 없다.
                <br />
                &#9314;데이터이용자는 데이터제공자의 서면에 의하여 사전 승낙이
                없는 한 본 계약 목적 이외의 목적으로 제공데이터를가공, 분석,
                편집, 통합 기타의 이용을 할 수 없으며, 제공데이터를 제3자
                (데이터이용자가 법인인 경우 그계열사, 관련회사도 제3자에
                포함)에게 공개, 제공, 누설하여서는 아니된다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제6조(파생데이터)
                <br />
                &#9312;데이터이용자는 파생데이터의 이용권한을 가지며,
                데이터이용자는 데이터제공자에 대하여
                <InputBasic
                  id="d_range"
                  type="number"
                  min={0}
                  // label={"범위 입력"}
                  label={platform_messageList.label05}
                  value={dRange}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      dRange: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange: newCountValue,
                      month,
                      rDay,
                      vDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setDRange(newCountValue);
                  }}
                  disabled={disabled}
                />
                의 범위에서 [ 의 목적의 범위 에서] 파생데이터를 무상[유상]으로
                이용하는 것을 허락한다.
                <br />
                &#9313;데이터이용자가 파생데이터를 이용함에 따라 발생하는 발명,
                고안, 창작 및 영업비밀 등에 대한 지식재산권은 데이터이용자에게
                귀속한다. 단, 데이터이용자는 데이터제공자에 대하여 해당
                지식재산권에 대한 무상[유상]의 실시 허락 을 한다.
                <br />
                &#9314;제1항 및 제2항의 이용 허락의 조건에 대한 구체적 내용은
                별도로 협의하여 부속문서에 포함 한다.
                <br />
                &#9315;데이터이용자가 파생데이터를 이용하여 실시한 사업 또는
                서비스에 따라 매출을 얻을 때는, 데이터이용자가 얻은 매출액의
                일부분을 데이터제공자에게 지불한다.지급조건 및 비율에 대해서는
                데이터제공자 및 데이터이용자 사이의 별도의 협의하에 부속문서에
                포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제7조(결합데이터)
                <br />
                &#9312;데이터이용자는 결합데이터의 이용권한을 가지며,
                데이터이용자는 데이터제공자에 대하여
                <InputBasic
                  id="c_range"
                  type="number"
                  min={0}
                  // label={"범위 입력"}
                  label={platform_messageList.label06}
                  value={cRange}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      cRange: newCountValue,
                    });
                    onVaildate({
                      cRange: newCountValue,
                      dRange,
                      month,
                      rDay,
                      vDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setCRange(newCountValue);
                  }}
                  disabled={disabled}
                />
                의 범위에서 [ 의 목적의 범위 에서] 결합데이터를 무상[유상]으로
                이용하는 것을 허락한다.
                <br />
                &#9313;데이터이용자가 결합데이터를 이용함에 따라 발생하는 발명,
                고안, 창작 및 영업비밀 등에 대한 지식재산권은 데이터이용자에게
                귀속한다. 단, 데이터이용자는 데이터제공자에 대하여 해당
                지식재산권에 대한 무상[유상]의 실시 허락 을 한다. <br />
                &#9314;제1항 및 제2항의 이용 허락의 조건에 대한 구체적 내용은
                별도로 협의하여 부속문서에 포함 한다.
                <br />
                &#9315;데이터이용자가 결합데이터를 이용하여 실시한 사업 또는
                서비스에 따라 매출이 얻을때는 매출액의 일부분을 갑에게
                지급하여야 하며 지급 조건 및 비율에 대해서는 별도로 협의하여
                부속문서에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제8조(플랫폼운영자가 제공하는 서비스의 종류)
                <br />
                플랫폼운영자가 제공하는 서비스는 다음과 같다.
                <br />
                &#9312;전자상거래 플랫폼 개발 및 운영 서비스 : 판매 관련 지원
                서비스, 구매 관련 지원서비스, 매매계약체결 관련 지원 서비스,
                상품 정보 검색 서비스, 기타 전자상거래 플랫폼 관련 서비스를
                말한다.
                <br />
                &#9313;광고 및 프로모션 서비스 : 플랫폼운영자가 데이터제공자의
                판매활동을 지원하기 위해 제공하는 상품 광고 및 프로모션 서비스를
                말한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제9조(서비스의 성격과 책임)
                <br />
                &#9312;플랫폼운영자가 제공하는 서비스는 데이터판매중개서비스로서
                데이터제공자가 재화 등을 거래할 수 있도록
                제조데이터보호거래플랫폼의 이용을 허락하거나 데이터판매를 알선,
                중개하는 것을 목적으로 하며, 플랫폼운영자는 상품 또는 용역의
                거래와 관련하여 데이터제공자 또는 데이터이용자를 대리하지
                아니한다.
                <br />
                &#9313;데이터제공자와 데이터이용자 사이에 성립된 거래 및
                데이터제공자가 제공하고 등록한 정보에 대해서는 데이터제공자가
                직접적인 책임을 부담하여야 한다. 다만, 판매를 위탁받은
                데이터상품에 대해서는 플랫폼운영자가 데이터제공자의 지위를
                가진다.
                <br />
                &#9314;플랫폼운영자는 플랫폼운영자가 제공하는 시스템을 통하여
                이루어지는 데이터제공자와 데이터이용자 간의 거래와 관련하여
                판매의사 또는 구매의사의 존부 및 진정성, 등록상품의 품질,
                완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성,
                데이터제공자 또는 데이터이용자가 입력하는 정보 및 그 정보를
                통하여 링크된 URL에 게재된 자료의 진실성, 적법성 등 일체에
                대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 관련
                데이터제공자 및 데이터이용자가 전적으로 부담한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제10조(서비스 이용계약)
                <br />
                &#9312;서비스 이용계약은 데이터제공자가 플랫폼운영자가 제공하는
                서비스를 이용하고자 하는 청약(신청)을 하고 플랫폼운영자가 이를
                승낙함으로써 성립한다. 플랫폼운영자는 이용승낙의 의사표시를 해당
                서비스화면에 게시하거나 이메일 또는 기타 방법으로 통지한다.
                <br />
                &#9313;서비스를 이용하고자 하는 데이터제공자는 플랫폼운영자가
                정한 회원 가입 신청양식에 따라 필요한 사항을 기재하여야 한다.
                <br />
                &#9314;플랫폼운영자는 원칙적으로 데이터제공자의 서비스
                이용신청을 승낙하여야 하나, 다음의 각호 사유에 해당하는 경우에는
                승낙을 거부하거나 유보할 수 있다.
                <BoxSx>
                  1. 이미 가입된 회원과 이름, 주민등록번호(또는
                  사업자등록번호)가 동일한 경우
                </BoxSx>
                <BoxSx>
                  2. 데이터제공자의 귀책사유로 인하여 이용계약이 해지된 날로부터{" "}
                  <InputBasic
                    id="month"
                    // label={"데이터 명 입력"}
                    label={platform_messageList.label07}
                    value={month}
                    // helperText="**이전 내용입니다."
                    // helperText={preContractContent[0]}
                    onChange={(event) => {
                      // 전문 변경 이벤트 호출
                      setOnPreambleChangeEvent(event, {
                        month: event.target.value,
                      });
                      onVaildate({
                        cRange,
                        dRange,
                        month: event.target.value,
                        rDay,
                        vDay,
                        sDay,
                        s2Day,
                        process,
                        processDataType,
                        processMonth,
                        processDRate,
                        processDCount,
                        processCRate,
                        processCCount,
                      });
                      setMonth(event.target.value);
                    }}
                    disabled={disabled}
                  />{" "}
                  개월 이내 재이용신청을 하는 경우
                </BoxSx>
                <BoxSx>
                  3. 플랫폼운영자로부터 판매중지 조치 등을 받은 회원이 그
                  조치기간 중에 이용계약을 임의해지하고 재이용신청을 하는 경우
                </BoxSx>
                <BoxSx>
                  4. 허위의 정보를 기재하거나 플랫폼운영자가 제시하는 내용을
                  기재하지 않은 경우
                </BoxSx>
                <BoxSx>
                  5. 플랫폼운영자의 서비스 설비능력이 부족하거나 기술적으로
                  서비스를 제공하기 어려운 경우
                </BoxSx>
                <BoxSx>
                  6. 그 외 본 계약에 위배되거나 위법 내지 부당한 이용신청임이
                  객관적으로 인정되는 경우
                </BoxSx>
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제11조(데이터제공자의 판매행위)
                <br />
                &#9312;데이터제공자는 데이터상품정보와 거래조건에 관한 내용을
                ‘전자상거래 등에서의 소비자 보호에 관한 법률’, ‘전자상거래
                등에서의 상품 등의 정보제공에 관한 고시’ 등 관련 법령 및 본
                계약에서 정한 방법으로 직접 등록 · 관리하여야 한다. 데이터상품
                등록 후 고시 등 관련 법령이 변경되는 경우 그에 맞추어 관련
                정보를 수정, 보완하여야 한다.
                <br />
                &#9313;플랫폼운영자는 데이터제공자가 등록한 데이터상품정보를
                플랫폼운영자가 정한 기준과 방법에 따라
                제조데이터보호거래플랫폼에 게재할 수 있고 데이터제공자의 의견을
                반영하여 상품정보를 수정 게재할 수 있다.
                <br />
                &#9314;데이터제공자는 판매하는 데이터상품의 종류와 범위,
                판매가격, 거래조건을 스스로 결정하고 플 랫폼운영자는 합리적인
                이유없이 이에 관여하지 아니한다.
                <br />
                &#9315;데이터제공자가 위탁 및 제공하는 데이터상품의 종류와 범위,
                판매가격, 거래조건은 스마트 계약에 명시한다.
                <br />
                &#9316;데이터제공자는 등록상품에 특별한 거래조건이 있거나
                추가되는 비용이 있는 경우 데이터이용자 가 이를 알 수 있도록
                명확하게 기재하여야 한다.
                <br />
                &#9317;데이터이용자가 본 조 및 플랫폼운영자가 정한
                상품등록기준을 위반한 경우 플랫폼운영자는 데이 터이용자에 대해
                상품판매 제한, 판매자 아이디(ID) 사용 중지 등 필요한 조치를 취할
                수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제12조(판매제한 상품 및 금지행위)
                <br />
                &#9312;다음 각 호에서 정하는 판매제한 상품의 등록·판매는
                금지된다. 이를 위반하여 판매제한 상품을 등록·판매한 경우 관련한
                모든 책임은 데이터제공자가 부담한다.
                <BoxSx>
                  1. 개인정보, 지식재산권 등 타인의 권리를 침해하는 상품
                </BoxSx>
                <BoxSx>2. 법령에 따라 유통 및 판매가 금지된 상품</BoxSx>
                <BoxSx>
                  3. 그 외 플랫폼운영자가 합리적인 사유로 판매를 금지하는 상품
                </BoxSx>
                &#9313;데이터제공자는 다음 각 호의 행위를 하여서는 아니된다.
                <BoxSx>
                  1. 실제 판매하지 않는 상품을 등록하거나 중복하여 상품등록을
                  하는 행위
                </BoxSx>
                <BoxSx>
                  2. 개인정보, 지식재산권 등 타인의 권리를 침해하거나 표시광고법
                  등 관련 법령을 위반하는 내용으로 상품의 정보를 안내하거나
                  광고하는 행위
                </BoxSx>
                <BoxSx>
                  3. 제조데이터보호거래플랫폼을 통하지 않고 데이터이용자에게
                  직접 상품을 판매하거나 이를 유도하는 행위
                </BoxSx>
                <BoxSx>
                  4. 그 외 플랫폼운영자가 합리적인 사유로 데이터제공자에게
                  중지를 요청하는 행위
                </BoxSx>
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제13조(서비스 이용료)
                <br />
                &#9312;서비스이용료는 플랫폼운영자가 제공하는 서비스를
                데이터제공자가 이용하는 것에 따른 대가로서 데이터제공자가
                플랫폼운영자에게 지급하는 금액을 의미하며, 플랫폼운영자는
                판매대금 정산 시 서비스 이용료를 공제하여 데이터제공자에게
                지급한다.
                <br />
                &#9313;서비스이용료는 플랫폼서비스이용료(판매가에 일정 비율의
                서비스이용료율을 곱한 것)와 판매 촉진서비스이용료(광고, 프로모션
                비용 등)를 포함한다.
                <br />
                &#9314;서비스이용료에 대한 구체적인 내용은 스마트계약에 별도로
                정하며, 플랫폼운영자와 데이터제공 자 간의협의 또는
                플랫폼운영자의 내부 사정에 따라 변경될 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제14조(데이터상품의 전송)
                <br />
                &#9312;플랫폼운영자는 3자 판매를 통해 구매한 데이터이용자의
                주문에 따른 결제가 완료되는 즉시 데이터제공자가 주문정보를
                확인할 수 있도록 조치를 취하고, 데이터제공자는 주문정보에 따라
                전송 하여야 한다.
                <br />
                &#9313;데이터제공자가 데이터이용자에게 제공하는 데이터상품의
                전송 또는 발송기간은 스마트계약 에 포함한다.
                <br />
                &#9314;전송의 방식으로 데이터를 판매하는 경우 플랫폼운영자는
                전송시 데이터의 무결성, 동일성 등을 보장하여야 한다.
                <br />
                &#9315;데이터의 제공 방법은 스마트계약서에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제15조(청약철회 등)
                <br />
                &#9312;데이터제공자는 ’전자상거래 등에서의 소비자보호에 관한
                법률‘ 제17조 제2항에 근거하여 데이터 제공이 개시된 경우
                데이터이용자의 청약철회, 계약해제(이하 ’청약철회 등‘이라고
                한다)의 요구를 거절할 수 있다. 다만, 제공되는 데이터가 가분적인
                경우 데이터이용자에게 제공이 개시되지 않은 부분에 대하여는
                그러하지 아니하다.
                <br />
                &#9313;데이터제공자는 청약철회 등이 불가능하다는 사실을
                데이터이용자가 쉽게 알 수 있는 곳에 명확하게 표시하고 다음 각
                호의 방법 중 하나 이상의 방법으로 시험 사용 상품을 제공하여야
                한다.
                <BoxSx>
                  1. 일부 이용의 허용 : 데이터의 일부를 미리보기, 미리듣기
                  등으로 제공
                </BoxSx>
                <BoxSx>
                  2. 한시적 이용의 허용 : 일정 사용기간을 설정하여 데이터 제공
                </BoxSx>
                <BoxSx>
                  3. 체험용 데이터 제공 : 일부 제한된 기능만을 사용할 수 있는
                  데이터 제공
                </BoxSx>
                <BoxSx>
                  4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기
                  곤란한 경우 : 데이터에 관한 정보 제공
                </BoxSx>
                <br />
                &#9314;데이터제공자가 제2항에 따라 청약철회 등이 불가능하다는
                사실을 명확하게 표시하지 않거나 시험 사용 상품을 제공하지 아니한
                경우에는 데이터이용자의 청약철회 등의 요구를 거절할 수 없다.
                상품의 내용이 표시·광고의 내용과 다르거나 계약내용과 다르게
                이행된 경우에도 같다.
                <br />
                &#9315;청약철회 등이 가능한 경우, 데이터이용자는 상품의 배송,
                전송이 완료된 때로부터 일정기간 이내에 청약철회 등을 요구할 수
                있다. 다만, 상품의 내용이 표시·광고의 내용과 다르거나 계약내용과
                다르게 이행된 경우 및 그 사실을 안 날 또는 알 수 있었던 날을
                기준으로 청약을 철회할 수 있으며 청약철회 기간은 스마트계약에
                포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제16조(청약철회 등의 효과)
                <br />
                &#9312;데이터를 구매한 데이터이용자는 청약철회 등을 한 경우에도
                이미 제공받은 데이터를 반환하지 아니한다. 다만 구매한 데이터를
                재사용하여서는 아니되며 삭제하여야 한다.
                <br />
                &#9313;데이터제공자는 데이터이용자가 청약철회를 한 날로부터
                3영업일 이내에 이미 지급받은 상품대금을 환급하여야 한다. 이 경우
                데이터제공자가 데이터이용자에게 상품대금의 환급을 지연한 때에는
                그 지연기간에 대하여 전자상거래법 시행령이 정하는 이율을 곱하여
                산정한 지연이자를 지급하여야 한다.
                <br />
                &#9314;데이터제공자는 상품대금을 환급할 때 데이터이용자가
                신용카드로 상품대금을 지급한 경우에는 지체없이 결제업자에게
                상품대금의 청구를 정지하거나 취소하도록 요청하여야 한다. 다만,
                데이터제공자가 결제업자로부터 해당 상품대금을 이미 지급받은
                때에는 지체없이 그 대금을 결제업자에게 환급하고 그 사실을
                데이터이용자에게 알려야 한다.
                <br />
                &#9315;플랫폼운영자는 상품대금의 환급과 관련한 의무의 이행에
                대하여 데이터제공자와 연대하여 등 관련 비용에 대하여
                데이터제공자에게 구상권을 행사할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제17조(판매대금의 정산)
                <br />
                &#9312;플랫폼운영자는 데이터제공자가 제공한 데이터를 제 3자에게
                판매한 판매대금 및 추가 활용으로 발생한 금액에서 제13조의 서비스
                이용료, 플랫폼운영자에 대한 채무금, 기타 데이터제공자의
                귀책사유로 발생한 비용을 공제한 나머지 금액을 데이터제공자에게
                지급한다.
                <br />
                &#9313;플랫폼에서는 3자 판매를 통해 구매한 데이터 이용자가
                서비스화면에서 구매확정을 클릭하거나 구매확정 기간이지나
                자동구매확정이 된 후{" "}
                <InputBasic
                  id="r_day"
                  type="number"
                  // label={"데이터 명 입력"}
                  label={platform_messageList.label08}
                  value={rDay}
                  // helperText="**이전 내용입니다."
                  // helperText={preContractContent[0]}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    // 전문 변경 이벤트 호출
                    setOnPreambleChangeEvent(event, {
                      rDay: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      rDay: newCountValue,
                      vDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setRDay(newCountValue);
                  }}
                  disabled={disabled}
                />{" "}
                영업일 이내에 위 판매대금의 정산금을 데이터제공자에게 지급한다.
                <br />
                &#9314;데이터제공자는 데이터이용자가 판매대금의 정산 이후 또는
                본 계약의 종료 이후 청약철회 등을 요구하는 경우를 대비하여 일정
                금액의 판매예치금을 부담할 수 있다. 이 경우 상품대금의 환급금은
                판매예치금으로부터 우선 충당된다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제18조(본 계약 종료 후의 효력)
                <br />
                &#9312;플랫폼운영자는 3자 판매를 통해 구매한 데이터이용자가
                신용카드로 결제했을 때 상품의 허위등록, 판매가장등록, 신용카드
                결제시스템만을 이용하기 위한 허위거래가 의심되는 경우 해당
                거래내역을 확인하기 위하여 최대{" "}
                <InputBasic
                  id="v_day"
                  type="number"
                  min={0}
                  // label={"위반 시정기간 일수 입력"}
                  label={platform_messageList.label09}
                  value={vDay}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      vDay: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      rDay,
                      vDay: newCountValue,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setVDay(newCountValue);
                  }}
                  disabled={disabled}
                />
                일까지 정산을 보류할 수 있다. 이 경우 플랫폼운영자는 상품거래
                사실 확인을 위한 증빙서류를 데이터제공자에게 요구할 수 있고 이를
                확인한 후 상품판매대금의 정산금을 지급할 수 있다.
                <br />
                &#9313;데이터제공자와 데이터이용자 간에 동일한 유형의 환불요청이
                지속적으로 발생하거나 발생한 가능성이 객관적으로 인정되는 경우
                데이터이용자로부터의 환불요청에 대비하여 일정 기간 판매대금의
                정산을 유보할 수 있다.
                <br />
                &#9314;판매대금에 대하여 가압류, 압류 및 추심명령 등이 있는 경우
                플랫폼운영자는 해당 결정이 해제될 때까지 판매대금의 정산을
                중지하거나 제3채무자로서 데이터제공자의 채권자에게 채무를 변제할
                수 있다.
                <br />
                &#9315;본 조에서 정한 경우 외에도 법률의 규정에 의하거나
                합리적인 사유가 있는 경우에는 플랫폼운영자는 데이터제공자에게
                통지하고 판매대금의 일부 또는 전부에 대한 정산을 일정 기간
                유보할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제19조(제공데이터의 유출 시 조치)
                <br />
                &#9312;데이터이용자는 제공데이터 유출, 제3자 제공, 목적 외 이용
                등 본 계약에 위반하는 제공데이터의 이용(이하 [제공데이터 유출
                등]이라 한다)을 발견한 경우, 즉시 데이터제공자에게 그 취지를
                통지하여야 한다.
                <br />
                &#9313;데이터이용자의 고의 또는 과실에 의해 제공데이터 유출 등이
                발생한 경우, 을은 자기의 비용과 책임으로 제공데이터 유출 등의
                사실 여부를 확인하고, 제공데이터 유출 등의 사실이 확인된 경우 그
                원인 및 재발방지책을 검토하고 이를 데이터사용자에게 보고하여야
                한다.
                <br />
                &#9314;제공데이터에 개인정보가 포함되어 있는 경우,
                데이터이용자는 개인정보보호법령에 따라 개인정보 유출 등의 사실을
                감독당국에 신고하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제20조(지식재산권, 개인정보 등의 보호)
                <br />
                &#9312;데이터제공자는 데이터상품, 서비스 등의 등록, 판매, 광고
                등과 관련하여 타인의 상표권, 특허권, 디자인권, 저작권, 초상권,
                성명권 기타 타인의 지식재산권 및 개인정보를 침해하지 아니하여야
                한다. 타인의 지식재산권, 개인정보를 이용할 경우에는 정당한
                권리자를 확인하고 그로부터 이용허락을 받아야 한다.
                <br />
                &#9313;정당한 권리자가 전항과 관련하여 데이터제공자에 대해
                권리침해를 주장하는 경우, 오픈마켓 운영자는 관련 데이터상품과
                서비스의 판매를 중지하는 등의 필요한 조치를 취할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제21조(데이터이용자 개인정보의 보호)
                <br />
                &#9312;데이터제공자는 데이터이용자의 개인정보를 처리하는 경우
                기술적·관리적 보호조치 등 개인정보보호법 등 관련 법령에서 정한
                개인정보보호에 관한 규정을 준수하여야 한다.
                <br />
                &#9313;데이터제공자는 전자상거래 플랫폼 서비스의 이용에 따라
                취득한 데이터이용자 등 타인의 개인정보를 본 계약에서 정한 목적
                이외의 용도로 사용할 수 없으며, 개인정보의 분실·도난·유출·변조
                또는 훼손을 방지할 의무가 있다. 이를 위반할 경우 데이터제공자는
                관련 법령에 따른 모든 민·형사상의 법적 책임을 부담하고 자신의
                노력과 비용으로 플랫폼운영자를 면책시켜야 한다.
                &#9314;플랫폼운영자는 개인정보 보호를 위하여 배송 등의 목적으로
                데이터제공자에게 공개되어있는 데 이터이용자의 개인정보를 상당
                기간이 경과한 후 비공개 조치할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제22조(면책)
                <br />
                &#9312;플랫폼운영자는 데이터판매중개자로서
                데이터보호거래플랫폼을 기반으로 한 거래시스템만을 제공하고,
                데이터제공자가 등록한 상품 등에 관한 정보 또는 데이터이용자와의
                거래에 관하여 발생한 분쟁에 개입하지 아니하며, 해당 분쟁의
                결과로 인한 모든 책임은 데이터제공자가 부담한다.
                <br />
                &#9313;전항과 관련하여 플랫폼운영자가 제3자에게 손해를
                배상하거나 기타 비용을 지출한 경우 플랫폼 운영자는
                데이터제공자에게 구상권을 행사할 수 있다.
                <br />
                &#9314;플랫폼운영자는 적법한 권리자의 요구가 있는 경우에는 해당
                상품 등에 관한 정보를 삭제하거나 수정할 수 있으며,
                데이터제공자는 이에 대해 이의를 제기하거나 손해배상을 청구할 수
                없다.
                <br />
                &#9315;플랫폼운영자는 ‘전자상거래 등에서의 소비자보호에 관한
                법률’ 제20조 제2항에 의거하여 데이터제공자의 정보를 열람할 수
                있는 방법을 데이터이용자에게 제공할 수 있으며, 데이터제공자는
                해당 정보를 기재하지 아니하거나 허위로 기재함으로써 발생하는
                모든 책임을 부담하여야 한다. 이와 관련하여 플랫폼운영자가
                제3자에게 손해를 배상하거나 기타 비용을 지출한 경우
                플랫폼운영자는 데이터제공자에게 구상권을 행사할 수 있다.
                <br />
                &#9316;플랫폼운영자는 컴퓨터 등 정보통신설비의 보수, 점검, 교체,
                고장, 전기통신의 중단, 지연 등에 따라 전자상거래 관련 서비스의
                제공이 중단된 경우 고의 또는 중대한 과실이 없는 한 책임을 지지
                아니한다.
                <br />
                &#9317;플랫폼운영자는 관련 법령 및 오픈마켓운영자가 제공한 일반
                이용약관 및 개별약관의 변경, 데이터제공자에 대한 공지사항 등의
                주의의무를 게을리하여 발생한 데이터제공자의 불이익에 대한 책임을
                지지 아니한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제23조(계약기간)
                <br />
                &#9312;계약의 유효기간 및 연장에 관련된 사항은 스마트계약에
                포함한다.
                <br />
                &#9313;당사자들이 본 계약의 내용 및 조건을 변경하고자 할 경우
                변경된 내용을 반영하여 계약서를 새로이 작성하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제24조(계약의 해제 또는 해지)
                <br />
                &#9312;일방 당사자는 타방 당사자가 본 계약을 위반한 경우{" "}
                <InputBasic
                  id="s_day"
                  type="number"
                  min={0}
                  // label={"위반 시정기간 일수 입력"}
                  label={platform_messageList.label10}
                  value={sDay}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      sDay: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      rDay,
                      vDay,
                      sDay: newCountValue,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setSDay(newCountValue);
                  }}
                  disabled={disabled}
                />
                일간의 기간을 정해 위반의 시정을 요구할 수 있고, 그 기간내에
                타방 당사자가 위반을 시정하지 않은 때에는 서면에 의한 통지로써
                본 계약을 해제/해지할 수 있다. &#9313;다음 각 호에 해당하는
                경우, 양 당사자는 최고 없이도 즉시 본 계약을 해제/해지할 수
                있다.
                <BoxSx>
                  1. 플랫폼운영자가 대금 지급의무를{" "}
                  <InputBasic
                    id="s2_day"
                    type="number"
                    min={0}
                    // label={"위반 시정기간 일수 입력"}
                    label={platform_messageList.label11}
                    value={s2Day}
                    onChange={(event) => {
                      const newCountValue = countValue(event);
                      setOnPreambleChangeEvent(event, {
                        s2Day: newCountValue,
                      });
                      onVaildate({
                        cRange,
                        dRange,
                        month,
                        rDay,
                        vDay,
                        sDay,
                        s2Day: newCountValue,
                        process,
                        processDataType,
                        processMonth,
                        processDRate,
                        processDCount,
                        processCRate,
                        processCCount,
                      });
                      setS2Day(newCountValue);
                    }}
                    disabled={disabled}
                  />
                  일 이상 지체하는 경우
                </BoxSx>
                <BoxSx>
                  2. 수표, 어음의 부도처리 등 지급정지상태에 빠진 경우
                </BoxSx>
                <BoxSx>3. 압류, 가압류, 가처분, 강제집행이 발생한 경우</BoxSx>
                <BoxSx>
                  4. 파산, 화의, 정리, 회생절차 개시신청을 받거나 또는 스스로
                  그러한 신청을 한 경우
                </BoxSx>
                <BoxSx>
                  5. 타방 당사자의 사전승낙 없이 본 계약상 권리의 전부 또는 일부
                  양도하는 경우
                </BoxSx>
                &#9314;본 조의 해제/해지는 손해배상청구권에 영향을 미치지
                않는다. &#9315;양 당사자는 본 계약에서 별도로 정한 경우 이외에
                서면으로 합의함으로써 본 계약을 해제/해지 할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제25조(비용)
                <br />본 계약에 별도의 정함이 있는 경우와 아울러 양 당사자들이
                별도 합의한 경우를 제외하고, 본 계약 의 체결 및 이행에 관한
                비용에 관해서는 각자의 부담으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제26조(불가항력)
                <br />
                &#9312;어떠한 당사자도 지진, 태풍, 수해, 화재, 노동쟁의, 전쟁
                기타 예측이 불가능하고 합리적인 통제를 벗어나는 원인으로 인해
                계약의 이행에 실패하거나 이행을 지체하여 손해 또는 손실을
                발생시켰더라도 이에 대해 책임을 지지 않는다. 단, 이행을 하지
                못하게 된 당사자는 그 원인을 제거하기 위해 상업적이고 합리적인
                노력을 다하여야 하고, 그 원인이 제거된 후에는 합리적인 조치로
                계약의 이행을 지속해야 한다.
                <br />
                &#9313;어느 일방 당사자가 불가항력의 사유로 이행의무의 전부 또는
                일부에 대한 불능의 상태에 이르는 경우, 즉시 상대 당사자에게
                그러한 불가항력에 대한 합리적으로 충분한 내용을 통지하여야 한다.
                <br />
                &#9314;전항의 경우, 양 당사자들은 계약의 원활한 이행을 위해
                성실하게 선후책을 협의해야 한다.
                <br />
                &#9315;불가항력으로 본 계약의 이행불능 또는 이행지체가 일 이상
                지속되는 경우, 일방 당사자는 상대방 에 대한 서면 통지로서
                손해배상 없이도 본 계약을 해제/해지할 수 있다.
                <br />
                &#9316;불가항력을 이유로 본 계약이 해제/해지될 경우 그때까지 양
                당사자가 수령한 이용대가는 반환되 지 않는다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제27조(완전합의)
                <br />본 계약은 양 당사자의 모든 합의를 망라하며, 본건 주제에
                관한 양 당사자 간의 서면 또는 구두에 의한 종전의 일체의 합의에
                우선한다. 본 계약의 변경은 양 당사자가 서명한 서면에 의하지 않는
                한 구속력을 가지지 않는 것으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제28조(일부 무효)
                <br />본 계약의 일부 조항이 무효 또는 이행불능으로 인정되더라도
                특별한 사정이 없는 한 이러한 사실은 본 계약의 다른 조항의
                유효성에 영향을 미치지 아니한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제29조(양도금지)
                <br />양 당사자는 상대방 당사자의 사전 서면에 의한 동의를 받지
                아니하고, 본 계약, 본 계약상의 지위 또는 이들에 기한 권리 ·
                의무를 양도, 이전 기타 처분을 해서는 아니된다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제30조(준거법)
                <br />본 계약은 한국법에 준거하고, 한국법에 따라서 해석한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제31조(관할법원 등)
                <br />
                &#9312;본 계약에 기인하거나 관련하여 발생한 분쟁(본 계약의 각
                조항의 해석에 의문이 발생한 경우를 포함함)에 관해서는 우선 양
                당사자들이 성실하게 협의함으로써 그 해결하기로 한다. &#9313;위
                &#9312;항의 협의가 이루어지지 않은 경우에는 울산지방법원을
                제1심의 전속적 합의관할 법원으로 한다.
              </Typography>
            </GridSx>
          </AccordionDetails>
        </Accordion>
      </div>
      <div ref={appendixRef}>
        <Accordion sx={accordionSx} PaperComponent={PaperComponent}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography component="h2" variant="subtitle2">
                  플랫폼 표준계약서(부속)
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {/* <StyledPaper> */}
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                플랫폼형 표준계약서 - 부속문서
                <br />
                플랫폼운영자와 데이터이용자 간의 표준계약서,
                <br />
                플랫폼운영자와 데이터제공자 간의 표준계약서
                <br />
                <br />
                1. 제공데이터 범위
                <br />
                &#9312;제공데이터는{" "}
                <InputBasic
                  id="process"
                  // label={"공정 명 입력"}
                  label={platform_messageList.label12}
                  value={process}
                  onChange={(event) => {
                    // 부속 변경 이벤트 호출
                    setOnAppendixChangeEvent(event, {
                      process: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      vDay,
                      rDay,
                      sDay,
                      s2Day,
                      process: event.target.value,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcess(event.target.value);
                  }}
                  disabled={disabled}
                />
                공정의{" "}
                <InputBasic
                  id="process_data_type"
                  // label={"데이터 명 입력"}
                  label={platform_messageList.label13}
                  value={processDataType}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processDataType: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      rDay,
                      vDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType: event.target.value,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessDataType(event.target.value);
                  }}
                  disabled={disabled}
                />
                데이터의{" "}
                <InputBasic
                  id="process_month"
                  type="number"
                  min={0}
                  // label={"개월 수 입력"}
                  label={platform_messageList.label14}
                  value={processMonth}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processMonth: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      rDay,
                      vDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth: newCountValue,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessMonth(newCountValue);
                  }}
                  disabled={disabled}
                />
                개월치 데이터를 말한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                플랫폼형 표준계약서 - 부속문서(파생데이터)
                <br />
                플랫폼운영자와 데이터이용자 간의 표준계약서,
                <br />
                플랫폼운영자와 데이터제공자 간의 표준계약서
                <br />
                <br />
                1. 파생데이터 이용허락 조건 및 이득발생시 지급조건
                <br />
                &#9312;데이터이용자의 파생데이터를 이용한 경제적 비경제적 활동이
                데이터제공자의 사업에 불이익을 주지않아야 한다.
                <br />
                &#9313;계약종료시 파생데이터를 더 이상 사용하지 않을 시에는
                파생데이터를 파기하여야 하며 파생데이터를 계속이용할 경우에는
                별도의 계약을 통해 파생데이터를 이용하여야 한다.
                <br />
                &#9314;파생데이터를 이용하여 발생한 매출의{" "}
                <InputBasic
                  id="process_d_rate"
                  type="number"
                  min={0}
                  // label={"매출의 제공 % 입력"}
                  label={platform_messageList.label15}
                  value={processDRate}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processDRate: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      vDay,
                      rDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate: newCountValue,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessDRate(newCountValue);
                  }}
                  disabled={disabled}
                />
                %를 갑에게 제공하여야 하다. 파생데이터의 사용횟수 및 타
                기관으로의 제공건수{" "}
                <InputBasic
                  id="process_d_count"
                  // label={"제공 건수/ 년 입력"}
                  label={platform_messageList.label16}
                  value={processDCount}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processDCount: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      vDay,
                      rDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount: event.target.value,
                      processCRate,
                      processCCount,
                    });
                    setProcessDCount(event.target.value);
                  }}
                  disabled={disabled}
                />{" "}
                건/년 으로 제한한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                플랫폼형 표준계약서 - 부속문서(걸합데이터)
                <br />
                플랫폼운영자와 데이터이용자 간의 표준계약서,
                <br />
                플랫폼운영자와 데이터제공자 간의 표준계약서
                <br />
                <br />
                1. 결합데이터 이용허락 조건 및 이득발생시 지급조건
                <br />
                &#9312;데이터이용자의 결합데이터를 이용한 경제적 비경제적 활동이
                데이터제공자의 사업에 불이익을 주지않아야 한다.
                <br />
                &#9313;계약종료시 결합데이터를 더 이상 사용하지 않을 시에는
                결합데이터를 파기하여야 하며 결합데이터를 계속이용할 경우에는
                별도의 계약을 통해 파생데이터를 이용하여야 한다.
                <br />
                &#9314;결합데이터를 이용하여 발생한 매출의{" "}
                <InputBasic
                  id="process_c_rate"
                  type="number"
                  min={0}
                  // label={"매출의 제공 % 입력"}
                  label={platform_messageList.label17}
                  value={processCRate}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processCRate: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      vDay,
                      rDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate: newCountValue,
                      processCCount,
                    });
                    setProcessCRate(newCountValue);
                  }}
                  disabled={disabled}
                />{" "}
                %를 데이터제공자에게 제공하여야 하다. 결합데이터의 사용횟수 및
                타 기관으로의 제공건수
                <InputBasic
                  id="process_c_count"
                  // label={"제공 건수/ 년 입력"}
                  label={platform_messageList.label18}
                  value={processCCount}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processCCount: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      month,
                      vDay,
                      rDay,
                      sDay,
                      s2Day,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount: event.target.value,
                    });
                    setProcessCCount(event.target.value);
                  }}
                  disabled={disabled}
                />
                건/년 으로 제한한다.
              </Typography>
            </GridSx>
            {/* </StyledPaper> */}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
