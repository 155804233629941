import React, { useEffect, useRef, useState, useContext } from "react";
import { MessageContext } from "assets/js/syntax";
// import { client as axios } from "../../assets/js/axios";
import client from "assets/js/commonApi";
import {
  ContractElementToText,
  ContractHtmlToText,
} from "assets/js/contractUtil";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
  Typography,
  TextField,
  Stack,
  InputAdornment,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import IconTxtBtn from "components/btns/IconTxtBtn"; // 계약 담당자 검색 버튼(확장성있게 수정하기 애매)
import PaperComponent from "components/paper/PaperComponent"; // paper
import InputContent from "components/inputBox/InputContent"; // 내용
import DatePic from "components/picker/DatePic";
import ContractRadioBtn from "components/radio/ContractRadioBtn"; // 레디오 버튼
import {
  PAYMENT_TYPE_LABELS,
  PROCESSING_AT_END_LABELS,
  DATA_PROVIDED_LABELS,
  FILE_TYPE_LABELS,
} from "assets/hooks/constants/contractRadioButtonLabels"; // 레디오 버튼 라벨
// import ContractAccordion from "components/accordion/ContractAccordion";
import ContractAccordionPlatformSell from "components/accordion/ContractAccordionPlatformSell";
import SelectCompany from "components/selectBox/SelectCompany"; // 계약 담당자 - 선택 인풋창
import InputDisable from "components/inputBox/InputDisable"; // 계약 담당자 - 선택 후 값 자동 저장
import ContractNeedAddInput from "./ContractNeedAddInput";
import ModalContractHandlerSelect from "./ModalContractHandlerSelect"; // 담당자 선택

// 담당자 선택
import { CircularIndeterminate } from "components/loaders/CircularIndeterminate";
// import { AppContext } from "App";
import { SelectForm } from "components/selectBox/SelectForm";

// 쿼리
import { useGroupSelectQuery } from "assets/hooks/useCategory/useSelectQuery";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  // maxWidth: 400,
  margin: "8px auto",
  display: "flex",
  flex: 1,
  color: theme.palette.text.primary,
}));

const ModalPlatformContractNew = ({
  onClose,
  contract_number,
  preamble,
  appendix,
  onRefresh,
  // step_type,
}) => {
  const { triggerRefresh } = useContext(MessageContext); // 전역 계약창 정보 업데이트 시 리프레쉬
  // 로그인 유저 정보
  // const company = sessionStorage.getItem("company");
  const userDID = sessionStorage.getItem("did");
  // const userName = sessionStorage.getItem("name");

  // 240513 로그인 정보 업데이트
  const company = sessionStorage.getItem("company");
  const userName = sessionStorage.getItem("name");

  // 쿼리
  const selectedGroupQuery = useGroupSelectQuery();
  const { data: groups, isLoading, isError } = selectedGroupQuery;
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [contractNumber, setContractNumber] = useState("");
  console.log("## contractNumber", contractNumber);
  // const [changeUpdate, setChangeUpdate] = useState();
  const [recital, setRecital] = useState("");
  // const [preambleData, setPreambleData] = useState([company]); // 240416 추가
  // const [appendixData, setAppendixData] = useState([]); // 240416 추가
  const [preambleData, setPreambleData] = useState(""); // 240416 추가
  const [appendixData, setAppendixData] = useState(""); // 240416 추가
  const onPreambleChange = (event, newData) => {
    setPreambleData(newData);
  };

  const onAppendixChange = (event, newData) => {
    setAppendixData(newData);
  };
  const [from_dtm, setFrom_dtm] = useState(dayjs());
  const [to_dtm, setTo_dtm] = useState(dayjs());
  const [amount, setAmount] = useState(null);
  const [finalCnt, setfinalCnt] = useState(null);
  const [paymentValue, setPaymentValue] = useState("500");
  const [processingAtEndValue, setProcessingAtEndValue] = useState("600");
  const [dataProvidedValue, setDataProvidedValue] = useState("701");
  const [fileTypeValue, setFileTypeValue] = useState("800");
  // const selectedPaymentTypeLabel = PAYMENT_TYPE_LABELS[paymentValue];
  // const selectedProcessingAtEndLabel =
  //   PROCESSING_AT_END_LABELS[processingAtEndValue];
  // const selectedDataProvidedLabel = DATA_PROVIDED_LABELS[dataProvidedValue];
  // const selectedFileTypeLabel = FILE_TYPE_LABELS[fileTypeValue];

  const [buyerId, setBuyerId] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(); // 그룹 선택창

  // 검색창 테스트 S
  const [selectedCompanyValue, setSelectedCompanyValue] = useState(); // 선택된 회사명

  const [selectedManagerValue, setSelectedManagerValue] = useState(); // 선택된 담당자

  const onSelectedCoNMgrChange = ({ coName, mgrName, userId }) => {
    setSelectedCompanyValue(coName);
    setSelectedManagerValue(mgrName);
    setBuyerId(userId);
  };

  // 검색창 테스트 E

  // *** 계약담당자선택 팝업 open NEW S
  const [handleBtn, setHandleBtn] = useState();
  const [isOpenModalHandlerSelect, setIsOpenModalHandlerSelect] =
    useState(false);
  // *** 계약담당자선택 팝업 open NEW E

  // 회사명 선택 콤보박스 { value: '회사코드', label: '회사명' }
  // 담당자 선택 콤보박스 { value: 'did', label: '이름' }

  const preambleRef = useRef(); // 표준계약서
  const appendixRef = useRef(); // 부속계약서
  // const [preamble, setPreamble] = useState(); // 표준 계약서
  // const [appendix, setAppendix] = useState(); // 부속 계약서

  // 해당 셀렉터와 일치하는 첫 번째 자식 요소를 찾아 그 값을 반환
  const extractValue = (template, selector) =>
    template.firstElementChild.querySelector(selector)?.value;
  // HTML 문자열을 사용하여 새로운 HTML 템플릿을 생성
  const createTemplate = (html) => {
    const template = document.createElement("div");
    template.innerHTML = html.trim();
    return template;
  };
  // 데이터 확인용 - 최종구현 후 삭제
  const logValues = (prefix, values) => {
    Object.entries(values).forEach(([key, value]) => {
      console.log(`## ${prefix} ${key}`, value);
    });
  };

  // const commonSaveUpdate = () => {}

  // ** 저장 & 등록 S
  // commonSaveUpdate 저장 & 등록 공통
  const selectorsP = {
    "#sell_company": "sellCompany",
    "#buy_company": "buyCompany",
    "#data_type": "dataType",
    "#day": "day",
    "#d_range": "dRange",
    "#c_range": "cRange",
    "#s_day": "sDay",
    "#count": "count",
    "#year": "year",
    "#v_day": "vDay",
    "#r_day": "rDay",
    "#court": "court",
  };
  const selectorsX = {
    "#process": "process",
    "#process_data_type": "processDataType",
    "#process_month": "processMonth",
    "#process_d_rate": "processDRate",
    "#process_d_count": "processDCount",
    "#process_c_rate": "processCRate",
    "#process_c_count": "processCCount",
  };

  useEffect(() => {
    if (contractNumber) {
      console.log("계약 번호가 설정되었습니다:", contractNumber);
      // 여기에 계약 번호를 사용하여 실행할 추가 작업을 넣습니다.
      // 예: 다음 단계의 API 호출, UI 업데이트 등
    }
  }, [contractNumber]);

  // 저장(첫번째 임시 저장)
  const register = (step_type, btnSU) => {
    setIsMessageLoading(true); // 요청 전 로딩 시작
    const preamble = ContractElementToText(preambleRef.current); // 표준
    const appendix = ContractElementToText(appendixRef.current); // 부속

    const headers = { responseType: "stream" };

    // 계약서 히스토리 이력
    const contract_hist = {
      hist_body: [preambleData[1], preambleData[2]], // input값만 빼서 배열 형태
      hist_appendix: appendixData, // input값만 빼서 배열 형태
    };

    client
      .post(
        "/platformsell/insert",
        {
          // buyer_id: buyerId,
          seller_group_id: selectedGroup,
          contract_title: recital,
          step_type: step_type,
          to_dtm: to_dtm.format("YYYY-MM-DD"),
          from_dtm: from_dtm.format("YYYY-MM-DD"),
          // contract_price: amount,

          // contract_number: contract_number,
          payment_type: paymentValue,
          termination_type: processingAtEndValue,
          response_type: dataProvidedValue,
          data_type: fileTypeValue,
          contract_type: "002", // 고정타입 // 계약서 양식 다르니깐 박아두면 된다.
          contract_body: preamble,
          contract_appendix: appendix,
          final_cnt: finalCnt, // 계약정보내 있는거
          contract_hist: step_type === "201" ? contract_hist : null,
        },
        { headers },
      )
      .then((response) => {
        const { status, data } = response;
        // const step200 = step_type === "200";
        // const step201 = step_type === "201";
        // const datas = response.data.data; // 계약서 넘버
        if (status === 200) {
          let shouldClose = true;
          setContractNumber(data.data); // 계약서 넘버
          setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
          switch (step_type) {
            case "200":
              alert("저장 하였습니다");
              shouldClose = false; // 저장버튼을 눌렀을 때는 창을 닫지 않음
              break;
            case "201":
              alert("등록 하였습니다");
              break;
            // case "200":
            //   if (btnSU === "S") {
            //     alert("저장 하였습니다");
            //     shouldClose = false; // 저장버튼을 눌렀을 때는 창을 닫지 않음
            //   } else if (btnSU === "U") {
            //     alert("등록 하였습니다");
            //   }
            //   break;
          }
          if (shouldClose && onClose) onClose();
          onRefresh();
        }
        // if (response.data.msg === "판매 등록 및 저장 완료") {
        //   setContractNumber(response.data.data); // 계약서 넘버
        //   console.log("Received contract number:", response.data.data);
        //   if (step200) {
        //     alert("저장 하였습니다");
        //     triggerRefresh();
        //   } else if (step201) {
        //     alert("등록 하였습니다");
        //     triggerRefresh();
        //     if (onClose) onClose();
        //   }
        // }
        // console.log("## datas", datas); // 계약서 넘버
      })
      .catch((error) => {
        console.log("An error occurred:", error.response); // Handle error.
        setIsMessageLoading(false); // 오류 발생 시 로딩 종료
      });
  };

  // 등록
  const update = (step_type, btnSU) => {
    setIsMessageLoading(true); // 요청 전 로딩 시작

    const preamble = ContractElementToText(preambleRef.current); // 표준
    const appendix = ContractElementToText(appendixRef.current); // 부속

    console.log("## REF PREAMBLE", preamble);
    console.log("## REF APPENDIX", appendix);

    // 바인딩 할 때 구문
    const headers = { responseType: "stream" };

    // 계약서 히스토리 이력
    const contract_hist = {
      hist_body: [preambleData[1], preambleData[2]], // input값만 빼서 배열 형태
      hist_appendix: appendixData, // input값만 빼서 배열 형태
    };

    client
      .post(
        "/platformsell/update",
        {
          step_type: step_type,
          contract_sub_id: contractNumber,
          buyer_id: buyerId,
          seller_group_id: selectedGroup,
          contract_title: recital, // 제목
          to_dtm: to_dtm.format("YYYY-MM-DD"), // 시작일
          from_dtm: from_dtm.format("YYYY-MM-DD"), // 종료일
          contract_price: amount, // 금액
          termination_type: processingAtEndValue,
          response_type: dataProvidedValue,
          data_type: fileTypeValue,
          payment_type: paymentValue,
          contract_type: "002", // 고정타입 // 계약서 양식 다르니깐 박아두면 된다.
          // step_type: step_type,
          // contract_number: contractNumber,
          contract_body: preamble,
          contract_appendix: appendix,

          final_cnt: finalCnt, // 계약정보내 있는거
          contract_hist: step_type === "201" ? contract_hist : null, // 구매자가 수정요청한 케이스 ?
        },
        { headers },
      )
      .then((response) => {
        const { status } = response;
        // const { step_type } = contractData;
        // const isSeller = company_info_type === "seller";
        // const isBuyer = company_info_type === "buyer";
        // const step200 = step_type === "200";
        // const step201 = step_type === "201";
        if (status === 200) {
          let shouldClose = true;
          setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
          switch (step_type) {
            case "200":
              alert("저장 하였습니다");
              shouldClose = false; // 저장버튼을 눌렀을 때는 창을 닫지 않음
              break;
            case "201":
              alert("등록 하였습니다");
              break;
            // case "200":
            //   if (btnSU === "S") {
            //     alert("저장 하였습니다");
            //     shouldClose = false; // 저장버튼을 눌렀을 때는 창을 닫지 않음
            //   } else if (btnSU === "U") {
            //     alert("등록 하였습니다");
            //   }
            //   break;
          }
          if (shouldClose && onClose) onClose();
          onRefresh();
        }
        // if (
        //   response.data.msg === "판매 등록 및 저장 완료" ||
        //   response.data.msg === "수정 완료"
        // ) {
        //   if (step200) {
        //     alert("저장 하였습니다");
        //     triggerRefresh();
        //   } else if (step201) {
        //     alert("등록 하였습니다");
        //     triggerRefresh();
        //     if (onClose) onClose();
        //   }
        // }
      })
      .catch((error) => {
        console.log("An error occurred:", error.response); // Handle error.
        setIsMessageLoading(false); // 오류 발생 시 로딩 종료
      });
  };
  // // 등록

  // ** 저장 & 등록 E

  // console.log("## companyData", companyData);

  // **** 미 입력 S
  const [needAddInputOpen, setNeedAddInputOpen] = useState(false);
  const handleClickOpen = (params) => {
    setIsOpenModalHandlerSelect(true);
    setHandleBtn(params.target.value);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNeedAddInputOpen(false);
    setIsOpenModalHandlerSelect(false);
  };

  // **** 유효성 확인 S
  const [inputChange, setInputChange] = useState({});
  const [message, setMessage] = useState();
  const onVaildate = (data) => {
    setInputChange(data);
  };

  const {
    label00,
    // label01,
    label02,
    label03,
    // label04,
    label05,
    label06,
    label07,
    label08,
    label09,
    label10,
    label11,
    label12,
    label13,
    label14,
    label15,
    label16,
    label17,
    label18,
    // label19,
    // label20,
    // label21,
  } = useContext(MessageContext).platform_messageList;

  // const { platform_messageList } = useContext(MessageContext);

  const validationRules = [
    { field: "recital", label: label00 },
    // 240514 추가 S
    // { field: "amount", label: label01 },
    { field: "finalCnt", label: label02 },
    { field: "selectedGroup", label: label03 },
    // { field: "buyerId", label: label04 },
    // 240514 추가 E
    { field: "dRange", label: label05 },
    { field: "cRange", label: label06 },
    { field: "month", label: label07 },
    { field: "rDay", label: label08 },
    { field: "vDay", label: label09 },
    { field: "sDay", label: label10 },
    { field: "s2Day", label: label11 },
    { field: "process", label: label12 },
    { field: "processDataType", label: label13 },
    { field: "processMonth", label: label14 },
    { field: "processDRate", label: label15 },
    { field: "processDCount", label: label16 },
    { field: "processCRate", label: label17 },
    { field: "processCCount", label: label18 },
    // { field: "processDCount", label: label19 },
    // { field: "processCRate", label: label20 },
    // { field: "processCCount", label: label21 },
  ];

  // 유효성 에 대한 팝업 표시
  const Validation = () => {
    // 초기 필드 검사
    const initialFields = [
      { value: recital, label: label00 }, // 제목 = 기존내용
      // { value: amount, label: label01 }, // 합계 금액
      { value: finalCnt, label: label02 }, // 데이터 총 제공 횟수
      { value: selectedGroup, label: label03 }, // 그룹 ID
      // { value: buyerId, label: label04 }, // 계약 담당자 선택
    ];

    // 초기 필드 검사 수행
    for (const { value, label } of initialFields) {
      if (!value) {
        console.log(`## [유효성 에러]`, label);
        setMessage(label);
        return false; // 실패 시 검사 중단
      }
    }

    for (const rule of validationRules.slice(3)) {
      const fieldValue = inputChange[rule.field]; // 동적으로 필드에 대한 값을 가져오기
      console.log(`## fieldValue ${rule.field}`, fieldValue);
      // 현재 룰의 필드에 해당하는 입력 데이터가 없거나 길이가 0이면 아래의 코드를 실행
      if (!fieldValue) {
        console.log(`## 유효성 에러 ${rule.field}`, rule.label);
        setMessage(rule.label); // 유효성 에러 메시지
        return false; // 유효성 검사에서 실패시 false를 반환하고 함수를 종료
      }
    }
    return true; // 모든 룰을 통과하여 유효성 검사 통과했을 시 true 반환
  };
  // **** 유효성 확인 E

  const ValidationAdd = () => {
    // selectedGroup의 value 속성을 검사하여 유효한지 확인
    if (!finalCnt) {
      console.log("## 유효성 에러: 데이터 총 제공 횟수를 입력해 주세요.");
      setMessage("데이터 총 제공 횟수를 입력해 주세요.");
      return false;
    }
    return true; // 유효성 검사 통과
  };

  // 저장 & 등록 버튼 코드 병합
  const SaveUpdateButton = () => {
    console.log("## recital, recital.length", recital, recital.length);
    console.log("## contractNumber", contractNumber);

    // 저장
    const handleSaveBtn = () => {
      const target = "200";
      const action = contractNumber ? "update" : "register";
      // action === "register" && register(target);
      // action === "update" && update(target);
      if (!ValidationAdd()) {
        setNeedAddInputOpen(true); // 미 입력시 예외처리
        return;
      } else if (action === "register") {
        setNeedAddInputOpen(false);
        register(target, "S");
      } else if (action === "update") {
        setNeedAddInputOpen(false);
        update(target, "S");
      }
      // triggerRefresh();
    };

    // 등록
    const handleUpdateBtn = () => {
      const target = "201";
      const action = contractNumber ? "update" : "register";
      if (Validation() === false) {
        setNeedAddInputOpen(true);
        return;
      } else if (action === "register") {
        setNeedAddInputOpen(false);
        register(target, "U");
      } else if (action === "update") {
        setNeedAddInputOpen(false);
        update(target, "U");
      }
      // triggerRefresh();
    };
    return (
      <>
        <Button onClick={handleSaveBtn}>저장</Button>
        <Button onClick={handleUpdateBtn}>등록</Button>
      </>
    );
  };

  // // 240726 기존 코드
  // // 금액 & 데이터 총 제공 횟수
  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   // setAmount(value === "" ? 0 : Number(value)); // 빈 문자열 체크 후 0 또는 숫자 변환
  //   // const countValue = value === "" ? 0 : Number(value);
  //   const countValue = value === "" ? 0 : Math.max(Number(value), 0);
  //   console.log("입력된 값:", countValue); // 입력값 로깅
  //   if (name === "finalCnt") {
  //     // setfinalCnt(countValue);
  //     setfinalCnt(countValue !== null ? countValue : 0);
  //   }
  //   // if (name === "amount") {
  //   //   // setAmount(countValue);
  //   //   setAmount(countValue !== null ? countValue : 0);
  //   // } else if (name === "finalCnt") {
  //   //   // setfinalCnt(countValue);
  //   //   setfinalCnt(countValue !== null ? countValue : 0);
  //   // }
  //   // setAmount(countValue);
  //   // setfinalCnt(countValue);
  // };

  // 240726 기존 코드
  // 금액 & 데이터 총 제공 횟수
  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   // setAmount(value === "" ? 0 : Number(value)); // 빈 문자열 체크 후 0 또는 숫자 변환
  //   // const countValue = value === "" ? 0 : Number(value);
  //   const countValue = value === "" || 0 ? null : Math.max(Number(value), 0);
  //   console.log("입력된 값:", countValue); // 입력값 로깅
  //   if (name === "finalCnt") {
  //     // setfinalCnt(countValue);
  //     setfinalCnt(countValue !== null ? countValue : 0);
  //   }
  //   // if (name === "amount") {
  //   //   // setAmount(countValue);
  //   //   setAmount(countValue !== null ? countValue : 0);
  //   // } else if (name === "finalCnt") {
  //   //   // setfinalCnt(countValue);
  //   //   setfinalCnt(countValue !== null ? countValue : 0);
  //   // }
  //   // setAmount(countValue);
  //   // setfinalCnt(countValue);
  // };

  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   // const countValue = value === "" ? 0 : Number(value);
  //   const countValue = value === "" ? value : Math.max(Number(value), 0);
  //   // 만약에 첫번째 숫자가 0이면
  //   console.log("입력된 값:", countValue); // 입력값 로깅

  //   if (name === "amount") {
  //     // setAmount(countValue);
  //     setAmount(countValue !== null ? countValue : 0);
  //   } else if (name === "finalCnt") {
  //     // setfinalCnt(countValue);
  //     setfinalCnt(countValue !== null ? countValue : 0);
  //   }
  //   // setAmount(countValue);
  //   // setfinalCnt(countValue);
  // };

  const handleCountChange = (event) => {
    const { name, value } = event.target;
    let countValue;

    if (value === "") {
      countValue = ""; // 입력이 완전히 지워졌을 때 빈 문자열로 설정
    } else {
      // 첫 번째 자리가 '0'이고, 길이가 1을 초과하는 경우 '0'을 제거
      if (value.startsWith("0") && value.length > 1) {
        countValue = value.replace(/^0+/, "");
      } else {
        countValue = value;
      }

      // 음수 입력 방지 및 숫자 변환
      countValue = Math.max(Number(countValue), 0);
    }

    console.log("입력된 값:", countValue); // 입력값 로깅

    // 각 필드에 맞게 상태 업데이트
    if (name === "amount") {
      setAmount(countValue.toString()); // 문자열로 변환하여 상태 업데이트
    } else if (name === "finalCnt") {
      setfinalCnt(countValue.toString()); // 문자열로 변환하여 상태 업데이트
    }
  };

  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   let countValue;

  //   // 빈 문자열이거나 "0"이면 null을 할당하고, 그렇지 않으면 양수 또는 0으로 설정
  //   if (value === "" || value === "0") {
  //     countValue = "";
  //   } else {
  //     // countValue = Math.max(Number(value), 0);
  //     const trimmedValue =
  //       value.startsWith("0") && value.length > 1 ? value.substring(1) : value;
  //     countValue = Math.max(Number(trimmedValue), 0);
  //   }

  //   console.log("입력된 값:", countValue); // 입력값 로깅

  //   if (name === "amount") {
  //     // null일 때 null을 유지하고, 그렇지 않을 때만 숫자로 설정
  //     setAmount(countValue !== null ? countValue : null);
  //   } else if (name === "finalCnt") {
  //     // setfinalCnt(countValue !== null ? countValue : null);
  //     setfinalCnt(countValue === "" ? "" : countValue);
  //   }
  // };

  // // 금액 & 데이터 총 제공 횟수
  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   // const countValue = value === "" ? 0 : Number(value);
  //   const countValue =
  //     value === "" || null || 0 ? null : Math.max(Number(value), 0);
  //   console.log("입력된 값:", countValue); // 입력값 로깅

  //   if (name === "amount") {
  //     // setAmount(countValue);
  //     setAmount(countValue !== null ? countValue : 0);
  //   } else if (name === "finalCnt") {
  //     // setfinalCnt(countValue);
  //     setfinalCnt(countValue !== null ? countValue : 0);
  //   }
  //   // setAmount(countValue);
  //   // setfinalCnt(countValue);
  // };

  // 스타일
  const radioSX = {
    // "& .Mui-disabled": {
    //   color: "#000000DE !important",
    //   "-webkit-text-fill-color": "#000000DE !important",
    // },
    "& .MuiRadio-root": {
      p: 0,
    },
  };

  // ****** 240116 코드추가 S

  //   return _data;
  // };

  // ****** 240116 코드추가 E

  // 서치바 - 계약담당자 S

  // 서치바 - 계약담당자 E

  // 그룹 선택 창
  const GroupOptions = groups
    ? groups.map((group) => ({
        value: group.group_id, // group_id 사용
        label: group.group_name, // group_name 사용
      }))
    : [];

  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Dialog
          open={true}
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          {isMessageLoading && <CircularIndeterminate />}
          <DialogTitle
            component="h1" // 헤딩태그 지정
            variant="h6" // 변형 사이즈
            // sx={modalContractSx}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            플랫폼 판매 계약서
          </DialogTitle>
          {/* 타이틀 */}
          <DialogContent>
            {contractNumber && (
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ alignItems: "center", marginBottom: "8px" }}
              >
                <Grid item>
                  <Typography component="h2" variant="subtitle2">
                    계약번호
                  </Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography
                    id="contract_number"
                    component="body1"
                    variant="subtitle2"
                  >
                    {contractNumber}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {/* 계약번호 */}
            <Grid
              sx={{ flexGrow: 1 }}
              item
              xs={12}
              sm
              container
              direction="column"
            >
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography component="h2" variant="subtitle2">
                    제목
                  </Typography>
                  <StyledPaper
                    variant="outlined"
                    // sx={{
                    //   my: 1,
                    //   mx: "auto",
                    //   display: "flex",
                    //   flex: 1,
                    // }}
                  >
                    {/* <InputBase
                      // type="Number"
                      sx={{ width: "100%" }}
                      type="string"
                      placeholder="내용 입력"
                      inputProps={{ "aria-label": "내용 입력" }}
                      value={recital}
                      onChange={(event) => {
                        setRecital(event.target.value);
                        console.log("## 내용 입력 Test", event.target.value);
                      }}
                    /> */}
                    <InputContent
                      type="string"
                      value={recital}
                      placeholder="제목 입력"
                      inputProps={{ "aria-label": "제목 입력" }}
                      onChange={(event) => {
                        setRecital(event.target.value);
                        console.log("## radio 내용 입력", event.target.value);
                      }}
                      // disabled={DisabledTrue}
                      // helperText={PreContent[0]}
                    />
                  </StyledPaper>
                </Grid>
              </Grid>
              {/* 내용 설명 */}
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography component="h2" variant="subtitle2">
                    선택
                  </Typography>
                  <StyledPaper
                    variant="outlined"
                    sx={{
                      my: 1,
                      mx: "auto",
                      display: "flex",
                      flex: 1,
                      paddingBottom: "0",
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                      }}
                    >
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "8px", width: "calc(100% + 49px)" }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              sx={{ mt: 0.5 }}
                            >
                              계약기간
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <DatePic
                              component="dd"
                              // fromValue={from_dtm}
                              // toValue={to_dtm}
                              fromValue={from_dtm}
                              toValue={to_dtm}
                              fromInputFormat={"yyyy-MM-dd"}
                              fromMask={"____-__-__"}
                              toInputFormat={"yyyy-MM-dd"}
                              toMask={"____-__-__"}
                              fromFormat="YYYY/MM/DD"
                              toFormat="YYYY/MM/DD"
                              fromOnChange={(newValue) => setFrom_dtm(newValue)}
                              toOnChange={(newValue) => setTo_dtm(newValue)}
                              // sx={radioSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 계약기간 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              // gutterBottom
                            >
                              데이터 총
                              <Typography
                                component="span"
                                // variant="h5"
                                sx={{ display: "flex", fontSize: "inherit" }}
                              >
                                제공 횟수
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* <Stack
                              gap={0.5}
                              sx={{ display: "flex", flexDirection: "row" }}
                            > */}
                            <TextField
                              name="finalCnt"
                              // id="standard-number"
                              label="데이터 총 제공 횟수"
                              type="number"
                              // sx={{ m: 1, width: "25ch" }}
                              size="small"
                              value={finalCnt}
                              // onChange={(e) => setAmount(e.target.value)}
                              onChange={handleCountChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    회
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        {/* 데이터 총 제공 횟수 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              결제
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={paymentValue}
                              onChange={(value) => setPaymentValue(value)}
                              contractTypeLabels={PAYMENT_TYPE_LABELS}
                              // sx={DisabledSX}
                              sx={radioSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 결제 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{
                            // mt: 0.1,
                            mt: 1,
                          }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              종료시 처리
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={processingAtEndValue}
                              onChange={(value) =>
                                setProcessingAtEndValue(value)
                              }
                              contractTypeLabels={PROCESSING_AT_END_LABELS}
                              sx={radioSX}
                            />
                          </Grid>
                        </Grid>
                        {/* 종료시 처리 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "1px", mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              데이터 제공 방식
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* <Typography component="dd" variant="p" gutterBottom> */}
                            <ContractRadioBtn
                              typeValue={dataProvidedValue}
                              onChange={(value) => setDataProvidedValue(value)}
                              contractTypeLabels={DATA_PROVIDED_LABELS}
                              sx={radioSX}
                            />
                            {/* </Typography> */}
                          </Grid>
                        </Grid>
                        {/* 데이터 제공 방식 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              제공 형태
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={fileTypeValue}
                              onChange={(value) => setFileTypeValue(value)}
                              contractTypeLabels={FILE_TYPE_LABELS}
                              sx={radioSX}
                            />
                          </Grid>
                        </Grid>
                        {/*  제공 형태 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography component="dt" variant="subtitle2">
                              그룹 선택
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <SelectForm
                              value={selectedGroup}
                              onSelectChange={setSelectedGroup}
                              // options={sortOptions}
                              options={GroupOptions}
                              label="그룹 선택"
                              placeholder="그룹 선택"
                            />
                          </Grid>
                        </Grid>
                        {/* 그룹 ID */}
                        {/* <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{
                            mt: 1,
                            display: "flex",
                            width: "calc(100% + 49px)",
                          }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              계약 담당자
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs
                            // xs={12}
                            zeroMinWidth
                            // direction="column"
                            gap={2}
                            // spacing={3}
                            sx={{
                              // width: "100%",
                              display: "flex",
                              // flexGrow: "1",
                            }}
                          >

                            <Grid item container>
                              <IconTxtBtn
                                value={handleBtn}
                                onClick={handleClickOpen}
                              />
                            </Grid>
                            <Grid
                              item
                              container
                              zeroMinWidth
                              spacing={2}
                              sx={{ flexGrow: 1 }}
                            >
                              <Grid item xs={12} md={6}>
                                <InputDisable
                                  label="회사명"
                                  value={selectedCompanyValue}
                                  focused={
                                    selectedCompanyValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  InputLabelProps={
                                    selectedCompanyValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  disabled={
                                    !selectedCompanyValue ||
                                    (!onSelectedCoNMgrChange && true)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>

                                <InputDisable
                                  label="담당자명"
                                  value={selectedManagerValue}
                                  focused={
                                    selectedManagerValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  InputLabelProps={
                                    selectedManagerValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  disabled={
                                    !selectedManagerValue ||
                                    (!onSelectedCoNMgrChange && true)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid> */}
                        {/*  계약 담당자 */}
                      </Grid>
                      {/* </Box> IN */}
                    </Box>
                    {/* </Box> */}
                  </StyledPaper>
                  {/* 선택 paper */}
                </Grid>
                {/* 선택 인 */}
              </Grid>
              {/* 선택 */}
              <ContractAccordionPlatformSell
                onVaildate={onVaildate}
                preamble={preamble}
                appendix={appendix}
                preambleRef={preambleRef}
                appendixRef={appendixRef}
                // sellerCompany={company}
                sellerCompany={company}
                // buyerCompany={companyValue.label}
                buyerCompany={selectedCompanyValue}
                onPreambleChange={onPreambleChange}
                // 부속 변경 이벤트
                onAppendixChange={onAppendixChange}
                // buyerLabel={companyValue.label === false ? "구매회사" : ""}
                // 240116 S
                // 전문 변경 이벤트
                //
                // onPreambleChange={(event, newData) => {
                //   if (newData || companyData || setCompanyData) {
                //     setPreambleData(newData);
                //     // newData();
                //   }

                //   // setCompanyData();
                // }}
                // // 부속 변경 이벤트
                // onAppendixChange={(event, newData) => {
                //   if (newData || companyData || setCompanyData) {
                //     setAppendixData(newData);
                //     // newData();
                //   }
                // }}
                //
                // onPreambleChange={(e, newData) => {
                //   setPreambleData(newData);
                //   if (companyValue) {
                //     e();
                //   }
                // }}
                // // 부속 변경 이벤트
                // onAppendixChange={(e, newData) => {
                //   setAppendixData(newData);
                //   if (companyValue) {
                //     e();
                //   }
                // }}
                /////
                //               onPreambleChange={(e, newData) => {
                //                 setPreambleData(newData);
                // setCompanyData() => {
                //   setPreambleData(newData)
                // }}

                //               // 부속 변경 이벤트
                //               onAppendixChange={(e, newData) => {
                //                 setAppendixData(newData);
                //               }}
                ///////
                // company={companyData}
                // 240116 E
              />
            </Grid>
            <Box sx={{ pt: 2, pb: 2 }}>
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ justifyContent: "right", marginBottom: "8px" }}
              >
                <Grid item sx={{ alignItems: "right" }}>
                  <Typography
                    sx={{ color: "#717171" }}
                    component="h2"
                    variant="subtitle2"
                  >
                    판매자
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography
                    sx={{
                      color: "#717171",
                      fontWeight: "900",
                      textAlign: "right",
                    }}
                    component="h2"
                    variant="subtitle2"
                  >
                    {userName}
                  </Typography>
                </Grid>
              </Grid>
              {/* 판매자 */}
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ justifyContent: "right", marginBottom: "8px" }}
              >
                <Grid item sx={{ alignItems: "right" }}>
                  <Typography
                    sx={{ color: "#717171" }}
                    component="h2"
                    variant="subtitle2"
                  >
                    구매자
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography
                    sx={{
                      color: "#717171",
                      fontWeight: "900",
                    }}
                    component="h2"
                    variant="subtitle2"
                  >
                    {/* {userValue.label} */}
                    {selectedManagerValue}
                  </Typography>
                </Grid>
              </Grid>
              {/* 구매자 */}
            </Box>
            {/* </Grid> */}
            {/* 콘텐츠 in */}
          </DialogContent>
          {/* 콘텐츠 */}
          <DialogActions>
            {/* <Agreement /> */}
            <SaveUpdateButton
              did={userDID} // 저장버튼 필요
              contract_number={contractNumber}
              // changeUpdate={changeUpdate} // 등록버튼 필요
            />
            {/* 등록55
            </UpdateButton> */}
            <Button onClick={onClose}>닫기</Button>
          </DialogActions>
          {needAddInputOpen && (
            <ContractNeedAddInput
              open={needAddInputOpen}
              handleClose={handleClose}
              message={message}
              // recital={recital}
            />
          )}
        </Dialog>
      </Box>
      {isOpenModalHandlerSelect && (
        <ModalContractHandlerSelect
          isOpen={isOpenModalHandlerSelect}
          handleClose={handleClose}
          onFinalClickInfoChange={onSelectedCoNMgrChange}
          // onClose={handleClose}
        />
      )}
    </>
  );
};

export default ModalPlatformContractNew;
