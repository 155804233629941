// import * as React from "react";
import React, { useEffect, useState, useContext } from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import useErrorHandling from "assets/hooks/useError/useErrorQrHandling";
import SnackbarMessage from "./SnackbarMessage";
// import ListItemText from "@mui/material/ListItemText";
// import ListItem from "@mui/material/ListItem";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

// 추가 S
import { useNavigate } from "react-router-dom";
import client from "assets/js/commonApi";
import { Box } from "@mui/material";
import QRCode from "react-qr-code";

import { v4 } from "uuid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalContractQr({
  onClose,
  isOpen,
  handleClose,
  contract_sub_id,
  handleOnlyQrFileClose,
  company_info_type,
}) {
  const navigate = useNavigate();
  const [code, setCode] = useState({});
  const [limit, setLimit] = useState(180);
  const [isVerified, setIsVerified] = useState(false); // 검증 상태 추적
  const { handleError, snackbarOpen, snackbarMessage, handleCloseSnackbar } =
    useErrorHandling();
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");

  // useEffect(() => {
  //   if (limit === 0) {
  //     navigate("/contractlist");
  //     return;
  //   }
  // }, [limit, navigate]);
  // /sell/sign
  // /buy/sign
  useEffect(() => {
    const pulling_key = v4().toString();
    console.log("테스트용contract_sub_id", contract_sub_id);

    const signType = company_info_type === "seller" ? "sell/sign" : "buy/sign";
    console.log("##signType", signType);
    const c = {
      // type: "sign",
      type: signType,
      data: {
        pulling_key: pulling_key,
        // contract_sub_id: contract_sub_id,
        contract_sub_id: contract_sub_id,
        subscription_id: "",
      },
    };
    console.log("테스트용contract_sub_id", contract_sub_id);
    setCode(c);

    // const limitInterval = setInterval(() => {
    //   setLimit((time) => time - 1);
    // }, 1000);
    const limitInterval = setInterval(() => {
      setLimit((time) => {
        if (time > 0) {
          return time - 1;
        } else {
          clearInterval(limitInterval); // 시간이 0이 되면 interval 중지
          console.log("## 시간이 종료되었습니다.");
          // navigate("/contractlist");
          // window.location.reload();
          // handleClose();
          handleOnlyQrFileClose();
          return 0;
        }
      });
    }, 1000);
    const interval = setInterval(() => {
      client
        .create({
          timeout: 4000,
        })
        .get(
          "/pulling/sign",
          // {
          //   connection_id: "",
          // },
          {
            headers: { pulling_key: pulling_key },
          },
        )
        .then((response) => {
          clearInterval(interval);
          console.log("[QR SCAN]", response);
          if (handleClose) handleClose();
          // sessionStorage.setItem("access", response.headers.access);
          // sessionStorage.setItem("refresh", response.headers.refresh);

          // 계약서화면 이동하기
          // navigate("/contractlist");
        })
        .catch((reason) => {
          // console.log(reason);
          const continuePolling = handleError(reason); // 오류 처리
          if (!continuePolling) {
            clearInterval(interval); // 풀링 중단
          }
          // if (reason.response && reason.response.status !== 401) {
          //   clearInterval(interval); // 401이 아닌 다른 오류 발생 시 인터벌 종료
          //   handleError(reason); // 오류 처리
          // }
          //
          // clearInterval(interval);
          // handleError(reason);
          // showSnackbar("인증 과정에서 오류가 발생했습니다."); // 에러 발생 시 스낵바 메시지 표시
        });
    }, 3000);

    return () => {
      clearInterval(limitInterval);
      clearInterval(interval);
    };
  }, []);

  // company_info_type, contract_sub_id

  //   const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  // const handleError = (error) => {
  //   let message = "인증 과정에서 오류가 발생했습니다.";
  //   if (error.response) {
  //     switch (error.response.status) {
  //       // case 400:
  //       //   message = "잘못된 요청입니다. 입력을 확인해 주세요.";
  //       //   break;
  //       case 401:
  //         message = "인증 실패. 로그인 정보를 확인해 주세요.";
  //         break;
  //       case 403:
  //         message = "계약 담당자가 아닙니다.";
  //         break;
  //       case 500:
  //         message = "서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.";
  //         break;
  //       default:
  //         message = "네트워크 오류가 발생했습니다. 연결을 확인해 주세요.";
  //         break;
  //     }
  //   }
  //   // 오류 메시지가 설정되지 않은 경우에만 기본 메시지 설정
  //   if (!message) {
  //     message = "알 수 없는 오류가 발생했습니다.";
  //   }
  //   showSnackbar(message);
  // };

  // const showSnackbar = (message) => {
  //   setSnackbarMessage(message);
  //   setSnackbarOpen(true);
  // };

  // const handleCloseSnackbar = () => {
  //   setSnackbarOpen(false);
  // };
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ background: "#468ECD" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              QR 인증
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}
        <Box
          sx={{
            width: "100%",
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "20% 0 13%",
            // height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <img src={qrimage} alt="큐알 이미지" /> */}
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              // maxWidth: 64,
              maxWidth: 104,
              width: "100%",
            }}
          >
            <QRCode
              // size={256}
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={JSON.stringify(code)}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Box>
        <Typography sx={{ paddingBottom: 12, paddingLeft: 12 }}>
          인증시간 : {`${limit} 초`}
        </Typography>
        <SnackbarMessage
          open={snackbarOpen}
          handleClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={30000}
        />
      </Dialog>
    </div>
  );
}
