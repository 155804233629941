// import * as React from "react";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import Card from "@mui/material/";
// import CardActions from "@mui/material/";
// import CardContent from "@mui/material/";
// import CardMedia from "@mui/material/";
// import Button from "@mui/material/";
// import Typography from "@mui/material/";
// import Chip from "@mui/material/";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Chip,
  IconButton,
  Checkbox,
} from "@mui/material";
import {
  AccessTime,
  CloudDownload,
  Visibility,
  Favorite,
  FavoriteBorder,
  ShoppingCart,
  AddShoppingCart,
  CheckCircle,
  CheckCircleRounded,
  Close,
} from "@mui/icons-material";
import { SnackbarMessage } from "components/modal"; // 스낵바 팝업
import Stack from "@mui/material/Stack";

import { useUpdateLikeQuery } from "assets/hooks/useCategoryDetail/useUpdateLikeQuery"; // 좋아요
import { useAddToCartQuery } from "assets/hooks/useCategoryDetail/useAddToCartQuery"; // 장바구니

// "platform_id": "p_id_04",
// "data_title": "타이틀_4",
// "data_main_cat": "건강",
// "data_sub_cat": ["영양제", "영양제", "영양제"],
// "data_desc": "영양제 시장 분석 보고서.",
// "data_size": 300,
// "data_ext": "DOC",
// "data_price": 200000,
// "user_cart": "n",
// "view_cnt": 2000,
// "download_count": 90,
// "likes": "y",
// "like_cnt": 75,
// "reg_date": "2019-08-23"

// 데이터 이미지 data_image_url
// 카테고리 라벨 data_main_cat
// 타이틀 data_title
// 설명 data_desc
// 금액 data_price
// 태그 data_sub_cat
// 등록일자 reg_date
// 다운로드 수 download_count
// 조회수 view_cnt
// 좋아요 likes
// 좋아요 카운트 like_cnt
// 데이터 용량 data_size

// 이미지 파일 이름을 입력받아 배경색을 반환하는 함수
const getBackgroundColorByImageName = (imageName) => {
  // 파일 이름에서 확장자를 제거합니다 (옵션에 따라 필요한 경우에만 사용)
  const cleanImageName = imageName.replace(/\.\w+$/, "");
  // 배경색 객체에서 해당 이미지 이름에 해당하는 색상 코드를 찾습니다
  return backgroundColors[cleanImageName] || "#ffffff"; // 기본값으로 흰색을 반환
};

// const defaultImageUrl = "http://localhost:9001/static/images/logao.png";
// const imageUrl = dataImageUrl || defaultImageUrl;

// const backgroundColors = {
//   "category_img_01.png": "#7FC6E8", // 섬유제품 제조업: 하늘색
//   "category_img_02.png": "#F5A9B8", // 의복 및 모피제품 제조업: 분홍색
//   "category_img_03.png": "#A67C52", // 가죽, 가방 및 신발제품 제조업: 갈색
//   "category_img_04.png": "#5A3E36", // 목재 및 나무제품 제조업: 짙은 갈색
//   "category_img_05.png": "#F5F5DC", // 펄프, 종이 및 종이제품 제조업: 밝은 베이지색
//   "category_img_06.png": "#3D9A9B", // 인쇄 및 기록매제 복제업: 청록색
//   "category_img_07.png": "#7B7B7B", // 코크스, 연탄 및 석유정제품 제조업: 진회색
//   "category_img_08.png": "#FFEC47", // 화학물질 및 화학제품 제조업: 밝은 노란색
//   "category_img_09.png": "#89CFF0", // 의료용 물질 및 의약품 제조업: 밝은 파란색
//   "category_img_10.png": "#F5A455", // 고무제품 및 플라스틱 제조업: 오렌지색
//   "category_img_11.png": "#BEBEBE", // 비금속 광물제품 제조업: 회색
//   "category_img_12.png": "#B7AFA3", // 1차금속 제조업: 철갈색
//   "category_img_13.png": "#C0C0C0", // 금속가공제품 제조업: 실버색
//   "category_img_14.png": "#4A708B", // 전자부품, 컴퓨터, 영상 및 통신장비 제조업: 메탈릭 블루
//   "category_img_15.png": "#98FF98", // 의료, 정밀, 광학기기 및 시계 제조업: 밝은 민트색
//   "category_img_16.png": "#7AD3E6", // 전기장비 제조업: 밝은 청록색
//   "category_img_17.png": "#6B8E23", // 그 밖의 기계 및 장비 제조업: 녹색
//   "category_img_18.png": "#C41E3A", // 자동차 및 트레일러 제조업: 진한 빨간색
//   "category_img_19.png": "#008080", // 그 밖의 운송장비 제조업: 진한 청록색
//   "category_img_20.png": "#DEB887", // 가구 제조업: 따뜻한 목재색
//   "category_img_21.png": "#D3D3D3", // 그 밖의 제품 제조업: 연한 회색
//   "category_img_22.png": "#F4C2C2", // 산업용 기계 및 장비 수리업: 투스카니색
//   "category_img_23.png": "#f0f0f0", // 예시로 추가된 배경색, 실제 적용 시 적절한 산업을 매핑해주세요.
// };

const backgroundColors = {
  category_img_01: "#7FC6E8", // 섬유제품 제조업: 하늘색
  category_img_02: "#F5A9B8", // 의복 및 모피제품 제조업: 분홍색
  category_img_03: "#A67C52", // 가죽, 가방 및 신발제품 제조업: 갈색
  category_img_04: "#5A3E36", // 목재 및 나무제품 제조업: 짙은 갈색
  category_img_05: "#F5F5DC", // 펄프, 종이 및 종이제품 제조업: 밝은 베이지색
  category_img_06: "#3D9A9B", // 인쇄 및 기록매제 복제업: 청록색
  category_img_07: "#7B7B7B", // 코크스, 연탄 및 석유정제품 제조업: 진회색
  category_img_08: "#FFEC47", // 화학물질 및 화학제품 제조업: 밝은 노란색
  category_img_09: "#89CFF0", // 의료용 물질 및 의약품 제조업: 밝은 파란색
  category_img_10: "#F5A455", // 고무제품 및 플라스틱 제조업: 오렌지색
  category_img_11: "#BEBEBE", // 비금속 광물제품 제조업: 회색
  category_img_12: "#B7AFA3", // 1차금속 제조업: 철갈색
  category_img_13: "#C0C0C0", // 금속가공제품 제조업: 실버색
  category_img_14: "#4A708B", // 전자부품, 컴퓨터, 영상 및 통신장비 제조업: 메탈릭 블루
  category_img_15: "#98FF98", // 의료, 정밀, 광학기기 및 시계 제조업: 밝은 민트색
  category_img_16: "#7AD3E6", // 전기장비 제조업: 밝은 청록색
  category_img_17: "#6B8E23", // 그 밖의 기계 및 장비 제조업: 녹색
  category_img_18: "#C41E3A", // 자동차 및 트레일러 제조업: 진한 빨간색
  category_img_19: "#008080", // 그 밖의 운송장비 제조업: 진한 청록색
  category_img_20: "#DEB887", // 가구 제조업: 따뜻한 목재색
  category_img_21: "#D3D3D3", // 그 밖의 제품 제조업: 연한 회색
  category_img_22: "#F4C2C2", // 산업용 기계 및 장비 수리업: 투스카니색
  category_img_23: "#f0f0f0", // 예시로 추가된 배경색, 실제 적용 시 적절한 산업을 매핑해주세요.
};

export default function CategoryInfoCard({
  dataImageUrl, // 이미지
  alt,
  dataTitle, // 제목
  dataDesc, // 설명
  dataPrice, // 금액
  dataMainCat, // 메인 카테고리
  // data_sub_cat,
  dataDate, // 등록일자 ** 1번
  downloadCount, // 다운로드수 ** 2번
  viewCnt, // 조회수 ** 4번
  likeCount, // 좋아요 수 **3번

  dataExt, // 파일확장자 명
  userLike, // 좋아요 Y N
  userCart, // 장바구니 Y N
  userBuy, // 구매여부 Y N
  // platform_main_id,
  dataTitleOnClick, // 타이틀 클릭시 상세 페이지
  dataImageOnClick, // 이미지 클릭시 상세 페이지
  platform_main_id,
  // platform_main_id,

  dataSubCat, // 해시태그
  dataSize, // 용량

  dataCompanyName, // 회사이름 > 회사이름으로 검색해야되서
  onChange = (id, event) => {},
}) {
  // let access = sessionStorage.getItem("access");
  // let access = sessionStorage.getItem("access");
  // let refresh = sessionStorage.getItem("refresh");
  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("refresh");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("##pathname", pathname);
  // const loader = () => {
  //   navigate("/login");
  //   console.log("loader", loader);
  // };

  // const { platform_main_id } = useParams();
  const { mutate: updateLike } = useUpdateLikeQuery(); // 좋아요
  // const { mutate: addToCartMutation } = useAddToCartQuery(); // 장바구니
  // const { mutate: buyReqMutation } = usePlatformBuyReqQuery([]); // 구매 요청
  const addToCartMutation = useAddToCartQuery(); // 장바구니

  // const [likeStatus, setLikeStatus] = useState(details?.user_like || "N"); //
  const [likeStatus, setLikeStatus] = useState(userLike); // 좋아요
  const [isInCart, setInCart] = useState(userCart); // 장바구니

  console.log("##user_like", userLike);
  const [isPopupOpen, setPopupOpen] = useState(false); // 장바구니, 구매요청
  const [popupMessage, setPopupMessage] = useState(""); // 장바구니, 구매요청
  // const defaultImageUrl = "/path/to/default-image.jpg";
  //   // 이미지 URL에서 파일 이름만 추출
  //   const imageName = dataImageUrl.split("/").pop();
  // 이미지 URL에서 파일 이름만 추출하는 부분을 수정
  //   const imageName = dataImageUrl
  //     ? dataImageUrl.split("/").pop()
  //     : "defaultImage.png";
  //   // const imageName = dataImageUrl.split("/").pop();
  //   const backgroundColor = getBackgroundColorByImageName(imageName);
  // 이미지 URL에서 파일 이름만 추출하는 부분을 수정
  const imageName = dataImageUrl
    ? dataImageUrl.split("/").pop()
    : "defaultImage.png";
  // 배경색을 동적으로 설정
  const backgroundColor = getBackgroundColorByImageName(imageName);
  const cleanImageName = imageName.replace(/\.\w+$/, "");
  console.log("Original image name:", imageName);
  console.log("Clean image name:", cleanImageName);
  console.log("Resolved color:", backgroundColors[cleanImageName]);

  // 좋아요
  console.log("???likeStatus", likeStatus);
  const handleLikeClick = () => {
    if (access === "null") {
      navigate("/login", { replace: true });
      console.log("####@@@@loader", navigate);
      return; // 추가 실행 중지
    }
    // console.log("");
    const newLikeStatus = likeStatus === "Y" ? "N" : "Y";
    console.log("#@$likeStatus", likeStatus);
    updateLike(
      { platform_main_id, userLike: newLikeStatus },
      {
        onSuccess: () => {
          setLikeStatus(newLikeStatus); // 성공적으로 업데이트 되면 상태 업데이트
        },
      },
    );
  };
  console.log("##accessToken", access);
  console.log("##문자열accessToken", typeof access);
  // 장바구니
  // function redirectToLogin(navigate) {
  //   console.log("Redirecting to login due to missing access token");
  //   navigate("/login", { replace: true });
  // }

  const handleAddToCart = () => {
    // 토큰 존재 여부 확인
    // alert(typeof access);
    if (!access) {
      alert(access);
    }
    // console.log("##access", access);
    if (
      // !access ||
      // access === null ||
      // access === false ||
      // access === "" ||
      access === "null"
    ) {
      // if (access === null) {
      // navigate("/login"); // 토큰이 없으면 로그인 페이지로 리다이렉트
      navigate("/login", { replace: true });
      console.log("####@@@@loader", navigate);
      return; // 추가 실행 중지
    }

    // if (!access || access === null) {
    //   redirectToLogin(navigate);
    //   console.log("####@@@@loader", navigate);
    //   return;
    // }

    // // 토큰 존재 여부 확인
    // if (!accessToken) {
    //   navigate("/login"); // 토큰이 없으면 로그인 페이지로 리다이렉트
    //   return; // 추가 실행 중지
    // }
    if (userCart === "Y") {
      setPopupMessage("이미 장바구니에 담겨져 있습니다.");
      setPopupOpen(true);
      // setInCart(true);
    } else if (userBuy === "Y") {
      setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
      setPopupOpen(true);
    } else {
      addToCartMutation.mutate(platform_main_id, {
        onSuccess: (data) => {
          setPopupMessage("장바구니에 담겼습니다.");
          setPopupOpen(true);
          setInCart(true);
          // 여기에서 아이콘 변경 로직도 추가할 수 있습니다.
        },
        // onSuccess: (data) => {
        //   // 조건에 따라 적절한 메시지를 설정합니다.
        //   if (data.msg === "같은 회사 소속") {
        //     setPopupMessage("같은 회사 소속입니다.");
        //   } else {
        //     setPopupMessage("장바구니에 담겼습니다.");
        //   }
        //   setPopupOpen(true);
        // },
        onError: (error) => {
          // if (!access) {
          //   // Redirect to login page or open login modal
          //   navigate("/login");
          //   return;
          // } else if (error.response.status === 400) {
          //   setPopupMessage("같은 회사 소속입니다.");
          //   setPopupOpen(true);
          //   setInCart(false);
          // }
          if (error.response.status === 400) {
            setPopupMessage("같은 회사 소속입니다.");
            setPopupOpen(true);
            setInCart(false);
          }
        },
      });
    }
    console.log("loader", handleAddToCart);
    // 같은 회사 소속인 경우 메시지를 표시
    // if (addToCartMutation.data.msg === "같은 회사 소속") {
    //   setPopupMessage("같은 회사 소속입니다.");
    // }
    // if (details?.company_affiliation === "Y") {
    //   setPopupMessage("같은 회사 소속입니다.");
    //   setPopupOpen(true);
    // }
  };

  return (
    <Grid item xs={12} md={3}>
      <Card
        // onClick={onClick} // 전체 넘겨주는 온클릭
        sx={{
          maxWidth: 345,
          //   backgroundColor: getBackgroundColorByImageName(imageName),
        }}
      >
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
          }}
        >
          <Checkbox
            icon={<CheckCircleRounded sx={{ color: "#fff" }} />}
            checkedIcon={<CheckCircle sx={{ color: "#468dcc" }} />}
            // 버블링을 위한 onClick 이벤트
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              onChange(platform_main_id, event);
              event.stopPropagation();
            }}
          />
        </CardActions>
        {/* <Box> */}
        <Box
          sx={{
            backgroundColor,
            height: 140,
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: "100%",
              width: "auto",
              maxWidth: "100%",
              cursor: "pointer",
            }}
            // sx={{
            //   height: 140,
            //   width: "auto",
            //   margin: "0 auto",
            //   // p: 1,
            //   // backgroundColor: "#f0f0f0",
            //   // backgroundColor: getBackgroundColorByImageName(imageName), // 배경색 동적으로 설정
            //   // backgroundSize: "cover",
            // //   backgroundRepeat: "no-repeat",
            // //   backgroundPosition: "center",
            // //   backgroundSize: "contain",
            // }}
            //   component="img"
            image={dataImageUrl}
            // image={dataImageUrl || defaultImageUrl} // 이미지 URL이 없으면 기본 이미지 사용
            // alt={dataTitle}
            // alt={dataTitle || "Default Title"}
            alt={dataTitle || "Default Image"}
            onClick={dataImageOnClick}
            // alt={alt}
            // onError={(e) => (e.target.src = "/path/to/default-image.jpg")} // 로드 실패 시 대체 이미지 경로
          />
        </Box>
        {/* </Box> */}

        <CardContent>
          <Chip
            label={dataMainCat}
            variant="outlined"
            sx={{
              mt: 1,
              borderColor: "#468dcc",
              color: "#468dcc",
              p: 0,
              padding: "0",
              height: 24,
            }}
          />
          <Typography
            onClick={dataTitleOnClick}
            gutterBottom
            variant="h5"
            component="div"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {dataTitle}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            회사명 : {dataCompanyName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dataDesc}
            {/* {dataDesc || "No description available."} */}
          </Typography>
          <Typography variant="body2" color="text.primary" sx={{ mt: 2 }}>
            ${dataPrice}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            File Type: {dataExt}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Size: {dataSize}KB
          </Typography>
          {/* <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mt: 1, flexWrap: "wrap" }}
          >
            {dataSubCat.map((tag) => (
              <Chip
                //   key={index}
                label={tag}
                variant="outlined"
                sx={{ mt: 1, mr: 1 }}
              />
            ))}
          </Stack> */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mt: 1, flexWrap: "wrap" }}
          >
            {Array.isArray(dataSubCat) &&
              dataSubCat.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  variant="outlined"
                  sx={{ mt: 1, mr: 1 }}
                />
              ))}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <AccessTime fontSize="small" />
            <Typography variant="body2">{dataDate} </Typography>
            <CloudDownload fontSize="small" />
            <Typography variant="body2">{downloadCount} </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Visibility fontSize="small" />
            <Typography variant="body2">{viewCnt} Views </Typography>

            {/* <Chip
              icon={likeStatus === "Y" ? <Favorite /> : <FavoriteBorder />}
              // label="좋아요"
              // variant="outlined"
              // variant="filled"
              // variant={likeStatus === "Y" ? "container" : "outlined"}
              color="error"
              // onClick={handleLikeClick}
              // onClick={() => handleLikeClick(platform_main_id)}
              onClick={handleLikeClick}
            /> */}
            <FavoriteBorder />
            <Typography variant="body2">{likeCount}</Typography>
            {/* <Typography variant="body2">{likeCount} Likes </Typography> */}
          </Stack>
        </CardContent>
      </Card>
      <SnackbarMessage
        open={isPopupOpen}
        // onClose={() => setPopupOpen(false)}
        handleClose={() => setPopupOpen(false)}
        message={popupMessage}
        autoHideDuration={10000}
      />
    </Grid>
  );
}
