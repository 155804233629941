import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Typography, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchForm from "components/searchs/SearchForm";
import CategoryButtons from "components/viewContents/categoryContractView/CategoryButtons";
import CategoryInfoCard from "components/viewContents/CategoryInfoCard";
import CategorySearchAddOptions from "components/accordion/CategorySearchAddOptions";
// import TestOptions from "components/accordion/TestOptions";
import CategoryDataFilterBar from "components/viewContents/categoryContractView/CategoryDataFilterBar";
import { useSearchContractsQuery } from "assets/hooks/useCategory/useSearchContractsQuery";
import { useMainCatQuery } from "assets/hooks/useCategory/useSelectQuery";

// 날짜 라이브러리 S
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
// 날짜 라이브러리 E

// 리액트 쿼리
// import { useFetchContractSummaries } from "assets/hooks/useCategory/useFetchContractSummaries";
// import { useSearchParams } from "react-router-dom/dist";

export default function Category() {
  // const { platform_main_id } = useParams();
  const navigate = useNavigate();

  const [sortOption, setSortOption] = useState("reg_date"); // 등록일자 순
  const [searchParams, setSearchParams] = useSearchParams();

  // const [searchInitiated, setSearchInitiated] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all"); // 상태 추가: 선택된 카테고리

  // 240527 수정 전 코드 S
  const keyword = searchParams.get("q") || ""; // URL에서 검색어 가져오기
  const category = searchParams.get("category") || "all";
  // 240527 수정 전 코드 E

  // useEffect(() => {
  //   // URL에서 검색어(q)와 카테고리(category) 파라미터를 추출
  //   const keyword = searchParams.get("q") || "";
  //   const category = searchParams.get("category") || "all";

  //   // 이 값을 상태로 설정
  //   setSelectedCategory(category);

  //   // 필요한 경우 API를 호출하여 데이터를 검색/필터링
  //   fetchData({ keyword, category });
  // }, [searchParams]);

  // const keyword = searchParams.get("q");
  // 날짜 상태 관리
  const [fromDtm, setFromDtm] = useState(dayjs().subtract(30, "day"));
  const [toDtm, setToDtm] = useState(dayjs());
  // 가격
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  // const {
  //   data: searchApiQuery = [],
  //   isLoading,
  //   isError,
  //   error,
  // } = useSearchContractsQuery({
  //   keyword,
  //   // data_main_cat: selectedCategory, // 0524금요일
  //   // category: selectedCategory,
  //   from_dtm: fromDtm, // 추가된 매개변수
  //   to_dtm: toDtm, // 추가된 매개변수
  //   min_price: minPrice, // 추가된 매개변수
  //   max_price: maxPrice, // 추가된 매개변수
  //   // searchInitiated,
  //   sortOption,
  //   additionalOptions: {
  //     data_title: keyword,
  //     // data_main_cat: keyword,
  //     data_sub_cat: keyword,
  //     data_desc: keyword,
  //     company_name: keyword,
  //     // category: selectedCategory,
  //   },
  // });

  const additionalOptions = {
    data_title: keyword,
    data_sub_cat: keyword,
    data_desc: keyword,
    company_name: keyword,
    data_main_cat: selectedCategory,
  };

  // 유효한 날짜인지 확인 후 추가
  if (fromDtm && toDtm) {
    const formattedFrom = dayjs(fromDtm).format("YYYY-MM-DD");
    const formattedTo = dayjs(toDtm).format("YYYY-MM-DD");

    if (dayjs(formattedFrom).isValid() && dayjs(formattedTo).isValid()) {
      additionalOptions.from_dtm = formattedFrom;
      additionalOptions.to_dtm = formattedTo;
    }
  }

  // // 유효한 숫자인지 확인 후 추가
  // if (minPrice && maxPrice) {
  //   const parsedMin = parseInt(minPrice, 0);
  //   const parsedMax = parseInt(maxPrice, 0);

  //   if (!isNaN(parsedMin) && !isNaN(parsedMax)) {
  //     additionalOptions.min_price = parsedMin;
  //     additionalOptions.max_price = parsedMax;
  //   }
  // }

  // 유효한 숫자인지 확인 후 추가
  if (minPrice || maxPrice) {
    const parsedMin = parseInt(minPrice, 0);
    const parsedMax = parseInt(maxPrice, 0);

    if (!isNaN(parsedMin) || !isNaN(parsedMax)) {
      additionalOptions.min_price = parsedMin;
      additionalOptions.max_price = parsedMax;
    }
  }

  const {
    data: searchApiQuery = [],
    isLoading,
    isError,
    error,
  } = useSearchContractsQuery({
    keyword,
    // data_main_cat: selectedCategory, // 0524금요일
    // category: selectedCategory,
    // from_dtm: fromDtm, // 추가된 매개변수
    // to_dtm: toDtm, // 추가된 매개변수
    // min_price: minPrice, // 추가된 매개변수
    // max_price: maxPrice, // 추가된 매개변수
    // searchInitiated,
    sortOption,
    additionalOptions,
    // additionalOptions: {
    //   data_title: keyword,
    //   // data_main_cat: keyword,
    //   data_sub_cat: keyword,
    //   data_desc: keyword,
    //   company_name: keyword,
    //   // category: selectedCategory,
    //   data_main_cat: selectedCategory,
    //   ...(fromDtm &&
    //     toDtm && {
    //       from_dtm: dayjs(fromDtm).format("YYYY-MM-DD"), // 두 날짜가 모두 제공될 경우에만 추가
    //       to_dtm: dayjs(toDtm).format("YYYY-MM-DD"),
    //     }),
    //   ...(minPrice &&
    //     maxPrice && {
    //       min_price: parseInt(minPrice), // 두 가격이 모두 제공될 경우에만 추가
    //       max_price: parseInt(maxPrice),
    //     }),
    // },
  });

  // //최소 금액 최대 금액 -처리 되지 않게 수정
  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   // const countValue = value === "" ? 0 : Number(value);
  //   const countValue = value === "" ? 0 : Math.max(Number(value), 0);
  //   console.log("입력된 값:", countValue); // 입력값 로깅

  //   if (name === "minName") {
  //     setMinPrice(countValue !== null ? countValue : 0);
  //   } else if (name === "maxName") {
  //     setMaxPrice(countValue !== null ? countValue : 0);
  //   }
  // };

  //최소 금액 최대 금액 -처리 되지 않게 수정
  const handleCountChange = (event) => {
    const { name, value } = event.target;
    let countValue;

    if (value === "") {
      countValue = "";
    } else {
      if (value.startsWith("0") && value.lenth > 1) {
        countValue = value.replace(/^0+/, "");
      } else {
        countValue = value;
      }
      countValue = Math.max(Number(countValue), 0);
    }
    // 각 필드에 맞게 상태 업데이트
    if (name === "minName") {
      setMinPrice(countValue.toString()); // 문자열로 변환하여 상태 업데이트
    } else if (name === "maxName") {
      setMaxPrice(countValue.toString()); // 문자열로 변환하여 상태 업데이트
    }
  };

  // useEffect(() => {
  //   console.log("Loading status:", isLoading);
  //   if (isError) {
  //     console.error("An error occurred:", error);
  //   }

  //   if (searchApiQuery && searchApiQuery.length > 0) {
  //     console.log("Loaded data details:", searchApiQuery);
  //   } else {
  //     console.log("No data loaded or data is empty");
  //   }
  // }, [isLoading, isError, error, searchApiQuery]);
  // useEffect(() => {
  //   console.log("Loading:", isLoading);
  //   console.log("Error:", isError);
  //   console.log("Data:", searchApiQuery);

  //   if (isError) {
  //     console.error("Error Details:", error);
  //   }
  // }, [isLoading, isError, error, searchApiQuery]);

  // // 검색어가 있을 경우 검색 결과 요청
  // useEffect(() => {
  //   if (keyword) {
  //     setSearchParams({ q: keyword });
  //   }
  // }, [keyword, setSearchParams]);
  // const {
  //   data: searchApiQuery,
  //   isLoading,
  //   isError,
  //   error,
  // } = useSearchContractsQuery({
  //   keyword,
  //   searchInitiated,
  //   sortOption,
  //   additionalOptions: {}, // 필요한 경우 추가 옵션 포함
  // });

  // // api 쿼리
  // const {
  //   data: searchApiQuery,
  //   isLoading: isLoadingSearchApiQuery,
  //   isError: isErrorSearchApiQuery,
  //   error: errorSearchApiQuery,
  // } = useSearchContractsQuery(keyword, searchInitiated);

  // // 계약서 임시 데이터
  // const {
  //   // data: contractSummaries,
  //   data: contractSummaries = [], // 기본값으로 빈 배열을 설정
  //   isLoading: isLoadingSummaries,
  //   isError: isErrorSummaries,
  //   error: errorSummaries,
  // } = useFetchContractSummaries(searchInitiated);

  // // Combine isLoading and isError states
  // const isLoading = isLoadingSearchApiQuery || isLoadingSummaries;
  // const isError = isErrorSearchApiQuery || isErrorSummaries;
  // const error = errorSearchApiQuery || errorSummaries; // Assumes that only one error is shown at a time
  // // 데이터 디버깅
  //
  //
  // S 정상 ****
  // const sortedData = contractSummaries?.sort((a, b) => {
  //   if (sortOption === "reg_date") {
  //     return new Date(b.reg_date) - new Date(a.reg_date);
  //   }
  //   // 추가 정렬 옵션 구현 가능
  //   return 0;
  // });
  // E 정상 ****

  // 정상 코드 S ***********
  // const sortedData = contractSummaries?.sort((a, b) => {
  //   if (sortOption === "reg_date") {
  //     // Sort by registration date
  //     return new Date(b.reg_date) - new Date(a.reg_date);
  //   } else if (sortOption === "download") {
  //     // Sort by download count
  //     return b.download_cnt - a.download_cnt;
  //   } else if (sortOption === "like") {
  //     // Sort by like count
  //     return b.like_cnt - a.like_cnt;
  //   } else if (sortOption === "view") {
  //     // Sort by view count
  //     return b.view_cnt - a.view_cnt;
  //   }
  //   // Default case if no sort option matches
  //   return 0;
  // });
  // 정상 코드 E ***********

  const sortFunctions = {
    reg_date: (a, b) => new Date(b.reg_date) - new Date(a.reg_date),
    download: (a, b) => b.download_cnt - a.download_cnt,
    like: (a, b) => b.like_cnt - a.like_cnt,
    view: (a, b) => b.view_cnt - a.view_cnt,
  };

  const sortedData = searchApiQuery?.sort((a, b) =>
    (sortFunctions[sortOption] || (() => 0))(a, b),
  );

  // // 필터링 및 정렬 로직
  // const sortedData = searchApiQuery?.sort((a, b) =>
  //   sortOption === "reg_date" ? new Date(b.reg_date) - new Date(a.reg_date) : b[sortOption] - a[sortOption]
  // );

  const handleSearch = (keyword) => {
    setSearchParams({ q: keyword, category: "all" });
    // setSearchInitiated(true); // 검색을 시작했다는 표시
    // setSelectedCategory(null); // 검색 시 카테고리 선택 초기화
    setSelectedCategory("all"); // 이 부분이 중요합니다.
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  // console.log("Loaded data:", searchApiQuery);
  console.log("Loaded data:", searchApiQuery);
  console.log("Sorted data:", sortedData);

  const handleCategorySelect = (categoryValue) => {
    console.log("Selected Category:", categoryValue);
    setSelectedCategory(categoryValue);
    setSearchParams({ q: keyword, category: categoryValue }); // "all"도 URL에 명시적으로 포함
  };

  // const handleCategorySelect = (categoryValue) => {
  //   console.log("Selected Category:", categoryValue);
  //   setSelectedCategory(categoryValue);
  //   const newCategory = categoryValue === "all" ? null : categoryValue;
  //   setSearchParams({ q: keyword, category: newCategory });

  //   // setSearchParams({ q: keyword, category: category });

  //   // 필요한 추가 동작을 여기에 구현할 수 있습니다.
  // };

  // 데이터 필터링
  const filteredData =
    selectedCategory === "all"
      ? searchApiQuery
      : searchApiQuery.filter(
          (item) => item.data_main_cat === selectedCategory,
        );
  // const filteredData = selectedCategory
  //   ? searchApiQuery.filter((item) => item.data_main_cat === selectedCategory)
  //   : searchApiQuery;

  console.log("filteredData:", filteredData);

  const handleSearchWithOptions = (fromDtm, toDtm, min, max) => {
    const from = dayjs(fromDtm).format("YYYY-MM-DD");
    const to = dayjs(toDtm).format("YYYY-MM-DD");
    console.log("#### 카테고리Formatted fromDate:", from);
    console.log("#### 카테고리Formatted toDate:", to);
    // console.log("검색 시작일:", formattedFrom);
    // console.log("검색 종료일:", formattedTo);
    console.log("최소 가격:", min);
    console.log("최대 가격:", max);

    setSearchParams({
      q: searchParams.get("q"),
      category: searchParams.get("category") || "all",
      from: dayjs(from).format("YYYY-MM-DD"),
      to: dayjs(to).format("YYYY-MM-DD"),
      // from: from,
      // to: to,
      min: min,
      max: max,
      // min: minPrice !== null ? minPrice : undefined,
      // max: maxPrice !== null ? maxPrice : undefined,
    });
  };

  // 240725 code 수정
  // const handleSearchWithOptions = (fromDtm, toDtm, min, max) => {
  //   const from = dayjs(fromDtm).format("YYYY-MM-DD");
  //   const to = dayjs(toDtm).format("YYYY-MM-DD");
  //   console.log("#### 카테고리Formatted fromDate:", from);
  //   console.log("#### 카테고리Formatted toDate:", to);
  //   console.log("최소 가격:", min);
  //   console.log("최대 가격:", max);

  //   const queryParams = {
  //     q: searchParams.get("q"),
  //     category: searchParams.get("category") || "all",
  //     from: dayjs(from).format("YYYY-MM-DD"),
  //     to: dayjs(to).format("YYYY-MM-DD")
  //   };

  //   // 최소 금액 또는 최대 금액이 입력된 경우 해당 값을 추가
  //   if (min !== "") {
  //     queryParams.min = min;
  //   }
  //   if (max !== "") {
  //     queryParams.max = max;
  //   }

  //   setSearchParams(queryParams);
  // };
  // const handleSearchWithOptions = (fromDtm, toDtm, minPrice, maxPrice) => {
  //   const from = dayjs(fromDtm).format("YYYY-MM-DD");
  //   const to = dayjs(toDtm).format("YYYY-MM-DD");
  //   const min = minPrice;
  //   const max = maxPrice;

  //   console.log("#### 카테고리Formatted fromDate:", from);
  //   console.log("#### 카테고리Formatted toDate:", to);
  //   // console.log("검색 시작일:", formattedFrom);
  //   // console.log("검색 종료일:", formattedTo);
  //   console.log("최소 가격:", min);
  //   console.log("최대 가격:", max);
  //   // 함수 수정
  //   setSearchParams({
  //     q: searchParams.get("q"),
  //     category: selectedCategory,
  //     // from: fromDtm,
  //     // to: toDtm,
  //     from: from,
  //     to: to,
  //     min: minPrice,
  //     max: maxPrice,
  //   });
  // };

  const resetAll = () => {
    // setFromDtm(null);
    // setToDtm(null);
    //
    // 현재 날짜에서 30일 전으로 fromDtm 설정
    setFromDtm(dayjs().subtract(30, "day"));
    // 현재 날짜로 toDtm 설정
    setToDtm(dayjs());
    // setMinPrice(null);
    // setMaxPrice(null);
    setMinPrice("");
    setMaxPrice("");
    // 필요하다면 다른 상태들을 초기화하거나 새 검색을 수행
  };

  const handleDetailClick = (platform_main_id) => {
    navigate(`/category/${platform_main_id}`);
  };

  // 검색 함수
  // const handleSearchWithOptions = (from, to, min, max) => {
  //   const formattedFrom = dayjs(from).format("YYYY-MM-DD");
  //   const formattedTo = dayjs(to).format("YYYY-MM-DD");

  //   console.log("검색 시작일:", formattedFrom);
  //   console.log("검색 종료일:", formattedTo);
  //   console.log("최소 가격:", min);
  //   console.log("최대 가격:", max);

  //   setSearchParams({
  //     from: formattedFrom,
  //     to: formattedTo,
  //     min: min,
  //     max: max,
  //   });
  // };
  //
  // const handleSearchWithOptions = () => {
  //   const params = {
  //     keyword,
  //     data_main_cat: selectedCategory,
  //     fromDtm,
  //     toDtm,
  //     minPrice,
  //     maxPrice,
  //   };

  //   // API를 호출하는 예제 함수
  //   fetchSearchResults(params);
  // };

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   setSearchParams({ q: keyword }); // 입력된 검색어를 URL에 반영
  // };

  // 삭제 S
  // console.log("##sortedData", sortedData);
  // const [dataItems, setDataItems] = useState([{}]);

  // hook
  // const {
  //   data: contractSummaries,
  //   isLoading,
  //   isError,
  //   error,
  // } = useFetchContractSummaries({ keyword });
  // console.log("### data", contractSummaries);
  // 삭제 E

  // const { data: contractsData } = useSearchContractsQuery();

  // 카테고리만 박스로 하고
  // 카테고리 제외하고 나머지 >> 박스 > 컨테이너로 감싸기
  // 헤시태그 갯수 제한 15개이고, 두줄 넘어가면 호버 하면 보이게, 생략처리
  // #태그 추가해서 백으로 넘길지 아니면 그냥 넘길지 생각해서 넘기기

  // 리액트 쿼리 적용해서 데이터 불러옴 > 정렬 로직을 useEffect 내에 구현

  // 검색 필터링 등록일자순/ 추천순/ 인기순
  // 240418 0418 S 001
  // useEffect(() => {
  //   if (!contractSummaries) return;

  //   let sorted = [...contractSummaries];
  //   sorted = sorted.sort((a, b) => {
  //     // 정렬 로직 추가 (예: 최신 등록일 순)
  //     if (sortOption === "recent") {
  //       return new Date(b.reg_date) - new Date(a.reg_date);
  //     }
  //     return 0; // 다른 정렬 조건을 추가할 수 있음
  //   });

  //   setSortedData(sorted);
  // }, [contractSummaries, sortOption]);
  // 240418 0418 E 001

  const ContainerSx = styled(Container)(
    ({ theme, paddingTop = 8, paddingBottom = 6 }) => ({
      // pt: 8,
      // pb: 6,
      // paddingTop: theme.spacing(8),
      // paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(paddingTop), // 기본값은 8
      paddingBottom: theme.spacing(paddingBottom), // 기본값은 6
    }),
  );

  // 에러처리
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (isError) {
  //   return <div>An error occurred: {error?.message}</div>;
  // }

  // // 데이터 오류 처리 S
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (isError) {
  //   // 보다 사용자 친화적인 에러 메시지 제공
  //   return (
  //     <div>
  //       An error occurred: {error.response?.data?.message || "Unknown error"}
  //     </div>
  //   );
  // }
  // if (sortedData.length === 0 && searchInitiated) {
  //   return <div>No data found. Please try a different query.</div>;
  // }
  // // 데이터 오류 처리 E

  // if (!sortedData.length && searchInitiated) {
  //   return <div>No data found. Please try a different query.</div>;
  // }

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  //
  // if (isError) {
  //   return <div>An error occurred: {error?.message}</div>;
  // }

  console.log("##error?.message", error?.message);
  const formatTotalData = (num) => {
    return new Intl.NumberFormat("ko-KR").format(num);
  };
  return (
    <>
      <Box sx={{ pb: 6 }}>
        <Box>
          <ContainerSx
            maxWidth="lg"
            // sx={{ display: "flex", flexDirection: "row" }}
          >
            <Typography
              component="h2"
              variant="h3"
              sx={{
                display: "flex",
                margin: "32px 0",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              제조 데이터
              <Typography
                component="span"
                variant="h2"
                sx={{
                  fontWeight: "500",
                  color: "#468ECD",
                }}
              >
                안전한
              </Typography>
              거래 플랫폼
            </Typography>
          </ContainerSx>
        </Box>
        {/* 타이틀 Title Area End */}
        <Box>
          <ContainerSx maxWidth="lg" paddingTop={0} paddingBottom={0}>
            <SearchForm onSearch={handleSearch} />
          </ContainerSx>
        </Box>

        {/* 검색 Search form End */}
        <Box>
          <ContainerSx maxWidth="lg">
            <CategoryButtons
              onCategorySelect={handleCategorySelect}
              selectedCategory={selectedCategory}
            />
          </ContainerSx>
        </Box>
        {/* 카테고리 Category form End */}
        {/* <Box>
          <ContainerSx maxWidth="lg">
            <CategorySearchAddOptions />
          </ContainerSx>
          line Area
        </Box> */}
        <Box>
          {/* <ContainerSx maxWidth="lg" paddingTop={0} paddingBottom={0}> */}
          <CategorySearchAddOptions
            fromValue={fromDtm}
            toValue={toDtm}
            fromOnChange={setFromDtm}
            toOnChange={setToDtm}
            minPrice={minPrice}
            maxPrice={maxPrice}
            // minPrice={minPrice}
            // maxPrice={maxPrice}
            // minOnChange={setMinPrice}
            // maxOnChange={setMaxPrice}
            //
            // minOnChange={(e) => setMinPrice(e.target.value)}
            // maxOnChange={(e) => setMaxPrice(e.target.value)}
            //
            minName="minName"
            maxName="maxName"
            minOnChange={handleCountChange}
            maxOnChange={handleCountChange}
            onSearch={handleSearchWithOptions}
            resetForm={resetAll}
            // fromValue={fromDtm}
            // toValue={toDtm}
            // fromOnChange={setFromDtm}
            // toOnChange={setToDtm}
            // minOnChange={(value) => setMinPrice(value)}
            // maxOnChange={(value) => setMaxPrice(value)}
            // minPrice={minPrice}
            // maxPrice={maxPrice}
            // onSearch={handleSearchWithOptions}
          />
          {/* </ContainerSx> */}
          {/* line Area2 */}
        </Box>
        {/* 검색 추가 옵션 Search Add options End */}
        <Box>
          <ContainerSx maxWidth="lg" paddingTop={0} paddingBottom={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 0",
              }}
            >
              {/* 데이터 수량 표시 */}
              <Typography sx={{}}>
                전체
                <Typography
                  component="span"
                  // variant="p"
                  sx={{
                    fontWeight: "600",
                    color: "#468ECD",
                    fontSize: "17px",
                  }}
                >
                  &nbsp;
                  {/* {searchInitiated && sortedData && sortedData.length} */}
                  {/* <b>{searchInitiated && sortedData && sortedData.length}</b> */}
                  <b>{filteredData && formatTotalData(filteredData.length)}</b>
                  &nbsp;
                </Typography>
                건(한달 이내)
              </Typography>
              {/* 정렬 옵션 바 */}
              <CategoryDataFilterBar
                // sortOption={sortOption}
                // onSortChange={setSortOption}
                sortOption={sortOption}
                onSortChange={handleSortChange}
                options={[
                  { value: "reg_date", label: "등록일자순" },
                  { value: "download", label: "다운로드순" },
                  { value: "like", label: "좋아요순" },
                  { value: "view", label: "조회순" },
                ]}
              />
            </Box>
          </ContainerSx>
        </Box>
        <Box>
          <ContainerSx maxWidth="lg">
            {/* {searchInitiated && sortedData && sortedData.length > 0 ? ( */}
            {filteredData && filteredData.length > 0 ? (
              <Grid container spacing={4}>
                {/* {sortedData.map((item, index) => ( */}
                {filteredData.map((item, index) => (
                  <CategoryInfoCard
                    // onClick={() => handleDetailClick(item.platform_main_id)}
                    dataTitleOnClick={() =>
                      handleDetailClick(item.platform_main_id)
                    }
                    dataImageOnClick={() =>
                      handleDetailClick(item.platform_main_id)
                    }
                    detailContentClick={() =>
                      handleDetailClick(item.platform_main_id)
                    }
                    // onClick={handleDetailClick}
                    sx={{
                      display: "flex",
                      // flexDirection: "column",
                      // flexWrap: "wrap",
                      // gap: "20px",
                      // width: "calc(25% - 20px)",
                      // margin: "10px",
                    }}
                    // key={item.platform_id}
                    key={index}
                    // key={item.id}
                    // key={item.data_title}
                    dataImageUrl={item.data_img_url}
                    // dataImageUrl={item.data_image_url || "defaultImageUrl"} // 예시로 기본 이미지 추가
                    // dataImageUrl={item.data_image_url || defaultImageUrl}
                    dataTitle={item.data_title}
                    // dataImageUrl={item.data_image_url} // 데이터에 이미지 URL을 추가한다고 가정
                    // dataTitle={item.data_title}
                    dataDesc={item.data_desc || "No description available"} // 설명이 null 일 때 대체 텍스트
                    dataPrice={formatTotalData(item.data_price)}
                    // dataMainCat={item.data_main_cat} 카테고리 코드번호
                    dataMainCat={item.data_main_cat_desc}
                    dataSubCat={item.data_sub_cat}
                    dataDate={item.ins_dtm}
                    downloadCount={formatTotalData(item.download_cnt)}
                    viewCnt={formatTotalData(item.view_cnt)}
                    // likeCount={item.like_cnt} // 좋아요 카운트
                    initialLikeCount={formatTotalData(item.like_cnt)} // 좋아요 카운트
                    userLike={item.user_like} // 좋아요 여부 Y N
                    userBuy={item.user_buy} // 좋아요 여부 Y N
                    dataExt={item.data_ext}
                    userCart={item.user_cart}
                    dataSize={formatTotalData(item.data_size)}
                    dataCompanyName={item.company_name}
                    platform_main_id={item.platform_main_id} // platform_main_id 넘겨주기
                  />
                ))}
              </Grid>
            ) : (
              <Typography sx={{ mt: 3, textAlign: "center" }}>
                No data found
              </Typography>
            )}
          </ContainerSx>
        </Box>
        {/* Search form End */}
      </Box>
    </>
  );
}

//     display: "flex",
// flexDirection: "column",
// flexWrap: "wrap",
// gap: "20px",
// width: "calc(25% - 20px)",
// margin: "10px",
