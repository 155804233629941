// import React from 'react'
import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

const CategoryDataFilterBar = ({ onSortChange, sortOption, options }) => {
  //   const [sortOption, setSortOption] = useState("recent");

  const handleSortChange = (event) => {
    const newSortOption = event.target.value;
    // setSortOption(newSortOption);
    onSortChange(newSortOption); // 부모 컴포넌트로 선택된 정렬 옵션 전달.
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 0",
      }}
    >
      {/* <Typography variant="h6"> */}
      {/* 전체 데이터 건수: {dataItems.length} 건 */}
      {/* </Typography> */}
      <FormControl size="small" sx={{ minWidth: 120 }}>
        {/* <InputLabel id="sort-select-label">정렬 옵션</InputLabel> */}
        <Select
          labelId="sort-select-label"
          id="sort-select"
          value={sortOption}
          //   label="정렬 옵션"
          placeholder="정렬 옵션"
          onChange={handleSortChange}
        >
          {/* <MenuItem value={"reg_date"}>등록일자순</MenuItem>
          <MenuItem value={"download"}>다운로드순</MenuItem>
          <MenuItem value={"like"}>좋아요순</MenuItem>
          <MenuItem value={"view"}>조회순</MenuItem> */}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {/* <MenuItem value={"recommended"}>추천순</MenuItem>
          <MenuItem value={"cheapest"}>인기순</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CategoryDataFilterBar;
