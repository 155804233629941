import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

const addToCart = async (platform_main_id) => {
  return client.post("/user/cartupdate", {
    // user_cart: userCart,
    platform_main_id,
  });
};

export const useAddToCartQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addToCart, // 이곳에서 mutationFn을 명시적으로 지정
    onSuccess: (data) => {
      console.log("장바구니 상태가 성공적으로 업데이트되었습니다.");
      //   if (data.msg === "같은 회사 소속") {
      //     console.log("같은 회사 소속입니다.");
      //   }
      // queryClient.invalidateQueries(["platform-detail"]);
      // 이곳에 추가적으로 팝업을 띄우는 로직을 구현할 수 있습니다.
    },
    onError: (error) => {
      console.error("장바구니 업데이트 실패:", error);
    },
  });
};
