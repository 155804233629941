import React, { Fragment, useState, useEffect } from "react";
import useGroup from "views/temp/groupManagement/useGroup";
import {
  Autocomplete,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonBase,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { SnackbarMessage } from "components/modal";
import MyDialog from "components/modal/Dialog";

const GroupListComponent = ({
  list,
  onClick,
  onDoubleClick,
  onNewClick,
  selectGroup,
  newGroup,
  onNewGroupChange,
  onDeleteClick,
}) => (
  <>
    <List>
      {onNewClick ? (
        <ListItem divider>
          <ListItemIcon>
            <ButtonBase onClick={onNewClick}>
              <AddCircleIcon />
            </ButtonBase>
          </ListItemIcon>
          <TextField
            variant="standard"
            value={newGroup}
            onChange={onNewGroupChange}
            placeholder="신규 그룹명 입력"
          />
        </ListItem>
      ) : null}
      {list.map((item, index) => (
        <ButtonBase
          sx={{
            width: "100%",
            backgroundColor:
              selectGroup.group_id === item.group_id ? "#007BFF" : "",
          }}
          onClick={onClick(item)}
          onDoubleClick={onDoubleClick(item)}
        >
          <ListItem key={index} disablePaddingl divider>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary={item.group_name} />
            {new Set(["일반그룹", "결제그룹"]).has(item.group_name) === false &&
            onDeleteClick ? (
              <ListItemIcon sx={{ flexDirection: "row-reverse" }}>
                <ButtonBase onClick={onDeleteClick(item)}>
                  <RemoveCircleIcon />
                </ButtonBase>
              </ListItemIcon>
            ) : null}
          </ListItem>
        </ButtonBase>
      ))}
    </List>
  </>
);

const MyTextInput = ({
  options = [],
  value,
  label = "신규 관리자명 입력",
  onChange,
}) => (
  <Autocomplete
    sx={{ width: "100%" }}
    value={value}
    label={label}
    onChange={onChange}
    options={options}
    getOptionLabel={(user) => user?.user_name ?? ""}
    disablePortal
    autoHighlight
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        inputProps={{
          ...params.inputProps,
        }}
        size="small"
        variant="standard"
        sx={{ marginTop: -1, marginBottom: 1 }}
      />
    )}
  />
);

const ListComponent = ({
  options,
  group,
  list,
  onNewClick,
  onClick,
  value,
  onChange,
  label,
}) => (
  <List>
    {onChange ? (
      <ListItem disablePadding divider>
        <ListItemIcon>
          <ButtonBase onClick={onNewClick}>
            <AddCircleIcon />
          </ButtonBase>
        </ListItemIcon>
        <MyTextInput
          options={options}
          value={value}
          onChange={onChange}
          label={label}
        />
      </ListItem>
    ) : null}
    {list.map((item, index) => (
      <ListItem key={index} divider>
        <ListItemIcon>
          {item.group_user_type === "master" ? (
            <ManageAccountsIcon />
          ) : (
            <PersonIcon />
          )}
        </ListItemIcon>
        <ListItemText primary={item.user_name} />
        {item.group_user_type !== "master" && group?.group_name !== "일반그룹" && onClick ? (
          <ButtonBase onClick={onClick(item)}>
            <ListItemIcon sx={{ flexDirection: "row-reverse" }}>
              <RemoveCircleIcon />
            </ListItemIcon>
          </ButtonBase>
        ) : null}
      </ListItem>
    ))}
  </List>
);

const GroupManagement = () => {
  const {
    group: groups,
    manager,
    basic,
    user: users,
    dialog,
    snackBar,
    onLoadGroup,
    onLoadUser,
    onGroupClick,
    onGroupDoubleClick,
    onAddGroupClick,
    onDeleteGroupClick,
    onAddManagerClick,
    onDeleteManagerClick,
    onAddBasicClick,
    onDeleteBasicClick,
    onNewGroupChange,
  } = useGroup();

  useEffect(() => {
    onLoadGroup();
    onLoadUser();
  }, []);

  const isMaster = groups.list.some(
    (group) => group.group_user_type === "master",
  );
  const isBasic = groups.list.some(
    (group) => group.group_user_type === "basic",
  );
  const [userAccodionInfo, setUserAccodionInfo] = useState({
    isShowManager: false,
    isShowUser: false,
    managerMaxHeight: "130",
    userMaxHeight: "130px",
  });

  const userAccodionChange = () => {
    const maxHeight = {
      managerMaxHeight: "",
      userMaxHeight: "",
    };

    if (userAccodionInfo.isShowManager && !userAccodionInfo.isShowUser) {
      maxHeight.managerMaxHeight = "130px";
      maxHeight.userMaxHeight = "130px";
    }
    if (!userAccodionInfo.isShowManager && !userAccodionInfo.isShowUser) {
      maxHeight.userMaxHeight = "300px";
    }
    if (userAccodionInfo.isShowManager && userAccodionInfo.isShowUser) {
      maxHeight.managerMaxHeight = "300px";
    }

    setUserAccodionInfo((pre) => ({
      ...pre,
      ...maxHeight,
      isShowUser: !pre.isShowUser,
    }));
  };
  const managerAccodionChange = () => {
    const maxHeight = {
      managerMaxHeight: "",
      userMaxHeight: "",
    };

    if (!userAccodionInfo.isShowManager && userAccodionInfo.isShowUser) {
      maxHeight.managerMaxHeight = "130px";
      maxHeight.userMaxHeight = "130px";
    }
    if (userAccodionInfo.isShowManager && userAccodionInfo.isShowUser) {
      maxHeight.userMaxHeight = "290px";
    }
    if (!userAccodionInfo.isShowManager && !userAccodionInfo.isShowUser) {
      maxHeight.managerMaxHeight = "290px";
    }

    setUserAccodionInfo((pre) => ({
      ...pre,
      ...maxHeight,
      isShowManager: !pre.isShowManager,
    }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", height: "420px" }}>
      <div style={{ width: "33%", paddingRight: "16px" }}>
        <Typography
          sx={{
            height: "48px",
            paddingTop: "8px",
            paddingBottom: "8px",
            fontWeight: "bold",
          }}
        >
          그룹 정보 {`(${groups.list.length} 개)`}
        </Typography>
        <div
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #ccc",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          <GroupListComponent
            selectGroup={groups.select}
            newGroup={groups.new.group_name}
            list={groups.list}
            onClick={onGroupClick}
            onDoubleClick={onGroupDoubleClick}
            onNewClick={isMaster ? onAddGroupClick(groups.new) : null }
            onNewGroupChange={isBasic ? null : onNewGroupChange}
            onDeleteClick={isBasic ? null : onDeleteGroupClick}
          />
        </div>
      </div>
      <div
        style={{
          width: "33%",
          flexDirection: "column",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        <Typography
          sx={{
            height: "48px",
            paddingTop: "8px",
            paddingBottom: "8px",
            fontWeight: "bold",
          }}
        >
          소속 회원 {`(${manager.list.length + basic.list.length} 명)`}
        </Typography>
        <div style={{ height: "420px" }}>
          <MuiAccordion
            expanded={userAccodionInfo.isShowManager}
            onChange={managerAccodionChange}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="manager"
              id="manager"
            >
              <Typography>관리자 {`(${manager.list.length} 명)`}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                height: userAccodionInfo.managerMaxHeight,
                overflow: "auto",
              }}
            >
              <ListComponent
                options={users.list}
                group={groups.select}
                list={manager.list}
                onNewClick={onAddManagerClick(manager.select)}
                onClick={isBasic ? null : onDeleteManagerClick}
                value={manager.select}
                onChange={
                  isBasic
                    ? null
                    : (_, value) => {
                        console.log(
                          "[GroupManagement manager onChange]",
                          value,
                        );
                        manager.setManager(value);
                      }
                }
                label="신규 관리자 입력"
              />
            </AccordionDetails>
          </MuiAccordion>
          <MuiAccordion
            expanded={userAccodionInfo.isShowUser}
            onChange={userAccodionChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>사용자 {`(${basic.list.length} 명)`}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                height: userAccodionInfo.userMaxHeight,
                overflow: "auto",
              }}
            >
              <ListComponent
                options={users.list}
                group={groups.select}
                list={basic.list}
                onNewClick={onAddBasicClick(basic.select)}
                onClick={isBasic ? null : onDeleteBasicClick}
                value={basic.select}
                onChange={
                  isBasic
                    ? null
                    : (_, value) => {
                        console.log(
                          "[GroupManagement manager onChange]",
                          value,
                        );
                        basic.setBasic(value);
                      }
                }
                label="신규 사용자 입력"
              />
            </AccordionDetails>
          </MuiAccordion>
        </div>
      </div>
      <div style={{ width: "33%", paddingLeft: "16px" }}>
        <Typography
          sx={{
            height: "48px",
            paddingTop: "8px",
            paddingBottom: "8px",
            fontWeight: "bold",
          }}
        >
          전체 사용자 {`(${users.list.length} 명)`}
        </Typography>
        <div
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #ccc",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          <ListComponent list={users.list} />
        </div>
      </div>
      <MyDialog {...dialog} />
      <SnackbarMessage {...snackBar} />
    </div>
  );
};

export default GroupManagement;
