import React, { useState, useEffect } from "react";
import "./ContractProgress.css";
import { useNavigate } from "react-router-dom";
import { usePlatformBuyingQuery } from "assets/hooks/useMypage/contractProgress/usePlatformBuyingQuery";
import { usePlatformBuyingStepQuery } from "assets/hooks/useMypage/data/usePlatformBuyingStepQuery";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Typography, Button, Stack, Badge } from "@mui/material";
import {
  SaveAlt,
  KeyboardArrowRight,
  Task,
  DriveFileRenameOutlineOutlined,
  InsertChartOutlinedOutlined,
  EventRepeatOutlined,
  HourglassEmpty,
  RateReview,
  Assignment,
  ProductionQuantityLimits,
  Payment,
} from "@mui/icons-material";

import StepItemList from "components/viewContents/contractProgressView/StepItemList";

// 탭별 공통 스타일
const cardStyle = {
  display: "flex",
  flexDirection: "row",
};

const contentStyle = {
  backgroundColor: "#f9f9f9", // 배경색
  border: "1px solid #e0e0e0", // 테두리
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 그림자
  borderRadius: "8px", // 모서리 둥글게
  marginBottom: "16px", // 마진
  //
  padding: "20px", // 컨텐츠 패딩
  ml: "20px",
};

const StackSX = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  // justifyContent: "center", // 전체 스택을 중앙 정렬
};
const IconSx = {
  fontSize: 40,
  color: "#0000008a",
};
const BadgeSx = {
  "& .MuiBadge-badge": {
    backgroundColor: "#468ECD",
    color: "#fff",
    fontWeight: "600",
    fontSize: "1.125rem",
    p: 2,
    pt: 1.5, // 12px
    pl: 1,
    pr: 1,
    borderRadius: "15px",
  },
};
const ArrowSx = {
  fontSize: "3rem",
  color: "rgba(0, 0, 0, 0.12)",
  // color: "#ccc",
};

const StepTextSx = {
  fontSize: "0.875rem",
};

const PlatformBuyingTab = ({ isActive }) => {
  const queryClient = useQueryClient(); // queryClient 사용
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(null);
  console.log("## currentStep", currentStep);
  const { data: platformBuyingData, refetch } = usePlatformBuyingQuery(
    "CST03",
    { enabled: isActive },
  );

  // const { data: platformBuyingData } = usePlatformBuyingQuery(
  //   "CST03",
  //   { enabled: isActive }, // 1번 탭이 활성화될 때만 쿼리 활성화
  // );

  useEffect(() => {
    if (isActive) {
      refetch(); // isActive가 true일 때 데이터 새로고침
    }
  }, [isActive, refetch]);

  const {
    data: platformBuyingStepData,
    isLoading: isDataLoading,
    refetch: refetchPlatformBuyingStepData,
  } = usePlatformBuyingStepQuery(currentStep, {}, currentStep !== null);
  // useEffect(() => {
  //   if (currentStep) {
  //     refetchPlatformBuyingStepData();
  //   }
  // }, [currentStep, refetchPlatformBuyingStepData]);
  // 코드, 라벨 및 아이콘 매핑
  const steps = {
    300: {
      label: "구매 요청",
      icon: <ProductionQuantityLimits sx={IconSx} />,
      relatedCodes: ["300"],
    },
    301: {
      label: "등록 완료",
      icon: <Task sx={IconSx} />,
      relatedCodes: ["301", "302"],
    },
    303: {
      label: "플랫폼 서명",
      icon: <DriveFileRenameOutlineOutlined sx={IconSx} />,
      relatedCodes: ["303"],
    },
    304: {
      label: "구매자 서명",
      icon: <DriveFileRenameOutlineOutlined sx={IconSx} />,
      relatedCodes: ["304"],
    },
    305: {
      label: "결제 완료",
      icon: <Payment sx={IconSx} />,
      relatedCodes: ["305"],
    },
    306: {
      label: "데이터 등록 중",
      icon: <HourglassEmpty sx={IconSx} />,
      relatedCodes: ["306"],
    },
    307: {
      label: "데이터 등록 완료",
      icon: <InsertChartOutlinedOutlined sx={IconSx} />,
      relatedCodes: ["307"],
    },
    309: {
      label: "계약 종료 1개월 이내",
      icon: <EventRepeatOutlined sx={IconSx} />,
      relatedCodes: ["308", "309"],
    },
  };

  const findStepCount = (code) => {
    const step = steps[code];
    if (!step) return 0;

    return platformBuyingData
      ?.filter((d) => step.relatedCodes.includes(d.code_value))
      .reduce((total, item) => total + item.step_count, 0);
  };

  // const handleStepClick = (stepId) => {
  //   console.log(`Box clicked: ${stepId}`);
  //   setCurrentStep(stepId);
  // };
  const handleStepClick = (stepId) => {
    console.log(`##Box clicked: ${stepId}`);
    setCurrentStep(stepId);
    queryClient.invalidateQueries(["platform_buying_step_data"]);
  };

  console.log(
    "##@@platformBuyingData",
    platformBuyingData && platformBuyingData,
  );

  console.log(
    "##@@platformBuyingStepData",
    platformBuyingStepData && platformBuyingStepData,
  );

  const handleGoToSales = () => {
    navigate("/mypage/buy");
  };
  const Step = ({ code, label, icon, count, onClick }) => (
    <Box
      onClick={() => handleStepClick(code)}
      className="cp_StepBoxSx"
      // sx={{
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   cursor: "pointer",
      //   position: "relative",
      // }}
    >
      <Badge badgeContent={count} max={99} sx={BadgeSx}>
        {icon}
      </Badge>
      <Typography
        // sx={StepTextSx}
        className="cp_StepTextSx"
      >
        {label}
      </Typography>
    </Box>
  );
  return (
    <>
      <Stack spacing={4}>
        <Stack gap={1.5} sx={StackSX}>
          {Object.entries(steps).map(
            ([code, { label, icon }], index, array) => (
              // code < "400" ? (
              <React.Fragment key={code}>
                <Step
                  code={code}
                  label={label}
                  icon={icon}
                  count={findStepCount(code)}
                  onClick={handleStepClick}
                />

                {code < 309 && <KeyboardArrowRight sx={ArrowSx} />}
              </React.Fragment>
            ),
            // ) : null,
          )}
        </Stack>
      </Stack>
      <Typography
        variant="body1"
        sx={{ mt: 10, mb: 0.1, fontSize: "0.875rem" }}
      >
        데이터 등록완료 1개월 이후 데이터는 구매내역에서 확인 바랍니다.
        <Button
          onClick={handleGoToSales}
          // variant="contained"
          variant="text"
          color="primary"
          sx={{
            ml: 0.25,
            textDecoration: "underline",
            textUnderlineOffset: "3px",
            marginTop: "-4px",
          }}
        >
          구매내역 바로가기
        </Button>
      </Typography>
      <StepItemList
        stapData={platformBuyingStepData}
        contract_type="003"
        company_info_type="buyer"
        isDataLoading={isDataLoading}
        currentStep={currentStep}
      />
    </>
  );
};

export default PlatformBuyingTab;
