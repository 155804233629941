import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { CircularIndeterminate } from "components/loaders/CircularIndeterminate";

// 공통으로 사용하기 위해, 파일명 공통으로 작명

// const BootstrapInput = styled(TextField)(({ theme }) => ({
//   //   "label + &": {
//   //     marginTop: theme.spacing(3),
//   //   },
//   borderRadius: 4,
//   position: "relative",
//   backgroundColor: theme.palette.background.paper,
//   border: "1px solid #ced4da",
//   fontSize: 16,
//   padding: "10px 26px 10px 12px",
//   transition: theme.transitions.create(["border-color", "box-shadow"]),
//   input: {
//     borderWidth: "1px",
//     fontWeight: "bold",
//     "& .MuiOutlinedInput-input:focused": {
//       //   borderColor: 'green',
//       borderRadius: 4,
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//     },
//   },
//   //   MuiTextField: {
//   //     styleOverrides: {
//   //       root: {
//   //         "& .MuiOutlinedInput-root": {
//   //           "&:hover fieldset": {
//   //             borderColor: "rgba(0, 0, 0, 0.23)",
//   //           },
//   //           "&.Mui-focused fieldset": {
//   //             // borderColor: "rgba(0, 0, 0, 0.23)",
//   //             borderRadius: 4,
//   //             borderColor: "#80bdff",
//   //             boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//   //           },
//   //         },
//   //       },
//   //     },
//   //   },
//   //   "& label.Mui-focused": {
//   //     borderRadius: 4,
//   //     borderColor: "#80bdff",
//   //     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//   //   },
//   //   "& .MuiInput-underline:after": {
//   //     borderBottomColor: "green",
//   //   },
//   //   "& .MuiOutlinedInput-root": {
//   //     "& fieldset": {
//   //       borderColor: "red",
//   //     },
//   //     "&:hover fieldset": {
//   //       borderColor: "yellow",
//   //     },
//   //     "&.Mui-focused fieldset": {
//   //       borderColor: "green",
//   //     },
//   //   },
//   //   "& .MuiTextField-root": {
//   // borderRadius: 4,
//   // position: "relative",
//   // backgroundColor: theme.palette.background.paper,
//   // border: "1px solid #ced4da",
//   // fontSize: 16,
//   // padding: "10px 26px 10px 12px",
//   // transition: theme.transitions.create(["border-color", "box-shadow"]),
//   // Use the system font instead of the default Roboto font.
//   // "&:focus": {
//   //   borderRadius: 4,
//   //   borderColor: "#80bdff",
//   //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//   // },
//   //   },
//   //   "& .MuiInputBase-root": {
//   //     // borderRadius: 4,
//   //     // position: "relative",
//   //     // backgroundColor: theme.palette.background.paper,
//   //     // border: "1px solid #ced4da",
//   //     // fontSize: 16,
//   //     // padding: "10px 26px 10px 12px",
//   //     // transition: theme.transitions.create(["border-color", "box-shadow"]),
//   //     // // Use the system font instead of the default Roboto font.
//   //     "&:focus": {
//   //       borderRadius: 4,
//   //       borderColor: "#80bdff",
//   //       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//   //     },
//   //   },

//   "& fieldset": { border: "none" },
// }));

// const CssTextField = styled(TextField, {
//   shouldForwardProp: (props) => props !== "focusColor",
// })((p) => ({
//   // input label when focused
//   "& label.Mui-focused": {
//     color: p.focusColor,
//   },
//   // focused color for input with variant='standard'
//   "& .MuiInput-underline:after": {
//     borderBottomColor: p.focusColor,
//   },
//   // focused color for input with variant='filled'
//   "& .MuiFilledInput-underline:after": {
//     borderBottomColor: p.focusColor,
//   },
//   // focused color for input with variant='outlined'
//   "& .MuiOutlinedInput-root": {
//     "&.Mui-focused fieldset": {
//       borderColor: p.focusColor,
//     },
//   },
// }));

const style = {
  width: "500px",
  "& label.Mui-focused": {
    // color: "green",
    color: "#80bdff",
  },
  //   "&:hover fieldset:": {
  //     border: "none !important",
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "grey",
  //     outline: "none",
  //   },
  "& .MuiOutlinedInput-root": {
    // "&.Mui-hover fieldset": {
    //   //   borderColor: "green",
    //   borderRadius: 1,
    //   borderColor: "#80bdff",
    //   //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
    // "&:hover fieldset:": {
    //     border: "none !important",
    //   },
    "&.Mui-focused fieldset": {
      //   borderColor: "green",
      borderRadius: 1,
      borderColor: "#80bdff",
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
};

export default function InputFormDlg({
  open,
  value,
  onChange,
  handleConfirm,
  onClick,
  onClose,
  reUpdate,
  isMessageLoading,
}) {
  //   const [open, setOpen] = React.useState(false);
  // const [remark, setRemark] = React.useState(""); // 비고 내용을 위한 상태 추가
  // const handleConfirm = () => {
  //   reUpdate(remark); // 비고 내용을 인자로 넘겨줍니다.
  //   onClose(); // 다이얼로그 닫기
  // };

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={onClick}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={onClose}>
        {isMessageLoading && <CircularIndeterminate />}
        <DialogTitle>비고</DialogTitle>
        <DialogContent>
          <DialogContentText>비고 내용 입력하세요.</DialogContentText>
          <TextField
            sx={style}
            autoFocus
            margin="dense"
            id="name"
            label="내용 입력"
            // type="email"
            type="string"
            fullWidth
            variant="outlined"
            multiline
            //
            value={value}
            onChange={onChange}
            // onChange={(e) => setRemark(e.target.value)} // 입력값 상태 업데이트
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>확인</Button>
          <Button onClick={onClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
