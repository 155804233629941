import React, { useEffect, useState, useContext } from "react";
import { MessageContext } from "assets/js/syntax";
// import { client as axios } from "assets/js/axios";
import client from "assets/js/commonApi";
import { Box, Typography, Container } from "@mui/material";
import DataGridLayout from "components/grid/DataGridLayout";
// import { ModalContract } from "components/modal/ModalContract";
import { ModalDirectContract } from "components/modal/ModalDirectContract";
import { ModalPlatformContract } from "components/modal/ModalPlatformContract";
// import ModalContractNew from "components/modal/ModalContractNew";
// import SearchBarDoc from "components/SearchBarDoc";
import SearchBaseForm from "components/searchForm/SearchBaseForm";
import BasicTooltip from "components/tooltips/BasicTooltip";

// import DatePic from "components/picker/DatePic";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const SalesList = () => {
  // const { refreshData } = useContext(MessageContext); // 저장 등록 시 뷰화면 리프레쉬
  const { triggerRefresh } = useContext(MessageContext);
  const [rowsData, setRowsData] = useState([]); // 계약 목록 데이터
  const [isDataLoading, setIsDataLoading] = useState(false); // 추가된 로딩 상태
  const [stepOptions, setStepOptions] = useState([]); // 검색창 옵션 데이터
  const [stepValue, setStepValue] = useState({}); // 검색창 상태(진행상황) 값, label
  const [stepInputValue, setStepInputValue] = useState(""); // 검색창 옵션 선택(진행상황 입력값) 값
  const [contractNumber, setContractNumber] = useState(""); // 계약 목록 데이터 - 계약번호 - 상태값을 저장하여 검색 기능 구현시 사용
  const [selectContractNumber, setSelectContractNumber] = useState(); // 선택된 계약번호(계약서내 계약번호)
  const [isOpenModal, setIsOpenModal] = useState(false); // 계약서 모달창 open, close
  const [isOpenPlatformModal, setIsOpenPlatformModal] = useState(false);
  // const [isOpenNewModal, setIsOpenNewModal] = useState(false); // 신규 계약서 모달창 open, close

  // 날짜 상태 관리
  const [fromDtm, setFromDtm] = useState(dayjs().subtract(30, "day"));
  const [toDtm, setToDtm] = useState(dayjs());

  const onStepvalueChange = (_, value) => setStepValue(value);

  // 구매관리 동일한 코드
  const onStepInputChange = (_, value) => {
    setStepInputValue(value);
    if (!value) {
      setStepValue({ label: "전체", value: "" }); // clear 버튼 클릭 시 전체로 변경하는 로직
    }
  };
  console.log("stepInputValue", stepInputValue);

  const handleClear = (event) => {
    if (!stepInputValue && event.key === "Escape") {
      setStepValue({ label: "전체", value: "" }); // clear 버튼 클릭 시 전체조회로 변경하는 로직
    }
  };

  const onStepGetOptionLabel = (option) =>
    option && option.label ? option.label : ""; // 검색바 - 선택옵션 - Input Label

  // 계약 목록 데이터
  // stepValue, contractNumber 이용하여 서버 데이터 요청 > 결과를 rowsData 상태 업데이트

  // useEffect를 사용하여 컴포넌트가 마운트되었을 때 데이터를 불러옴
  // useEffect(() => {
  //   GetData(fromDtm, toDtm);
  // }, [refreshData]); // 빈 배열을 넘겨주면 컴포넌트가 마운트될 때만 실행됨
  useEffect(() => {
    GetData(fromDtm, toDtm);
  }, [triggerRefresh]); // 빈 배열을 넘겨주면 컴포넌트가 마운트될 때만 실행됨
  // 저장하거나 등록 버튼 눌렀을때 콜백 함수
  // const onSaveNUpdateCallback = () => {
  //   GetData(fromDtm, toDtm);
  // }

  const GetData = (fromDtm, toDtm) => {
    setIsDataLoading(true); // 데이터 로딩 시작
    // console.log("#### Received fromDate:", fromDtm); // 디버깅을 위한 로그
    // console.log("#### Received toDate:", toDtm);

    const from = dayjs(fromDtm);
    const to = dayjs(toDtm);

    console.log("#### Formatted fromDate:", from.format("YYYY-MM-DD"));
    console.log("#### Formatted toDate:", to.format("YYYY-MM-DD"));

    if (!from.isValid() || !to.isValid()) {
      console.error("Provided dates are not valid.");
      // console.error("####@@@@ Provided dates are not valid:", fromDtm, toDtm);
      return;
    }

    client
      .post("/sell/list", {
        // from_dtm: from,
        // to_dtm: to,
        to_dtm: to.format("YYYY-MM-DD"),
        from_dtm: from.format("YYYY-MM-DD"), // 서버에서 처리할 수 있는 문자열 형태로 전송

        // .post("/mdpt_agree/select/", {
        // contract_sub_id: contractNumber,
        step_type: stepValue ? stepValue.value : "", // stepValue가 null이 아닌 경우에만 접근
      })
      .then((res) => {
        // const rows = res.data.data ? res.data.data : [];
        if (!res.data || !res.data.data) {
          console.error("No data received or incorrect data path.");
          return;
        }
        const filteredRows = res.data.data.filter((item) => {
          // const insDate = dayjs(item.ins_dtm);
          const insDate = dayjs(item.ins_dtm);
          console.log("#### insDate:", insDate.format("YYYY-MM-DD"));
          console.log("#### insDate:from", from);
          console.log("#### insDate:to", to);
          // const insDate = dayjs(item.ins_dtm).format("YYYY-MM-DD");

          console.log("####@@@ insDate:from", insDate.isSameOrAfter(from));
          console.log("####@@@ insDate:to", insDate.isSameOrBefore(to));
          return (
            insDate.isValid() &&
            insDate.isSameOrAfter(from, "day") &&
            insDate.isSameOrBefore(to, "day")
          );
        });
        console.log("##@@@@ Filtered Rows:", filteredRows);
        setRowsData(
          filteredRows.map((item, index) => ({
            ...item,
            id: index + 1,
            dtm: `${item.from_dtm?.substring(0, 10)} ~ ${item.to_dtm?.substring(
              0,
              10,
            )}`,
            doc: "계약서",
          })),
        );

        console.log("New RowsData:", rowsData);
      })
      .catch((err) => {
        console.error("## Error in GetData:", err);
      })
      .finally(() => {
        setIsDataLoading(false); // 데이터 로딩 완료
      });
  };

  // 타입별로 조건문을 만들어 창을 띄우기
  // 지금은 대리님 바뿌니깐 담에 리스트데이터에 넣어달라고 요청하기
  const handleClickOpen = (params) => {
    console.log("## params", params);
    console.log("## params", params.row.contract_type);

    console.log("## handleClickOpen", handleClickOpen);
    // alert("handleClickOpen");
    setSelectContractNumber(params.row.contract_sub_id);

    if (params.row.contract_type === "001") {
      setIsOpenModal(true);
    }
    if (params.row.contract_type === "002") {
      setIsOpenPlatformModal(true);
    }
    // if (params.row.contract_type === "003") {
    //   setIsOpenPlatformModal(true);
    // }

    // setIsOpenPlatformModal(true);
    // setSelectDid(params.row.did_buyer);
  };

  // const handleClickPlatformOpen = (params) => {
  //   console.log("## params", params);
  //   console.log("## handleClickOpen", handleClickOpen);
  //   // alert("handleClickOpen");
  //   setSelectContractNumber(params.row.contract_sub_id);
  //   // setIsOpenModal(true);
  //   setIsOpenPlatformModal(true);
  //   // setSelectDid(params.row.did_buyer);
  // };

  const handleClose = () => {
    setIsOpenModal(false);
    // setIsOpenNewModal(false);
    setIsOpenPlatformModal(false);
    // GetData();
    // 새로운 날짜 범위 계산
    const newFromDtm = dayjs().subtract(30, "day");
    const newToDtm = dayjs();
    // 데이터 새로고침
    GetData(newFromDtm, newToDtm);
  };

  const handleClickNewOpen = (params) => {
    console.log("## params", params);
    console.log("## handleClickNewOpen", handleClickNewOpen);
    // setIsOpenNewModal(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.1,
    },
    {
      field: "contract_sub_id",
      headerName: "계약 번호",
      // type: "String",
      // width: 150,
      flex: 1.5,
      // editable: true,
      // disableColumnFilter: "true",
      renderCell: (params) => (
        <BasicTooltip title={params.row.contract_sub_id} />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "seller_name",
      headerName: "판매자",
      flex: 0.65,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.seller_name} />,
    },
    {
      field: "buyer_name",
      headerName: "구매자",
      // width: 100,
      // flex: 0.75,
      flex: 0.65,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.buyer_name} />,
    },
    {
      field: "contract_title",
      headerName: "제목",
      // type: "date",
      // width: 150,
      // flex: 1,
      flex: 1.65,
      // editable: true,
      renderCell: (params) => (
        <BasicTooltip title={params.row.contract_title} />
      ),
    },
    {
      field: "dtm",
      headerName: "계약기간",
      // type: "date",
      // width: 150,
      flex: 1.7,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.dtm} />,
    },
    // {
    //   field: "sign_seller_dtm",
    //   headerName: "구매자서명",
    //   // width: 80,
    //   flex: 0.65,
    //   renderCell: (params) => (
    //     <BasicTooltip title={params.row.sign_seller_dtm} />
    //   ),
    // },
    {
      field: "doc",
      headerName: "계약서",
      // width: 80,
      flex: 0.65,
    },
    {
      field: "code_name",
      headerName: "진행상황",
      // width: 100,
      // flex: 0.75,
      flex: 1.25,
      // flex: 0.55,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.code_name} />,
    },
  ];
  const datagridSx = {
    border: "0",
    "& .MuiDataGrid-root": {
      border: "0px !important",
    },
    "& .MuiDataGrid-main": {},
    "& .MuiDataGrid-root--densityStandard": {},
    "& .MuiDataGrid-withBorderColor": {
      border: 0,
    },
    '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
      // color: "blue",

      "& div": {
        // minHeight: "60px !important",
        // height: 60,
        // lineHeight: "59px !important",
      },
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        // "&:nth-of-type(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
        "&:nth-of-type(2n)": { backgroundColor: "rgba(238, 242, 245, 1)" },
      },
    },
    "& .MuiDataGrid-cell:hover:nth-of-type(7)": {
      cursor: "pointer !important",
      color: "red",
    },
    "& .MuiDataGrid-cell:nth-of-type(7)": {
      "& .MuiDataGrid-cellContent": {
        borderBottom: "1px solid #000",
      },
    },

    // pointer cursor on ALL rows
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "55px !important",
      maxHeight: "55px !important",
      lineHeight: "55px !important",
      borderTop: "#29314A 3px solid",
      borderBottom: "#29314A 3px solid",

      borderRadius: "0px !important",
    },
    "& .MuiDataGrid-footerContainer": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "40px !important",
      maxHeight: "40px !important",
      lineHeight: "40px !important",
    },
  };

  return (
    <>
      <Box>
        <Container sx={{ p: "0 !important" }}>
          <SearchBaseForm
            // onClick={GetData}
            onSearch={GetData}
            fromDtm={fromDtm}
            toDtm={toDtm}
            setFromDtm={setFromDtm}
            setToDtm={setToDtm}
            // onSearchNumerChanged={setContractNumber}
            // onNew={handleClickNewOpen}
            // value={stepValue}
            // stepOnChange={onStepvalueChange}
            // inputValue={stepInputValue}
            // onInputChange={onStepInputChange}
            // options={stepOptions}
            // getOptionLabel={onStepGetOptionLabel}
            // onClear={handleClear}
            // isOptionEqualToValue={(option, value) =>
            //   option.value === value.value ||
            //   (option.label === value.label && option.value === "")
            // }
          />
          <Box>
            <DataGridLayout
              rows={rowsData}
              // rows={partner}
              columns={columns}
              onCellClick={handleClickOpen}
              sx={datagridSx}
              isDataLoading={isDataLoading} // DataGridLayout에 로딩 상태 전달
            />
            {isOpenModal && (
              <ModalDirectContract
                // did={selectDid}
                contract_sub_id={selectContractNumber}
                // open={isOpenModal}
                company_info_type="seller"
                onClose={handleClose}
                onRefresh={triggerRefresh}
              />
            )}
            {isOpenPlatformModal && (
              <ModalPlatformContract
                // did={selectDid}
                contract_sub_id={selectContractNumber}
                // open={isOpenModal}
                company_info_type="seller"
                onClose={handleClose}
                onRefresh={triggerRefresh}
              />
            )}
            {/* {isOpenNewModal && <ModalContractNew onClose={handleClose} />} */}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default SalesList;
