const ContractHtmlToText = (html) => {
  const temp = document.createElement("div");
  temp.innerHTML = html;
  temp.querySelectorAll("label").forEach((label) => label.remove());
  temp.querySelectorAll(".MuiInputBase-input").forEach((input) => {
    input.innerText = input.value;
  });

  return temp.innerText;
};

const ContractElementToText = (element) => {
  if (element) {
    const cloneElement = element.cloneNode(true);

    cloneElement.querySelectorAll("label").forEach((label) => label.remove());
    cloneElement.querySelectorAll(".MuiInputBase-input").forEach((input) => {
      input.append(
        `[[${JSON.stringify({ [snakeToCamel(input.id)]: input.value })}]]`,
      );
    });

    return cloneElement.textContent;
  }
};

const GetContractValueObjectForText = (text) => {
  let match;
  let result = {};
  const regex = /\[\[((.|\s)*?)\]\]/g;

  while ((match = regex.exec(text)) !== null) {
    try {
      result = { ...result, ...JSON.parse(match[1]) };
    } catch (e) {
      console.error("[contractUtil]", "[GetContractValueObjectForText]", e);
      continue;
    }
  }

  return result;
};

const GetContractValueArrayForText = (text) => {
  let match;
  let result = [];
  const regex = /\[\[((.|\s)*?)\]\]/g;

  while ((match = regex.exec(text)) !== null) {
    try {
      result = [...result, ...Object.values(JSON.parse(match[1]))];
    } catch (e) {
      console.error("[contractUtil]", "[GetContractValueObjectForText]", e);
      continue;
    }
  }

  return result;
};

// 카멜 케이스 변환
const snakeToCamel = (str) =>
  str.toLowerCase().replace(/(_\w)/g, (m) => m.toUpperCase().substr(1));

export {
  ContractHtmlToText,
  ContractElementToText,
  GetContractValueObjectForText,
  GetContractValueArrayForText,
};
