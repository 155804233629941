import React, { useEffect, useState, useContext, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  // MuiGrid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  // Divider,
} from "@mui/material";
import Draggable from "react-draggable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import SelectCompany from "components/selectBox/SelectCompany";
import InputBasic from "components/inputBox/InputBasic";

import { MessageContext } from "assets/js/syntax";

import { GetContractValueObjectForText } from "assets/js/contractUtil"; // 240521 과장님 추가

// 텍스트 마디 간격 스타일
// const MuiGrid = styled(Grid)(({ theme }) => ({
//   width: "100%",
//   ...theme.typography.body2,
//   '& [role="separator"]': {
//     // margin: theme.spacing(0, 2),
//     marginTop: theme.spacing(8),
//   },
// }));

const GridSx = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "16px",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
    // marginTop: theme.spacing(8),
  },
}));

const BoxSx = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "16px",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
    // marginTop: theme.spacing(8),
  },
}));

// Item
const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  flexDirection: "row",
  // color: theme.palette.text.secondary,
}));

// 드래그 컴포넌트
function PaperComponent(props) {
  return (
    <Draggable
      handle="#MuiAccordionSummary-root"
      cancel={'[class*="MuiAccordionDetails-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ContractAccordionPlatformBuy({
  // onVaildate = () => null,
  preamble,
  preambleRef,
  appendix,
  appendixRef,
  sellerCompany,
  buyerCompany,
  buyerLabel,
  disabled,
  label,
  onPreambleChange, // 전문 Change 이벤트
  onAppendixChange, // 부속 Change 이벤트
  onVaildate,
  // company,
  // allValue,
  // allOnChange,
  // onChange,
  // inputValue,
  // onInputChange,
  // options,
}) {
  // 스타일 디테일

  const accordionSx = {
    "& .MuiAccordionSummary-root": {
      //   boxShadow: 0,
      //   padding: "0",
      //   margin: "0",
    },

    "& .MuiButtonBase-root": {
      boxShadow: 0,
      //   padding: "0",
      margin: "0",
      minHeight: "0",
    },
    "& .MuiAccordionDetails-root": {
      minHeight: "280px",
      height: "280px",
      overflow: "auto",
    },
  };

  // 240116 S
  // const [initPreamble, setInitPreamble] = useState(null);
  // const [initAppendix, setInitAppendix] = useState(null);
  const [initPreamble, setInitPreamble] = useState({}); // 기존 전문 데이터(JSON 형태)
  const [initAppendix, setInitAppendix] = useState({}); // 기존 부속 데이터(JSON 형태)
  // 240116 E

  // 여기 부분은 변수를 꼭 수정해야함 warn
  const [buyCompany, setBuyCompany] = useState();
  const [dRange, setDRange] = useState();
  const [cRange, setCRange] = useState();

  const [process, setProcess] = useState();
  const [processDataType, setProcessDataType] = useState();
  const [processMonth, setProcessMonth] = useState();
  const [processDRate, setProcessDRate] = useState();
  const [processDCount, setProcessDCount] = useState();
  const [processCRate, setProcessCRate] = useState();
  const [processCCount, setProcessCCount] = useState();

  // const memoizedOnVaildate = useCallback(onVaildate, [onVaildate]);
  // const memoizedOnVaildate = useCallback(
  //   (data) => {
  //     // onVaildate(data);
  //   },
  //   [onVaildate],
  // );

  useEffect(() => {
    // 바인딩 할 때 구문
    if (preamble) {
      const _preamble = GetContractValueObjectForText(preamble);

      setBuyCompany(_preamble.sellCompany);
      setCRange(_preamble.cRange);
      setDRange(_preamble.dRange);

      // 화면 렌더링 줄이기 위해 변수 추가
      setInitPreamble(GetContractValueObjectForText(preamble));

      console.log("[REF P buyCompany]", buyCompany);
      console.log("[REF P dRange]", dRange);
      console.log("[REF P cRange]", cRange);
    }

    if (appendix) {
      const _appendix = GetContractValueObjectForText(appendix);
      setProcess(_appendix.process);
      setProcessDataType(_appendix.processDataType);
      setProcessMonth(_appendix.processMonth);
      setProcessDRate(_appendix.processDRate);
      setProcessDCount(_appendix.processDCount);
      setProcessCRate(_appendix.processCRate);
      setProcessCCount(_appendix.processCCount);

      setInitAppendix(_appendix);

      console.log("[REF X process]", process);
      console.log("[REF X processDataType]", processDataType);
      console.log("[REF X processMonth]", processMonth);
      console.log("[REF X processDRate]", processDRate);
      console.log("[REF X processDCount]", processDCount);
      console.log("[REF X processCRate]", processCRate);
      console.log("[REF X processCCount]", processCCount);
    }
    // }, [preamble, appendix, company]);
  }, [preamble, appendix]);
  // 계약서 라벨

  // const onVaildateCallback = useCallback(() => {
  //   onVaildate({
  //     dataType,
  //     day,
  //     dRange,
  //     cRange,
  //     sDay,
  //     count,
  //     year,
  //     vDay,
  //     rDay,
  //     court,
  //     process,
  //     processDataType,
  //     processMonth,
  //     processDRate,
  //     processDCount,
  //     processCRate,
  //     processCCount,
  //   });
  // }, []);

  useEffect(() => {
    if (initAppendix && initPreamble) {
      console.log("initRendering", initAppendix, initPreamble);
      onVaildate({
        ...initAppendix,
        ...initPreamble,
      });
    }
  }, [initAppendix, initPreamble]);
  // if (initAppendix && initPreamble) {
  //   console.log("initRendering", initAppendix, initPreamble);
  //   onVaildate({
  //     ...initAppendix,
  //     ...initPreamble,
  //   });
  // }

  const {
    // label01,
    // label02,
    // label03,
    // label04,
    label05,
    label06,
    label07,
    label08,
    label09,
    label10,
    label11,
    label12,
    label13,
    label14,
    label15,
    label16,
    label17,
    label18,
    label19,
    label20,
    label21,
  } = useContext(MessageContext);

  // 예시 추후 삭제 2번 - 여기서부터 표준계약서(부속)
  // 총 17개
  let preContractContent = [
    "기존 데이터 명 입력 00", // 00
    "기존 데이터 명 입력 01", // 01
    "기존 데이터 명 입력 02", // 02
    "기존 데이터 명 입력 03", // 03
    "기존 데이터 명 입력 04", // 04
    "기존 데이터 명 입력 05", // 05
    "기존 데이터 명 입력 06", // 06
    "기존 데이터 명 입력 07", // 07
    "기존 데이터 명 입력 08", // 08
    "기존 데이터 명 입력 09", // 09
    "기존 데이터 명 입력 10", // 10
    "기존 데이터 명 입력 11", // 11
    "기존 데이터 명 입력 12", // 12
    "기존 데이터 명 입력 13", // 13
    "기존 데이터 명 입력 14", // 14
    "기존 데이터 명 입력 15", // 15
    "기존 데이터 명 입력 16", // 16
  ];

  // ****** 추가 240116 S
  // Method
  // 변경된 데이터의 배열 가져오기
  // 사용자가 입력할 때 이벤트 호출
  // 전문 변경 이벤트
  const setOnPreambleChangeEvent = (event, newData) => {
    // 현재 입력된 데이터
    const currentData = {
      buyerCompany,
      cRange,
      dRange,
    };
    // 새로 입력된 데이터
    newData = { ...currentData, ...newData };

    const preambleData = [newData.buyerCompany, newData.dRange, newData.cRange];

    // 1) 삭제하기
    // const formattedData = preambleData.map((value) => {
    //   // 따옴표로 감싸진 문자열은 그대로 두고, 숫자 등은 그대로 반환
    //   return typeof value === "string" ? `'${value}'` : value;
    // });
    // const formattedString = `[${formattedData.join(",")}]`;
    // onPreambleChange(event, formattedString);

    //

    // // 2) 데이터 불필요하게 변화요구되어 수정한것
    // const formattedData = preambleData.map((value) => {
    //   // 따옴표가 있는 경우 제거
    //   return typeof value === "string" ? value.replace(/'/g, "") : value;
    // });
    // const formattedDataEmpty = formattedData.map((value) =>
    //   !value ? "" : value,
    // );
    // // 백단에서 빈문자열이 들어갔을때 처리해줘야 한다. 왜냐면 넘겨주는 배열에서 쌍따옴표를 제외시켜 빈값을 주기가 어렵다.
    // // 그래서 데이터중에 하나라도 입력이 안되어 있으면 Null값으로 반환한다.
    // const formattedString = `[${formattedDataEmpty.join(",")}]`;
    // onPreambleChange(event, formattedString);

    // 3) 데이터 불필요하게 변화요구되어 수정한것

    // 코드 수정 240603 _ 과장님이 수정해줌
    // const formattedData = preambleData.map(
    //   (value) => `"${value ? value : ""}"`,
    // );
    // const formattedString = `[${formattedData.join(",")}]`;

    // onPreambleChange(event, formattedString);
    const formattedData = preambleData.map((value) => `${value ? value : ""}`);
    // const formattedString = `[${formattedData.join(",")}]`;

    onPreambleChange(event, formattedData);
  };

  // 부속 변경 이벤트
  const setOnAppendixChangeEvent = (event, newData) => {
    const currentData = {
      process,
      processDataType,
      processMonth,
      processDRate,
      processDCount,
      processCRate,
      processCCount,
    };

    newData = { ...currentData, ...newData };

    const appendixData = [
      newData.process,
      newData.processDataType,
      newData.processMonth,
      newData.processDRate,
      newData.processDCount,
      newData.processCRate,
      newData.processCCount,
    ];

    // // 2) 데이터 불필요하게 변화요구되어 수정한것
    // const formattedData = appendixData.map((value) => {
    //   // 따옴표가 있는 경우 제거
    //   return typeof value === "string" ? value.replace(/'/g, "") : value;
    // });
    // const formattedDataEmpty = formattedData.map((value) =>
    //   !value ? "" : value,
    // );
    // const formattedString = `[${formattedDataEmpty.join(",")}]`;
    // onAppendixChange(event, formattedString);
    // const formattedData = appendixData.map(
    //   (value) => `"${value ? value : ""}"`,
    // );
    // const formattedString = `[${formattedData.join(",")}]`;

    // onAppendixChange(event, formattedString);
    const formattedData = appendixData.map((value) => `${value ? value : ""}`);
    // const formattedString = `[${formattedData.join(",")}]`;

    onAppendixChange(event, formattedData);
  };
  // ****** 추가 240116 E
  const countValue = (event) => {
    return event.target.value === ""
      ? 0
      : Math.max(Number(event.target.value), 0);
  };
  return (
    <>
      <div ref={preambleRef}>
        <Accordion
          // value={allValue}
          // onChange={allOnChange}
          sx={accordionSx}
          PaperComponent={PaperComponent}
          // sx={{ boxShadow: 0, padding: "0", margin: "0" }}
        >
          <AccordionSummary
            //   sx={accordionSx}
            //   sx={{ padding: "0", margin: "0", minHeight: "0" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography component="h2" variant="subtitle2">
                  플랫폼운영자와 데이터제공자 간의 표준계약서
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {/* <StyledPaper> */}
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제1조(목적)
                <br />본 계약은 (주)비피앤솔루션 (이하 '플랫폼운영자'라고
                한다)와 플랫폼 운영자가 운영하는 플랫폼'U-STC' (이하
                '제조데이터보호거래플랫폼'라고 한다)에서 구매회원으로 가입하여
                데이터상품 등의 전자상거래 관려 판매 서비스 및 기타 서비스(이하
                '서비스')를 이용하는{" "}
                <InputBasic
                  id="buyer_company"
                  // label={"데이터 명 입력"}
                  label={"구매회사"}
                  value={buyerCompany}
                  disabled={true}
                />{" "}
                (이하 '데이터이용자'라고 한다) 간의 권리와 의무, 데이터
                활용형태에 관한 정의, 그 밖에 필요한 사항을 규정하는 것을
                목적으로 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제2조{"("}정의{")"}
                <br />본 계약에서 사용하는 용어의 뜻은 다음과 같다.
                <br />
                &#9312;“데이터”란 데이터베이스, 전자화된 파일 등 광(光) 또는
                전자적 방식으로 처리된 자료 또는 정보를 말한다.
                <br />
                &#9313;“데이터상품”이란 데이터제공자와 데이터이용자 사이의
                거래에 제공되는 데이터, API, 이미지, 동영상 등 일체의 데이터를
                말한다.
                <br />
                &#9314;“데이터제공자”는 플랫폼운영자가 운영하는
                제조데이터보호거래플랫폼에 판매회원으로 가입하 여
                제조데이터보호거래플랫폼을 이용하여 데이터 및 관련 부가서비스를
                판매, 위탁 및 제공하는 사 업자를 말한다.
                <br />
                &#9315;“데이터이용자”는 플랫폼운영자가 운영하는
                제조데이터보호거래플랫폼에 구매회원으로 가입하 여
                데이터제공자로부터 데이터를 구매하고 제공받는 자를 말한다.
                <br />
                &#9316;"데이터중개"란 제조데이터보호거래플랫폼을 이용하여거래
                당사자 간의 데이터판매를 알선, 중 개하는 행위를 말한다.
                <br />
                &#9317;“제조데이터보호거래플랫폼”이란 컴퓨터 등과 정보통신설비를
                이용하여 데이터 및 재화 등을 거 래할 수 있도록 설정된 가상의
                영업장을 말한다.
                <br />
                &#9318;“제공데이터”란 본 계약에 따라 데이터제공자가
                플랫폼운영자에게 판매를 통한 활용을 위 해 제공하는 데이터로서,
                부속문서에 기재된 것을 말한다.
                <br />
                &#9319;“파생데이터”란 데이터이용자가 제공데이터를 가공, 분석,
                편집 등을 통하여 새로 또는 2차적으로 생성한 데이터 등을 말한다.
                <br />
                &#9320;“결합데이터”란 데이터이용자가 제공데이터에 파생데이터 및
                기존데이터를 결합하여 새로 또는 2차 적으로 생성한 데이터 등을
                말한다.
                <br />
                &#9321;“이용권한”이란 제공데이터, 또는 파생데이터 또는
                결합데이터를 이용할 수 있는 권한을 말 한다.
                <br />
                &#9322;“지식재산권”이란 특허, 실용신안, 디자인, 상표, 컴퓨터
                프로그램을 포함한 저작권, 데 이터베이스권, 엉업비밀 등을 포함한
                법상 보호 가능한 일체의 무형재산에 대한 권리로서 출원, 신청 및
                등록을 모두 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제3조(계약서의 명시)
                <br />
                &#9312;플랫폼운영자는 본 계약서의 내용을 데이터제공자가 알 수
                있도록 제조데이터보호거래플랫폼 홈 페이지에 게시하거나 그 밖에
                데이터제공자가 쉽게 확인할 수 있는 방법으로 알려야 한다.
                <br />
                &#9313;플랫폼운영자는 데이터제공자가 본 계약서의 내용에 관하여
                질의 및 응답할 수 있도록 적절한 절 차를 마련하여야 한다.
                <br />
                &#9314;제공데이터, 파생데이터 및 결합데이터의 이용은
                데이터이용자와 데이터제공자 간의 제약사 상을 따라야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제4조(계약의 해석)
                <br />본 계약서에서 정하지 아니한 사항과 계약서의 해석에
                관하여는 ‘약관의 규제에 관한 법률’, ‘전자상거래 등에서의
                소비자보호에 관한 법률’, ‘정보통신망 이용촉진 및 정보보호 등에
                관한 법률’, ‘개인정보보호법’, ‘신용정보의 이용 및 보호에 관한
                법률’, ‘저작권법’ 등 관계 법령 및 상관습에 따른다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제5조(제공데이터의 이용허락)
                <br />
                &#9312;데이터제공자는 데이터이용자에 대하여, 제공데이터를 본
                계약의 유효기간 내에서 본 계약 목적의 범위 내에서만 이용하는
                것을 허락한다.
                <br />
                &#9313;데이터이용자는 본 계약에서 명시적으로 규정된 것을
                제외하고는 제공데이터에 대하여 내용 의 정정, 추가 또는 삭제를
                행할 수 있는 권한이 없다.
                <br />
                &#9314;데이터이용자는 데이터제공자의 서면에 의하여 사전 승낙이
                없는 한 본 계약 목적 이외의 목적으로 제공데이터를가공, 분석,
                편집, 통합 기타의 이용을 할 수 없으며, 제공데이터를 제3자
                (데이터이용자가 법인인 경우 그계열사, 관련회사도 제3자에
                포함)에게 공개, 제공, 누설하여서는 아니된다.
                <br />
                &#9315;제공데이터에 관한 지식재산권(데이터베이스에 관한 권리를
                포함하고 이에 제한되지 않음)은 데이터제공자에게 귀속된다. 그러나
                제공데이터 중 제3자에게 지식재산권이 귀속되는 것은 그러하지
                아니한다.
                <br />
                &#9316;본 조의 이용허락은 데이터제공자의 제공데이터의 이용을
                방해하지 아니한다. &#9317;데이터제공자는 데이터이용자 이외의
                제3자에게도 본 조의 이용허락을 할 수 있다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제6조(파생데이터)
                <br />
                &#9312;데이터이용자는 파생데이터의 이용권한을 가지며,
                데이터이용자는 데이터제공자에 대하여
                <InputBasic
                  id="d_range"
                  type="number"
                  min={0}
                  // label={"범위 입력"}
                  label={label07}
                  value={dRange}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      dRange: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange: newCountValue,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setDRange(newCountValue);
                  }}
                  disabled={disabled}
                />
                의 범위에서 [ 의 목적의 범위 에서] 파생데이터를 무상[유상]으로
                이용하는 것을 허락한다.
                <br />
                &#9313;데이터이용자가 파생데이터를 이용함에 따라 발생하는 발명,
                고안, 창작 및 영업비밀 등에 대한 지식재산권은 데이터이용자에게
                귀속한다. 단, 데이터이용자는 데이터제공자에 대하여 해당
                지식재산권에 대한 무상[유상]의 실시 허락 을 한다.
                <br />
                &#9314;제1항 및 제2항의 이용 허락의 조건에 대한 구체적 내용은
                별도로 협의하여 부속문서에 포함 한다.
                <br />
                &#9315;데이터이용자가 파생데이터를 이용하여 실시한 사업 또는
                서비스에 따라 매출을 얻을 때는, 데이터이용자가 얻은 매출액의
                일부분을 데이터제공자에게 지불한다.지급조건 및 비율에 대해서는
                데이터제공자 및 데이터이용자 사이의 별도의 협의하에 부속문서에
                포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제7조(결합데이터)
                <br />
                &#9312;데이터이용자는 결합데이터의 이용권한을 가지며,
                데이터이용자는 데이터제공자에 대하여
                <InputBasic
                  id="c_range"
                  type="number"
                  min={0}
                  // label={"범위 입력"}
                  label={label08}
                  value={cRange}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnPreambleChangeEvent(event, {
                      cRange: newCountValue,
                    });
                    onVaildate({
                      cRange: newCountValue,
                      dRange,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setCRange(newCountValue);
                  }}
                  disabled={disabled}
                />
                의 범위에서 [ 의 목적의 범위 에서] 결합데이터를 무상[유상]으로
                이용하는 것을 허락한다.
                <br />
                &#9313;데이터이용자가 결합데이터를 이용함에 따라 발생하는 발명,
                고안, 창작 및 영업비밀 등에 대한 지식재산권은 데이터이용자에게
                귀속한다. 단, 데이터이용자는 데이터제공자에 대하여 해당
                지식재산권에 대한 무상[유상]의 실시 허락 을 한다. <br />
                &#9314;제1항 및 제2항의 이용 허락의 조건에 대한 구체적 내용은
                별도로 협의하여 부속문서에 포함 한다.
                <br />
                &#9315;데이터이용자가 결합데이터를 이용하여 실시한 사업 또는
                서비스에 따라 매출이 얻을때는 매출액의 일부분을 갑에게
                지급하여야 하며 지급 조건 및 비율에 대해서는 별도로 협의하여
                부속문서에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제8조(이용신청 및 방법)
                <br />
                &#9312;데이터이용자는 제조데이터보호거래플랫폼 이용에 필요한
                정보를 제공하여야 하고 플랫폼운영자는 제조데이터보호거래플랫폼
                이용신청에 필요한 정보의 용도와 범위를 데이터이용자가 확인할 수
                있도록 하여야 한다
                <br />
                &#9313;데이터이용자가 사업자인 경우에 제1항의 신청을 할 때에
                실제 상호 및 정보를 기재하여야 하고 소비자인 경우에는 본인의
                실명 및 실제 정보를 기재하여야 한다. 데이터이용자가 타사업자나
                타인의 명의를 도용하거나 거짓 정보를 기재한 경우에는 본 계약에서
                정한 권리를 주장할 수없고, 플랫폼 운영자는 이를 이유로 계약을
                해제하거나 해지할 수 있다.
                <br />
                &#9314;미성년자가 이용신청을 할 경우에는 법정대리인의 동의를
                얻어야 하고, 구체적인 동의절차는 플랫폼운영자가 제공하는 방법에
                따른다.
                <br />
                &#9315;데이터이용자의 제공데이터의 이용허락에 대한 대가는
                스마트계약서에 포함한다.
                <br />
                &#9316;데이터 제공방법, 데이터이용의 대가 지급방법, 시기, 데이터
                이용기간 및 연장에 관련된 사항은 스마트계약서에 포함한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제9조(이용신청의 승낙과 제한)
                <br />
                &#9312;본 계약은 데이터이용자가 플랫폼운영자에게 데이터
                이용신청을 하고 플랫폼운영자의 승낙이 데이터이용자에게 도달한
                때에 성립한다.
                <br />
                &#9313;플랫폼운영자는 다음 각 호의 어느 하나에 해당하는
                이용신청에 대해서는 승낙하지 않을 수 있다.
                <BoxSx>
                  1. 제5조(이용신청 및 방법) 제3항에 위반하여 이용을 신청한 경우
                </BoxSx>
                <BoxSx>2. 데이터이용자가 이용요금을 납부하지 않은 경우</BoxSx>
                <BoxSx>
                  3. 데이터이용자가 플랫폼마켓운영자와 체결한 계약의 중대한
                  내용을 위반한 사실이 있는 경우
                </BoxSx>
                <BoxSx>
                  4. 타인의 신용카드, 유·무선 전화, 은행 계좌 등을 무단으로
                  이용하거나 도용하여 이용요금을 결 제하는 경우
                </BoxSx>
                <BoxSx>
                  5. ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’,
                  ‘개인정보보호법’, ‘저작권법’ 등 관계 법령 에서 금지하는
                  위법행위를 할 목적으로 이용신청을 하는 경우
                </BoxSx>
                <BoxSx>
                  6. 데이터이용자가 본 계약 이전에 데이터이용자의 자격을 상실한
                  사실이 있는 경우
                </BoxSx>
                <BoxSx>
                  7. 그 밖에 제1호에서 제6호까지에 준하는 사유로서 승낙하는 것이
                  상당히 부적절하다고 판단되는 경우
                </BoxSx>
                <br />
                &#9314;플랫폼운영자는 다음 각 호의 어느 하나에 해당하는 경우에는
                그 사유가 해소될 때까지 승낙을 유보할 수 있다.
                <BoxSx>1. 플랫폼운영자에게 기술적 장애가 있는 경우</BoxSx>
                <BoxSx>
                  2. 서비스 장애 또는 제조데이터보호거래플랫폼 결제수단에 장애가
                  있는 경우
                </BoxSx>
                <BoxSx>
                  3. 그 밖에 제1호 또는 제2호에 준하는 사유로서 이용신청의
                  승낙이 곤란한 경우
                </BoxSx>
                <br />
                &#9315;데이터이용자는 이용신청 시 기재한 사항이 변경되었을 경우
                그 내용을 플랫폼운영자에게 지체 없이 알려야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제10조(청약철회 등)
                <br />
                &#9312;플랫폼운영자가 제공하는 제조데이터보호거래플랫폼에서
                데이터제공자와 데이터 이용 계약을 체결한 소비자인 데이터이용자는
                ‘전자상거래 등에서의 소비자보호에 관한 법률’ 제17조 제2항에
                근거하여 데이터 제공이 개시된 경우 다음 각 호의
                기간(데이터이용자와 데이터제공자가 다음 각 호의 기간보다 긴
                기간으로 약정한 경우에는 그 기간을 말한다) 내에 본 계약에 관한
                청약의 철회 또는 계약의 해제(이하 ‘청약철회 등’)를 할 수 있다.
                <BoxSx>
                  1. 계약 내용에 관한 서면(전자문서를 포함한다, 이하 같다)을
                  교부(전송을 포함한다, 이하 같다)받은 날로부터 7일. 다만, 그
                  교부받은 날보다 데이터 제공이 늦게 이루어진 경우에는 데이터
                  제공이 개시된 날로부터 7일
                </BoxSx>
                <BoxSx>
                  2. 데이터제공자 주소 등이 기재되지 아니한 서면을 교부받았거나
                  데이터제공자의 주소 변경 등의 사유로 제1호의 기간 이내에
                  청약철회 등을 할 수 없는 경우에는 그 주소를 안 날 또는 알 수
                  있었 던 날로부터 7일
                </BoxSx>
                <BoxSx>
                  3. 체험용 데이터 제공 : 일부 제한된 기능만을 사용할 수 있는
                  데이터 제공
                </BoxSx>
                <BoxSx>
                  4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기
                  곤란한 경우 : 데이터에 관한 정보 제공
                </BoxSx>
                <br />
                &#9314;데이터 제공이 개시된 경우에 데이터이용자는 데이터제공자의
                의사에 반하여 청약철회 등을 할 수 없다. 다만 가분적 데이터의
                경우에는 제공이 개시되지 아니한 부분에 대하여는 청약철회 등을 할
                수 있다.
                <br />
                &#9315;데이터제공자는 제2항에 따라 청약철회 등이 불가능한 경우
                이러한 사실을 데이터이용자가 쉽게 알 수 있는 곳에 명확하게
                표시하고 다음 각 호의 방법 중 하나 이상의 방법으로 시험 사용
                상품을 제 공하여야 한다.
                <BoxSx>
                  1. 일부 이용의 허용 : 데이터의 일부를 미리보기, 미리듣기
                  등으로 제공
                </BoxSx>
                <BoxSx>
                  2. 한시적 이용의 허용 : 일정 사용기간을 설정하여 데이터 제공
                </BoxSx>
                <BoxSx>
                  3. 체험용 데이터 제공 : 일부 제한된 기능만을 사용할 수 있는
                  데이터 제공
                </BoxSx>
                <BoxSx>
                  4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기
                  곤란한 경우 : 데이터에 관한 정보 제공
                </BoxSx>
                <br />
                &#9315;데이터 내용이 표시・광고한 내용과 다르거나 계약 내용과
                다르게 이행된 경우에 데이터이용자는 그 사실을 알았거나 알 수
                있었던 날을 기준으로 청약을 철회할 수 있으며 청약철회 기간은
                스마트계약에 포함된다.
                <br />
                &#9316;데이터이용자는 구두 또는 서면으로 청약철회 등을 할 수
                있으며, 청약철회 등을 서면으로 하는 경우에는 그 의사표시가
                기재된 서면을 발송한 날부터 효력이 발생한다.
                <br />
                &#9317;본 계약이 체결된 사실 및 그 시기, 서비스 등의 제공사실 및
                그 시기 등에 관하여 다툼이 있는 경우에는 데이터제공자가 이를
                증명하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제11조(청약철회 등의 효과)
                <br />
                &#9312;데이터제공자는 데이터이용자가 청약철회 등을 한 경우에 그
                날로부터 3영업일 이내에 소비자로 부터 지급받은 대금을 환급한다.
                <br />
                &#9313;제1항에서 데이터제공자가 데이터이용자에게 환급을 지연한
                경우에는 그 지연기간에 대하여 ‘전자상거래 등에서의 소비자보호에
                관한 법률’ 및 같은 법 시행령에서 정하는 이율을 곱하여 산정한
                지연이자를 지급한다.
                <br />
                &#9314;데이터제공자는 대금을 환급함에 있어 데이터이용자가
                신용카드나 그밖에 ‘전자상거래 등에서의 소비자보호에 관한 법률
                시행령’에서 정하는 결제수단으로 대금을 지급한 경우에는 해당
                결제수단을 제공한 결제업자에게 지체 없이 대금의 청구를 정지 또는
                취소하도록 요청한다. 다만, 데이터제공자가 결제업자로부터 이미
                대금을 지급받은 경우에는 이를 결제업자에게 환불하고
                데이터이용자에게 통지한다.
                <br />
                &#9315;데이터제공자는 데이터이용자가 데이터 일부를 사용한
                경우에는 그로 인해 데이터이용자가 얻은 이익 또는 데이터 제공에
                든 비용에 상당하는 금액을 데이터이용자에게 청구할 수 있다.
                <br />
                &#9316;데이터이용자가 청약철회 등을 한 경우에 데이터제공자는
                데이터이용자에게 청약철회 등을 이유 로 위약금 또는 손해배상을
                청구할 수 없다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제12조(플랫폼운영자의 의무)
                <br />
                &#9312;플랫폼운영자는 관계 법령을 준수하고, 데이터이용자가
                데이터를 원활히 이용할 수 있도록 과학기술정보통신부장관이 정하는
                정보보호에 관한 기준을 갖추어야 한다.
                <br />
                &#9313;플랫폼운영자는 안정적인 데이터 제공을 위하여 정기적인
                운영 점검을 실시할 수 있고, 이를 사전에 데이터이용자에게
                통지하여야 한다.
                <br />
                &#9314;플랫폼운영자는 장애로 인하여 정상적인 서비스가 어려운
                경우에 이를 신속하게 수리 및 복구하고, 신속한 처리가 곤란한
                경우에는 그 사유와 일정을 데이터이용자에게 알려야 한다.
                <br />
                &#9315;플랫폼운영자는 적절한 수준의 보안서비스를 제공하여야 하며
                정보의 유출 또는 제3자로부터 데이터이용자의 권리가 침해받지
                않도록 하여야 한다.
                <br />
                &#9316;플랫폼운영자는 법률에 특별한 규정이 있거나 법령상 의무를
                준수하기 위하여 불가피한 경우를 제외하고 데이터이용자의 동의
                없이 이용자 정보를 제3자에게 제공하거나 데이터 제공 목적 외의
                용도로 이용할 수 없다. 플랫폼운영자로부터 이용자 정보를 제공받은
                제3자도 또한 같다.
                <br />
                &#9317;플랫폼운영자는 데이터이용자가 서비스 이용현황 및 이용요금
                등 이용내역을 알기 쉽게 확인할 수 있도록 조치하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제13조(데이터이용자의 의무)
                <br />
                &#9312;데이터이용자는 데이터를 이용하는 과정에서 ‘저작권법’ 등
                제3자의 지식재산권을 침해하거나 관계 법령을 위반하는 행위를
                하여서는 아니 된다.
                <br />
                &#9313;데이터이용자는 본 계약에서 정한 날까지 이용료를
                납부하여야 하고, 연락처, 요금결제 수단 등 거래에 필요한 정보가
                변경된 때에는 그 사실을 오픈마켓운영자에게 지체 없이 알려야
                한다.
                <br />
                &#9314;데이터이용자는 아이디와 비밀번호 등
                제조데이터보호거래플랫폼 접속정보에 대한 관리책임이 있으며 도용
                또는 제3자의 이용으로 인해 발생한 문제의 책임은 데이터이용자에게
                있다. 다만, 플랫폼운영자에게 고의 또는 과실이 있는 경우에는
                그러하지 않는다.
                <br />
                &#9314;데이터이용자는 본 계약의 규정 및 데이터와 관련하여
                플랫폼운영자로부터 통지받은 제반사항을 준수하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제14조(데이터서비스 제공 및 변경)
                <br />
                &#9312;플랫폼운영자는 데이터이용자에게 제공하는 서비스의 내용
                또는 그 이행수준을 변경하고자 하는 경우에 미리 알려야 한다.
                다만, 서비스의 내용 또는 그 이행수준 변경이 데이터이용자에게
                불리한 경우에는 데이터이용자의 동의를 얻어야 한다.
                <br />
                &#9313;플랫폼운영자는 서비스 제공과 관련하여 다음과 같은 업무를
                수행한다.
                <BoxSx>
                  1. 플랫폼운영자는 연중무휴, 1일 24시간 중단 없는 서비스를
                  제공해야 한다. 다만, 정기점검이나 시스템의 성능향상을 위한
                  작업 등을 데이터이용자에게 사전 통지한 경우에 서비스를 일시
                  중단할 수 있다.
                </BoxSx>
                <BoxSx>
                  2. 플랫폼운영자는 서비스에 장애가 발생한 경우에 지체 없이 수리
                  또는 복구한다.
                </BoxSx>
                <BoxSx>
                  3. 그밖에 서비스의 안정적인 운영에 필요한 관련 업무를
                  수행한다.
                </BoxSx>
                <br />
                &#9314;플랫폼운영자가 서비스의 내용을 변경하는 경우에는
                변경사유, 변경될 서비스의 내용 및 제공일자 등을 명시하여 그
                적용일자로부터 최소한 7일 전부터 해당 서비스의 초기 화면에
                게시하여 알려야 한다. 다만, 서비스의 내용 변경이
                데이터이용자에게 불리하거나 중대한 사항의 변경은 최소 30일
                전부터 전화, 휴대전화, 우편, 전자우편 또는 문자메시지 등으로
                알려야 하며, 데이터이용자에게 불리하고 또한 그 내용이 중요한
                경우에는 데이터이용자의 동의를 얻어야 한다.
                <br />
                &#9315;플랫폼운영자는 데이터이용자의 문의나 요청에 대응하기 위해
                이를 처리하는 담당부서 및 담당 자의 이름과 연락처를 정하여
                알려주어야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제15조(데이터서비스 제공의 정지)
                <br />
                &#9312;플랫폼운영자는 다음 각 호의 어느 하나에 해당하는 경우에
                서비스 제공을 정지할 수 있으며, 그 사유가 해소되면 지체 없이
                서비스 제공을 재개하여야 한다.
                <BoxSx>
                  1. 데이터이용자가 시스템 운영이나 네트워크 보안 등에 심각한
                  장애를 초래한 경우
                </BoxSx>
                <BoxSx>
                  2. 기타 관련 법령에 위반하거나 플랫폼운영자의 업무를 방해하는
                  행위를 하는 경우
                </BoxSx>
                <br />
                &#9313;플랫폼운영자는 제1항에 따른 서비스를 정지하기 전 7일까지
                그 사실을 데이터이용자에게 알리고 이의신청의 기회를 주어야 한다.
                다만, 데이터이용자의 책임 있는 사유로 알릴 수 없는 때에는
                그러하지 아니하다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제16조(데이터서비스의 중단)
                <br />
                &#9312;플랫폼운영자는 다음 각 호의 어느 하나에 해당하는 경우에
                일정한 시간동안 서비스 제공을 중단 할 수 있으며, 그 사유가
                해소되면 지체 없이 서비스 제공을 재개하여야 한다.
                <BoxSx>
                  1. 서비스 제공을 위한 컴퓨터 등 정보통신설비의 보수점검, 교체,
                  정기점검 또는 성능 향상을 위 하여 필요한 경우
                </BoxSx>
                <BoxSx>
                  2. 해킹, 컴퓨터바이러스 등 전자적 침해사고, 통신사고, 장애
                  등으로 정상적인 서비스 제공이 불 가능한 경우
                </BoxSx>
                <BoxSx>
                  3. 천재지변, 비상사태, 정전, 설비 장애 등 불가항력 사유로
                  정상적인 서비스 제공이 불가능한 경 우
                </BoxSx>
                <br />
                &#9313;플랫폼운영자는 제1항 제1호의 경우에는 서비스 제공을
                중단하기 전 7일까지 그 사실을 데이터이용자에게 알려야 한다.
                다만, 제2호 및 제3호의 경우에는 사전 통지 없이 서비스를 중단할
                수 있으나, 중단 후에는 지체 없이 그 사실을 이용자에게 알려야
                한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제17조(데이터서비스 이용제한)
                <br />
                &#9312;플랫폼운영자는 데이터이용자의 행위로 서비스의 손상, 정지
                등을 초래하거나 그밖에 본 계약의 규정에 위반하여 서비스에 대한
                플랫폼운영자의 업무수행 또는 서비스 제공에 현저한 지장을 주거나
                줄 우려가 있는 행위를 한 경우에는 서비스 이용을 제한할 수 있다.
                <br />
                &#9313;플랫폼운영자는 제1항의 규정에 의하여 서비스의 이용을
                제한하는 경우에는 미리 그 사유와 이용 제한 기간, 이의신청 방법을
                해당 데이터이용자에게 통지하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제18조(데이터이용자의 계약 해지)
                <br />
                &#9312;데이터이용자는 언제든지 본 계약의 해지를 요청할 수
                있으며, 플랫폼운영자는 이를 지체없이 처 리하여야 한다.
                <br />
                &#9313;데이터이용자가 계약 해지를 요청한 경우에 플랫폼운영자는
                데이터이용자 본인 여부를 확인할 수 있으며, 해당 데이터이용자가
                본인으로 확인되는 경우 지체 없이 데이터이용자의 요청에 따른 조치
                를 취해야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제19조(플랫폼운영자의 계약 해지)
                <br />
                &#9312;플랫폼운영자는 다음 각 호의 어느 하나에 해당하는 경우에
                계약을 해지할 수 있다.
                <BoxSx>
                  1. 데이터이용자가 제13조(데이터이용자의 의무)에서 정한
                  데이터이용자의 의무를 위반한 경우
                </BoxSx>
                <BoxSx>
                  2. 제17조(데이터서비스 이용제한)에 따라 서비스의 이용이 제한된
                  데이터이용자가 상당한 기간 동안 해당 사유를 해소하지 않는 경우
                </BoxSx>
                <BoxSx>3. 사업의 종료에 따라 서비스를 종료하는 경우</BoxSx>
                <br />
                &#9313;플랫폼운영자가 제1항에 따라 계약을 해지하고자 하는 때에는
                데이터이용자에게 7일 전까지 그 사유를 통지하고 이의신청의 기회를
                주어야 한다. 다만, 데이터이용자의 책임 있는 사유로 알릴 수 없는
                때에는 그러하지 않는다.
                <br />
                &#9314;플랫폼운영자는 데이터이용자가 고의 또는 중대한 과실로
                플랫폼운영자 또는 데이터제공자에게 손해를 입힌 경우에는 사전
                통지 없이 계약을 해지할 수 있으며, 플랫폼운영자는 해지 후 그
                사실을 이용자에게 지체 없이 알려야 한다.
                <br />
                &#9315;제1항 제3호 및 제3항에 따른 계약 해지는 데이터이용자에
                대한 손해배상의 청구에 영향을 미치지 않는다.
                <br />
                &#9316;플랫폼운영자가 계약을 해지하는 경우에는 데이터이용자에게
                서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을
                통지하여야 한다.
                <BoxSx>1. 해지사유</BoxSx>
                <BoxSx>2. 해지일</BoxSx>
                <BoxSx>3. 환급비용</BoxSx>
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제20조(제공데이터의 유출 시 조치)
                <br />
                &#9312;데이터이용자는 제공데이터 유출, 제3자 제공, 목적 외 이용
                등 본 계약에 위반하는 제공데이터의 이용(이하 [제공데이터 유출
                등]이라 한다)을 발견한 경우, 즉시 데이터제공자에게 그 취지를
                통지하여야 한다.
                <br />
                &#9313;데이터이용자의 고의 또는 과실에 의해 제공데이터 유출 등이
                발생한 경우, 을은 자기의 비용과 책임으로 제공데이터 유출 등의
                사실 여부를 확인하고, 제공데이터 유출 등의 사실이 확인된 경우 그
                원인 및 재발방지책을 검토하고 이를 데이터사용자에게 보고하여야
                한다.
                <br />
                &#9314;제공데이터에 개인정보가 포함되어 있는 경우,
                데이터이용자는 개인정보보호법령에 따라 개 인정보 유출 등의
                사실을 감독당국에 신고하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제21조(데이터이용자 정보의 보호와 관리)
                <br />
                플랫폼운영자는 관계 법령이 정하는 바에 따라 데이터이용자의
                정보를 보호해야 하며, 데이터이용자 정보의 이용에 관해서는 관계
                법령 및 별도로 고지하는 개인정보 처리방침이 적용된다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제22조(손해배상) &#9312;플랫폼운영자의 고의 또는 과실로 인한
                서비스 장애나 본 계약에서 정하는 수준에 미달하는 서비 스를
                제공하는 등 데이터이용자에게 손해를 발생시킨 경우에는 그 손해를
                배상하여야 한다. &#9313;데이터이용자가 고의 또는 과실로
                플랫폼운영자 혹은 데이터제공자에게 손해를 발생시킨 경우 에는 그
                손해를 배상하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제23조(면책)
                <br />
                &#9312;플랫폼운영자는 다음 각 호의 사유로 인하여 발생한 손해에
                대하여는 책임을 면한다.
                <BoxSx>
                  1. 제13조(데이터서비스의 중단) 제1항 각 호의 사유로 서비스
                  점검이 불가피하여 같은 조 제2항에서 정한 절차에 따라 사전에
                  알린 경우로써 플랫폼운영자에게 고의 또는 과실이 없는 경우
                </BoxSx>
                <BoxSx>
                  2. 천재지변, 전쟁·내란·폭동 등 비상사태, 현재의 기술수준으로는
                  해결이 불가능한 기술적 결함 그 밖에 불가항력으로 서비스를
                  제공할 수 없는 경우
                </BoxSx>
                <BoxSx>
                  3. 데이터이용자의 고의 또는 과실로 인한 서비스의 중단, 장애 및
                  계약 해지의 경우
                </BoxSx>
                <BoxSx>
                  4. 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로
                  제공하지 아니하여 데이터이용자에게 손해가 발생한데 대하여
                  플랫폼운영자에게 고의 또는 과실이 없는 경우
                </BoxSx>
                <BoxSx>
                  5. 데이터이용자의 컴퓨터 환경이나 플랫폼운영자의 고의 또는
                  과실이 없는 네트워크 환경으로 인하여 문제가 발생한 경우
                </BoxSx>
                <BoxSx>
                  6. 데이터이용자의 컴퓨터 오류 또는 사업자정보 및 전자우편
                  주소의 부정확한 기재 등으로 데이터이용자에게 발생한 손해에
                  대하여 플랫폼운영자에게 고의 또는 과실이 없는 경우
                </BoxSx>
                <br />
                &#9313;플랫폼운영자는 데이터이용자 또는 제3자가 서비스 내 또는
                제조데이터보호거래플랫폼 홈페이지에 게시 또는 전송한 정보,
                자료의 신뢰도, 정확성 등의 내용에 대하여 고의 또는 과실이 없는
                한 책임을면한다.
                <br />
                &#9314;플랫폼운영자는 데이터이용자 사이 또는 데이터이용자와
                제3자 사이에 지식재산권 침해로 발생 한 분쟁으로 인한 손해에
                대하여 고의 또는 과실이 없는 한 책임을 면한다.
                <br />
                &#9315;플랫폼운영자는 데이터이용자 사이 또는 데이터이용자와
                제3자 사이에 오픈마켓운영자가 제공하는 서비스를 매개로 발생한
                분쟁에 대하여 다음 각 호의 요건을 모두 갖춘 경우에는 이로 인해
                발생한 손해에 대하여 책임을 면한다.
                <BoxSx>
                  1. 플랫폼운영자가 관계 법령을 위반하지 아니한 경우
                </BoxSx>
                <BoxSx>
                  2. 플랫폼운영자가 고의 또는 과실이 없음을 증명한 경우
                </BoxSx>
                <BoxSx>
                  3. 다른 데이터이용자 또는 제3자가 데이터이용자의 권리를
                  침해하는 때에 플랫폼운영자가 그 침해행위를 통제할 권한과
                  능력이 없는 경우
                </BoxSx>
                <BoxSx>
                  4. 다른 데이터이용자 또는 제3자가 데이터이용자의 권리를
                  침해하는 때에 플랫폼운영자가 그 침해 사실을 알았거나 또는
                  침해가 명백하다는 사실 또는 그 정황을 알게 된 즉시 그
                  침해행위를 중단시킨 경우
                </BoxSx>
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제24조(데이터이용자에 대한 통지)
                <br />
                &#9312;플랫폼운영자는 다음 각 호의 어느 하나에 해당하는 사유가
                발생한 경우에는 데이터이용자가 미리 지정한 전화, 문자메시지,
                전자우편, 서비스 접속화면, 사이버몰 홈페이지 게시 등의 방법으로
                데이터이용자에게 알려야 한다.
                <BoxSx>1. 침해사고</BoxSx>
                <BoxSx>2. 데이터이용자 정보의 유출</BoxSx>
                <BoxSx>3. 서비스의 중단</BoxSx>
                <BoxSx>4. 서비스의 종료</BoxSx>
                <BoxSx>
                  5. 그밖에 데이터이용자의 서비스 이용에 중대한 영향을 미치는
                  사항
                </BoxSx>
                <br />
                &#9313;플랫폼운영자는 제1항 각 호 중 어느 하나에 해당하는 사유가
                발생한 경우에는 그 사실을 지체 없이 데이터이용자에게 알려야
                한다. 다만, 다음 각 호의 경우는 예외로 한다.
                <BoxSx>1. 7일 전에 사전 예고를 하고 서비스를 중단한 경우</BoxSx>
                <BoxSx>
                  2. 30일 전에 서비스를 변경하거나 종료하도록 한 경우
                </BoxSx>
                <BoxSx>3. 30일 전에 사업을 폐지하거나 종료하도록 한 경우</BoxSx>
                <br />
                &#9314;플랫폼운영자는 제1항 제1호에서 제3호까지의 사유가 발생한
                경우에 지체 없이 다음 각 호의 사 항을 해당 데이터이용자에게
                알려야 한다.
                <BoxSx>1. 발생내용</BoxSx>
                <BoxSx>2. 플랫폼운영자의 피해 확산 방지 조치 현황</BoxSx>
                <BoxSx>3. 데이터이용자의 피해예방 또는 확산방지방법</BoxSx>
                <BoxSx>4. 담당부서 및 연락처</BoxSx>
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제25조(본 계약 종료 후의 효력)
                <br />
                &#9312;본 계약이 유효기간의 만료 또는 해제에 따라 종료한
                경우이더라도, 제20조, 제23조의 규정 은 유효하게 존속하는 것으로
                한다.
                <br />
                &#9313;데이터이용자는 본 계약이 종료한 때 제공데이터 중에서
                부속문서에서 계약종료시에 폐기 또는 제거, 삭제가 명기된 것에
                관하여, 플랫폼관리자와 데이터이용자가 별도로 협의하여 정한
                절차에 따라서 신속히 폐기 또는 제거, 삭제하는 것으로 한다.
                &#9314;데이터이용자는 제2항에 따라 폐기 또는 제거, 삭제할 의무를
                부담하는 데이터 이외의 제공 데이터 등에 대하여 데이터제공자의
                이용허락을 받아 계속 이용할 수 있다. 데이터이용자가 계속
                제공데이터 등을 이용하는 범위 및 행위에 대해서는 본 계약의
                효력이 유지된다. &#9315;데이터이용자는 계약 종료 후
                플랫폼운영자부터 제공받은 자료를 즉시 플랫폼에 반환한다.
                물리적으로 반환할 수없는 경우에는 플랫폼운영자의 지시에 따라
                폐기하고 폐기한 사실을 증명 하는 서면을 제출하여야 한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제26조(양도금지)
                <br />
                플랫폼운영자와 데이터이용자는 본 계약에 따른 권리와 의무의 전부
                또는 일부를 상대방의 서면 동의 없이 제3자에게 양도 또는 담보로
                제공할 수 없다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제27조(준거법)
                <br />
                &#9312;본 계약은 한국법에 준거하고, 한국법에 따라서 해석한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                제28조(관할법원 등)
                <br />
                &#9312;본 계약에 기인하거나 관련하여 발생한 분쟁(본 계약의 각
                조항의 해석에 의문이 발생한 경우를 포함함)에 관해서는 우선 양
                당사자들이 성실하게 협의함으로써 그 해결하기로 한다. &#9313;위
                &#9312;항의 협의가 이루어지지 않은 경우에는 울산지방법원을
                제1심의 전속적 합의관할 법원으로 한다.
              </Typography>
            </GridSx>
          </AccordionDetails>
        </Accordion>
      </div>
      <div ref={appendixRef}>
        <Accordion sx={accordionSx} PaperComponent={PaperComponent}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography component="h2" variant="subtitle2">
                  플랫폼 표준계약서(부속)
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {/* <StyledPaper> */}
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                플랫폼형 표준계약서 - 부속문서
                <br />
                플랫폼운영자와 데이터이용자 간의 표준계약서,
                <br />
                플랫폼운영자와 데이터제공자 간의 표준계약서
                <br />
                <br />
                1. 제공데이터 범위
                <br />
                &#9312;제공데이터는{" "}
                <InputBasic
                  id="process"
                  // label={"공정 명 입력"}
                  label={label15}
                  value={process}
                  onChange={(event) => {
                    // 부속 변경 이벤트 호출
                    setOnAppendixChangeEvent(event, {
                      process: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process: event.target.value,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcess(event.target.value);
                  }}
                  disabled={disabled}
                />
                공정의{" "}
                <InputBasic
                  id="process_data_type"
                  // label={"데이터 명 입력"}
                  label={label16}
                  value={processDataType}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processDataType: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process,
                      processDataType: event.target.value,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessDataType(event.target.value);
                  }}
                  disabled={disabled}
                />
                데이터의{" "}
                <InputBasic
                  id="process_month"
                  type="number"
                  min={0}
                  // label={"개월 수 입력"}
                  label={label17}
                  value={processMonth}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processMonth: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process,
                      processDataType,
                      processMonth: newCountValue,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessMonth(newCountValue);
                  }}
                  disabled={disabled}
                />
                개월치 데이터를 말한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                플랫폼형 표준계약서 - 부속문서(파생데이터)
                <br />
                플랫폼운영자와 데이터이용자 간의 표준계약서,
                <br />
                플랫폼운영자와 데이터제공자 간의 표준계약서
                <br />
                <br />
                1. 파생데이터 이용허락 조건 및 이득발생시 지급조건
                <br />
                &#9312;데이터이용자의 파생데이터를 이용한 경제적 비경제적 활동이
                데이터제공자의 사업에 불이익을 주지않아야 한다.
                <br />
                &#9313;계약종료시 파생데이터를 더 이상 사용하지 않을 시에는
                파생데이터를 파기하여야 하며 파생데이터를 계속이용할 경우에는
                별도의 계약을 통해 파생데이터를 이용하여야 한다.
                <br />
                &#9314;파생데이터를 이용하여 발생한 매출의{" "}
                <InputBasic
                  id="process_d_rate"
                  type="number"
                  min={0}
                  // label={"매출의 제공 % 입력"}
                  label={label18}
                  value={processDRate}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processDRate: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process,
                      processDataType,
                      processMonth,
                      processDRate: newCountValue,
                      processDCount,
                      processCRate,
                      processCCount,
                    });
                    setProcessDRate(newCountValue);
                  }}
                  disabled={disabled}
                />
                %를 갑에게 제공하여야 하다. 파생데이터의 사용횟수 및 타
                기관으로의 제공건수{" "}
                <InputBasic
                  id="process_d_count"
                  // label={"제공 건수/ 년 입력"}
                  label={label19}
                  value={processDCount}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processDCount: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount: event.target.value,
                      processCRate,
                      processCCount,
                    });
                    setProcessDCount(event.target.value);
                  }}
                  disabled={disabled}
                />{" "}
                건/년 으로 제한한다.
              </Typography>
            </GridSx>
            <GridSx>
              <Typography component="p" variant="body2" gutterBottom>
                플랫폼형 표준계약서 - 부속문서(결합데이터)
                <br />
                플랫폼운영자와 데이터이용자 간의 표준계약서,
                <br />
                플랫폼운영자와 데이터제공자 간의 표준계약서
                <br />
                <br />
                1. 결합데이터 이용허락 조건 및 이득발생시 지급조건
                <br />
                &#9312;데이터이용자의 결합데이터를 이용한 경제적 비경제적 활동이
                데이터제공자의 사업에 불이익을 주지않아야 한다.
                <br />
                &#9313;계약종료시 결합데이터를 더 이상 사용하지 않을 시에는
                결합데이터를 파기하여야 하며 결합데이터를 계속이용할 경우에는
                별도의 계약을 통해 파생데이터를 이용하여야 한다.
                <br />
                &#9314;결합데이터를 이용하여 발생한 매출의{" "}
                <InputBasic
                  id="process_c_rate"
                  type="number"
                  min={0}
                  // label={"매출의 제공 % 입력"}
                  label={label20}
                  value={processCRate}
                  onChange={(event) => {
                    const newCountValue = countValue(event);
                    setOnAppendixChangeEvent(event, {
                      processCRate: newCountValue,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate: newCountValue,
                      processCCount,
                    });
                    setProcessCRate(newCountValue);
                  }}
                  disabled={disabled}
                />{" "}
                %를 데이터제공자에게 제공하여야 하다. 결합데이터의 사용횟수 및
                타 기관으로의 제공건수
                <InputBasic
                  id="process_c_count"
                  // label={"제공 건수/ 년 입력"}
                  label={label21}
                  value={processCCount}
                  onChange={(event) => {
                    setOnAppendixChangeEvent(event, {
                      processCCount: event.target.value,
                    });
                    onVaildate({
                      cRange,
                      dRange,
                      process,
                      processDataType,
                      processMonth,
                      processDRate,
                      processDCount,
                      processCRate,
                      processCCount: event.target.value,
                    });
                    setProcessCCount(event.target.value);
                  }}
                  disabled={disabled}
                />
                건/년 으로 제한한다.
              </Typography>
            </GridSx>
            {/* </StyledPaper> */}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
