import { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import { MenuIcon, NavigateNextIcon } from "components/icons/IconMui";

const drawerWidth = 240;

const StackSX = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const MypageTopArea = ({ handleDrawerToggle, selectedMenuItem }) => {
  // const [selectedMenuItem, setSelectedMenuItem] = useState("내 계약 진행");

  // const handleMenuItemClick = (text) => {
  //   setSelectedMenuItem(text);
  // };
  //   const [mobileOpen, setMobileOpen] = useState(false);
  //   const [isClosing, setIsClosing] = useState(false);

  //   const handleDrawerToggle = () => {
  //     if (!isClosing) {
  //       setMobileOpen(!mobileOpen);
  //     }
  //   };

  //   const handleDrawerClose = () => {
  //     setIsClosing(true);
  //     setMobileOpen(false);
  //   };

  //   const handleDrawerTransitionEnd = () => {
  //     setIsClosing(false);
  //   };

  return (
    <AppBar
      // position="fixed"
      position="static"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // width: { sm: `calc(100% - ${drawerWidth}px)` },
        // ml: { sm: `${drawerWidth}px` },
        backgroundColor: "#fff !important",
        color: "inherit",
        border: 0,
        boxShadow: 0,
        // mb: 4,
      }}
    >
      <Toolbar sx={{ paddingLeft: "0 !important" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: { sm: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography component="h2" variant="h4" sx={{ width: drawerWidth }}>
            마이페이지
          </Typography>
          <Stack gap={0.5} sx={StackSX}>
            <NavigateNextIcon />
            <Typography component="h3" variant="h6" sx={{ p: 3, pl: 0 }}>
              {selectedMenuItem}
            </Typography>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MypageTopArea;
