import React, { useCallback, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Tab,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { SnackbarMessage } from "components/modal";
import { ModalDirectContract } from "components/modal/ModalDirectContract";
import { ModalPlatformContract } from "components/modal/ModalPlatformBuyContract";
import { ModalPlatformBuyContract } from "components/modal/ModalPlatformBuyContract";
import FTBottomCard from "components/viewContents/FTManagementView/FTBottomCard";
import MyDialog from "components/modal/Dialog";
import useFT from "views/temp/ftManagement/useFT";

// 스타일
const StyledTab = styled(Tab)({
  backgroundColor: "#fff", // 배경색
  minHeight: "unset",
  // backgroundColor: "#f0f0f0", // 배경색
  // color: "#000", // 글자색
  // fontSize: "1rem", // 글자 크기
  "&.Mui-selected": {
    // 선택된 탭 스타일
    // backgroundColor: "#c0c0c0",
    // color: "blue",
    color: "#468ECD",
    border: "1px solid #468ECD",
    borderRadius: "25px",
  },
  "&:hover": {
    // 마우스 오버 스타일
    backgroundColor: "#eee",
    // backgroundColor: "#e0e0e0",
    // border: "1px solid #468ECD",
    borderRadius: "25px",
  },
});

const TabPanelSx = styled(TabPanel)(({ theme }) => ({
  padding: "0",
  marginTop: "24px",
}));

const TFTopCCardSx = styled(Card)(({ theme }) => ({
  ...theme.typography.h5,
  background: "#29314a",
  color: "#fff",
}));

const FTManagement = () => {
  const {
    userDID,
    userName,
    dialog,
    snackBar,
    balance,
    value,
    content,
    selectContractNumber,
    isOpenModal,
    isOpenPlatformModal,
    handleChange,
    handleClose,
    onAmountClick,
  } = useFT();

  // 준비중 E
  return (
    <>
      {/* <ModalContinue /> */}
      <Box sx={{ pb: 6 }}>
        <Container
          sx={{
            // backgroundColor: "green",
            // width: "100%",
            pt: 4,
            pb: 6,
          }}
          maxWidth="lg"
        >
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid
              container
              item
              xs={6.5}
              spacing={0}
              sx={{
                "& .MuiGrid-item": {
                  paddingLeft: "0",
                  paddingTop: "0",
                },
                "& .MuiPaper-root": {
                  // padding: "0",
                  // margin: "0 8px",
                  // "&:nth-child(1)": { marginRight: "4px" },
                  // "&:nth-child(2)": { marginLeft: "4px" },
                },
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  position: "relative",
                  "& .MuiPaper-root": {
                    height: "100%",
                    padding: "16px",
                    marginLeft: "2.5%",
                  },
                }}
              >
                <TFTopCCardSx>
                  <CardContent sx={{ padding: "0px !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // p: 2,
                      }}
                    >
                      <Typography component="h4" variant="h5">
                        FT
                      </Typography>
                      <Typography
                        component="P"
                        variant="h5"
                        sx={{
                          color: "#468ecd",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignContent: "baseline",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography component="span" variant="h4">
                          {balance}
                        </Typography>
                        개
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{
                      p: 0,
                      mt: 2,
                    }}
                  >
                    {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignContent: "baseline",
                        alignItems: "flex-end",
                      }}
                    > */}
                    <Button // color="#468ECD"
                      sx={{
                        borderColor: "#fff",
                        color: "#fff",
                        width: "50%",
                      }}
                      target="_blank"
                      // href="/intro" // 이거 나중에, 프롭스로 넘겨주기 !!!!!!!!!!!!!!!!!!!!!!!!!!!
                      // onClick
                      size="small"
                      variant="outlined"
                      onClick={onAmountClick}
                    >
                      충전
                    </Button>
                    {/* </Box> */}
                  </CardActions>
                </TFTopCCardSx>
                {/* rightAred */}
              </Grid>
            </Grid>
            {/* leftArea */}
          </Grid>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={{
                  ".MuiTabs-indicator": {
                    // 선택된 탭에만 밑줄 스타일 지정
                    backgroundColor: "transparent", // 밑줄 제거
                  },
                }}
              >
                <StyledTab label="FT 사용내역" value="1" />
                <StyledTab label="FT 수령내역" value="2" />
                <StyledTab label="FT 결제내역" value="3" />
              </TabList>
            </Box>
            <TabPanelSx value="1">
              {content.map((card) => (
                <FTBottomCard key={card.id} card={card} />
              ))}
            </TabPanelSx>
            <TabPanelSx value="2">
              {content.map((card) => (
                <FTBottomCard key={card.id} card={card} />
              ))}
            </TabPanelSx>
            <TabPanelSx value="3">
              {content.map((card) => (
                <FTBottomCard key={card.id} card={card} />
              ))}
            </TabPanelSx>
          </TabContext>
        </Container>
      </Box>
      {isOpenModal && new Set(["1", "3"]).has(value) && (
        <ModalDirectContract
          contract_sub_id={selectContractNumber}
          company_info_type="buyer"
          onClose={handleClose}
        />
      )}
      {isOpenModal && value === "2" && (
        <ModalDirectContract
          contract_sub_id={selectContractNumber}
          company_info_type="seller"
          onClose={handleClose}
        />
      )}
      {isOpenPlatformModal && (
        <ModalPlatformBuyContract
          contract_sub_id={selectContractNumber}
          company_info_type="buyer"
          onClose={handleClose}
        />
      )}
      <MyDialog {...dialog} />
      <SnackbarMessage {...snackBar} />
    </>
  );
};

export default FTManagement;
