import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// 공통으로 사용하기 위해, 파일명 공통으로 작명

const style = {
  width: "500px",
  "& label.Mui-focused": {
    color: "#80bdff",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderRadius: 1,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
};

const Prompt = ({ context, open, value, onChange, onClick, onClose }) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ backgroundColor: "#00000000" }}
      >
        {/* <DialogTitle>제목</DialogTitle> */}
        <DialogContent>
          <DialogContentText>{context}</DialogContentText>
          <TextField
            sx={style}
            autoFocus
            size="small"
            margin="dense"
            id="name"
            label="내용 입력"
            type="email"
            fullWidth
            variant="outlined"
            value={value}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClick}>확인</Button>
          <Button onClick={onClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const Confirm = ({ context, open, onClick, onClose }) => {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText>{context}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClick}>확인</Button>
          <Button onClick={onClose}>취소</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const Alert = ({ context, open, onClose }) => {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText>{context}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>확인</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default function MyDialog({ context, open, value, onChange, onClick, onClose }) {
  if (onChange && onClick && onClose) {
    return <Prompt {...{ context, open, value, onChange, onClick, onClose }} />;
  }
  if (onClick && onClose) {
    return <Confirm {...{ context, open, onClick, onClose }} />;
  }
  if (onClose) {
    return <Alert {...{ context, open, onClose }} />;
  }

  return null;
}
