import { useState, useEffect } from "react";
import client from "assets/js/commonApi";
import Axios from "axios";

export default function useCart() {
  const [data, setData] = useState([]);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 10000,
    handleClose: () => setSnackBar((pre) => ({ ...pre, open: false })),
  });
  const showSnackBar = (message) =>
    setSnackBar((pre) => ({
      ...pre,
      open: true,
      message: message,
    }));

  const getData = async () => {
    client
      .post("/user/cartlist")
      .then((res) => {
        console.log("[useCart]", "[data]", res.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.error("[useCart]", "[data err]", err);
      });
  };

  const onClick = (checkList) => {
    if (checkList.length === 0) {
      alert("구매요청할 플렛폼 데이터를 선택하세요");
      return;
    }

    client
      .post(
        "/platformbuy/ask",
        checkList.map((platform_main_id) => ({
          platform_main_id,
        })),
      )
      .then((response) => {
        console.log("[cartLikes ask]", response);
        showSnackBar("구매요청을 완료하였습니다");
        getData();
      })
      .catch((error) => {
        console.log("[cartLikes ask error]", error.response);
      });
  };

  const onRemoveCart = (checkList) => {
    if (checkList.length === 0) {
      showSnackBar("장바구니 제외할 플렛폼 데이터를 선택하세요");

      return;
    }

    Axios.all(
      checkList.map((platform_main_id) => {
        return client.delete("/user/cartupdate", {
          data: {
            platform_main_id,
          },
        });
      }),
    )
      .then((response) => {
        console.log("[cartLikes ask]", response);
        showSnackBar("장바구니에서 제외하였습니다.");
        getData();
      })
      .catch((error) => {
        setSnackBar((pre) => ({
          ...pre,
          open: true,
          message: error.response.data.msg ?? "",
        }));
        console.log("[cartLikes ask error]", error.response);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return { data, snackBar, onClick, onRemoveCart };
}
