import React, { useRef } from "react";
import BarStorage from "views/temp/storageManagement/BarStorage";
import { useParams } from "react-router-dom";

import FolderItem from "./FolderItem";
import FileItem from "./FileItem";
import { ListItem, ListItemText, Typography, Grid } from "@mui/material";

import ModalContractQrFilev2 from "components/modal/ModalContractQrFilev2";

const Directory = ({
  storage,
  selectFolder,
  selectFile,
  qrcode,
  onCreateFolderClick,
  onDeleteStorageFolderClick,
  onUploadStorageFilePopupClick,
  onUploadStorageUploadPopupClick,
  onUploadStorageUploadPopupClose,
  onUploadStorageDownloadPopupClick,
  onUploadStorageDownloadPopupClose,
  onFolderClick,
  onFolderMoveClick,
  onFileClick,
  onFileMoveClick,
  onFileInfoClick,
  onFileDownloadClick,
  onUploadStorageFileChange,
  onMoveStorageFile,
  onDeleteStorageFileClick,
}) => {
  const { storage_sub_id, folder_id } = useParams();
  const fileRef = useRef(null);

  return (
    <>
      <ModalContractQrFilev2
        isOpen={qrcode.isShowUploadPopup}
        qrcode={qrcode.code}
        onClose={onUploadStorageUploadPopupClose}
        handleClose={onUploadStorageUploadPopupClose}
        handleOnlyQrFileClose={onUploadStorageUploadPopupClose}
      />
      <ModalContractQrFilev2
        isOpen={qrcode.isShowDownloadPopup}
        qrcode={qrcode.code}
        onClose={onUploadStorageDownloadPopupClose}
        handleClose={onUploadStorageDownloadPopupClose}
        handleOnlyQrFileClose={onUploadStorageDownloadPopupClose}
      />
      <BarStorage
        onNewFolderClick={() => {
          // const name = prompt("폴더명을 입력하세요");

          // if (name) {
          //   onCreateFolderClick({
          //     storage_sub_id: storage_sub_id,
          //     subscription_id: "",
          //     folder_name: name,
          //     parent_folder_id: folder_id === "root" ? "" : folder_id,
          //   })();
          // }
          onCreateFolderClick({
            storage_sub_id: storage_sub_id,
            subscription_id: "",
            parent_folder_id: folder_id === "root" ? "" : folder_id,
          })();
        }}
        onNewDataClick={() => {
          onUploadStorageFilePopupClick();
        }}
        onMoveParentFolderClick={() => {
          const folderInfo = selectFolder

          if (folderInfo) {
            if (folderInfo.parent_folder_id) {
              onFolderClick({ folder_id: folderInfo.parent_folder_id })();
            } else {
              onFolderClick({ folder_id: "root" })();
            }
          } else {
            onFolderClick({ folder_id: "root" })();
          }
        }}
      />
      <input
        style={{ display: "none" }}
        ref={fileRef}
        type="file"
        multiple={true}
        onChange={onUploadStorageFileChange}
      />
      <div
        style={{
          width: "100%",
          height: "90%",
          overflow: "auto",
        }}
      >
        <ListItem>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>폴더</Typography>
          </ListItemText>
        </ListItem>
        <Grid container>
          {storage?.folder_info
            .filter(
              (folder) =>
                (folder_id === "root" && !folder.parent_folder_id) ||
                folder.parent_folder_id === folder_id,
            )
            .map((folder) => (
              <Grid item xs={12} md={6} key={folder.folder_id}>
                <FolderItem
                  storage={storage}
                  folder={folder}
                  onFolderClick={onFolderClick}
                  onFolderMoveClick={onFolderMoveClick}
                  onDeleteStorageFolderClick={onDeleteStorageFolderClick}
                />
              </Grid>
            ))}
        </Grid>
        <ListItem>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>파일</Typography>
          </ListItemText>
        </ListItem>

        <Grid container spacing={1}>
          {storage?.file_info
            .map((file) => (
              <Grid item xs={12} md={6} key={file.upload_file_id}>
                <FileItem
                  storage={storage}
                  file={file}
                  select={selectFile?.upload_file_id === file.upload_file_id}
                  onFileClick={onFileClick}
                  onFileInfoClick={onFileInfoClick}
                  onFileMoveClick={onFileMoveClick}
                  onFileDownloadClick={onFileDownloadClick}
                  onMoveStorageFile={onMoveStorageFile}
                  onDeleteStorageFileClick={onDeleteStorageFileClick}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default Directory;
