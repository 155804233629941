// hooks/usePlatformDetailQuery.js
import { useQuery } from "@tanstack/react-query";
// import client from "assets/js/commonApi";
// import client from "assets/js/commonApi";
import client from "assets/js/commonApi";

const fetchPlatformDetail = (platform_main_id) => {
  return client.post(`/platformitem/select`, {
    // params: { platform_main_id },
    platform_main_id,
  });
};

export const usePlatformDetailQuery = (platform_main_id) => {
  return useQuery({
    queryKey: ["platform-detail", platform_main_id],
    queryFn: () => fetchPlatformDetail(platform_main_id),
    select: (result) => result.data.data,
    staleTime: 300000, // 5분
  });
};
