import axios from "axios";

const client = axios.create({
  baseURL: "https://u-stc.net:8821",
  //   baseURL: "http://10.10.10.26:9001", // 개발
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default client;
