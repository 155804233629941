import React, { useEffect, useState, useRef, useContext } from "react";
import { MessageContext } from "assets/js/syntax";
import axios from "axios";
import client from "assets/js/commonApi";
// import { ContractHtmlToText } from "assets/js/contractUtil";
import {
  ContractHtmlToText,
  ContractElementToText,
  GetContractValueArrayForText,
} from "assets/js/contractUtil";
import { styled } from "@mui/material/styles";
import { v4 } from "uuid";
import CryptoJS, { AES } from "crypto-js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import IconTxtBtn from "components/btns/IconTxtBtn";
import PaperComponent from "components/paper/PaperComponent"; // paper
import InputContent from "components/inputBox/InputContent"; // 내용
import DatePic from "components/picker/DatePic"; // 날짜 선택
import ContractRadioBtn from "components/radio/ContractRadioBtn"; // 레디오 버튼
// import {
//   // ContractRadioDataProvided,
//   // ContractRadioPayment,
//   // ContractRadioProcessingAtEnd,
//   // ContractRadioFileType,
// } from "../radio"; // 레디오 버튼
import {
  // CONTRACT_TYPE_LABELS,
  PAYMENT_TYPE_LABELS,
  PROCESSING_AT_END_LABELS,
  DATA_PROVIDED_LABELS,
  FILE_TYPE_LABELS,
} from "assets/hooks/constants/contractRadioButtonLabels";

import ContractAccordion from "components/accordion/ContractAccordion"; // 아코디언
// import SelectCompany from "components/selectBox/SelectCompany"; // 회사 및 담당자 선택
import ModalContractHandlerSelect from "./ModalContractHandlerSelect"; // 담당자 선택
import ModalPaymentRequestForm from "./ModalPaymentRequestForm"; // 결제 요청
import InputDisable from "components/inputBox/InputDisable"; // 계약 담당자 - 선택 후 값 자동 저장 240305
import BtnNDragUploadFile from "components/BtnStyle/BtnNDragUploadFile"; // 파일 업로드
import ModalContractQrFileDown from "./ModalContractQrFileDown";
import {
  ContractNeedAddInput, // 유효성 경고
  InputFormDlg, // 비고 폼
  ModalContractQr, // 서명
  SnackbarMessage, // 파일 업 및 다운로드 알림
} from "."; // 스낵바는 특정 작업(성공,실패,알림,정보 등)에 대한 가벼운 피드백을 제공
import {
  SignedStatusView, // 계약서 하단 서명뷰
  FileDownloadAreaView, // 다운로드 이동 영역 뷰
} from "../viewContents/modalContractView";
import { CircularIndeterminate } from "components/loaders/CircularIndeterminate"; // 로딩 중 표시
import { AppContext } from "App";
import { SelectForm } from "components/selectBox/SelectForm";

// 쿼리
import { useGroupSelectQuery } from "assets/hooks/useCategory/useSelectQuery";

// 큐알 초기값
// const initQRcode = {
//   type: "storage/key",
//   data: {
//     pulling_key: "",
//     encrypted_key: "",
//     // storage_sub_id: "",
//     contract_sub_id: "",
//   },
// };
const access = sessionStorage.getItem("access");
const refresh = sessionStorage.getItem("refresh");
// const contractorID = sessionStorage.getItem("user_id");
// const connectedUserID

const FormData = require("form-data");

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: "8px auto",
  display: "flex",
  flex: 1,
  color: theme.palette.text.primary,
}));

const ModalDirectContract = ({
  onClose,
  did,
  contract_sub_id,
  company_info_type, // 여기서 부모한테 넘겨주고 있음
  onRefresh, // 새로고침 props로 판매페이지로 넘겨주기
}) => {
  const { triggerRefresh } = useContext(MessageContext); // 전역 계약창 정보 업데이트 시 리프레쉬
  // 페이지 이동
  const navigate = useNavigate();
  const fileListPageMove = () => {
    navigate("/filelist");
    console.log("## fileListPageMove", fileListPageMove);
  };

  // 로그인 유저 정보
  // const company = sessionStorage.getItem("company");
  const userDID = sessionStorage.getItem("did");
  // const userName = sessionStorage.getItem("name");

  // 240513 로그인 정보 업데이트
  const company = sessionStorage.getItem("company");
  const userName = sessionStorage.getItem("name");
  // 240711 로그인 정보 업데이트
  const contractorID = sessionStorage.getItem("user_id");
  console.log("Debug## - contractorID", contractorID);
  console.log("Debug## - userName", userName);
  console.log("Debug## - company", company);

  // useName을 무조건 적으로 받아놓는게 아니라,
  // 회사도 마찬가지임
  // 이 컴페니 & 유저네임의 경우 판매자의 경우 데이터를 매핑해준거라고 생각된다.,
  //담아주는 데이터에서도 코드처리는 물론,,
  // 저거로 데이터를 처리하기보다. 만약에 데이터를 받아온다면, 백에서 던져주는 데이터 매핑하기
  //  판매 구매내역 리스트를 클릭했을때 받아오는 데이터 있잖슴,
  // 머 판매자 구매자에 따라 값이 판매자 아이디 네임 회사로 들어가고

  // 쿼리
  const selectedGroupQuery = useGroupSelectQuery();
  // const { data: groups } = selectedGroupQuery;
  const { data: groups, isLoading, isError } = selectedGroupQuery;

  console.log("@@@###groups", groups);

  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [contractData, setContractData] = useState({}); // 계약서 데이터
  // const [stepType, setStepType] = useState(); // 스텝타입
  console.log("## contractData", contractData);
  const [recital, setRecital] = useState(""); // 내용
  const [preamble, setPreamble] = useState(); // 표준 계약서
  const [appendix, setAppendix] = useState(); // 부속 계약서
  const [preambleData, setPreambleData] = useState([
    company,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // 240416 추가
  const [appendixData, setAppendixData] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // 240416 추가

  // const onPreambleChange = (event, newData) => {
  //   setPreambleData(newData);
  // };

  // const onAppendixChange = (event, newData) => {
  //   setAppendixData(newData);
  // };

  // 수정한 데이터 변화를 감지를 못해서 변화안된것으로 인식해서 리로드가 되지 않은 것임
  // onChange를 감지한 변수를 담아주지 못해서
  const onPreambleChange = (event, newData) => {
    const preamble = ContractElementToText(preambleRef.current); // 표준
    setPreambleData(newData); // 배열만 담아주기 위해서 // 단순히 배열로만 변경하는것
    setPreamble(preamble); // 새로운 값으으로 안바껴서 / 전체내용 // 아코디언에서 이것만 감지해서
  };

  const onAppendixChange = (event, newData) => {
    const appendix = ContractElementToText(appendixRef.current); // 부속
    setAppendixData(newData);
    setAppendix(appendix);
  };

  const [from_dtm, setFrom_dtm] = useState(dayjs()); // 시작 날짜
  const [to_dtm, setTo_dtm] = useState(dayjs()); // 종료 날짜
  const [amount, setAmount] = useState(null); // 계약 금액
  const [finalCnt, setfinalCnt] = useState(null); // 데이터 총 제공 횟수
  const [paymentValue, setPaymentValue] = useState("500"); // 레디오 버튼(결재)
  const [processingAtEndValue, setProcessingAtEndValue] = useState("600"); // 레디오 버튼(종료시 처리)
  const [dataProvidedValue, setDataProvidedValue] = useState("701"); // 레디오 버튼(데이터 제공 방식)
  const [fileTypeValue, setFileTypeValue] = useState("800"); // 레디오 버튼(제공 형태)
  // const selectedContractTypeLabel = PAYMENT_TYPE_LABELS[paymentValue];
  const selectedPaymentTypeLabel = PAYMENT_TYPE_LABELS[paymentValue];
  const selectedProcessingAtEndLabel =
    PROCESSING_AT_END_LABELS[processingAtEndValue];
  const selectedDataProvidedLabel = DATA_PROVIDED_LABELS[dataProvidedValue];
  const selectedFileTypeLabel = FILE_TYPE_LABELS[fileTypeValue];

  const [buyerId, setBuyerId] = useState("");
  // const [selectedGroup, setSelectedGroup] = useState(null); // 그룹 선택창
  const [selectedGroup, setSelectedGroup] = useState({
    value: "",
    label: "그룹 선택",
  });

  // const [groupId, setGroupId] = useState("");

  // *** 계약담당자선택 팝업 open NEW S
  // ** 검색창 테스트 S
  const [selectedCompanyValue, setSelectedCompanyValue] = useState(); // 선택된 회사명
  const [selectedManagerValue, setSelectedManagerValue] = useState(); // 선택된 담당자
  const onSelectedCoNMgrChange = ({ coName, mgrName, userId }) => {
    setSelectedCompanyValue(coName);
    setSelectedManagerValue(mgrName);
    setBuyerId(userId);
  };
  // ** 검색창 테스트 E
  const [handleBtn, setHandleBtn] = useState(); // 버튼
  const [isOpenModalHandlerSelect, setIsOpenModalHandlerSelect] =
    useState(false); // 팝업 open

  // *** 계약담당자선택 팝업 open NEW E

  //
  //
  //
  //

  // ********* 결제 요청자 선택 팝업 open NEW S
  // ** 검색창 테스트 S
  const [selectedUserIdValue, setSelectedUserIdValue] = useState(); // 선택된 사용자 ID
  const [selectedUserNameValue, setSelectedUserNameValue] = useState(); // 선택된 사용자 이름ㄴ
  const onSelectedUserChange = ({ userId, userName, groupId }) => {
    setSelectedUserIdValue(userId);
    setSelectedUserNameValue(userName);
    setSelectedGroup(groupId);
  };
  // ** 검색창 테스트 E
  // const [handlePayRequestBtn, setHandlePayRequestBtn] = useState(); // 버튼
  const [isOpenModalPayRequest, setIsOpenModalPayRequest] = useState(false); // 팝업 open
  // ********* 결제 요청자 팝업 open NEW E

  const [companyData, setCompanyData] = useState([]); // 회사명/담당자 기본 데이터
  // // 회사명 선택 콤보박스 { value: '회사코드', label: '회사명' }
  const [companyValue, setCompanyValue] = useState({});

  console.log("##companyValue", companyValue);

  const [companyInputValue, setCompanyInputValue] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const onCompanyValueChange = (event, value) => setCompanyValue(value);
  const onCompanyInputChange = (event, value) => setCompanyInputValue(value);
  // 담당자 선택 콤보박스 { value: 'did', label: '이름' }
  const [userValue, setUserValue] = useState({});
  const [userInputValue, setUserInputValue] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const onUserValueChange = (event, value) => setUserValue(value);
  const onUserInputChange = (event, value) => setUserInputValue(value);
  const onCompanyNUserGetOptionLabel = (option) =>
    option && option.label ? option.label : ""; // 회사 담당자 공통

  const preambleRef = useRef(); // 표준계약서
  const appendixRef = useRef(); // 부속계약서

  // 구매자 수정요청 수정완료 S
  const [isEditable, setIsEditable] = useState(false); // 수정 가능 상태
  const [reloadTrigger, setReloadTrigger] = useState(false);

  const [isEditing, setIsEditing] = useState(false); // 버튼 상태 변화

  // 구매자 수정요청 수정완료 E

  // 판매자 102(구매자 수정요청한) S
  const [contractHist, setContractHist] = useState({
    remark: null,
    hist_from_dtm: null,
    hist_to_dtm: null,
    hist_price: null,
    types: {
      termination_type: null,
      response_type: null,
      data_type: null,
      payment_type: null,
    },
    hist_body: Array(12).fill(null), // 길이 12의 null로 초기화
    hist_appendix: Array(7).fill(null), // 길이 7의 null로 초기화
  });
  console.log("##@@contractHist.hist_body", contractHist.hist_body);
  console.log("##@@contractHist.hist_appendix", contractHist.hist_appendix);

  // 판매자 102(구매자 수정요청한) E
  console.log("types:", contractHist.types);
  console.log("##@@contractHist:", contractHist && contractHist);
  // 파일 기능 (다운로드)
  const key = v4().toString();
  const fileInputRef = useRef(null);

  // BtnNDragUploadFile.js 파일 제한 및 기타코드 수정 >>> 필요없는내용있늗듯, 코드분석해서 삭제할것 삭제 하기

  // 파일 기능(업로드) S **
  const [uploadFile, setUploadFile] = useState(null); // 파일 업로드
  const [open, setOpen] = useState(false); // 파일 업로드 확장자 예외처리

  // const handleUpdateRemark = (newRemark) => {
  //   console.log("Updated Remark:", newRemark); // 새 비고 내용 로그
  //   reUpdate();
  //   setRemark(newRemark); // 비고 폼 닫을 시 상태 업데이트
  //   // setIsOpenVigoDlg(false); // 비고 폼 닫기 버튼
  // };

  // 파일 변경
  const onFileChange = (e) => {
    console.log(`## asdfffsfsafsaf`);
    try {
      const files = e.target.files;
      if (files.length > 0) {
        setUploadFile(files[0]);

        // 1. 선택한 파일 불러옴
        const file = files[0];
        console.log(`## FILE`, `${file}`);

        // 2. 선택한 파일을 base64로 변환
        const fileReader = new FileReader();
        console.log(`## KEY`, `${key}`);
        fileReader.readAsDataURL(file);
        fileReader.onload = ({ target }) => {
          const base64 = target.result;
          console.log("## FILE", target.result);
          // 3. 암호화
          const encode = AES.encrypt(base64, key).toString();
          console.log("## FILE ENCODE", encode);

          const _files = new FormData();
          console.log("## FILE BB", _files);
          const _blob = new Blob([encode]);
          _files.append("file", _blob, file.name);

          // 3.1 파일 전송하기 (uRL 이 달라 8079와 달라 client 모듈 사용 불가)
          const url = "http://ipfs.busan-coldchain.com:5001/api/v0/add";

          axios
            .post(url, _files, {
              headers: {},
            })
            .then((value) => {
              console.log("## UPLOAD", value);
            })
            .catch((reason) => {
              console.log("## UPLOAD ERROR", reason);
            });

          // 4. 복호화
          const decode = AES.decrypt(encode, key).toString(CryptoJS.enc.Utf8);
          console.log("## FILE DECODE", decode);
          // 5. base64를 원래 파일로 변환
          const src = decode;
          const fileName = file.name;
        };
      }
    } catch (e) {
      // 에러 구간
      // alert(e);
    }
  };
  // 파일 기능(업로드) E

  // 구매자는 비고창이 오픈되고, 판매자는 비고내용을 입력하기
  const [needAddInputOpen, setNeedAddInputOpen] = useState(false); // 비고 영역 추가 - 아 이부분 머였는지 나중에 분석
  const [remark, setRemark] = useState(); // 비고 입력
  // remark
  const [isOpenVigoDlg, setIsOpenVigoDlg] = useState(false); // 비고 창 - 아 이부분 머였는지 나중에 분석

  const [fileInfo, setFileInfo] = useState([]); // 파일 리스트 State for file info

  // 미 입력
  const handleClose = (event, reason, newRemark) => {
    if (reason === "clickaway") {
      return;
    }
    setNeedAddInputOpen(false);
    setOpen(false); // 파일 확장자 예외처리 팝업 닫힘 기능
    // setRemark(newRemark); // 비고 폼 닫을 시 상태 업데이트
    setIsOpenVigoDlg(false); // 비고 폼 닫기 버튼
    setIsOpenModalHandlerSelect(false); // 담당자 선택 닫기 버튼
    setIsOpenModalPayRequest(false); // 결제 요청 닫기 버튼
  };

  // 비고 - 사용할 코드
  const handleClickOpen = (params) => {
    if (contractData.step_type > "100") {
      setIsOpenModalHandlerSelect(false);
    } else {
      // alert("setIsOpenVigoDlg");
      setIsOpenVigoDlg(true); // 비고 - 기능구현 전 상태
      setIsOpenModalHandlerSelect(true); // 담당자 선택 - 팝업 open State
      setHandleBtn(params.target.value);
    }
    console.log("## setIsOpenVigoDlg", setIsOpenVigoDlg);
  };

  // 결제 요청 오픈
  const handleClickPayRequestOpen = (params) => {
    console.log("## handleClickPayRequestOpen", handleClickPayRequestOpen);
    // alert("setIsOpenVigoDlg");
    setIsOpenModalPayRequest(true); // 담당자 선택 - 팝업 open State
    // setHandlePayRequestBtn(params.target.value);
  };

  // 데이터 바인딩 구문 S **
  // 요약 템플릿과 셀렉터를 인자로 받아, 해당 셀렉터와 일치하는 첫 번째 자식 요소를 찾아 그 값을 반환
  const extractValue = (template, selector) =>
    template.firstElementChild.querySelector(selector)?.value;
  // 주어진 HTML 문자열을 사용하여 새로운 HTML 템플릿을 생성
  // 새로운 div 요소를 생성하고, 그 내부 HTML을 제공된 HTML 문자열로 설정한 후 해당 요소를 반환
  const createTemplate = (html) => {
    const template = document.createElement("div");
    template.innerHTML = html.trim();
    return template;
  };
  // 데이터 확인용 - 기능구현 최종완료 후 삭제(객체의 키-값 쌍)
  const logValues = (prefix, values) => {
    Object.entries(values).forEach(([key, value]) => {
      console.log(`## ${prefix} ${key}`, value);
    });
  };

  // 입력(선택-숫자)된 요소와 키 매핑
  const selectorsP = {
    "#sell_company": "sellCompany",
    "#buy_company": "buyCompany",
    "#data_type": "dataType",
    "#day": "day",
    "#d_range": "dRange",
    "#c_range": "cRange",
    "#s_day": "sDay",
    "#count": "count",
    "#year": "year",
    "#v_day": "vDay",
    "#r_day": "rDay",
    "#court": "court",
  };
  const selectorsX = {
    "#process": "process",
    "#process_data_type": "processDataType",
    "#process_month": "processMonth",
    "#process_d_rate": "processDRate",
    "#process_d_count": "processDCount",
    "#process_c_rate": "processCRate",
    "#process_c_count": "processCCount",
  };

  // 재저장 & 재등록
  const reUpdate = (step_type, btnSU, isModificationRequest = false) => {
    console.log("Updating - Loading starts"); // 로딩 시작 로그
    setIsMessageLoading(true); // 요청 전 로딩 시작

    let url = company_info_type === "seller" ? "/sell/update" : "/buy/update";
    //   if (isModificationRequest) {
    //     step_type = "102"; // Set to '102' for modification requests
    // }
    const preamble = ContractElementToText(preambleRef.current); // 표준
    const appendix = ContractElementToText(appendixRef.current); // 부속

    // 참조된 요소들의 외부 HTML을 콘솔에 출력
    console.log("## REF PREAMBLE", preamble);
    console.log("## REF APPENDIX", preamble);

    // 템플릿 생성
    const templateP = createTemplate(preamble);
    const templateX = createTemplate(appendix);

    // 계약서 타입(레디오버튼)
    const hist_types = {
      termination_type: processingAtEndValue,
      response_type: dataProvidedValue,
      data_type: fileTypeValue,
      payment_type: paymentValue,
    };

    // 계약서 히스토리 이력
    const contract_hist = {
      // remark: company_info_type === "buyer" ? remark : null, // 바이어일 때
      // remark: company_info_type === "buyer" && remark,
      remark: remark,
      // remark: company_info_type === "buyer" ? needAddInputOpen : null, // 바이어일 때 입력 값, 판매자일 때 null // 수정 요청 사항 메시지 입력 > 등록 후에 입력되는 내용 매핑
      hist_to_dtm: to_dtm.format("YYYY-MM-DD"), // 동일
      hist_from_dtm: from_dtm.format("YYYY-MM-DD"), // 동일
      types: hist_types || null,
      hist_price: amount, // 동일하게
      hist_body: preambleData, // input값만 빼서 배열 형태
      hist_appendix: appendixData, // input값만 빼서 배열 형태
    };

    const headers = { responseType: "stream" };
    const groupSelectionValue = selectedGroup
      ? selectedGroup.value
      : selectedGroup;
    // 서버에 보낼 데이터 객체 매핑
    const requestData = {
      buyer_id: buyerId,
      // seller_group_id: selectedGroup,
      // seller_group_id: groupSelectionValue, // selectedGroup 함수처리 결과 할당
      // [company_info_type === "seller" ? "seller_group_id" : "buyer_group_id"]:
      //   groupSelectionValue,
      // [company_info_type === "seller" ? "seller_group_id" : "buyer_group_id"]:
      //   selectedGroup,
      seller_group_id:
        company_info_type === "seller" ? selectedGroup.value : null,
      buyer_group_id:
        company_info_type === "buyer" ? selectedGroup.value : null,
      contract_title: recital,
      // step_type: step_type,
      step_type: company_info_type === "seller" ? step_type : "102",
      to_dtm: to_dtm.format("YYYY-MM-DD"),
      from_dtm: from_dtm.format("YYYY-MM-DD"),
      contract_price: amount,
      payment_type: paymentValue,
      termination_type: processingAtEndValue,
      response_type: dataProvidedValue,
      data_type: fileTypeValue,
      contract_type: "001", // 고정타입 // 계약서 양식 다르니깐 박아두면 된다.
      contract_body: preamble, // 표준 계약서
      contract_appendix: appendix, // 부속 계약서

      contract_sub_id: contract_sub_id, // contractNumber 여기서 리스트 페이지로 넘겨주고 있음
      final_cnt: finalCnt, // 계약정보내 있는거
      contract_hist: contract_hist || null,
    };
    // S10_A
    // S20_A
    // stepS10A
    // stepS20A
    // let url = company_info_type === "seller" ? "/sell/select" : "/buy/select";
    // 판매/ 구매 계약서 업데이트 : 조건에 따라 매핑

    client
      .post(url, requestData, { headers })
      .then((response) => {
        console.log("Updating - Loading ends"); // 로딩 종료 로그
        console.log("#response", response);
        const { status, data } = response;
        console.log("Response status:", status);
        console.log("Response message:", data.msg);

        const { step_type } = contractData;
        const isSeller = company_info_type === "seller";
        const isBuyer = company_info_type === "buyer";
        // const { company_info_type } = company_info;
        // (response.data.msg === "success")
        // if (response.status === 200 && step100)
        if (status === 200) {
          let shouldClose = true; // 기본적으로는 창을 닫는 것을 허용
          setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
          switch (step_type) {
            case "100":
              if (btnSU === "S") {
                alert("저장 하였습니다");
                shouldClose = false; // 저장버튼을 눌렀을 때는 창을 닫지 않음
              } else if (isSeller && btnSU === "U") {
                alert("등록 하였습니다");
              }
              break;
            case "101":
              if (isBuyer) {
                alert("수정요청 완료 하였습니다");
              }
              break;
            case "102":
              alert("수정 등록 하였습니다");
              break;
            case "105":
              alert("결제 요청 하였습니다. 앱(App)에서 결제 진행해주세요.");
              break;
          }
          if (shouldClose && onClose) onClose();
          onRefresh();
        }
        // console.log("#response", response);
        // console.log("Response status:", response.status);
        // console.log("Response message:", response.data.msg);
        // const step100 = contractData.step_type === "100";
        // const step101 = contractData.step_type === "101";
        // const step102 = contractData.step_type === "102";
        // const step105 = contractData.step_type === "105";
        // const isSeller = company_info_type === "seller";
        // const isBuyer = company_info_type === "buyer";
        // console.log("##company_info_type", company_info_type);
        // step100
        // if (response.status === 200 && step100) {
        //   alert("저장 하였습니다");
        //   onRefresh();
        // }
        // if (response.status === 200) {
        //   // step100
        //   if (step100) {
        //     if (btnSU === "S") {
        //       alert("저장 하였습니다");
        //       onRefresh();
        //     } else if (isSeller && btnSU === "U") {
        //       alert("등록 하였습니다");
        //       if (onClose) onClose(); // ModalContractQrFile Dialog onClose를 props로 받아 조건문에 넣음
        //       onRefresh();
        //     }
        //   }
        //   if (step101 || step102 || step105) {
        //     // step101
        //     if (step101) {
        //       if (isBuyer) {
        //         alert("수정요청 완료 하였습니다");
        //         if (onClose) onClose();
        //         onRefresh();
        //       }
        //     }
        //     // step102
        //     if (step102) {
        //       alert("수정 등록 하였습니다");
        //       if (onClose) onClose();
        //       onRefresh();
        //     }
        //     // step105
        //     if (step105) {
        //       alert("결제 요청 하였습니다. 앱(App)에서 결제 진행해주세요.");
        //       if (onClose) onClose();
        //       onRefresh();
        //     }
        //   }
        // }
      })
      .catch((error) => {
        console.log("Updating - Error occurred, Loading ends"); // 에러 발생 및 로딩 종료 로그
        console.error("API request failed:", error); // 네트워크 요청 실패 로그
        console.log("Error response:", error.response); // 오류 응답 로그
        alert("An error occurred, please try again.");
        setIsMessageLoading(false); // 오류 발생 시 로딩 종료
      });
    // 바인딩 할 때 구문 E
  };

  // 데이터 바인딩 구문 E **

  // 파일명 조회(계약번호/ 판매회사명/ 파일명/ 계약기간/ 진행상황/ 기타) S

  const handleOnlyQrFileClose = () => {
    setIsOpenSignQrModal(false);
  };
  // 파일명 조회(계약번호/ 판매회사명/ 파일명/ 계약기간/ 진행상황/ 기타) E

  // 계약관리 - 계약서 조회/ 저장/ 수정
  useEffect(() => {
    let url = company_info_type === "seller" ? "/sell/select" : "/buy/select";
    client
      .post(
        url,
        { contract_sub_id: contract_sub_id },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // access: access,
            // refresh: refresh,
          },
        },
      )
      .then((res) => {
        // let data = res.data.data[0];
        let data = res.data.data;
        console.log("## 원본 데이터", data);
        if (data) {
          setContractData(data);
          // setStepType(data.step_type);
          setRecital(data.contract_title);
          setFrom_dtm(dayjs(data.from_dtm));
          setTo_dtm(dayjs(data.to_dtm));
          setAmount(data.contract_price);
          setfinalCnt(data.final_cnt);
          // setSelectedGroup(data.seller_group_id);
          setSelectedCompanyValue(data.buyer_company_name);
          setSelectedManagerValue(data.buyer_name);
          setBuyerId(data.buyer_id);
          setFileInfo(data.file_info);
          // 표준 계약서 부속 계약서
          setPreamble(data.contract_body);
          setAppendix(data.contract_appendix);
          setPreambleData(GetContractValueArrayForText(data.contract_body));
          setAppendixData(GetContractValueArrayForText(data.contract_appendix));
          setPaymentValue(data.payment_type);
          setProcessingAtEndValue(data.termination_type);
          setDataProvidedValue(data.response_type);
          setFileTypeValue(data.data_type);
          // setPreambleData(data.contract_body);
          // setAppendixData(data.contract_appendix);
          //
          //
          // 이거는 선택한것을 담아오는데 셀러이냐 바이어이냐에 따라 달라짐
          // 그리고 상태가 100 101에 따라 데이터를 처리
          // S 그룹 데이터 진행단계 에 따른 처리 1 - 나머지는 데이터 받아왔을때 처리해주는 부분 있음
          // 조건에 따라 그룹 선택

          //
          if (company_info_type === "seller") {
            setSelectedGroup({
              value: data.seller_group_id || "",
              label: data.seller_group_name || "그룹 선택",
            });
          } else if (company_info_type === "buyer") {
            setSelectedGroup({
              value: data.buyer_group_id || "",
              label: data.buyer_group_name || "그룹 선택",
            });
          }
          // if (groups) {
          //   setSelectedGroup({
          //     value: data.seller_group_id,
          //     label: data.seller_group_name,
          //   });
          // }
          if (data && data.contract_hist) {
            const {
              remark,
              hist_from_dtm,
              hist_to_dtm,
              hist_price,
              types,
              hist_body,
              hist_appendix,
            } = data.contract_hist;
            const defaultTypes = {
              termination_type: types["termination_type"] || null,
              response_type: types["response_type"] || null,
              data_type: types["data_type"] || null,
              payment_type: types["payment_type"] || null,
            };
            // // types 객체 내에서 누락된 값에 대해 null을 기본값으로 설정
            // const defaultTypes = {
            //   termination_type: null,
            //   response_type: null,
            //   data_type: null,
            //   payment_type: null,
            // };

            // types가 정의되지 않았거나 null인 경우 defaultTypes를 사용
            // const normalizedTypes = types
            //   ? { ...defaultTypes, ...types }
            //   : defaultTypes;
            // const normalizedTypes = types ? { ...defaultTypes } : defaultTypes;
            setContractHist({
              remark,
              hist_from_dtm,
              hist_to_dtm,
              hist_price,
              types: defaultTypes,
              hist_body, // 배열이 포함된 상태 데이터 업데이트
              hist_appendix,
              // remark: remark,
              // hist_from_dtm: hist_from_dtm,
              // hist_to_dtm: hist_to_dtm,
              // hist_price: hist_price,
              // types: defaultTypes,
              // hist_body: hist_body, // 배열이 포함된 상태 데이터 업데이트
              // hist_appendix: hist_appendix, // 배열이 포함된 상태 데이터 업데이트
            });
          }
        }
        //   // groups에서 buyer_group_name에 해당하는 group_id를 찾아 selectedGroup을 설정
        //   if (company_info_type === "buyer" && groups) {
        //     const matchedGroup = groups.find(
        //       (group) => group.group_name === data.buyer_group_name,
        //     );
        //     if (matchedGroup) {
        //       setSelectedGroup({
        //         value: matchedGroup.group_id,
        //         label: matchedGroup.group_name,
        //       });
        //     } else {
        //       // 매칭되는 그룹 이름이 없을 경우 초기화
        //       setSelectedGroup({ value: "", label: "" });
        //     }
        //   }
        // } else {
        //   console.log("## 데이터 없음");
        // }
        //
        //
        //
        //

        //   if (company_info_type === "seller") {
        //     setSelectedCompanyValue(data.seller_company_name);
        //     setSelectedManagerValue(data.seller_name);
        //     setBuyerId(data.seller_id);
        //     if (groups) {
        //       setSelectedGroup({
        //         value: data.seller_group_id,
        //         label: data.seller_group_name,
        //       });
        //     }
        //   } else if (company_info_type === "buyer") {
        //     setSelectedCompanyValue(data.buyer_company_name);
        //     setSelectedManagerValue(data.buyer_name);
        //     setBuyerId(data.buyer_id);
        //     if (groups && data.step_type === "101") {
        //       setSelectedGroup({
        //         value: data.buyer_group_id || "",
        //         label: data.buyer_group_name || "",
        //       });
        //     }
        //   }
        // } else {
        //   console.log("## 데이터 없음");
        // }

        // E 그룹 데이터 진행단계 에 따른 처리 1 - 나머지는 데이터 받아왔을때 처리해주는 부분 있음
        // if (groups) {
        //   setSelectedGroup({
        //     value: data.seller_group_id,
        //     label: data.seller_group_name,
        //   });
        // }

        // 그룹 ID로 매핑하여 그룹 이름 설정
        // if (groups) {
        //   const matchedGroup = groups.find(
        //     (group) => group.group_id === data.seller_group_id,
        //   );
        //   if (matchedGroup) {
        //     setSelectedGroup({
        //       value: matchedGroup.group_id,
        //       label: matchedGroup.group_name,
        //     });
        //   }
        // }
        // // 그룹 ID로 매핑하여 그룹 이름 설정
        // const matchedGroup = groups?.find(
        //   (group) => group.group_id === data.seller_group_id,
        // );
        // if (matchedGroup) {
        //   setSelectedGroup({
        //     value: matchedGroup.group_id,
        //     label: matchedGroup.group_name,
        //   });
        // }
        //
        // // 그룹 ID로 매핑하여 그룹 이름 설정
        // if (groups && groups.length > 0) {
        //   const matchedGroup = groups.find(
        //     (group) => group.group_id === data.seller_group_id,
        //   );
        //   if (matchedGroup) {
        //     setSelectedGroup({
        //       value: matchedGroup.group_id,
        //       label: matchedGroup.group_name,
        //     });
        //   }
        // }

        console.log("## 표준계약서", data.preamble);
        console.log("## 부속계약서", data.appendix);
        console.log("## 계약서 모달창 데이터", data);
        console.log("## 테스트용contract_sub_id", contract_sub_id);
        console.log("## data_type_name", data.data_type_name);
      });
  }, [did, contract_sub_id, company_info_type, groups, reloadTrigger]);

  console.log("Current selected group:", selectedGroup);

  // 계약서 데이터 - 표준/부속/레디오버튼 데이터 받아온것
  // useEffect(() => {
  //   if (contractData) {
  //     console.log("## PAYMENT", contractData);

  //     setPreamble(contractData.contract_body);
  //     setAppendix(contractData.contract_appendix);
  //     setPreambleData(GetContractValueArrayForText(contractData.contract_body));
  //     setAppendixData(
  //       GetContractValueArrayForText(contractData.contract_appendix),
  //     );
  //     setPaymentValue(contractData.payment_type);
  //     setProcessingAtEndValue(contractData.termination_type);
  //     setDataProvidedValue(contractData.response_type);
  //     setFileTypeValue(contractData.data_type);
  //   }
  // }, [contractData]);

  console.log("## userDID", userDID);
  console.log("## contractData.DID_BUYER", contractData.did_buyer);
  console.log("## contractData.DID_SELLER", contractData.did_seller);
  console.log("## contractData.STEP_TYPE", contractData.step_type);
  console.log("## contractData.SIGN_BUYER_YN", contractData.sign_buyer_yn);
  console.log("## contractData.SIGN_SELLER_YN", contractData.sign_seller_yn);

  // 구매자 확인 그룹 아이디 유효성 체크
  const ValidationGroupID = () => {
    // selectedGroup의 value 속성을 검사하여 유효한지 확인
    if (!selectedGroup || !selectedGroup.value) {
      console.log("## 유효성 에러: 그룹 ID 선택 필요");
      setMessage("그룹 선택");
      return false;
    }
    return true; // 유효성 검사 통과
  };

  // 구매자 확인 버튼 S
  const check = () => {
    // 바인딩 할 때 구문
    const headers = {
      responseType: "stream",
    };

    console.log("## Check function called");
    console.log("## Selected Group:", selectedGroup);

    if (!ValidationGroupID()) {
      setNeedAddInputOpen(true);
      return;
    }
    console.log("Updating - Loading starts"); // 로딩 시작 로그
    setIsMessageLoading(true); // 요청 전 로딩 시작
    client
      .post(
        "/buy/confirm",
        {
          contract_sub_id: contract_sub_id,
          buyer_group_id: selectedGroup.value,
          // buyer_group_id: buyer_group_id,
        },
        { headers: headers },
      )
      .then((response) => {
        console.log("## Server response:", response);
        setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
        if (response.status === 200) {
          alert("확인 완료 되었습니다.", contract_sub_id);
          console.log("## contract_sub_id", contract_sub_id);
          console.log("## selectedGroup", selectedGroup);
          console.log("## selectedGroup", selectedGroup.value);
          if (onClose) onClose();
        } else {
          alert("서버에서 문제가 발생했습니다. 다시 시도해주세요.");
        }
      })
      .catch((error) => {
        // Handle error.
        console.log("## An error occurred:", error.response);
        setIsMessageLoading(false); // 데이터 처리 완료 후 로딩 종료
      });
  };
  // ** 구매자 확인 버튼 E

  // ** 구재마 수정 요청 S
  // 수정 API 호출을 처리하는 함수
  // const buyUpdate = () => {
  //   const requestData = {
  //     contract_sub_id: contract_sub_id,
  //     // step_type: contractData.step_type,
  //     step_type: "102",
  //     // 필요한 추가 데이터를 여기에 포함시킵니다.
  //   };

  //   client
  //     .post("/buy/update", requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${access}`, // API 인증 토큰이 필요할 경우
  //       },
  //     })
  //     .then((response) => {
  //       alert("수정이 완료되었습니다.");
  //       onRefresh();
  //       // 성공적으로 수정되면 할 행동 (예: 모달 닫기, 상태 업데이트 등)
  //     })
  //     .catch((error) => {
  //       console.error("수정 중 에러 발생:", error);
  //       alert("수정에 실패하였습니다.");
  //     });
  // };

  // ** 구재마 수정 요청 E

  // 파일 확장자
  // 파일 확장자 예외처리 필요없을 시 추후 코드 삭제
  const getFileAccept = (dataTypeName) => {
    const acceptedTypes = ["json", "csv", "excel", "txt", "XML", "zip", "pdf"];
    return acceptedTypes.includes(dataTypeName) ? (
      `.${dataTypeName}`
    ) : (
      // <SnackbarMessage message="선택한 파일 유형은 현재 지원되지 않습니다. 다른 유형의 파일을 선택해주세요." />
      // 나중에
      <SnackbarMessage
        message="선택한 파일 유형만 지원됩니다."
        open={open}
        handleClose={handleClose}
      />
    );
  };

  // S 파일업로드 > 기존에 판매자 계약서에서 보여주었지만, 스토리지 관리에서 파일을 직접업로드 > 불필요 코드
  const FileUploadArea = ({
    did,
    company_info_type,
    contractData,
    encryptKey,
  }) => {
    if (company_info_type === "seller") {
      if (
        contractData.step_type === "106" ||
        contractData.step_type === "107"
      ) {
        //계약완료상태 파일업로드 영역 추가 됨
        return (
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography component="h2" variant="subtitle2">
                파일 업로드
              </Typography>
              <StyledPaper variant="outlined" sx={{ justifyContent: "center" }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  sx={{
                    mt: 1,
                    justifyContent: "center",
                  }}
                >
                  <BtnNDragUploadFile
                    fileName={uploadFile?.name}
                    // value={name}
                    encryptKey={encryptKey}
                    contract_sub_id={contract_sub_id}
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                    // handleClose
                    handleQrUploadClose={handleQrClose}
                    accept={getFileAccept(contractData.data_type_name)}
                  />
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
        );
      }
    }
    return null;
  };
  // E 파일업로드 > 기존에 판매자 계약서에서 보여주었지만, 스토리지 관리에서 파일을 직접업로드 > 불필요 코드

  // disabled S
  // const DisabledTrue =
  //   contractData.step_type === "102" || contractData.step_type === "100"
  //     ? false
  //     : true;
  // const DisabledTrue = contractData.step_type > "100" ? true : false;
  // const DisabledTrue = contractData.step_type > "100" && !isEditable;
  // const DisabledTrue =
  //   (contractData.step_type > "100" || contractData.step_type === "102") &&
  //   !isEditable;
  const DisabledTrue =
    !(
      company_info_type === "seller" &&
      (contractData.step_type === "100" || contractData.step_type === "102")
    ) && !isEditable;

  // 판매자면서
  // 100이나 102 스텝 타입이 아닌 경우 편집모드 비활성화
  // const DisabledTrue =
  // !(
  //   contractData.step_type === "100" ||
  //   (!(company_info_type = "buyer") && contractData.step_type === "102")
  // ) && !isEditable;
  // const DisabledTrueBuy =
  // !(contractData.step_type === "100" || contractData.step_type === "101") &&
  // !isEditable;

  const DisabledTrueBuy = contractData.step_type > "101"; // step_type이이 101보다 크면 디즈블드 처리 > 등록완료 상태가 되면 디즈블드 처리
  const DisabledFix = contractData.step_type > "100"; // step_type이 100보다 크면 디즈블드 처리 10

  const handleReload = () => {
    setIsEditing(false); // 편집 상태 종료 // 버튼
    setIsEditable(false); // 입력 필드 "비" 활성화 // 입력내용에 관련한것
    setReloadTrigger(!reloadTrigger); // 현재 상태의 반대값을 설정하여 변화를 유발합니다.
    // 편집 상태를 종료하고 원래 데이터로 되돌립니다.
    // setIsEditing(false);
    // 필요하다면 원래 데이터를 다시 불러오는 로직을 여기에 추가하세요.
    // 예: setSomeData(originalData);
  };

  // const DisabledTrue = contractData.step_type > "100" && !isEditable;

  // const DisabledTrue = contractData.step_type > "100";
  // const DisabledTrue = contractData.step_type === "100";

  const handleModifyRequestClick = () => {
    setIsEditable(true); // 수정을 활성화
    // setIsEditing(true); // 편집 모드
    setIsEditing(true); // 편집 모드 활성화 버튼 화면 표시
  };

  // '수정 완료' 클릭 핸들러
  const handleModifyCompleteClick = () => {
    // reUpdate("101");
    setIsEditable(false);
    setIsEditing(false); // 편집 모드 비활성화
    setIsOpenVigoDlg(true);
    // setIsEditing(false); // 편집 모드 비활성화
    // reUpdate("101"); // 여기에 수정 사항을 서버에 저장하는 로직을 추가할 수 있습니다.
  };

  // disabled E

  // 102 && seller 인경우 데이터 표시
  // 101 && buyer && his 데이터가 있는 경우 데이터 표시

  // 계약서 버튼
  const HandleBtns = ({ did, contractData, contract_sub_id }) => {
    // const isSeller = did === contractData.did_seller;
    // const isBuyer = did === contractData.did_buyer;
    // const isSellerID =
    // const isBuyerID =
    const isSeller = company_info_type === "seller";
    const isBuyer = company_info_type === "buyer";
    const is100 = contractData.step_type === "100";
    // const is101 = contractData.step_type === "101";
    const is101 = contractData.step_type === "101";
    const is102 = contractData.step_type === "102";
    const is105 = contractData.step_type === "105";
    const isRelevantUser =
      contractorID === contractData.buyer_id ||
      contractorID === contractData.seller_id; // 현재 사용자가 일치하는지 확인
    console.log("## company_info_type:", company_info_type);
    console.log("Debug - contractorID:", contractorID);
    console.log("Debug - contractData.buyer_id:", contractData.buyer_id);
    console.log("Debug - contractData.seller_id:", contractData.seller_id);
    console.log("Debug - isRelevantUser:", isRelevantUser);
    if (!isRelevantUser) return null; // 일치하지 않으면 아무 버튼도 표시하지 않음

    if (
      (isSeller && ["103"].includes(contractData.step_type)) ||
      (isBuyer && contractData.step_type === "104")
    ) {
      return (
        <Button value={QrBtn} onClick={handleClickQrOpen}>
          서명
        </Button>
      );
    }
    // 판매자 저장/ 등록
    if (isSeller && is100) {
      return (
        <>
          <Button onClick={() => reUpdate("100", "S")}>저장</Button>
          <Button onClick={UpBtnOnclick}>등록</Button>
        </>
      );
    }

    // 판매자 저장/ 등록
    if (isSeller && is102) {
      return <Button onClick={UpBtnOnclick}>수정등록</Button>;
    }

    if (isEditing) {
      return (
        <>
          <Button
            onClick={handleReload}
            // onClick={() => {
            //   setIsEditing(false); // 편집 상태 종료 // 버튼
            //   setIsEditable(false); // 입력 필드 "비" 활성화 // 입력내용에 관련한것
            //   handleReload();
            // }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              handleModifyCompleteClick(); // 수정 완료 처리
            }}
          >
            수정 완료
          </Button>
          {/* <Button
            onClick={() => {
              handleModifyCompleteClick(); // 수정 완료 처리
            }}
          >
            수정 완료
          </Button> */}
        </>
      );
    } else if (isBuyer && is101) {
      return (
        <>
          <Button onClick={() => check()}>확인</Button>
          <Button onClick={() => handleModifyRequestClick()}>수정 요청</Button>
        </>
      );
    }
    // 구매자 결제 요청
    if (isBuyer && is105) {
      // if (isBuyer && is200) {
      return <Button onClick={handleClickPayRequestOpen}>결제 요청</Button>;
    }

    //   // if (isBuyer && is101) {
    //   return (
    //     <>
    //       <Button onClick={check}>확인</Button>
    //       <Button onClick={handleModifyRequestClick}>수정요청</Button>
    //       {/* <Button onClick={buyUpdate}>수정 완료</Button> */}
    //     </>
    //   );
    // }
    // // 구매자 확인
    // if (isBuyer && is105) {
    //   // if (isBuyer && is101) {
    //   return <Button onClick={handleClickPayRequestOpen}>결제 요청</Button>;
    // }

    // 구매자 반려 버튼 - 비고
    // if (isBuyer && is101) {
    //   return <Button onClick={handleClickOpen}>반려</Button>;
    // }
    return null;
  };
  // 반려 눌렀을때 //팝업 - 비고창 - 확인/취소//확인 눌렀을 때 수정내용 + 비고내용 같이 전송하기(백단으로)//
  console.log("## contract_sub_id", contract_sub_id);

  // 그룹선택 판매자/구매자에 따라 대입

  // *****
  // const groupSelectionValue = (() => {
  //   console.log(
  //     "Debug - company_info_type:",
  //     company_info_type,
  //     "contractData.step_type:",
  //     contractData.step_type,
  //   );

  //   // 판매자이면서 step_type이 '101' 또는 '103' 일 때
  //   if (
  //     company_info_type === "seller" &&
  //     ["101", "103"].includes(contractData.step_type)
  //   ) {
  //     return selectedGroup?.value || "";
  //   }
  //   // 구매자이면서 step_type이 '101' 일 때
  //   else if (
  //     company_info_type === "buyer" &&
  //     contractData.step_type === "101"
  //   ) {
  //     return selectedGroup?.value || "";
  //   }

  //   // 위 조건에 맞지 않는 경우, 기본적으로 selectedGroup의 value를 사용하거나 없다면 빈 문자열을 리턴
  //   return selectedGroup?.value || "";
  // })();
  // *****

  // 그룹 데이터 진행단계 에 따른 처리 2 - 나머지는 데이터 받아왔을때 처리해주는 부분 있음
  // useEffect(() => {
  //   if (groups && groups.length > 0) {
  //     // 적절한 그룹을 찾아서 상태 업데이트
  //     const defaultGroup = groups.find(
  //       (g) => g.group_id === "기본값 또는 조건",
  //     );
  //     if (defaultGroup) {
  //       setSelectedGroup({
  //         value: defaultGroup.group_id,
  //         label: defaultGroup.group_name,
  //       });
  //     }
  //   }
  // }, [groups]);

  // GroupOptions는 groups에 데이터가 있고 selectedGroup에 유효한 값이 없을 때만 생성
  // const GroupOptions =
  //   groups.length > 0 && !selectedGroup?.value
  //     ? groups.map((group) => ({
  //         value: group.group_id,
  //         label: group.group_name,
  //       }))
  //     : [];

  // const GroupOptions =
  //   groups.length > 0
  //     ? groups.map((group) => ({
  //         value: group.group_id,
  //         label: group.group_name,
  //       }))
  //     : [];

  const GroupOptions = groups
    ? groups.map((group) => ({
        value: group.group_id, // group_id 사용
        label: group.group_name, // group_name 사용
      }))
    : [];

  console.log("@@@@##GroupOptions", GroupOptions);
  console.log("@@@##selectedGroup", selectedGroup);
  // **
  // const groupSelectionValue = (() => {
  //   console.log(
  //     "Debug - company_info_type:",
  //     company_info_type,
  //     "contractData.step_type:",
  //     contractData.step_type,
  //   );
  //   if (
  //     company_info_type === "seller" &&
  //     (contractData.step_type === "101" || contractData.step_type === "103")
  //   ) {
  //     return selectedGroup?.value || "";
  //   } else if (
  //     company_info_type === "buyer" &&
  //     contractData.step_type === "101"
  //   ) {
  //     return selectedGroup;
  //   }
  //   return; // 조건에 맞지 않을 때 기본값
  // })();
  // **

  // **** 유효성 확인/ 팝업 기능 구현/ 요청함수 담기 S
  const [inputChange, setInputChange] = useState({});
  const [message, setMessage] = useState();

  // 데이터 유효성 체크
  const onVaildate = (data) => {
    setInputChange(data);
  };
  // 라벨
  // const {
  //   label00,
  //   label01,
  //   label02,
  //   label03,
  //   label04,
  //   label05,
  //   label06,
  //   label07,
  //   label08,
  //   label09,
  //   label10,
  //   label11,
  //   label12,
  //   label13,
  //   label14,
  //   label15,
  //   label16,
  //   label17,
  //   label18,
  //   label19,
  //   label20,
  //   label21,
  // } = useContext(AppContext);
  // const { messageList } = useContext(MessageContext);
  const {
    label00,
    label01,
    label02,
    label03,
    label04,
    label05,
    label06,
    label07,
    label08,
    label09,
    label10,
    label11,
    label12,
    label13,
    label14,
    label15,
    label16,
    label17,
    label18,
    label19,
    label20,
    label21,
  } = useContext(MessageContext).messageList;
  const validationRules = [
    { field: "recital", label: label00 },
    // 240514 추가 S
    { field: "amount", label: label01 },
    { field: "finalCnt", label: label02 },
    { field: "selectedGroup", label: label03 },
    { field: "buyerId", label: label04 },
    // 240514 추가 E
    { field: "dataType", label: label05 },
    { field: "day", label: label06 },
    { field: "dRange", label: label07 },
    { field: "cRange", label: label08 },
    { field: "sDay", label: label09 },
    { field: "count", label: label10 },
    { field: "year", label: label11 },
    { field: "vDay", label: label12 },
    { field: "rDay", label: label13 },
    { field: "court", label: label14 },
    { field: "process", label: label15 },
    { field: "processDataType", label: label16 },
    { field: "processMonth", label: label17 },
    { field: "processDRate", label: label18 },
    { field: "processDCount", label: label19 },
    { field: "processCRate", label: label20 },
    { field: "processCCount", label: label21 },
  ];
  console.log("## preamble ", preamble);
  console.log("## appendix ", appendix);

  // // 유효성 에 대한 팝업 표시
  // const Validation = () => {
  //   // 내용
  //   if (recital.length === 0) {
  //     console.log("## [유효성 에러] 내용 입력", label00);
  //     setMessage(label00);
  //     return false;
  //   }
  //   // 선택 & 표준계약서(부속)
  //   for (const rule of validationRules.slice(1)) {
  //     const fieldValue = inputChange[rule.field]; // 동적으로 필드에 대한 값을 가져오기
  //     console.log(`## fieldValue ${rule.field}`, fieldValue);
  //     // 현재 룰의 필드에 해당하는 입력 데이터가 없거나 길이가 0이면 아래의 코드를 실행
  //     if (!fieldValue) {
  //       console.log(`## 유효성 에러 ${rule.field}`, rule.label);
  //       setMessage(rule.label); // 유효성 에러 메시지
  //       return false; // 유효성 검사에서 실패시 false를 반환하고 함수를 종료
  //     }
  //   }
  //   return true; // 모든 룰을 통과하여 유효성 검사 통과했을 시 true 반환
  // };

  // 유효성 에 대한 팝업 표시
  const Validation = () => {
    // // 내용
    // if (recital.length === 0) {
    //   console.log("## [유효성 에러] 내용 입력", label00);
    //   setMessage(label00);
    //   return false;
    // }
    // 초기 필드 검사
    const initialFields = [
      { value: recital, label: label00 }, // 제목 = 기존내용
      { value: amount, label: label01 }, // 합계 금액
      { value: finalCnt, label: label02 }, // 데이터 총 제공 횟수
      { value: selectedGroup, label: label03 }, // 그룹 ID
      // { value: selectedGroup && selectedGroup !== "", label: label03 }, // 그룹 ID
      { value: buyerId, label: label04 }, // 계약 담당자 선택
    ];

    // 초기 필드 검사 수행
    for (const { value, label } of initialFields) {
      if (!value) {
        console.log(`## [유효성 에러]`, label);
        setMessage(label);
        return false; // 실패 시 검사 중단
      }
    }

    // 선택 & 표준계약서(부속)
    for (const rule of validationRules.slice(5)) {
      // 처음 5 개 항목을 제외한 나머지 검사
      const fieldValue = inputChange[rule.field]; // 동적으로 필드에 대한 값을 가져오기
      console.log(`## fieldValue ${rule.field}`, fieldValue);
      // 현재 룰의 필드에 해당하는 입력 데이터가 없거나 길이가 0이면 아래의 코드를 실행
      if (!fieldValue) {
        console.log(`## 유효성 에러 ${rule.field}`, rule.label);
        setMessage(rule.label); // 유효성 에러 메시지
        return false; // 유효성 검사에서 실패시 false를 반환하고 함수를 종료
      }
    }
    return true; // 모든 검사를 통과하면 true 반환
  };
  // **** 유효성 확인 E

  // 구매자 수정 시 기존 데이터 아래 표시 - 기능적용 및 실 데이터 적용 후 (예시 추후 삭제하기)
  let PreContent = [
    // "기존 내용 00", // 00
    "계약기간 23/11/16 01", // 01
    "계약기간 23/11/16 02", // 02
    "금액값 03 ", // 03
    "데이터 총 제공 횟수 04", // 04
    "결재 05 ", // 05
    "종료시 처리 06", // 06
    "데이터 제공 방식 07", // 07
    "제공 형태 08", // 08
    // "기존 제공 형태 06", // 06
    // "기존 회사명 선택 07", // 07
    // "기존 당당자 선택 08", // 08
  ];

  // onClick 등록버튼
  const UpBtnOnclick = () => {
    console.log("## recital", recital);
    console.log("## recital.length", recital.length);
    if (Validation() === false) {
      return setNeedAddInputOpen(true);
    } else {
      setNeedAddInputOpen(false);
      // reUpdate("101");
      reUpdate("101", "U");
    }
    // triggerRefresh();
  };

  // **** 유효성 확인/ 팝업 기능 구현/ 요청함수 담기 S
  // 서명 판매자용 뷰
  const SellerSigned = ({ contractData }) => (
    <SignedStatusView contractData={contractData} isSeller={true} />
  );
  // 서명 구매자용 뷰
  const BuyerSigned = ({ contractData }) => (
    <SignedStatusView contractData={contractData} isSeller={false} />
  );
  // 서명 QR
  const [QrBtn, setQrBtn] = useState();
  const [isOpenSignQrModal, setIsOpenSignQrModal] = useState(false);

  const handleClickQrOpen = (params) => {
    setIsOpenSignQrModal(true);
    setQrBtn(params.target.value);
  };
  const handleQrClose = () => {
    setIsOpenSignQrModal(false);
    if (onClose) {
      onClose();
    }
  };

  // ****** 240116 코드추가 S

  // 기존 contractData와 비교하여서 같으면 null, 다르면 변경값을 지정하여 반환함
  const motificateContractData = () => {
    // 변경사항 확인하기
    const _data = {
      contract_sub_id:
        contractData.contract_sub_id === contract_sub_id
          ? null
          : contract_sub_id,
      payment_type:
        contractData.payment_type === paymentValue ? null : paymentValue,
      did_buyer:
        contractData.did_buyer === userValue.value ? null : userValue.value,
      termination_type:
        contractData.termination_type === processingAtEndValue
          ? null
          : processingAtEndValue,
      to_dtm:
        contractData.to_dtm === to_dtm.format("YYYY-MM-DD")
          ? null
          : to_dtm.format("YYYY-MM-DD"),
      from_dtm:
        contractData.from_dtm === from_dtm.format("YYYY-MM-DD")
          ? null
          : from_dtm.format("YYYY-MM-DD"),
      recital:
        contractData.recital === recital ? null : recital ? null : recital,
      response_type: contractData.response_type ? null : dataProvidedValue,
      data_type: contractData.data_type ? null : fileTypeValue,
    };
    for (const key in _data) {
      if (!_data[key]) delete _data[key];
    }

    return _data;
  };

  // ****** 240116 코드추가 E

  // 금액 & 데이터 총 제공 횟수
  // const handleCountChange = (event) => {
  //   const { name, value } = event.target;
  //   // const countValue = value === "" ? 0 : Number(value);
  //   const countValue = value === "" ? 0 : Math.max(Number(value), 0);
  //   console.log("입력된 값:", countValue); // 입력값 로깅

  //   if (name === "amount") {
  //     // setAmount(countValue);
  //     setAmount(countValue !== null ? countValue : 0);
  //   } else if (name === "finalCnt") {
  //     // setfinalCnt(countValue);
  //     setfinalCnt(countValue !== null ? countValue : 0);
  //   }
  //   // setAmount(countValue);
  //   // setfinalCnt(countValue);
  // };
  //
  const handleCountChange = (event) => {
    const { name, value } = event.target;
    let countValue;

    if (value === "") {
      countValue = ""; // 입력이 완전히 지워졌을 때 빈 문자열로 설정
    } else {
      // 첫 번째 자리가 '0'이고, 길이가 1을 초과하는 경우 '0'을 제거
      if (value.startsWith("0") && value.length > 1) {
        countValue = value.replace(/^0+/, "");
      } else {
        countValue = value;
      }

      // 음수 입력 방지 및 숫자 변환
      countValue = Math.max(Number(countValue), 0);
    }

    console.log("입력된 값:", countValue); // 입력값 로깅

    // 각 필드에 맞게 상태 업데이트
    if (name === "amount") {
      setAmount(countValue.toString()); // 문자열로 변환하여 상태 업데이트
    } else if (name === "finalCnt") {
      setfinalCnt(countValue.toString()); // 문자열로 변환하여 상태 업데이트
    }
  };

  // 스타일
  const DisabledSX = {
    "& .Mui-disabled": {
      color: "#000000DE !important",
      "-webkit-text-fill-color": "#000000DE !important",
    },
    "& .MuiRadio-root": {
      p: 0,
    },
  };
  // 선택 - 타입별
  const TypeTypographySX = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    color: "red",
    fontSize: "0.75rem",
  }));

  const TypeTypographySX2 = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    color: "red",
    fontSize: "0.75rem",
    marginTop: 4,
  }));

  // if (!groups) {
  //   return <div>Loading...</div>;
  // }
  // useEffect(() => {
  //   if (groups && groups.length > 0 && contractData.seller_group_id) {
  //     const matchedGroup = groups.find(
  //       (group) => group.group_id === contractData.seller_group_id,
  //     );
  //     if (matchedGroup) {
  //       setSelectedGroup({
  //         value: matchedGroup.group_id,
  //         label: matchedGroup.group_name,
  //       });
  //     }
  //   }
  // }, [groups, contractData.seller_group_id]);
  // // 그룹 ID에 맞는 그룹 이름 찾기
  // useEffect(() => {
  //   if (groups && selectedGroup) {
  //     const group = groups.find((g) => g.group_id === selectedGroup);
  //     if (group) {
  //       setSelectedGroup({
  //         value: group.group_id,
  //         label: group.group_name,
  //       });
  //     }
  //   }
  // }, [groups, selectedGroup]);

  // useEffect(() => {
  //   if (groups && groups.length > 0) {
  //     // 첫 번째 그룹을 기본적으로 선택
  //     setSelectedGroup({
  //       value: groups[0].group_id,
  //       label: groups[0].group_name,
  //     });
  //   }
  // }, [groups]);

  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Dialog
          open={true}
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          {isMessageLoading && <CircularIndeterminate />}
          <DialogTitle
            component="h1" // 헤딩태그 지정
            variant="h6" // 변형 사이즈
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            일반&#40;직접&#41; 계약서
          </DialogTitle>
          {/* // 타이틀 */}
          <DialogContent>
            {/* {contractData.contract_sub_id && ( */}
            <Grid
              item
              container
              wrap="nowrap"
              spacing={2}
              sx={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item>
                <Typography component="h2" variant="subtitle2">
                  계약번호
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  id="contract_sub_id"
                  component="body1"
                  variant="subtitle2"
                >
                  {contractData.contract_sub_id}
                </Typography>
              </Grid>
            </Grid>
            {/* )} */}
            {/* 계약번호 */}
            <Grid
              sx={{ flexGrow: 1 }}
              item
              xs={12}
              sm
              container
              direction="column"
            >
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography component="h2" variant="subtitle2">
                    제목
                  </Typography>
                  <StyledPaper variant="outlined">
                    <InputContent
                      type="string"
                      value={recital}
                      placeholder="제목 입력"
                      inputProps={{ "aria-label": "제목 입력" }}
                      onChange={(event) => {
                        setRecital(event.target.value);
                        console.log("## 제목 입력", event.target.value);
                      }}
                      disabled={DisabledFix}
                      // disabled={DisabledTrue}
                      sx={DisabledSX}
                      // helperText={PreContent[0]}
                    />
                  </StyledPaper>
                </Grid>
              </Grid>
              {contractHist.remark && (
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography component="h3" variant="subtitle2">
                      비고&#40;요청사항&#41;
                    </Typography>
                    <StyledPaper variant="outlined">
                      {/* <InputContent
                      type="string"
                      value={recital}
                      placeholder="제목 입력"
                      inputProps={{ "aria-label": "제목 입력" }}
                      onChange={(event) => {
                        setRecital(event.target.value);
                        console.log("## 제목 입력", event.target.value);
                      }}
                      disabled={DisabledFix}
                      // disabled={DisabledTrue}
                      sx={DisabledSX}
                      // helperText={PreContent[0]}
                    /> */}

                      <Typography>{contractHist.remark}</Typography>

                      {/* <Typography >
                      {contractHist.remark}
                    </Typography> */}
                    </StyledPaper>
                  </Grid>
                </Grid>
              )}
              {/* 판매자 비고 내용 확인 */}
              {/* 제목 */}
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography component="h2" variant="subtitle2">
                    선택
                  </Typography>
                  <StyledPaper variant="outlined" sx={{ paddingBottom: "0" }}>
                    <Box sx={{ p: 2 }}>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "8px", width: "calc(100% + 49px)" }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              sx={{ mt: 0.5 }}
                            >
                              계약기간
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <DatePic
                              component="dd"
                              fromValue={from_dtm}
                              toValue={to_dtm}
                              fromInputFormat={"yyyy-MM-dd"}
                              fromMask={"____-__-__"}
                              toInputFormat={"yyyy-MM-dd"}
                              toMask={"____-__-__"}
                              fromFormat="YYYY/MM/DD"
                              toFormat="YYYY/MM/DD"
                              fromOnChange={(newValue) => setFrom_dtm(newValue)}
                              toOnChange={(newValue) => setTo_dtm(newValue)}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {/* {contractData.from_dtm} */}
                            {/* <Typography
                              sx={{ fontSize: "0.75rem", color: "red" }}
                            > */}

                            <TypeTypographySX2>
                              {contractHist.hist_from_dtm &&
                                " 시작일 : " +
                                  contractHist.hist_from_dtm +
                                  " ~ "}
                              {contractHist.hist_to_dtm &&
                                "종료일 : " + contractHist.hist_to_dtm}
                            </TypeTypographySX2>

                            <TypeTypographySX2>
                              {/* 기존 선택 계약기간 01" */}
                              {/* {contractHist.remark} */}
                              {/* {contractHist.hist_from_dtm}
                              {contractHist.hist_to_dtm} */}
                              {/* {PreContent[0]} ~ {PreContent[1]} */}
                            </TypeTypographySX2>
                          </Grid>
                        </Grid>
                        {/* 계약기간 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              // gutterBottom
                            >
                              금액
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* <Stack
                              gap={0.5}
                              sx={{ display: "flex", flexDirection: "row" }}
                            > */}
                            <TextField
                              name="amount"
                              label="금액"
                              type="number"
                              // sx={{ m: 1, width: "25ch" }}
                              size="small"
                              value={amount}
                              // onChange={(e) => setAmount(e.target.value)}
                              onChange={handleCountChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    원
                                  </InputAdornment>
                                ),
                              }}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                              // InputProps={{
                              //   startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                              // }}
                              // InputLabelProps={{
                              //   endAdornment: (
                              //     <InputAdornment position="end">
                              //       kg
                              //     </InputAdornment>
                              //   ),
                              // }}
                            />
                            {contractHist.hist_price && (
                              <TypeTypographySX2>
                                {contractHist.hist_price} 원
                              </TypeTypographySX2>
                            )}
                            {/* <TypeTypographySX2
                              sx={{ fontSize: "0.75rem", color: "red" }}
                            > */}
                            {/* 기존 선택 계약기간 01" */}
                            {/* {PreContent[2]} */}

                            {/* {contractHist.hist_price} */}
                            {/* </TypeTypographySX2> */}
                            {/* <Typography
                                // variant="h6"
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  fontSize: 18,
                                }}
                              >
                                원
                              </Typography>
                            </Stack> */}
                          </Grid>
                        </Grid>
                        {/* 계약 금액 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              // gutterBottom
                            >
                              데이터 총
                              <Typography
                                component="span"
                                // variant="h5"
                                sx={{ display: "flex", fontSize: "inherit" }}
                              >
                                제공 횟수
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* <Stack
                              gap={0.5}
                              sx={{ display: "flex", flexDirection: "row" }}
                            > */}
                            <TextField
                              name="finalCnt"
                              label="데이터 총 제공 횟수"
                              type="number"
                              // sx={{ m: 1, width: "25ch" }}
                              size="small"
                              value={finalCnt}
                              // onChange={(e) => setAmount(e.target.value)}
                              onChange={handleCountChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    회
                                  </InputAdornment>
                                ),
                              }}
                              disabled={DisabledFix}
                              sx={DisabledSX}
                            />
                            {/* <Typography
                              sx={{ fontSize: "0.75rem", color: "red" }}
                            > */}

                            {/* {contractHist && (
                              <TypeTypographySX2>
                                {contractHist.hist_price} 원
                              </TypeTypographySX2>
                            )} */}
                            {/* {contractHist.types.payment_type} */}
                          </Grid>
                        </Grid>
                        {/* 데이터 총 제공 횟수 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              결제
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={paymentValue}
                              onChange={(value) => setPaymentValue(value)}
                              contractTypeLabels={PAYMENT_TYPE_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {/* 기존 선택 결제 03 */}
                            {/* <TypeTypographySX>{PreContent[4]}</TypeTypographySX> */}

                            {contractHist.types && (
                              <TypeTypographySX>
                                {/* {contractHist.types.payment_type} */}
                                {
                                  PAYMENT_TYPE_LABELS[
                                    contractHist.types.payment_type
                                  ]
                                }
                              </TypeTypographySX>
                            )}
                          </Grid>
                        </Grid>
                        {/* 결제 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "1px", mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              종료시 처리
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={processingAtEndValue}
                              onChange={(value) =>
                                setProcessingAtEndValue(value)
                              }
                              contractTypeLabels={PROCESSING_AT_END_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {contractHist.types && (
                              <TypeTypographySX>
                                {/* {contractHist.types.response_type} */}
                                {/* {
                                  PROCESSING_AT_END_LABELS[
                                    contractHist.types.response_type
                                  ]
                                } */}{" "}
                                {
                                  PROCESSING_AT_END_LABELS[
                                    contractHist.types.termination_type
                                  ]
                                }
                              </TypeTypographySX>
                            )}
                            {/* <TypeTypographySX>{PreContent[4]}</TypeTypographySX> */}
                          </Grid>
                        </Grid>
                        {/* 종료시 처리 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mb: "1px", mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              데이터 제공 방식
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <ContractRadioBtn
                              typeValue={dataProvidedValue}
                              onChange={(value) => setDataProvidedValue(value)}
                              contractTypeLabels={DATA_PROVIDED_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {contractHist.types && (
                              <TypeTypographySX>
                                {/* {contractHist.types.data_type} */}
                                {/* {selectedDataProvidedLabel} */}
                                {/* {
                                  DATA_PROVIDED_LABELS[
                                    contractHist.types.data_type
                                  ]
                                } */}{" "}
                                {
                                  DATA_PROVIDED_LABELS[
                                    contractHist.types.response_type
                                  ]
                                }
                              </TypeTypographySX>
                            )}
                            {/* <TypeTypographySX>{PreContent[5]}</TypeTypographySX> */}
                            {/* <ContractRadio /> */}
                            {/* </Typography> */}
                          </Grid>
                        </Grid>
                        {/* 데이터 제공 방식 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              제공 형태
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* ㄴㄴ */}
                            <ContractRadioBtn
                              typeValue={fileTypeValue}
                              onChange={(value) => setFileTypeValue(value)}
                              contractTypeLabels={FILE_TYPE_LABELS}
                              disabled={DisabledTrue}
                              sx={DisabledSX}
                            />
                            {/* <TypeTypographySX>{PreContent[6]}</TypeTypographySX> */}
                            {contractHist.types && (
                              <TypeTypographySX>
                                {/* {contractHist.types.payment_type} */}
                                {/* {selectedFileTypeLabel} */}
                                {/* {
                                  FILE_TYPE_LABELS[
                                    contractHist.types.termination_type
                                  ]
                                } */}
                                {FILE_TYPE_LABELS[contractHist.types.data_type]}
                              </TypeTypographySX>
                            )}
                          </Grid>
                        </Grid>
                        {/*  제공 형태 */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          // rowSpacing={2}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item>
                            <Typography component="dt" variant="subtitle2">
                              그룹 선택
                            </Typography>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <SelectForm
                              // value={
                              //   selectedGroup.value
                              //     ? selectedGroup.value
                              //     : selectedGroup
                              // }
                              // value={selectedGroup?.value || selectedGroup}
                              value={selectedGroup?.value || ""}
                              // onSelectChange={setSelectedGroup}
                              onSelectChange={(value) => {
                                setSelectedGroup({
                                  value,
                                  label:
                                    GroupOptions.find(
                                      (option) => option.value === value,
                                    )?.label || "그룹 선택",
                                });
                              }}
                              // onSelectChange={setSelectedGroup}
                              // value={selectedGroup?.value || selectedGroup}
                              // value={selectedGroup ? selectedGroup.value : ""}
                              // onSelectChange={setSelectedGroup}
                              // value={selectedGroup.value}
                              // value={selectedGroup.value}
                              // value={selectedGroup ? selectedGroup.value : ""}
                              // value={selectedGroup?.value || ""}
                              // onSelectChange={(item) => setSelectedGroup(item)}

                              // onSelectChange={(value) => {
                              //   if (!value) {
                              //     setSelectedGroup({
                              //       value: "",
                              //       label: "그룹 선택",
                              //     });
                              //   } else {
                              //     setSelectedGroup(value);
                              //   }
                              // }}
                              options={GroupOptions}
                              label="그룹 선택"
                              placeholder="그룹 선택"
                              disabled={DisabledTrueBuy}
                              sx={DisabledSX}
                              // value={selectedGroup?.value || ""}
                              // value={groupSelectionValue}
                              // value={selectedGroup}
                              // onSelectChange={setSelectedGroup}
                              // options={sortOptions}
                            />
                          </Grid>
                        </Grid>
                        {/* 그룹 ID */}
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          sx={{
                            mt: 1,
                            display: "flex",
                            width: "calc(100% + 49px)",
                          }}
                        >
                          <Grid item>
                            <Typography
                              component="dt"
                              variant="subtitle2"
                              gutterBottom
                            >
                              계약 담당자
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs
                            // xs={12}
                            zeroMinWidth
                            // direction="column"
                            gap={2}
                            // spacing={3}
                            sx={{
                              // width: "100%",
                              display: "flex",
                              // flexGrow: "1",
                            }}
                          >
                            {/* <IconButton
                              size="large"
                              aria-label="search"
                              color="inherit"
                              variant="contained"
                            >
                              <Typography component="span" variant="subtitle2">
                                회사명 & 담당자 선택
                              </Typography>
                            </IconButton> */}
                            <Grid item container>
                              <IconTxtBtn
                                value={handleBtn}
                                onClick={handleClickOpen}
                              />
                            </Grid>
                            <Grid
                              item
                              container
                              zeroMinWidth
                              spacing={2}
                              sx={{ flexGrow: 1 }}
                            >
                              <Grid item xs={12} md={6}>
                                <InputDisable
                                  label="회사명"
                                  // contractData.seller_name
                                  value={selectedCompanyValue}
                                  focused={
                                    selectedCompanyValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  InputLabelProps={
                                    selectedCompanyValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  disabled={
                                    !selectedCompanyValue ||
                                    (!onSelectedCoNMgrChange && true)
                                  }
                                  // onChange={onSelectedCoNMgrChange}
                                  // onSelectedCoNMgrChange={
                                  //   onSelectedCoNMgrChange
                                  // }
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <InputDisable
                                  label="담당자명"
                                  value={selectedManagerValue}
                                  focused={
                                    selectedManagerValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  InputLabelProps={
                                    selectedManagerValue ||
                                    (onSelectedCoNMgrChange && true)
                                  }
                                  disabled={
                                    !selectedManagerValue ||
                                    (!onSelectedCoNMgrChange && true)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/*  계약 담당자 */}
                      </Grid>
                      {/* </Box> IN */}
                    </Box>
                    {/* </Box> */}
                  </StyledPaper>
                  {/* 선택 paper */}
                </Grid>
                {/* 선택 인 */}
              </Grid>
              {/* 선택 */}
              {/* <FileUploadArea
                company_info_type={company_info_type}
                encryptKey={key}
                contractData={contractData}
              /> */}
              {/* 파일 업로드 추후에 삭제하기 - 관련코드 삭제하기 */}
              <FileDownloadAreaView
                // did={userDID}
                file_info={fileInfo}
                company_info_type={company_info_type}
                encryptKey={key}
                contractData={contractData}
                contract_sub_id={contract_sub_id}
                // isOpenFileDownQrModal={}
                // onClick={fileListPageMove}
              />
              {/* 파일 다운로드 */}
              <ContractAccordion
                onVaildate={onVaildate}
                preambleRef={preambleRef}
                appendixRef={appendixRef}
                // sellerCompany={company}
                // sellerCompany={selectedCompanyValue}
                sellerCompany={contractData.seller_company_name} // 셀러 회사를 잡아주고 았고
                // buyerCompany={selectedCompanyValue}
                buyerCompany={contractData.buyer_company_name} // 바이어 회사를 잡아주고 있다.
                // buyerCompany={companyValue.label}
                preamble={preamble}
                appendix={appendix}
                disabled={DisabledTrue}
                // 240116 S
                // 전문 변경 이벤트
                onPreambleChange={onPreambleChange}
                // onPreambleChange={(event, newData) => {
                //   setPreambleData(newData);
                // }}
                // 부속 변경 이벤트
                onAppendixChange={onAppendixChange}
                // onAppendixChange={(event, newData) => {
                //   setAppendixData(newData);
                // }}
                // 240116 E
                histBody={
                  contractHist.hist_body && {
                    helperText02: contractHist.hist_body[2], //1
                    helperText03: contractHist.hist_body[3], //2
                    helperText04: contractHist.hist_body[4], //3
                    helperText05: contractHist.hist_body[5], //4
                    helperText06: contractHist.hist_body[6], //5
                    helperText07: contractHist.hist_body[7], //6
                    helperText08: contractHist.hist_body[8], //7
                    helperText09: contractHist.hist_body[9], //8
                    helperText10: contractHist.hist_body[10], //9
                    helperText11: contractHist.hist_body[11], //10
                    helperText12: contractHist.hist_body[12], //11
                    // dataType: contractHist.hist_body[0]
                  }
                }
                histAppendix={
                  contractHist.hist_appendix && {
                    helperText13: contractHist.hist_appendix[0],
                    helperText14: contractHist.hist_appendix[1],
                    helperText15: contractHist.hist_appendix[2],
                    helperText16: contractHist.hist_appendix[3],
                    helperText17: contractHist.hist_appendix[4],
                    helperText18: contractHist.hist_appendix[5],
                    helperText19: contractHist.hist_appendix[6],
                  }
                }
              />
              {/* 표준 계약서(부록) */}
            </Grid>
            <Box sx={{ pt: 2, pb: 2 }}>
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ justifyContent: "right", marginBottom: "8px" }}
              >
                <Grid item sx={{ alignItems: "right" }}>
                  <Typography
                    sx={{ color: "#717171" }}
                    component="h2"
                    variant="subtitle2"
                  >
                    판매자
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography
                    sx={{
                      color: "#2f3655",
                      fontWeight: "900",
                    }}
                    BorderBottomOutlined
                    component="body1"
                    variant="subtitle2"
                  >
                    {contractData.seller_name}
                    {/* {userName} */}
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <SellerSigned contractData={contractData} />
                </Grid>
              </Grid>
              {/* 판매자 */}
              <Grid
                item
                container
                wrap="nowrap"
                spacing={2}
                sx={{ justifyContent: "right", marginBottom: "8px" }}
              >
                <Grid item sx={{ alignItems: "right" }}>
                  <Typography
                    sx={{ color: "#717171" }}
                    component="h2"
                    variant="subtitle2"
                  >
                    구매자
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography
                    sx={{
                      color: "#2f3655",
                      fontWeight: "900",
                    }}
                    BorderBottomOutlined
                    component="body1"
                    variant="subtitle2"
                  >
                    {/* {userValue.label} */}
                    {/* {userValue.label} */}
                    {selectedManagerValue}
                  </Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <BuyerSigned contractData={contractData} />
                </Grid>
              </Grid>
              {/* 구매자 */}
            </Box>
            {/* </Grid> */}
            {/* 콘텐츠 in */}
          </DialogContent>
          {/* 콘텐츠 */}
          <DialogActions>
            <HandleBtns
              did={userDID}
              contractData={contractData}
              contract_sub_id={contract_sub_id}
            />
            {/* <Button onClick={onClose}>닫기</Button> */}
            <Button
              onClick={() => {
                const _data = motificateContractData();
                // 계약서 전체 데이터(JSON)
                console.log("[계약서 전체", contractData);
                // 계약서의 상단 입력부분 변경된 값을 배열로 보여줌
                console.log("[계약서 공통]", _data);
                console.log(
                  "[계약서 전문 - 텍스트]",
                  ContractHtmlToText(preambleRef.current.innerHTML),
                );
                console.log("[계약서 전문 - 값]", [preambleData]);
                console.log(
                  "[계약서 부속 - 텍스트]",
                  ContractHtmlToText(appendixRef.current.innerHTML),
                );
                console.log("[계약서 부속 - 값]", appendixData);
                onClose();
              }}
            >
              닫기
            </Button>
          </DialogActions>
          {needAddInputOpen && (
            <ContractNeedAddInput
              open={needAddInputOpen}
              handleClose={handleClose}
              message={message}
              // recital={recital}
            />
          )}
        </Dialog>
        {/* {isOpenSignQrModal && (
          <ModalContractQr
            isOpen={isOpenSignQrModal}
            handleClose={handleQrClose}
            contract_sub_id={contract_sub_id}
            handleOnlyQrFileClose={handleOnlyQrFileClose}
            company_info_type={company_info_type}
          />
        )} */}
        {isOpenSignQrModal && (
          <ModalContractQr
            isOpen={isOpenSignQrModal}
            handleClose={handleQrClose}
            contract_sub_id={contract_sub_id}
            handleOnlyQrFileClose={handleOnlyQrFileClose}
            company_info_type={company_info_type}
          />
        )}
      </Box>
      {/* 파일 구문 */}
      <input
        type="file"
        style={{ display: "none" }}
        multiple={false}
        ref={fileInputRef}
        onChange={onFileChange}
      />
      {/* 비고 입력 폼 */}

      {isOpenVigoDlg && (
        <InputFormDlg
          isMessageLoading={isMessageLoading}
          // open={isOpenVigoDlg}
          // // reUpdate={reUpdate}
          // reUpdate={handleUpdateRemark}
          // onClose={handleClose}
          // remark={remark}
          open={isOpenVigoDlg}
          value={remark}
          // onChange={(e) => setRemark(e.target.value)}
          // handleConfirm={reUpdate}
          // // reUpdate={handleUpdateRemark}
          // onClose={handleClose}
          onChange={(e) => {
            console.log("@@@@###Before Change:", remark); // 변경 전 상태 출력
            setRemark(e.target.value);
            console.log("@@@@###After Change:", e.target.value); // 변경 후 입력된 값 출력
          }}
          // handleConfirm={() => {
          //   console.log("@@@@###Confirm Clicked, Current Remark:", remark); // 확인 클릭 시 현재 비고 상태 출력
          //   reUpdate(remark); // 여기에 필요한 파라미터를 추가할 수 있습니다.
          // }}
          handleConfirm={() => reUpdate(remark)}
          // handleConfirm={reUpdate}
          onClose={() => {
            console.log("Dialog Closed"); // 닫기 버튼 클릭 시 출력
            handleClose();
          }}
        />
      )}
      {/* 담당자 선택 창 */}
      {isOpenModalHandlerSelect && (
        <ModalContractHandlerSelect
          isOpen={isOpenModalHandlerSelect}
          handleClose={handleClose}
          onFinalClickInfoChange={onSelectedCoNMgrChange}
          // onClose={handleClose}
        />
      )}
      {/* 결제 요청 창 */}
      {isOpenModalPayRequest && (
        <ModalPaymentRequestForm
          isOpen={isOpenModalPayRequest}
          handleClose={handleClose}
          onPaymentSuccess={onClose}
          onFinalClickInfoChange={onSelectedCoNMgrChange}
          contract_sub_id={contract_sub_id}
          isMessageLoading={isMessageLoading}
          // onClose={handleClose}
        />
      )}
    </>
  );
};

export { ModalDirectContract, StyledPaper };
