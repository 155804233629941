import * as React from "react";
// import Button from "@mui/material/Button";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

import {
  // Container,
  Box,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Grid,
  InputBase,
  Alert,
  AlertTitle,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,

  // RadioGroup,
  // Radio,
} from "@mui/material";

export default function ContractNeedAddInput({ open, handleClose, message }) {
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const action = (
    <React.Fragment>
      {/* <InfoIcon /> */}
      <Button color="secondary" size="small" onClick={handleClose}>
        {/* UNDO */}
        입력하기
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    // <div>
    //   {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
    //   <Snackbar
    //     open={open}
    //     autoHideDuration={10000}
    //     onClose={handleClose}
    //     // message="내용 입력이 누락 되었습니다."
    //     // message={message}
    //   >
    //     {/* <InfoIcon /> */}
    //     <SnackbarContent
    //       action={action}
    //       style={{
    //         backgroundColor: "teal",
    //       }}
    //       message={message}
    //     />
    //   </Snackbar>
    // </div>
    <Stack
      open={open}
      // spacing={2}
      // sx={{ maxWidth: 600 }}
      sx={{
        height: "100%",
        width: "100%",
        margin: "0 auto",
        position: "absolute",
        // left: "4%",
        // bottom: "4%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        // background: "#323232",
        // borderRadius: "4px",
        // pl: 1.5,
        // boxShadow: 3,
      }}
    >
      {/* <InfoIcon sx={{ color: "#fff", fontSize: "24px" }} /> */}
      <Alert
        severity="warning"
        sx={{ position: "relative", boxShadow: 3, width: "90%", zIndex: "1" }}
      >
        <AlertTitle>Warning</AlertTitle>
        This is a warning alert —{" "}
        <strong style={{ fontWeight: "600" }}>{message}</strong>
        {/* <SnackbarContent
          sx={{
            minWidth: "unset !important",
            // background: "#eef2f5",
            transition: "0",
            boxShadow: "0",
            // color: "#29304a",
            // fontWeight: "500",
            // pl: 1.5,
            // "& .MuiPaper-root-MuiSnackbarContent-root": {
            //   minWidth: "unset !important",
            // },
          }}
          // message={message}
          action={action}
        /> */}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1.5%", top: "2.5%" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>

      {/* <SnackbarContent
        sx={{
          minWidth: "unset !important",
          // background: "#eef2f5",
          transition: "0",
          boxShadow: "0",
          // color: "#29304a",
          // fontWeight: "500",
          // pl: 1.5,
          // "& .MuiPaper-root-MuiSnackbarContent-root": {
          //   minWidth: "unset !important",
          // },
        }}
        message={message}
        action={action}
      /> */}
      {/* <SnackbarContent
        message={
          "I love candy. I love cookies. I love cupcakes. \
        I love cheesecake. I love chocolate."
        }
      /> */}
      {/* <SnackbarContent
        message="I love candy. I love cookies. I love cupcakes."
        action={action}
      />
      <SnackbarContent
        message={
          "I love candy. I love cookies. I love cupcakes. \
        I love cheesecake. I love chocolate."
        }
        action={action}
      /> */}
    </Stack>
  );
}
