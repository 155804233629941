import * as React from "react";
import { useNavigate, redirect } from "react-router-dom";
import Intro from "views/Intro";
import PropTypes from "prop-types";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  Button,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function MainBottomCard(props) {
  const navigate = useNavigate();
  const introPageMove = () => {
    navigate("/intro");
    console.log("introPageMove", introPageMove);
  };

  const { card } = props;

  // const MainBottomCardSx = {

  //   "& .MuiCardContent-root:last-child": {
  //     paddingBottom: 0,
  //   },

  // };
  // const ButtonSxBase = styled(Button)({
  //   boxShadow: 'none',
  //   textTransform: 'none',
  //   fontSize: 16,
  //   padding: '6px 12px',
  //   border: '1px solid',
  //   lineHeight: 1.5,
  //   backgroundColor: '#0063cc',
  //   borderColor: '#0063cc',
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  //   '&:hover': {
  //     backgroundColor: '#0069d9',
  //     borderColor: '#0062cc',
  //     boxShadow: 'none',
  //   },
  //   '&:active': {
  //     boxShadow: 'none',
  //     backgroundColor: '#0062cc',
  //     borderColor: '#005cbf',
  //   },
  //   '&:focus': {
  //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  //   },
  // });

  // const ColorButton = styled(Button)(({ theme }) => ({
  //   color: theme.palette.getContrastText(purple[500]),
  //   backgroundColor: purple[500],
  //   '&:hover': {
  //     backgroundColor: purple[700],
  //   },
  // }));

  return (
    <Grid item xs={12} md={4}>
      <Card>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 60,
              height: 60,

              display: { xs: "none", sm: "block" },
            }}
            image={card.image}
            alt={card.imageLabel}
          />
          {/* </Box> */}

          {/* <CardContent sx={{ flex: 1 }}> */}
          <CardContent
            // sx={MainTopCardSx}
            sx={{
              p: 0,
              pb: "0 !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "flex-end",
                // mt: 1,
                p: 0,
                // padding: "0 !impprtant",
                //   m: 2,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                }}
                component="h4"
                variant="h5"
              >
                {card.title}
              </Typography>
              {/* <Typography
                sx={{ fontSize: "22px", color: "#468ECD", fontWeight: 500 }}
                variant="subtitle1"
                //   color="text.secondary"
              >
                {card.date}
              </Typography> */}
              <Typography
                sx={{
                  mt: 1,
                  // fontSize: "12px",
                  // color: "#468ECD"
                }}
                component="p"
                variant="subtitle1"
                //   paragraph
              >
                {card.description}
              </Typography>
              {/* <Typography variant="subtitle1" color="primary">
              Continue reading...
            </Typography> */}
              <CardActions sx={{ p: 0, mt: 2 }}>
                <Button // color="#468ECD"
                  sx={{ borderColor: "#468ECD", color: "#468ECD" }}
                  target="_blank"
                  // href="/intro" // 이거 나중에, 프롭스로 넘겨주기 !!!!!!!!!!!!!!!!!!!!!!!!!!!
                  onClick={introPageMove}
                  size="small"
                  variant="outlined"
                  endIcon={<KeyboardArrowRightIcon />}
                >
                  알아보기
                </Button>
              </CardActions>
            </Box>
          </CardContent>
        </Box>
      </Card>
      {/* </CardActionArea> */}
    </Grid>
  );
}

MainBottomCard.propTypes = {
  card: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainBottomCard;
