import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

export default function DatePic({
  fromValue,
  toValue,
  fromOnChange,
  toOnChange,
  fromInputFormat,
  fromMask,
  toInputFormat,
  toMask,
  fromFormatter,
  toFormatter,
  // allFormat,
  fromFormat,
  toFormat,
  disabled,
  sx,
}) {
  // const [fromValue, setFromValue] = React.useState(dayjs("2023-09-14"));
  // const [toValue, setToValue] = React.useState(dayjs("2023-09-14"));
  // console.log("fromValue ", fromValue);
  // console.log("toValue ", toValue);

  // export default function DatePic({ from_dtm, to_dtm }) {
  // export default function DatePic() {

  // React.useEffect(() => {
  //   setFromValue(dayjs(from_dtm));
  //   setToValue(dayjs(to_dtm));
  //   // alert(JSON.stringify(from_dtm));
  // }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker"]}>
        {/* 제어되지 않는 */}
        {/* <DatePicker
          label="Uncontrolled picker"
          defaultValue={dayjs("2022-04-17")}
        /> */}
        {/* 제어되는 */}
        <DatePicker
          label="시작일"
          inputFormat={fromInputFormat}
          mask={fromMask}
          // inputFormat={"yyyy-MM-dd"}
          // mask={"____-__-__"}
          value={fromValue}
          onChange={fromOnChange}
          valueFormatter={fromFormatter}
          format={fromFormat}
          disabled={disabled}
          sx={sx}
          slotProps={{ textField: { size: "small" } }}
        />
        <DatePicker
          label="종료일"
          inputFormat={toInputFormat}
          mask={toMask}
          // inputFormat={"yyyy-MM-dd"}
          // mask={"____-__-__"}
          value={toValue}
          onChange={toOnChange}
          valueFormatter={toFormatter}
          format={toFormat}
          disabled={disabled}
          sx={sx}
          slotProps={{ textField: { size: "small" } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
  // const [value, setValue] = React.useState(dayjs("2022-04-17"));

  // return (
  //   <LocalizationProvider dateAdapter={AdapterDayjs}>
  //     <DemoContainer components={["DatePicker", "DatePicker"]}>
  //       {/* 제어되지 않는 */}
  //       {/* <DatePicker
  //         label="Uncontrolled picker"
  //         defaultValue={dayjs("2022-04-17")}
  //       /> */}
  //       {/* 제어되는 */}
  //       <DatePicker
  //         label="Controlled picker"
  //         value={value}
  //         onChange={(newValue) => setValue(newValue)}
  //       />
  //       <DatePicker
  //         label="Controlled picker"
  //         value={value}
  //         onChange={(newValue) => setValue(newValue)}
  //       />
  //     </DemoContainer>
  //   </LocalizationProvider>
  // );
}

//   const columns = [

//     {
//       field: 'createdAt',
//       headerName: 'Join Date',
//       width: 100,
//       valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
//     },

//  ];
