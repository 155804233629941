import React from "react";
import Login from "./Login";
import { styled } from "@mui/material/styles";
import { Button, Stack, Box, Grid, Typography, Container } from "@mui/material";

// 이미지
import intro_01_img from "../assets/images/introImage/intro_01_img.svg";
import intro_02_img from "../assets/images/introImage/intro_02_img.svg";
import intro_03_img from "../assets/images/introImage/intro_03_img.svg";

const InBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "40px 0",
  // ...theme.typography.h5,
  // textAlign: "left",
  // paddingleft: "16px",
  // position: "absolute",
  // left: "10%",
  // top: "12%",
  // color: theme.palette.text.secondary,
}));

// const H4Span = styled(Typography)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   justifyContent: "center",
// }));

// const h4Sx = {
//   "& .MuiTypography-h5": {
//     backgroundColor: "#fff",
//     padding: "4px 10px",
//     borderRadius: "20px",
//     border: "1px solid #000",
//   },
// };

const Intro = () => {
  return (
    <>
      <Box
        sx={
          {
            // pb: 6
          }
        }
      >
        {/* contentsA S */}
        <Box>
          <Container
            sx={{
              // backgroundColor: "green",
              // width: "100%",
              pt: 8,
              pb: 6,
            }}
            maxWidth="lg"
          >
            <Typography
              component="h2"
              variant="h3"
              sx={{ marginBottom: "32px" }}
            >
              플랫폼소개
            </Typography>
            <Typography
              component="h3"
              variant="h5"
              sx={{ marginBottom: "32px", lineHeight: 2 }}
            >
              ETRI와 공동연구기관의 협업을 통해
              <Typography
                component="span"
                variant="h5"
                sx={{ display: "flex" }}
              >
                제조 데이터의 안전한 거래를 위한 시스템을 구성
              </Typography>
            </Typography>
            <InBox>
              <img src={intro_01_img} alt="제조 데이터 시스템 구성 이미지" />
            </InBox>
          </Container>
        </Box>
        {/* contentsA E */}
        {/* contentsB S */}
        <Box sx={{ backgroundColor: "#EFF2F4" }}>
          <Container
            sx={{
              // backgroundColor: "green",
              // width: "100%",
              pt: 8,
              pb: 6,
            }}
            maxWidth="lg"
          >
            <Typography
              component="h3"
              variant="h5"
              sx={{ marginBottom: "32px", lineHeight: 2 }}
            >
              제조데이터 보호, 표준화 기술개발을 통한
              <Typography
                component="span"
                variant="h5"
                sx={{ display: "flex" }}
              >
                산업계 신뢰성 확보기반 제조데이터 거래 생태계 구축
              </Typography>
            </Typography>

            <Typography
              component="p"
              variant="subtitle1"
              sx={{ marginBottom: "32px" }}
            >
              제조데이터 보호·거래 플랫폼 개발을 통해
              수요기업(제조)-공급기업(AI솔루션) 간 신뢰성을 기반으로 제조데이터
              거래활성화에 기여
              <br />
              제조데이터 거래활성화를 통해 공급기업이 제공받지 못한 다양한
              데이터를 활용한 AI솔루션 개발 촉진 및 데이터 피드백을 통한
              기술고도화에 기여
              <br />
              또한, 공공주도의 제조데이터 거래기반을 통해 동종산업간
              기술고도화를 위한 제조데이터 활용 생태계 구축
            </Typography>
            <InBox>
              <img src={intro_02_img} alt="제조 데이터 시스템 구성 이미지" />
            </InBox>
          </Container>
        </Box>
        {/* contentsA B */}
        {/* contentsA C */}
        <Box sx={{}}>
          <Container
            sx={{
              // backgroundColor: "green",
              // width: "100%",
              pt: 8,
              pb: 6,
            }}
            maxWidth="lg"
          >
            <Typography
              component="h3"
              variant="h5"
              sx={{ marginBottom: "32px", lineHeight: 2 }}
            >
              기존 주력산업 및 제조업 대상
              <Typography
                component="span"
                variant="h5"
                sx={{ display: "flex" }}
              >
                제조혁신기술 기술개발-실증-상용화 연계를 통한 제조-ICT 산업의
                동반성장 추진
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{ marginBottom: "32px" }}
            >
              공공주도 기반 공급기업-수요기업 협력 기술개발 추진으로
              <br />
              일회성 기술개발이 아닌 현장실증 및 상용화 연계를 통한
              공급기업-수요기업의 동반성장 기대
            </Typography>
            <InBox>
              <img src={intro_03_img} alt="제조 데이터 시스템 구성 이미지" />
            </InBox>
          </Container>
        </Box>
        {/* contentsA C */}
        {/* contentsA D */}
        <Box sx={{ backgroundColor: "#EFF2F4" }}>
          <Container
            sx={{
              // backgroundColor: "green",
              // width: "100%",
              pt: 8,
              pb: 6,
            }}
            maxWidth="lg"
          >
            {/* 데이터거래 S */}
            <Box sx={{ mb: 6 }}>
              <Typography
                component="h4"
                variant="h5"
                sx={{
                  mb: 1,
                  lineHeight: 2,
                }}
              >
                <Typography
                  component="span"
                  variant="h5"
                  // sx={h4Sx}
                  sx={{
                    backgroundColor: "#fff",
                    padding: "4px 10px",
                    borderRadius: "20px",
                    border: "1px solid #000",
                  }}
                >
                  데이터 거래
                </Typography>
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  marginBottom: "32px",
                  lineHeight: 2,
                }}
              >
                제조 데이터의 거래에서 필요한 부분은 데이터의 안전한 관리이다.
                이를 위해 분산 파일 저장 시스템인 IPFS를 사용하며, 데이터의
                암복호화를 위한 key는 블록체인의 DID 관리 기술을 활용하여
                시스템을 구성
                <br /> 데이터는 IPFS를 통해 분산 저장이 되도록 구성하고, Web
                기능 또한 IPFS를 기반으로 구동되도록 시스템을 구성함.
              </Typography>
            </Box>
            {/* 데이터거래 E */}
            {/* 안전한 계약 S */}
            <Box sx={{ mb: 6 }}>
              <Typography
                component="h4"
                variant="h5"
                sx={{
                  mb: 1,
                  lineHeight: 2,
                }}
              >
                <Typography
                  component="span"
                  variant="h5"
                  // sx={h4Sx}
                  sx={{
                    backgroundColor: "#fff",
                    padding: "4px 10px",
                    borderRadius: "20px",
                    border: "1px solid #000",
                  }}
                >
                  안전한 계약
                </Typography>
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  marginBottom: "32px",
                  lineHeight: 2,
                }}
              >
                계약의 이행을 관리하고 계약 정보를 관리는 계약에서 중요한 부분을
                차지한다. 이를 위해 블록체인의 Smart Contract를 이용하여 계약을
                수행하고 계약 이행을 관리한다.
              </Typography>
            </Box>
            {/* 안전한 계약 E */}
            {/* AI 프레임워크 S */}
            <Box>
              <Typography
                component="h4"
                variant="h5"
                sx={{
                  mb: 1,
                  lineHeight: 2,
                }}
              >
                <Typography
                  component="span"
                  variant="h5"
                  // sx={h4Sx}
                  sx={{
                    backgroundColor: "#fff",
                    padding: "4px 10px",
                    borderRadius: "20px",
                    border: "1px solid #000",
                  }}
                >
                  AI 프레임워크
                </Typography>
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  marginBottom: "32px",
                  lineHeight: 2,
                }}
              >
                데이터의 활용을 위해서는 AI분석이 필요하다. 이를 위해
                플랫폼에서는 연계 가능한 AI 프레임워크를 제공하여 서비스 한다.
              </Typography>
            </Box>
            {/* AI 프레임워크 E */}
          </Container>
        </Box>
        {/* contentsA D */}
      </Box>

      {/* <div>홈 Home</div>
      <Typography variant="h2" component="h2">
        계약관리
      </Typography>
      <Login /> */}
    </>
  );
};
export default Intro;
