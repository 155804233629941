import { useQuery } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 플랫폼 전체 데이터 조회
const fetchPlatformStatisticsData = () => {
  return client.post(`/platformitem/statistics`);
};

// 플랫폼 전체 데이터에 대한 쿼리 훅
export const useStatisticsQuery = (options = {}) => {
  return useQuery({
    queryKey: ["platform-statistics-data"],
    queryFn: fetchPlatformStatisticsData,
    select: (result) => result.data.data,
    staleTime: 300000, // 5분
    placeholderData: {
      month_data: [], // 이 부분에 필요한 기본 데이터 구조를 설정
      month_accumul_data: [], // 예를 들어, 여기에 기본 배열이나 객체를 제공
    },
    ...options, // 외부에서 전달된 추가 설정을 반영
  });
};
