import { useState } from "react";
import client from "assets/js/commonApi";

const initDialog = {
  open: false,
  context: "",
  value: "",
  onChange: () => {},
  onClick: () => {},
  onClose: () => {},
};
const initGroup = {
  group_id: "",
  group_name: "",
  storage_sub_id: "",
  group_user_type: [],
};
const initUser = {
  user_id: "",
  user_type: "",
  did: "",
  email: "",
  email_yn: "",
  approval_yn: "",
  approval_dtm: "",
  upd_user: "",
};

export default function useGroup() {
  const [dialog, setDialog] = useState(initDialog);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 10000,
    handleClose: () => setSnackBar((pre) => ({ ...pre, open: false })),
  });
  const showSnackBar = (message) =>
    setSnackBar((pre) => ({
      ...pre,
      open: true,
      message: message,
    }));
  const [group, setGroup] = useState(initGroup);
  const [newGroup, setNewGroup] = useState(initGroup);
  const [manager, setManager] = useState(initUser);
  const [basic, setBasic] = useState(initUser);
  const [user, setUser] = useState(initUser);
  const [groupList, setGroupList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [basicList, setBasicList] = useState([]);
  const [userList, setUserList] = useState([]);

  const hasBasicUser = (user) => {
    return basicList.some((basic) => basic.user_id === user.user_id);
  };
  const hasManagerUser = (user) => {
    return managerList.some((manager) => manager.user_id === user.user_id);
  };

  const onLoadGroup = async () => {
    // client.post("/group/list", initGroup).then((res) => {
    //   const data = res?.data?.data;
    //   console.log("[useGroup onLoadGroup]", data);

    //   setGroupList(data);
    // });

    const groupList = (await client.post("/group/list", initGroup)).data?.data;
    // const payGroupList = (await client.post("/pay/group")).data?.data

    console.log("[useGroup onLoadGroup group]", groupList);
    // console.log("[useGroup onLoadGroup payGroup]", payGroupList);

    setGroupList([...groupList]);
  };

  const onLoadUser = () => {
    client
      .post("/user/list", { ...initUser, approval_yn: "승인" })
      .then((res) => {
        const data = res?.data?.data;

        setUserList(data);
      });
  };

  const onGroupClick =
    (group = initGroup) =>
    () => {
      setGroup(group);
      setManager(initUser);
      setBasic(initUser);

      client
        .post("/group/select", {
          group_id: group.group_id,
        })
        .then((res) => {
          const data = res?.data?.data;
          console.log("[useGroup onGroupClick]", data);
          const manager = (data?.user_info ?? []).filter((user) => {
            return (
              user.group_user_type.indexOf("master") > -1 ||
              user.group_user_type.indexOf("manager") > -1
            );
          });
          const basic = (data?.user_info ?? []).filter((user) => {
            return user.group_user_type.indexOf("basic") > -1;
          });

          setManagerList(manager);
          setBasicList(basic);
        });
    };

  const onGroupDoubleClick =
    (group = initGroup) =>
    () => {
      setGroup(group);
      setManager(initUser);
      setBasic(initUser);

      const groupUpdate = async (groupName) => {
        client
          .post("/group/update", {
            group_id: group.group_id,
            group_name: groupName,
            update_info: [],
            insert_info: [],
            delete_info: [],
          })
          .then(() => onLoadGroup());
      };

      setDialog({
        open: true,
        context: "그룹명 변경",
        value: group.group_name,
        onChange: (event) =>
          setDialog((pre) => ({ ...pre, value: event.target.value })),
        onClick: () => {
          setDialog((pre) => {
            groupUpdate(pre.value);

            return { open: false };
          });
        },
        onClose: () => {
          setDialog({ open: false });
        },
      });
    };

  const onAddGroupClick =
    (group = initGroup) =>
    () => {
      client
        .post("/group/insert", { ...group, group })
        .then((res) => {
          console.log("[useGroup onAddGroupClick]", res);
          onLoadGroup();
          setNewGroup(initGroup);
          showSnackBar("그룹을 추가하였습니다");
        })
        .catch((err) => {
          console.error("[useGroup onAddGroupClick]", err);
          showSnackBar(err.response.data.msg);
        });
    };

  const onDeleteGroupClick =
    (group = initGroup) =>
    () => {
      const deleteGroup = async () => {
        client
          .post("/group/delete", { ...group, group })
          .then((res) => {
            console.log("[useGroup onDeleteGroupClick]", res);
            onLoadGroup();
            setGroup(initGroup);
            setManagerList([]);
            setBasicList([]);
            showSnackBar("그룹을 삭제하였습니다");
          })
          .catch((err) => {
            console.error("[useGroup onDeleteGroupClick]", err);
          });
      };

      setDialog({
        open: true,
        context: "그룹을 삭제하시겠습니까?",
        onClick: () => {
          deleteGroup();
          setDialog({ open: false });
        },
        onClose: () => setDialog({ open: false }),
      });
    };

  const onAddManagerClick =
    (user = initUser) =>
    () => {
      if (!group.group_id) {
        showSnackBar("회원을 등록할 그룹을 선택해주세요");

        return;
      }
      if (!userList?.find((u) => u.user_id === user.user_id)) {
        showSnackBar("등록된 사용자가 아닙니다");

        return;
      }

      // 이미 기본 유저이면 update, 아니면 insert
      const isBasic = hasBasicUser(user);
      const info = [{ user_id: user.user_id, group_user_type: "manager" }];
      const data = {
        group_id: group.group_id,
        group_name: group.group_name,
        update_info: isBasic ? info : [],
        insert_info: isBasic ? [] : info,
        delete_info: [],
      };

      client
        .post("/group/update", data)
        .then((res) => {
          onGroupClick(group)();
          showSnackBar("관리자를 추가하였습니다");
        })
        .catch((err) => {
          showSnackBar(err.response.data.msg);
        });
    };

  const onDeleteManagerClick =
    (user = initUser) =>
    () => {
      const managerDelete = async () =>
        client
          .post("/group/update", {
            group_id: group.group_id,
            group_name: group.group_name,
            update_info: [],
            insert_info: [],
            delete_info: [user],
          })
          .then((res) => {
            onGroupClick(group)();
            showSnackBar("관리자를 삭제하였습니다");
          });

      setDialog({
        open: true,
        context: "관리자를 삭제하시겠습니까",
        onClick: () => {
          managerDelete();
          setDialog({ open: false });
        },
        onClose: () => setDialog({ open: false }),
      });
    };

  const onAddBasicClick =
    (user = initUser) =>
    () => {
      if (!group.group_id) {
        showSnackBar("회원을 등록할 그룹을 선택해주세요");

        return;
      }

      if (!userList?.find((u) => u.user_id === user.user_id)) {
        setDialog({
          open: true,
          context: "등록된 사용자가 아닙니다",
          onClose: () => setDialog({ open: false }),
        });
        return;
      }

      // 이미 관리자 유저이면 update, 아니면 insert
      const isManager = hasManagerUser(user);
      const info = [{ user_id: user.user_id, group_user_type: "basic" }];
      const data = {
        group_id: group.group_id,
        group_name: group.group_name,
        update_info: isManager ? info : [],
        insert_info: isManager ? [] : info,
        delete_info: [],
      };

      client
        .post("/group/update", data)
        .then((res) => {
          console.log("[useGroup onAddBasicClick group]", group);
          onGroupClick(group)();
          showSnackBar("사용자를 추가하였습니다");
        })
        .catch((err) => {
          showSnackBar(err.response.data.msg);
        });
    };

  const onDeleteBasicClick =
    (user = initUser) =>
    () => {
      const deleteBasic = async () => {
        client
          .post("/group/update", {
            group_id: group.group_id,
            group_name: group.group_name,
            update_info: [],
            insert_info: [],
            delete_info: [user],
          })
          .then((res) => {
            console.log("[useGroup onDeleteBasicClick group]", group);
            onGroupClick(group)();
            showSnackBar("사용자를 삭제하였습니다");
          });
      };

      setDialog({
        open: true,
        context: "사용자를 삭제하시겠습니까",
        onClick: () => {
          deleteBasic();
          setDialog({ open: false });
        },
        onClose: () => setDialog({ open: false }),
      });
    };

  const onNewGroupChange = (event) => {
    setNewGroup((pre) => ({ ...pre, group_name: event.target.value }));
  };

  return {
    group: {
      select: group,
      new: newGroup,
      list: groupList,
      setGroup,
    },
    manager: {
      select: manager,
      list: managerList,
      setManager,
    },
    basic: {
      select: basic,
      list: basicList,
      setBasic,
    },
    user: {
      select: user,
      list: userList,
      setUser,
    },
    dialog: dialog,
    snackBar: snackBar,
    onLoadGroup,
    onLoadUser,
    onGroupClick,
    onGroupDoubleClick,
    onAddGroupClick,
    onDeleteGroupClick,
    onAddManagerClick,
    onDeleteManagerClick,
    onAddBasicClick,
    onDeleteBasicClick,
    onNewGroupChange,
  };
}
