import { blueberryTwilightPaletteDark } from "@mui/x-charts";
import client from "assets/js/commonApi";
import { useCallback, useEffect, useState } from "react";

export default function useFT() {
  // 로그인 유저 정보
  const userDID = sessionStorage.getItem("did");
  const userName = sessionStorage.getItem("name");
  const [dialog, setDialog] = useState({
    context: "",
    open: false,
    value: "0",
    onChange: () => {},
    onClick: () => {},
    onClose: () =>
      setDialog((pre) => ({
        ...pre,
        context: "",
        open: false,
        value: "0",
        onChange: () => {},
        onClick: () => {},
      })),
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 10000,
    handleClose: () => setSnackBar((pre) => ({ ...pre, open: false })),
  });
  const [balance, setBalance] = useState(0);
  const [value, setValue] = useState("1");
  const [content, setContent] = useState([]);
  const [selectContractNumber, setSelectContractNumber] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPlatformModal, setIsOpenPlatformModal] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setIsOpenPlatformModal(false);
    setSelectContractNumber("");
  };

  const getFTBuyList = async () => {
    return client
      .post("/ft/buy", {})
      .then((res) => {
        const b = res.data?.data?.ft_balance ?? 0;
        const list =
          res.data?.data?.ft_contract?.map((item, index) => ({
            ...item,
            id: item.index + 1,
          })) ?? [];

        return { balance: b, list: list };
      })
      .catch((err) => {
        console.error("[useFT]", "[useFT err]", err);
      });
  };

  const getFTSellList = async () => {
    return client
      .post("/ft/sell", {})
      .then((res) => {
        const b = res.data?.data?.ft_balance ?? 0;
        const list =
          res.data?.data?.ft_contract?.map((item, index) => ({
            ...item,
            id: index + 1,
          })) ?? [];

        return { balance: b, list: list };
      })
      .catch((err) => {
        console.error("[useFT]", "[useFT err]", err);
      });
  };

  const getFTPurList = async () => {
    return client
      .post("/pay/purlist", {})
      .then((res) => {
        if (!res.data || !res.data.data) {
          console.error("No data received or incorrect data path.");
          return;
        }
        const filteredRows = res.data.data;
        // .filter((item) => {
        //   // return true
        //   return (
        //     item.result_yn.trim() === "결제 대기" ||
        //     item.result_yn.trim() === "파일 등록 중"
        //   );
        // });
        const list =
          filteredRows?.map((item, index) => ({
            ...item,
            id: index + 1,
          })) ?? [];

        return { list: list };
      })
      .catch((err) => {
        console.error("## Error in GetData:", err);
      });
  };

  const showContract = useCallback(
    (contract_sub_id) => {
      let url = "/buy/select";

      switch (value) {
        case "1":
          url = "/buy/select";
          break;
        case "2":
          url = "/sell/select";
          break;
        default:
          url = "/buy/select";
      }

      client.post(url, { contract_sub_id }).then((res) => {
        const data = res.data?.data;

        if (data) {
          setSelectContractNumber(contract_sub_id);
          switch (data.contract_type) {
            case "001":
              setIsOpenModal(true);
              break;
            case "003":
              setIsOpenPlatformModal(true);
              break;
            default:
          }
        }
      });
    },
    [value],
  );

  const showSnackBar = (message) =>
    setSnackBar((pre) => ({
      ...pre,
      open: true,
      message: message,
    }));

  const chargeFT = (amount) => {
    // 숫자만 입력
    if (/^[0-9]*$/.test(amount) === false) {
      showSnackBar("올바른 액수를 입력하세요");
      return;
    }

    client
      .post("/ft/charge", { amount: Number(amount) })
      .then((res) => {
        getFTBuyList().then(({ balance }) => {
          showSnackBar("FT 충전이 완료되었습니다");
          setBalance(balance);
        });
      })
      .catch((err) => {
        showSnackBar(err.response?.data.msg);
      });
  };

  const onAmountClick = () => {
    setDialog((pre) => ({
      ...pre,
      open: true,
      context: "충전 액수를 입력하세요",
      onClick: () =>
        setDialog((pre) => {
          chargeFT(pre.value);
          return { ...pre, open: false, value: "0" };
        }),
      onChange: (event) =>
        setDialog((pre) => ({ ...pre, value: event.target.value })),
      onClose: () => setDialog({ open: false }),
    }));
  };

  // 탭 변경에 따라 조회항목 달라짐
  useEffect(() => {
    switch (value) {
      case "1":
        getFTBuyList().then(({ balance, list }) => {
          setBalance(Number(balance).toLocaleString("ko-KR"));
          setContent(
            list.map((content) => ({
              state: "결제완료",
              title: content.contract_title,
              date: content.ins_dtm,
              quickbtn: `${Number(content.payment_amount).toLocaleString(
                "ko-KR",
              )} FT 계약 바로가기`,
              quickbtnOnClick: () => showContract(content.contract_sub_id),
            })),
          );
          console.log("[FTManagement]", list);
        });
        break;
      case "2":
        getFTSellList().then(({ balance, list }) => {
          setBalance(Number(balance).toLocaleString("ko-KR"));
          setContent(
            list.map((content) => ({
              state: "결제완료",
              title: content.contract_title,
              date: content.ins_dtm,
              quickbtn: `${Number(content.payment_amount).toLocaleString(
                "ko-KR",
              )} FT 계약 바로가기`,
              quickbtnOnClick: () => showContract(content.contract_sub_id),
            })),
          );
          console.log("[FTManagement]", list);
        });
        break;
      case "3":
        getFTPurList().then(({ list }) => {
          setContent(
            list.map((content) => ({
              state: `${content.req_name} 요청 ${content.result_yn}`,
              title: content.contract_title,
              date: content.ins_dtm,
              quickbtn: `${Number(content.contract_price).toLocaleString(
                "ko-KR",
              )} FT 계약 바로가기`,
              quickbtnOnClick: () => showContract(content.contract_sub_id),
              // 사용 안함
              // cancelbtn: content.result_yn === "결제 대기" && {
              //   text: "거절",
              //   onClick: () => {
              //     setDialog({
              //       open: true,
              //       context:
              //         "거절 시, 계약이 취소됩니다. 그래도 진행 하시겠습니까?",
              //       onClick: () => {
              //         client
              //           .post("/pay/confirm_common", {
              //             contract_sub_id: content.contract_sub_id,
              //             result_yn: "N",
              //           })
              //           .then((res) => {
              //             console.log("[useFT accept]", res);
              //             setDialog({ open: false })
              //             showSnackBar("모바일에서 결제 거절을 완료하세요");
              //           })
              //           .catch((err) => {
              //             console.log("[useFT accept]", err);
              //             setDialog({ open: false })
              //             showSnackBar(err.response.data.msg);
              //           });
              //       },
              //       onClose: () => setDialog({ open: false }),
              //     });
              //   },
              // },
              // acceptbtn: content.result_yn === "결제 대기" && {
              //   text: "결제",
              //   onClick: async () => {
              //     // 현재 FT 금액과 거래 FT 금액을 확인한 이후에 결제진행함
              //     const contracPrice = Number(content.contract_price);
              //     const numBalance = Number(balance.replace(",", ""));

              //     if (Number.isNaN(contracPrice) || Number.isNaN(numBalance)) {
              //       showSnackBar("FT 금액이 숫자가 아닙니다");
              //       return;
              //     }

              //     if (contracPrice > numBalance) {
              //       showSnackBar("결제할 FT 금액이 모자랍니다");
              //       return;
              //     }

              //     setDialog({
              //       open: true,
              //       context: "결제 하시겠습니까?",
              //       onClick: () => {
              //         client
              //           .post("/pay/confirm_common", {
              //             contract_sub_id: content.contract_sub_id,
              //             result_yn: "Y",
              //           })
              //           .then((res) => {
              //             console.log("[useFT accept]", res);
              //             setDialog({ open: false });
              //             showSnackBar("모바일에서 결제 진행을 완료하세요");
              //           })
              //           .catch((err) => {
              //             console.log("[useFT accept]", err);
              //             setDialog({ open: false });
              //             showSnackBar(err.response.data.msg);
              //           });
              //       },
              //       onClose: () => setDialog({ open: false }),
              //     });
              //   },
              // },
            })),
          );
          console.log("[FTManagement]", list);
        });
        break;
      default:
    }
  }, [value, showContract]);

  return {
    userDID,
    userName,
    dialog,
    snackBar,
    balance,
    value,
    content,
    selectContractNumber,
    isOpenModal,
    isOpenPlatformModal,
    handleChange,
    handleClose,
    onAmountClick,
  };
}
