import * as React from "react";
// import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { NumericFormat } from 'react-number-format';
import {
  // Container,
  Box,
  TextField,
  // Paper,
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Grid,
  // Alert,
  // InputBase,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          // root: {
          //   [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          //     borderColor: "var(--TextField-brandBorderHoverColor)",
          //   },
          //   [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          //     borderColor: "var(--TextField-brandBorderFocusedColor)",
          //   },
          // },
        },
      },
      // MuiFilledInput: {
      //   styleOverrides: {
      //     root: {
      //       "&:before, &:after": {
      //         borderBottom: "2px solid var(--TextField-brandBorderColor)",
      //       },
      //       "&:hover:not(.Mui-disabled, .Mui-error):before": {
      //         borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
      //       },
      //       "&.Mui-focused:after": {
      //         borderBottom:
      //           "2px solid var(--TextField-brandBorderFocusedColor)",
      //       },
      //     },
      //   },
      // },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

export default function InputContent({
  id,
  label,
  type,
  defaultValue,
  value,
  onChange,
  disabled,
  helperText,
  placeholder,
  inputProps,
  // options,
}) {
  const outerTheme = useTheme();

  // 기존텍스트필드 스타일인데, 내용 비워 둬야 함.
  // 스타일을 수정해야 함
  const InputBoxSx = {
    // backgroundColor: "pink",
    width: "100%",
    border: "0",
    "& .MuiInputBase-root": { p: 0, border: "0" },
    // textfield border삭제
    "& fieldset": { border: "none" },
    "& .MuiFormControl-root": {
      // position: "relative",

      "& label": {
        // backgroundColor: "red",
      },
    },
    "& .MuiInput-root": {
      fontSize: "14px",
      // backgroundColor: "red",
    },
    // " .MuiInput-root": {
    //   // position: "relative",
    //   // "& textarea": {
    //   //   backgroundColor: "#000",
    //   // },
    //   "& input": {
    //     backgroundColor: "red",
    //     fontSize: "12px",
    //   },
    // },
    "& .Mui-disabled": {
      color: "#000000DE !important",
      "-webkit-text-fill-color": "#000000DE !important",
      // borderColor: "red !important",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontSize: "0.75rem",
      // backgroundColor: "yellow",
      ml: 0,
      mr: 0,
    },
    "& .MuiFormHelperText-root.Mui-disabled": {
      // 여기는 디지블드 상태라 수정할 필요없지만 일단 네임값 넣어둠
    },
  };
  // 내용 입력
  const InputBaseSX = {
    backgroundColor: "pink",
    width: "100%",
    // "& input.MuiInputBase-input.Mui-disabled": {
    //   color: "pink !important",
    //   // webkitTextFillColor: "red",
    // },
    // "&$disabled": {
    //   color: "red",
    //   border: "1px solid red",
    // },

    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000000DE !important",
      "-webkit-text-fill-color": "#000000DE !important",
      // borderColor: "red !important",
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      // borderColor: "red !important",
    },
  };
  return (
    <>
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          // InputLabelProps={{ shrink: value ? true : false }}
          // id={id}
          sx={InputBoxSx}
          // sx={InputBaseSX}
          // label={label}
          // size="small"
          type={type}
          // variant="standard"
          placeholder={placeholder}
          inputProps={inputProps}
          value={value}
          onChange={onChange}
          disabled={disabled}
          helperText={helperText}
          multiline
        />
      </ThemeProvider>
    </>
  );
}

// export default function CustomizedInputsStyleOverrides() {
//     const outerTheme = useTheme();

//     return (
//       <Box
//         sx={{
//           display: "grid",
//           gridTemplateColumns: { sm: "1fr 1fr 1fr" },
//           gap: 2,
//         }}
//       >
//         <ThemeProvider theme={customTheme(outerTheme)}>
//           {/* <TextField label="Outlined" />
//           <TextField label="Filled" variant="filled" /> */}
//           <TextField label="Standard" variant="standard" />
//         </ThemeProvider>
//       </Box>
//     );
//   }
