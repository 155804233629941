import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

const withAuthRoutes = [
  "/filelist",
  "/contractlist",
  "/buylist",
  "/did",
  // "/tf",
  // "/category",
  "/mypage",
  //
  "/mypage/progress",
  "/mypage/sales",
  "/mypage/buy",
  "/mypage/consignments",
  "/mypage/ft",
  "/mypage/nft",
  "/mypage/cart-likes",
  "/mypage/users",
  "/mypage/groups",
  "/mypage/storage",
];
const withoutAuthRoutes = ["/", "/intro", "/statistics", "/login"];

console.log("##### Navigate", Navigate);
export default function AuthGuard({ children }) {
  // const { isLoggedIn } = authStore();
  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("refresh");
  const isLoggedIn = access && refresh;
  const { pathname } = useLocation();

  console.log("##### isLoggedIn:", isLoggedIn);
  console.log("##### pathname:", pathname);

  if (withAuthRoutes.includes(pathname) && !isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  // const requiresAuth = withAuthRoutes.some((route) =>
  //   pathname.startsWith(route),
  // );

  // if (requiresAuth && !isLoggedIn) {
  //   return <Navigate to="/login" replace />;
  // }

  return children;
}
