// import React from "react";
import { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Container,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  IconButton,
  Badge,
  Grid,
  Paper,
  Link,
  AppBar,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import MypageMainNav, {
  menuItems,
} from "components/viewContents/mypageView/MypageMainNav";
import MypageTopArea from "components/viewContents/mypageView/MypageTopArea";
// import { menuItems } from "components/viewContents/mypageView/MypageMainNav";

// const drawerWidth = 240;

const Mypage = (props) => {
  const { window } = props;

  // const [selectedMenuItem, setSelectedMenuItem] = useState("내 계약 진행");
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].text);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // const renderSelectedComponent = () => {
  //   const selectedItem = menuItems.find(
  //     (item) => item.text === selectedMenuItem,
  //   );
  //   return selectedItem ? (
  //     <selectedItem.component />
  //   ) : (
  //     <div>Component not found</div>
  //   );
  // };
  //
  //
  // const renderSelectedComponent = () => {
  //   const selectedItem = menuItems.find(
  //     (item) => item.text === selectedMenuItem,
  //   );
  //   return selectedItem ? null : <div>Component not found</div>;
  // };

  //
  // const renderSelectedComponent = () => {
  //   const selectedItem = menuItems.find(
  //     (item) => item.text === selectedMenuItem,
  //   );
  //   return selectedItem ? <Outlet /> : <div>Component not found</div>;
  // };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const listItemStyle = (itemText) => ({
    // pl: 0,

    backgroundColor: selectedMenuItem === itemText ? "#0000000a" : "inherit", // 활성화 시 배경색 변경
    // 추가 스타일은 필요에 따라 여기에 작성
    "&:hover": {
      backgroundColor: "#0000000a", // 호버 시 배경색 변경
    },
  });

  return (
    // <Box sx={{ pb: 6 }}>
    <Container sx={{ pt: 8, pb: 6, pl: 0, pr: 0, zIndex: "1" }} maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // height: "100vh",
        }}
      >
        <MypageTopArea
          handleDrawerToggle={handleDrawerToggle}
          selectedMenuItem={selectedMenuItem}
        />
        {/* MypageTopArea Header */}
        <Box sx={{ display: "flex", flexDirection: "row", zIndex: "1" }}>
          {/* <Toolbar sx={{ minHeight: "76px", height: "76px" }} /> */}
          {/* <Divider /> */}

          <MypageMainNav
            mobileOpen={mobileOpen}
            handleDrawerClose={handleDrawerClose}
            setSelectedMenuItem={setSelectedMenuItem}
            menuItems={menuItems}
            listItemStyle={listItemStyle}
          />
          {/* <Outlet /> */}
          {/* nav 메뉴 */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
            }}
          >
            {/* <Toolbar sx={{ minHeight: "76px", height: "76px" }} />
            <Divider /> */}
            <Box sx={{ p: 3, pr: 0 }}>
              {/* {renderSelectedComponent()}  */}
              <Outlet />
            </Box>
            {/* <Routes>
              <Route>
                <Route path="/progress" element={<ContractProgress />} />
                <Route path="/sales" element={<SalesList />} />
                <Route path="/buy" element={<BuyList />} />
                <Route path="/consignments" element={<ConsignmentList />} />
                <Route path="/ft" element={<FTManagement />} />
                <Route path="/nft" element={<NFTManagement />} />
                <Route path="/cart-likes" element={<CartLikes />} />
                <Route path="/users" element={<UserManagement />} />
                <Route path="/groups" element={<GroupManagement />} />
                <Route path="/storage" element={<StorageManagement />} />
              </Route>
            </Routes> */}
            {/* 여기에 각페이지 불러올거여 */}
          </Box>
          {/* content 내용 */}
        </Box>
        {/* Mypage nav & content */}
      </Box>
    </Container>
    // </Box>
  );
};
Mypage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};
export default Mypage;
