import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { Box, Tab, Grid, Typography, Stack, Badge } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";

import DirectSalesTab from "./contractProgress/DirectSalesTab"; // 직접 판매
import PlatformSalesTab from "./contractProgress/PlatformSalesTab"; // 플랫폼 판매
import PlatformBuyingTab from "./contractProgress/PlatformBuyingTab"; // 플랫폼 구매

// 스타일
const StyledTab = styled(Tab)({
  backgroundColor: "#fff", // 배경색
  minHeight: "unset",
  // backgroundColor: "#f0f0f0", // 배경색
  // color: "#000", // 글자색
  // fontSize: "1rem", // 글자 크기
  "&.Mui-selected": {
    // 선택된 탭 스타일
    // backgroundColor: "#c0c0c0",
    // color: "blue",
    color: "#468ECD",
    border: "1px solid #468ECD",
    borderRadius: "25px",
  },
  "&:hover": {
    // 마우스 오버 스타일
    backgroundColor: "#eee",
    // backgroundColor: "#e0e0e0",
    // border: "1px solid #468ECD",
    borderRadius: "25px",
  },
});

const ContractProgress = () => {
  const queryClient = useQueryClient();
  const [value, setValue] = React.useState("1");
  // const navigate = useNavigate();
  // const handleNavigation = (path) => {
  //   navigate(path);
  // };

  // 기본
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChange = (event, newValue) => {
  //   if (value !== newValue) {
  //     setValue(newValue);
  //     switch (newValue) {
  //       case "1":
  //         queryClient.invalidateQueries(["direct-sales-progress"]);
  //         break;
  //       case "2":
  //         queryClient.invalidateQueries(["platform-sales-progress"]);
  //         break;
  //       case "3":
  //         queryClient.invalidateQueries(["platform-buying-progress"]);
  //         break;
  //       default:
  //         // No invalidation
  //         break;
  //     }
  //   }
  // };
  //
  // const handleChange = (event, newValue) => {
  //   if (value !== newValue) {
  //     setValue(newValue);
  //     if (newValue === "1") {
  //       queryClient.invalidateQueries(["direct-sales-progress"]);
  //     } else if (newValue === "2") {
  //       queryClient.invalidateQueries(["platform-sales-progress"]);
  //     } else if (newValue === "3") {
  //       queryClient.invalidateQueries(["platform-buying-progress"]);
  //     }
  //   }
  //   // setValue(newValue);
  //   // if (newValue === "1") {
  //   //   queryClient.invalidateQueries(["direct-sales-progress"]);
  //   // } else if (newValue === "2") {
  //   //   queryClient.invalidateQueries(["platform-sales-progress"]);
  //   // } else if (newValue === "3") {
  //   //   queryClient.invalidateQueries(["platform-buying-progress"]);
  //   // }
  // };

  // const TFBottomCardContent = [
  //   {
  //     state: "결제완료",
  //     title: "비피앤솔루션 커피물류 데이터 계약",
  //     date: "2023.06.19 구매",
  //     // description: "데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
  //     quickbtn: "3,000FT 계약 바로가기",
  //     // image: mainbottomicon_01,
  //     // imageLabel: "총 계약 건수 아이콘",
  //   },
  //   {
  //     state: "결제완료",
  //     title: "2체인트리 용접 데이터 계약",
  //     date: "2023.06.20 구매",
  //     // description: "11데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
  //     quickbtn: "3,000FT 계약 바로가기",
  //     // image: mainbottomicon_01,
  //     // imageLabel: "총 계약 건수 아이콘",
  //   },
  //   {
  //     state: "결제완료",
  //     title: "3체인트리 용접 데이터 계약",
  //     date: "2023.06.30 구매",
  //     // description: "22데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
  //     quickbtn: "3,000FT 계약 바로가기",
  //     // image: mainbottomicon_01,
  //     // imageLabel: "총 계약 건수 아이콘",
  //   },
  // ];

  const TabPanelSx = styled(TabPanel)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.h5,
    // padding: theme.spacing(1),
    // textAlign: "center",
    // display: "flex",
    // flexDirection: "column",
    // backgroundColor: "#EFF2F4",
    // position: "relative",
    // height: "100%",
    padding: "0",
    marginTop: "24px",
    // color: theme.palette.text.secondary,
    // background: "#29314a",
    // color: "#fff",
  }));

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              // borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // indicatorColor="none"
              sx={{
                ".MuiTabs-indicator": {
                  // 선택된 탭에만 밑줄 스타일 지정
                  backgroundColor: "transparent", // 밑줄 제거
                },
              }}
            >
              <StyledTab label="일반(직접)" value="1" />
              <StyledTab label="플랫폼판매" value="2" />
              <StyledTab label="플랫폼구매" value="3" />
            </TabList>
          </Box>
          <TabPanelSx value="1">
            <DirectSalesTab isActive={value === "1"} />
          </TabPanelSx>
          <TabPanelSx value="2">
            <PlatformSalesTab isActive={value === "2"} />
          </TabPanelSx>
          <TabPanelSx value="3">
            <PlatformBuyingTab isActive={value === "3"} />
          </TabPanelSx>
        </TabContext>
      </Box>
    </>
  );
};

export default ContractProgress;
