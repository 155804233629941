import { useState } from "react";

function useErrorHandling() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleError = (error) => {
    // let message = "인증 과정에서 오류가 발생했습니다.";
    let message = "";
    let continuePolling = true; // 기본적으로 풀링을 계속 유지

    if (error.response) {
      const status = error.response.status;
      switch (status) {
        case 200:
          // 200 OK - 서명 성공, 폴링을 중단
          continuePolling = false;
          break;
        case 412:
          // 401 Unauthorized - 풀링을 계속합니다. 메시지는 필요 없습니다.
          console.log("풀링중");
          break;
        case 403:
          // 403 Forbidden - 계약 담당자가 아닙니다, 메시지를 보여주고 풀링을 계속합니다.
          console.log("접근이 거부되었습니다.");
          message = "계약 담당자가 아닙니다.";
          break;
        case 500:
          message = "서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.";
          continuePolling = false;
          break;
        default:
          message = "네트워크 오류가 발생했습니다. 연결을 확인해 주세요.";
          continuePolling = false; // 네트워크 오류 등 일부 오류는 풀링 중단
          break;
      }
    }
    if (message) {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }
    return continuePolling;
    // if (!message) {
    //   message = "알 수 없는 오류가 발생했습니다.";
    // }
    // setSnackbarMessage(message);
    // setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return { handleError, snackbarOpen, snackbarMessage, handleCloseSnackbar };
}

export default useErrorHandling;
