// import { useQuery } from "@tanstack/react-query";
// import client from "assets/js/commonApi";
// import { useSearchParams } from "react-router-dom";
// import axios from "axios";

// const fetchSearchContracts = ({ keyword }) => {
//   const baseUrl = "/category";
//   // 조건부 URL 설정: 키워드가 있으면 검색 경로, 없으면 기본 경로
//   return client.post(keyword ? `${baseUrl}/search?q=${keyword}` : baseUrl); // 520
// };

// export const useSearchContractsQuery = (keyword, searchInitiated) => {
//   return useQuery({
//     queryKey: ["contracts-search", keyword],
//     queryFn: () => fetchSearchContracts({ keyword }),
//     select: (data) => data.data, // API 응답에서 contractSummaries 배열을 추출
//     enabled: !!keyword && searchInitiated, // 검색어가 있고, 검색이 시작된 경우에만 쿼리를 활성화 // (검색어 비어 있지 않을 때)
//     onError: (error) => {
//       console.error("## Error fetching contracts:", error);
//     },
//     onSuccess: (data) => {
//       console.log("## Contracts data:", data);
//       // setSearchParams({ q: keyword });
//     },
//   });
// };

import { useQuery } from "@tanstack/react-query";
import client from "assets/js/commonApi";
import { useSearchParams } from "react-router-dom";

// 검색 및 필터링을 위한 API 요청
const fetchSearchContracts = ({
  keyword,
  sortOption = "reg_date",
  additionalOptions = {},
}) => {
  // const baseUrl = "/category";
  const baseUrl = "/platformitem/list";
  // const query = keyword ? `?q=${encodeURIComponent(keyword)}` : "";
  // const body = keyword ? {} : { sort_cat: sortOption, ...additionalOptions }; // 검색어가 있으면 검색, 없으면 일반 목록 + 추가 옵션
  // const body = { sort_cat: sortOption, ...additionalOptions };
  const body = { q: keyword, sort_cat: sortOption, ...additionalOptions };
  // return client.post(`${baseUrl}${query ? "/search" + query : "/list"}`, body);
  // return client.post(`${baseUrl}${query}`, body);
  return client.post(baseUrl, body);
};

// 통합된 훅
export const useSearchContractsQuery = ({
  keyword,
  searchInitiated,
  sortOption = "reg_date",
  additionalOptions = {},
}) => {
  return useQuery({
    queryKey: ["contracts", { keyword, sortOption, ...additionalOptions }],
    queryFn: () =>
      fetchSearchContracts({ keyword, sortOption, additionalOptions }),
    // select: (response) => response.data.data || [], // 데이터가 없는 경우 빈 배열 반환
    select: (response) => {
      console.log("API response data:", response.data.data);
      return response.data.data || [];
    },
    enabled: searchInitiated, // 검색이 시작된 경우에만 쿼리 활성화
    onError: (error) => {
      console.error("Error fetching contracts:", error);
    },
  });
};
