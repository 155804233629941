import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

// 단일 스텝 데이터 가져오기
const fetchStepData = async (stepId, params) => {
  const response = await client.get(`/user/mypage/${stepId}`, { params });
  return response.data.data;
};

// 두 개의 스텝 데이터를 병합하여 가져오는 함수
const fetchCombinedStepData = async (stepIds, params) => {
  const responses = await Promise.all(
    stepIds.map((stepId) => fetchStepData(stepId, params)),
  );
  // return responses.flat(); // 모든 응답 데이터를 하나의 배열로 병합

  const combinedData = responses.flat();
  combinedData.sort((a, b) => new Date(b.ins_dtm) - new Date(a.ins_dtm));
  return combinedData;
};

// 단일 또는 병합된 스텝 데이터를 가져오는 훅
export const usePlatformBuyingStepQuery = (
  stepId,
  params,
  isEnabled = false,
) => {
  const queryClient = useQueryClient(); // queryClient 인스턴스 접근
  let stepIds;

  // 두 개의 스텝 데이터를 병합
  if (stepId === "301" || stepId === "302") {
    stepIds = ["301", "302"];
  }
  // else if (stepId === "304" || stepId === "305") {
  //   stepIds = ["304", "305"];
  // }
  else if (stepId === "308" || stepId === "309") {
    stepIds = ["308", "309"];
  } else {
    stepIds = [stepId]; // 다른 경우 단일 스텝 데이터만 처리
  }

  const fetchData =
    stepIds.length > 1
      ? () => fetchCombinedStepData(stepIds, params)
      : () => fetchStepData(stepId, params);

  return useQuery({
    queryKey: ["platform_buying_step_data", stepIds.join(","), params],
    queryFn: () => fetchData(stepIds, params),
    enabled: isEnabled, // 쿼리를 자동 실행하지 않고, 필요할 때만 실행하도록 설정
    select: (result) => result,
    onSuccess: () => {
      queryClient.invalidateQueries(["platform_buying_step_data"]);
    },
    staleTime: 300000, // 데이터를 5분간 fresh 상태로 유지
  });
};
