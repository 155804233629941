// import React from "react";
import React, { useEffect, useState } from "react";
import "./PlatformDetail.css";
import {
  Box,
  Typography,
  Container,
  Grid,
  Paper,
  Stack,
  Chip,
  Button,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { usePlatformDetailQuery } from "assets/hooks/useCategory/useSelectDetailQuery";
// import PlatformDetailTable from "components/table/PlatformInfoTable";
import PlatformInfoTable from "components/table/PlatformInfoTable";
import PlatformInfoDetailTable from "components/table/PlatformInfoDetailTable";

// PlatformInfoDetalTable

// 아이콘

import {
  VisibilityOutlined, // 조회수 아이콘
  FolderOutlined, // 파일타입 아이콘
  EventAvailableOutlined, // 등록일 아이콘
  Settings, // 파일 사이즈 아이콘
  Favorite, // 좋아요 아이콘
  FavoriteBorder, // 좋아요 Line
  SaveAlt, // 다운로드 아이콘
  List, // 리스트 아이콘
  ShoppingCartRounded, // 장바구니 fill
  ShoppingCartOutlined, //  장바구니 line
  AddShoppingCartOutlined,
  Store,
  Storefront, // 구매요청
  CheckCircleOutline, // 구매요청완료 계약하기
  StorefrontRounded,
  ShoppingCart,
  HourglassBottom,
  InsertDriveFileOutlined,
  BorderColor, // 계약하기 후보 아이콘1
  PostAdd,
  Padding,
} from "@mui/icons-material";

import { useUpdateLikeQuery } from "assets/hooks/useCategoryDetail/useUpdateLikeQuery"; // 좋아요 api
import { useAddToCartQuery } from "assets/hooks/useCategoryDetail/useAddToCartQuery";
import { usePlatformBuyReqQuery } from "assets/hooks/useCategoryDetail/usePlatformBuyReqQuery";
import { SnackbarMessage } from "components/modal"; // 스낵바 팝업

const StackSX = {
  display: "flex",
  flexDirection: "row",
  // pl: 0.5,
  // pr: 0.5,
  // padding: "4px !important",
  // mb: 2,
};

const ContainerSx = styled(Container)(
  ({
    theme,
    paddingTop = 8,
    paddingBottom = 6,
    paddingLeft = 0,
    paddingRight = 0,
  }) => ({
    // pt: 8,
    // pb: 6,
    // paddingTop: theme.spacing(8),
    // paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(paddingTop), // 기본값은 8
    paddingBottom: theme.spacing(paddingBottom), // 기본값은 6
    paddingLeft: theme.spacing(paddingLeft),
    paddingRight: theme.spacing(paddingRight),
  }),
);

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// 이미지 스타일

// const labels = [
//   { label: "센터명", key: "centerName" },
//   { label: "담당자명", key: "contactName" },
//   { label: "담당자연락처", key: "contactNumber" },
//   { label: "이용허락범위", key: "permissionScope" },
//   { label: "연장신청사유", key: "extensionReason" },
//   { label: "등록일", key: "registrationDate" },
// ];

const PlatformDetail = () => {
  const access = sessionStorage.getItem("access");
  const { platform_main_id } = useParams();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const {
    data: details,
    isLoading,
    isError,
    error,
  } = usePlatformDetailQuery(platform_main_id);

  // const [likeStatus, setLikeStatus] = useState(details && details.user_like);
  // const [likeStatus, setLikeStatus] = useState("N");

  // useEffect(() => {
  //   if (details && details.user_like) {
  //     setLikeStatus(details.user_like);
  //   }
  //   if (details && details.user_cart) {
  //     setInCart(details.user_cart);
  //   }
  //   if (details && details.user_buy) {
  //     setRequesting(details.user_buy);
  //   }
  // }, [details]);

  // useEffect(() => {
  //   if (details && details.user_like) {
  //     setLikeStatus(details.user_like);
  //   }
  //   if (details && details.like_cnt) {
  //     setLikeCount(details.like_cnt);
  //   }
  //   if (details && details.user_cart) {
  //     setInCart(details.user_cart);
  //   }
  //   if (details && details.user_buy) {
  //     setRequesting(details.user_buy);
  //   }
  // }, []);

  useEffect(() => {
    if (details) {
      setLikeStatus(details.user_like === "Y");
      setLikeCount(details.like_cnt);
      setInCart(details.user_cart === "Y");
      setRequesting(details.user_buy === "Y");
    }
  }, [details]);

  const formatTotalData = (num) => {
    return new Intl.NumberFormat("ko-KR").format(num);
  };

  const { mutate: updateLike } = useUpdateLikeQuery(); // 좋아요
  // const { mutate: addToCartMutation } = useAddToCartQuery(); // 장바구니
  // const { mutate: buyReqMutation } = usePlatformBuyReqQuery([]); // 구매 요청
  const addToCartMutation = useAddToCartQuery(); // 장바구니
  const buyReqMutation = usePlatformBuyReqQuery([]); // 구매 요청
  // const [likeStatus, setLikeStatus] = useState(details?.user_like || "N"); //
  //
  //
  // const [likeStatus, setLikeStatus] = useState(details?.user_like); // 좋아요
  // const [likeCount, setLikeCount] = useState(details?.like_cnt);
  // const [isInCart, setInCart] = useState(details?.user_cart); // 장바구니
  // const [isRequesting, setRequesting] = useState(details?.user_buy); // 구매 요청 상태 추가
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isInCart, setInCart] = useState(false);
  const [isRequesting, setRequesting] = useState(false);

  console.log("Current like status:", likeStatus);
  console.log("Current like count:", likeCount);
  console.log("Current like count:", details?.like_cnt);

  // console.log("##details?.user_like", details?.user_like);
  // console.log("####likeCount", likeCount);

  const [isPopupOpen, setPopupOpen] = useState(false); // 장바구니, 구매요청
  const [popupMessage, setPopupMessage] = useState(""); // 장바구니, 구매요청

  // 좋아요
  const handleLikeClick = () => {
    // const newLikeStatus = likeStatus === "Y" ? "N" : "Y";
    if (!access || access === "null") {
      navigate("/login", { replace: true });
      return; // 추가 실행 중지
    }
    const newLikeStatus = !likeStatus;
    // const newLikeCount = likeStatus ? likeCount - 1 : likeCount + 1; //
    updateLike(
      { platform_main_id, userLike: newLikeStatus ? "Y" : "N" },
      {
        onSuccess: () => {
          setLikeStatus(newLikeStatus); // 성공적으로 업데이트 되면 상태 업데이트
          // setLikeCount(newLikeCount);
          setLikeCount((prevCount) =>
            newLikeStatus ? prevCount + 1 : prevCount - 1,
          );
        },
        onError: () => {
          // 에러 처리: 사용자에게 실패 메시지 표시 등
          alert("좋아요 업데이트에 실패했습니다.");
        },
      },
    );
  };

  // 계약 진행 중 또는 계약 완료 된  상풍 입니다.
  // 장바구니
  const handleAddToCart = () => {
    console.log("##@@## handleAddToCart called");
    if (!access || access === "null") {
      navigate("/login", { replace: true });
      return; // 추가 실행 중지
    }
    console.log("##@@## Access not null, checking cart status...");
    if (details?.user_cart === "Y") {
      setPopupMessage("이미 장바구니에 담겨져 있습니다.");
      setPopupOpen(true);
      // setInCart(true);
    } else if (details?.user_buy === "Y") {
      console.log("##@@## Already purchased or requested");
      setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
      setPopupOpen(true);
    } else {
      console.log("##@@## Attempting to add to cart...");
      addToCartMutation.mutate(details?.platform_main_id, {
        onSuccess: (data) => {
          setPopupMessage("장바구니에 담겼습니다.");
          setPopupOpen(true);
          setInCart(true);
          setInCart("Y"); // 장바구니 상태 업데이트
        },
        // onSuccess: (data) => {
        //   // 조건에 따라 적절한 메시지를 설정합니다.
        //   if (data.msg === "같은 회사 소속") {
        //     setPopupMessage("같은 회사 소속입니다.");
        //   } else {
        //     setPopupMessage("장바구니에 담겼습니다.");
        //   }
        //   setPopupOpen(true);
        // },
        onError: (error) => {
          console.error("##@@## Add to cart failed", error);
          if (error.response.status === 406) {
            setPopupMessage("같은 회사 소속입니다.");
            setPopupOpen(true);
            setInCart(false);
          } else if (error.response.status === 409) {
            setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
            setPopupOpen(true);
          } else if (error.response.status === 419) {
            setPopupMessage("이미 장바구니에 담겨져 있습니다.");
            setPopupOpen(true);
          } else {
            setPopupMessage("오류가 발생했습니다. 다시 시도해주세요.");
            setPopupOpen(true);
          }
        },
      });
    }
  };

  // platform_main_id
  // const handleBuyReq = (selectedItems) => {
  const handleBuyReq = (platform_main_id) => {
    // console.log("@@배열확인", typeof selectedItems, selectedItems);

    // 배열이 맞는지 확인
    // if (!Array.isArray(selectedItems)) {
    //   console.error("selectedItems must be an array");
    //   return; // 배열이 아니면 함수 실행 중지
    // }
    // 이미 구매 요청이 되었는지 확인
    // const platformMainIds = selectedItems.map((item) => item.platform_main_id);
    // console.log("##배열확인", typeof platformMainIds);
    if (!access || access === "null") {
      navigate("/login", { replace: true });
      return; // 추가 실행 중지
    }
    // if (details?.user_buy === "Y") {
    //   setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
    //   setPopupOpen(true);
    // }
    if (isRequesting) {
      setPopupMessage("이미 구매요청 및 계약완료된 상품입니다.");
      setPopupOpen(true);
      return; // 이미 계약 요청된 상태면 추가 요청 방지
    } else {
      // 구매 요청이 아직 안 되었다면, 요청 실행
      // buyReqMutation.mutate(platformMainIds, {
      // buyReqMutation.mutate(selectedItems, {
      setRequesting(false); // 요청 시작
      buyReqMutation.mutate(platform_main_id, {
        onSuccess: () => {
          setPopupMessage("구매요청이 완료되었습니다.");
          setPopupOpen(true);
          // 성공 시 아이콘 변경 로직 등 추가 기능 구현
          setRequesting(true); // 요청 완료
          setRequesting("Y");
        },
        onError: (error) => {
          let message = "구매요청 실패. 다시 시도해주세요."; // 기본 메시지
          if (error.response) {
            switch (error.response.status) {
              case 400:
                message = "같은 회사 소속입니다.";
                break;
              case 409:
                message = "이미 구매요청 및 계약완료된 상품입니다.";
                break;
              case 418:
                message = "다른 유형의 상위 계약입니다.";
                break;
              default:
                message = error.response.data?.message || message; // 서버가 제공하는 오류 메시지가 있는 경우 사용
            }
          }
          setPopupMessage(message);
          setPopupOpen(true);
          setRequesting(false); // 요청 실패
        },
      });
    }
  };

  useEffect(() => {
    if (isLoading) {
      console.log("Loading...");
    } else if (isError) {
      console.error("Error:", error);
    } else {
      console.log("##details", details);
    }
  }, [details, isLoading, isError, error]);

  //   console.log("##details", details);

  return (
    <>
      <Box sx={{ pb: 6 }}>
        <Box>
          <ContainerSx
            maxWidth="lg"
            // sx={{ display: "flex", flexDirection: "row" }}
          >
            <Typography
              component="h2"
              variant="h4"
              sx={{
                display: "flex",
                margin: "32px 0 16px",
                // textAlign: "center",
                // justifyContent: "center",
                // alignItems: "flex-end",
              }}
            >
              상품 상세 정보
            </Typography>
            <Box
              sx={{
                border: "1px solid #ccc",
                // borderRadius: 2,
                // mt: 1,
                // mb: 4,
                mb: 2,
                p: 2,
                pl: 0,
                pr: 0,
                borderLeft: 0,
                borderRight: 0,
                // width: "100%",
                // flexGrow: 1,
              }}
            >
              {/* <Stack gap={1} sx={StackSX}> */}
              <Grid container spacing={5}>
                <Grid item xs={3.5}>
                  <Box
                    className="pd_flexContainer"
                    sx={{
                      // height: "265px",
                      maxHeight: "322px",
                    }}
                  >
                    <img
                      // style={{
                      //   maxWidth: "100%",
                      //   height: "auto",
                      //   borderRadius: "8px", // 예시로 모서리를 둥글게 하는 스타일 추가
                      // }}
                      src={details?.data_img_url}
                      alt={details?.data_title}
                      className="pd_imageStyle"
                    />
                  </Box>
                </Grid>
                {/* 이미지 영역 */}
                <Grid item xs={8.5}>
                  {/* <Box container spacing={2}> */}
                  <Box>
                    <Stack direction="row">
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          mb: 2.5,
                          width: "80%",
                          "& .MuiChip-label": { pl: 1, pr: 1 },
                        }}
                      >
                        <Chip
                          label={details?.data_main_cat_desc}
                          // variant="outlined"
                          sx={{
                            height: "24px",
                            // borderColor: "#468ECD",
                            // color: "#468ECD",
                            // borderColor: "#29314A",
                            // color: "#29314A",
                            // backgroundColor: "#29314A",
                            // color: "#fff",
                            // fontWeight: "600",
                            fontWeight: "500",
                          }}
                        />
                        {/* 메인 카테고리 */}
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "0.8125rem", // 13px
                            height: "24px",
                          }}
                        >
                          {details?.company_name}
                        </Typography>
                        {/* 회사명 */}
                      </Stack>
                      <Stack
                        sx={{
                          // mb: 2.5,
                          mb: 1.25,
                          width: "20%",
                          alignItems: "flex-end",
                        }}
                      >
                        <List
                          onClick={handleBack}
                          // fontSize="large"
                          sx={{
                            cursor: "pointer",
                            marginTop: "-10px",
                            fontSize: "2.375rem",
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack gap={2}>
                      <Typography
                        component="h3"
                        variant="h5"
                        sx={{ fontWeight: "500" }}
                      >
                        {details?.data_title}
                      </Typography>
                      <Typography>
                        {/* 설명 : */}
                        {details?.data_desc}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={0.1}
                        gap={0.5}
                        alignItems="center"
                        sx={{
                          // mt: 1,
                          flexWrap: "wrap",
                          // marginTop: "-12px",
                          marginTop: "-6px",
                        }}
                      >
                        {Array.isArray(details?.data_sub_cat) &&
                          details?.data_sub_cat.map((tag, index) => (
                            <Chip
                              key={index}
                              label={tag}
                              // variant="outlined"
                              variant="outlined"
                              sx={{
                                // fontSize: "0.75rem",
                                fontSize: "0.8125rem",
                                // height: "auto",
                                height: "24px",
                                // p: 0,
                                // border: "0",
                                // color: "#00000099",
                                "& .MuiChip-label": { pl: 1, pr: 1 }, //0.75
                              }}
                            />
                          ))}
                      </Stack>
                      {/* <Typography sx={{ fontSize: "0.8125rem" }}>
                        {details?.data_sub_cat}
                      </Typography> */}
                      {/* 서브 카테고리 :  */}
                      <Typography
                        sx={{
                          pt: 0.5,
                          color: "#29314A",
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          // fontSize: "1.5rem" 24px
                          fontSize: "1.625rem", // 26px
                        }}
                        variant="h5"
                      >
                        {/* 가격:  */}
                        {formatTotalData(details?.data_price)}
                        <Typography
                          component="span"
                          // variant="h6"
                          sx={{
                            pl: 0.25,
                            // mt: 0.5,
                            marginTop: "-2px",
                            // fontWeight: "bold",
                            // fontSize: "1.375rem",
                            // fontSize: "1.365rem",
                            fontSize: "1.3125rem",
                            position: "absolute",
                            fontWeight: "500",
                            // fontFamily: "Noto Sans KR",
                          }}
                        >
                          원
                        </Typography>
                      </Typography>
                    </Stack>
                    {/* 텍스트 영역 top 영역 */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        // mt: 2,
                        mt: 1.35,
                        color: "#B0B0B0",
                        // fontSize: "0.875rem",
                      }}
                    >
                      <Stack
                        direction="row"
                        // gap={2.5}
                        gap={1.75}
                        sx={{
                          // display: "flex",
                          justifyContent: "flex-start",
                          // width: "75%",
                          // color: "#B0B0B0",
                        }}
                        // gap={2}
                        // spacing={2}
                      >
                        <Stack gap={0.5} sx={StackSX}>
                          <EventAvailableOutlined
                            sx={{ fontSize: "1rem", mt: 0.5 }}
                          />
                          <Typography sx={{ fontSize: "0.875rem" }}>
                            {details?.ins_dtm}
                          </Typography>
                        </Stack>
                        <Stack gap={0.5} sx={StackSX}>
                          <Settings sx={{ fontSize: "1rem", mt: 0.5 }} />
                          <Typography sx={{ fontSize: "0.875rem" }}>
                            {formatTotalData(details?.data_size)}KB
                          </Typography>
                        </Stack>
                        <Stack gap={0.5} sx={StackSX}>
                          {/* <FolderOutlined sx={{ fontSize: "1rem", mt: 0.5 }} /> */}
                          <InsertDriveFileOutlined
                            sx={{ fontSize: "1rem", mt: 0.5 }}
                          />
                          <Typography sx={{ fontSize: "0.875rem" }}>
                            {details?.data_ext}
                          </Typography>
                        </Stack>
                        <Stack gap={0.5} sx={StackSX}>
                          <SaveAlt sx={{ fontSize: "1rem", mt: 0.5 }} />
                          <Typography sx={{ fontSize: "0.875rem" }}>
                            {formatTotalData(details?.download_cnt)}
                          </Typography>
                        </Stack>
                        <Stack gap={0.5} sx={StackSX}>
                          <VisibilityOutlined
                            sx={{ fontSize: "1rem", mt: 0.5 }}
                          />
                          <Typography sx={{ fontSize: "0.875rem" }}>
                            {formatTotalData(details?.view_cnt)}
                          </Typography>
                        </Stack>
                        <Stack gap={0.5} sx={StackSX}>
                          <Favorite sx={{ fontSize: "1rem", mt: 0.5 }} />
                          <Typography sx={{ fontSize: "0.875rem" }}>
                            {formatTotalData(likeCount)}
                          </Typography>
                        </Stack>
                      </Stack>
                      {/* left 영역 */}
                      {/* <Stack
                        direction="row"
                        gap={3}
                        sx={{
                          justifyContent: "flex-end",
                          width: "25%",
                        }}
                      >
                        <Stack gap={0.5} sx={StackSX}>
                          <Favorite fontSize="small" />
                          <Typography>{likeCount}</Typography>
                        </Stack>
                        <Stack gap={0.5} sx={StackSX}>
                          <SaveAlt fontSize="small" />
                          <Typography>{details?.download_cnt}</Typography>
                        </Stack>
                      </Stack> */}
                      {/* right 영역 */}
                    </Box>
                    {/* 텍스트 영역 bottom 영역 */}
                  </Box>
                </Grid>
                {/* 텍스트 영역 */}
              </Grid>
              {/* </Stack> */}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack direction="row" spacing={2}>
                <Chip
                  // icon={<FavoriteBorderIcon />}
                  // label="좋아요"
                  // variant="outlined"
                  // color="error"
                  // sx={{ color: "red" }}
                  //
                  icon={
                    likeStatus ? (
                      <Favorite sx={{ fontSize: "1.375rem" }} />
                    ) : (
                      <FavoriteBorder sx={{ fontSize: "1.375rem" }} />
                    )
                  }
                  // icon={
                  //   details?.user_like === "Y" ? (
                  //     <FavoriteIcon />
                  //   ) : (
                  //     <FavoriteBorderIcon />
                  //   )
                  // }
                  label="좋아요"
                  variant="outlined"
                  // variant={likeStatus === "Y" ? "container" : "outlined"}
                  color="error"
                  onClick={handleLikeClick}
                  sx={{ pl: 0.5, pr: 0.5 }}
                />

                <Chip
                  icon={
                    isInCart ? (
                      <ShoppingCart sx={{ fontSize: "1.3125rem" }} />
                    ) : (
                      <AddShoppingCartOutlined sx={{ fontSize: "1.3125rem" }} />
                    )
                  }
                  // icon={
                  //   isInCart === "Y" ? (
                  //     <ShoppingCartIcon />
                  //   ) : (
                  //     <AddShoppingCartOutlinedIcon />
                  //   )
                  // }
                  label="장바구니"
                  variant="outlined"
                  // variant={!isRequesting ? "outlined" : "container"}
                  color="success"
                  onClick={handleAddToCart}
                  sx={{ pl: 0.5, pr: 0.5 }}
                />
                {/* <IconButton color="success" aria-label="add to shopping cart">
                  <AddShoppingCartOutlinedIcon />
                </IconButton> */}
                <Chip
                  icon={
                    isRequesting ? (
                      <CheckCircleOutline
                        sx={
                          {
                            // color: "#fff !important"
                            // color: "#468ECD !important",
                          }
                        }
                      />
                    ) : (
                      <PostAdd
                        sx={
                          {
                            // color: "#fff !important"
                            // color: "#468ECD !important",
                          }
                        }
                      />
                    )
                  }
                  // icon={
                  //   isRequesting === "Y" ? (
                  //     <CheckCircleOutlineIcon />
                  //   ) : (
                  //     <StorefrontIcon />
                  //   )
                  // }
                  // label="구매요청"

                  // label={!isRequesting ? "구매요청" : "진행 중"}

                  // variant={!isRequesting ? "outlined" : "container"}

                  // onClick={handleBuyReq}
                  // platform_main_id={platform_main_id}
                  // platform_main_id={platform_main_id}
                  // selectedItems={selectedItems}
                  color="info"
                  variant="outlined"
                  label="계약하기"
                  onClick={() => handleBuyReq([{ platform_main_id }])}
                  sx={{
                    pl: 0.5,
                    pr: 0.5,
                    // backgroundColor: "#29314a",
                    // backgroundColor: "#468ECD",
                    fontWeight: "500",
                    // color: "#468ECD",
                    // borderColor: "#468ECD",
                    // color: "#fff",
                  }}
                />
                {/* <Button variant="outlined" startIcon={<FavoriteBorderIcon />}>
                  좋아요
                </Button>
                <Button
                  // variant="contained"
                  variant="outlined"
                  startIcon={<ShoppingCartOutlinedIcon />}
                >
                  장바구니
                </Button>
                <Button
                  // variant="contained"
                  variant="outlined"
                  startIcon={<StorefrontIcon />}
                >
                  구매요청
                </Button> */}
              </Stack>
            </Box>
            {/* 좋아요, 장바구니, 구매요청 버튼 */}
            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  display: "flex",
                  margin: "32px 0 12px",
                  // textAlign: "center",
                  // justifyContent: "center",
                  // alignItems: "flex-end",
                }}
              >
                데이터 정보
              </Typography>
              <PlatformInfoTable details={details} />
            </Box>
            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  display: "flex",
                  margin: "32px 0 12px",
                  // textAlign: "center",
                  // justifyContent: "center",
                  // alignItems: "flex-end",
                }}
              >
                데이터 상세 정보
              </Typography>
              <PlatformInfoDetailTable columnData={details?.column_info} />
            </Box>
          </ContainerSx>
        </Box>
        {/* <h1>Platform Detail</h1>
        <p>Platform ID: {platform_main_id}</p>
        <p>Company Name: {details?.company_name}</p>
        <p>Description: {details?.data_desc}</p>
        <p>Main Category: {details?.data_main_cat_desc}</p>
        <p>Price: {details?.data_price}</p>
        <p>Sub Categories: {details?.data_sub_cat.join(", ")}</p>
        <img src={details?.data_img_url} alt={details?.data_title} /> */}
        {/* 추가적인 상세 내용 표시 */}
      </Box>
      <SnackbarMessage
        open={isPopupOpen}
        // onClose={() => setPopupOpen(false)}
        handleClose={() => setPopupOpen(false)}
        message={popupMessage}
        autoHideDuration={10000}
      />
    </>
  );
};

export default PlatformDetail;
