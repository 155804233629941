import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonBase,
  Typography,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ModalFileInfo from "components/modal/ModalFileInfo";

const FileItem = ({
  storage,
  select,
  file,
  onFileInfoClick,
  onFileClick,
  onFileMoveClick,
  onFileDownloadClick,
  onMoveStorageFile,
  onDeleteStorageFileClick,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <ListItem>
      <ButtonBase
        sx={{
          width: '100%',
          backgroundColor: select ? "#007BFF" : "#ccc",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
        onClick={(event) => {
          onFileClick(file)();
          event.stopPropagation();
        }}
      >
        <ListItemIcon sx={{ marginLeft: '15px' }}>
          <InsertDriveFileIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography noWrap>{`${file.file_name}.${file.file_ext}`}</Typography>
        </ListItemText>

        {/* <button onClick={onFileClick({ upload_file_id: file.upload_file_id })}>
        파일 정보 수정하기
      </button>
      <button
        onClick={onMoveStorageFile(
          storage.storage_sub_id,
          "folder_Emeok7A_DOl1Iq-jmLHxU",
          "folder_qTQfSwBmbiyQKEA_iBew7",
          [file.upload_file_id],
        )}
      >
        파일 이동
      </button>
      <button onClick={onDeleteStorageFileClick(file)}>파일 삭제</button> */}
        <IconButton
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={(event) => {
              setAnchorEl(null);
              event.stopPropagation();
              onFileInfoClick(file)();
            }}
          >
            파일 정보 수정하기
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              setAnchorEl(null);
              event.stopPropagation();
              onFileDownloadClick(file)()
            }}
          >
            파일 다운로드
          </MenuItem>
          {/* 추후 삽입 */}
          <MenuItem
            onClick={(event) => {
              onFileMoveClick(file);
              setAnchorEl(null);
              event.stopPropagation();
            }}
          >
            파일 이동
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              setAnchorEl(null);
              event.stopPropagation();
              onDeleteStorageFileClick(file)();
            }}
          >
            파일 삭제
          </MenuItem>
        </Menu>
      </ButtonBase>
    </ListItem>
  );
};

export default FileItem;
