import React, { useState, useContext } from "react";
import { MessageContext } from "assets/js/syntax";
import { Box, Container } from "@mui/material";
import DataGridLayout from "components/grid/DataGridLayout";
import { ModalDirectContract } from "components/modal/ModalDirectContract";
import { ModalPlatformContract } from "components/modal/ModalPlatformContract";
import { ModalPlatformBuyContract } from "components/modal/ModalPlatformBuyContract";

import BasicTooltip from "components/tooltips/BasicTooltip";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const StepItemList = ({
  stapData,
  company_info_type,
  directStapData,
  platformSalesStapData,
  platforBuyingStapData,
  contract_type,
  isDataLoading,
  currentStep,
  // company_info_type,
}) => {
  const { triggerRefresh } = useContext(MessageContext);
  // 병합되는 api(데이터) 때문에 포맺팅
  const formattedData = stapData?.map((contract, index) => ({
    id: index + 1,
    contract_sub_id: contract.contract_sub_id,
    contract_title: contract.contract_title,
    code_name: contract.code_name,
    seller_name: contract.seller_name,
    buyer_name: contract.buyer_name,
    seller_company_name: contract.seller_company_name,
    buyer_company_name: contract.buyer_company_name,
    dtm: `${dayjs(contract.from_dtm).format("YYYY-MM-DD")} ~ ${dayjs(contract.to_dtm).format("YYYY-MM-DD")}`,
    contract_type: contract_type,
    contract_user_type: contract.contract_user_type,
    doc: "계약서",
  }));

  // const formattedData = Array.isArray(directStapData)
  //   ? directStapData.map((contract, index) => ({
  //       id: index + 1,
  //       contract_sub_id: contract.contract_sub_id,
  //       contract_title: contract.contract_title || "N/A",
  //       code_name: contract.code_name,
  //       seller_name: contract.seller_name,
  //       buyer_name: contract.buyer_name,
  //       seller_company_name: contract.seller_company_name,
  //       buyer_company_name: contract.buyer_company_name,
  //       dtm: `${dayjs(contract.from_dtm).format("YYYY-MM-DD")} ~ ${dayjs(contract.to_dtm).format("YYYY-MM-DD")}`,
  //       doc: "계약서",
  //     }))
  //   : [];

  const [rowsData, setRowsData] = useState([]); // 계약 목록 데이터
  const [stepOptions, setStepOptions] = useState([]); // 검색창 옵션 데이터
  const [stepValue, setStepValue] = useState({}); // 검색창 상태(진행상황) 값, label
  const [stepInputValue, setStepInputValue] = useState(""); // 검색창 옵션 선택(진행상황 입력값) 값
  const [contractNumber, setContractNumber] = useState(""); // 계약 목록 데이터 - 계약번호 - 상태값을 저장하여 검색 기능 구현시 사용
  const [selectContractNumber, setSelectContractNumber] = useState(); // 선택된 계약번호(계약서내 계약번호)
  const [contractUserType, setContractUserType] = useState(""); // 선택된 계약의 사용자 타입
  const [isOpenModal, setIsOpenModal] = useState(false); // 계약서 모달창 open, close 판매
  const [isOpenPlatformModal, setIsOpenPlatformModal] = useState(false);
  // const [isOpenNewModal, setIsOpenNewModal] = useState(false); // 신규 계약서 모달창 open, close
  const [isOpenPlatformBuyModal, setIsOpenPlatformBuyModal] = useState(false);

  const handleClear = (event) => {
    if (!stepInputValue && event.key === "Escape") {
      setStepValue({ label: "전체", value: "" }); // clear 버튼 클릭 시 전체조회로 변경하는 로직
    }
  };

  const onStepGetOptionLabel = (option) =>
    option && option.label ? option.label : ""; // 검색바 - 선택옵션 - Input Label

  // 타입별로 조건문을 만들어 창을 띄우기
  // 지금은 대리님 바뿌니깐 담에 리스트데이터에 넣어달라고 요청하기
  const handleClickOpen = (params) => {
    console.log("## params", params);
    console.log("## params", params.row.contract_type);

    console.log("## handleClickOpen", handleClickOpen);
    console.log(
      "## company_info_type params.row.contract_user_type",
      params.row.contract_user_type,
    );
    // alert("handleClickOpen");
    // onSelectContractNumber(params.row.contract_sub_id); // 부모에 계약 번호 전달
    setSelectContractNumber(params.row.contract_sub_id);
    setContractUserType(params.row.contract_user_type);

    if (params.row.contract_type === "001") {
      setIsOpenModal(true);
    }
    // if (
    //   params.row.contract_type === "002" ||
    //   params.row.contract_type === "003"
    // ) {
    //   setIsOpenPlatformModal(true);
    // }
    if (params.row.contract_type === "002") {
      setIsOpenPlatformModal(true);
    } else if (params.row.contract_type === "003") {
      setIsOpenPlatformBuyModal(true);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    // setIsOpenNewModal(false);
    setIsOpenPlatformModal(false);
    setIsOpenPlatformBuyModal(false);
    // GetData();
  };

  // 조건적 클래스 적용
  const getCellClassName = (params) => {
    if (
      params.field === "doc" &&
      company_info_type === "buyer" &&
      currentStep === "300"
    ) {
      return "disabled-cell"; // '300' 스텝에서만 적용할 클래스
    }
    return "";
  };

  const columns = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.1,
    },
    {
      field: "contract_sub_id",
      headerName: "계약 번호",
      // type: "String",
      // width: 150,
      flex: 1.5,
      // editable: true,
      // disableColumnFilter: "true",
      renderCell: (params) => (
        <BasicTooltip title={params.row.contract_sub_id} />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "seller_name",
      headerName: "판매자",
      flex: 0.65,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.seller_name} />,
    },
    {
      field: "buyer_name",
      headerName: "구매자",
      // width: 100,
      // flex: 0.75,
      flex: 0.65,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.buyer_name} />,
    },
    {
      field: "contract_title",
      headerName: "제목",
      // type: "date",
      // width: 150,
      // flex: 1,
      flex: 1.65,
      // editable: true,
      renderCell: (params) => (
        <BasicTooltip title={params.row.contract_title} />
      ),
    },
    {
      field: "dtm",
      headerName: "계약기간",
      // type: "date",
      // width: 150,
      flex: 1.7,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.dtm} />,
    },
    // {
    //   field: "sign_seller_dtm",
    //   headerName: "구매자서명",
    //   // width: 80,
    //   flex: 0.65,
    //   renderCell: (params) => (
    //     <BasicTooltip title={params.row.sign_seller_dtm} />
    //   ),
    // },
    {
      field: "doc",
      headerName: "계약서",
      // width: 80,
      flex: 0.65,
      cellClassName: getCellClassName,
    },
    {
      field: "code_name",
      headerName: "진행상황",
      // width: 100,
      // flex: 0.75,
      flex: 1.25,
      // flex: 0.55,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.code_name} />,
    },
  ];

  const datagridSx = {
    border: "0",
    "& .MuiDataGrid-root": {
      border: "0px !important",
    },
    "& .MuiDataGrid-main": {},
    "& .MuiDataGrid-root--densityStandard": {},
    "& .MuiDataGrid-withBorderColor": {
      border: 0,
    },
    '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
      // color: "blue",

      "& div": {
        // minHeight: "60px !important",
        // height: 60,
        // lineHeight: "59px !important",
      },
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        // "&:nth-of-type(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
        "&:nth-of-type(2n)": { backgroundColor: "rgba(238, 242, 245, 1)" },
      },
    },
    "& .MuiDataGrid-cell:hover:nth-of-type(7)": {
      cursor: "pointer !important",
      color: "red",
    },
    "& .MuiDataGrid-cell:nth-of-type(7)": {
      "& .MuiDataGrid-cellContent": {
        // cursor: "pointer !important",
        // color: "red",
        // border-bottom: 1px solid #000,
        borderBottom: "1px solid #000",
      },
    },
    // 내 계약 진행 > 플랫폼 구매 300단계에서 구매자인경우만 스타일 적용
    "& .disabled-cell": {
      pointerEvents: "none",
      color: "rgba(0, 0, 0, 0.26)",
      "& .MuiDataGrid-cellContent": {
        borderBottom: "0 !important",
      },
    },

    // pointer cursor on ALL rows
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "55px !important",
      maxHeight: "55px !important",
      lineHeight: "55px !important",
      borderTop: "#29314A 3px solid",
      borderBottom: "#29314A 3px solid",

      borderRadius: "0px !important",
    },
    "& .MuiDataGrid-footerContainer": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "40px !important",
      maxHeight: "40px !important",
      lineHeight: "40px !important",
    },
  };

  // // 데이터 없을시
  // if (!Array.isArray(formattedData) || formattedData.length === 0) {
  //   return <div>No data available for this step.</div>;
  // }

  return (
    <>
      <Box>
        <Container sx={{ p: "0 !important" }}>
          {/* <SearchBaseForm
            // onClick={GetData}
            onSearch={GetData}
            fromDtm={fromDtm}
            toDtm={toDtm}
            setFromDtm={setFromDtm}
            setToDtm={setToDtm}
          /> */}
          <Box>
            <DataGridLayout
              // rows={rowsData}
              // rows={directStapData}
              rows={formattedData || []}
              // rows={directStapData && directStapData}
              // rows={partner}
              columns={columns}
              getRowId={(row) => row.contract_sub_id}
              onCellClick={handleClickOpen}
              sx={datagridSx}
              getCellClassName={getCellClassName}
              isDataLoading={isDataLoading}
            />
            {isOpenModal && (
              <ModalDirectContract
                // did={selectDid}
                contract_sub_id={selectContractNumber}
                // open={isOpenModal}
                // company_info_type="seller"
                // company_info_type={company_info_type}
                company_info_type={contractUserType}
                onClose={handleClose}
                onRefresh={triggerRefresh}
              />
            )}
            {isOpenPlatformModal && (
              <ModalPlatformContract
                // did={selectDid}
                contract_sub_id={selectContractNumber}
                // open={isOpenModal}
                // company_info_type="seller"
                company_info_type={company_info_type} // 기능적으로 필요없다.
                // company_info_type={contractUserType}
                onClose={handleClose}
                onRefresh={triggerRefresh}
              />
            )}
            {isOpenPlatformBuyModal && (
              <ModalPlatformBuyContract
                // did={selectDid}
                contract_sub_id={selectContractNumber}
                // open={isOpenModal}
                // company_info_type="seller"
                company_info_type={company_info_type} // 기능적으로 필요없다.
                // company_info_type={contractUserType}
                onClose={handleClose}
                onRefresh={triggerRefresh}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default StepItemList;
