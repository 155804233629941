import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Button, Box, Grid, Toolbar, InputBase } from "@mui/material";
import dayjs from "dayjs";
import DatePicMypagebase from "components/picker/DatePicMypagebase";

// 스타일
const DisabledSX = {
  // height: "48px",
  "& .Mui-disabled": {
    color: "#000000DE !important",
    "-webkit-text-fill-color": "#000000DE !important",
  },
  "& .MuiRadio-root": {
    p: 0,
  },
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const ToolbarSx = styled(Toolbar)(({ theme }) => ({
  // background: "#eef2f5",
  // borderRadius: "4px",
  // border: "1px solid #ebebebb3",
  // marginBottom: "16px",
  // display: "flex",
  // justifyContent: "flex-end",
  minHeight: "72px",
  // minHeight: "48px",
  // width: "100%", 230913 주석처리
  marginBottom: "8px",
  marginLeft: 0,
  // marginRight: "32px",
  padding: "0px !important",
  "& .MuiInputBase-root :focus": {
    background: "#fff",
    marginLeft: 0,

    // zIndex:'0'
  },
  "& > div": {
    // background: "#ebebebb3",
    // marginRight: "0",
    marginLeft: "0",
    // border: "1px solid #b7b6b6b3",

    borderRadius: "4px",
    // "&:focus": {
    //   backgroundColor: "blue",
    // }, // 포크스 다시적용
  },
  "& > div:focus": {
    // border: "1px solid #29304a ",
    // background: "red",
  },
  "& > div:hover": {
    // border: "1px solid #29304a ",
  },

  "& .MuiInputBase-input": {
    // backgroundColor: "red",
    // padding: theme.spacing(1, 1, 1, 0),
    // // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    // width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
  "& .MuiInputBase-input:focus": {
    border: "1px solid #29304a ",
    // background: "red",
    // background: "#fff",
  },
  //   "& .MuiInputBase-root": {
  //     // backgroundColor: "red",
  //     // padding: theme.spacing(1, 1, 1, 0),
  //     // // vertical padding + font size from searchIcon
  //     // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     // transition: theme.transitions.create("width"),
  //     // width: "100%",
  //     // [theme.breakpoints.up("md")]: {
  //     //   width: "20ch",
  //     // },
  //   },
}));

export default function SearchBaseForm({
  onClick,
  onSearch,
  fromDtm,
  toDtm,
  setFromDtm,
  setToDtm,
}) {
  // const [fromDtm, setFromDtm] = useState(dayjs().subtract(30, "day"));
  // const [toDtm, setToDtm] = useState(dayjs());

  const handleSearch = () => {
    if (onSearch) {
      onSearch(fromDtm, toDtm);
    }
  };

  // console.log("fromDtm", fromDtm);
  // console.log("toDtm", toDtm);

  const handleChange = (event) => {
    console.log("setFrom_dtm", setFromDtm);
    console.log("setTo_dtm", setToDtm);
    setFromDtm(event.target.value);
    setToDtm(event.target.value);
  };

  // 클릭 조회 버튼
  function searchButton(e) {
    if (onClick) {
      onClick(e);
      return;
    }
    console.log("###검색버튼", searchButton);
    // call your method here
    e.preventDefault();
    alert("###searchButton", searchButton);
    setFromDtm(e.target.value);
    setToDtm(e.target.value);
  }

  // 엔터 키 검색
  function handleKeyPress(e) {
    console.log("###handleKeyPress", handleKeyPress);
    if (e.code === "Enter") {
      // call your method here
      e.preventDefault();
      alert("###handleKeyPress", handleKeyPress);
    }
    // setSearchNum(e.target.value);
    setFromDtm(e.target.value);
    setToDtm(e.target.value);
    searchButton(e);
    handleSearch(e);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box>
        {/* <Box sx={{ flexGrow: 1 }}> */}
        <ToolbarSx>
          <Grid item container>
            <Grid item xs>
              <DatePicMypagebase
                component="dd"
                //
                fromValue={fromDtm}
                toValue={toDtm}
                fromOnChange={setFromDtm}
                toOnChange={setToDtm}
                // onKeyDown={handleKeyPress} 날짜 선택 후 조회 버튼 클릭, 엔터는 웹접근성(tab처리 시 가능할듯) 고려시 기능 살리기
                sx={DisabledSX}
              />
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Button
                onClick={handleSearch}
                // onClick={() => onSearch(from_dtm, to_dtm)}
                // onClick={searchButton}
                variant="contained"
                sx={{
                  // height: "41px",
                  boxShadow: "none",
                  backgroundColor: "#29304a",
                  // borderRadius: "2px"
                  height: "100%",
                  ml: 1.5,
                }}
              >
                조회
              </Button>
            </Grid>
          </Grid>
        </ToolbarSx>
      </Box>
    </Box>
  );
}
