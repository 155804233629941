import React, { useEffect, useState } from "react";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import { useNavigate, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom/dist";
// import { useNavigate } from "react-router-dom";

export default function SearchForm({ onSearch }) {
  const navigate = useNavigate();
  const location = useLocation(); // 현재 URL의 location 객체를 가져옵니다.
  const [keyword, setKeyword] = useState("");

  // URL의 쿼리 파라미터에서 검색어를 추출하여 상태를 설정합니다.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get("q");
    if (q) setKeyword(decodeURIComponent(q));
  }, [location.search]);

  const searchByKeyword = (e) => {
    e.preventDefault();
    onSearch(keyword);
    console.log("@@@ 검색 키워드:", keyword);
    navigate(`?q=${encodeURIComponent(keyword)}`, { replace: true }); //
    // setKeyword("");
  };

  const handleClearInput = () => {
    setKeyword(""); // 입력 필드 초기화
    // navigate("/search", { replace: true });
    navigate("/category", { replace: true });
  };

  // // 전체 주석 기존 240523 S
  // // const navigate = useNavigate();

  // // const [searchTerms, setSearchTerms] = useState({
  // //   data_title: '',
  // //   data_main_cat: '',
  // //   data_sub_cat: '',
  // //   data_desc: '',
  // // });

  // const handleChange = (e) => {
  //   console.log("현재 키워드:", keyword); // 변경 전 키워드
  //   setKeyword(e.target.value);
  //   console.log("업데이트 될 키워드:", e.target.value); // 변경 후 키워드, 실제 업데이트는 비동기적으로 이루어집니다.
  // };

  // // useEffect(() => {
  // //   console.log("##SearchForm");
  // // }, []);

  // // useEffect (() => { ,[]})
  // const searchByKeyword = (e) => {
  //   e.preventDefault();
  //   console.log("@@@ 검색 키워드:", keyword);
  //   // navigate(`/category?q=${keyword}`);
  //   // navigate(`http://localhost:3000//category/search?query=${keyword}`);
  //   // navigate(`/category/search?q=${keyword}`);
  //   onSearch(keyword); // 상위 컴포넌트로 검색어 전달 // 상위 컴포넌트의 handleSearch 함수를 호출하며 현재 검색어를 전달
  //   setKeyword(""); // 검색 종료 후 셋키워드 비워주고 싶을 시 추가
  //   // setKeyword(e.target.value);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center", // Centers the search form
        p: 1, // Adds padding around the box
      }}
    >
      <form
        // onClick={searchByKeyword}
        onSubmit={searchByKeyword}
        style={{ width: "100%" }}
      >
        <TextField
          value={keyword}
          // onChange={(e) => setKeyword(e.target.value)}
          onChange={(e) => setKeyword(e.target.value)}
          // onChange={handleChange}
          fullWidth
          id="search"
          type="search"
          // label="Search"
          variant="outlined"
          placeholder="Search"
          sx={{
            // width: "80%", // 폼의 너비를 조절
            // height: "100px",
            // maxWidth: 800, // 최대 너비 설정
            minWidth: 280, // 최소 너비 설정
            borderRadius: 50, // 테두리를 둥글게 설정
            "& .MuiOutlinedInput-root": {
              // OutlinedInput 내부의 root 클래스에 스타일을 적용
              borderRadius: 50, // 여기서도 borderRadius를 설정해야 실제 테두리가 둥글게 적용됩니다.
              height: 70,
            },
          }}
          // InputLabelProps={{
          //   shrink: false,
          // }}
          InputProps={{
            startAdornment: (
              // <InputAdornment
              //   position="start"
              //   type="submit"
              // >
              //   <SearchIcon />
              // </InputAdornment>
              <InputAdornment position="start">
                <IconButton type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: keyword && ( // 입력 값이 있을 때만 클리어 아이콘 표시
              <InputAdornment position="end">
                <IconButton onClick={handleClearInput} aria-label="clear input">
                  <ReplayIcon sx={{ color: "#aaa" }} fontSize="large" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
}
