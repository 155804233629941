import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { v4 } from "uuid";
import CryptoJS, { AES } from "crypto-js";
import {
  Grid,
  Box,
  // MuiGrid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Button,

  // Divider,
} from "@mui/material";
import classNames from "classnames";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SnackbarMessage from "components/modal/SnackbarMessage";
import ModalContractQrFile from "components/modal/ModalContractQrFile";
import { Margin } from "@mui/icons-material";

// const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB

function BtnNDragUploadFile({
  label = "",
  className = "",
  onFileSelect = () => null,
  contract_sub_id,
  did,
  onChange,
  upLoadCid,
  // fileNames,
  fileNameData,
  encryptKey,
  // handleQrClose,
  handleQrUploadClose,
  accept,

  // props,

  // limitInterval,
  // limit,
  // props,
}) {
  //
  // 파일 기능
  // // limitInterval 필요하다면 사용하지만, 필요하지않아, 그냥 생략 해도 됨
  // const { limitInterval } = props;

  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("refresh");
  const [fileData, setFileData] = useState({});

  const fileInputRef = useRef(null);
  const [uploadFile, setUploadFile] = useState(null);

  // 파일 사이즈 제한
  const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB

  const [open, setOpen] = React.useState(false);

  // upLoad Cid

  const [cidData, setCidData] = useState();
  const [fileNameDp, setFileNameDp] = useState();
  const [enckey, setEnckey] = useState();

  useEffect(() => {
    // if () {
    // console.log("upLoadCid", upLoadCid);
    // console.log("[[key]]", encryptKey)
    // }
  }, []);

  const [fileNameInfo, setFileNameInfo] = useState();
  console.log("fileNameInfo", fileNameInfo);

  // 파일 데이터 가져오기
  useEffect(() => {
    axios
      .post(
        "http://ariescontrol.busan-coldchain.com:8079/mdpt_file/file_name_select",
        {
          contract_sub_id: contract_sub_id,
        },
      )
      .then((res) => {
        if (res.data?.data) {
          console.log("777777777res", res);
          console.log("777777777res이름", res.data.data.NAME);
          setFileNameInfo(res);
          setFileNameDp(res.data.data.NAME);
        }
      });

    // const fileNameInfo
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleOnlyQrFileClose = () => {
    setIsOpenFileQrModal(false);
  };
  const onFileChange = (e) => {
    console.log(`asdfffsfsafsaf`);
    try {
      const files = e.target.files;
      if (files.length > 0) {
        // 파일 사이즈 제한 부분 주석 처리 S
        // if (files[0].size <= MAX_FILE_SIZE) {
        // 파일 사이즈 제한 부분 주석 처리 E
        setUploadFile(files[0]);

        // 1. 선택한 파일 불러옴
        const file = files[0];
        console.log(`[FILE]`, `${file}`);

        // 2. 선택한 파일을 base64로 변환
        const fileReader = new FileReader();
        console.log(`[KEY]`, `${encryptKey}`);
        fileReader.readAsDataURL(file);
        fileReader.onload = ({ target }) => {
          const base64 = target.result;
          console.log("#########[FILE]", target.result);
          // 3. 암호화
          const encode = AES.encrypt(base64, encryptKey).toString();
          console.log("[FILE ENCODE]", encode);

          const _files = new FormData();
          // _files.append('file', encode, encode.name)
          // _files.append('file', file, file.name)
          console.log("[FILE BB]", _files);
          const _blob = new Blob([encode]);
          _files.append("file", _blob, file.name);
          setFileNameDp(file.name);
          // 3.1 파일 전송하기
          const url = "http://ipfs.busan-coldchain.com:5001/api/v0/add";
          // const url ='/ipfs/api/v0/add'
          axios
            .post(url, _files, {
              headers: {},
            })
            // .then((value) => {

            // })
            .then((value) => {
              // setFileData(value);
              // alert(value.data.Size);
              console.log("value", value);
              console.log("value.data", value.data);
              if (value.status === 200) {
                setFileData(value);
                setOpen(value);
                // alert("파일 업로드 되었습니다.");
              }
              // console.log("value.data.data.Size", value.data);
              // const upLoadName = (value.data.Name);
              const upLoadCid = value.data.Hash;
              console.log("과장님upLoadCid", upLoadCid);
              console.log("파일네임", value.data.Name);
              // const fileNames = value.data.Name;
              // console.log("@@@@@@@@@@@파일네임", fileNames);

              console.log("[BTN KEY]", encryptKey);
              console.log("[BTN CID]", upLoadCid);
              setEnckey(encryptKey);
              setCidData(upLoadCid);

              // setFileNameData(fileNames);
              return axios.post(
                "http://ariescontrol.busan-coldchain.com:8079/mdpt_file/insert",

                {
                  // did: did,
                  contract_sub_id: contract_sub_id,
                  file_size: value.data.Size,
                  // name: value.data.Name,
                  name: file.name,
                  cid: upLoadCid,
                  // did: did,
                  // contract_sub_id: contract_sub_id,
                },
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    // access: access,
                    // refresh: refresh,
                  },
                },
              );

              // console.log("[UPLOAD]", value);
            })
            .catch((reason) => {
              console.log("[UPLOAD ERROR]", reason);
            });

          // 4. 복호화
          const decode = AES.decrypt(encode, encryptKey).toString(
            CryptoJS.enc.Utf8,
          );
          console.log("[FILE DECODE]", decode);
          console.log("key", typeof encryptKey);
          console.log("인코드", typeof encode);
          console.log("인코드ㅇㄹㅎㅎ", encode);
          // 5. base64를 원래 파일로 변환
          const src = decode;
          const fileName = file.name;
          // const mimeType = base64.split(',')[0].match(/:(.*?);/)[1]
          // 다운로드
          // const link = document.createElement("a");
          // link.href = src;
          // link.download = fileName;
          // link.click();

          // const link = document.createElement("a")
          // link.href = 'http://13.124.143.230:5001/api/v0/cat?arg=QmTa7VkRv7qrMtt9x9Dor86KkhVXqYT3FZieHbwUaVXbCb'
          // link.download = 'test'
          // link.click()
        };

        // 파일 사이즈 제한 부분 주석 처리 S
        // 제한 사이즈보다 오바시 에러 표시 띄우는곳
        // } else {
        //   throw new Error("파일 사이즈는 8MB 이하로 첨부 가능합니다.");
        // }
        // 파일 사이즈 제한 부분 주석 처리 E
      } else {
      }
    } catch (e) {
      // 에러 구간
      // alert(e);
    }
  };
  //

  // const [uploadFile, setUploadFile] = useState(null);

  // const fileInputRef = useRef(null);

  // 파일업로드 QR 상태 값
  // QR 버튼
  const [QrBtn, setQrBtn] = useState();
  // QR 창
  const [isOpenFileQrModal, setIsOpenFileQrModal] = useState(false);
  const handleClickQrOpen = (params) => {
    console.log("777params", params);
    console.log("handleClickQrOpen", handleClickQrOpen);
    // alert("handleClickQrOpen");
    setIsOpenFileQrModal(true);
    setQrBtn(params.target.value);
    // setSelectContractNumber(params.data.contract_sub_id);
  };
  // const handleQrUploadClose = () => {
  //   setIsOpenFileQrModal(false);
  //   // if (onClose) {
  //   //   onClose();
  //   // }
  // };
  //
  const FileQrButton = ({ fileData }) => {
    if (fileData.status === 200) {
      return (
        // <Box>

        // </Box>
        <Button
          autoFocus
          value={QrBtn}
          onClick={handleClickQrOpen}
          variant="contained"
          sx={{ background: "#468ECD", display: "flex", width: "100%" }}
        >
          업로드 파일 QR 전송
        </Button>
      );
    } else {
      return null;
    }
  };

  const OnFileChangeMessage = ({ fileData }) => {
    console.log("fileData", fileData);
    // alert(fileData.data.Size);
    if (fileData.status === 200) {
      // alert(fileData.Size);
      return (
        <>
          {/* <fileQrButton /> */}
          <SnackbarMessage
            // onChange={onFileChange}
            open={open}
            handleClose={handleClose}
            message="파일 업로드 되었습니다."
          />
        </>
      );
    }
  };

  // 내보낸다
  const FileDisplay = ({ fileData, fileNameData }) => {
    if (fileNameDp) {
      return (
        <Box>
          <Typography
            component="h2"
            variant="subtitle2"
            sx={{ color: "#000", whiteSpace: "nowrap" }}
          >
            현재 저장된 파일 :
          </Typography>
          <Typography
            component="h2"
            variant="subtitle2"
            sx={{ color: "#000", whiteSpace: "nowrap" }}
          >
            {fileNameData}
            {/* {fileNameDp} */}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Typography component="h2" variant="subtitle2" sx={{ color: "#ccc" }}>
          Drag 'n' drop some files here, or click to select files
        </Typography>
      );
    }
  };

  return (
    <div
    // className={"flex flex-row items-center justify-start"}
    >
      {label && label !== "" && (
        <label
          //   className="footnote-12 text-character-primary mb-[16px]"
          htmlFor={label}
        >
          {label ?? ""}
        </label>
      )}
      <Box
        sx={{ flexDirection: "column", display: "flex", marginBottom: "12px" }}
      >
        <Button
          type="button"
          variant="text"
          // className="body-regular border-natural-5 text-character-primary flex flex-row items-center justify-center gap-[10px] whitespace-nowrap border-[1px] border-solid px-[15px] py-[4px]"
          component="label"
          // startIcon={
          //   <CloudUploadIcon fontSize="large" sx={{ color: "#468ECD" }} />
          // }
          // sx={{ background: "#468ECD" }}
          fileName={uploadFile?.name}
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          <Box sx={{ alignContent: "center", textAlign: "center" }}>
            <CloudUploadIcon fontSize="large" sx={{ color: "#468ECD" }} />
            <FileDisplay
              fileData={fileData}
              fileNameData={fileNameData || fileNameDp}
            />
            {/* <Typography
              component="h2"
              variant="subtitle2"
              sx={{ color: "#ccc" }}
            >
              Drag 'n' drop some files here, or click to select files
            </Typography>
            <Typography
              component="h2"
              variant="subtitle2"
              sx={{ color: "#000" }}
            >
              현재 업로드 된 파일 : {fileNameData}
            </Typography> */}
          </Box>
        </Button>
        <input
          id={label}
          type="file"
          // accept=".json,.csv,.excel,.txt,.XML,.zip,.pdf"
          accept={accept}
          className="hidden"
          // style={{ border: "0" }}
          // style={{ display: "none" }}
          multiple={false}
          ref={fileInputRef}
          onChange={onFileChange}
          // onClick={handleClick}
          // onClick={}
          // onChange={(e) => {
          //   try {
          //     const file = e.target.files;
          //     if (file) {
          //       if (file[0].size <= MAX_FILE_SIZE) {
          //         setUploadFile(file[0]);
          //       } else {
          //         throw "파일 사이즈는 8MB 이하로 첨부 가능합니다.";
          //       }
          //     } else {
          //     }
          //   } catch (e) {
          //     // 에러 구간
          //   }
          // }}
        />
      </Box>
      <OnFileChangeMessage fileData={fileData} />
      <FileQrButton fileData={fileData} />
      {/* <FileUploadMessage /> */}
      {isOpenFileQrModal ? (
        <ModalContractQrFile
          // onClose={handleQrClose}
          isOpen={isOpenFileQrModal}
          // handleClose={handleQrClose}
          handleClose={handleQrUploadClose}
          // handleClose={handleClose}
          handleOnlyQrFileClose={handleOnlyQrFileClose}
          // value={contract_sub_id}
          cid={cidData}
          contract_sub_id={contract_sub_id}
          encryptKey={encryptKey}
          // limit={limit}
          // limitInterval={limitInterval}
          // limit={limit}
          // limitInterval={limitInterval}
          // props={props}
          // did={selectDid}
          // contract_sub_id={selectContractNumber}
          // open={isOpenModal}
          // onClose={handleQrClose}
        />
      ) : null}
    </div>
  );
}

export default BtnNDragUploadFile;
