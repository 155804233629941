import React, { useEffect, useState } from "react";
import useStorage from "views/temp/storageManagement/useStorage";
import {
  List,
  Typography,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ButtonBase,
  Button,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupsIcon from "@mui/icons-material/Groups";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseIcon from "@mui/icons-material/Close";
import { Routes, Route } from "react-router-dom";

import { ModalDirectContract } from "components/modal/ModalDirectContract";

import ModalFileInfo from "components/modal/ModalFileInfo";
import ModalFileInfoNew from "components/modal/ModalFileInfoNew";
import Directory from "views/temp/storageManagement/Directory";

import { SnackbarMessage } from "components/modal";
import Dialog from "components/modal/Dialog";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ModalExplorer from "components/modal/ModalExplorer";

// /*
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(90deg)',
  // },
  // '& .MuiAccordionSummary-content': {
  // marginLeft: theme.spacing(1),
  // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
// */

const StorageManagement = () => {
  const {
    group,
    storage,
    contract,
    file,
    qrcode,
    dialog,
    explorer,
    snackBar,
    fromDtm,
    toDtm,
    onFromChange,
    onToChange,
    onUnbindClick,
    onGroupClick,
    onContractClick,
    onFolderClick,
    onFolderMoveClick,
    onFileClick,
    onFileMoveClick,
    onFileInfoClick,
    onFileDownloadClick,
    onUploadStorageFileInfo,
    onUploadStorageFile,
    onUploadStorageFilePopupClick,
    onUploadStorageFilePopupClose,
    onUploadStorageUploadPopupClick,
    onUploadStorageUploadPopupClose,
    onUploadStorageDownloadPopupClick,
    onUploadStorageDownloadPopupClose,
    onUploadStorageFileChange,
    onCreateFolderClick,
    onMoveStorageFolderAndFile,
    onMoveStorageFile,
    onDeleteStorageFolderClick,
    onDeleteStorageFileClick,
    onMappingFilesForContractClick,
    onUnMappingFilesForContractClick,
  } = useStorage();

  // onLoadGroup();
  // useEffect(() => {
  // }, []);

  const [isShowContractListPopup, setIsShowContractListPopup] = useState(false);
  const [isShowContractPopup, setIsShowContractPopup] = useState(false);
  const [contractDetailPopup, setContractDetailPopup] = useState({
    show: false,
    did: "",
    contract_sub_id: "",
    company_info_type: "",
  });
  const [isShowContractFileListPopup, setIsShowContractFileListPopup] =
    useState(false);

  return (
    <>
      <div style={{ height: "440px", display: "flex", flexDirection: "row" }}>
        <div
          style={{
            position: "relative",
            width: "25%",
            height: "100%",
            borderRight: "1px solid #ddd",
          }}
        >
          <div
            style={{
              widht: "500px",
              height: "40px",
            }}
          >
            <Typography
              noWrap
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                widht: "inherit",
                height: "inherit",
                backgroundColor: "#fff",
                fontWeight: "bold",
                overflow: "visible",
              }}
            >
              {`홈 > ${group.select.group_name} ${
                group.select.group_name
                  ? storage.select?.path.split("/").join(" > ") ?? "root"
                  : ""
              }`}
            </Typography>
          </div>
          <List sx={{ padding: 0, overflow: "auto" }}>
            {group.list.map((group) => (
              <Accordion onChange={onGroupClick(group)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ListItemIcon>
                    <GroupsIcon />
                  </ListItemIcon>
                  <Typography>{group.group_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ListItemButton
                    onClick={() => {
                      onGroupClick(group)();
                      setIsShowContractListPopup(true);
                    }}
                  >
                    <ListItemIcon />
                    <Typography>- 판매 목록</Typography>
                  </ListItemButton>
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
          {isShowContractListPopup ? (
            <div
              style={{
                position: "absolute",
                top: "50px",
                left: "0",
                width: "100%",
                height: "90%",
                border: "1px solid #ddd",
                backgroundColor: "#fff",
                overflow: "auto",
              }}
            >
              <List>
                {/* <DatePic
                  component="dd"
                  // fromValue={from_dtm}
                  // toValue={to_dtm}
                  fromValue={fromDtm}
                  toValue={toDtm}
                  fromInputFormat={"yyyy-MM-dd"}
                  fromMask={"____-__-__"}
                  toInputFormat={"yyyy-MM-dd"}
                  toMask={"____-__-__"}
                  fromFormat="YYYY/MM/DD"
                  toFormat="YYYY/MM/DD"
                  fromOnChange={onFromChange}
                  toOnChange={onToChange}
                  // sx={radioSX}
                /> */}
                <ListItem sx={{ justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: "bold" }}>판매목록</Typography>
                  <ButtonBase onClick={() => setIsShowContractListPopup(false)}>
                    <CloseIcon />
                  </ButtonBase>
                </ListItem>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ListItem sx={{ flexDirection: "column" }}>
                    <DatePicker
                      label="시작일"
                      inputFormat={"yyyy-MM-dd"}
                      mask={"____-__-__"}
                      value={fromDtm}
                      onChange={onFromChange}
                      format={"YYYY/MM/DD"}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ marginBottom: 1 }}
                    />
                    <DatePicker
                      label="종료일"
                      inputFormat={"yyyy-MM-dd"}
                      mask={"____-__-__"}
                      value={toDtm}
                      onChange={onToChange}
                      format={"YYYY/MM/DD"}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </ListItem>
                </LocalizationProvider>
                {contract.list.map((contract) => {
                  return (
                    <ListItemButton
                      key={contract.contract_sub_id}
                      onClick={() => {
                        setIsShowContractPopup(true);
                        onContractClick({
                          contract_sub_id: contract.contract_sub_id,
                        })();
                      }}
                      sx={{ borderBottom: "1px solid #ddd" }}
                    >
                      <ListItemText>
                        <Typography noWrap>
                          {contract.contract_title}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  );
                })}
              </List>
            </div>
          ) : null}
          {isShowContractPopup ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
                overflow: "auto",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  backgroundColor: "#fff",
                  overflow: "auto",
                }}
              >
                <List>
                  <ListItem sx={{ justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      선택 계약정보
                    </Typography>
                    <ButtonBase onClick={() => setIsShowContractPopup(false)}>
                      <CloseIcon />
                    </ButtonBase>
                  </ListItem>
                  <ListItem>
                    <Typography sx={{ fontWeight: "bold" }}>제목</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{contract.select.contract_title}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography sx={{ fontWeight: "bold" }}>판매자</Typography>
                  </ListItem>
                  <ListItem sx={{ justifyContent: "space-between" }}>
                    <Typography>{contract.select.seller_group_name}</Typography>
                    <Typography>{contract.select.seller_name}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography sx={{ fontWeight: "bold" }}>구매자</Typography>
                  </ListItem>
                  <ListItem sx={{ justifyContent: "space-between" }}>
                    <Typography>
                      {contract.select.buyer_company_name}
                    </Typography>
                    <Typography>{contract.select.buyer_name}</Typography>
                  </ListItem>
                  <ListItem>
                    <Button
                      onClick={() => {
                        setContractDetailPopup({
                          show: true,
                          contract_sub_id: contract.select.contract_sub_id,
                          company_info_type: "seller",
                        });
                      }}
                    >
                      <Typography>상세 내용</Typography>
                    </Button>
                    <Button
                      onClick={() => {
                        setIsShowContractFileListPopup(true);
                      }}
                    >
                      <Typography>파일 목록</Typography>
                    </Button>
                  </ListItem>
                </List>
              </div>
              {isShowContractFileListPopup ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "50%",
                      backgroundColor: "#fffsss",
                      overflow: "auto",
                    }}
                  >
                    <List>
                      <ListItem sx={{ justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          파일 리스트
                        </Typography>
                        <ButtonBase
                          onClick={() => setIsShowContractFileListPopup(false)}
                        >
                          <CloseIcon />
                        </ButtonBase>
                      </ListItem>
                      <ListItemButton
                        onClick={onMappingFilesForContractClick(
                          contract.select.contract_sub_id,
                        )}
                      >
                        <Typography>파일 추가</Typography>
                      </ListItemButton>
                      {contract.select.file_info?.map((file) => {
                        return (
                          <ListItem
                            key={file.download_file_id}
                            style={{ justifyContent: "space-between" }}
                          >
                            <InsertDriveFileIcon style={{ color: "#5e5e5e" }} />
                            <Typography noWrap>{file.file_name}</Typography>
                            <ButtonBase
                              onClick={onUnMappingFilesForContractClick(
                                contract.select.contract_sub_id,
                                [file.download_file_id],
                              )}
                            >
                              <RemoveCircleIcon style={{ color: "#5e5e5e" }} />
                            </ButtonBase>
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          style={{ width: "75%", height: "100%" }}
          onClick={() => onUnbindClick()}
        >
          <Routes>
            <Route
              path="/:storage_sub_id/:folder_id"
              element={
                <Directory
                  storage={storage.directories}
                  selectFolder={storage.select.folder}
                  selectFile={file.select}
                  qrcode={qrcode}
                  onCreateFolderClick={onCreateFolderClick}
                  onDeleteStorageFolderClick={onDeleteStorageFolderClick}
                  onFolderClick={onFolderClick}
                  onFolderMoveClick={onFolderMoveClick}
                  onFileClick={onFileClick}
                  onFileMoveClick={onFileMoveClick}
                  onFileInfoClick={onFileInfoClick}
                  onFileDownloadClick={onFileDownloadClick}
                  onUploadStorageFilePopupClick={onUploadStorageFilePopupClick}
                  onUploadStorageFileChange={onUploadStorageFileChange}
                  onMoveStorageFile={onMoveStorageFile}
                  onDeleteStorageFileClick={onDeleteStorageFileClick}
                  onUploadStorageUploadPopupClick={
                    onUploadStorageUploadPopupClick
                  }
                  onUploadStorageUploadPopupClose={
                    onUploadStorageUploadPopupClose
                  }
                  onUploadStorageDownloadPopupClick={
                    onUploadStorageDownloadPopupClick
                  }
                  onUploadStorageDownloadPopupClose={
                    onUploadStorageDownloadPopupClose
                  }
                />
              }
            />
          </Routes>
        </div>
        {file.isShowPopup && file.select && file.select.data_size > 0 && (
          <ModalFileInfo
            fileInfo={file.select}
            open={file.isShowPopup}
            onClose={onUploadStorageFilePopupClose}
            onUpload={onUploadStorageFileInfo}
          />
        )}
        {file.isShowPopup && (!file.select || file.select.data_size === 0) && (
          <ModalFileInfoNew
            fileInfo={file.select}
            open={file.isShowPopup}
            onClose={onUploadStorageFilePopupClose}
            onUpload={onUploadStorageFile}
          />
        )}
        {contractDetailPopup?.show && (
          <ModalDirectContract
            onClose={() =>
              setContractDetailPopup({
                show: false,
                did: "",
                contract_sub_id: "",
                company_info_type: "",
              })
            }
            did={contractDetailPopup.did}
            contract_sub_id={contractDetailPopup.contract_sub_id}
            company_info_type={contractDetailPopup.company_info_type}
          />
        )}
      </div>
      <ModalExplorer
        isShow={explorer.open}
        storage={storage}
        group={group}
        file={file}
        onClose={explorer.handleClose}
        onMove={onMoveStorageFolderAndFile}
      />
      <Dialog {...dialog} />
      <SnackbarMessage {...snackBar} />
    </>
  );
};

export default StorageManagement;
