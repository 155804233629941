import React, { useEffect, useState } from "react";
import client from "assets/js/commonApi";
import { styled } from "@mui/material/styles";
import {
  Button,
  ButtonGroup,
  Box,
  Typography,
  Container,
  InputBase,
  Paper,
} from "@mui/material";
// import { Button } from "@mui/material";
import SearchBarUser from "components/SearchBarUser";
import DataGridLayout from "components/grid/DataGridLayout";
import BasicTooltip from "components/tooltips/BasicTooltip";

// 부모에서 자식으로 props를 넘겨줄때, 키로 받는듯,
const DID = ({ onChange }) => {
  // const [agree, setAgree] = useState("a");
  const [rowsData, setRowsData] = useState([]);

  const [excelDownload, setExcelDownload] = useState(false);
  // const [registerStatus, setRegisterStatus] = useState("1"); // 등록,미등록 레디오 버튼

  // React.useEffect(() => {
  //   if (didSearchOptionsValue) {
  //     setRadioValue(didSearchOptionsValue);
  //   }
  // }, [didSearchOptionsValue]);
  const [searchNum, setSearchNum] = useState("");
  const [names, setNames] = useState();
  const [radioValue, setRadioValue] = useState("1");

  console.log("@@ radioValue", radioValue);

  const handleClickOpen = () => {
    console.log("excelDownload", handleClickOpen);
    alert("excelDownload");
    setExcelDownload(true);
  };
  // const GetRadio = (event) => {
  //   setAgree(event.target.value);
  // };

  const GetData = (event) => {
    // 여기서 등록, 미등록 값을 이용한다.
    // agree
    const headers = {
      "Content-type": "application/json",
      Accept: "application/json",
      // 헤더 토큰 준다 백에서
    };
    // client.defaults.headers.post = null;
    //for (let i = 0; i < 100; i++)
    client
      .post(
        "/mdpt_user/user_list",
        {
          // approval_yn: "",
          // reg_number: "",
          name: names, // 검색 기능 구현
          approval_yn: radioValue, // 승인 // 0 미승인
          reg_number: "",
          // did: "efcd9575",
          // contract_number: "c528f136",
          // did: { did },
          // contract_number: { contract_number },
        },
        headers,
      )
      .then((res) => {
        if (res.data && res.data.data && Array.isArray(res.data.data)) {
          const rows = res.data.data;
          console.log("## 사용자관리 데이터-확인2", rows);
          const newRows = rows?.map((row, index) => {
            for (let key in row) {
              const lowerKey = key.toLowerCase();
              if (lowerKey !== key) {
                row[lowerKey] = row[key];
                delete row[key];
              }
            }

            // const fromDtm = row.from_dtm;
            // const toDtm = row.to_dtm;
            // const
            return {
              ...row,
              id: index + 1,
              approval_date: row.approval_date.substring(0, 10),
              // dtm: `${fromDtm.substring(0, 10)} ~ ${toDtm.substring(0, 10)}`,

              // empty: "",
            };
          });
          setRowsData(newRows);
          console.log("## 사용자관리 데이터-확인3", res);
          console.log("## 사용자관리 데이터-확인1", rows);
        } else {
          // Handle unexpected response structure
          console.error("Unexpected response structure:", res);
          // You might want to set an appropriate state or show an error message
        }
      })
      .catch((error) => {
        // Handle request error
        console.error("Error fetching data:", error);
        // You might want to set an appropriate state or show an error message
      });
  };

  // 기본 조회(로딩 시 데이터 표시) S
  useEffect(() => {
    console.log("## useEffect: Calling GetData");
    GetData();
  }, [radioValue]); // 의존성 배열
  // 기본 조회(로딩 시 데이터 표시) E

  const BtnOptions = ({ radioValue }) => {
    if (radioValue === "1") {
      return (
        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled elevation buttons"
          size="small"
          // style={{ marginLeft: 16, }}
        >
          <Button style={btnSxA}>수정</Button>
          <Button
            variant="contained"
            // color=""
            style={btnSxB}
          >
            해지
          </Button>
        </ButtonGroup>
      );
    } else if (radioValue === "0") {
      return (
        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled elevation buttons"
          size="small"
          // style={{ marginLeft: 16, }}
        >
          <Button style={btnSxA}>수락</Button>
          <Button
            variant="contained"
            // color=""
            style={btnSxB}
          >
            거부
          </Button>
        </ButtonGroup>
      );
    }
  };

  // 검색 옵션(등록, 미등록) E
  // style S
  const datagridSx = {
    border: "0",
    "& .MuiDataGrid-root": {
      // borderLeft: 0,
      // border: "none",
      // borderWidth: "0 !important",
      // border: "red 1px solid !important",
      border: "0px !important",
      // border: "1px solid #000",
      // borderWidth: "0px !important",
    },
    // borderRadius: 2,
    "& .MuiDataGrid-main": {
      // borderRadius: "0",
      // border: "1px solid #000",
      // boderLeft: "0",
      // borderRight: "0",
      // border: "0px solid",
      // border: "0",
    },
    "& .MuiDataGrid-root--densityStandard": {
      // borderLeft: 0,
      // border: "none",
      // borderWidth: "0 !important",
      // border: "red 1px solid !important",
      // border: "0",
    },
    "& .MuiDataGrid-withBorderColor": {
      // --unstable_DataGrid-radius: '0'
      // borderLeft: 0,
      // border: "none",
      // borderWidth: "0 !important",
      // border: "red 1px solid !important",
      // borderTop: "red 1px solid !important",
      //
      //
      border: 0,
      // borderWidth: "0 !important",
      // borderLeft: 0,
      // borderRadius: "0 !important",
    },

    '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
      // color: "blue",
      // fontSize: 18,
      //risky
      // minHeight: "60px !important",
      // height: 60,
      "& div": {
        // minHeight: "60px !important",
        // height: 60,
        // lineHeight: "59px !important",
      },
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        // "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
        "&:nth-child(2n)": { backgroundColor: "rgba(238, 242, 245, 1)" },
      },
    },

    // 컬럼 6번째 친구한테 스타일 주는것 - 계약관리한테만 줘야 함 -만약에 계약 관리 페이지면 스타일 주기 삼항으로
    // 스타일 수정 전
    // "& .MuiDataGrid-cell": {
    //   pointerEvents: "none",
    //   // fontWeight: "500",
    // },
    // "& .MuiDataGrid-cell:nth-child(1), ": {
    //   pointerEvents: "auto",
    // },

    // pointerEvents S
    // "& .MuiDataGrid-cell:nth-child(1), .MuiDataGrid-cell:nth-child(2), .MuiDataGrid-cell:nth-child(3), .MuiDataGrid-cell:nth-child(4), .MuiDataGrid-cell:nth-child(5)":
    //   {
    //     pointerEvents: "none",
    //   },
    // pointerEvents E

    // "& .MuiDataGrid-cell:nth-child(6)": {
    //   // pointerEvents: "auto",
    //   // backgroundColor: "yellow",
    //   // border: "1px solid gray",
    //   "& :nth-child(2n)": {
    //     border: "1px solid red",
    //   },
    // },

    "& .MuiDataGrid-cell:nth-child(6)": {
      // pointerEvents: "auto",
      // backgroundColor: "yellow",
      // border: "1px solid gray",
      // border: "1px solid red",
    },

    // "& .MuiDataGrid-cell:nth-child(6):nth-child(2n)": {
    //   // pointerEvents: "auto",
    //   // backgroundColor: "yellow",
    //   // border: "1px solid gray",
    //   border: "1px solid red",
    // },

    // "& .MuiDataGrid-cell:nth-child(6)":{

    // },

    // pointer cursor on ALL rows
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "55px !important",
      maxHeight: "55px !important",
      lineHeight: "55px !important",
      // borderRadius: "0",
      borderTop: "#29314A 3px solid",
      borderBottom: "#29314A 3px solid",

      borderRadius: "0px !important",
      // borderLeft: "none !important",
      // borderLeft: "0 !important",
    },
    "& .MuiDataGrid-footerContainer": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "40px !important",
      maxHeight: "40px !important",
      lineHeight: "40px !important",
    },
    // disable cell selection style
    // "& .MuiDataGrid-cell:focus": {
    //   outline: "none",
    // },
    // // pointer cursor on ALL rows
    // "& .MuiDataGrid-row:hover:nth-child(6)": {
    //   cursor: "pointer !important",
    //   color: "red",
    // },

    // "& .RaDatagrid-clickableRow": { cursor: "default !important" },
    // ".MuiDataGrid-columnHeader--showColumnBorder": {
    //   border: "1px solid red",
    // },
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    // padding: theme.spacing(2),
    display: "flex",
    flex: 1,
    borderRadius: "0",
    color: theme.palette.text.primary,
  }));

  const InputBaseSx = {
    border: "0",
    width: "100%",
    // "& > :hover": {
    //   border: "1px solid #29304a ",
    // },
    "& .MuiDataGrid-root": {
      // borderLeft: 0,
      // border: "none",
      // borderWidth: "0 !important",
      // border: "red 1px solid !important",
      border: "0px !important",
      // border: "1px solid #000",
      // borderWidth: "0px !important",
    },
    "& .MuiFormControl-root": {
      // position: "relative",

      "& label": {
        // backgroundColor: "red",
      },
    },
    "& .MuiInput-root": {
      // backgroundColor: "red",
    },
    // " .MuiInput-root": {
    //   // position: "relative",
    //   // "& textarea": {
    //   //   backgroundColor: "#000",
    //   // },
    //   "& input": {
    //     backgroundColor: "red",
    //     fontSize: "12px",
    //   },
    // },
    '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
      // color: "blue",
      // fontSize: 18,
      //risky
      // minHeight: "60px !important",
      // height: 60,
      "& div": {
        // minHeight: "60px !important",
        // height: 60,
        // lineHeight: "59px !important",
        border: "0",
        borderRadius: "0",
      },
    },
    "& .MuiInputBase-root": {
      // width: "100%",
    },
    // "& .MuiInputBase-root :focus": {
    //   background: "#fff",
    //   marginLeft: 0,

    //   // zIndex:'0'
    // },
    "& .MuiInputBase-input": {
      width: "100%",
      background: "#f1f1f1",
      fontSize: "14px",
      // border: "0",
      padding: 1,
      // boxShadow: " 5px 5px 5px 5px gray inset",
      // boxShadow: "1px 1px 1px 1px #ccc inset",

      // "& > div:focus": {
      //   // border: "1px solid #29304a ",
      //   background: "red",
      // },
      // "& > div:hover": {
      //   border: "1px solid #29304a ",
      // },
    },
    "& .MuiInputBase-input:focus": {
      background: "#fff",
    },
    // ".MuiDataGrid-root" div[data-rowIndex][role="row"]:nth-of-type(5n-4) div
  };
  // useEffect(() => {/
  //   axios.get("/dummy/dummytest.json").then((res) => {
  //     // const data = response.data
  //     setPartner(res.data);
  //     console.log("아아아", res.data);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // console.log("###partner", partner);
  // let testArr = [];
  // for (let i = 0; i < partner.length; i++) {
  //   if (Number(id) !== partner[i].id) {
  //     testArr.push(partner[i]);
  //   }
  // }

  const btnSxA = {
    background: "#468ECD",
    color: "#fff",
    borderTop: "1px solid #468ECD",
    borderLeft: "1px solid #468ECD",
    borderBottom: "1px solid #468ECD",
    borderRight: "0",
    padding: "0 16px 0",
    // fontWeight: "600",
  };

  const btnSxB = {
    // background: "#f1f1f1",
    background: "#fff",
    color: "#29304a",
    borderTop: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    // borderTop: "1px solid #29304a",
    // borderRight: "1px solid #29304a",
    // borderBottom: "1px solid #29304a",
    borderLeft: "0",
    padding: "0 16px 0",
    // fontWeight: "600",
  };
  // style E

  // columns, rows S
  const columns = [
    {
      field: "id",
      headerName: "No.",
      // width: 15
      flex: 0.5,
    },
    {
      field: "did",
      // field: "contract_number",
      headerName: "DID",
      // field: "join_num",
      // headerName: "계약 번호",
      type: "number",
      // width: 150,
      flex: 2,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.did} />,

      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "name",
      // field: "name",
      // field: "buyer",
      headerName: "성명",
      // width: 100,
      // flex: 1,
      // flex: 0.5,
      flex: 0.6,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.name} />,
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "approval_yn",
      // field: "file_name",
      headerName: "등록여부",
      // width: 100,
      // flex: 1.25,
      // flex: 0.5,
      flex: 0.6,
      // editable: true,
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "approval_date",
      // field: "join_period",
      headerName: "승인일자",
      // type: "date",
      // width: 150,
      // flex: 1,
      // flex: 0.75,
      flex: 1,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.approval_date} />,
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },

    // 기존 비고 S
    // {
    //   field: "remark",
    //   headerName: "비고2",
    //   // width: 80,
    //   flex: 2.5,
    //   editable: true,
    //   // valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    // },

    // 기존 비고 E
    // {
    //   field: "btn_option",
    //   headerName: "Button",
    //   // width: 80,
    //   flex: 0.8,
    //   editable: true,
    //   // valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    // },
    {
      field: "remark",
      headerName: "비고",
      // width: 80,
      flex: 4,
      // editable: false,
      renderCell: (params) => {
        return (
          <StyledPaper>
            <InputBase
              sx={InputBaseSx}
              // sx={{
              //   width: "100%",
              //   background: "#f1f1f1",
              //   fontSize: "14px",
              // }}
              type="string"
              placeholder="비고 입력"
              inputProps={{ "aria-label": "비고 입력" }}
              size="small"
              // variant="standard"
              // value={recital}
              // onChange={(event) => {
              //   setRecital(event.target.value);
              //   console.log(event.target.value);
              // }}
            />
            {/* <Tooltip title={params.row.remark}>{params.row.remark}</Tooltip> */}
          </StyledPaper>
        );
      },
      // renderCell: (params) => {
      //   <strong>
      //     {params.value.getFullYear()}
      //     <Button
      //       variant="contained"
      //       color="primary"
      //       size="small"
      //       style={{ marginLeft: 16 }}
      //     ></Button>
      //   </strong>;
      // },
      // valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },

    {
      field: "btn_option",
      headerName: "Button",
      // width: 80,
      flex: 1.15,
      editable: false,
      renderCell: (cellValues) => {
        return <BtnOptions radioValue={radioValue} />;
      },
    },
  ];
  const rows = [
    {
      id: 1,
      join_num: 11234355,
      // seller: "Linkbuzz",
      buyer: "Bergnaum, Crona and Beier",
      file_name: "part.json",
      content_brief: "부품 구입 이력",
      join_period: "2022/09/21",
      // doc: "MRP",
      ongoing: "Corvette",
    },
    {
      id: 2,
      join_num: "",
      // seller: "Mudo",
      file_name: "program.json",
      content_brief: "프로그램 구입 이력",
      buyer: "Marquardt, Strosin and Waters",
      join_period: "2022/07/30",
      // doc: "SMO",
      ongoing: "Protege",
    },
    {
      id: 3,
      join_num: 3,
      file_name: "keyboard.json",
      // seller: "Yozio",
      content_brief: "PC 구입 이력",
      buyer: "Durgan Group",
      join_period: "2022/08/25",
      // doc: "JAX",
      ongoing: "HHR",
    },
    {
      id: 4,
      join_num: "dfed-235-dfds-dsfd",
      file_name: "",
      // seller: "Yozio",
      content_brief: "키보드 구입 이력",
      buyer: "Durgan Group",
      join_period: "2022/08/25",
      // doc: "JAX",
      ongoing: "HHR",
    },
    {
      id: 5,
      join_num: 5,
      seller: "Tagopia",
      buyer: "Connelly Inc",
      join_period: "2022/12/27",
      doc: "SRW",
      ongoing: "Cayenne",
    },
    {
      id: 6,
      join_num: 6,
      seller: "Quatz",
      buyer: "Botsford-Hilll",
      join_period: "2023/01/26",
      doc: "KMN",
      ongoing: "RDX",
    },
    {
      id: 7,
      join_num: 7,
      seller: "Flipopia",
      buyer: "Harber Inc",
      join_period: "2023/06/16",
      doc: "BLL",
      ongoing: "Suburban 1500",
    },
    {
      id: 8,
      join_num: 8,
      seller: "Photojam",
      buyer: "Bechtelar, Breitenberg and McLaughlin",
      join_period: "2022/08/18",
      doc: "MVU",
      ongoing: "Maxima",
    },
    {
      id: 9,
      join_num: 9,
      seller: "Skiptube",
      buyer: "Reichel, Fahey and Abernathy",
      join_period: "2022/10/29",
      doc: "TVL",
      ongoing: "Tacoma",
    },
    {
      id: 10,
      join_num: 10,
      seller: "Mybuzz",
      buyer: "Osinski, Johnson and Bauch",
      join_period: "2023/04/09",
      doc: "VNX",
      ongoing: "Grand Marquis",
    },
    {
      id: 11,
      join_num: 11,
      seller: "Skinix",
      buyer: "Hauck, Conroy and Kuphal",
      join_period: "2023/05/29",
      doc: "INC",
      ongoing: "MKX",
    },
    {
      id: 12,
      join_num: 12,
      seller: "Plajo",
      buyer: "Champlin-Ledner",
      join_period: "2023/06/26",
      doc: "OSU",
      ongoing: "LeSabre",
    },
    {
      id: 13,
      join_num: 13,
      seller: "Yodoo",
      buyer: "Wisozk-Rogahn",
      join_period: "2022/09/17",
      doc: "SLM",
      ongoing: "Land Cruiser",
    },
    {
      id: 14,
      join_num: 14,
      seller: "Realcube",
      buyer: "Considine-Tremblay",
      join_period: "2022/12/24",
      doc: "BTR",
      ongoing: "Versa",
    },
    {
      id: 15,
      join_num: 15,
      seller: "Demizz",
      buyer: "Harvey Group",
      join_period: "2023/04/26",
      doc: "WGE",
      ongoing: "Civic",
    },
    {
      id: 16,
      join_num: 16,
      seller: "Topdrive",
      buyer: "Schiller-Skiles",
      join_period: "2022/07/20",
      doc: "PNQ",
      ongoing: "Grand Prix",
    },
    {
      id: 17,
      join_num: 17,
      seller: "Browseblab",
      buyer: "Monahan, Cartwright and Konopelski",
      join_period: "2022/09/18",
      doc: "AJK",
      ongoing: "Esprit",
    },
    {
      id: 18,
      join_num: 18,
      seller: "Roodel",
      buyer: "Jerde Group",
      join_period: "2023/04/21",
      doc: "COR",
      ongoing: "Econoline E250",
    },
    {
      id: 19,
      join_num: 19,
      seller: "Chatterbridge",
      buyer: "Nitzsche, Mueller and O'Reilly",
      join_period: "2022/11/22",
      doc: "BTS",
      ongoing: "Jimmy",
    },
  ];
  // columns, rows E
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
      return;
    }
    console.log("setSearchNum", setSearchNum);
    setSearchNum(event.target.value);
    setNames(event.target.value);
    setRadioValue(event.target.value);
  };
  function searchButton(e) {
    GetData();
    console.log("###검색버튼", searchButton);
    // call your method here
    e.preventDefault();
    alert("###searchButton", searchButton);
    setSearchNum(e.target.value);
  }
  // 등록 해지에 대한 유즈 스테이트 기본값 등록 f

  function handleKeyPress(e) {
    console.log("###handleKeyPress", handleKeyPress);
    if (e.code === "Enter") {
      // call your method here
      e.preventDefault();
      alert("###handleKeyPress", handleKeyPress);
      searchButton(e);
    }
    setSearchNum(e.target.value);
  }

  console.log("@@ handleChange", handleChange);

  // 검색 옵션(등록, 미등록) S

  const DidRadioChange = (e) => {
    if (onChange) {
      onChange(e);
      // searchButton(e);

      //   return;
    }
    console.log("## DidRadioChange", e.target.value);
    // setSearchNum(e.target.value);
    setRadioValue(e.target.value);
  };
  console.log("@@ onChange", onChange);
  return (
    <>
      <Box sx={{ pb: 6 }}>
        <Container
          sx={{
            // backgroundColor: "green",
            // width: "100%",
            pt: 8,
            pb: 6,
          }}
          maxWidth="lg"
        >
          <Typography component="h2" variant="h3" sx={{ marginBottom: "32px" }}>
            사용자관리
            {/* DID관리 */}
          </Typography>
          {/* <Box sx={{ display: "flex" }}> */}
          <SearchBarUser
            onClick={GetData}
            // onChange={GetRadio}
            onChange={DidRadioChange}
            onSearchNames={setNames}
            // didSearchOptionsValue={registerStatus}
            value={radioValue}
            searchNumValue={searchNum}
            searchButton={searchButton}
            handleChange={handleChange}
            handleKeyPress={handleKeyPress}
            // sx={{ flexGrow: "1" }}
          />
          {/* <DidRadio onChange={searchOnChange} /> */}

          {/* </Box> */}

          <Box
            sx={
              {
                // height: 400,
                // width: "100%",
                // pl: "5rem",
                // pr: "5rem",
                // pt: "1rem",
                // pb: "1rem",
              }
            }
          >
            <DataGridLayout
              // rows={rows}
              rows={rowsData}
              columns={columns}
              onCellClick={handleClickOpen}
              // checkboxSelection
              sx={datagridSx}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default DID;
