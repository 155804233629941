import React, { useState, useContext, useEffect } from "react";
import "./ContractProgress.css";
import { MessageContext } from "assets/js/syntax";
import { useNavigate } from "react-router-dom";
import { usePlatformSalesQuery } from "assets/hooks/useMypage/contractProgress/usePlatformSalesQuery";
import { usePlatformSalesStepQuery } from "assets/hooks/useMypage/data/usePlatformSalesStepQuery";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Typography, Button, Stack, Badge } from "@mui/material";
import {
  SaveAlt,
  KeyboardArrowRight,
  Task,
  DriveFileRenameOutlineOutlined,
  InsertChartOutlinedOutlined,
  EventRepeatOutlined,
  HourglassEmpty,
  RateReview,
  Assignment,
} from "@mui/icons-material";

import StepItemList from "components/viewContents/contractProgressView/StepItemList";

// 탭별 공통 스타일
const cardStyle = {
  display: "flex",
  flexDirection: "row",
};

const contentStyle = {
  backgroundColor: "#f9f9f9", // 배경색
  border: "1px solid #e0e0e0", // 테두리
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 그림자
  borderRadius: "8px", // 모서리 둥글게
  marginBottom: "16px", // 마진
  //
  padding: "20px", // 컨텐츠 패딩
  ml: "20px",
};

const StackSX = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  // justifyContent: "center", // 전체 스택을 중앙 정렬
};
const IconSx = {
  fontSize: 40,
  color: "#0000008a",
};
const BadgeSx = {
  "& .MuiBadge-badge": {
    backgroundColor: "#468ECD",
    color: "#fff",
    fontWeight: "600",
    fontSize: "1.125rem",
    p: 2,
    pt: 1.5, // 12px
    pl: 1,
    pr: 1,
    borderRadius: "15px",
  },
};
const ArrowSx = {
  fontSize: "3rem",
  color: "rgba(0, 0, 0, 0.12)",
  // color: "#ccc",
};

const StepTextSx = {
  fontSize: "0.875rem",
};

const PlatformSalesTab = ({ isActive }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { triggerRefresh } = useContext(MessageContext); // 전역 계약창
  const [currentStep, setCurrentStep] = useState(null);
  const { data: platformSalesData, refetch } = usePlatformSalesQuery("CST02", {
    enabled: isActive,
  });

  useEffect(() => {
    if (isActive) {
      refetch(); // isActive가 true일 때 데이터 새로고침
    }
  }, [isActive, refetch]);

  const {
    data: platformSalesStepData,
    isLoading: isDataLoading,
    refetch: refetchPlatformSalesStepData,
  } = usePlatformSalesStepQuery(currentStep, {}, currentStep !== null);

  // useEffect(() => {
  //   if (currentStep) {
  //     refetchPlatformSalesStepData();
  //   }
  // }, [currentStep, refetchPlatformSalesStepData]);
  // 코드, 라벨 및 아이콘 매핑
  const steps = {
    200: {
      label: "임시 저장",
      icon: <SaveAlt sx={IconSx} />,
      relatedCodes: ["200"],
    },
    201: {
      label: "등록 완료",
      icon: <Task sx={IconSx} />,
      relatedCodes: ["201", "202"],
    },
    203: {
      label: "판매자 서명",
      icon: <DriveFileRenameOutlineOutlined sx={IconSx} />,
      relatedCodes: ["203"],
    },
    204: {
      label: "플랫폼 서명",
      icon: <DriveFileRenameOutlineOutlined sx={IconSx} />,
      relatedCodes: ["204"],
    },
    205: {
      label: "데이터 등록 중",
      icon: <HourglassEmpty sx={IconSx} />,
      relatedCodes: ["205"],
    },
    206: {
      label: "데이터 등록 완료",
      icon: <InsertChartOutlinedOutlined sx={IconSx} />,
      relatedCodes: ["206"],
    },
    207: {
      label: "검토 중",
      icon: <RateReview sx={IconSx} />,
      relatedCodes: ["207"],
    },
    208: {
      label: "게시 완료",
      icon: <Assignment sx={IconSx} />,
      relatedCodes: ["208"],
    },
    210: {
      label: "계약 종료 1개월 이내",
      icon: <EventRepeatOutlined sx={IconSx} />,
      relatedCodes: ["209", "210"],
    },
  };

  const findStepCount = (code) => {
    const step = steps[code];
    if (!step) return 0;

    return platformSalesData
      ?.filter((d) => step.relatedCodes.includes(d.code_value))
      .reduce((total, item) => total + item.step_count, 0);
  };

  // const handleStepClick = (stepId) => {
  //   console.log(`Box clicked: ${stepId}`);
  //   setCurrentStep(stepId);
  // };
  const handleStepClick = (stepId) => {
    console.log(`##Box clicked: ${stepId}`);
    setCurrentStep(stepId);
    queryClient.invalidateQueries(["platform_sales_step_data"]);
  };

  console.log("##@@platformSalesData", platformSalesData && platformSalesData);

  console.log(
    "##@@platformSalesStepData",
    platformSalesStepData && platformSalesStepData,
  );

  const handleGoToSales = () => {
    navigate("/mypage/sales");
  };
  const Step = ({ code, label, icon, count, onClick }) => (
    <Box
      onClick={() => handleStepClick(code)}
      className="cp_StepBoxSx"
      // sx={{
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   cursor: "pointer",
      //   position: "relative",
      // }}
    >
      <Badge badgeContent={count} max={99} sx={BadgeSx}>
        {icon}
      </Badge>
      <Typography
        // sx={StepTextSx}
        className="cp_StepTextSx"
      >
        {label}
      </Typography>
    </Box>
  );
  return (
    <>
      <Stack spacing={4}>
        <Stack gap={1.5} sx={StackSX}>
          {Object.entries(steps).map(
            ([code, { label, icon }], index, array) => (
              // code < "400" ? (
              <React.Fragment key={code}>
                <Step
                  code={code}
                  label={label}
                  icon={icon}
                  count={findStepCount(code)}
                  onClick={handleStepClick}
                />

                {code < 210 && <KeyboardArrowRight sx={ArrowSx} />}
              </React.Fragment>
            ),
            // ) : null,
          )}
        </Stack>
      </Stack>
      <Typography
        variant="body1"
        sx={{ mt: 10, mb: 0.1, fontSize: "0.875rem" }}
      >
        데이터 등록완료 1개월 이후 데이터는 판매내역에서 확인 바랍니다.
        <Button
          onClick={handleGoToSales}
          // variant="contained"
          variant="text"
          color="primary"
          sx={{
            ml: 0.25,
            textDecoration: "underline",
            textUnderlineOffset: "3px",
            marginTop: "-4px",
          }}
        >
          판매내역 바로가기
        </Button>
      </Typography>
      <StepItemList
        stapData={platformSalesStepData}
        contract_type="002"
        company_info_type="seller"
        isDataLoading={isDataLoading}
      />
    </>
  );
};

export default PlatformSalesTab;
