import React from "react";
import useUser from "views/temp/userManagement/useUser";

import { Box, Container } from "@mui/material";
import DataGridLayout from "components/grid/UserDataGridLayout";
import SearchBarUser from "views/temp/userManagement/SearchBarUser";
import BasicTooltip from "components/tooltips/BasicTooltip";
import Dialog from "components/modal/Dialog";
import { SnackbarMessage } from "components/modal";

const UserManagement = () => {
  const columns = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.1,
    },
    {
      field: "user_name",
      headerName: "이름",
      flex: 0.65,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.user_name} />,
    },
    {
      field: "did",
      headerName: "DID",
      // type: "String",
      // width: 150,
      flex: 1.5,
      // editable: true,
      // disableColumnFilter: "true",
      renderCell: (params) => (
        <BasicTooltip title={params.row.did} />
      ),
      valueGetter: ({ value }) => value || "-", // 데이타 값이 없을 때
    },
    {
      field: "email",
      headerName: "이메일",
      // width: 100,
      // flex: 0.75,
      flex: 1.25,
      // flex: 0.55,
      // editable: true,
      renderCell: (params) => <BasicTooltip title={params.row.email} />,
    },
    {
      field: "approval_yn",
      headerName: "승인여부",
      // width: 80,
      flex: 0.65,
    },
    {
      field: "approval_button",
      headerName: "승인",
      // width: 80,
      flex: 0.65,
    },
    {
      field: "disapproval_button",
      headerName: "철회",
      // width: 80,
      flex: 0.65,
    },
  ];
  const datagridSx = {
    border: "0",
    "& .MuiDataGrid-root": {
      border: "0px !important",
    },
    "& .MuiDataGrid-main": {},
    "& .MuiDataGrid-root--densityStandard": {},
    "& .MuiDataGrid-withBorderColor": {
      border: 0,
    },
    '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
      // color: "blue",

      "& div": {
        // minHeight: "60px !important",
        // height: 60,
        // lineHeight: "59px !important",
      },
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        // "&:nth-of-type(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
        "&:nth-of-type(2n)": { backgroundColor: "rgba(238, 242, 245, 1)" },
      },
    },
    "& .MuiDataGrid-cell:hover:nth-of-type(n+6)": {
      cursor: "pointer !important",
      color: "red",
    },
    "& .MuiDataGrid-cell:nth-of-type(n+6)": {
      "& .MuiDataGrid-cellContent": {
        // cursor: "pointer !important",
        // color: "red",
        // border-bottom: 1px solid #000,
        borderBottom: "1px solid #000",
      },
    },

    // pointer cursor on ALL rows
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "55px !important",
      maxHeight: "55px !important",
      lineHeight: "55px !important",
      borderTop: "#29314A 3px solid",
      borderBottom: "#29314A 3px solid",

      borderRadius: "0px !important",
    },
    "& .MuiDataGrid-footerContainer": {
      // backgroundColor: "rgba(0,0,255,0.6)",
      // color: "rgba(255,0,0,0.7)",
      fontSize: 14,
      minHeight: "40px !important",
      maxHeight: "40px !important",
      lineHeight: "40px !important",
    },
  };
  const { list, dialog, snackBar, search, onCellClick } = useUser();

  return (
    <>
      <Box>
        <Container sx={{ p: "0 !important" }}>
          <SearchBarUser
            handleTypeChange={search.onSearchTypeChange}
            handleChange={search.onSearchChange}
            handleKeyPress={search.onSearchKeyPress}
            searchType={search.type}
            searchButton={search.onSearchClick}
          />
          <Box>
            <DataGridLayout
              rows={list}
              columns={columns}
              onCellClick={onCellClick}
              sx={datagridSx}
            />
          </Box>
        </Container>
      </Box>
      <Dialog {...dialog} />
      <SnackbarMessage {...snackBar} />
    </>
  );
}

export default UserManagement;
