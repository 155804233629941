import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

const theme = createTheme({
  components: {
    // MuiButton 컴포넌트에 대한 스타일 오버라이드
    MuiButton: {
      styleOverrides: {
        // startIcon에 대한 스타일
        startIcon: {
          // '>*:nth-of-type(1)'는 모든 직접 자식 요소에 적용됩니다.
          "& > *:nth-of-type(1)": {
            fontSize: "27px", // 아이콘 크기를 27px로 설정
          },
        },
      },
    },
  },
});

const FixedButton = (mode) => {
  // 버튼 컴포넌트에 스타일링 적용
  const StyledButton = styled(Button)({
    fontSize: 18,
    color: "#fff", // 텍스트 색상은 흰색으로 가정
    backgroundColor: "#468ECD", // 아이콘 색상 코드
    "&:hover": {
      backgroundColor: "#468ECD",
      // #0079bb
      // #005187
      // #29314A
      // 호버 상태의 배경색 (어두운 톤으로 조정)
    },
    // boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)", // 엠보스 효과
    borderRadius: "4px", // 버튼 모서리 둥글게
    padding: "6px 16px", // 내부 여백
    fontWeight: 700, // 글씨 굵기
    // 나머지 필요한 스타일
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "fixed", // 뷰포트에 상대적으로 고정 위치
          // bottom: 24, // 하단에서 24px 위에 위치
          // right: 24, // 오른쪽에서 24px 떨어진 위치
          top: "50%", // 하단에서 24px 위에 위치
          right: "1.65%", // 오른쪽에서 24px 떨어진 위치
          zIndex: 1000, // 대부분의 다른 요소들 위에 위치하도록
        }}
      >
        <StyledButton
          variant="contained"
          color="primary"
          startIcon={
            <NoteAltOutlinedIcon
            // sx={{
            //   "& > *:nth-of-type(1)": {
            //     fontSize: "27px", // 아이콘 크기를 27px로 설정
            //   },
            // }}
            // size="large"
            // size="lg"
            // sx={{ "--IconButton-size": "47px" }}
            // size="50"
            />
          }
        >
          계약 생성
        </StyledButton>
      </Box>
    </ThemeProvider>
  );
};

export default FixedButton;
