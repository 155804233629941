import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Container,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  IconButton,
  Badge,
  Grid,
  Paper,
  Link,
  AppBar,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";

import {
  // ManageAccountsIcon,
  AssignmentTurnedInOutlinedIcon,
  HistoryIcon,
  ShoppingBasketOutlinedIcon,
  SummarizeOutlinedIcon,
  AccountBalanceWalletOutlinedIcon,
  CollectionsBookmarkOutlinedIcon,
  ShoppingCartOutlinedIcon,
  FavoriteBorderIcon,
  PeopleOutlineIcon,
  GroupWorkOutlinedIcon,
  StorageOutlinedIcon,
  MenuIcon,
} from "components/icons/IconMui";

import { ManageAccounts, Person } from "@mui/icons-material";

// view - mypages
import ContractProgress from "views/mypages/ContractProgress";
import SalesList from "views/mypages/SalesList";
import BuyList from "views/mypages/BuyList";
import ConsignmentList from "views/mypages/ConsignmentList";
import FTManagement from "views/mypages/FTManagement";
// import FTManagement from "views/mypages/FTManagement";
import NFTManagement from "views/mypages/NFTManagement";
import CartLikes from "views/mypages/CartLikes";
import UserManagement from "views/mypages/UserManagement";
import GroupManagement from "views/mypages/GroupManagement";
import StorageManagement from "views/mypages/StorageManagement";

const drawerWidth = 240;

const StackSX = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const menuItems = [
  {
    text: "내 계약 진행",
    route: "/mypage/progress",
    Icon: AssignmentTurnedInOutlinedIcon,
    component: ContractProgress,
  },
  {
    text: "판매내역",
    route: "/mypage/sales",
    Icon: HistoryIcon,
    component: SalesList,
  },
  {
    text: "구매내역",
    route: "/mypage/buy",
    Icon: ShoppingBasketOutlinedIcon,
    component: BuyList,
  },
  {
    text: "위탁판매내역",
    route: "/mypage/consignments",
    Icon: SummarizeOutlinedIcon,
    component: ConsignmentList,
  },
  {
    text: "FT관리",
    route: "/mypage/ft",
    Icon: AccountBalanceWalletOutlinedIcon,
    component: FTManagement,
  },
  {
    text: "NFT관리",
    route: "/mypage/nft",
    Icon: CollectionsBookmarkOutlinedIcon,
    component: NFTManagement,
  },
  {
    text: "장바구니/찜",
    route: "/mypage/cart-likes",
    Icon: ShoppingCartOutlinedIcon,
    AltIcon: FavoriteBorderIcon,
    component: CartLikes,
  },
  {
    text: "사용자관리",
    route: "/mypage/users",
    Icon: PeopleOutlineIcon,
    component: UserManagement,
  },
  {
    text: "그룹관리",
    route: "/mypage/groups",
    Icon: GroupWorkOutlinedIcon,
    component: GroupManagement,
  },
  {
    text: "스토리지관리",
    route: "/mypage/storage",
    Icon: StorageOutlinedIcon,
    component: StorageManagement,
  },
];

const MypageMainNav = ({
  window,
  mobileOpen,
  handleDrawerClose,
  setSelectedMenuItem,
  listItemStyle,
}) => {
  const user_type = sessionStorage.getItem("user_type");
  const userName = sessionStorage.getItem("name");
  console.log("##user_type", user_type);
  const userType = user_type === "master" ? "관리자" : "사용자";
  console.log("##userType", userType);
  const UserTypeIcon = user_type === "master" ? ManageAccounts : Person;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.route),
    );
    if (activeItem) {
      setSelectedMenuItem(activeItem.text);
    }
  }, [location]);

  const handleMenuItemClick = (item) => {
    // setSelectedMenuItem(item.text); ㅂㄱ
    // setSelectedMenuItem(item.text); ㅂㄱ
    navigate(item.route); // 메뉴 항목에 따라 URL 변경
    handleDrawerClose(); // 모바일에서 메뉴 선택 후 드로어 닫기
  };

  // const { window } = props;
  //   const [mobileOpen, setMobileOpen] = useState(false);
  //   const [isClosing, setIsClosing] = useState(false);
  //   const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].text);

  //   const handleDrawerToggle = () => {
  //     if (!isClosing) {
  //       setMobileOpen(!mobileOpen);
  //     }
  //   };

  //   const handleDrawerClose = () => {
  //     setIsClosing(true);
  //     setMobileOpen(false);
  //   };

  //   const handleDrawerTransitionEnd = () => {
  //     setIsClosing(false);
  //   };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  //   const listItemStyle = (itemText) => ({
  //     pl: 0,
  //     backgroundColor: selectedMenuItem === itemText ? "#0000000a" : "inherit", // 활성화 시 배경색 변경
  //     // 추가 스타일은 필요에 따라 여기에 작성
  //     "&:hover": {
  //       backgroundColor: "#0000000a", // 호버 시 배경색 변경
  //     },
  //   });

  const MenuList = () => {
    return (
      <Box>
        <Stack gap={0.5} sx={{ pt: 1.5, pb: 1.5 }}>
          <Typography sx={{ fontWeight: "600" }}>{userName}</Typography>
          <Stack gap={1} sx={StackSX}>
            {/* 관리자 or 사용자 */}
            <Typography>{userType}</Typography>
            {/* <ManageAccounts fontSize="small" /> */}
            <UserTypeIcon fontSize="small" />
          </Stack>
        </Stack>
        {/* <Divider /> 경계 선*/}
        <List>
          {menuItems.map(
            (item, index) =>
              // 베이직이 아니어야만 사용자관리 메뉴 표시
              (item.text !== "사용자관리" || user_type !== "basic") && (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    sx={listItemStyle(item.text)}
                    onClick={() => handleMenuItemClick(item)}
                  >
                    <ListItemIcon>
                      <item.Icon />
                      {item.AltIcon && <item.AltIcon />}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </List>
      </Box>
    );
  };

  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        //   width: drawerWidth,
        flexShrink: { sm: 0 },
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        // open={open}
        // onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            height: "100%",
          },
        }}
      >
        <MenuList />
      </Drawer>
      {/* 모바일 */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            position: "relative",
            // borderColor: "pink",
            // borderLeft: "1px solid #000",
            borderRight: "unset",
          },
        }}
        open
      >
        <MenuList />
      </Drawer>
      {/* PC */}
    </Box>
  );
};
MypageMainNav.propTypes = {
  window: PropTypes.any,
};
export default MypageMainNav;
