import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

// const VisuallyHiddenInput = styled('input')({
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: 1,
//   overflow: 'hidden',
//   position: 'absolute',
//   bottom: 0,
//   left: 0,
//   whiteSpace: 'nowrap',
//   width: 1,
// });

const SearchBtn = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid ",
  lineHeight: 1.5,
  backgroundColor: "#ebebebb3",
  borderColor: "#b7b6b6b3",

  //   background: #004071;
  //   background: #29304a;
  //   background: #027bbe;

  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  color: "#999",
  "&:hover": {
    backgroundColor: "rgb(235,235,235,0.9)",
    // borderColor: "#29304a",
    boxShadow: "none",
    // background: "rgb(229, 234, 242)",
    // borderColor: "rgb(199, 208, 221)",
    // color: "#fff",// 담당저 선태 컬러 변경되네 이것도
  },
  //   "&:active": {
  //     boxShadow: "none",
  //     backgroundColor: "#29304a",
  //     borderColor: "#005cbf",
  //   },
  //   "&:focus": {
  //     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  //   },
});

const IconSx = {
  //   color: "#004071",
  //   color: "inherit",
  color: "#000000de",

  // hover 하려면 기본값 root에 넣어야 함
  root: {
    // color: "#000000de",
    // "&:hover": {
    //   color: "#fff",
    // },
  },
  //   "&root": {
  //     "&:hover": {
  //       color: "#fff",
  //     },
  //   },
  //   "&:active": {
  //     color: "#fff",
  //   },
  //   "&:focus": {
  //     color: "#fff",
  //   },
};

export default function IconTxtBtn({ onClick }) {
  return (
    <SearchBtn
      onClick={onClick}
      component="label"
      variant="contained"
      startIcon={<SearchIcon fontSize="large" sx={IconSx} />}
    >
      담당자 선택
    </SearchBtn>
  );
}
