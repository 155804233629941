// import * as React from "react";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
  Button,
  Stack,
  Box,
  Grid,
  Typography,
  Container,
  Toolbar,
  InputBase,
} from "@mui/material";
// import DidRadio from "./radio/DidRadio";
// import SelectStepType from "components/selectBox/SelectStepType";
import SearchType from "components/selectBox/SearchType";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  // "&:hover": {
  //   backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  // marginRight: theme.spacing(2),// 16px
  marginRight: theme.spacing(1), // 16px
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "1.125rem",
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(0.5)})`,

    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const ToolbarSx = styled(Toolbar)(({ theme }) => ({
  minHeight: "unset !important",
  // minHeight: "48px",
  // width: "100%", 230913 주석처리
  marginBottom: 8,
  marginLeft: 0,
  // marginRight: "32px",
  padding: "0px !important",
  "& .MuiInputBase-root :focus": {
    background: "#fff",
    marginLeft: 0,

    // zIndex:'0'
  },
  boxShadow: "none",
  "& > div": {
    background: "#ebebebb3",
    // marginRight: "0",
    marginLeft: "0",
    border: "1px solid #b7b6b6b3",

    borderRadius: "4px",
  },
  "& > div:focus": {},
  "& > div:hover": {
    border: "1px solid #29304a ",
  },

  "& .MuiInputBase-input": {},
  "& .MuiInputBase-input:focus": {
    border: "1px solid #29304a ",
  },
}));

export default function SearchBarPaymentRequest({ onSearch }) {
  const [userId, setUserId] = useState(""); // 회사명
  const [userName, setUserName] = useState(""); // 담당자(매니저)

  // 담당자 상태 관리 (기존 useState Name 참고하기)

  // const [newContractBtn, setNewContractBtn] = useState();
  // const [terminateBtn, setTerminateBtn] = useState();

  // console.log("searchNum", searchNum);
  console.log("## userId", userId);
  console.log("## userName", userName);

  // 회사명
  // onChange - 현재 페이지에서 타겟팅 해주고
  const handleUserIdChange = (e) => {
    console.log("## userId", userId);
    const value = e.target.value;
    setUserId(value);
    // onSearchCoChanged(value); // props로 부모 modald에게 전달
  };

  // 담당자명(매니저)
  // onChange - 현재 페이지에서 타겟팅 해주고
  const handleUserNameChange = (e) => {
    console.log("## userName", userName);
    const value = e.target.value;
    setUserName(value);
    // onSearchMgrChanged(value); // props로 부모 modald에게 전달
  };

  // Refresh 버튼에 대한 이벤트 핸들러 추가
  const handleReset = () => {
    // 입력한 내용만 삭제 되게
    setUserId("");
    setUserName("");

    // 초기화 시 전체 목록을 다시 불러옵니다.
    // onSearch("", "");
  };
  // ** 이상없으면 삭제하기 S 230227
  // 버튼 - 클릭 시 onClick 이벤트 (검색 기능)
  function searchButton(e) {
    e.preventDefault();
    // if (onClick) {
    onSearch(userId, userName);
    // } else {
    //   console.log("검색 조건:", userId, userName);
    // }
    console.log("검색 조건 :", userId, userName);
    // console.log("###검색버튼", searchButton);
    // call your method here

    // onSearchCoChanged(userId);
    // onSearchMgrChanged(userName);
  }
  // ** 이상없으면 삭제하기 E 230227

  function handleKeyPress(e) {
    // console.log("###handleKeyPress", handleKeyPress);
    if (e.code === "Enter") {
      // call your method here
      e.preventDefault();
      // alert("###handleKeyPress", handleKeyPress);
      console.log("Enter key pressed");
      onSearch(userId, userName); // 예시: 검색 실행
    }
  }

  // setTerminateBtn
  const SearchBtn = styled(Button)({
    position: "absolute",
    right: "0",
    top: "0",
    height: "100%",
    boxShadow: "none",
    textTransform: "none",
    // fontSize: 16,
    // padding: "6px 12px",
    padding: 0,
    // border: "1px solid ",
    // lineHeight: 1.5,
    // backgroundColor: "green",
    // borderColor: "#b7b6b6b3",
    cursor: "pointer",
    color: "#999",
    zIndex: "1",
    "&:hover": {
      backgroundColor: "rgb(235,235,235,0.9)",
      // cursor: "pointer",
      boxShadow: "none",
    },
    // "& .MuiButton-startIcon": {
    //   fontSize: 50,
    // },
  });

  return (
    <Box sx={{ width: 1, mb: 2 }}>
      {/* <Box sx={{ width: 1, background: "red" }}> */}
      {/* <ToolbarSx>
        <Search sx={{ flexGrow: 1 }}>
          <SearchIconWrapper>
            <BusinessIcon
              onClick={searchButton}
              sx={{
                // color: "#468ECD",
                // color: "#00000099",
                // color: "#0000008a",
                color: "#00000063",
                // color: "#c8c8c8",
              }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            // type="Number"
            type="search"
            id="search"
            placeholder="ID 입력"
            inputProps={{ "aria-label": "검색하기" }}
            // value={searchNum}
            value={userId} //회사명
            onChange={handleUserIdChange}
            onKeyDown={handleKeyPress}
          />
        </Search>
        <Button
          onClick={searchButton}
          variant="contained"
          sx={{
            // height: "41px",
            height: "43.88px",
            boxShadow: "none",
            // backgroundColor: "#29304a",
            backgroundColor: "#468ECD",
            // borderRadius: "2px"
          }}
          startIcon={<SearchIcon sx={{ color: "#fff" }} />}
        >
          검색
        </Button>
      </ToolbarSx> */}
      <ToolbarSx>
        <Search sx={{ flexGrow: 1 }}>
          <SearchIconWrapper>
            <PersonIcon
              // onClick={searchButton}
              sx={{
                color: "#00000063",
              }}
            />
          </SearchIconWrapper>

          {/* </SearchIconWrapper> */}
          <StyledInputBase
            // type="Number"
            type="search"
            id="search"
            placeholder="담당자명 입력"
            inputProps={{ "aria-label": "검색하기" }}
            // value={searchNum}
            value={userName} // 담당자(매니저)
            onChange={handleUserNameChange}
            onKeyDown={handleKeyPress}
          />
        </Search>
        <Button
          onClick={searchButton}
          variant="contained"
          sx={{
            // height: "41px",
            height: "43.88px",
            boxShadow: "none",
            // backgroundColor: "#29304a",
            backgroundColor: "#468ECD",
            // borderRadius: "2px"
          }}
          startIcon={<SearchIcon sx={{ color: "#fff" }} />}
        >
          검색
        </Button>
        <Button
          onClick={handleReset}
          variant="contained"
          sx={{
            // height: "41px",
            height: "43.88px",
            boxShadow: "none",
            // padding: "0",
            padding: "6px 9.57px",
            // borderRadius: "2px"
            color: "#000000de",
            backgroundColor: "#fff",
          }}
          startIcon={<RefreshRoundedIcon sx={{ color: "#000000de" }} />}
        >
          초기화
        </Button>
      </ToolbarSx>
    </Box>
  );
}
