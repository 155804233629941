import client from "assets/js/commonApi";
import React, { useEffect, useState } from "react";
import PaperComponent from "components/paper/PaperComponent";
import {
  Box,
  Typography,
  Button,
  ListItemText,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FolderItem from "views/temp/storageManagement/FolderItemUnMenu";
import FileItem from "views/temp/storageManagement/FileItemUnMenu";

const ModalExplorer = ({
  isShow,
  group,
  storage,
  file,
  onMove,
  onClose,
}) => {
  const [folderId, setFolderId] = useState("root");
  const [queue, setQueue] = useState([]);
  const [directories, setDirectories] = useState();
  const title =
    '"' +
    (file.select.file_name
      ? `${file.select.file_name}.${file.select.file_ext}`
      : storage?.select?.folder?.folder_name ?? "root") +
    '" 이동하기';

  useEffect(() => {
    if (!isShow) {
      // 초기화
      setFolderId("root");
      setQueue([]);
      setDirectories();
    }
  }, [isShow]);

  useEffect(() => {
    if (folderId && group.select.storage_sub_id) {
      if (folderId === "root") {
        // root인 경우
        client
          .post("/storage/root", {
            storage_sub_id: group.select.storage_sub_id,
          })
          .then((res) => {
            const data = res?.data.data;
            const storage_sub_id = group.storage_sub_id;
            const folder_info = data.folder_info ?? [];
            const file_info = data.file_info ?? [];

            setDirectories({ storage_sub_id, folder_info, file_info });
          });
      } else {
        client
          .post("/folder/detail", {
            folder_id: folderId,
          })
          .then((res) => {
            const data = res?.data.data;
            const storage_sub_id = group.select.storage_sub_id;
            const folder_info = data.folder_info ?? [];
            const file_info = data.file_info ?? [];

            setDirectories({ storage_sub_id, folder_info, file_info });
          });
      }
    }
  }, [client, folderId, group]);

  return (
    <Dialog
      open={isShow}
      PaperComponent={PaperComponent}
      onClose={onClose}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
    >
      <DialogTitle
        component="h1"
        variant="h6"
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">{`${
          group.select.group_name
        } > root > ${queue
          .map((folder) => folder.folder_name)
          .join(" > ")}`}</Typography>
      </DialogContent>
      <DialogContent>
      <Box sx={{ height: "400px" }}>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>폴더</Typography>
          </ListItemText>
          <Grid container>
            {directories?.folder_info.map((folder) => (
              <Grid item xs={12} md={6} key={folder.folder_id}>
                <FolderItem
                  storage={storage}
                  folder={folder}
                  onFolderClick={() => () => {
                    setFolderId(folder.folder_id);
                    setQueue((queue) => [...queue, folder]);
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>파일</Typography>
          </ListItemText>
          <Grid container spacing={1}>
            {directories?.file_info.map((file) => (
              <Grid item xs={12} md={6} key={file.upload_file_id}>
                <FileItem storage={storage} file={file} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setQueue((queue) => {
              queue.pop();
              if (queue.length > 0) {
                setFolderId(queue[queue.length - 1].folder_id);
              } else {
                setFolderId("root");
              }
              return [...queue];
            });
          }}
        >
          상위이동
        </Button>
        <Button
          onClick={onMove(
            group?.select?.storage_sub_id,
            file?.select.upload_file_id
              ? storage?.select?.folder?.folder_id === "root"
                ? undefined
                : storage?.select?.folder?.folder_id
              : storage?.select?.folder?.parent_folder_id,
            queue[queue.length - 1]?.folder_id,
            file?.select.upload_file_id
              ? undefined
              : [storage?.select.folder?.folder_id],
            file?.select.upload_file_id
              ? [file?.select.upload_file_id]
              : undefined,
          )}
        >
          이동
        </Button>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalExplorer;
