import * as React from "react";
import { Typography, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DatePicMypagebase({
  fromValue,
  toValue,
  fromOnChange,
  toOnChange,
  // fromInputFormat = "YYYY-MM-DD",
  // toInputFormat = "YYYY-MM-DD",
  // fromMask = "____-__-__",
  // toMask = "____-__-__",
  // fromFormatter,
  // toFormatter,
  // fromFormat = "YYYY/MM/DD",
  // toFormat = "YYYY/MM/DD",
  disabled,
  sx,
}) {
  const inputFormat = "YYYY-MM-DD"; // 두 선택기 모두에 대한 일관된 형식
  const mask = "____-__-__"; // 공통 마스크

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker"]}>
        <DatePicker
          label="시작일"
          inputFormat={inputFormat}
          mask={mask}
          // inputFormat={"yyyy-MM-dd"}
          // mask={"____-__-__"}
          value={fromValue}
          onChange={fromOnChange}
          renderInput={(params) => <TextField {...params} />}
          // valueFormatter={fromFormatter}
          // format={fromFormat}
          disabled={disabled}
          sx={sx}
          slotProps={{ textField: { size: "small" } }}
        />
        <Typography sx={{ display: "flex", alignItems: "center" }}>
          {" "}
          ~{" "}
        </Typography>
        <DatePicker
          label="종료일"
          inputFormat={inputFormat}
          mask={mask}
          // inputFormat={"yyyy-MM-dd"}
          // mask={"____-__-__"}
          value={toValue}
          onChange={toOnChange}
          renderInput={(params) => <TextField {...params} />}
          // valueFormatter={toFormatter}
          // format={toFormat}
          disabled={disabled}
          sx={sx}
          slotProps={{ textField: { size: "small" } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
