import React, { useEffect, useState } from "react";
import { useStatisticsQuery } from "assets/hooks/useStatistics/useStatisticsQuery";
import dayjs from "dayjs";

// import client from "assets/js/commonApi";
import ChartBasic from "components/charts/ChartBasic";
import StatisticsCard from "components/viewContents/StatisticsCard";
import { styled } from "@mui/material/styles";
import { Paper, Box, Grid, Typography, Container } from "@mui/material";
// import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#EFF2F4",
  position: "relative",
  height: "100%",
  // color: theme.palette.text.secondary,
}));

const TypographySx = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  textAlign: "left",
  position: "absolute",
  left: "10%",
  top: "12%",
}));

const Statistics = () => {
  // const { data: statisticsData } = useStatisticsQuery();
  const {
    data: statisticsData,
    isLoading,
    isError,
  } = useStatisticsQuery({
    // staleTime: 600000, // 캐시 시간을 10분으로 조정
    placeholderData: { month_data: [], month_accumul_data: [] }, // 초기 데이터 설정
  });
  // const { data: statisticsData } = useStatisticsQuery();
  // const { data: statisticsData, isLoading, isError } = useStatisticsQuery();

  // if (isLoading) {
  //   return <div>Loading statistics...</div>;
  // }

  // if (isError || !statisticsData) {
  //   return <div>Error loading data!</div>;
  // }

  // 데이터가 준비되면, 이 아래에서부터 데이터를 사용한 렌더링 로직을 계속합니다.

  // const monthData = statisticsData?.month_data;
  // const accumulData = statisticsData?.month_accumul_data;
  const monthData = statisticsData?.month_data || [];
  const accumulData = statisticsData?.month_accumul_data || [];
  // const monthData = statisticsData?.month_data.slice(0, 1000) || [];
  // const accumulData = statisticsData?.month_accumul_data.slice(0, 1000) || [];

  // const reversedAccumul = AccumulData.reverse();
  // console.log("reversedAccumul", reversedAccumul); // 예: [4, 5, 6] (6월 현재)
  // console.log("reversedAccumul", reversedAccumul[0].month); // 예: [4, 5, 6] (6월 현재)
  console.log("##@@statisticsData", statisticsData);
  console.log("##@@statisticsData.month_data", statisticsData?.month_data[0]);

  // useEffect(
  //   (params) => {
  //     const headers = {
  //       "Content-type": "application/json",
  //       Accept: "application/json",
  //     };
  //     client
  //       .get(
  //         // "/mdpt_platform/accumulate", 여기서 데이터  240612 수요일 강예영
  //         // {
  //         //   did: did,
  //         //   contract_number: contract_number,
  //         // },
  //         { did: "" },
  //         {
  //           headers: headers,
  //         },
  //       )
  //       .then((res) => {
  //         let datas = res.data.data;
  //         // 솔트의반환값을데이타스에다시 할당해준다.
  //         console.log("## 누적 통계 데이터", datas);
  //         datas = datas.sort(function (a, b) {
  //           return b.MONTH - a.MONTH;
  //           // setAccumulateData(datas);
  //         });
  //         // datas = datas.slice(3, 6); 7월 8월 9월 표시
  //         datas = datas.slice(0, 3);
  //         datas = datas.reverse();

  //         console.log("res.data.data", JSON.stringify(res.data.data));
  //         const newDatas = datas?.map((data, index) => {
  //           for (let key in data) {
  //             const lowerKey = key.toLowerCase();
  //             if (lowerKey !== key) {
  //               data[lowerKey] = data[key];
  //               delete data[key]; // 오브젝트 키 A 값 12345 빈오브젝트 만드는기능
  //             }
  //           }
  //           return {
  //             ...data,
  //             id: index,
  //           };
  //         });
  //         setAccumulateData(newDatas);
  //         console.log("## 인기 데이터 datas", datas);
  //       });
  //     client
  //       .get(
  //         // "/mdpt_platform/month", 여기서 데이터  240612 수요일 강예영
  //         // {
  //         //   did: did,
  //         //   contract_number: contract_number,
  //         // },
  //         { did: "" },
  //         headers,
  //       )
  //       .then((res) => {
  //         let datas = res.data.data;
  //         // 솔트의반환값을데이타스에다시 할당해준다.
  //         datas = datas.sort(function (a, b) {
  //           return b.MONTH - a.MONTH;
  //           // setAccumulateData(datas);
  //         });

  //         // 최신 데이터 표시(현재 월)
  //         datas = datas.slice(0);

  //         // datas = datas.reverse();
  //         // // 솔트의반환값을데이타스에다시 할당해준다.

  //         // datas.MONTH.slice(3, -1);
  //         // 데이터에 있는 배열을 슬라이스 해라

  //         console.log(
  //           "## 월 데이터 res.data.data",
  //           JSON.stringify(res.data.data),
  //         );
  //         const newDatas = datas?.map((data, index) => {
  //           for (let key in data) {
  //             const lowerKey = key.toLowerCase();
  //             if (lowerKey !== key) {
  //               data[lowerKey] = data[key];
  //               delete data[key]; // 오브젝트 키 A 값 12345 빈오브젝트 만드는기능
  //             }
  //           }
  //           return {
  //             ...data,
  //             id: index,
  //           };
  //         });
  //         // setMonthData(newDatas); kyy
  //         console.log("## 통계 월별 데이터 datas", datas);
  //         console.log("## 금번 월별 계약 건수", newDatas);
  //       });
  //     // 총 NFT 금액
  //     client
  //       .post(
  //         // "/mdpt_tf/select_ntf_accept", 여기서 데이터  240612 수요일 강예영
  //         // {
  //         //   did: did,
  //         //   contract_number: contract_number,
  //         // },
  //         { did: "" },
  //         headers,
  //       )
  //       .then((res) => {
  //         const datas = res.data.data;
  //         console.log(
  //           "## 통계 전체 데이터 res.data.data",
  //           JSON.stringify(res.data.data),
  //         );
  //         const newDatas = datas?.map((data, index) => {
  //           for (let key in data) {
  //             const lowerKey = key.toLowerCase();
  //             if (lowerKey !== key) {
  //               data[lowerKey] = data[key];
  //               delete data[key]; // 오브젝트 키 A 값 12345 빈오브젝트 만드는기능
  //             }
  //           }
  //           return {
  //             ...data,
  //             id: index,
  //           };
  //         });
  //         setTotalSumData(newDatas);
  //         console.log("## 총 datas", datas);
  //         console.log("##총 NFT 금액 데이터", newDatas);
  //       });
  //   },

  //   [],
  // );

  // 현재 월

  const currentMonth = dayjs().month() + 1; // dayjs는 월을 0부터 시작하므로 1을 더해줍니다.

  // 최근 3개월의 월을 배열로 구하기
  const lastThreeMonths = [
    currentMonth,
    dayjs().subtract(1, "month").month() + 1,
    dayjs().subtract(2, "month").month() + 1,
  ];

  // 배열을 역순 정렬
  const reversedMonths = lastThreeMonths.reverse();
  // console.log(lastThreeMonths); // 예: [6, 5, 4] (6월 현재)
  console.log(reversedMonths); // 예: [4, 5, 6] (6월 현재)

  // 통계 그래프 데이터 S
  // 월 표시
  const xLabels = [
    `${reversedMonths[0]}월`,
    `${reversedMonths[1]}월`,
    `${reversedMonths[2]}월`,
  ];

  // 그래프 시각적 표현/ bar, line 그래프 대입
  // 누적 계약 건수
  const seriesA = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.contract_count,
        monthData[1]?.contract_count,
        monthData[2]?.contract_count,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.contract_count,
        monthData[1]?.contract_count,
        monthData[2]?.contract_count,
      ],
    },
  ];
  // 누적 계약 금액
  const seriesB = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.price_amount,
        monthData[1]?.price_amount,
        monthData[2]?.price_amount,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.price_amount,
        monthData[1]?.price_amount,
        monthData[2]?.price_amount,
      ],
    },
  ];
  // 누적 거래 데이터 용량
  const seriesC = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.datasize_amount,
        monthData[1]?.datasize_amount,
        monthData[2]?.datasize_amount,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.datasize_amount,
        monthData[1]?.datasize_amount,
        monthData[2]?.datasize_amount,
      ],
    },
  ];
  // 누적 다운로드 수
  const seriesD = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.download_count,
        monthData[1]?.download_count,
        monthData[2]?.download_count,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.download_count,
        monthData[1]?.download_count,
        monthData[2]?.download_count,
      ],
    },
  ];
  // 누적 FT금액
  const seriesF = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.ft_amount,
        monthData[1]?.ft_amount,
        monthData[2]?.ft_amount,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.ft_amount,
        monthData[1]?.ft_amount,
        monthData[2]?.ft_amount,
      ],
    },
  ];
  // 누적 회원가인한 기업 수
  const seriesG = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.company_count,
        monthData[1]?.company_count,
        monthData[2]?.company_count,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.company_count,
        monthData[1]?.company_count,
        monthData[2]?.company_count,
      ],
    },
  ];
  // 누적 회원 수
  const seriesH = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      // data: [2, 5, 3],
      data: [
        monthData[0]?.user_count,
        monthData[1]?.user_count,
        monthData[2]?.user_count,
      ],
    },
    {
      type: "line",
      yAxisKey: "eco",
      color: "red",
      data: [
        monthData[0]?.user_count,
        monthData[1]?.user_count,
        monthData[2]?.user_count,
      ],
    },
  ];
  // 통계 그래프 데이터 E

  // 통계 none 그래프 데이터 S
  const MainTopCardContent = [
    {
      title: "월별 계약 건수",
      date: accumulData[2]?.contract_count,
      description: "건",
    },
    {
      title: "월별 계약 금액",
      date: accumulData[2]?.price_amount,
      description: "원",
    },
    {
      title: "월별 거래 데이터 용량",
      date: accumulData[2]?.datasize_amount,
      description: "GB",
    },
    {
      title: "월별 다운로드 수",
      date: accumulData[2]?.download_count,
      description: "회",
    },
    {
      title: "총 NFT 금액",
      // date: accumulData[2]?.month,
      date: "0",
      description: "원",
    },
    {
      title: "월별 FT 금액",
      date: accumulData[2]?.ft_amount,
      description: "원",
    },
    // {
    //   title: "월별 계약 건수",
    //   date: monthData[0]?.agree_count,
    //   description: "건",
    // },
  ];
  // 통계 none 그래프 데이터 E
  return (
    <>
      <Box
        sx={{
          pb: 6,
        }}
      >
        <Container
          sx={{
            pt: 8,
            pb: 6,
          }}
          maxWidth="lg"
        >
          <Typography component="h2" variant="h3" sx={{ marginBottom: "32px" }}>
            플랫폼통계
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {/* A 시작 */}
            <Grid container spacing={4} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">누적 계약 건수</TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesA}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <StatisticsCard card={MainTopCardContent[0]} />
                </Item>
              </Grid>
            </Grid>
            {/* A 끝 */}
            {/* B 시작 */}
            <Grid
              container
              spacing={4}
              sx={{ justifyContent: "flex-end", mt: 1 }}
            >
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">누적 계약 금액</TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesB}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <StatisticsCard card={MainTopCardContent[1]} />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">
                    누적 거래 데이터 용량
                  </TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesC}
                  />
                </Item>
              </Grid>
            </Grid>
            {/* B 끝 */}
            {/* C 시작 */}
            <Grid
              container
              spacing={4}
              sx={{ justifyContent: "flex-end", mt: 1 }}
            >
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <StatisticsCard card={MainTopCardContent[2]} />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">누적 다운로드 수</TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesD}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <StatisticsCard card={MainTopCardContent[3]} />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <StatisticsCard card={MainTopCardContent[4]} />
                </Item>
              </Grid>
            </Grid>
            {/* C 끝 */}
            {/* D 시작 */}
            <Grid
              container
              spacing={4}
              sx={{ justifyContent: "flex-end", mt: 1 }}
            >
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">누적 FT 금액</TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesF}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <StatisticsCard
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    card={MainTopCardContent[5]}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">누적 기업 수</TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesG}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Item>
                  <TypographySx component="h3">누적 회원 수</TypographySx>
                  <ChartBasic
                    xAxis={[
                      {
                        id: "years",
                        data: xLabels,
                        scaleType: "band",
                        categoryGapRatio: 0.98,
                        // categoryGapRatio: 1.5,
                        valueFormatter: (value) => value.toString(),
                      },
                    ]}
                    series={seriesH}
                  />
                </Item>
              </Grid>
            </Grid>
            {/* D 끝 */}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Statistics;
