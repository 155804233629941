// import React from "react";
import { useState } from "react";
import { Button, Grid, Typography, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { StyledPaper } from "components/modal/ModalContract"; // StyledPaper 컴포넌트에 맞게 import 경로 수정
import CryptoJS, { AES } from "crypto-js";
import { v4 } from "uuid";
import client from "assets/js/commonApi";

import ModalContractQrFilev2 from "components/modal/ModalContractQrFilev2";

const StyledPaper = styled(Paper)(({ theme }) => ({
  // StyledPaper 컴포넌트
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: "8px auto",
  display: "flex",
  flex: 1,
  color: theme.palette.text.primary,
}));

// 큐알 초기값
const initQRcode = {
  type: "storage/key",
  data: {
    pulling_key: "",
    encrypted_key: "",
    storage_sub_id: "",
    contract_sub_id: "",
  },
};

export default function FileDownloadAreaView({
  did,
  company_info_type,
  contractData,
  encryptKey,
  file_info,
  // contract_sub_id,
  onClick,
  // contract_sub_id
}) {
  // if (did === contractData.did_buyer) {
  //   if (
  //     contractData.step_type === "S40_A" ||
  //     contractData.step_type === "S50_A"
  //   )

  // const [cidKey, setCidKey] = useState(); // 다운로드 키
  // const [decryptKey, setDecryptKey] = useState();
  const [isOpenFileDownQrModal, setIsOpenFileDownQrModal] = useState(false); // 창 open
  const [qrcode, setQrcode] = useState(initQRcode);
  const [selectedFile, setSelectedFile] = useState();
  // const [intervalHandler, setIntervalHandler] = useState(() => {});
  // const pullingKey = v4().toString().replaceAll("-", "");

  const FILELIST_API_ENDPOINT = "/buy/download";
  const DOWNLOAD_PULLING_ENDPOINT = "/file_download_pulling";
  const IPFS_API_ENDPOINT = "http://ipfs.busan-coldchain.com:5001/api/v0/cat/";

  const onQRPopupClose = () => {
    setIsOpenFileDownQrModal(false);
  };
  const handleQRClose = (qrcode, result) => {
    setIsOpenFileDownQrModal(false);
    console.log("@@qrcode", qrcode);
    console.log("@@result", result);

    if (!selectedFile) {
      return;
    }

    client
      .post("/buy/download", {
        // file_id: fileInfos.upload_file_id,
        // folder_id: folder.folder_id,
        // storage_sub_id: group.storage_sub_id,
        upload_file_id: selectedFile.upload_file_id,
        download_file_id: selectedFile.download_file_id,
        contract_sub_id: contractData.contract_sub_id,
      })
      .then((res) => {
        const { file_name, file_ext, payload } = res.data?.data; // 3개정보 ****** // 사용자가 받을 파일 그자체

        console.log(
          "[useStorage]",
          "[onFileDownloadClick]",
          "[file_name]",
          file_name,
        );
        console.log(
          "[useStorage]",
          "[onFileDownloadClick]",
          "[file_ext]",
          file_ext,
        );
        console.log(
          "[useStorage]",
          "[onFileDownloadClick]",
          "[paylaod]",
          payload, // 파일 그자체
        );

        // 4. 복호화
        // const decryptedKey = result.key;
        // const decodeBase64 = atob(payload);
        // const decode = AES.decrypt(decodeBase64, decryptedKey).toString(
        //   CryptoJS.enc.Utf8,
        // );
        const decryptedKey = AES.decrypt(
          result?.key,
          qrcode?.data?.encrypted_key,
        ).toString(CryptoJS.enc.Utf8);
        //
        const decodeBase64 = atob(payload);
        const decode = AES.decrypt(decodeBase64, decryptedKey).toString(
          CryptoJS.enc.Utf8,
        );
        console.log("## FILE DECODE", decode);
        // 5. base64를 원래 파일로 변환
        const src = decode;

        // 다운로드
        const link = document.createElement("a");
        link.href = src;
        link.download = file_name;
        link.click();
      });
  };
  const handleOnlyQrFileClose = () => {
    setIsOpenFileDownQrModal(false);
  };
  const onFileItemClick = (file, contractSubId) => {
    const decryptedKey = "decrypted_key";
    // 매개변수(두번째contractSubId)

    // alert(JSON.stringify(file));
    setSelectedFile(file);
    // const uploadFileId = file.upload_file_id;
    // const downloadFileId = file.download_file_id;

    setQrcode((code) => ({
      ...code,
      data: {
        ...code.data,
        pulling_key: v4().toString(), // 기계에서 복호화 키값을 던져 준다 - 풀링형태로 받을 수 있또록 먼저 지정
        encrypted_key: v4().toString(), // 필요없지만 넣어라
        // storage_sub_id: "", // 여기다 넣어주기
        contract_sub_id: contractData.contract_sub_id,
      },
    }));

    // const contractSubId = contract_sub_id;
    // console.log(
    //   "uploadFileId, downloadFileId, contractSubId",
    //   uploadFileId,
    //   downloadFileId,
    //   contractSubId,
    // );
    // setIsOpenFileDownQrModal

    setIsOpenFileDownQrModal(true);
  };

  // const handleClickQrOpen = () => {
  //   setIsOpenFileDownQrModal(true);
  // };

  if (company_info_type === "buyer") {
    if (
      contractData.step_type === "105" || // 이거는 추후 삭제
      contractData.step_type === "106" ||
      contractData.step_type === "107" ||
      contractData.step_type === "108" ||
      contractData.step_type === "307" // 플랫폼 구매 계약
    ) {
      return (
        <>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography component="h2" variant="subtitle2">
                파일 다운로드
              </Typography>
              <StyledPaper variant="outlined" sx={{ justifyContent: "center" }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  sx={{
                    mt: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      // p: 1,
                      // m: 1,
                      // bgcolor: "background.paper",
                      borderRadius: 1,
                    }}
                  >
                    {/* <Button
                    component="label"
                    variant="text"
                    startIcon={
                      <FindInPageIcon
                        style={{
                          fontSize: 30,
                          position: "absolute",
                          left: "-21px",
                          top: "1px",
                        }}
                      />
                    }
                    onClick={onClick}
                    encryptKey={encryptKey}
                    sx={{ textDecoration: "underline", color: "#468ECD" }}
                  >
                    파일관리 페이지 이동하기
                  </Button> */}
                    {file_info?.map((file, index) => (
                      <Button
                        key={index}
                        // onClick={isOpenFileDownQrModal}
                        onClick={
                          () =>
                            onFileItemClick(file, contractData.contract_sub_id) // contractData.contract_sub_id 인자 = 매개변수에 인자값을 대입해줘라
                        }
                        component="a"
                        // href={`download_path/${file.download_file_id}`} // Adjust the download path as needed
                        variant="text"
                        startIcon={
                          <FileDownloadIcon
                            style={{
                              fontSize: 30,
                              position: "absolute",
                              left: "-21px",
                              top: "1px",
                            }}
                          />
                        }
                        sx={{ textDecoration: "underline", color: "#468ECD" }}
                      >
                        {file.file_name || `파일 ${index + 1}`}
                      </Button>
                    ))}
                  </Box>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
          <ModalContractQrFilev2
            isOpen={isOpenFileDownQrModal}
            onClose={onQRPopupClose}
            handleClose={handleQRClose}
            handleOnlyQrFileClose={handleOnlyQrFileClose}
            qrcode={qrcode}
          />
        </>
      );
    }
  }
  return null;
}
