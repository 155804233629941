import React from "react";
import {
  ButtonBase,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";


const FolderItemUnMenu = ({
  folder,
  onFolderClick,
}) => {
  return (
    <>
      <ListItem>
        <ButtonBase
          sx={{
            width: "100%",
            backgroundColor: "#ccc",
            borderRadius: "10px",
          }}
          onClick={onFolderClick(folder)}
        >
          <ListItemIcon sx={{ marginLeft: "15px" }}>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText sx={{ marginRight: "15px" }}>
            <Typography noWrap>{folder.folder_name}</Typography>
          </ListItemText>
        </ButtonBase>
      </ListItem>
    </>
  );
};

export default FolderItemUnMenu;
