import { useEffect, useState, useCallback } from "react";
import client from "assets/js/commonApi";

const initUser = {
  user_id: "",
  user_type: "",
  did: "",
  email: "",
  email_yn: "",
  approval_yn: "",
  approval_dtm: "",
  upd_user: "",
};

export default function useUser(user = initUser) {
  const [dialog, setDialog] = useState({
    open: false,
    context: "",
    value: "",
    onChange: () => {},
    onClick: () => {},
    onClose: () => {},
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 10000,
    handleClose: () => setSnackBar((pre) => ({ ...pre, open: false })),
  });
  const [type, setType] = useState("user_name");
  const [search, setSearch] = useState("");
  const [info, setInfo] = useState({ ...initUser, ...user });

  const [list, setList] = useState([]);

  const getUsers = useCallback(() => {
    console.log("[useUser onSearchClick]");
    const newInfo = { ...initUser, [type]: search };
    setInfo(newInfo);

    client
      .post("/user/list", newInfo)
      .then((res) => {
        const data = res?.data?.data;

        if (Array.isArray(data)) {
          const newData = data.map((item, index) => ({
            ...item,
            id: index + 1,
            approval_yn: item.approval_yn
              ? item.approval_yn === "Y"
                ? "승인"
                : "철회"
              : "대기",
            approval_button: item.approval_yn
              ? item.approval_yn === "Y"
                ? null
                : "승인하기"
              : "승인하기",
            disapproval_button: item.approval_yn ? null : "철회하기",
          }));
          setList(newData);
        }
      })
      .catch((err) =>
        console.error("[[useUser onSearchClick /user/list]]", err),
      );
  }, [type, search]);

  const showSnackBar = (message) =>
    setSnackBar((pre) => ({
      ...pre,
      open: true,
      message: message,
    }));

  const onSearchTypeChange = (event) => {
    setType(event.target.value);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const onSearchClick = () => {
    getUsers();
  };

  const onSearchKeyPress = (event) => {
    console.log("[useUser onSearchKeyPress]", event);
    if (event.key === "Enter") {
      onSearchClick();
    }
  };

  const onCellClick = (params) => {
    const {
      user_id,
      user_name,
      user_type,
      email,
      approval_button,
      disapproval_button,
    } = params.row;
    const { field } = params;

    // 내용 없으면 이벤트 방지
    if (!field) return;

    console.log("[useUser onCellClick]", params);
    if (field === "disapproval_button" && disapproval_button) {
      setDialog({
        open: true,
        context: `정말로 ${user_name} 사용자를 철회하시겠습니까?`,
        onClick: () => {
          setDialog({ open: false });
          // 철회
          client
            .post("/user/approval", [
              {
                user_id,
                user_name,
                user_type: user_type ?? "basic",
                email,
                approval_yn: "N",
              },
            ])
            .then((res) => {
              console.log("[useUser onCellClick /user/approval]", res);

              showSnackBar(`${user_name} 사용자를 철회하였습니다`);
              onSearchClick();
            })
            .catch((err) => {
              showSnackBar(err.response.data.msg);
              console.error("[useUser onCellClick /user/approval]", err);
            });
        },
        onClose: () => setDialog({ open: false }),
      });
    }
    if (field === "approval_button" && approval_button) {
      setDialog({
        open: true,
        context: `${user_name} 사용자를 승인하시겠습니까?`,
        onClick: () => {
          setDialog({ open: false });
          // 승인
          client
            .post("/user/approval", [
              {
                user_id,
                user_name,
                user_type: user_type ?? "basic",
                email,
                approval_yn: "Y",
              },
            ])
            .then((res) => {
              console.log("[useUser onCellClick /user/approval]", res);

              showSnackBar(`${user_name} 사용자를 승인하였습니다`);
              onSearchClick();
            })
            .catch((err) => {
              showSnackBar(err.response.data.msg);
              console.error("[useUser onCellClick /user/approval]", err);
            });
        },
        onClose: () => setDialog({ open: false }),
      });
    }
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return {
    info,
    list,
    snackBar,
    dialog,
    search: {
      type,
      onSearchChange,
      onSearchTypeChange,
      onSearchKeyPress,
      onSearchClick,
    },
    onCellClick,
  };
}
