// import React from "react";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Stack,
  Box,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

import { useNavigate, redirect } from "react-router-dom";
import ModalContinue from "components/modal/ModalContinue";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PageError = () => {
  // 페이지 이동
  const navigate = useNavigate();
  const homePageMove = () => {
    navigate("/");
    console.log("## homePageMove", homePageMove);
  };

  const goBack = () => {
    navigate(-1);
  };

  // text 스타일
  const TypographySx = {
    fontSize: "20px",
    textAlign: "center",
    color: "#0009",
    fontWeight: "500",
  };

  // 버튼 스타일
  const MoveBtnSx = {
    background: "#29314A",
    cursor: "grab",
    fontSize: "16px",
    pl: 3,
    pr: 3,
    "&:hover": {
      background: "#29314A",
      // border: "1px solid #468ECD",
      // backgroundColor: "rgb(70 142 205 / 4%)",
    },
  };

  return (
    <>
      <Box
        sx={{
          background: "#EFF2F4",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            // pt: 8,
            pb: 6, // footerbar때문에 가운데 정렬 안되니 90px 정도 빼고 정렬하기
          }}
          maxWidth="lg"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#468ECD", fontSize: "120px" }} />
            <Typography
              component="h2"
              // variant="h3"
              sx={{
                color: "#468ECD",
                mb: 1,
                mt: 1,
                fontSize: "2.5rem",
                fontWeight: 500,
              }}
            >
              서비스 이용에 불편을 드려 죄송합니다.
            </Typography>
            <Box sx={{ mb: 2.5 }}>
              <Typography
                component="p"
                variant="subtitle1"
                sx={TypographySx}
                // sx={{
                //   marginBottom: "32px",
                //   lineHeight: 2,
                // }}
              >
                요청하신 페이지에 오류가 발생하였습니다.
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                sx={TypographySx}
                // sx={{
                //   fontWeight: "600",
                //   color: "#29314A",
                //   display: "flex",
                // }}
              >
                잠시후에 다시 이용 부탁드립니다.
              </Typography>
            </Box>
            <Stack spacing={1} direction="row">
              <Button onClick={homePageMove} sx={MoveBtnSx} variant="contained">
                홈으로 이동
              </Button>
              <Button
                autoFocus
                onClick={goBack}
                sx={MoveBtnSx}
                variant="contained"
              >
                이전 페이지로 이동
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default PageError;
