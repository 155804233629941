import React, { useState, useEffect } from "react";
import "./ContractProgress.css";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { useDirectSalesQuery } from "assets/hooks/useMypage/contractProgress/useDirectSalesQuery";
import { useDirectSalesStepQuery } from "assets/hooks/useMypage/data/useDirectSalesStepQuery";
import { useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Chip,
  Stack,
  Badge,
} from "@mui/material";
import {
  SaveAlt,
  KeyboardArrowRight,
  Task,
  HowToRegOutlined,
  DriveFileRenameOutlineOutlined,
  InsertChartOutlinedOutlined,
  EventRepeatOutlined,
  EditNote,
  Addchart,
  HourglassEmpty,
  DoneAll,
  RemoveShoppingCart,
  Undo,
  CheckCircle,
  AssignmentReturn,
  AssignmentTurnedIn,
  CreditCardOff,
  CreditCardOffOutlined,
  HighlightOff,
  DeleteForever,
  Backspace,
} from "@mui/icons-material";

import StepItemList from "components/viewContents/contractProgressView/StepItemList";

// import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import AddchartIcon from "@mui/icons-material/Addchart";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
// 탭별 공통 스타일
const cardStyle = {
  display: "flex",
  // justifyContent:'c'
  flexDirection: "row",
  // backgroundColor: "#f9f9f9", // 배경색
  // border: "1px solid #e0e0e0", // 테두리
  // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 그림자
  // borderRadius: "8px", // 모서리 둥글게
  // marginBottom: "16px", // 마진
};

const contentStyle = {
  backgroundColor: "#f9f9f9", // 배경색
  border: "1px solid #e0e0e0", // 테두리
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 그림자
  borderRadius: "8px", // 모서리 둥글게
  marginBottom: "16px", // 마진
  //
  padding: "20px", // 컨텐츠 패딩
  ml: "20px",
};

// 아이콘 감싸는 박스Box 스타일
//
//
//
const StackSX = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  // justifyContent: "center", // 전체 스택을 중앙 정렬
};
const IconSx = {
  fontSize: 40,
  color: "#0000008a",
};
const BadgeSx = {
  "& .MuiBadge-badge": {
    backgroundColor: "#468ECD",
    color: "#fff",
    fontWeight: "600",
    fontSize: "1.125rem",
    p: 2,
    pt: 1.5, // 12px
    pl: 1,
    pr: 1,
    borderRadius: "15px",
  },
};
const ArrowSx = {
  fontSize: "3rem",
  color: "rgba(0, 0, 0, 0.12)",
  // color: "#ccc",
};

const StepTextSx = {
  fontSize: "0.875rem",
};

const DirectSalesTab = ({ isActive }) => {
  const queryClient = useQueryClient(); // queryClient 사용
  const navigate = useNavigate();
  // const CST01 = "yourCST01Value"; // CST01 값을 설정

  const [currentStep, setCurrentStep] = useState(null);

  const [currentStepData, setCurrentStepData] = useState([]);

  const { data: directSalesData, refetch: refetchDirectSalesData } =
    useDirectSalesQuery("CST01", {
      enabled: isActive,
    });

  // const { data: directSalesData } = useDirectSalesQuery(
  //   "CST01",
  //   { enabled: isActive }, // 1번 탭이 활성화될 때만 쿼리 활성화
  // );

  useEffect(() => {
    if (isActive) {
      refetchDirectSalesData(); // isActive가 true일 때 데이터 새로고침
    }
  }, [isActive, refetchDirectSalesData]);

  //   useEffect(() => {
  //     refetchDirectStepData()
  // }, [triggerRefresh]);
  // 모달 수정 시 데이터그리 - 트리거리프레쉬 사용하는데, isActive처럼 사용할지 트리거 리프레쉬 사용할지 생각하기

  //
  //
  // useEffect(() => {
  //   if (currentStep !== null) {
  //     queryClient.invalidateQueries(["direct_sales_step_data"]);
  //   }
  // }, [currentStep, queryClient]);

  // const { data: directStapData } = useDirectSalesStepQuery(
  //   currentStep,
  //   {},
  //   currentStep !== null,
  // );

  // 버리는 코드
  // 쿼리 키에 currentStep을 포함하여, 스텝 변경시 자동으로 재호출하도록 설정
  // const { data: directStapData, refetch: refetchDirectStepData } =
  //   useDirectSalesStepQuery(["direct_sales_step_data", currentStep], {
  //     enabled: !!currentStep, // 스텝이 null이 아닐 때만 쿼리 활성화
  //     onSuccess: () => {
  //       console.log("Data fetched successfully"); // 데이터 성공적으로 불러왔을 때 실행할 함수
  //       // queryClient.invalidateQueries(["direct_sales_data"]); // 쿼리 무효화}
  //     },
  //   });
  //

  // 기존 코드 (기본)
  // const { data: directStapData } = useDirectSalesStepQuery(currentStep,
  //   ["direct_sales_step_data", currentStep],
  //   {
  //     enabled: !!currentStep, // 스텝이 null이 아닐 때만 쿼리 활성화
  //     onSuccess: () => {
  //       console.log("Data fetched successfully"); // 데이터 성공적으로 불러왔을 때 실행할 함수
  //       // queryClient.invalidateQueries(["direct_sales_data"]); // 쿼리 무효화}
  //     },
  //   },
  // );
  const {
    data: directStapData,
    isLoading: isDataLoading,
    refetch: refetchDirectStepData,
  } = useDirectSalesStepQuery(currentStep, {}, currentStep !== null);

  // 기존 코드 리패티 적용한 코드
  // const { data: directStapData, refetch: refetchDirectStepData } =
  //   useDirectSalesStepQuery(currentStep, {
  //     enabled: !!currentStep, // currentStep이 null이 아니면 쿼리 활성화
  //     onSuccess: () => {
  //       console.log("Data fetched successfully for step " + currentStep);
  //     },
  //   });
  //
  // useEffect(() => {
  //   if (currentStep) {
  //     refetchDirectStepData();
  //   }
  // }, [currentStep, refetchDirectStepData]);
  //
  // // queryClient 적용한 코드 - 작동이 안됨
  // const { data: directStapData } = useDirectSalesStepQuery(currentStep, {
  //   queryKey: ["direct_sales_step_data", currentStep],
  //   // enabled: !!currentStep,
  //   enabled: true, // 이 부분을 `true`로 설정하여 항상 활성화

  //   onSuccess: () => {
  //     console.log("Data fetched successfully for step " + currentStep);
  //     // queryClient.invalidateQueries(["direct_sales_step_data"]);
  //   },
  // });

  // 코드, 라벨 및 아이콘 매핑
  const steps = {
    100: {
      label: "임시 저장",
      icon: <SaveAlt sx={IconSx} />,
      relatedCodes: ["100"],
    },
    101: {
      label: "등록 완료",
      icon: <Task sx={IconSx} />,
      relatedCodes: ["101"],
    },
    102: {
      label: "수정 요청",
      icon: <EditNote sx={IconSx} />,
      relatedCodes: ["102"],
    },
    103: {
      label: "구매자 확인",
      icon: <HowToRegOutlined sx={IconSx} />,
      relatedCodes: ["103"],
    },
    104: {
      label: "판매자 서명",
      icon: <DriveFileRenameOutlineOutlined sx={IconSx} />,
      relatedCodes: ["104"],
    },
    105: {
      label: "구매자 서명",
      icon: <DriveFileRenameOutlineOutlined sx={IconSx} />,
      relatedCodes: ["105", "106"],
    },
    // 105: { label: "결제 대기", icon: <HourglassEmpty /> },
    // 106: { label: "파일 등록 전", icon: <Addchart /> },
    107: {
      label: "데이터 등록 중",
      icon: <HourglassEmpty sx={IconSx} />,
      relatedCodes: ["107"],
    },
    108: {
      label: "데이터 등록 완료",
      icon: <InsertChartOutlinedOutlined sx={IconSx} />,
      relatedCodes: ["108"],
    },
    // 109: { label: "계약 만료", icon: <EventRepeatOutlined /> },
    110: {
      label: "계약 종료 1개월 이내",
      icon: <EventRepeatOutlined sx={IconSx} />,
      relatedCodes: ["109", "110"],
    },
    401: {
      // label: "판매자 계약 철회",
      label: "판매 철회",
      // icon: <Undo sx={IconSx} />,
      icon: <AssignmentReturn sx={IconSx} />,
      relatedCodes: ["401"],
    },
    402: {
      // label: "구매자 계약 철회",
      label: "구매 철회",
      icon: <RemoveShoppingCart sx={IconSx} />,
      // icon: <CreditCardOffOutlined sx={IconSx} />,
      relatedCodes: ["402"],
    },
    403: {
      label: "철회 완료",
      // icon: <CheckCircle sx={IconSx} />,
      icon: <AssignmentTurnedIn sx={IconSx} />,
      relatedCodes: ["403"],
    },
    // 403: {
    //   label: "철회 완료",
    //   icon: <AssignmentReturn sx={IconSx} />,
    //   relatedCodes: ["403"],
    // },
    // 403: {
    //   label: "철회 완료",
    //   icon: <CancelPresentation sx={IconSx} />,
    //   relatedCodes: ["403"],
    // },
    // 403: {
    //   label: "철회 완료",
    //   icon: <HighlightOff sx={IconSx} />,
    //   relatedCodes: ["403"],
    // },
    // 403: {
    //   label: "철회 완료",
    //   icon: <DeleteForever sx={IconSx} />,
    //   relatedCodes: ["403"],
    // },
    // 403: {
    //   label: "철회 완료",
    //   icon: <Backspace sx={IconSx} />,
    //   relatedCodes: ["403"],
    // },
  };

  // const steps400 = {
  //   401: { label: "판매자 계약 철회", icon: <RemoveShoppingCart /> },
  //   402: { label: "구매자 계약 철회", icon: <Undo /> },
  //   403: { label: "철회 완료", icon: <CheckCircle /> },
  // };

  // const findStepCount = (code) => {
  //   const item = directSalesData?.find((d) => d.code_value === code);
  //   return item ? item.step_count : 0;
  // };

  // 스텝 카운트를 묶어줌
  const findStepCount = (code) => {
    const step = steps[code];
    if (!step) return 0;

    return directSalesData
      ?.filter((d) => step.relatedCodes.includes(d.code_value))
      .reduce((total, item) => total + item.step_count, 0);
  };

  // const handleStepClick = (step) => {
  //   setCurrentStep(step);
  // };

  // 기본 코드
  // 스텝 안묶어준 친구
  const handleStepClick = (stepId) => {
    console.log(`##Box clicked: ${stepId}`);
    setCurrentStep(stepId);
    queryClient.invalidateQueries(["direct_sales_step_data"]);
  };

  //

  // // // 리패치 적용
  // const handleStepClick = (stepId) => {
  //   console.log(`###Box clicked: ${stepId}`);

  //   // 무조건 현재 선택된 스텝을 업데이트
  //   setCurrentStep(stepId);

  //   // 현재 스텝이 이미 선택된 스텝과 같은 경우에만 데이터를 새로 고침
  //   if (currentStep === stepId) {
  //     refetchDirectStepData();
  //   }
  // };

  //

  //
  // // 리패치 적용
  // const handleStepClick = (stepId) => {
  //   console.log(`Box clicked: ${stepId}`);

  //   if (currentStep !== stepId) {
  //     setCurrentStep(stepId); // 새로운 스텝 선택
  //   } else {
  //     // 같은 스텝을 다시 클릭했을 경우 데이터를 새로 고침
  //     refetchDirectStepData();
  //   }
  // };

  // // 쿼리 클라이언트 적용 - 작동이 안됨
  // const handleStepClick = (stepId) => {
  //   console.log(`##Box clicked: ${stepId}`);
  //   setCurrentStep(stepId);
  //   // queryClient.invalidateQueries(["direct_sales_step_data"]);
  // };

  // const handleStepClick = (stepId) => {
  //   console.log(`Box clicked: ${stepId}`);
  //   if (currentStep !== stepId) {
  //     // 새로운 스텝을 선택했을 때 상태 업데이트
  //     setCurrentStep(stepId);
  //   } else {
  //     // 이미 선택된 스텝을 다시 클릭했을 때 데이터를 새로 고침
  //     refetchDirectStepData();
  //   }
  // };

  // const handleStepClick = (stepId) => {
  //   console.log(`Box clicked: ${stepId}`);
  //   if (currentStep === stepId) {
  //     // 현재 스텝을 다시 클릭했을 때 데이터를 새로고침
  //     refetchDirectStepData();
  //   } else {
  //     // 새로운 스텝 선택 시 상태 변경
  //     setCurrentStep(stepId);
  //   }
  // };

  // const handleStepClick = (stepId) => {
  //   console.log(`Box clicked: ${stepId}`);
  //   // stepGroups에서 관련된 모든 stepIds를 찾고, 없으면 기본 stepId 사용
  //   const relatedStepIds = steps[stepId].relatedCodes;
  //   setCurrentStep(relatedStepIds); // 관련된 모든 코드들을 현재 스텝으로 설정
  // };

  // useEffect(() => {
  //   if (directSalesData) {
  //     console.log("##directSalesData", directSalesData); // 데이터가 준비되면 콘솔에 출력
  //   }
  // }, [directSalesData]); // directSalesData가 변경될 때마다 실행
  console.log("##@@directSalesData", directSalesData && directSalesData);

  // const CST01 = 'yourCST01Value';  // CST01 값을 설정
  // const { data: directSalesData, isLoading, error } = useDirectSalesQuery;
  //   navigate("/mypage/sales");
  //   console.log("Navigation function called");
  // const QuickBtn = () => {

  // }
  const handleGoToSales = () => {
    // console.log("##Navigating to /mypage/sales", navigate);
    // navigate("/mypage/sales");
    console.log("#@#@Navigating to /mypage/sales");
    navigate("/mypage/sales");
    console.log("#@#@Navigation function called");
  };

  const Step = ({ code, label, icon, count, onClick }) => (
    // <Box
    //   onClick={() => onClick(code)}
    <Box
      onClick={() => handleStepClick(code)}
      className="cp_StepBoxSx"
      // sx={{
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   cursor: "pointer",
      //   position: "relative",
      // }}
    >
      <Badge badgeContent={count} max={99} sx={BadgeSx}>
        {icon}
      </Badge>
      <Typography
        // sx={StepTextSx}
        className="cp_StepTextSx"
      >
        {label}
      </Typography>
    </Box>
  );

  return (
    <>
      {/* <Stack spacing={4}> */}
      <Stack spacing={10}>
        <Stack gap={1.5} sx={StackSX}>
          {Object.entries(steps).map(([code, { label, icon }], index, array) =>
            code < "400" ? (
              <React.Fragment key={code}>
                {/* <Box
                  onClick={() => handleStepClick(code)}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Badge
                    badgeContent={findStepCount(code)}
                    max={99}
                    sx={BadgeSx}
                  >
                    {icon}
                  </Badge>
                  <Typography sx={StepTextSx}>{label}</Typography>
                </Box> */}
                <Step
                  code={code}
                  label={label}
                  icon={icon}
                  count={findStepCount(code)}
                  onClick={handleStepClick}
                />
                {/* {index !== array.length - 1 && (
                  <KeyboardArrowRight sx={ArrowSx} />
                )} */}
                {code < 110 && <KeyboardArrowRight sx={ArrowSx} />}
              </React.Fragment>
            ) : null,
          )}
        </Stack>

        <Stack gap={1.5} sx={StackSX} justifyContent="flex-end">
          {Object.entries(steps).map(
            ([code, { label, icon }]) =>
              code >= 400 && (
                <React.Fragment key={code}>
                  {/* <Card sx={cardStyle}> */}
                  <CardContent sx={contentStyle}>
                    <Box
                      // display="flex"
                      // flexDirection="column"
                      // alignItems="center"
                      // onClick={handleStepClick}
                      onClick={() => handleStepClick(code)}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Badge
                        badgeContent={findStepCount(code)}
                        max={99}
                        sx={BadgeSx}
                      >
                        {icon}
                      </Badge>
                      <Typography sx={StepTextSx}>{label}</Typography>
                    </Box>
                  </CardContent>
                  {/* </Card> */}
                </React.Fragment>
              ),
          )}
        </Stack>
      </Stack>
      <Typography
        variant="body1"
        // className="cp_moveTextSx"
        sx={{ mt: 5, mb: 0.1, fontSize: "0.875rem" }}
      >
        데이터 등록완료 1개월 이후 데이터는 판매내역에서 확인 바랍니다.
        <Button
          onClick={handleGoToSales}
          // variant="contained"
          variant="text"
          color="primary"
          // className="cp_moveBtnSx"
          sx={{
            ml: 0.25,
            textDecoration: "underline",
            textUnderlineOffset: "3px",
            marginTop: "-4px",
          }}
        >
          판매내역 바로가기
        </Button>
      </Typography>
      <StepItemList
        stapData={directStapData}
        contract_type="001"
        // company_info_type={company_info_type}
        // company_info_type="seller"
        // contract_user_type={}
        // onSelectContractNumber={onSelectContractNumber}
        // selectContractNumber={selectContractNumber}
        isDataLoading={isDataLoading}
      />
      {/* <DirectSalesInfoCard /> 이카드 컴포넌트 삭제하기 */}
    </>
  );
};

export default DirectSalesTab;
