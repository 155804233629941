import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "assets/js/commonApi";

const platformBuyRequest = async (platform_main_id) => {
  return client.post("/platformbuy/ask", platform_main_id);
};

export const usePlatformBuyReqQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: platformBuyRequest, // 이곳에서 mutationFn을 명시적으로 지정
    onSuccess: () => {
      console.log("구매요청 상태가 성공적으로 업데이트되었습니다.");
      // queryClient.invalidateQueries(["platform-detail"]);
      // 이곳에 추가적으로 팝업을 띄우는 로직을 구현할 수 있습니다.
    },
    onError: (error) => {
      console.error("구매요청 업데이트 실패:", error);

      //   error.response.state
    },
  });
};
