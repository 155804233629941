import * as React from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CircularIndeterminate } from "components/loaders/CircularIndeterminate";
import { Stack } from "@mui/material";

import FolderOffIcon from "@mui/icons-material/FolderOff";

const DataGridLayout = ({
  children,
  rows,
  columns,
  onCellClick,
  sx,
  checkboxSelection,
  isDataLoading,
}) => {
  // const datagridSx = {
  //   // borderRadius: 2,
  //   "& .MuiDataGrid-main": {
  //     // borderRadius: 2
  //   },
  //   '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
  //     // color: "blue",
  //     // fontSize: 18,
  //     //risky
  //     // minHeight: "60px !important",
  //     // height: 60,
  //     "& div": {
  //       // minHeight: "60px !important",
  //       // height: 60,
  //       // lineHeight: "59px !important",
  //     },
  //   },
  //   "& .MuiDataGrid-virtualScrollerRenderZone": {
  //     "& .MuiDataGrid-row": {
  //       "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
  //     },
  //   },
  //   // "& .MuiDataGrid-cell:focus": {
  //   //   outline: "none",
  //   // },
  //   // "& .MuiDataGrid-cell":{
  //   //   // text-decoration:'underline'
  //   //   // border-bottom:
  //   //   border-bottom:'1px solid #000',
  //   // },

  //   // 컬럼 6번째 친구한테 스타일 주는것 - 계약관리한테만 줘야 함 -만약에 계약 관리 페이지면 스타일 주기 삼항으로
  //   "& .MuiDataGrid-cell:hover:nth-child(6)": {
  //     cursor: "pointer !important",
  //     color: "red",
  //   },
  //   "& .MuiDataGrid-cell:nth-child(6)": {
  //     "& .MuiDataGrid-cellContent": {
  //       // cursor: "pointer !important",
  //       // color: "red",
  //       // border-bottom: 1px solid #000,
  //       borderBottom: "1px solid #000",
  //     },
  //   },

  //   // pointer cursor on ALL rows

  //   "& .MuiDataGrid-columnHeaders": {
  //     // backgroundColor: "rgba(0,0,255,0.6)",
  //     // color: "rgba(255,0,0,0.7)",
  //     fontSize: 14,
  //     minHeight: "40px !important",
  //     maxHeight: "40px !important",
  //     lineHeight: "40px !important",
  //   },
  //   "& .MuiDataGrid-footerContainer": {
  //     // backgroundColor: "rgba(0,0,255,0.6)",
  //     // color: "rgba(255,0,0,0.7)",
  //     fontSize: 14,
  //     minHeight: "40px !important",
  //     maxHeight: "40px !important",
  //     lineHeight: "40px !important",
  //   },
  //   // disable cell selection style
  //   // "& .MuiDataGrid-cell:focus": {
  //   //   outline: "none",
  //   // },
  //   // // pointer cursor on ALL rows
  //   // "& .MuiDataGrid-row:hover:nth-child(6)": {
  //   //   cursor: "pointer !important",
  //   //   color: "red",
  //   // },

  //   // "& .RaDatagrid-clickableRow": { cursor: "default !important" },
  // };

  // 스타일

  // const StyledTextField = styled(TextField)`
  //   label.Mui-focused {
  //     color: green;
  //   }
  //   .MuiOutlinedInput-input {
  //     cursor: pointer;
  //   }
  //   .MuiOutlinedInput-root {
  //     fieldset {
  //       border-color: red;
  //     }
  //     &:hover fieldset {
  //       border-color: blue;
  //       cursor: pointer;
  //     }
  //     &.Mui-focused fieldset {
  //       border-color: green;
  //     }
  //   }
  // `;
  // const [message, setMessage] = React.useState("");
  // // const data = useMovieData();
  // const handleRowClick = (params) => {
  //   setMessage(`Movie "${params.row.title}" clicked`);
  // };
  // console.log("##message", message);

  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const handleOnCellClick = (params) => {
    const field = params.field; // 비교할 대상 변수로 만들고
    const contractFieldName = "doc"; // 내가 원하는 값을 변수로 만들고
    const excelDownloadFieldName = "file_name";

    if (field === contractFieldName) {
      // alert("계약서 컬럼 셀 선택");
      setFinalClickInfo(params);
      onCellClick(params);
    }
    if (field === excelDownloadFieldName) {
      setFinalClickInfo(params);
      onCellClick(params);
    }

    // setFinalClickInfo(params);
  };

  console.log("finalClickInfo", finalClickInfo);

  // // no data 이미지 S
  // const getHeightSX = () => {
  //   // 데이터가 없을 때
  //   if (!rows || rows.length === 0) {
  //     return { height: 400 };
  //   }
  //   // 데이터가 있을 때
  //   const numRows = rows.length;
  //   const calculatedHeight = numRows * 50 + 100;
  //   return { height: calculatedHeight };
  // };
  // // no data 이미지 E

  const getHeightSX = () => {
    return {
      minHeight: 180, // 최소 높이를 180px로 설정
      // maxHeight: 500,
      maxHeight: 700,
      ...sx,
    };
  };

  // const getHeightSX = () => {
  //   // 데이터가 없을 때
  //   if (!rows || rows.length === 0) {
  //     return { minHeight: 180, maxHeight: 500 };
  //   }
  //   // 데이터가 있을 때
  //   const numRows = rows.length;
  //   const calculatedHeight = numRows * 50 + 100;
  //   return { height: calculatedHeight };
  // };

  // // no data 이미지 E
  // const getHeightSX = () => {
  //   if (!rows || rows.length === 0) {
  //     return { height: 400, minHeight: 300, maxHeight: 500 };
  //   } else {
  //     const numRows = rows.length;
  //     const calculatedHeight = Math.max(numRows * 50 + 100, 400); // 최소 높이 400px 보장
  //     return { height: calculatedHeight };
  //   }
  // };
  // const getHeightSX = () => {
  //   if (!rows || rows.length === 0) {
  //     // return { minHeight: 180, maxHeight: 380 };
  //     return { minHeight: 180, maxHeight: 380 };
  //   } else {
  //     const numRows = rows.length;
  //     const calculatedHeight = Math.max(numRows + 50, 380); // 최소 높이 400px 보장
  //     return { height: calculatedHeight };
  //   }
  // };

  return (
    // <Stack>
    <Stack sx={getHeightSX}>
      {/* {isDataLoading && <CircularIndeterminate />} */}
      <DataGrid
        // onRowClick={handleRowClick}
        // {...data}
        autoHeight
        children={children}
        sx={sx}
        // sx={{ minHeight: 180, maxHeight: 500, ...sx }} // minHeight와 maxHeight를 직접 정의
        // getRowId={(row) => row.id}
        rows={rows}
        // key={id}
        // checkboxSelection={checkboxSelection}
        columns={columns}
        // baseTooltip
        initialState={{
          pagination: {
            // paginationModel: {
            //   pageSize: 10,
            // },
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        // pageSizeOptions={[10]}
        pageSizeOptions={[5, 10]}
        hideFooterSelectedRowCount={true}
        // checkboxSelection
        // disableRowSelectionOnClick
        // disableSelectionOnClick
        // columnHeight={25}
        rowHeight={55}
        onCellClick={handleOnCellClick}
        loading={isDataLoading} // 로더 mui
      />
      {/* {finalClickInfo && finalClickInfo.row.doc
        ? `Final clicked finalClickInfo.row.doc = ${finalClickInfo.row.doc}`
        : null} */}
    </Stack>
  );
};

export default DataGridLayout;

// disable cell selection style
// "& .MuiDataGrid-cell:focus": {
//   outline: "none",
// },
// // pointer cursor on ALL rows
// "& .MuiDataGrid-row:hover": {
//   cursor: "pointer",
// },
