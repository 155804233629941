import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import { MessageContext } from "assets/js/syntax";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePic from "components/picker/DatePic";
import ContractNeedAddInput from "./ContractNeedAddInput";
import { SelectForm } from "components/selectBox/SelectForm";
import { useMainCatQuery } from "assets/hooks/useCategory/useSelectQuery";

import dayjs from "dayjs";
import * as jschardet from "jschardet";
import * as XLSX from "xlsx/xlsx.mjs";

const RenderCheckColumn = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handerChange = (event, newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue ? "Y" : "N" });
  };

  return (
    <Checkbox
      icon={<CheckBoxIcon />}
      checked={value === "Y"}
      checkedIcon={<CheckBoxIcon sx={{ color: "#468dcc" }} />}
      onClick={(event) => event.stopPropagation()}
      onChange={handerChange}
    />
  );
};

const ModalFileInfo = ({
  open = false,
  fileInfo,
  onUpload = () => {},
  onClose = () => {},
}) => {
  // const { triggerRefresh } = useContext(MessageContext); // 전역 계약창 정보 업데이트 시 리프레쉬
  const { data: categories } = useMainCatQuery();
  const file = useRef();
  const [info, setInfo] = useState({
    ...{
      data_title: "",
      data_main_cat: "",
      data_sub_cat: "",
      data_price: 0,
      data_desc: "",
      data_ext: "",
      data_size: 0,
      collect_from_dtm: dayjs(),
      collect_to_dtm: dayjs(),
      collect_machine: "",
      collect_product: "",
      collect_process: "",
      column_info: [],
      columnsInfo: [],
    },
    ...fileInfo,
  });

  // **** 미 입력 S
  const [needAddInputOpen, setNeedAddInputOpenOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNeedAddInputOpenOpen(false);
  };

  // **** 유효성 확인 S
  const [message, setMessage] = useState();
  const {
    label00,
    label01,
    label02,
    label03,
    label04,
    label05,
    label06,
    label07,
    label08,
    label09,
  } = useContext(MessageContext).file_messageList;

  // 유효성 에 대한 팝업 표시
  const Validation = () => {
    // // 내용
    // 초기 필드 검사
    const initialFields = [
      { value: info.data_title, label: label00 },       // 파일 선택
      { value: info.data_main_cat, label: label01 },    // Main Category 입력
      { value: info.data_sub_cat, label: label02 },     // Sub Category 입력
      { value: info.data_price, label: label03 },       // 가격 입력
      { value: info.data_desc, label: label04 },        // 설명 입력
      { value: info.collect_from_dtm, label: label05 }, // 수집 시작일 입력
      { value: info.collect_to_dtm, label: label06 },   // 수집 종료일 입력
      { value: info.collect_machine, label: label07 },  // 수집장비 입력
      { value: info.collect_process, label: label08 },  // 수집공정 입력
      { value: info.collect_product, label: label09 },  // 수집제품 입력
    ];

    // 초기 필드 검사 수행
    for (const { value, label } of initialFields) {
      if (!value) {
        console.log(`## [유효성 에러]`, label);
        setMessage(label);
        return false; // 실패 시 검사 중단
      }
    }

    return true; // 모든 검사를 통과하면 true 반환
  };
  // **** 유효성 확인 E

  // Sub Category 남은 입력 가능 길이 계산
  const caculateLength = (str) => {
    // Database에 입력 문자열 구조
    // 최대길이 50글자
    // 중괄호, #, \, 가 붙음
    // [#태그1,#태그2,#태그3]
    const LIMIT_LENGTH = 50;
    const count = str.split(",").length;
    const length = str.replace(/,/g, "").length;

    return LIMIT_LENGTH - 2 - (2 * count - 1) - length;
  };
  // **** Sub Category 남은 입력 가능 길이 계산 E

  const onUpdateClick = () => {
    if (Validation()) {
      setNeedAddInputOpenOpen(false);
      onUpload(file, info)();
    } else {
      setNeedAddInputOpenOpen(true);
    }
  };

  const onFileChange = (event) => {
    console.log("[ModalFileInfo]", "[onFileChange]", event.target.files);
    const fileInfo = event.target.files[0];
    // 50MB
    const limitFileSize = 1000 * 1000 * 50;

    if (fileInfo) {
      const filename = fileInfo.name.substring(
        0,
        fileInfo.name.lastIndexOf("."),
      );
      if (filename.length > 50) {
        alert("파일의 이름은 50자를 넘을 수 없습니다");

        file.current.value = "";
        setInfo({
          data_title: "",
          data_main_cat: "",
          data_sub_cat: "",
          data_price: 0,
          data_desc: "",
          data_ext: "",
          data_size: 0,
          collect_from_dtm: dayjs(),
          collect_to_dtm: dayjs(),
          collect_machine: "",
          collect_product: "",
          collect_process: "",
          column_info: [],
          columnsInfo: [],
        });
        return;
      }
      if (fileInfo.size > limitFileSize) {
        alert("파일의 용량은 50MB를 넘을 수 없습니다");

        file.current.value = "";
        setInfo({
          data_title: "",
          data_main_cat: "",
          data_sub_cat: "",
          data_price: 0,
          data_desc: "",
          data_ext: "",
          data_size: 0,
          collect_from_dtm: dayjs(),
          collect_to_dtm: dayjs(),
          collect_machine: "",
          collect_product: "",
          collect_process: "",
          column_info: [],
          columnsInfo: [],
        });
        return;
      }

      setInfo((pre) => ({
        ...pre,
        data_title: fileInfo.name,
        data_ext: fileInfo.name.substring(
          fileInfo.name.lastIndexOf(".") + 1,
          fileInfo.name.length,
        ),
        data_size: fileInfo.size,
        column_info: [],
      }));

      const SHEET_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const CSV_TYPE = "text/csv";
      if (fileInfo.type === SHEET_TYPE) {
        // 엑셀 형식인 경우
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array", bookVBA: true });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            defval: "",
          });

          console.log("[ModalFileInfo]", "[sheet]", jsonData[0]);
          setInfo((pre) => ({
            ...pre,
            column_info: jsonData[0]?.map((column, index) => ({
              id: index + 1,
              column_no: index + 1,
              column_name: column,
              column_size: 0,
              column_desc: column,
              column_pk_yn: "Y",
            })),
          }));
        };

        reader.readAsArrayBuffer(fileInfo);
      }
      if (fileInfo.type === CSV_TYPE) {
        // CSV 형식인 경우
        const encodeReader = new FileReader();
        const reader = new FileReader();

        encodeReader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          let str = "";

          for (var i = 0; i < data.length; ++i) {
            str += String.fromCharCode(data[i]);
          }

          const { encoding } = jschardet.detect(str);
          reader.readAsText(fileInfo, encoding);
          console.log("[ModalFileInfo]", "[type]", encoding);
        };

        reader.onload = async (e) => {
          const data = e.target.result;
          console.log("[ModalFileInfo]", "[target]", e.target);
          const workbook = XLSX.read(data, { type: "binary", bookVBA: true });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            defval: "",
          });

          console.log("[ModalFileInfo]", "[sheet]", jsonData[0]);
          setInfo((pre) => ({
            ...pre,
            column_info: jsonData[0]?.map((column, index) => ({
              id: index + 1,
              column_no: index + 1,
              column_name: column,
              column_size: 0,
              column_desc: column,
              column_pk_yn: "Y",
            })),
          }));
        };

        encodeReader.readAsArrayBuffer(fileInfo);
      }
    }
  };
  const onTextFieldChange = (event) => {
    const value = event.target.value;
    const length = caculateLength(value);

    if (length > -1)
      setInfo((info) => ({
        ...info,
        [event.target.name]: value,
      }));
  };
  const onProcessRowUpdate = (updatedRow, originalRow) => {
    setInfo((info) => ({
      ...info,
      column_info: info.column_info.map((row) => {
        if (row.id === updatedRow.id) {
          return updatedRow;
        }
        return row;
      }),
    }));

    return updatedRow;
  };
  const onProcessRowUpdateError = () => {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Dialog open={open} onClose={onClose}>
          <DialogTitle
            component="h1" // 헤딩태그 지정
            variant="h6" // 변형 사이즈
            // sx={modalContractSx}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            파일 업로드
          </DialogTitle>
          <DialogContent>
            <Grid sx={{ flexGrow: 1 }} xs={12} direction="column">
              {fileInfo.data_size === 0 && (
                <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                  <Grid item>
                    <Typography variant="subtitle2">파일선택</Typography>
                  </Grid>
                  <Grid item>
                    <input type="file" ref={file} onChange={onFileChange} />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">파일명</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="subtitle2">{info.data_title}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography component="dt" variant="subtitle2">
                    크기
                  </Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="subtitle2">
                    {info.data_size} byte
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">Main Category</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <SelectForm
                    value={info.data_main_cat}
                    onSelectChange={(item) =>
                      setInfo((info) => ({ ...info, data_main_cat: item }))
                    }
                    options={
                      categories
                        ? categories.map((categoryItem) => ({
                            label: categoryItem.code_name,
                            value: categoryItem.code_value,
                          }))
                        : []
                    }
                    label="Main Category"
                    placeholder="Main Category"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">Sub Category</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <TextField
                    name="data_sub_cat"
                    size="small"
                    value={info.data_sub_cat}
                    onChange={onTextFieldChange}
                  ></TextField>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="subtitle2" color="text.secondary">
                    {`${caculateLength(info.data_sub_cat)}자 입력 가능합니다`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">가격</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <TextField
                    name="data_price"
                    label="가격"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">원</InputAdornment>
                      ),
                    }}
                    value={info.data_price}
                    onChange={onTextFieldChange}
                    size="small"
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">설명</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <TextField
                    name="data_desc"
                    label="설명"
                    size="small"
                    value={info.data_desc}
                    onChange={onTextFieldChange}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">확장자</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="subtitle2">{info.data_ext}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">수집기간</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <DatePic
                    component="dd"
                    fromValue={info.collect_from_dtm}
                    toValue={info.collect_to_dtm}
                    fromInputFormat={"yyyy-MM-dd"}
                    fromMask={"____-__-__"}
                    toInputFormat={"yyyy-MM-dd"}
                    toMask={"____-__-__"}
                    fromFormat="YYYY/MM/DD"
                    toFormat="YYYY/MM/DD"
                    fromOnChange={(newValue) =>
                      setInfo((info) => ({
                        ...info,
                        collect_from_dtm: newValue,
                      }))
                    }
                    toOnChange={(newValue) =>
                      setInfo((info) => ({ ...info, collect_to_dtm: newValue }))
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">수집장비</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <TextField
                    name="collect_machine"
                    label="장비"
                    size="small"
                    value={info.collect_machine}
                    onChange={onTextFieldChange}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">수집공정</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <TextField
                    name="collect_process"
                    label="공정"
                    size="small"
                    value={info.collect_process}
                    onChange={onTextFieldChange}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">수집제품</Typography>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <TextField
                    name="collect_product"
                    label="제품"
                    size="small"
                    value={info.collect_product}
                    onChange={onTextFieldChange}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="column" sx={{ mt: 0.5 }}>
                <Grid item>
                  <Typography variant="subtitle2">컬럼정보</Typography>
                </Grid>
                <Grid item xs zeroMinWidth sx={{ minHeight: "400px" }}>
                  <DataGrid
                    rows={info.column_info}
                    columns={[
                      {
                        field: "column_no",
                        headerName: "순번",
                        type: "number",
                        width: 80,
                      },
                      {
                        field: "column_name",
                        headerName: "컬럼명",
                        width: 80,
                      },
                      {
                        field: "column_size",
                        headerName: "길이",
                        width: 80,
                        editable: true,
                      },
                      {
                        field: "column_desc",
                        headerName: "설명",
                        width: 200,
                        editable: true,
                      },
                      {
                        field: "column_pk_yn",
                        headerName: "키 여부",
                        width: 80,
                        renderCell: RenderCheckColumn,
                        renderEditCell: RenderCheckColumn,
                        editable: true,
                      },
                    ]}
                    rowHeight={55}
                    processRowUpdate={onProcessRowUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                    hideFooter
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onUpdateClick}>업로드</Button>
            <Button onClick={onClose}>닫기</Button>
          </DialogActions>
          {needAddInputOpen && (
            <ContractNeedAddInput
              open={needAddInputOpen}
              handleClose={handleClose}
              message={message}
            />
          )}
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default ModalFileInfo;
