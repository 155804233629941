import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Grid, Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SnackbarMessage } from "components/modal";
import CartInfoCard from "components/viewContents/CartInfoCard";
import SearchBarCart from "views/temp/cartLikes/SearchBarCart";
import SearchBarLike from "views/temp/cartLikes/SearchBarLike";
import useCart from "views/temp/cartLikes/useCart";
import useLike from "views/temp/cartLikes/useLike";
import { useNavigate } from "react-router-dom";

// 스타일
const StyledTab = styled(Tab)({
  backgroundColor: "#fff", // 배경색
  minHeight: "unset",
  // backgroundColor: "#f0f0f0", // 배경색
  // color: "#000", // 글자색
  // fontSize: "1rem", // 글자 크기
  "&.Mui-selected": {
    // 선택된 탭 스타일
    // backgroundColor: "#c0c0c0",
    // color: "blue",
    color: "#468ECD",
    border: "1px solid #468ECD",
    borderRadius: "25px",
  },
  "&:hover": {
    // 마우스 오버 스타일
    backgroundColor: "#eee",
    // backgroundColor: "#e0e0e0",
    // border: "1px solid #468ECD",
    borderRadius: "25px",
  },
});

const TabPanelSx = styled(TabPanel)(({ theme }) => ({
  padding: "0",
  marginTop: "24px",
}));

const CartPanel = () => {
  const { data, snackBar, onClick, onRemoveCart } = useCart();
  const navigate = useNavigate();
  const [checkList, setCheckList] = useState([]);

  const deleteCheckList = (platformMainId) => {
    setCheckList((pre) => {
      const checkSet = new Set(pre);

      checkSet.delete(platformMainId);
      return [...checkSet];
    });
  };

  //  const onItemClick = (platformMainId) => {
  //    alert(platformMainId)
  //  }
  const handleDetailClick = (platform_main_id) => {
    navigate(`/category/${platform_main_id}`);
  };
  const onChange = (platformMainId, event) => {
    if (event.target.checked) {
      setCheckList((pre) => [...new Set(pre).add(platformMainId)]);
    } else {
      deleteCheckList(platformMainId);
    }
  };
  const onRemove = (platformMainId) => {
    deleteCheckList(platformMainId);
  };

  return (
    <div>
      <SearchBarCart
        onRemoveClick={() => onRemoveCart(checkList)}
        onClick={() => onClick(checkList)}
      />
      <Grid container spacing={1}>
        {data.map((item) => (
          <CartInfoCard
            sx={{
              display: "flex",
            }}
            key={item.platform_main_id}
            platform_main_id={item.platform_main_id}
            dataImageUrl={item.data_img_url}
            dataCompanyName={item.company_name}
            dataTitle={item.data_title}
            dataMainCat={item.data_main_cat_desc}
            dataSubCat={item.data_sub_cat}
            dataDesc={item.data_desc}
            dataSize={item.data_size}
            dataExt={item.data_ext}
            dataPrice={item.data_price}
            dataDate={item.ins_dtm}
            downloadCount={item.download_cnt}
            viewCnt={item.view_cnt}
            likeCount={item.like_cnt} // 좋아요 카운트
            // onClick={onItemClick}
            dataTitleOnClick={() => handleDetailClick(item.platform_main_id)}
            dataImageOnClick={() => handleDetailClick(item.platform_main_id)}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
      </Grid>
      <SnackbarMessage {...snackBar} />
    </div>
  );
};

const LikePanel = () => {
  const { data, snackBar, onAddClick, onRemoveClick } = useLike();
  const navigate = useNavigate();
  const [checkList, setCheckList] = useState([]);

  const deleteCheckList = (platformMainId) => {
    setCheckList((pre) => {
      const checkSet = new Set(pre);

      checkSet.delete(platformMainId);
      return [...checkSet];
    });
  };

  const handleDetailClick = (platform_main_id) => {
    navigate(`/category/${platform_main_id}`);
  };
  const onChange = (platformMainId, event) => {
    if (event.target.checked) {
      setCheckList((pre) => [...new Set(pre).add(platformMainId)]);
    } else {
      deleteCheckList(platformMainId);
    }
  };
  const onRemove = (platformMainId) => {
    deleteCheckList(platformMainId);
  };

  return (
    <div>
      <SearchBarLike
        onAddClick={() => onAddClick(checkList)}
        onRemoveClick={() => onRemoveClick(checkList)}
      />
      <Grid container spacing={1}>
        {data.map((item) => (
          <CartInfoCard
            sx={{
              display: "flex",
            }}
            key={item.platform_main_id}
            platform_main_id={item.platform_main_id}
            dataImageUrl={item.data_img_url}
            dataCompanyName={item.company_name}
            dataTitle={item.data_title}
            dataMainCat={item.data_main_cat_desc}
            dataSubCat={item.data_sub_cat}
            dataDesc={item.data_desc}
            dataSize={item.data_size}
            dataExt={item.data_ext}
            dataPrice={item.data_price}
            dataDate={item.ins_dtm}
            downloadCount={item.download_cnt}
            viewCnt={item.view_cnt}
            likeCount={item.like_cnt} // 좋아요 카운트
            // onClick={onItemClick}
            dataTitleOnClick={() => handleDetailClick(item.platform_main_id)}
            dataImageOnClick={() => handleDetailClick(item.platform_main_id)}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
      </Grid>
      <SnackbarMessage {...snackBar} />
    </div>
  );
};

const CartLikes = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              ".MuiTabs-indicator": {
                // 선택된 탭에만 밑줄 스타일 지정
                backgroundColor: "transparent", // 밑줄 제거
              },
            }}
          >
            <StyledTab label="장바구니" value="1" />
            <StyledTab label="찜" value="2" />
          </TabList>
        </Box>
        <TabPanelSx value="1">
          <CartPanel />
        </TabPanelSx>
        <TabPanelSx value="2">
          <LikePanel />
        </TabPanelSx>
      </TabContext>
    </Box>
  );
};

export default CartLikes;
